import React from "react";
import { Field } from "redux-form";
import { customInput } from "../../../../../common/custom-input";
import { CustomSwitch } from "../../../../../common/customSwitch";
import { popOverCheckBox } from "../../../../../common/popOverCheckBox";
import styles from "../../../SWMSMastersContainer.module.css";
import { Tooltip, Popconfirm } from "antd";
import { getStringifiedCellContent } from "../../../utils";
import { Strings } from '../../../../../../dataProvider/localize';


export const fetchColumns = (
  save,
  edit,
  cancel,
  changeStatus,
  editingKey,
  consequencesOptions,
  controlsOptions,
  standardsOptions,
  deleteRow
) => [
    {
      title: "Hazard ID",
      dataIndex: "title",
      sorter: (a, b) => a.title < b.title ? -1 : a.title > b.title ? 1 : 0,
      editable: true,
      width: 100,
      render: (cellContent, rowData, rowIndex) =>
        rowData.key === "addRow" ? (
          <div>
            <Field name="title" component={customInput} autoFocus />
          </div>
        ) : editingKey === rowData.id ? (
          <div>
            <Field name={`title_${rowData.id}`} component={customInput} autoFocus />
          </div>
        ) : (
          <Tooltip title={cellContent}>
            <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
              {cellContent}
            </div>
          </Tooltip>
        ),
    },
    {
      title: "Hazard Description",
      dataIndex: "description",
      editable: true,
      width: 100,
      render: (cellContent, rowData, rowIndex) =>
        rowData.key === "addRow" ? (
          <div>
            <Field name="description" component={customInput} />
          </div>
        ) : editingKey === rowData.id ? (
          <div>
            <Field name={`description_${rowData.id}`} component={customInput} />
          </div>
        ) : (
          <Tooltip title={cellContent}>
            <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
              {cellContent}
            </div>
          </Tooltip>
        ),
    },
    {
      title: "Consequences",
      dataIndex: "hazards_consequences",
      editable: true,
      width: 100,
      render: (cellContent, rowData, rowIndex) =>
        rowData.key === "addRow" ? (
          <Field
            name="consequences"
            component={popOverCheckBox}
            options={consequencesOptions.map((item) => ({
              label: item.title,
              value: item.id,
            }))}
            buttonTitle="Consequences"
          />
        ) : editingKey === rowData.id ? (
          <div>
            <Field
              name={`consequences_${rowData.id}`}
              component={popOverCheckBox}
              options={consequencesOptions.map((item) => ({
                label: item.title,
                value: item.id,
              }))}
              buttonTitle="Consequences"
            />
          </div>
        ) : (
          <Tooltip
            title={getStringifiedCellContent(cellContent, "consequence_title")}
          >
            <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
              {getStringifiedCellContent(cellContent, "consequence_title")}
            </div>
          </Tooltip>
        ),
    },
    {
      title: "Controls",
      dataIndex: "hazards_swms_controls",
      editable: true,
      width: 100,
      render: (cellContent, rowData, rowIndex) =>
        rowData.key === "addRow" ? (
          <Field
            name="swms_controls"
            component={popOverCheckBox}
            options={controlsOptions.map((item) => ({
              label: item.title,
              value: item.id,
            }))}
            buttonTitle="Controls"
          />
        ) : editingKey === rowData.id ? (
          <div>
            <Field
              name={`swms_controls_${rowData.id}`}
              component={popOverCheckBox}
              options={controlsOptions.map((item) => ({
                label: item.title,
                value: item.id,
              }))}
              buttonTitle="Controls"
            />
          </div>
        ) : (
          <Tooltip
            title={getStringifiedCellContent(cellContent, "swms_control_title")}
          >
            <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
              {getStringifiedCellContent(cellContent, "swms_control_title")}
            </div>
          </Tooltip>
        ),
    },
    {
      title: "Standards/Legislations",
      dataIndex: "hazards_standards",
      editable: true,
      width: 100,
      render: (cellContent, rowData, rowIndex) =>
        rowData.key === "addRow" ? (
          <Field
            name="standards"
            component={popOverCheckBox}
            options={standardsOptions.map((item) => ({
              label: item.title,
              value: item.id,
            }))}
            buttonTitle="Standards"
          />
        ) : editingKey === rowData.id ? (
          <div>
            <Field
              name={`standards_${rowData.id}`}
              component={popOverCheckBox}
              options={standardsOptions.map((item) => ({
                label: item.title,
                value: item.id,
              }))}
              buttonTitle="Standards"
            />
          </div>
        ) : (
          <Tooltip
            title={getStringifiedCellContent(cellContent, "standard_title")}
          >
            <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
              {getStringifiedCellContent(cellContent, "standard_title")}
            </div>
          </Tooltip>
        ),
    },
    {
      title: "Status",
      dataIndex: "active",
      editable: true,
      width: 50,
      render: (cellContent, rowData, rowIndex) => {
        return rowData.key === "addRow" ? (
          <div className={styles.centeredCell}>
            <Field name="active" size="small" component={CustomSwitch} />
          </div>
        ) : editingKey === rowData.id ? (
          <div className={styles.centeredCell}>
            <Field
              name={`status_${rowData.id}`}
              size="small"
              component={CustomSwitch}
            />
          </div>
        ) : (
          <div className={styles.centeredCell}>
            <Field
              name={`status_${rowData.id}`}
              val={rowData.active}
              size="small"
              component={CustomSwitch}
              onChange={(e, value) => changeStatus(value, rowData, rowIndex)}
            />
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      editable: false,
      width: 50,
      render: (cellContent, rowData, rowIndex) =>
        rowData.key === "addRow" || editingKey === rowData.id ? (
          <div className={styles.spacedCell}>
            <i
              class="fa fa-save"
              style={{
                color:
                /* user.role && user.role_id > 0 ? */ "green" /* : 'red' */,
              }}
              aria-hidden="true"
              onClick={() =>
                editingKey
                  ? save(rowData, rowIndex, true)
                  : save(rowData, rowIndex, false)
              }
            ></i>
            <i
              class="fa fa-close"
              style={{
                color: /* user.role && user.role_id > 0 ? */ "red" /* : 'red' */,
              }}
              aria-hidden="true"
              onClick={() => cancel(rowData, rowIndex)}
            ></i>
          </div>
        ) : (
          <div className={styles.centeredCell}>
            <i
              class="fa fa-pencil"
              style={{
                color:
                /* user.role && user.role_id > 0 ? */ "green" /* : 'red' */,
              }}
              aria-hidden="true"
              onClick={() => edit(rowData, rowIndex)}
            ></i>
            <Popconfirm
              title={Strings.confirm_hazard_delete}
              onConfirm={() => deleteRow(rowData)}
              placement="topRight"
              okText="Yes"
              cancelText="No"
            >
              <button type="button" className='delete-bnt user-actions' >
                <i
                  class="fa fa-trash"
                  style={{
                    color: "#03A791",
                  }}
                ></i>
              </button>
            </Popconfirm>
          </div>
        ),
    },
  ];
