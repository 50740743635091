import moment from "moment";

export const serializeAllocatedStaffList = (staffList, allocatedUsers) => {
  const allocatedUsernames = allocatedUsers.map((user) => user.user_name);
  const filteredStaff = [];
  staffList.service_agents.forEach((sa) => {
    sa.site_supervisors.forEach((ss) => {
      if (allocatedUsernames.includes(ss.user_name)) {
        ss.org_id = sa.id
        filteredStaff.push(ss);
      }
    });
    sa.staffs.forEach((st) => {
      if (allocatedUsernames.includes(st.user_name)) {
        st.org_id = sa.id
        filteredStaff.push(st);
      }
    });
  });
  return filteredStaff;
};

export const serializeAllocatedTasks = (jobDetails) => {
  const selectedTasks = [];
  jobDetails.sites.forEach((site) =>
    site.tasks.forEach((task) => {
      if (jobDetails.selected_tasks && jobDetails.selected_tasks.includes(task.id)) {
        selectedTasks.push(task);
      }
    })
  );
  return selectedTasks;
};

export const serializeJobSchedules = (schedules) =>
  schedules.map((schedule) => ({
    start_date: schedule.start_date,
    start_time: moment(schedule.start, "HH:mm:ss").format("HH:mm:ss").toString(),
    finish_date: schedule.end_date,
    finish_time: moment(schedule.finish, "HH:mm:ss").format("HH:mm:ss"),
    task_details: schedule.scope,
    area: schedule.area,
    site_requirements: schedule.site_requirements,
  }));

export const serializeTasksForm = (tasks) => {
  const formData = {};
  tasks.forEach((task) => (formData[`select_task_${task.id}`] = true));
  return formData;
};

export const serializeChemicalsForm = (chemicals, allocatedChemicals) => {
  const formData = {};
  chemicals.forEach(
    (chemical) =>
    (formData[`select_chemical_${chemical.chemical_id}`] = allocatedChemicals
      .map((allocChem) => allocChem.chemical_id)
      .some((id) => id.toString() === chemical.chemical_id.toString()))
  );
  return formData;
};

export const serializeInsurancesForm = (selectedInsurances, allInsurances, additionalDocs, selectedAdditionalDocs, swmsDocument, swmsSelected, organisations, isJobSwms) => {
  const formData = {};
  let orgIds = organisations.map(org => org.id)
  selectedInsurances.filter(insurance => orgIds.indexOf(insurance.orgs_certificate.organisation_id) !== -1).forEach((insurance) =>
    (formData[`select_insurance_${insurance.orgs_certificate_id}`] = true)
  );
  allInsurances.forEach(org => org.certificates.forEach((insurance) => {
    if (insurance.doc_type !== "ORG_CERTIIFCATES")
      formData[`select_insurance_${insurance.doc_type}_${insurance.id}`] = true;
  }));
  selectedAdditionalDocs.filter(doc => orgIds.indexOf(doc.contractor_id) !== -1).forEach(doc => {
    formData[`select_additional_doc_${doc.id}`] = true
  })

  if (isJobSwms) {
    swmsDocument && swmsDocument.forEach(doc => {
      formData[`select_swms_document_${doc.id}`] = swmsSelected
    })
  } else {
    let allSwmsIds = typeof swmsSelected === "string" ? swmsSelected.split(",") : [];
    swmsDocument && swmsDocument.forEach(doc => {
      if (allSwmsIds.indexOf(doc.id.toString()) !== -1) {
        formData[`select_swms_document_${doc.id}`] = true;
      } else {
        formData[`select_swms_document_${doc.id}`] = false;
      }
    })
  }
  return formData;
};

export const serializePreviewRequest = (
  formValues,
  jobDetails,
  jobDocumentDetails,
  additionalDocs,
  allocatedUsers,
  isJobSwms,
  allChemicals
) => {
  const chemicals = [];
  const certificates = [];
  const staffUserNames = [];
  const schedules = [];
  const tasks = [];
  const swmsDocs = [];
  let selectedAdditionalDocs = [];
  let swmsPdfSelected = 0;

  Object.keys(formValues).forEach((keyName) => {
    if (formValues[keyName] && keyName.startsWith("select_chemical_")) {
      chemicals.push(parseInt(keyName.split("_")[2]));
    }
    if (formValues[keyName] && keyName.startsWith("select_insurance_")) {
      certificates.push(parseInt(keyName.split("_").pop()));
    }
    if (keyName.startsWith("select_additional_doc_") && formValues[keyName] === true) {
      selectedAdditionalDocs.push(parseInt(keyName.split("_").pop()));
    }

    if (formValues[keyName] && keyName.startsWith("select_swms_document_")) {
      if (isJobSwms) {
        swmsPdfSelected = 1;
      } else {
        swmsDocs.push(parseInt(keyName.split("_").pop()));
      }
    }
    if (keyName === "schedules") {
      schedules.push(...formValues[keyName]);
    }
    if (formValues[keyName] && keyName.startsWith("select_task_")) {
      tasks.push(parseInt(keyName.split("_")[2]));
    }
    if (keyName.startsWith("select_staff_")) {
      staffUserNames.push(parseInt(keyName.split("_")[2]));
    }
  });
  const formattedSchedules = formatSchedules(schedules);
  const formattedStaff = formatStaff(staffUserNames, formValues, allocatedUsers);

  let sds = allChemicals.filter(x => chemicals.indexOf(x.chemical_id) !== -1);

  return {
    job_id: jobDocumentDetails.job_id,
    job_doc_id: jobDocumentDetails.id,
    quote_id: jobDetails.quote_id,
    job_number: jobDetails.job_number,
    job_allocated_users: formattedStaff,
    certificates: certificates,
    schedules: formattedSchedules,
    job_doc_tasks: tasks,
    chemicals: chemicals,
    additional_docs: selectedAdditionalDocs,
    swms_pdf_selected: swmsPdfSelected,
    swms_forms_selected: swmsDocs.toString(),
    note: formValues.note,
    sds: [...sds]
  };
};

const formatSchedules = (schedules) =>
  schedules.map((item) => ({
    area: item.area,
    end_date: moment(item.finish_date).format("YYYY-MM-DD"),
    finish: moment(item.finish_time, "HH:mm:ss").format("HH:mm:ss").toString(),
    site_requirements: item.site_requirements,
    start_date: moment(item.start_date).format("YYYY-MM-DD"),
    start: moment(item.start_time, "HH:mm:ss").format("HH:mm:ss").toString(),
    scope: item.task_details,
    ...(item.id ? { id: item.id } : {}),
  }));

const formatStaff = (userNames, formValues, users) =>
  users.map((user) => ({
    org_id: user.org_id,
    user_name: user.user_name,
    selected_for_job_doc: formValues[`select_staff_${user.user_name}`] === true ? 1 : 0,
    user_license: Object.keys(formValues)
      .map(
        (key) =>
          formValues[key] &&
          key.startsWith(`staff_cell_${user.user_name}_`) &&
          parseInt(key.split("_").pop())
      )
      .filter((item) => item),
  }));
