import moment from "moment";

export const getFormattedDateTime = (
  changedFieldValue,
  formFieldValue,
  toFormat,
  checkValidDate
) => {
  return checkValidDate
    ? changedFieldValue
      ? moment(changedFieldValue).format(toFormat)
      : moment(formFieldValue).format(toFormat) === "Invalid date"
      ? formFieldValue
      : moment(formFieldValue).format(toFormat)
    : changedFieldValue
    ? moment(changedFieldValue).format(toFormat)
    : moment(formFieldValue).format(toFormat);
};

export const getUsernames = (formValues, actionType) => {
  const prefix =
    actionType === "CREATE"
      ? ""
      : actionType === "UPDATE"
      ? "update_"
      : "copy_";

  const formFields = Object.keys(formValues);
  const usernames = [];

  formFields.forEach((fieldName) => {
    if (fieldName.startsWith(prefix) && fieldName.includes("start_date")) {
      fieldName.split("_").length > 2 &&
        usernames.push(fieldName.split("_")[prefix ? 1 : 0]);
    }
  });

  return usernames;
};

export const getUpdatedStaffTableValues = (
  usernames,
  formValues,
  currentAction,
  start,
  finish,
  startDate,
  startTime,
  finishDate,
  finishTime
) => {
  const updatedFormValues = {};
  const prefix =
    currentAction === "CREATE"
      ? ""
      : currentAction === "UPDATE"
      ? "update_"
      : "copy_";

  usernames.forEach((u) => {
    if (
      moment(
        getFormattedDateTime(
          undefined,
          formValues[`${prefix}${u}_start_date`],
          "YYYY-MM-DD"
        ) +
          " " +
          getFormattedDateTime(
            undefined,
            formValues[`${prefix}${u}_start_time`],
            "HH:mm:ss",
            true
          )
      ).isBefore(start)
    ) {
      updatedFormValues[`${prefix}${u}_start_date`] = startDate;
      updatedFormValues[`${prefix}${u}_start_time`] = startTime;
    }

    if (
      moment(finish).isBefore(
        getFormattedDateTime(
          undefined,
          formValues[`${prefix}${u}_finish_date`],
          "YYYY-MM-DD"
        ) +
          " " +
          getFormattedDateTime(
            undefined,
            formValues[`${prefix}${u}_finish_time`],
            "HH:mm:ss",
            true
          )
      )
    ) {
      updatedFormValues[`${prefix}${u}_finish_date`] = finishDate;
      updatedFormValues[`${prefix}${u}_finish_time`] = finishTime;
    }
  });

  return updatedFormValues
};

export const getShiftColorByMap = {
  0: '#00B0F0',
  1: '#F5A623',
  2: '#7ED321',
  3: '#4A4A4A'
}