import React from "react";
import { Modal, Menu, Tabs, notification, Upload, Button } from 'antd';
import { reduxForm, getFormSyncErrors } from 'redux-form';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { validate } from '../../../utils/Validations/subscriptionValidate';
import * as actions from '../../../actions/profileManagementActions';
import * as trainingAction from '../../../actions/inductionTrainingAction';
import { Strings } from '../../../dataProvider/localize';
import { getStorage, handleFocus } from '../../../utils/common';

import PersonalDetails from './PersonalDetails';
import ViewPersonalDetails from './ViewPersonalDetails';
import PayrollDetails from './PayrollDetails';
import Licences from './Licences';
import AddNewLicences from './AddNewLicences';
import EditLIcences from './EditLIcences';
import MedicalDeclaration from './MedicalDeclaration';
import { ADMIN_DETAILS, VALIDATE_STATUS, ACCESS_CONTROL } from '../../../dataProvider/constant';
import "./ProfileStyle.css";
import styles from "./ProfileStyles.module.css";
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";

import {
  loadModels,
  getFullFaceDescription,
  isFaceDetectionModelLoaded
} from '../../../dataProvider/face';
import { createCanvas, loadImage } from 'canvas';
import Signature from './Signature';
import UploadProfilePic from './UploadProfilePic';

const { TabPane } = Tabs;

const MaxWidth = 600;

const INIT_STATE = {
  url: null,
  imageURL: null,
  fullDesc: null,
  imageDimension: null,
  error: null,
  loading: false
};

class Profile extends React.Component {
  userDetails = []
  org_user_id
  org_user_name
  profileComplete
  constructor(props) {
    super(props);
    this.state = {
      ...INIT_STATE,
      WIDTH: null,
      HEIGHT: 0,
      isModelLoaded: !!isFaceDetectionModelLoaded(),
      currentKey: '1',
      userLicencesSection: false,
      userDetailsSection: false,
      userPayrollSection: false,
      userMedicalSection: false,
      userRosteringSection: false,
      userInductionTrainingSection: false,
      formCompletion: 0,
      viewPersonalDetails: 'none',
      personalDetails: 'block',
      imageUrl: '',
      userDetails: '',
      addNewLicence: 'none',
      personalProfileDetails: 'block',
      certificatesLicences: 'none',
      addLeave: 'none',
      addNewLeaveForm: 'none',
      editLicence: 'none',
      file: '',
      openModal: false,
    }
    document.title = `${this.props.location.pathname !== '/dashboard/profile' ? 'User ' : ''} Profile - Service Farm`
  }


  componentWillMount() {
    this.resetState();
    let _W = document.documentElement.clientWidth;
    if (_W > MaxWidth) _W = MaxWidth;
    this.setState({ WIDTH: _W });
    this.mounting();
  }

  componentWillUnmount() {
    this.resetState()
  }

  componentDidMount() {
    if (this.props.match.params.userId && this.props.match.params.userName) {
      let userPermissions = JSON.parse(getStorage(ACCESS_CONTROL))["userManagement"]
      var accessControl = userPermissions && userPermissions.permissions ? userPermissions.permissions : [];
      this.org_user_id = this.props.match.params.userId
      this.org_user_name = this.props.match.params.userName
      this.props.userActions.getOrgUserProfileDetails(this.org_user_id, this.org_user_name).then(() => {
        if (accessControl.findIndex(access => access.control_name === 'sf_view_licenses_controller_view_licenses') !== -1 &&
          this.props.location.goToTab && this.props.location.goToTab === "licenses") {
          this.changeTabKey("3")
        }
      });
    } else {
      this.org_user_id = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id : null;
      this.org_user_name = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)).user_name : null;
      this.props.userActions.getOrgUserProfileDetails(this.org_user_id, this.org_user_name, null, false).then(() => {
        if (this.props.location.goToTab && this.props.location.goToTab === "licenses") {
          this.changeTabKey("3")
        }
      });
    }
    const page = 1
    // this.props.trainingAction.getCourses(page);

    this.props.userActions.getLicencesType();
    this.setState({ currentKey: '6' }, () => {
      this.setState({ currentKey: '5' }, () => {
        this.setState({ currentKey: '4' }, () => {
          this.setState({ currentKey: '3' }, () => {
            this.setState({ currentKey: '2' }, () => {
              this.setState({ currentKey: '1' }, () => {
                if (this.props.location.state && this.props.location.state.key) {
                  this.changeTabKey(this.props.location.state.key);
                }
              }
                            /* , () => this.setState({
                                viewPersonalDetails: 'none',
                                personalDetails: 'block',
                            }, () => this.setState({
                                viewPersonalDetails: 'block',
                                personalDetails: 'none'
                            })) */)
            })
          })
        })
      })
    })
    this.props.reset('Profile');
  }

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(props, oldProps) {
    var values = {}
    var valuesPayroll = {}
    var valuesMedical = {}
    var valuesRostering = {}
    var valRostering = false

    var formErrors = {}
    var formErrorsPayroll = {}

    this.state.userDetailsSection = this.state.userPayrollSection = this.state.userLicencesSection = this.state.userMedicalSection = this.state.userRosteringSection = false
    this.state.formCompletion = 0

    if (props.formValuesPesonalDetails && props.formValuesPesonalDetails.values) {
      values = props.formValuesPesonalDetails.values;
      formErrors = props.formSyncErrorsPesonalDetails;
    }

    let personalDetaisFormsValues = {}
    let personalDetailsFormErrors = {}
    if (props.formValuesPesonalDetailsForm && props.formValuesPesonalDetailsForm.values) {
      personalDetaisFormsValues = props.formValuesPesonalDetailsForm.values;
      personalDetailsFormErrors = props.formSyncErrorsPesonalDetailsForm
    }
    let residentialAddressFromsValues = {}
    let residentialAddressFromsErrors = {}
    if (props.formValuesResidentialAddressForm && props.formValuesResidentialAddressForm.values) {

      residentialAddressFromsValues = props.formValuesResidentialAddressForm.values;
      residentialAddressFromsErrors = props.formSyncErrorsResidentialAddress
    }
    let experienceFromsValues = {}
    /* let experienceFormsErrors = {}
    if (props.formValuesExperienceForm && props.formValuesExperienceForm.values) {
        experienceFromsValues = props.formValuesExperienceForm.values;
        experienceFormsErrors = props.formSyncErrorsExperienceForm
    } */
    let emergencyContactFromsValues = {}
    let emergencyContactFormsErrors = {}
    if (props.formValuesEmergencyContactForm && props.formValuesEmergencyContactForm.values) {
      emergencyContactFromsValues = props.formValuesEmergencyContactForm.values;
      emergencyContactFormsErrors = props.formSyncErrorsEmergencyContactForm
    }

    if (props.formValuesPayroll && props.formValuesPayroll.values) {
      valuesPayroll = props.formValuesPayroll.values
      formErrorsPayroll = props.formSyncErrorsPayroll
    }
    let payrollDetailsFormsValues = {}
    let payrollDetailsFormsErrors = {}
    if (props.formValuesPayrollForm && props.formValuesPayrollForm.values) {
      payrollDetailsFormsValues = props.formValuesPayrollForm.values
      payrollDetailsFormsErrors = props.formSyncErrorsPayrollFrom
    }
    let superFundFormsValues = {}
    let superFundFormsErrors = {}
    if (props.formValuesSuperFundForm && props.formValuesSuperFundForm.values) {
      superFundFormsValues = props.formValuesSuperFundForm.values
      superFundFormsErrors = props.formSyncErrorsSuperFundForm
    }
    let bankAccountFormsValues = {}
    let bankAccountFormsErrors = {}
    if (props.formValuesBankAccountForm && props.formValuesBankAccountForm.values) {
      bankAccountFormsValues = props.formValuesBankAccountForm.values
      bankAccountFormsErrors = props.formSyncErrorsBankAccountForm
    }

    if (props.formValuesMedical && props.formValuesMedical.values) {
      valuesMedical = props.formValuesMedical.values
      // formErrorsMedical = props.formSyncErrorsMedical
    }

    if (props.formValuesRostering && props.formValuesRostering.values) {
      valuesRostering = props.formValuesRostering.values
      Object.keys(valuesRostering).forEach(key => {
        if (valuesRostering[key]) {
          Object.keys(valuesRostering[key]).forEach(key2 => {
            if (valuesRostering[key].full_time_availability) {
              valRostering = true
            } else {
              Object.keys(valuesRostering[key][key2]).forEach(obj => {
                if (valuesRostering[key][key2][obj].from_to) {
                  valRostering = true
                }
              })
            }
          })
        }
      })
    }

    if (personalDetaisFormsValues.name && personalDetaisFormsValues.last_name && personalDetaisFormsValues.date_of_birth && personalDetaisFormsValues.gender && personalDetaisFormsValues.phone_number
      && personalDetaisFormsValues.email_address && (personalDetaisFormsValues.profile_pic || personalDetaisFormsValues.profile_pic) && residentialAddressFromsValues.street_address && residentialAddressFromsValues.city && residentialAddressFromsValues.state_id && residentialAddressFromsValues.zip_code
      && residentialAddressFromsValues.country && emergencyContactFromsValues.ec_full_name && emergencyContactFromsValues.relationship
      && (emergencyContactFromsValues.ec_mobile_number || emergencyContactFromsValues.ec_email) && emergencyContactFromsValues.ec_street_address && emergencyContactFromsValues.ec_city && emergencyContactFromsValues.ec_state && emergencyContactFromsValues.ec_zip_code && emergencyContactFromsValues.ec_country) {
      this.state.userDetailsSection = true
    }

    if (superFundFormsValues.is_super_ac) {
      if (payrollDetailsFormsValues.tfn && bankAccountFormsValues.bank_ac_name && bankAccountFormsValues.bank_name && bankAccountFormsValues.bank_bsb
        && bankAccountFormsValues.bank_ac_number) {
        this.state.userPayrollSection = true
      }
    } else {
      if (payrollDetailsFormsValues.tfn && superFundFormsValues.fund_name && superFundFormsValues.fund_abn && superFundFormsValues.phone_number
        && superFundFormsValues.bsb && superFundFormsValues.ac_no && superFundFormsValues.ac_name && bankAccountFormsValues.bank_ac_name && bankAccountFormsValues.bank_name && bankAccountFormsValues.bank_bsb
        && bankAccountFormsValues.bank_ac_number) {
        this.state.userPayrollSection = true
      }
    }
    var multi = 0;
    if (props.profile[0] && props.profile[0].licences && props.profile[0].licences.length && props.profile[0].licences.length > 0) {
      this.state.userLicencesSection = true
      multi++
    }

    if (props.profile[0] && props.profile[0].medical_declarations) {
      this.state.userMedicalSection = true
      multi++
    } else {
      if (valuesMedical.accept_job_requirement && valuesMedical.signature_file) {
        this.state.userMedicalSection = true
        multi++
      }
    }

    if (valRostering) {
      this.state.userRosteringSection = true
      multi++
    }

    let percentagePayroll
    if (this.props.one_course_completed) {
      this.state.userInductionTrainingSection = true
      multi++
    }

    let payrollDetailsForm = Object.keys(payrollDetailsFormsValues)
      .filter(key =>
        ((key === 'tfn' && !payrollDetailsFormsErrors.tfn))
      );
    let superFundForm
    if (!superFundFormsValues.is_super_ac) {
      superFundForm = Object.keys(superFundFormsValues)
        .filter(key =>
        ((key === 'fund_name' && !superFundFormsErrors.fund_name) || (key === 'fund_abn' && !superFundFormsErrors.fund_abn)
          || (key === 'phone_number' && !superFundFormsErrors.phone_number) || (key === 'bsb' && !superFundFormsErrors.bsb) ||
          (key === 'ac_no' && !superFundFormsErrors.ac_no) || (key === 'ac_name' && !superFundFormsErrors.ac_name)
        )
        );
    }
    let bankAccountForm = Object.keys(bankAccountFormsValues)
      .filter(key =>
      ((key === 'bank_ac_name' && !bankAccountFormsErrors.bank_ac_name) || (key === 'bank_name' && !bankAccountFormsErrors.bank_name)
        || (key === 'bank_bsb' && !bankAccountFormsErrors.bank_bsb) || (key === 'bank_ac_number' && !bankAccountFormsErrors.bank_ac_number)
      )
      );

    let percentageDetailsFrom = Object.keys(personalDetaisFormsValues)
      .filter(key =>
      ((key === 'name' && !personalDetailsFormErrors.name) || (key === 'last_name' && !personalDetailsFormErrors.last_name) || (key === 'date_of_birth' && !personalDetailsFormErrors.date_of_birth) || (key === 'gender' && !personalDetailsFormErrors.gender) ||
        (key === 'phone_number' && !personalDetailsFormErrors.phone_number) || (key === 'email_address' && !personalDetailsFormErrors.email_address) || (key === 'profile_pic')
      )
      );
    let percentageResidentailAddressFrom = Object.keys(residentialAddressFromsValues)
      .filter(key =>
      ((key === 'street_address' && !residentialAddressFromsErrors.street_address) || (key === 'city' && !residentialAddressFromsErrors.city) ||
        (key === 'state' && !residentialAddressFromsErrors.state) || (key === 'zip_code' && !residentialAddressFromsErrors.zip_code) || (key === 'country' && !residentialAddressFromsErrors.country)
      )
      );
    let percentageEmergencyContactFromsValues = Object.keys(emergencyContactFromsValues)
      .filter(key =>
      ((key === 'ec_full_name' && !emergencyContactFormsErrors.ec_full_name) || (key === 'relationship' && !emergencyContactFormsErrors.relationship) ||
        (key === 'ec_mobile_number' && !emergencyContactFormsErrors.ec_mobile_number) || (key === 'ec_email' && !emergencyContactFormsErrors.ec_email) || (key === 'ec_street_address' && !emergencyContactFormsErrors.ec_street_address) ||
        (key === 'ec_city' && !emergencyContactFormsErrors.ec_city) || (key === 'ec_state' && !emergencyContactFormsErrors.ec_state) || (key === 'ec_zip_code' && !emergencyContactFormsErrors.ec_zip_code) || (key === 'ec_country' && !emergencyContactFormsErrors.ec_country)
      )
      );

    var percentageValues
    if (superFundForm) {
      percentageValues = percentageDetailsFrom.length + percentageResidentailAddressFrom.length + percentageEmergencyContactFromsValues.length + payrollDetailsForm.length + superFundForm.length + bankAccountForm.length
    } else {
      percentageValues = percentageDetailsFrom.length + percentageResidentailAddressFrom.length + percentageEmergencyContactFromsValues.length + payrollDetailsForm.length + bankAccountForm.length
    }
    if (superFundFormsValues.is_super_ac) {
      this.state.formCompletion = Math.round((percentageValues / 26) * 60) + (multi * 10);
    } else {
      this.state.formCompletion = Math.round((percentageValues / 32) * 60) + (multi * 10);
    }
    this.profileComplete = Math.round(this.state.formCompletion)
    this.profileComplete && this.props.dispatch({ type: 'PROFILE_COMPLETE', payload: this.profileComplete });
  }

  changeTabKey = (key) => {
    this.key = key
    this.setState({
      currentKey: key,
      personalDetails: 'block',
      personalProfileDetails: 'block',
      addNewLicence: 'none',
      editLicence: 'none',
      certificatesLicences: 'none',
      addLeave: 'none',
      addNewLeaveForm: 'none',
      viewPersonalDetails: 'none',
      uploadLoader: false
    })
    if (key === '4') {
      this.setState({
        certificatesLicences: 'block'
      })
    }

    if (key === '5') {
      this.setState({
        certificatesLicences: 'block',
        addLeave: 'block'
      })
    }
  }


  dynamicTabContent = () => {
    if (this.state.currentKey === '1') {
      return null
    }
    else
      return null;
  }

  onSubmit = (formData) => {

  }

  // more info
  editMenu = (
    <Menu>
      <Menu.Item onClick={this.handleEditClick}>{Strings.menu_edit_org}</Menu.Item>
    </Menu>
  )

  // profile pic loader
  profileLoaderView = (
    <div className="profile-loader">
      <div class="sk-circle">
        <div class="sk-circle1 sk-child"></div>
        <div class="sk-circle2 sk-child"></div>
        <div class="sk-circle3 sk-child"></div>
        <div class="sk-circle4 sk-child"></div>
        <div class="sk-circle5 sk-child"></div>
        <div class="sk-circle6 sk-child"></div>
        <div class="sk-circle7 sk-child"></div>
        <div class="sk-circle8 sk-child"></div>
        <div class="sk-circle9 sk-child"></div>
        <div class="sk-circle10 sk-child"></div>
        <div class="sk-circle11 sk-child"></div>
        <div class="sk-circle12 sk-child"></div>
      </div>
    </div>
  )

  viewDetails = () => {
    this.setState({
      viewPersonalDetails: 'block',
      personalDetails: 'none',
      // personDetails: data
    })
  }

  editPerson = () => {
    this.setState({
      viewPersonalDetails: 'none',
      personalDetails: 'block',
      // personDetails: data
    })
  }

  addNewLicence = () => {
    this.setState({
      addNewLicence: 'block',
      // personalDetails: 'none',
      personalProfileDetails: 'none',
      viewPersonalDetails: 'none',
      editLicence: 'none'

    })
  }

  editLicence = (show, data) => {
    this.setState({
      editLicence: show,
      addNewLicence: 'none',
      // personalDetails: 'none',
      personalProfileDetails: 'none',
      viewPersonalDetails: 'none',
      licencesDetails: {
        ...data,
        licenseType: data.type && data.licence_type ? { label: data.licence_type, value: data.type } : null,
        expiry_date: data.expiry_date ? moment.utc(data.expiry_date).format("YYYY-MM-DD") : null,
        issued_date: data.issued_date ? moment.utc(data.issued_date).format("YYYY-MM-DD") : null
      }
    })
  }

  addLeave = (show) => {
    this.setState({
      addLeave: show,
      // personalDetails: 'none',
      personalProfileDetails: 'none',
      viewPersonalDetails: 'none'
    })
  }

  addNewLeave = () => {
    this.setState({
      addNewLeaveForm: 'block',
      addNewLicence: 'none',
      personalProfileDetails: 'none',
      certificatesLicences: 'none',
      addLeave: 'none',
      leaveDeatails: {},
      editLicence: 'none'
    })
  }

  editLeave = (leaves) => {
    this.setState({
      addNewLeaveForm: 'block',
      addNewLicence: 'none',
      personalProfileDetails: 'none',
      certificatesLicences: 'none',
      addLeave: 'none',
      leaveDeatails: leaves
    })
  }

  handleCancel = (key) => {
    if (this.key === '3') {
      this.setState({
        addNewLicence: 'none',
        personalProfileDetails: 'block',
        // personalDetails: 'none',
        viewPersonalDetails: 'none',
        addNewLeaveForm: 'none',
        certificatesLicences: 'none',
        addLeave: 'none',
        editLicence: 'none'
      })
    }
    if (this.key === '5') {
      this.setState({
        addNewLicence: 'none',
        personalProfileDetails: 'block',
        // personalDetails: 'none',
        viewPersonalDetails: 'none',
        addNewLeaveForm: 'none',
        certificatesLicences: 'block',
        addLeave: 'block',
        editLicence: 'none',

      })
    }
    this.props.reset();
  }

  removeFile = () => this.setState({ file: '' });

  resetState = () => {
    this.setState({ ...INIT_STATE });
  };

  mounting = async () => {
    await loadModels();
  };

  handleFileChange = async file => {
    this.resetState();
    await this.setState({
      imageURL: URL.createObjectURL(file),
      loading: true
    });
    await this.handleImageChange();
  };



  getImageDimension = imageURL => {
    let img = new Image();
    img.src = imageURL;
    img.onload = () => {
      let HEIGHT = (this.state.WIDTH * img.height) / img.width;
      this.setState({
        HEIGHT,
        imageDimension: {
          width: img.width,
          height: img.height
        }
      });
    };
  };

  handleImageChange = async (image = this.state.imageURL) => {
    await this.getImageDimension(image);
    await getFullFaceDescription(image).then(fullDesc => {
      if (fullDesc.length > 1 || fullDesc.length === 0) {
        notification.error({
          message: Strings.error_title,
          description: 'Invalid Profile Photo!',
          onClick: () => { },
          className: 'ant-error'
        });
        this.setState({ uploadLoader: false });
        return;
      } else if (this.state.imageDimension.width < 150 && this.state.imageDimension.height < 150) {
        notification.error({
          message: Strings.error_title,
          description: 'Upload Profile Photo with size of 150 X 150 px',
          onClick: () => { },
          className: 'ant-error'
        });
        this.setState({ uploadLoader: false });
        return;
      } else {
        const canvas = createCanvas(this.state.imageDimension.width, this.state.imageDimension.height)
        const ctx = canvas.getContext('2d');


        var imageURL = ''
        loadImage(this.state.imageURL).then((image1) => {
          ctx.shadowColor = '#000';
          ctx.shadowBlur = 20;
          ctx.shadowOffsetX = 0;
          ctx.shadowOffsetY = 0;

          // no crop
          let cropParam = this.cropBoxDimention(fullDesc[0].detection, this.state.imageDimension.width, this.state.imageDimension.height)

          // elementToDraw, cropX, cropY, width, height, x, y, width, height
          //ctx.drawImage(image1, cropParam.cropX, cropParam.cropY, cropParam.cropWidth, cropParam.cropHeight, 0, 0, this.state.WIDTH, this.state.WIDTH);

          // cropped
          ctx.drawImage(image1, cropParam.cropX, cropParam.cropY, cropParam.cropWidth, cropParam.cropHeight, 0, 0, cropParam.cropWidth, cropParam.cropHeight);

          imageURL = canvas.toDataURL()

          loadImage(imageURL).then((image2) => {
            const canvas2 = createCanvas(cropParam.cropWidth, cropParam.cropHeight)
            const ctx2 = canvas2.getContext('2d');
            ctx2.shadowColor = '#000';
            ctx2.shadowBlur = 20;
            ctx2.shadowOffsetX = 0;
            ctx2.shadowOffsetY = 0;
            ctx2.drawImage(image2, 0, 0, cropParam.cropWidth, cropParam.cropHeight, 0, 0, cropParam.cropWidth, cropParam.cropHeight);
            imageURL = canvas2.toDataURL()
            this.setState({ imageURL });
            this.uploadProfImage()
          });


        }).catch((error) => {
        });
        this.setState({ fullDesc, uploadLoader: false });
      }
    });
  };

  cropBoxDimention = (detection, imageWidth, imageHeight) => {
    const relativeBox = detection.relativeBox;
    const dimension = detection._imageDims;
    let referSize = dimension._width > dimension._height ? dimension._height : dimension._width
    let _X = imageWidth * relativeBox._x;
    let _Y =
      (relativeBox._y * imageWidth * dimension._height) / dimension._width;
    let _W = imageWidth * relativeBox.width;
    let _H =
      (relativeBox.height * imageHeight * dimension._height) /
      dimension._width;
    //return { cropX: _X, cropY: _Y, cropWidth: _W, cropHeight: _H }

    return {
      cropX: _X - (_X * 0.33), cropY: _Y - (_Y),
      cropWidth: _W + (_X * 0.66), cropHeight: _H + (_Y * 2)
    }
  }

  uploadProfImage = () => {
    this.setState({ uploadLoader: false })
    let finalFormData = new FormData();
    let binarydata = atob(this.state.imageURL.split(',')[1]);
    let array = []
    let i = 0
    while (i < binarydata.length) {
      array.push(binarydata.charCodeAt(i))
      i++
    };
    let newFile = new Blob([new Uint8Array(array)], { type: 'image/png' })
    finalFormData.append('profile_pic', newFile)
    return this.props.userActions.uploadProfileImage(finalFormData)
      .then((flag) => {
        notification.success({
          message: Strings.success_title,
          description: flag,
          onClick: () => { },
          className: 'ant-success'
        });
        this.props.userActions.getOrgUserDetails(this.org_user_id, this.org_user_name);
      }).catch((error) => {
        if (error.status === VALIDATE_STATUS) {
          notification.warning({
            message: Strings.validate_title,
            description: error && error.data && typeof error.data.message == 'string'
              ? error.data.message : Strings.generic_validate,
            onClick: () => { },
            className: 'ant-warning'
          });
        } else {
          notification.error({
            message: Strings.error_title,
            description: error && error.data && error.data.message && typeof error.data.message == 'string'
              ? error.data.message : Strings.generic_error,
            onClick: () => { },
            className: 'ant-error'
          });
        }
      });
  }

  onProfileUpdate = () => {
    this.setState({ openModal: false });
    this.props.userActions.getOrgUserDetails(this.org_user_id, this.org_user_name);
  }

  render() {

    let userPermissions = JSON.parse(getStorage(ACCESS_CONTROL))["userManagement"]
    var accessControl = userPermissions && userPermissions.permissions ? userPermissions.permissions : [];
    /**Permissions for the module */
    var permissions = {
      sf_org_update_user_permission: accessControl.findIndex(acess => acess.control_name === 'sf_org_update_user_permission'),
      sf_org_update_personalDetails: accessControl.findIndex(acess => acess.control_name === 'sf_personal_details_controller_update_personal_details'),
      sf_org_view_personalDetails: accessControl.findIndex(acess => acess.control_name === 'sf_personal_details_controller_get_personal_details'),
      sf_orf_update_payrollDetails: accessControl.findIndex(acess => acess.control_name === 'sf_payroll_details_controller_update_payroll_details'),
      sf_orf_view_payrollDetails: accessControl.findIndex(acess => acess.control_name === 'sf_payroll_details_controller_view_payroll_details'),
      sf_orf_update_licenses: accessControl.findIndex(acess => acess.control_name === 'sf_edit_licenses_controller_edit_licenses'),
      sf_orf_view_licenses: accessControl.findIndex(acess => acess.control_name === 'sf_view_licenses_controller_view_licenses'),
      sf_orf_view_medicalDeclaration: accessControl.findIndex(acess => acess.control_name === 'sf_medical_declaration_controller_view_medical_declarat'),
    }

    const { handleSubmit, profile, licenceType, formValuesPesonalDetailsForm } = this.props;
    this.userDetails = profile[0]


    const props = {
      name: 'file',
      accept: ".jpeg,.jpg,.png",
      // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      headers: {
        authorization: 'authorization-text',
      },
      // fileList: [this.state.file],
      showUploadList: false,
      beforeUpload: file => {
        this.setState({ uploadLoader: true })
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt5M = file.size / 1024 / 1024 < 2;

        if (!isJpgOrPng) {
          this.setState({ uploadLoader: false })
          notification.error({
            message: Strings.error_title,
            description: 'You can only upload JPG/PNG file!',
            onClick: () => { },
            className: 'ant-error'
          });
        } else if (!isLt5M) {
          this.setState({ uploadLoader: false })
          notification.error({
            message: Strings.error_title,
            description: 'Image must smaller than 2MB!',
            onClick: () => { },
            className: 'ant-error'
          });
        } else {
          const start = async () => {
            this.mounting()
            await this.handleFileChange(file)
          }
          Promise.all([
            this.mounting()
          ]).then(start)
        }
        return false;
      },
      onChange: info => {
        if (info.file.status !== 'uploading') {
        }
        // if (info.file.status === 'done') {
        //     message.success(`${info.file.name} file uploaded successfully`);
        //     getBase64(info.file.originFileObj, imageUrl =>
        //         this.setState({
        //             file: imageUrl,
        //         }),
        //     );
        // } else if (info.file.status === 'error') {
        //     message.error(`${info.file.name} file upload failed.`);
        // }
      },
      onRemove: this.removeFile
    };

    const renderTabBar = (props, DefaultTabBar) => (

      <>
        <div className="d-flex align-items-center">
          <DefaultTabBar {...props} style={{ flex: "1 1 auto" }} />
          <div style={{ flex: "0 0 100px" }} className="text-center">
            <div>
              <div className={`${styles.ProfilePic} profile-picture`}>
                {this.userDetails && this.userDetails.profile_pic ? (
                  <img src={this.userDetails.profile_pic} />
                ) : (
                  <div style={{ background: "#eaeaea", height: "100%", width: "100%", borderRadius: "50%" }}></div>
                )}
                {permissions.sf_org_update_personalDetails !== -1 || this.props.location.pathname === "/dashboard/profile" ? (
                  <Button style={{ position: "absolute", top: "80%", right: "10%", left: "unset" }} onClick={() => this.setState({ openModal: true })}>
                    <i class="fa fa-pencil"></i>
                  </Button>) : ""
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );

    return (
      <div>
        <div className="profile-cover">
          <div className={styles.ProfileBack}>
            <div className={styles.ProfileContainer}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 py-3">
                    <div className={styles.PageHead}>Profile</div>
                    <Tabs className="profile-v2-tabs" renderTabBar={renderTabBar} onChange={(key) => this.changeTabKey(key)} activeKey={this.state.currentKey} type="card">
                      {permissions.sf_org_view_personalDetails !== -1 || this.props.location.pathname === "/dashboard/profile" ?
                        <TabPane tab={
                          <div className={styles.JobTab}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className={styles.JobTabIcon}>
                              <path fill="currentColor" d="M319 806h322v-60H319v60Zm0-170h322v-60H319v60Zm-99 340q-24 0-42-18t-18-42V236q0-24 18-42t42-18h361l219 219v521q0 24-18 42t-42 18H220Zm331-554h189L551 236v186Z" />
                            </svg>
                            <div className={styles.TabText}>
                              Personal Details
                            </div>
                          </div>
                        } key="1" className="job-board-custom-tab">
                          {this.state.personalDetails === 'block' ?
                            <>
                              <PersonalDetails
                                initialValues={this.userDetails}
                                onPersonDetails={(data) => this.viewDetails()}
                                location={this.props.location}
                                userDetails={this.userDetails}
                              />
                            </>
                            :
                            ''
                          }
                          {this.state.viewPersonalDetails === 'block' ?
                            <div className="tab-wraper">
                              <ViewPersonalDetails
                                // initialValues={this.state.personDetails}
                                onPersonEdit={this.editPerson}
                              />
                            </div>
                            :
                            ''
                          }
                        </TabPane> : null}

                      {permissions.sf_orf_view_payrollDetails !== -1 || this.props.location.pathname === "/dashboard/profile" ?
                        <TabPane tab={
                          <div className={styles.JobTab}>
                            <svg className={styles.JobTabIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960">
                              <path fill="currentColor" d="M451 936v-84q-57-10-93.5-43.5T305 724l56-23q17 48 49 71.5t77 23.5q48 0 79-24t31-66q0-44-27.5-68T466 589q-72-23-107.5-61T323 433q0-55 35.5-92t92.5-42v-83h60v83q45 5 77.5 29.5T638 391l-56 24q-14-32-37.5-46.5T483 354q-46 0-73 21t-27 57q0 38 30 61.5T524 542q68 21 100.5 60.5T657 702q0 63-37 101.5T511 853v83h-60Z" />
                            </svg>
                            <div className={styles.TabText}>
                              Payroll Details
                            </div>
                          </div>
                        } key="2">
                          <div><PayrollDetails userDetails={this.userDetails} userId={this.org_user_id} userName={this.org_user_name} /></div>
                        </TabPane> : null}

                      {permissions.sf_orf_view_licenses !== -1 || this.props.location.pathname === "/dashboard/profile" ?
                        <TabPane tab={
                          <div className={styles.JobTab}>
                            <svg className={styles.JobTabIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960">
                              <path fill="currentColor" d="M180 936q-24 0-42-18t-18-42V276q0-24 18-42t42-18h207q-2-37 26-66.5t67-29.5q39 0 67 29.5t26 66.5h207q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm300-702q13 0 21.5-8.5T510 204q0-13-8.5-21.5T480 174q-13 0-21.5 8.5T450 204q0 13 8.5 21.5T480 234Zm2 421q58 0 98-40t40-98q0-58-40-98t-98-40q-58 0-98 40t-40 98q0 58 40 98t98 40ZM180 876h600v-37q-60-56-136-90.5T480 714q-88 0-164 34.5T180 839v37Z" />
                            </svg>
                            <div className={styles.TabText}>
                              Licenses
                            </div>
                          </div>
                        } key="3" className="">
                          <div><Licences userId={this.org_user_id} userName={this.org_user_name} initialValues={this.userDetails && this.userDetails.licences} reset={this.props.reset}
                            onPropsSet={(show, data) => data ? this.editLicence(show, data) : this.addNewLicence(show)} /></div>
                        </TabPane> : null}

                      {permissions.sf_orf_view_medicalDeclaration !== -1 || this.props.location.pathname === "/dashboard/profile" ?
                        <TabPane tab={
                          <div className={styles.JobTab}>
                            <svg className={styles.JobTabIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960">
                              <path fill="currentColor" d="M217 958 98 839q-19-17-19-43t19-43l564-564q18-18 43-17.5t43 17.5l119 119q17 18 17.5 43T867 394L303 958q-17 19-43 19t-43-19Zm287-158 205-205 158 158q18 17.573 18 42.786Q885 821 867 839L743 963q-17 17-40.5 14.5T662 958L504 800Zm-21-114q16.575 0 27.788-11.213Q522 663.575 522 647t-11.212-27.787Q499.575 608 483 608q-16.575 0-27.788 11.213Q444 630.425 444 647t11.212 27.787Q466.425 686 483 686Zm-74-74q16.575 0 27.788-11.213Q448 589.575 448 573t-11.212-27.787Q425.575 534 409 534q-16.575 0-27.788 11.213Q370 556.425 370 573t11.212 27.787Q392.425 612 409 612Zm147 0q16.575 0 27.787-11.213Q595 589.575 595 573t-11.213-27.787Q572.575 534 556 534t-27.787 11.213Q517 556.425 517 573t11.213 27.787Q539.425 612 556 612Zm-299-58L95 392q-17-17-18-39.5T93 313l124-124q17.573-18 42.786-18Q285 171 303 189l158 158-204 207Zm226-15q16.575 0 27.788-11.213Q522 516.575 522 500t-11.212-27.787Q499.575 461 483 461q-16.575 0-27.788 11.213Q444 483.425 444 500t11.212 27.787Q466.425 539 483 539Z" />
                            </svg>
                            <div className={styles.TabText}>
                              Medical Declaration
                            </div>
                          </div>
                        } key="4">
                          <div className="tab-wraper">
                            <MedicalDeclaration
                              userDetails={this.props.history.location.userDetails}
                              initialValues={this.userDetails && this.userDetails.medical_declarations}
                              isFromUserManagement={this.props.history.location.source}
                            /></div>
                        </TabPane> : null}

                      {permissions.sf_orf_view_medicalDeclaration !== -1 || this.props.location.pathname === "/dashboard/profile" ?
                        <TabPane tab={
                          <div className={styles.JobTab}>
                            <svg className={styles.JobTabIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960">
                              <path fill="currentColor" d="M80 1056V935h800v121H80Zm82-227V696l373-373 133 133-373 373H162Zm550-417L579 279l84-84q11-13 25-13.5t28 13.5l78 78q13 13 13 27.5T796 328l-84 84Z" />
                            </svg>
                            <div className={styles.TabText}>
                              Signature
                            </div>
                          </div>
                        } key="5">
                          <div>
                            <Signature
                              id={this.userDetails && this.userDetails.id}
                              userId={this.org_user_id}
                              userName={this.org_user_name}
                              sign={this.userDetails && this.userDetails.profile_completion_sign}
                              reset={this.props.reset}
                            />
                          </div>
                        </TabPane> : null}
                    </Tabs>
                  </div>
                </div>
              </div>
              {/* Add licences form start from here */}
              {this.state.addNewLicence === 'block' ?
                <div className="container-fluid">
                  <hr className={styles.DetailsDivider}></hr>
                  <div className="row">
                    <div className="col-12 py-3">
                      <div className={`${styles.ProfileLabel} font-weight-bold`}>Add New Licence</div>
                      <div className="mt-2">
                        <AddNewLicences userId={this.org_user_id} userName={this.org_user_name} onCancel={this.handleCancel} />
                      </div>
                    </div>
                  </div>
                </div> :
                ''
              }

              {/* Edit licences form start from here */}
              {this.state.editLicence === 'block' ?
                <div className="container-fluid">
                  <hr className={styles.DetailsDivider}></hr>
                  <div className="row">
                    <div className="col-12 py-3">
                      <div className={`${styles.ProfileLabel} font-weight-bold`}>Edit Licence</div>
                      <div className="mt-2">
                        <EditLIcences userId={this.org_user_id} userName={this.org_user_name} initialValues={this.state.licencesDetails} onCancel={this.handleCancel} />
                      </div>
                    </div>
                  </div>
                </div> :
                ''
              }
            </div>
          </div>
        </div>
        <Modal
          className="cs-modal-v2"
          centered
          footer={null}
          visible={this.state.openModal}
          maskClosable={false}
          onCancel={() => this.setState({ openModal: false })}
          destroyOnClose={true}
          width={600}
          closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
        >
          <UploadProfilePic onSubmitClose={this.onProfileUpdate} onClose={() => this.setState({ openModal: false })} userDetails={this.userDetails} />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profileManagement && state.profileManagement.profile,
    licenceType: state.profileManagement && state.profileManagement.licenceType,
    courses: state.inductionTraining.courses,
    one_course_completed: state.inductionTraining.one_course_completed,

    formValuesPesonalDetails: state.form && state.form.PesonalDetails,
    formValuesPesonalDetailsForm: state.form && state.form.PesonalDetailsForm,
    formValuesResidentialAddressForm: state.form && state.form.ResidentialAddressForm,
    formValuesExperienceForm: state.form && state.form.ExperienceForm,
    formValuesEmergencyContactForm: state.form && state.form.EmergencyContactForm,

    formValuesViewPesonalDetails: state.form && state.form.ViewPesonalDetails,
    formValuesPayroll: state.form && state.form.PayrollDetails,
    formValuesPayrollForm: state.form && state.form.PayrollDetailsForm,
    formValuesSuperFundForm: state.form && state.form.SuperFundForm,
    formValuesBankAccountForm: state.form && state.form.BankAccountForm,

    formValuesMedical: state.form && state.form.MedicalDeclaration,
    formValuesRostering: state.form && state.form.Rostering ? state.form.Rostering : {},

    formSyncErrorsPesonalDetails: getFormSyncErrors('PesonalDetails')(state),
    formSyncErrorsPesonalDetailsForm: getFormSyncErrors('PesonalDetailsForm')(state),
    formSyncErrorsResidentialAddress: getFormSyncErrors('ResidentialAddressForm')(state),
    formSyncErrorsExperienceForm: getFormSyncErrors('ExperienceForm')(state),
    formSyncErrorsEmergencyContactForm: getFormSyncErrors('EmergencyContactForm')(state),

    formSyncErrorsPayroll: getFormSyncErrors('PayrollDetails')(state),
    formSyncErrorsPayrollFrom: getFormSyncErrors('PayrollDetailsForm')(state),
    formSyncErrorsSuperFundForm: getFormSyncErrors('SuperFundForm')(state),
    formSyncErrorsBankAccountForm: getFormSyncErrors('BankAccountForm')(state),

    formSyncErrorsMedical: getFormSyncErrors('MedicalDeclaration')(state),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    userActions: bindActionCreators(actions, dispatch),
    trainingAction: bindActionCreators(trainingAction, dispatch),
  }
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  reduxForm({
    form: 'Profile', validate, enableReinitialize: true,
    onSubmitFail: (errors, dispatch, sub, props) => {
      handleFocus(errors, "#");
    }
  })
)(Profile)