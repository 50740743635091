import { baseApiInstance, scopeApiInstance } from "../../dataProvider/axiosHelper"
import { ALL_EMAIL_TEMPLATE_URL, EMAIL_TEMPLATES_RESET_PAGE, DELETE_EMAIL_TEMPLATE_URL, EMAIL_TEMPLATES_SET_PAGE, EMAIL_TEMPLATES_SWITCH_PAGE, EMAIL_TEMPLATE_UPDATE_URL, GET_EMAIL_TEMPLATE_CONTENT_V1 } from "../../dataProvider/constant"
import { Strings } from "../../dataProvider/localize";

export const getAllEmailTemplates = (abortController, params = {}, isNew = false) => dispatch => {
  return baseApiInstance.get(ALL_EMAIL_TEMPLATE_URL, { ...abortController, params: params }).then(res => {
    if (res.data && res.data.data && res.data.data.result) {
      dispatch({
        type: EMAIL_TEMPLATES_SET_PAGE,
        payload: {
          page: params.page,
          isNew: isNew,
          result: res.data.data.result,
          search: params.search ? params.search : "",
          requestKey: params.LastEvaluatedKey ? params.LastEvaluatedKey : null
        }
      })
      return Promise.resolve(res.data.data.result);
    } else {
      return Promise.reject(Strings.generic_error)
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const getEmailTemplatePage = (page = 1, isNew = false) => (dispatch, state) => {
  dispatch({
    type: EMAIL_TEMPLATES_SWITCH_PAGE,
    payload: {
      page: page,
      isNew: isNew
    }
  });
  const { emailTemplates } = state();
  let { items } = emailTemplates;
  let lastKey = null;
  try {
    let index = ((page - 1) * 10) - 1;
    lastKey = index <= 0 ? null : { slug: items[index].slug, org_id: items[index].org_id };
  } catch (e) {
    lastKey = null;
  }
  return Promise.resolve(lastKey);
}

export const resetEmailTemplates = () => dispatch => {
  dispatch({ type: EMAIL_TEMPLATES_RESET_PAGE });
  return Promise.resolve();
}

export const updateEmailTemplate = (formData) => () => {
  return baseApiInstance.put(EMAIL_TEMPLATE_UPDATE_URL, formData).then(res => {
    if (res.data && res.data.status) {
      return Promise.resolve("Email Template Updated Succesfully.");
    } else {
      return Promise.reject();
    }
  }).catch((error) => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const getEmailTemplateV2 = (slug) => () => {
  return baseApiInstance.get(GET_EMAIL_TEMPLATE_CONTENT_V1, { params: { slug: slug } }).then(res => {
    if (res.data && res.data.data && res.data.data.result && res.data.data.result.length > 0) {
      return Promise.resolve(res.data.data.result[0]);
    } else {
      return Promise.reject();
    }
  }).catch((error) => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const deleteEmail = (params = {}) => () => {
  return baseApiInstance.delete(DELETE_EMAIL_TEMPLATE_URL, { params: params }).then(async (res) => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject()
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}