import React from "react";
import { Field, FieldArray } from "redux-form";
import { IconButton } from "@mui/material";

import { customInput } from "../../../../common/custom-input";
import JsaActivityConsequenceSection from "./JsaActivityConsequenceSection";
import styles from "../../JobStyle.module.css";

export default function JsaActivityHazardSection({ fields, consequences, likelihoods, loading, loadingData, meta: { error } }) {

  return (
    <div className="py-3">
      {error ? <div className="error mt-2">{error}</div> : ""}
      {fields.map((member, index) => (
        <div key={index} className={styles.HazardBox}>
          <div className="container-fluid">
            <div className="row align-center">
              <div className="col-8">
                <div>
                  <div className={styles.Label}>Hazard</div>
                  <Field disabled={loading} name={`${member}.hazard_name`} component={customInput} className={styles.TextField} />
                </div>
              </div>
              <div className="col-4 d-flex align-items-center justify-end">
                {fields.length > 1 ? (
                  <IconButton disabled={loading} className="p-0" style={{ height: "auto" }} onClick={() => fields.remove(index)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className={styles.MinusIcon} viewBox="0 0 448 512">
                      <path fill="currentColor" d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                    </svg>
                  </IconButton>
                ) : ""}
              </div>
            </div>
            <FieldArray
              name={`${member}.consequences`}
              loadingData={loadingData}
              consequences={consequences}
              likelihoods={likelihoods}
              loading={loading}
              component={JsaActivityConsequenceSection}
            />
          </div>
        </div>
      ))}
      <button className={styles.PrimaryButton} disabled={loading} onClick={() => fields.push({ consequences: [{}] })}>
        Add Hazard
      </button>
    </div>
  )
}