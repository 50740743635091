export const Strings = {

  /**Common */
  error_title: "Error!",
  generic_error: "Something went wrong please try again later",
  save_btn: "Save",
  send_btn: "Send",
  send_for_admin_approval_bnt: "Send For Admin Approval",
  preview_btn: "Preview",
  generate_quote_btn: "Generate Quote",
  email_quote_to_client_bnt: "Email Quote To Client",
  search_placeholder: "Search",
  update_btn: 'Update',
  cancel_btn: 'Cancel',
  img_upload_text: 'Drag & Drop files here or click to select',
  img_upload_text_sc: 'Click or drag here to upload',
  img_drag_drop_text: 'or drag & drop them here',
  choose_file_to_upload: 'Choose file to upload',
  org_wizard_progress: 'Progress',
  network_error: "Network Error",
  profile_title: "Profile",
  change_password: 'Change Password',
  logout_title: "Logout",
  pay_btn: 'Pay',
  success_title: 'Success',
  warning_title: 'Warning',
  validate_title: 'Validation',
  generic_validate: "Please check your entries and try again",
  generic_validate_for_upload: "The file size is more than allowed",
  file_download_error: "There was an error while downloading the File.",


  /** Sign In */
  sign_in_email_label: 'Enter user name/email',
  sign_in_email_placeholder: 'User name/Email',
  sign_in_password_label: 'Password',
  sign_in_password_placeholder: 'Your super password',
  sign_in_forgot_password: 'Forgot your',
  sign_in_forgot_username: 'Username',
  sign_in_password: 'Password',
  sign_in_remember_me: 'Remember Me',
  sign_in_btn_sign_in: 'Sign In',
  sign_in_title_login: 'Login',

  /** Sign Up */
  sign_up_title: 'Set Password',
  sign_up_password_label: 'Enter password',
  sign_up_confirm_password_label: 'Confirm password',
  sign_up_btn_sing_up: 'Sign Up',

  //Reset Password
  reset_pass_title: 'Reset Password',
  reset_pass_placeholder_enter_code: "Enter login code",
  reset_pass_placeholder_enter_password: 'Enter password',
  reset_pass_placeholder_cofirm_password: 'Confirm password',
  reset_pass_btn_sign_up: 'Reset',

  /** Login SMS Code*/
  login_code_title: 'Login',
  login_code_placeholder_enter_code: "Enter login code",
  login_code_btn_sign_in: 'Sign In',

  /** Forgot User Name* */
  forgot_username_title: 'Forgot user name',
  forgot_username_placeholder_enter_username: "Enter email or phone number",
  forgot_username_placeholder_enter_mobile_no: "Please enter mobile number",
  forgot_username_placeholder_enter_email: "Please enter email id",
  forgot_username_btn_send_msg: 'Send Message',

  /** Forgot Password */
  forgot_pass_title: 'Forgot password',
  forgot_pass_placeholder_enter_username: "Enter user name/email",
  forgot_pass_btn_send_msg: 'Send Message',

  /** Verify Account */
  verify_account_msg: "Verified Successfully! Login credential sent on your email address.",
  verify_account_placeholder_otp: 'Enter OTP',
  verify_account_btn_submit: 'Submit',

  //  dashboard/dashboard
  ddash_title: "Dashboard",
  dd_intro_btn: "Intro video",
  dd_welcome_lg_hd: "Welcome to Service Farm, ",
  dd_letsget_sm_hd: "Let's get you started with your Online Induction",
  dd_pls_sure_para: "Please make sure that you have at least half an hour to complete the induction. You can choose to come back later and complete the induction your progress will be saved.",
  dd_mpdal_para: "Vivamus facilisis, tellus luctus dictum auctor, massa mauris elementum enim, ut iaculis neque.",

  /**Organization strings */
  add_org_title: "Add new Organisation",
  org_title: "Organisation",
  org_wizard_detail: "Details",
  org_wizard_industries: "Industries",
  org_wizard_user: 'Users',
  org_wizard_sub: 'Subscription',
  org_add_btn: 'Create New Organisations',
  user_table_name: "Name",
  user_table_email: "Email",
  user_table_phone: "Phone number",
  user_table_role: "User Role",
  user_table_pay_classification: "Pay Classification",
  user_table_sms: 'Sms code',
  user_table_email_code: 'Email code',
  user_role_placeholder: "Select user",
  user_role: "Role",
  add_user_btn: 'Add New User',
  invite_new_user_btn: 'Invite New Users',
  org_detail_card: 'Organisation Details',
  org_name: "Organisation Name",
  org_pri_person: "Primary Contact Person",
  org_phone: "Phone",
  org_email: "Email",
  org_address: "Address",
  org_abn: "ABN/ACN",
  org_user_card: "Users",
  sa_user_card: "Service Agent Users",
  sub_card: "Subscription Level",
  sub_type: "Subscription Type",
  sub_date: "Subscription Start Date",
  view_all_org: "View All Organisations",
  perm_scope_doc: 'Create Quote',
  perm_job_book: 'Book Job in Calendar',
  perm_generate_quote: 'Generate Quote',
  perm_send_quote: 'Send Quote for Approval',
  perm_generate_doc: 'Generate Job Docs',
  role_staff: "Staff",
  perm_card_title: 'User Permission',
  perm_info_text: 'Permissions are visible when a use is selected from the users panel',
  perm_title: 'User Permissions',
  user_add: "Added",
  user_update: 'Updated',
  user_error: 'Error',
  user_dublicate: 'Duplicate',
  user_imported_by: 'Imported by',
  user_started: 'Started',
  user_completed: 'Completed',
  user_import_summary: 'Import Summary for',
  user_bulk_import_que: 'Want to Bulk Import ?',
  user_bulk_import_info: "Easily upload users by importing records in .csv format. You can click here to download simple CSV template ready to import.",
  user_wizard_permissions: 'Permissions',
  user_wizard_user: "Users",
  invite_btn: 'Invite',
  menu_user_mgmt: 'User Management',
  menu_edit_bill: 'Edit billing details',
  menu_update_payment: 'Update payment info',
  menu_edit_org: 'Edit Details',
  bill_card: 'Billing Details',
  edit_org_card: 'Edit Organisation Details',
  org_edit_img: 'Edit Image',
  confirm_quote_update: 'This quote has been approved by the client. Are you sure you want to make changes?',


  // new_client: 'Add New Client',
  clients_title: 'Clients',
  create_client: 'Add New Client',
  view_all_clients: 'View All Clients',
  client_side_panel_idle_title: "Edit Client",
  client_side_panel_idle_info_txt: "Editing a client is possible while viewing the Client.",
  add_contact_btn: 'Add Contact Person',
  user_table_sites: 'Sites',
  user_table_active: 'Active',
  client_contact_phone_number: 'Phone Number',

  // Subscription
  sub_hd_title: "Subscription Details",
  sub_f_name: "Subscription Name",
  sub_f_amount: "Subscription Amount",
  sub_f_period: "Subscription Period",
  sub_add_new_title: "Add new Subscription",
  sub_title: "Subscriptions",
  sub_create_new_btn: "Create New Subscription",
  sub_view_all_btn: "View All subscription",
  sub_details_title: "Subscription Details",
  sub_edit_dtl_title: "Edit Subscription Details",

  // Dashboard
  das_help_center_title: "Help Center",
  das_learning_roadmap: "Learning Roadmap",
  das_video_help: "Video Help",

  // Navigation Menu
  role_manage_title: "Role Management",

  // Profile
  tab_personal_dtl: "Personal Details",
  tab_payroll_dtl: "Payroll Details",
  tab_licences: "Licences",
  tab_medical_dclr: "Medical Declaration",
  tab_rostering: "Rostering",
  tab_induction_training: "Induction & Training",


  /**Roles Management */
  role_title: "Role Management",
  add_role_btn: "Create New Roles",
  add_role_title: 'Add new Role',
  view_all_role: 'View All Roles',
  menu_role_edit: 'Edit Role',
  role_card: 'Role Details',
  role_name: "Role Name",
  role_status: 'Active',
  role_create_success: "Role Created successfully",
  role_update_success: "Role Permission Updated successfully",
  role_job_calendar_lables: "Job Calendar Labels",

  /**  Permission */
  permission: "Permissions",

  // Billing details
  add_pay_meth_title: "Add Payment Method",
  update_pay_title: "Update Payment Info",
  card_name_title: "Name on Card",
  card_number_pay: "Card Number",
  card_expdate: "Expiry",
  card_cvv: "CVC/CVV",
  switch_pay_mtod: "Switch Payment Method",
  save_card: 'Save card',
  update_billing_address_success_msg: "Billing address updated successfully!",
  update_payment_card_success: "Card Updated Successfully!",

  // checkbox style
  read_only_txt: "Read Only",
  modify_txt: "Modify",
  crate_txt: "Create",
  delete_txt: "Delete",
  all_txt: "All",
  admin_txt: "Admin",

  role_edit_title: "Edit Permission",
  role_quote_management_txt: "Quote Management",
  role_finance_management: "Finance Management",
  role_add_new_pesmission: "Add New Permission",

  // profile

  first_name: "First Name",
  middle_name: "Middle Name",
  last_name: "Last Name",
  dob_txt: "Date of Birth",
  gender_txt: "Gender",
  username_txt: "Username",
  mobile_no_txt: "Mobile Number",
  phone_no_txt: "Phone Number",
  primary_email_txt: "Primary Email",
  role_txt: "Role",
  res_address_txt: "Residential Address",
  street_add_txt: "Street Address",
  city_txt: "City/Suburb",
  state_txt: "State",
  zip_code_no: "Zip Code",
  country_txt: "Country",
  lat_long: "Latitude, Longitude",
  experience_txt: "Experience",
  area_of_expertise: "Area of Expertise",
  hours_of_experinace: "Hours of Experience",
  emergency_contact_dtl: "Emergency Contact Details",
  full_name_txt: "Full Name",
  relationship_txt: "Relationship",
  email_txt: "Email",
  address_txt: "Address",
  name_txt: "Name",
  student_usi: 'Student USI',
  visa_number: 'Visa Number',
  passport_number: 'Passport Number',
  // payroll

  employment_commenced_txt: "Employment Commenced",
  employment_status: "Employment Status",
  employment_type: "Employment Type",
  hrs_of_work_week: "Hours of Work / Week",
  regular_hrs_rate: "Regular Hourly Rate",
  overtime_hrs_rate: "Overtime Hourly Rate",
  double_ov_hr_rate: "Double Overtime Hourly Rate",
  agreed_pay_period: "Agreed Pay Period",
  agreed_pay_day: "Agreed Pay Day",
  abn_txt: "ABN/ACN",
  tfn_txt: "TFN",
  super_fund_provider: "Super Fund Provider",
  fund_name: "Super Name",
  fund_abn: "Fund ABN",
  usi_txt: "Super USI",
  payment_method_txt: "Payment Method",
  bsb_txt: "BSB",
  account_no_txt: "Account Number",
  account_name_txt: "Account Name",
  super_acc_check: "I don't have a Super Account",
  bank_account_details: "Bank Account Details",
  bank_acc_para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sagittis dignissim justo, ut finibus orci condimentum nec. Morbi lacinia porta maximus.",
  bank_account_name: "Bank Account Name",
  bank_name: "Bank Name",
  bank_bsb: "Bank BSB",
  bank_account_no: "Bank Account Number",
  confirm_bank_ac_no: "Confirm Bank Account Number",

  // licence
  add_new_licence: "Add New Licence",
  licence_type: "Licence Type",
  licence_number: "Licence Number",
  licence_issued_by: "Licence Issued By",
  licence_issued_date: "Licence Issued Date",
  licence_expiry_date: "Licence Expiry Date",
  choose_file: "Choose File",
  suppoert_file_type: "Supported File Types",
  edit_licence: "Edit Licence",


  // Medical Declaration
  sf_declaration: "Declaration",
  sf_signature: "Signature",
  drage_ur_signature: "Draw Your Signature",
  clear_txt: "Clear",
  certificates_licences: "Certificates/Licences",
  leave_type: "Leave Type",
  comment_txt: "Comment",
  time_of_request: "Time Off Request",
  notify_manager: "Notify Manager(s)",
  add_txt: "Add",

  invite_users: "Invite Users",

  // Service Agent
  new_service_agent: "New Service Agent",
  website_txt: "Website",
  notification_email_txt: "Notification Email",
  service_agent_dtl: "Service Agent Details",
  short_code_txt: "Short Code",
  service_inductries_txt: "Service Industries",
  service_inductry_txt: "Service Industry",
  sub_industry_txt: "Sub-Industry",
  sub_category_txt: "Sub-Category",
  connected_org_txt: "Connected Organisations",
  services_txt: "Services",
  category_txt: "Category",
  edit_industry_menu: "Edit Indstries",
  edit_indstry_details: "Edit Service Agent Industry",
  edit_sa_card: "Edit Service Agent Details",
  instry_header_text: "Industry",
  choose_service: "Choose Service",
  connect_btn: "Connect",
  view_all_service_agents: "View All Service Agents",
  sub_invoice_date: "Invoice Start Date",
  sub_percentage: "Discount %",
  sub_discount_period: "Discount period",
  sub_discount_amt: "Amount after discount",


  //Payment 
  payment_due_text: "Due payment : ",
  new_card_btn: "Different card",
  saved_card_btn: "Saved Card",
  cancel_payment_warning_msg: "Payment is due. Are you sure you want to cancel?",
  confirm_cancel_btn: "No",

  // induction

  company_training_txt: "Company Training",
  skills_training_txt: "Skills Training",
  induction_txt: 'Induction',


  //Service Agent
  add_industry_btn: "Add Industry",
  category_table_header: "Choose Category",
  sub_category_table_header: "Choose Sub-Category",
  add_service_btn: "Add Service",
  sa_add_btn: "Create New Service Agent",

  // Scope Doc
  side_panel_title: "Areas and SWMS",
  side_panel_info_txt: "Areas and SWMS are visible while editing the Quote.",
  scope_doc_title: "Quotes",
  create_new_scope_doc: "Create New Quote",
  client_details: "Client Details",
  client_name: "Client Name",
  quote_request_by: "Quote Request By",
  site_service_details: "Site/Service Details",
  job_name: "Job Name",
  site_name: "Site Name",
  add_site_btn: "Add Site",
  task_name: "Task Name",
  area_txt: "Area",
  estimate_txt: "Estimate",
  description_txt: "Description",
  note_txt: "Notes",
  repeat_txt: "Repeat Every",
  frequency_txt: "Frequency",
  task_duration_txt: "Task Duration",
  duration_txt: "Duration",
  sugg_start_date_txt: "Suggested Start Date",
  start_date_txt: "Start Date",
  expected_start_date_txt: "Suggested Start Date",
  add_task_txt: "Add Task",
  add_new_task_txt: "Add New Task",
  internal_notes: "Internal Notes",
  add_notes_txt: "Add New Note",
  areas_txt: "Areas",
  swms_txt: "SWMS",
  add_new_swms_document: "Add New SWMS Activity",
  update_task_txt: "Update Task",
  date_txt: "Date",
  send_quote_mail: "Email Quote",
  send_quote: "Send Quote",
  scope_doc_create_success: "Quote Created successfully",
  client_create_success: "Client Created successfully",
  person_delete_success: "Contact person deleted.",
  add_scope_doc_title: "Quotes",
  quote_valu_text: "Value",
  quote_select_admin: "Please Select Admin for Approval",
  client_site_delete_success: "Client Site Deleted Successfully.",


  user_list_delete_alert: "Cannot perform deletion, as organisation requires atleast one Admin",
  user_list_confirm_delete_alert: "Do you want to delete this user?",
  confirm_site_delete_alert: "Do you want to delete this site?",

  //swms alerts
  confirm_general_category_delete: "Do you want to delete this SWMS Category?",
  confirm_task_type_delete: "Do you want to delete this Task Type?",
  confirm_consequence_delete: "Do you want to delete this Consequence?",
  confirm_control_delete: "Do you want to delete this SWMS Control?",
  confirm_standard_delete: "Do you want to delete this Standard?",
  confirm_license_training_delete: "Do you want to delete this License and Training?",
  confirm_hazard_delete: "Do you want to delete this Hazard?",
  confirm_activity_delete: "Do you want to delete this SWMS Activity?",

  // email form
  from_txt: "From",
  to_txt: "To",
  subject_txt: "Subject",
  message_txt: "Message",
  send_me_copy_txt: "Send me a copy",

  SERVICE_NAME: "Service Farm",
  SERVICE_EMAIL: "info@service.farm",

  scope_doc_txt_scop_doc: "Scope Document #:",
  scope_doc_txt_bdm: "BDM:",
  scope_doc_edit_client_title: "Update Client Details",
  scope_doc_edit_ssd: "Update Site/Service Details",
  scope_doc_edit_notes: "Update Internal Notes",
  view_more_scope_doc: "View More Scope Docs",
  send_swms_sheet: "Send SWMS Sheet",
  send_sign_off_sheet: "Send Sign Off Sheet",
  send_job_report: "Send Job Report",

  //Email Templates

  email_template_title: "Email Templates",
  add_template_btn: "Add New Template",
  slug_txt: "Slug",
  view_all_templates: "View All Templates",

  // Job Calender
  number_shifts_txt: 'Number of Shifts',
  job_calender_txt: 'Job Calendar',
  internal_calendar_txt: 'Internal calendar',
  quote_txt: 'Quote # :',
  zone_txt: 'Zone',
  group_txt: 'Group',
  job_document_txt: 'Job Document #:',
  job_docs_txt: 'Job Docs',
  accout_manager_txt: 'Account Manager:',
  add_staff_bnt: 'Add Staff',
  preview_and_email_btn: 'Preview & Email',
  email_job_docs_bnt: 'Email Client',
  send_job_document: 'Send Job Document',
  from_profile: 'From Profile',
  job_details_txt: 'Job Details',
  day_shift_txt: 'Day Shift',
  night_shift_txt: 'Night Shift',
  update_job_docs_txt: 'Update Job Doc',

  // Equipment
  equipment: 'Equipment',
  equipment_create_success: "Equipment added successfully",
  equipment_details: 'Equipment Details',
  equipment_name: 'Equipment Name',
  equipment_id: 'Equipment ID',
  view_all_equipments: 'View All Equipments',
  equipment_cost: 'Equipment Cost',
  create_new_equipment: 'Create New Equipment',
  test_tag_history: 'Test & Tag History',
  test_tag_txt: 'Test & Tag',
  create_new_test_tag: 'Create New Test & Tag',
  type_txt: 'Type',
  upload_images: 'Upload Images',
  update_equipment: 'Edit Equipment',
  upload_equipment_image: 'Update Equipment images',
  equipment_update_success: "Equipment updated successfully",
  equipment_test_and_tag_added_success: "Test and tag added successfully",
  equipment_year_of_manufacture: 'Year of Manufacture',
  equipment_manufacture_batch_no: 'Manufacturers Batch No.',
  equipment_serial_no: 'Serial No.',
  equipment_brand: 'Brand',
  equipment_purchase_date: 'Purchase Date',
  equipment_destroy_date: 'Destroy After Date',
  equipment_status: 'Status',
  equipment_notes: 'Notes',

  // Module #6
  validity_txt: 'Validity:',
  alert_txt: 'Alert!',
  add_event_confirmation: 'Are you sure you want to add an event on this date?',
  allocate_manager_success: 'Allocated Account Manager',
  task_save_success: 'Task saved',
  job_time: 'Job Time',
  job_duration: 'Job Duration',
  number_shifts: 'Number of Shifts',
  working: 'Working',
  yard_time: 'Yard Time',
  site_time: 'Site Time',
  finish_time: 'Finish Time',
  all_orgnaisations: 'All Organisations',
  allocate_site_supervisor: 'Allocate Site Supervisor',
  allocate_supervisor: 'Allocate Supervisor',
  show_all_available_staff: 'Show All Available Staff',
  job_sa_group_outsrc_error: "Please select the service agents of checked jobs",
  job_cleaning_report: 'Job Report',
  cleaning_report: 'Job Cleaning Report',
  download_report: 'Download Report',
  email_to_client: 'Email to Client',
  add_new_record: 'Add New Record',
  before_after_photo_slider: 'Before / After',
  job_summary: 'Job Summary',
  job_document: 'Document',
  sign_off: 'Sign-off',
  task_details_success: 'Task details added successfully',
  outsource_task_confirm_delete_alert: 'Do you want to delete this split task?',

  // Job Doc
  contractor_details: "Contractor Details",
  client_company: "Company",
  client_abn: "ABN/ACN",
  client_contact: "Contact",
  client_phone: "Phone",
  job_service_details: "Service Details",
  job_service_agent: "Service Agent",
  job_staff_licenses_inductions: "Staff Licences/Inductions",
  job_staff_name_position: "Name (Position)",
  job_doc_insurance_certificate: "Insurance Certificates & SWMS",
  validity: 'Validity',
  validity_swms: "Validity (Number of Days Link Valid)",
  extend_job_bnt: 'Extend Job',
  job_date: "Date",
  job_start_date: "Start Date",
  job_end_date: "End Date",
  job_day: "Day",
  job_start: "Start",
  job_finish: "Finish",
  job_scope: "Scope",
  job_area: "Area",
  job_site_requirements: "Site Requirements",
  service_agent_not_assign: "Please assign service agent to tasks.",
  job_not_started: "Not Started",
  job_started: "Started",
  start_job: "Start Job",
  restart_job: "Restart Job",
  complete_job: "Completed",
  job_approved_admin: "Approved by Admin",
  job_doc_update_success: "Job Doc Updated Successfully.",

  //Photos/Docs upload//
  photos_docs_upload_info: "You can upload multiple images and document files. Supported formats are jpeg, pdf, docs, png, with size <2mb. ",
  photos_docs_upload_heading: "Upload Photos/Docs",
  job_document_title: "Job Documents",
  link_expire_day: "Link expires in",
  job_id: "Job ID",
  client_address: "Client Address",
  job_site_contact_name_number: "Site Contact Name / Number",
  job_client_number: "Client Number",
  job_purchase_order_number: "Purchase Order Number",
  job_invoiced: "Job Invoiced",

  // Sign SWMS
  sign_swms: 'Sign SWMS',
  safe_work_method_statement_swms: 'Safe Work Method Statement (SWMS)',
  abn: 'ABN/ACN',
  phone: 'Phone',
  site_details: 'Site Details',
  swms_no: 'SWMS No',
  staff_details: 'Staff Details',
  swms_document: 'SWMS Activity',
  signatures: 'Signatures',
  add_member: 'Add Member',
  lock_signature_process: 'Lock Signature Process',
  Signoff: 'Signoff',

  // Job Sign Off Sheet
  job_sign_off_sheet: 'Job Sign Off Sheet',
  sign_off_sheet: 'Sign Off Sheet',
  site_address: 'Site Address',
  site_contact_name: 'Site Contact Name',
  site_contact_number: 'Site Contact Number',
  scope_of_work: 'Scope of Work',
  job_accept_status: 'Job Accept Status',
  job_approval_status: 'Job Approval Status',

  amend_quote_rebook: 'Rebook Job',

  details_to_be_filled: 'Details to be filled by Client',
  upload_client_signed_document: 'Upload Client Signed Document',
  save_sign_complete: 'Save Sign & Complete',
  feegback_txt: 'Feedback',

  // Hazard Report
  position_txt: 'Position',
  manager_name: "Manager's Name",
  contact_number: 'Contact Number',
  email_address: 'Email Address',
  location_txt: 'Location',

  // Incident Reports
  priority: 'Priority',
  assigned_to: 'Assigned To',
  due_on: 'Due On',
  general_information: 'General Information',
  entered_by: 'Entered By',
  responsible_manager: 'Responsible Manager',
  closeout_manager: 'Closeout Manager',
  admin_details: 'Admin Details',
  employee_reporting: 'Employee Reporting',
  date_of_report: 'Date of Report',
  time_of_report: 'Time of Report',
  date_Time_of_report: 'Date & Time of Report',
  incident_details: 'Incident Details',
  date_of_incident: 'Date of Incident',
  time_txt: 'Time',
  division: 'Division',
  business_unit: 'Business Unit',
  company_txt: 'Company',
  project_txt: 'Project',
  is_this_work_related: 'Is this Work Related?',
  yes_txt: 'Yes',
  no_txt: 'No',
  description: 'Description',
  incident_categories: 'Incident Categories',
  view_all_reports_txt: 'View all Reports',

  //Completetd Jobs Management
  view_all_jobs_txt: 'View all Jobs',

  //Certificates Management
  view_all_certi_txt: 'View all certificates',

  //Miscellaneous
  view_all_mail_txt: 'View All Emails',
  /* PDF Template Management */
  pdf_template_title: "PDF Templates",
  add_pdf_template_btn: "Create New Template",
  edit_pdf_template_btn: "Edit Template",
  pdf_template_details: "PDF Template Details",
  view_all_pdf: 'View All PDF Templates',
  pdf_template_name: 'PDF Template Name',
  pdf_content: 'Content',
  pdf_template_status: 'Active',
  edit_template_btn: "Edit Template",
  slug: 'Slug',

  /* SMS Temlate */
  sms_template_title: "SMS Template",
  add_sms_template_btn: "Create New SMS",
  sms_view_all_btn: "View all template.",
  edit_sms_template_btn: "Edit SMS Template",
  sms_template_name: "SMS Template Name",
  sms_template_status: 'Active',
  edit_sms_template_title: 'Edit SMS Template',
  sms_template_card: 'SMS Template Details',
  sms_template_content: 'Content',

  /* Timesheets */
  view_all_timesheets: 'View all timesheets',

  //  induction & Training
  induction_training_title: "Induction",
  induction_training: "Induction & Training",
  my_courses: "My Courses",
  expired_course: "Expired",
  to_do_course: "Todo",
  view_all_induction: "View All Inuctions",
  induction_view_details_btn: "View Details",
  induction_status_completed: "Completed",
  induction_status_todo: "To Do",
  induction_status_inprogress: "In Progress",
  induction_status_expired: "Expired",

  //Broadcast
  broadcast_title: 'Broadcast',
  pay_rates_title: 'Pay Rates',
  add_broadcast_btn: 'Add New Broadcast',
  broadcast_title_txt: 'Title',
  broadcast_roles: 'Roles',
  body_txt: 'Body',
  view_all_broadcast: 'View All Broadcast',
  broadcast_attachment_txt: 'Attachment',
  broadcast_date_time: 'Send Date/Time',
  ind_create_course: "Create Course",
  ind_add_new_course: "Add New Course",
  ind_name: "Name",
  pay_roll_title: 'Pay Roll',


  // Placeholders -----------------------

  title_broadcast: 'Title',
  roles_broadcast: 'Roles',

  name_org: 'Full name',
  email_address_org: 'email@address.com',
  phone_number_org: 'Number for contact and SMS',
  role_id_org: 'Role',

  billing_address_org: 'Billing address',
  billing_email_address_org: 'email@address.com',

  card_number_upi: 'xxxx xxxx xxxx xxxx',
  card_name_upi: 'Card name',
  card_expiry_month_upi: 'mm',
  card_expiry_year_upi: 'yy',

  primary_contact_person_sdcd: 'Contact name',
  name_sdcd: 'Client name',
  email_sdcd: 'email@address.com',
  address_sdcd: 'Client address',
  abn_acn_sdcd: 'xx xxx xxx xxx',
  job_name_sdcd: 'Job name',

  site_name_sd_site: 'Site name',
  street_address_sd_site: 'Street',
  city_sd_site: 'City',
  state_sd_site: 'State',

  task_name_sd_task: 'Task name',
  area_name_sd_task: 'Area name',
  description_sd_task: 'Task description',

  note_internal: 'Internal notes are not visible to client',

  equipment_id_scd: 'Select equipment',
  equipment_cost_scd: 'Cost',
  equipment_note: 'Note',
  name_role_mng: 'Role name',

  name_acc_pd: 'First name',
  middle_name_acc_pd: 'Middle name',
  last_name_acc_pd: 'Last name',
  email_address_acc_pd: 'email@address.com',
  street_address_acc_pd: 'Street',
  city_acc_pd: 'City',
  state_acc_pd: 'State',
  sub_category_id_acc_pd: 'Select area of expertise',
  hours_of_experience_acc_pd: 'Hours',
  ec_full_name_acc_pd: 'Full name',
  relationship_acc_pd: 'e.g. Spouse, partner, mother',
  ec_email_acc_pd: 'email@address.com',

  abn_payrole: 'xx xxx xxx xxx',
  tfn_payrole: 'xxx xxx xxx',
  fund_name_payrole: 'Provider name',
  fund_abn_payrole: 'xx xxx xxx xxx',
  usi_payrole: 'Provider ID',
  ac_no_payrole: 'Provider account #',
  ac_name_payrole: 'Provider account name',
  bsb_payrole: 'xxx xxx',

  bank_bsb_payrole: 'xxx xxx',
  bank_ac_name_payrole: 'Bank account name',
  bank_name_payrole: 'Bank name',
  bank_ac_number_payrole: 'Account number',
  cnf_bank_ac_no_payrole: 'Account number',

  type_licen: 'Select type',
  number_licen: 'Licence number',
  issued_by_licen: 'Issuing body',

  leave_type_rost: 'Select type',
  comment_leave_rost: 'Comment to send to manager',

  email_address_sad: 'email@address.com',
  primary_person_sad: 'Contact name',
  notification_email_sad: 'email@address.com',
  abn_acn_sad: 'xx xxx xxx xxx',
  name_sad: 'Service Agent name',

  name_sau: 'Full name',
  email_address_sau: 'email@address.com',
  phone_number_sau: 'Number for contact and SMS',
  role_id_sau: 'Role',

  subscription_id_sa: 'Select a subscription',
  sub_discount_sa: '% to be discounted',
  sub_period_sa: 'No. of days applied',

  name_ued: 'Name',
  type_ued: 'Type',
  equ_group_ued: 'Group',
  equipment_id_ued: 'ID',
  cost_ued: 'Cost',
  eq_manufacture_batch_no: 'Batch No.',
  eq_serial_no: 'Serial No.',
  eq_brand: 'Brand',

  test_type_ceq: 'Test type',
  tester_ceq: 'Full name',
  license_type_ceq: 'Licence type',
  result_ceq: 'Result',

  address_clnt: 'Client address',
  name_clnt: 'Client name',
  website_client: 'Client Website',
  invoicing_email_client: "Invoicing Email",
  business_telephone: "Business Telephone Number",
  abn_acn_clnt: 'xx xxx xxx xxx',
  contact_name_clnt: 'Contact name',
  email_clnt: 'email@address.com',
  site_name_clnt: 'Site name',
  street_address_clnt: 'Street',
  city_clnt: 'City',
  state_clnt: 'State',

  scope_jobd: 'Scope',
  area_jobd: 'Areas',
  site_requirements_jobd: 'Requirements',

  temp_name_sms: 'Select an SMS template',

  name_org_c: 'Certificate name / type',
  description_org_c: 'Description of certificate',
  certificate_number_org_c: 'Certificate number',

  email_template_id_temp: 'Select an email template',
  from_email_temp: 'email@address.com',
  subject_email_temp: 'Email subject',

  area_job_rep: 'Area',
  note_job_rep: 'Note',
  photo_taken_by_whom_job_rep: 'Person submitting photo',

  location_hazd: 'Specific location of the hazard',
  description_hazd: 'Detailed description of the hazard',
  immediate_action_taken_hazd: 'What steps were taken to prevent harm?',

  location_incd_rep: 'Specific location of the incident',
  description_incd_rep: 'Detailed description of the incident',
  what_was_done_following_the_incident: 'Detailed description of what was done after the incident occurred',
  name_incd_rep: 'Full name',
  type_of_person_incd_rep: 'Contractor/employee/bystander etc',
  other_details_incd_rep: 'Contact information / other',
  position_incd_rep: 'Position',
  contact_details_incd_rep: 'Contact information',
  required_action_incd_rep: 'Action',
  who_is_responsible: 'Assignee',
  authorisor_name: 'Person authorising the content of this incident report',

  number_of_shifts_job_clnd: 'Shifts',
  account_manager: 'Select an Account Manager',
  outsourced_budget: '$',
  comment: 'Comment',
  accepting_person: 'Accepting person',
  sign_off_sheet_feedback: 'We appreciate any feedback',
  number_of_shifts: 'Shifts',
  completed_job_accept: 'Approve Completed Job',
  status: 'Status',
  job_calendar_templates: 'Job Calendar Labels',
  staff_name: 'Staff name',

  rebook_btn: 'Rebook',
  user_payroll: 'User Payroll',

  ///pay rate timesheet field label
  contract_typeLabel: 'Contract Type',
  hourly_pay_rate_ordinaryLabel: 'Hourly Pay Rate (Ordinary hours)',
  early_morning_afternoon_non_permanent_night_shiftLabel: 'Early Morning, afternoon & non permanent night shift Mon - Fri',
  night_shift_permanentLabel: 'Night shift Permanent Mon - Fri',
  saturdayLabel: 'Saturday',
  sundayLabel: 'Sunday',
  public_holidayLabel: 'Public Holiday',
  overtime_monday_to_saturday_first_2hoursLabel: 'Overtime Mon to Sat (first 2 hours)',
  overtime_monday_to_saturday_after_2hoursLabel: 'Overtime Mon to Sat (after 2 hours)',
  overtime_sundayLabel: 'Overtime Sunday',
  overtime_public_holidayLabel: 'Overtime Public Holiday',
  ewp_allowanceLabel: 'EWP Allowance',
  wah_allowanceLabel: 'WAH Allowance',
  height_allowance_cleaning_upto_22floorLabel: 'Height allowance cleaning up to 22 floor',
  height_allowance_cleaning_above_22floorLabel: 'Height allowance cleaning above to 22 floor',
  cold_places_allowanceLabel: 'Cold places allowance',
  hotPlaces_allowance_between_46and54_degreesLabel: 'Hot places allowance between 46 & 54 degrees',
  hot_places_allowance_exceeding_54degreesLabel: 'Hot places allowance exceeding 54 degrees',
  broken_shift_allowanceLabel: 'Broken shift allowance (shift)',
  first_aid_allowanceLabel: 'First aid allowance',
  leading_hand_allowance_1to10_employeesLabel: 'Leading hand allowance 1 to 10 employees',
  leading_hand_allowance_11to20_employeesLabel: 'Leading hand allowance 11 to 20 employees',
  leading_hand_allowance_over_20_employeesLabel: 'Leading hand allowance over 20 employees',
  refuse_collection_allowanceLabel: 'Refuse collection allowance',
  toilet_cleaning_allowanceLabel: 'Toilet cleaning allowance',
  meal_allowanceLabel: 'Meal allowance',
  vehicle_allowance_motor_vehicleLabel: 'Vehicle allowance motor vehicle',
  vehicle_allowance_motor_cycleLabel: 'Vehicle allowance motor cycle',

  not_have_any_payrate: "User do not have any payrate",
  payroll_timesheet: 'Timesheet',

}

export const validationString = {
  // Clients
  latitude: 'Please enter the latitude',
  longitude: 'Please enter the  longitude.',
  /* Auth validations */
  user_name_empty: "Please enter username/email.",
  user_name_len: "Password must be at least 8 characters.",
  pass_empty: "Please enter password.",
  pass_len: "Password must be at least 8 characters.",
  con_pass_empty: 'Please confirm your password.',
  con_pass_match: 'Password doesn\'t match',
  valid_code_empty: 'Please enter login code',
  valid_code_number: 'Must be number',
  full_name_empty: 'Enter full name',
  email_empty: 'Enter proper email address',
  email_invalid: 'Invalid email address',
  old_pass_empty: 'Please enter your current password.',
  new_pass_empty: "Please enter a new password.",
  /* Job details validation */
  job_start_date_empty: "Required.",
  job_time_select: 'Please select at least one job time.',
  job_duration_select: 'Please add at least one shift.',
  shift_date_empty: "Required",
  yard_time_empty: "Required",
  site_time_empty: "Required",
  finish_time_empty: "Required",
  supervisor_empty: "Required",
  supervisor_invalid: "Invalid, please select from dropdown",
  site_supervisor_empty: "Required",
  site_supervisor_invalid: "Invalid, please select from dropdown",

  /* add license validation */
  lic_type_empty: 'Please Select License Type.',
  lic_num_empty: 'This is a required field',
  issue_by_empty: 'This is a required field',
  issue_date_empty: 'This is a required field',
  expiry_date_empty: 'This is a required field',

  /* Payment validation */
  card_name_empty: "Please enter the card name.",
  card_num_empty: "Please enter the card number.",
  card_num_invalid: "Invalid card number.",
  card_expiry_empty: "Please enter the card expiry date.",
  card_cvn_empty: "Please enter the card CVC/CVV.",
  card_title_empty: "Please select title.",
  card_expiry_invalid: "Invalid card expiry",
  card_cvn_invalid: "Invalid CVC/CVV",
  bill_email_empty: "Please enter an email address.",
  bill_email_invalid: "Invalid email address.",
  bill_address_empty: "Please enter the billing address. ",

  /* email quote validation */
  to_email_empty: 'This is a required field',
  to_email_invalid: "Invalid email address",
  from_email_empty: 'This is a required field',
  from_email_invalid: "Invalid email address",
  cc_email_invalid: "Invalid email address",
  bcc_email_invalid: "Invalid email address",
  subject_empty: 'This is a required field',
  from_profile_empty: 'This is a required field',
  validity_empty: 'This is a required field',

  /* Equipment validation */
  eqp_name_empty: 'Please enter equipment name.',
  eqp_id_empty: 'Please enter equipment ID.',
  eqp_type_empty: 'Please enter equipment type.',
  eqp_grp_empty: 'Please enter equipment group.',
  eqp_cost_empty: 'Please enter equipment cost.',
  test_date_empty: 'Please enter the test date.',
  test_type_empty: 'Please enter the test type.',
  tester_empty: 'Please enter the tester\'s name.',
  tester_lic_type_empty: 'Please enter the licence type.',
  tester_lic_expiry_empty: 'Please enter the licence expiry.',
  test_result_empty: 'Please enter the test result.',
  nxt_test_date: 'Please enter the next test date.',

  /* Organization validation */
  org_name_empty: 'Please enter the organisation name.',
  org_primary_person_empty: 'Please enter the primary contact name.',
  org_phone_number_empty: 'Please enter a contact phone number.',
  org_phone_number_invalid: 'Invalid phone number.',
  org_email_empty: 'Please enter a contact email address.',
  org_email_invalid: 'Invalid email address.',
  org_abn_acn_empty: 'Please enter an ABN/ACN.',
  org_abn_acn_invlid: 'Invalid ABN/ACN.',

  org_user_name_empty: 'Please enter the user\'s name.',
  org_user_role_empty: 'This is a required field',
  org_user_email_empty: 'Please enter the user\'s email address.',
  org_user_email_invalid: 'Invalid email address.',
  org_user_phone_number_empty: 'Please enter the user\'s mobile number. ',
  org_user_phone_number_invalid: 'Invalid phone number.',

  /* Profile Payroll Details Validation */

  /* Role validation */
  role_name_empty: 'Please enter a name for the role.',

  /* Scope-Doc validation */
  scope_doc_isRequired: 'This is a required field.',
  scope_doc_atLeastOne: 'Please select at least one of these.',
  scope_doc_isPhoneNumber: 'Invalid phone number.',
  scope_doc_isEmail: 'Invalid email address.',
  scope_doc_isNumber: 'Must be a number.',
  scope_doc_isValidABN: 'Invalid ABN.',
  scope_doc_isTaskDateRequired: 'Please enter start date.',

  scope_doc_client_name_empty: 'Please enter a client name.',
  scope_doc_primary_contact_person_empty: 'Please enter a client contact name.',
  scope_doc_task_name_empty: 'This is a required field.',
  scope_doc_areas_empty: 'This is a required field.',
  scope_doc_name_empty: 'This is a required field.',
  scope_doc_phone_empty: 'Please enter a contact phone number.',
  scope_doc_phone_invalid: 'Invalid phone number.',
  scope_doc_email_empty: 'Please enter a contact email address.',
  scope_doc_email_invalid: 'Invalid email address.',
  scope_doc_address_empty: 'Please enter the client\'s address.',
  scope_doc_abn_acn_empty: 'Please enter an ABN/ACN.',
  scope_doc_abn_acn_invalid: 'Invalid ABN/ACN.',
  scope_doc_quote_requested_by_empty: 'This is a required field.',
  scope_doc_job_name_empty: 'Please enter a name for the job.',

  scope_doc_site_name_empty: 'Please enter a site name.',
  scope_doc_site_address_empty: 'Please enter the street address.',
  scope_doc_site_city_empty: 'Please enter the city.',
  scope_doc_site_state_empty: 'Please enter the state.',
  scope_doc_site_zip_code_empty: 'Please enter the zip code.',
  scope_doc_site_country_empty: 'Please select the country.',
  scope_doc_site_task_name_empty: 'Please enter a task name.',
  scope_doc_site_area_empty: 'Please enter an area.',
  scope_doc_site_estimate_empty: 'Please complete the estimate.',

  /** Client Validations */

  client_name_empty: 'Please enter a client name.',
  client_business_phone_empty: 'Please enter a business phone number.',
  client_website_empty: 'Please enter a website for the client',
  client_email_address_empty: 'Please enter the client\'s email address',

  /* Hazard report validate */

  haz_location_empty: 'Please enter a location.',
  haz_desc_empty: 'Please enter a description.',
  haz_date_empty: 'Please enter the date.',
  haz_category_empty: 'Please select at least one category.',
  haz_action_empty: 'Please enter action taken.',
  haz_likelihood_empty: 'Please select a likelihood.',
  haz_Consequence_empty: 'Please select a consequence.',
  haz_responsible_empty: 'Please select a responsible person.',
  haz_responsible_invalid: "Please select a valid responsible person.",
  haz_follow_up_empty: 'This is a required field',

  /* Profile validation */
  profile_first_name_empty: 'Please enter your first name.',
  profile_middle_name_empty: 'Please enter your middle name.',
  profile_last_name_empty: 'Please enter your last name.',
  profile_date_of_birth_empty: 'Please select your date of birth.',
  profile_gender_empty: 'Please select your gender.',
  profile_country_code_empty: 'This is a required field.',
  profile_phone_number_empty: 'Please enter your mobile number.',
  profile_phone_number_invalid: 'Invalid phone number.',
  profile_email_address_empty: 'Please enter your email address.',
  profile_email_address_invalid: 'Invalid email address.',
  profile_street_address_empty: 'Please enter your street address.',
  profile_city_empty: 'Please enter your city.',
  profile_state_empty: 'Please enter your state.',
  profile_zip_code_empty: 'Please enter your zip code.',
  profile_country_empty: 'Please select your country.',
  profile_ec_full_name_empty: 'Please enter a name.',
  profile_relationship_empty: 'Please enter your relationship.',
  profile_ec_mobile_number_empty: 'Please enter a contact number.',
  profile_ec_mobile_number_invalid: 'Invalid phone number.',
  profile_ec_email_empty: 'Please enter an email address.',
  profile_ec_email_invalid: 'Invalid email address.',
  profile_ec_street_address_empty: 'Please enter a street address.',
  profile_ec_city_empty: 'Please enter a city.',
  profile_ec_state_empty: 'Please enter a state.',
  profile_ec_zip_code_empty: 'Please enter a zip code.',
  profile_ec_country_empty: 'Please select a country.',
  profile_sub_category_empty: 'Please select an area of expertise.',
  profile_hours_of_experience_empty: 'Please enter hours of experience.',

  /* Profile Payroll Validation */
  tfn_empty: 'Please enter your TFN.',
  tfn_invalid: 'Invalid TFN.',
  fund_name_empty: 'Please enter your fund name.',
  fund_abn_empty: 'Please enter your fund ABN.',
  fund_abn_invalid: 'Invalid ABN.',
  usi_empty: 'Please enter your fund USI.',
  payroll_phone_number_empty: 'Please enter your fund\'s phone number.',
  payroll_phone_number_invalid: 'Invalid phone number.',
  payment_method_empty: 'Please select payment method.',
  bsb_empty: 'Please enter your fund\'s BSB.',
  bsb_invalid: 'Invalid BSB.',
  fund_ac_no_empty: 'Please enter your fund\'s account number.',
  fund_ac_no_invalid: 'Invalid account number.',
  fund_ac_name_empty: 'Please enter your fund\'s account name.',
  fund_ac_name_invalid: 'Invalid account name.',
  bank_ac_name_empty: 'Please enter your account name.',
  bank_ac_name_invalid: 'Invalid bank account name.',
  bank_name_empty: 'Please enter your bank name.',
  bank_name_invalid: 'Invalid bank name.',
  bank_bsb_empty: 'Please enter your BSB.',
  bank_bsb_invalid: 'Invalid BSB.',
  bank_ac_no_empty: 'Please enter your account number.',
  bank_ac_no_invalid: 'Invalid account number.',
  cnf_bank_ac_no_empty: 'Please re-enter your account number.',
  cnf_bank_ac_no_invalid: 'Invalid account number.',
  cnf_bank_ac_no_dismatch: 'Bank account number does not match.',
  /* Incident Report */
  inci_date_empty: 'Please enter the date.',
  inci_time_empty: 'Please enter the time.',
  inci_location_empty: 'Please enter the location.',
  inci_description_empty: 'Please enter a description.',
  inci_category_empty: 'Please select at least one category.',
  inci_what_done_empty: 'Please enter a description.',
  person_name_empty: 'Please enter a name.',
  person_type_empty: 'Please enter the type of person.',
  person_other_empty: 'Please enter contact/other details.',
  wit_name_empty: 'Please enter a name.',
  wit_type_empty: 'Please enter the type of person.',
  wit_other_empty: 'Please enter contact/other details.',
  consult_name_empty: 'Please enter a name.',
  consult_position_empty: 'Please enter the position.',
  consult_contact_empty: 'Please enter contact details.',
  action_risk_empty: 'Please select a control type.',
  action_required_empty: 'Please enter any required action.',
  action_who_responsible_empty: 'Please enter a responsible person.',
  action_date_complete_empty: 'Please enter a date.',
  action_auth_empty: 'Please enter a name.',
  action_sign_empty: 'Please sign here and click save.',
  action_responsible_invalid: 'Please select a valid responsible person',
  inci_assigned_to_empty: 'Please enter a person.',
  inci_due_date_empty: 'Please enter due empty',
  inci_comment_empty: 'Please enter comment',

  //Assign Account Manager Form
  acc_mgr_start_date_required_txt: 'Please enter start date',
  acc_mgr_end_date_required_txt: 'Please enter end date',
  acc_mgr_txt: 'Please select an Account Manager',
  acc_mgr_budget_txt: 'Please enter a budget.',

  /* Job report validate */
  job_report_empty: 'Add atleast one jobReport',
  job_report_area_validate: 'Please enter the area.',
  job_report_photo_taken_validate: 'Please enter a name.',
  Job_doc_decline_reason: "Please provide job decline reason",

  /* Medical validate */
  accept_job_requirment: 'Please accept this.',

  /* Service agent */
  sa_name_empty: 'Please enter the organisation name.',
  sa_primary_person_empty: 'Please enter the primary contact name.',
  sa_phone_number_empty: 'Please enter a contact phone number.',
  sa_phone_number_invalid: 'Invalid phone number.',
  sa_email_empty: 'Please enter a contact email address.',
  sa_email_invalid: 'Invalid email address.',
  sa_abn_acn_empty: 'Please enter an ABN/ACN.',
  sa_abn_acn_invlid: 'Invalid ABN/ACN.',
  sa_user_name_empty: 'Please enter the user\'s name.',
  sa_user_role_empty: 'This is a required field',
  sa_user_email_empty: 'Please enter the user\'s email address.',
  sa_user_email_invalid: 'Invalid email address.',
  sa_user_phone_number_empty: 'Please enter the user\'s mobile number. ',
  sa_user_phone_number_invalid: 'Invalid phone number.',
  sa_website_empty: "Invalid website",
  sa_industry_empty: 'Please select industry',

  /* timesheet validate */
  timesheet_name_empty: 'Please select a staff member.',
  timesheet_start_empty: 'Please enter a start time.',
  timesheet_end_empty: 'Please enter an end time.',

  /* SWMS validate */
  swms_name_empty: 'This is a required field.',
  swms_expiry_empty: 'This is a required field.',
  swms_active_empty: 'This is a required field.',
  swms_hazard_empty: 'This is a required field.',
  swms_likelihood_before_controls_empty: 'This is a required field.',
  swms_consequence_before_controls_empty: 'This is a required field.',
  swms_control_measures_empty: 'This is a required field.',
  swms_likelihood_after_controls_empty: 'This is a required field.',
  swms_consequence_after_controls_empty: 'This is a required field.',
  swms_person_responsible_empty: 'This is a required field',

  /* Time Off Request Validate */
  timeoff_start_date_empty: 'This is a required field.',
  timeoff_start_time_empty: 'This is a required field.',
  timeoff_end_date_empty: 'This is a required field.',
  timeoff_end_time_empty: 'This is a required field.',
  timeoff_leave_type_empty: 'This is a required field.',
  timeoff_comment_empty: 'This is a required field.',
  timeoff_notify_manager_empty: 'This is a required field.',

  /**Org Certificates Validations */
  certificate_name_empty: 'Please enter a certificate name.',
  /* PDF template validation */
  pdf_slug_empty: 'Please enter the slug.',
  pdf_temp_name_empty: 'Please enter the template name.',
  pdf_content_empty: 'Please enter the content.',
  /* SMS Template Validation */
  sms_slug_empty: 'Please enter the slug.',
  sms_temp_name_empty: 'Please enter the template name.',
  sms_content_empty: 'Please enter the content.',

  /*Split task validations*/

  split_task_count_error: 'A task must be split into at least two.',
  // user course quiz validation
  user_course_quiz_validation: 'Please solve all question.',

  /* Broacast validations */
  broadcast_title: 'Please enter the title.',
  broadcast_role: 'Please select the role.',
  broadcast_body: 'Please enter the content.',
  broadcast_send_date: 'Please provide date/time.',
  broadcast_attachment: 'Please upload file.',
  //Course Validations
  course_name: 'Please enter the name.',
  course_short_description: 'Please enter the short description.',
  course_detailed_description: 'Please enter the course description.',
  course_modules: 'Please select course module.',
  course_expiration: 'Please enter the expiration.',
  course_org_role: 'Please select organisation roles.',
  course_tags: 'Please select tags.',


  // pay rates validations 
  contractType: 'This is a required field.',
  decimal_value: 'Invalid Rate',

  //Job Allocation
  selectStaff: 'Please select atleast one staff.',

  //report  document
  job_report_document_validate: "Name required"
}
