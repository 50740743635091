import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, notification, Input } from "antd";
import { Strings } from "../../../../../dataProvider/localize";
import { fetchColumns, viewDefaults } from "./config/tableDefinitions";
import { compose, bindActionCreators } from "redux";
import { reduxForm } from "redux-form";
import * as actions from "../../../../../actions/SWMSAction";
import moment from "moment";
export class Chemicals extends Component {
  state = {
    currentPage: 1,
    pageSize: 10,
    editingKey: "",
    addMode: false,
    items: this.props.rows,
    search: ''
  };

  componentDidMount() {
    this.props.action.getOrgSWMS()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentTab !== this.props.currentTab) {
      this.setState({ addMode: false, editingKey: "", search: "" });
      this.props.reset();
      this.setState({ items: this.props.rows });
    }
    if (prevProps.rows !== this.props.rows) {
      this.setState({ items: this.props.rows })
      if(this.state.search && this.state.search.length >= 3) {
        this.onSearch({ target: { value: this.state.search }})
      }
    }
  }

  onSearch = (e) => {
    this.setState({ search: e.target.value })
    let value = e.target.value;
    if (value.trim().length >= 3) {
      let items = this.props.rows;
      let filteredItems = items.filter(i => (i.name !== null && i.name.toString().toLowerCase().includes(value.toLowerCase().trim())) ||
        (i.manufacturer !== null && i.manufacturer.toString().toLowerCase().includes(value.toLowerCase().trim())));
      this.setState({ items: filteredItems });
    } else {
      this.setState({ items: this.props.rows });
    }
  }

  onPageChange = (arg) => {
    this.setState({
      currentPage: arg,
      addMode: false,
      editingKey: "",
    });
    this.props.reset();
  };

  addRow = () => {
    this.setState({ addMode: true, editingKey: "", currentPage: 1 });
  };

  saveRow = (data, rowIndex, editing) => {
    const { formValues, action } = this.props;

    const formData = new FormData();
    let chemical = {}
    let validated = false 
    if (editing) {
      if (!formValues || (!formValues[`name_${data.id}`] || !formValues[`manufacturer_${data.id}`] ||
        !formValues[`issue_date_${data.id}`])) {
          validated = false;
          notification.error({
            message: Strings.error_title,
            description: "All of the Fields are required.",
            className: "ant-error"
          })
      } else {
        validated = true;
        formData.append("id", data.id);
        formData.append("name", formValues[`name_${data.id}`]);
        formData.append("manufacturer", formValues[`manufacturer_${data.id}`]);
        formData.append("active", formValues[`active_${data.id}`] ? 1 : 0);
        formData.append("issue_date_of_msds", moment(formValues[`issue_date_${data.id}`]).format("YYYY-MM-DD"));
        if (formValues.document && formValues.document.length > 0) {
          formData.append("document", formValues.document[0]);
        }
        formData.append("defaults", JSON.stringify(formValues[`defaults_${data.id}`].map(def => def.value)));
        formData.append("hazardous", formValues[`hazardous_${data.id}`]);
        formData.append("document_temp", data.document)
      }
    } else {
      if (!formValues || (!formValues.name || !formValues.active || !formValues.manufacturer ||
        !formValues.issue_date || (!formValues.document || (formValues.document && formValues.document.length < 1)))) {
          validated = false;
          notification.error({
            message: Strings.error_title,
            description: "All of the Fields are required.",
            className: "ant-error"
          })
      }
      else {
        validated = true;
        chemical.name = formValues.name;
        chemical.manufacturer = formValues.manufacturer;
        chemical.issue_date_of_msds = formValues.issue_date;
        chemical.defaults = formValues.defaults ? formValues.defaults.map(def => def.value) : [];
        if (formValues.document && formValues.document.length > 0) {
          chemical.document = formValues.document;
          formData.append("document", formValues.document[0])
        }
        chemical.active = formValues.active ? 1 : 0;
        chemical.hazardous = formValues.hazardous ? 1 : 0;
        formData.append("chemicals", JSON.stringify([chemical]))
      }
    }
    if (validated) {
      if (!editing) {
      action
        .addChemical(formData)
        .then((message) => {
          notification.success({
            message: Strings.success_title,
            description: message ? message : Strings.generic_error,
            onClick: () => {},
            className: "ant-success",
          });
          this.setState({ addMode: false, editingKey: "" });
          this.props.reset();
        })
        .catch((message) => {
          notification.error({
            message: Strings.error_title,
            description: message
              ? Array.isArray(message)
                ? message[0].message
                : Strings.generic_error
              : Strings.generic_error,
            onClick: () => {},
            className: "ant-error",
          });
        });
      } else {
        action 
        .updateChemical(formData)
        .then((message) => {
          notification.success({
            message: Strings.success_title,
            description: message ? message : Strings.generic_error,
            onClick: () => {},
            className: "ant-success",
          });
          this.setState({ addMode: false, editingKey: "" });
          this.props.reset();
        })
        .catch((message) => {
          notification.error({
            message: Strings.error_title,
            description: message
              ? Array.isArray(message)
                ? message[0].message
                : Strings.generic_error
              : Strings.generic_error,
            onClick: () => {},
            className: "ant-error",
          });
        });
      }
    }
  };

  editRow = (data, rowIndex) => {
    this.setState({ editingKey: data.id, addMode: false });
    this.props.change(`name_${data.id}`, data.name);
    this.props.change(`issue_date_${data.id}`, data.issue_date_of_msds);
    this.props.change(`manufacturer_${data.id}`, data.manufacturer);
    this.props.change(`hazardous_${data.id}`, data.hazardous);
    this.props.change(`defaults_${data.id}`, viewDefaults(data.defaults, this.props.orgSWMS, true));
    this.props.change(`active_${data.id}`, data.active);
  };

  changeStatus = (value, data, rowIndex) => {
    if (!this.state.editingKey && !this.state.addMode) {
      const formData = new FormData()
      formData.append("id", data.id);
      formData.append("name", data.name);
      formData.append("manufacturer", data.manufacturer);
      formData.append("active", value ? 1 : 0);
      formData.append("issue_date_of_msds", data.issue_date_of_msds);
      formData.append("defaults", JSON.stringify(JSON.parse(data.defaults)));
      formData.append("hazardous", data.hazardous);
      formData.append("document_temp", data.document)

      this.props.action
        .updateChemical(formData)
        .then((message) => {
          notification.success({
            message: Strings.success_title,
            description: message ? message : Strings.generic_error,
            onClick: () => {},
            className: "ant-success",
          });
        })
        .catch((message) => {
          notification.error({
            message: Strings.error_title,
            description: message
              ? Array.isArray(message)
                ? message[0].message
                : Strings.generic_error
              : Strings.generic_error,
            onClick: () => {},
            className: "ant-error",
          });
        });
    }
  };

  cancel = (data, rowIndex) => {
    this.setState({ addMode: false, editingKey: "" });
    this.props.reset();
  };

  deleteRow = (data, rowIndex) => {
    const formData = {
      id: data.id,
    }

    this.props.action
      .deleteChemical(formData)
      .then((message) => {
        notification.success({
          message: Strings.success_title,
          description: message ? message : Strings.generic_error,
          onClick: () => { },
          className: "ant-success",
        });
      })
      .catch((message) => {
        notification.error({
          message: Strings.error_title,
          description: message
            ? Array.isArray(message)
              ? message[0].message
              : Strings.generic_error
            : Strings.generic_error,
          onClick: () => { },
          className: "ant-error",
        });
      });
  }

  handleFileChange = (fileList) => {
    this.props.change("document", fileList);
  };

  render() {
    const {
      addMode,
      editingKey,
      currentPage,
      pageSize,
      items,
    } = this.state;

    return (
      <div className="new-swms-table">
        <div className="d-flex justify-content-between px-2">
          <div className="">
            <Input placeholder="Search (Chemical and Manufacturer)" value={this.state.search} onChange={this.onSearch} style={{ width: '250px', maxWidth: '500px' }} />
          </div>
          <div>
            <button
              type="button"
              className="bnt bnt-active add-row-swms-bnt"
              onClick={() => this.addRow()}
              disabled={addMode || editingKey}
            >
              Add Rows
            </button>
          </div>
        </div>
        <div
          style={{
            maxWidth: "90vw",
            padding: "0.5rem",
          }}
        ></div>
          <Table
            columns={fetchColumns(
              this.saveRow,
              this.editRow,
              this.cancel,
              this.changeStatus,
              editingKey,
              currentPage,
              this.deleteRow,
              this.props.orgSWMS,
              this.handleFileChange,
              this.props.formValues
          )}
            dataSource={addMode ? [{ key: "addRow" }, ...items] : items}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              onChange: (a) => this.onPageChange(a),
            }}
            bordered
            scroll={{ x: 600 }}
          />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rows: state.swmsReducer.orgSWMS.sds,
  formValues: state.form.ChemicalTable && state.form.ChemicalTable.values,
  orgSWMS: state.swmsReducer.orgSWMS
});

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(actions, dispatch),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "ChemicalTable",
    enableReinitialize: true
  })
)(Chemicals);
