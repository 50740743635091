import React, { Component } from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import styles from "./DraggableItemsListContainer.module.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { draggableSWMSItems } from "../../utils";
import { Tooltip } from "antd";
import * as SWMSMastersActions from "../../../../../actions/SWMSMastersActions";

export class DraggableItemsListContainer extends Component {
  componentDidMount() {
    const {
      ppe,
      tools,
      standards,
      highRiskWork,
      licensesAndTraining,
    } = this.props;

    this.props.actions.initializeDragList({
      tools,
      ppe,
      highRiskWork,
      licensesAndTraining,
      standards,
    });
  }

  render() {
    return (
      <div className="sf-card">
        <div className="sf-card-body">
          <div className={styles.Container}>
            {draggableSWMSItems.map((SWMS) => (
              <div key={`${SWMS.type}`} className={styles.SWMSList}>
                <h1 style={{
                  textAlign: "center",
                  marginBottom: "10px",
                  fontWeight: 600,
                  color: "#777",
                  fontSize: '1rem'
                }}
                >
                  {SWMS.label}
                </h1>
                <div className="droppable-list-area">
                  <Droppable droppableId={`drag_${SWMS.type}`}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={styles.DropArea}
                      >
                        {this.props.dragItems[SWMS.type].map((item, index) => (
                          <div className={styles.DragItemContainer}>
                            <Draggable
                              key={`drag_${SWMS.type}_${item.id}`}
                              draggableId={`drag_${SWMS.type}_${item.id}`}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  data-placement="top"
                                  data-toggle="tooltip"
                                  title={item[SWMS.displayProperty]}
                                >
                                  <p className={styles.ListItem}>
                                    {item[SWMS.displayProperty]}
                                  </p>
                                </div>
                              )}
                            </Draggable>
                            <button type="button" className={styles.AddButton} onClick={() => this.props.addCb(SWMS.type, item, index)}>
                              +
                            </button>
                          </div>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ppe: state.SWMSMasters.ppe,
    tools: state.SWMSMasters.tools,
    standards: state.SWMSMasters.standards,
    highRiskWork: state.SWMSMasters.highRiskWork,
    licensesAndTraining: state.SWMSMasters.licensesAndTraining,
    formValues:
      state.form && state.form.SWMSActivity
        ? state.form.SWMSActivity.values
        : {},
    dragItems: state.SWMSMasters.activityDragItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(SWMSMastersActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DraggableItemsListContainer);
