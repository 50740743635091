import React from "react";
import { CustomSwitch } from "../../../../../common/customSwitch";
import styles from "../../../SWMSMastersContainer.module.css";
import { Tooltip, Popconfirm } from "antd";
import { Field } from "redux-form";
import { Strings } from '../../../../../../dataProvider/localize';

export const fetchColumns = (history, generalCategory, category, taskType, roles, deleteRow, changeStatus, hasAddAccess, hasEditAccess, hasDeleteAccess) => [
  {
    title: "Activity ID",
    dataIndex: "activity_id",
    sorter: (a, b) => a.activity_id < b.activity_id ? -1 : a.activity_id > b.activity_id ? 1 : 0,
    width: 200,
    render: (cellContent, rowData, rowIndex) => (
      <Tooltip
        title={cellContent}
      >
        <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
          {cellContent}
        </div>
      </Tooltip>
    )
  },
  {
    title: "Activity Name",
    dataIndex: "activity",
    sorter: (a, b) => a.activity < b.activity ? -1 : a.activity > b.activity ? 1 : 0,
    width: 200,
    render: (cellContent, rowData, rowIndex) => (
      <Tooltip
        title={cellContent}
      >
        <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
          {cellContent}
        </div>
      </Tooltip>
    )
  },
  {
    title: "SWMS Category",
    dataIndex: "general_category_label",
    width: 200,
    render: (cellContent, rowData, rowIndex) => (
      <Tooltip
        title={cellContent}
      >
        <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
          {cellContent}
        </div>
      </Tooltip>
    )
  },
  {
    title: "SWMS Method",
    dataIndex: "category_label",
    width: 200,
    render: (cellContent, rowData, rowIndex) => {
      return (
        <Tooltip
          title={cellContent}
        >
          <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
            {cellContent}
          </div>
        </Tooltip>
      )
    }
  },
  {
    title: "Person Responsible",
    width: 200,
    render: (cellContent, rowData, rowIndex) => {
      var roleNames = [];
      rowData.swms_person_responsibles.forEach(person => {
        var specifiedRole = roles.find(role => role.id === person.person_responsible_id);
        if(specifiedRole) {
          roleNames.push(specifiedRole)
        }
      })
      return (
        <>
          {roleNames.map((role, index) => {
            return (
              <Tooltip
                title={role.name}
                key={role.id}
              >
                <span>{role.name}{index < (roleNames.length - 1) ? ", " : ""}</span>
              </Tooltip>
            )
          })}
        </>
      )
    }
  },
  {
    title: "Task Type",
    dataIndex: "task_type_label",
    width: 200,
    render: (cellContent, rowData, rowIndex) => (
      <Tooltip
        title={cellContent}
      >
        <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
          {cellContent}
        </div>
      </Tooltip>
    )
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 80,
    render: (cellContent, rowData, rowIndex) => (
      <div className={styles.centeredCell}>
        <Field
          name={`status_${rowData.id}`}
          val={rowData.active}
          size="small"
          component={CustomSwitch}
          onChange={(e, value) => { changeStatus(rowData.id, value) }}
        />
      </div>
    ),
  },
  {
    title: "Action",
    dataIndex: "action",
    width: 100,
    fixed: "right",
    render: (cellContent, rowData, rowIndex) => (
      <div className={styles.spacedBetweenCell}>
        <i
          class="fa fa-pencil"
          style={{
            color: "#03A791",
          }}
          hidden={!hasEditAccess}
          aria-hidden="true"
          onClick={() =>
            history.push({
              pathname: history.location.pathname + "/manage_swms_activity",
              state: { id: rowData.id, mode: "edit" }
            })
          }
        ></i>
        <i
          class="fa fa-files-o"
          style={{
            color: "#03A791",
          }}
          hidden={!hasAddAccess}
          aria-hidden="true"
          onClick={() =>
            history.push({
              pathname: history.location.pathname + "/manage_swms_activity",
              state: { id: rowData.id, mode: "copy" }
            })
          }
        ></i>
        <Popconfirm
          title={Strings.confirm_activity_delete}
          onConfirm={() => deleteRow(rowData.id)}
          placement="topRight"
          okText="Yes"
          cancelText="No"
        >
          <button type="button" className='delete-bnt user-actions' >
            <i
              class="fa fa-trash"
              hidden={!hasDeleteAccess}
              style={{
                color: "#03A791",
              }}
            ></i>
          </button>
        </Popconfirm>
      </div>
    ),
  },
];
