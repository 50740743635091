import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Icon } from 'antd';
import styles from './PreviewTaskImages.module.css';
import ImageLoader from "./ImageLoader";

function PreviewTaskImages(props) {

  const query = new URLSearchParams(props.location.search);
  let photos = JSON.parse(query.get("imgs"))

  const [quoteNumber, setQuoteNumber] = useState(query.get("num"));
  const [jobName, setJobName] = useState(query.get("job"));
  const [taskName, setTaskName] = useState(query.get("task"));
  const [currentImg, setCurrentImg] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState("");
  const [thumbnails, setThumbnails] = useState([]);
  const version = query.get("v");

  useEffect(() => {
    document.title = `Task Images for ${taskName}`
    setLoading(false);
    let thumbs = [];
    photos.forEach(image => {
      if (version && version.toString() === "2") {
        let url = new URL(image.image);
        let thumb = `${url.origin}/resized/100x100${url.pathname}`;
        thumbs.push(
          { thumbnail: thumb, image: image.image, note: image.note });
      } else {
        let url = new URL(image);
        let thumb = `${url.origin}/resized/100x100${url.pathname}`;
        thumbs.push(
          { thumbnail: thumb, image: image, note: "" });
      }
      updateImg(thumbs[0]);
    });
    setThumbnails(thumbs);
  }, []);

  const updateImg = (img) => {
    setCurrentImg(img)
  }

  return (
    <div className={styles.preview_task_images}>
      {loading === true ? (
        // initial Loader Screen
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
                <div className="sk-loder-st">
                  <div className="sk-circle">
                    <div className="sk-circle1 sk-child"></div>
                    <div className="sk-circle2 sk-child"></div>
                    <div className="sk-circle3 sk-child"></div>
                    <div className="sk-circle4 sk-child"></div>
                    <div className="sk-circle5 sk-child"></div>
                    <div className="sk-circle6 sk-child"></div>
                    <div className="sk-circle7 sk-child"></div>
                    <div className="sk-circle8 sk-child"></div>
                    <div className="sk-circle9 sk-child"></div>
                    <div className="sk-circle10 sk-child"></div>
                    <div className="sk-circle11 sk-child"></div>
                    <div className="sk-circle12 sk-child"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : hasError === true ? (
        //Error Screen
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
                <h4 className="h4 text-danger text-center">{error}</h4>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // Task Images
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="h1">Task Images</h1>
              <div className={styles.preview_information}>
                {quoteNumber &&
                  <p>Quote Number: {quoteNumber}</p>
                }
                {jobName &&
                  <p>Job Name: {jobName}</p>
                }
                {taskName &&
                  <p>Task Name: {taskName}</p>
                }
              </div>
              {thumbnails && thumbnails.length > 0 ?
                (
                  <div>
                    <div className={styles.main_imag_holder}>
                      <ImageLoader className={styles.img_main} src={currentImg.image} key={currentImg} alt={taskName} />
                    </div>
                    <div className="text-center border py-2" style={{ minHeight: "42px" }}>{currentImg.note}</div>
                    <div className={styles.preview_carousel}>
                      <div id="previewCarousel" className="carousel slide">
                        <div className="carousel-inner">
                          {thumbnails.map((img, index) => index % 3 === 0 ? (
                            <div className={index === 0 ? 'carousel-item active' : 'carousel-item'} key={index}>
                              <div className="d-flex align-items-center justify-content-center">
                                <div className={styles.preview_carousel_box} onClick={() => updateImg(img)} style={{ margin: "0px 50px" }}>
                                  <img className="img-fluid" alt={taskName} src={img.thumbnail} />
                                </div>
                                {thumbnails[index + 1] &&
                                  <div className={styles.preview_carousel_box} onClick={() => updateImg(thumbnails[index + 1])} style={{ margin: "0px 50px" }} >
                                    <img className="img-fluid" src={thumbnails[index + 1].thumbnail} alt={taskName} />
                                  </div>
                                }
                                {thumbnails[index + 2] &&
                                  <div className={styles.preview_carousel_box} onClick={() => updateImg(thumbnails[index + 2])} style={{ margin: "0px 50px" }}>
                                    <img className="img-fluid" src={thumbnails[index + 2].thumbnail} alt={taskName} />
                                  </div>
                                }
                              </div>
                            </div>
                          ) : "")}
                        </div>
                        <a href="#previewCarousel" className="carousel-control-prev" role="button" data-slide="prev">
                          <Icon type="caret-left" aria-hidden="true" style={{ fontSize: '25px', color: '#03A791' }} />
                        </a>
                        <a href="#previewCarousel" className="carousel-control-next" role="button" data-slide="next">
                          <Icon type="caret-right" aria-hidden="true" style={{ fontSize: '25px', color: '#03A791' }} />
                        </a>
                      </div>
                    </div>
                  </div>
                )
                :
                (
                  <div className="container-fluid">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-12">
                        <div className="d-flex align-items-center justify-content-center" style={{ height: "70vh" }}>
                          <h4 className="h4 text-danger text-center">No Task Images available.</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      )
      }
    </div>
  )
}

export default withRouter(PreviewTaskImages)