import React, { useState } from "react";
import styles from "../../JobStyle.module.css";
import { Collapse, Icon } from 'antd';
import SwmsCategoriesSelection from "./SwmsCategoriesSelection";
import SwmsMethodsSelection from "./SwmsMethodsSelection";

export default function SwmsSelectionModal({ onClose, task }) {

  const { Panel } = Collapse;

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSection, setSelectedSection] = useState("categories");

  const onNext = (categories) => {
    setSelectedCategories([...categories]);
    setSelectedSection("methods");
  }

  const onGoBack = () => {
    setSelectedSection("categories");
  }

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={styles.ModalHeading}>
              SWMS Selection
            </div>
            <hr></hr>
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-12">
            <div>
              <Collapse
                bordered={false}
                activeKey={selectedSection}
                expandIconPosition="right"
                className="custom-section-v2-collapse custom-job-swms-collapse"
                expandIcon={({ isActive }) => (
                  <Icon type="caret-right" rotate={isActive ? 90 : 0} style={{ color: "#2f1f41" }} />)
                }
              >
                <Panel
                  key="categories"
                  className="custom-job-swms-collapse-panel"
                  style={{ border: '0px !important' }}
                  header={<div className={`${styles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>SWMS Categories</div>}
                >
                  <SwmsCategoriesSelection onNext={onNext} task={task} />
                </Panel>
                <Panel
                  key="methods" className="custom-job-swms-collapse-panel"
                  style={{ border: '0px !important' }}
                  header={<div className={`${styles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>SWMS Methods</div>}
                >
                  <SwmsMethodsSelection task={task} onGoBack={onGoBack} onClose={onClose} categories={selectedCategories} />
                </Panel>
              </Collapse>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-right">
            <button className={`${styles.OutlinedButton} ml-1`} onClick={() => onClose()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}