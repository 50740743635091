import React from "react";
import { Radio } from "antd";

const CustomRadioGroupV2 = ({
  input,
  onChange,
  value,
  disabled,
  className = "",
  defaultValue,
  children,
  meta: { touched, error, warning },
}) => (
  <>
    <Radio.Group
      className={className}
      onChange={onChange || input.onChange}
      value={value || input.value}
      defaultValue={defaultValue}
      disabled={disabled}
    >
      {children}
    </Radio.Group>
    {touched &&
      ((error && <span className="error-input">{error}</span>) ||
        (warning && <span>{warning}</span>))}
  </>
);

export default CustomRadioGroupV2;
