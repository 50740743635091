import React, { useEffect } from "react";
import { Icon, notification } from "antd";
import { compose } from "redux";
import { useDispatch, connect } from "react-redux";
import { Field, reduxForm, FieldArray } from 'redux-form'
import { getJobOnSiteInfo, setOnJobSiteInfo } from "../../../../../actions/v2/JobActions";
import styles from "../../JobStyle.module.css";
import { customInput } from "../../../../../components/common/custom-input";
import { validateOnSiteSave } from "../validate"
import { Strings } from '../../../../../dataProvider/localize';
import { IconButton } from "@mui/material";

const renderContacts = ({ fields, meta: { error, submitFailed } }) => (
  <>
    {fields.map((member, index) => (
      <div className="row align-items-start" key={`field-${index}`}>
        <div className="col pt-2">
          <div>
            <Field className={styles.SingleLine}
              name={`${member}.contact_name`}
              placeholder="Contact Name"
              component={customInput}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="col pt-2">
          <div>
            <Field className={styles.SingleLine}
              name={`${member}.phone`}
              placeholder="Contact Phone"
              component={customInput}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="col-1 align-self-start pt-2">
          {(index + 1) === fields.length ?
            <IconButton className={`${styles.iconButton} ${styles.greenButton}`} onClick={() => fields.push({})} style={{ height: "auto" }}>
              <svg style={{ height: "11px", width: "11px", color: "#fff" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor" d="M240 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H176V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H384c17.7 0 32-14.3 32-32s-14.3-32-32-32H240V80z" />
              </svg>
            </IconButton>
            :
            <IconButton className={`${styles.iconButton} ${styles.redButton}`} onClick={() => { fields.remove(index) }} style={{ height: "auto" }}>
              <svg style={{ height: "11px", width: "11px", color: "#fff !important" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor" d="M416 256c0 17.7-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
              </svg>
            </IconButton>
          }
        </div>
      </div>
    ))}
  </>
)

const OnSite = (props) => {

  const dispatch = useDispatch();
  useEffect(() => {
    if (props.jobId) {
      dispatch(getJobOnSiteInfo(props.jobId))
    }
  }, [props.jobId])

  const onSubmit = (values) => {
    let formData = { ...values };
    formData.job_id = props.jobId;
    let site_contacts = [];
    if (formData.job_on_site_contacts && formData.job_on_site_contacts) {
      formData.job_on_site_contacts.forEach(contact => {
        if (contact.contact_name && contact.phone) {
          site_contacts.push(contact);
        }
      });
      formData.site_contacts = site_contacts;
      delete formData.job_on_site_contacts;
    }
    dispatch(setOnJobSiteInfo(formData)).then(message => {
      dispatch(getJobOnSiteInfo(props.jobId))
      notification.success({
        message: Strings.success_title,
        description: message ? message : Strings.generic_error, onClick: () => { },
        className: 'ant-success'
      });
    }).catch(err => {
      notification.error({
        message: Strings.error_title,
        description: err ? err : Strings.generic_error,
        className: 'ant-error'
      })
    })
  }

  return (
    <form onSubmit={props.handleSubmit(onSubmit)}>
      <div className={styles.blankContainer}>
        <div className="container-fluid pr-0">
          <div className="row">
            <div className="col">
              <div className={`${styles.SectionHeading} px-2`}>On-Site Information</div>
              <div className={styles.card}>
                <div className="container-fluid px-0 p-2">
                  <div className="row">
                    <div className="col">
                      <div className={styles.Label}>Site Meeting Point</div>
                      <div>
                        <Field className={styles.SingleLine}
                          name="site_meeting_point"
                          component={customInput}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className={styles.Label}>
                        Site Emergency Muster Point
                      </div>
                      <div>
                        <Field className={styles.SingleLine}
                          name="site_emergency_muster_point"
                          component={customInput}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="col-1"></div>
                  </div>
                  <FieldArray name="job_on_site_contacts" component={renderContacts} />
                  <div className="row justify-content-end">
                    <div className="col-12 pt-3 text-right">
                      <button className={styles.PrimaryButton} disabled={props.pristine}>Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form >
  );
}

const mapStateToProps = (state) => {
  let info = state.Job.onSiteInfo
  if (!info.job_on_site_contacts || info.job_on_site_contacts.length === 0) {
    info.job_on_site_contacts = [{}]
  }
  return { initialValues: info }
}

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: 'JobOnSite',
    validate: validateOnSiteSave,
    enableReinitialize: true
  }))(OnSite)
