import { validationString } from '../../dataProvider/localize'
export const isRequired = value => value ? undefined : validationString.scope_doc_isRequired

export const validate = values => {
  const errors = {}

  if (values && !values.contract_type) {
    errors.contract_type = validationString.contract_type;
  }
  if (values.hourly_pay_rate_ordinary) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.hourly_pay_rate_ordinary)) {
      errors.hourly_pay_rate_ordinary = validationString.decimal_value
    }
  }
  if (values.early_morning_afternoon_non_permanent_night_shift) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.early_morning_afternoon_non_permanent_night_shift)) {
      errors.early_morning_afternoon_non_permanent_night_shift = validationString.decimal_value
    }
  }
  if (values.night_shift_permanent) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.night_shift_permanent)) {
      errors.night_shift_permanent = validationString.decimal_value
    }
  }
  if (values.saturday) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.saturday)) {
      errors.saturday = validationString.decimal_value
    }
  }
  if (values.sunday) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.sunday)) {
      errors.sunday = validationString.decimal_value
    }
  }
  if (values.public_holiday) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.public_holiday)) {
      errors.public_holiday = validationString.decimal_value
    }
  }
  if (values.overtime_monday_to_saturday_first_2hours) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.overtime_monday_to_saturday_first_2hours)) {
      errors.overtime_monday_to_saturday_first_2hours = validationString.decimal_value
    }
  }
  if (values.overtime_monday_to_saturday_after_2hours) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.overtime_monday_to_saturday_after_2hours)) {
      errors.overtime_monday_to_saturday_after_2hours = validationString.decimal_value
    }
  }
  if (values.overtime_sunday) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.overtime_sunday)) {
      errors.overtime_sunday = validationString.decimal_value
    }
  }
  if (values.overtime_public_holiday) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.overtime_public_holiday)) {
      errors.overtime_public_holiday = validationString.decimal_value
    }
  }
  if (values.ewp_allowance) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.ewp_allowance)) {
      errors.ewp_allowance = validationString.decimal_value
    }
  }
  if (values.earlyMorningAfternoonNonPermanentNightShift) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.earlyMorningAfternoonNonPermanentNightShift)) {
      errors.earlyMorningAfternoonNonPermanentNightShift = validationString.decimal_value
    }
  }
  if (values.wah_allowance) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.wah_allowance)) {
      errors.wah_allowance = validationString.decimal_value
    }
  }
  if (values.height_allowance_cleaning_upto_22floor) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.height_allowance_cleaning_upto_22floor)) {
      errors.height_allowance_cleaning_upto_22floor = validationString.decimal_value
    }
  }
  if (values.height_allowance_cleaning_above_22floor) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.height_allowance_cleaning_above_22floor)) {
      errors.height_allowance_cleaning_above_22floor = validationString.decimal_value
    }
  }
  if (values.cold_places_allowance) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.cold_places_allowance)) {
      errors.cold_places_allowance = validationString.decimal_value
    }
  }
  if (values.hotPlaces_allowance_between_46and54_degrees) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.hotPlaces_allowance_between_46and54_degrees)) {
      errors.hotPlaces_allowance_between_46and54_degrees = validationString.decimal_value
    }
  }
  if (values.hot_places_allowance_exceeding_54degrees) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.hot_places_allowance_exceeding_54degrees)) {
      errors.hot_places_allowance_exceeding_54degrees = validationString.decimal_value
    }
  }
  if (values.broken_shift_allowance) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.broken_shift_allowance)) {
      errors.broken_shift_allowance = validationString.decimal_value
    }
  }
  if (values.first_aid_allowance) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.first_aid_allowance)) {
      errors.first_aid_allowance = validationString.decimal_value
    }
  }
  if (values.leading_hand_allowance_1to10_employees) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.leading_hand_allowance_1to10_employees)) {
      errors.leading_hand_allowance_1to10_employees = validationString.decimal_value
    }
  }
  if (values.leading_hand_allowance_11to20_employees) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.leading_hand_allowance_11to20_employees)) {
      errors.leading_hand_allowance_11to20_employees = validationString.decimal_value
    }
  }
  if (values.leading_hand_allowance_over_20_employees) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.leading_hand_allowance_over_20_employees)) {
      errors.leading_hand_allowance_over_20_employees = validationString.decimal_value
    }
  }
  if (values.refuse_collection_allowance) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.refuse_collection_allowance)) {
      errors.refuse_collection_allowance = validationString.decimal_value
    }
  }
  if (values.toilet_cleaning_allowance) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.toilet_cleaning_allowance)) {
      errors.toilet_cleaning_allowance = validationString.decimal_value
    }
  }
  if (values.meal_allowance) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.meal_allowance)) {
      errors.meal_allowance = validationString.decimal_value
    }
  }
  if (values.vehicle_allowance_motor_vehicle) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.vehicle_allowance_motor_vehicle)) {
      errors.vehicleAllowanceMotorVehicle = validationString.decimal_value
    }
  }
  if (values.vehicle_allowance_motor_cycle) {
    if (!/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(values.vehicle_allowance_motor_cycle)) {
      errors.vehicle_allowance_motor_cycle = validationString.decimal_value
    }
  }

  return errors
}