import { PDF_TEMPLATES_RESET_PAGE, PDF_TEMPLATES_SET_PAGE, PDF_TEMPLATES_SWITCH_PAGE } from "../../dataProvider/constant";

const INITIAL_STATE = {
  items: [],
  totalPages: 0,
  currentItems: [],
  LastEvaluatedKey: {},
  hasTotal: false,
  currentPageKey: null,
  lastSearch: ""
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PDF_TEMPLATES_SET_PAGE: {
      let newItems = [...state.items];
      if (action.payload.isNew) {
        newItems = [...newItems, ...action.payload.result.Items];
      } else {
        newItems.splice(((action.payload.page - 1) * 10), action.payload.result.Items.length, ...action.payload.result.Items)
      }
      return {
        ...state,
        ...(action.payload.isNew ? { totalPages: state.totalPages + 1 } : {}),
        items: [...newItems],
        lastSearch: action.payload.search ? action.payload.search : "",
        currentItems: [...action.payload.result.Items],
        LastEvaluatedKey: action.payload.result.LastEvaluatedKey ? action.payload.result.LastEvaluatedKey : null,
        ...(!state.hasTotal ? {
          hasTotal: action.payload.isNew && action.payload.result && !action.payload.result.LastEvaluatedKey ? true : false
        } : {}),
        currentPageKey: action.payload.requestKey ? action.payload.requestKey : null
      }
    }
    case PDF_TEMPLATES_SWITCH_PAGE: {
      let page = action.payload.page
      let newItems = [...state.items.slice((page - 1) * 10, ((page - 1) * 10) + 10)];
      let lastKey = null;
      try {
        let index = ((page - 1) * 10) - 1;
        lastKey = index <= 0 ? null : state.items[index];
      } catch (e) {
        lastKey = null;
      }
      return {
        ...state,
        ...(action.payload.page && action.payload.page <= state.totalPages ? {
          currentItems: [...newItems],
          LastEvaluatedKey: lastKey ? { slug: lastKey.slug, org_id: lastKey.org_id } : {}
        } : {})
      }
    }
    case PDF_TEMPLATES_RESET_PAGE: {
      return { ...INITIAL_STATE }
    }
    default: {
      return { ...state };
    }
  }
}