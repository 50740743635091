import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Modal } from "antd";
import CloseIcon from '@mui/icons-material/Close';

import styles from "../ProfileStyles.module.css";
import UpdateUserTimeZone from "./UpdateUserTimeZone";

export default function UserTimeZone({ userDetails = {}, onReloadUser }) {

  const [editModal, setEditModal] = useState(false);

  const onClose = (v = false) => {
    if(v) {
      onReloadUser();
    }
    setEditModal(false);
  }

  return (
    <div className="row">
      <div className="col-12 px-0 py-2 d-flex align-items-center" style={{ gap: "5px" }}>
        <div title={userDetails && userDetails.user_utc_timezone ? userDetails.user_utc_timezone : "Not Available"}>
          <b>Time Zone:</b> {userDetails && userDetails.user_utc_timezone ? userDetails.user_utc_timezone : "Not Available"}
        </div>
        <div>
          <Tooltip title={"Edit Time Zone"}>
            <span>
              <IconButton onClick={() => setEditModal(true)} className="p-0" style={{ height: "auto", color: "#90bf00" }} >
                <svg xmlns="http://www.w3.org/2000/svg" style={{ height: "14px", width: "14px" }} viewBox="0 0 512 512">
                  <path fill="currentColor" d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
                </svg>
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </div>
      <Modal
        className={`cs-modal-v2`}
        centered
        footer={null}
        visible={editModal}
        onCancel={() => setEditModal(false)}
        afterClose={onClose}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        destroyOnClose={true}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
      >
        <UpdateUserTimeZone
          timeZone={userDetails && userDetails.user_timezone ? userDetails.user_timezone : null}
          utc={userDetails && userDetails.user_utc_timezone ? userDetails.user_utc_timezone : null}
          userName={userDetails && userDetails.user_name ? userDetails.user_name : null}
          onClose={onClose}
        />
      </Modal>
    </div>
  )
}