import React from "react";
import { Field } from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import { Upload, notification, Checkbox } from "antd";
import { reduxForm } from "redux-form";
import * as action from "../../../actions/SAJobMgmtAction";
import { customInput } from "../../common/custom-input";

import { Strings } from "../../../dataProvider/localize";
import { singleValidate } from "../../../utils/Validations/jobReportValidation";
import { getStorage } from "../../../utils/common";
import emailEditor from "../../common/EmailEditor";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  ContentState,
  EditorState,
  convertToRaw,
} from "draft-js";
import {
  VALIDATE_STATUS,
  ADMIN_DETAILS,
} from "../../../dataProvider/constant";
import styles from "../../../components/dashboard/SWMSMasters/SWMSActivity/SWMSActivityContainer.module.css";
import { IconButton } from "@mui/material";
import jobStyles from "../Job/JobStyle.module.css";

class UpdateSingleDocument extends React.Component {
  state = {
    editorState: ''
  }
  handleChange = (fileList, keyName) => {
    this.props.change(`${keyName}`, fileList);
  };

  handlePreUpload = () => {
    return false;
  };

  editorState = (value) => {
    const { initialValues } = this.props;
    var body = value ? value : initialValues.comments;

    const html = body;
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        editorState: editorState,
      });
      return editorState;
    }
  };


  changeEditorState = (editorState, key) => {
    this.props.change(
      key,
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    this.setState({
      editorState,
    });
  };
  onSubmitDoc = async () => {

    const { formValues } = this.props;
    var finalForm = new FormData();

    formValues.document_files &&
      formValues.document_files.forEach((report, reportIndex) => {
        if (report && report.originFileObj) {

          finalForm.append("document_files", report.originFileObj);
        }
      });

    finalForm.append("id", formValues.id);
    var job_id = formValues.job_id;
    finalForm.append("document_name", formValues.document_name);
    finalForm.append("comments", formValues.comments);

    this.props
      .updateJobDocument(finalForm, job_id)
      .then((flag) => {
        this.props.reset();
        this.props.removeInlineUser(this.props.initialValues);
        notification.success({
          message: Strings.success_title,
          description: flag,
          onClick: () => { },
          className: "ant-success",
        });
      })
      .catch((error) => {
        if (error.status == VALIDATE_STATUS) {
          notification.warning({
            message: Strings.validate_title,
            description:
              error && error.data && typeof error.data.message == "string"
                ? error.data.message
                : Strings.generic_validate,
            onClick: () => { },
            className: "ant-warning",
          });
        } else {
          notification.error({
            message: Strings.error_title,
            description:
              error &&
                error.data &&
                error.data.message &&
                typeof error.data.message == "string"
                ? error.data.message
                : Strings.generic_error,
            onClick: () => { },
            className: "ant-error",
          });
        }
      });
  };

  render() {
    const uploadButton = (
      <div className={`${jobStyles.PrimaryText} justify-content-center d-flex align-items-center mt-1`} style={{ columnGap: "5px" }}>
        <i style={{ fontSize: "17px" }} className="material-icons">add_a_photo</i>
        <div>Upload Document</div>
      </div>
    );
    const { handleSubmit, formValues } = this.props;
    return (
      <tr>
        <td>
          <Checkbox className="cs-select-v2"></Checkbox>
        </td>
        <td>
          <Field
            name={`document_name`}
            className={`${jobStyles.InputFieldV2} w-100 mt-1`}
            type="text"
            component={customInput} />
        </td>
        <td>
          <div className="location-afbf-pic py-2">
            <Upload
              listType="pictured"
              fileList={formValues.document_files ? formValues.document_files : []}
              beforeUpload={this.handlePreUpload}
              accept=".pdf,.docx"
              onChange={({ fileList }) =>
                this.handleChange(fileList, "document_files")
              }
            >
              {formValues.document_files
                ? formValues.document_files.length >= 1 : <a
                  target="_blank"
                  className={jobStyles.PrimaryText}
                  href={formValues.shift_report_document}
                  title={formValues.document_name}
                >
                  <i
                    class="fa fa-file-pdf-o mr-2"
                    aria-hidden="true"
                  ></i>
                  {formValues.document_name}{" "}
                </a>}
              {formValues.document_files
                ? formValues.document_files.length >= 1
                  ? null
                  : uploadButton
                : uploadButton}
            </Upload>
          </div>
        </td>
        <td>
          <fieldset className="sf-form no-label">
            <div className={styles.TextEditor}>
              <Field
                name={`comments`}
                type="text"
                component={emailEditor}
                editorState={
                  this.state.editorState === ""
                    ? this.editorState()
                    : this.state.editorState
                }
                onEditorStateChange={(editorState) =>
                  this.changeEditorState(editorState, `comments`)
                }
              />
            </div>
          </fieldset>
        </td>
        <td>
          <div className="d-flex align-items-center" style={{ columnGap: "10px" }}>
            <IconButton type="button" style={{ color: "#c0b4ff" }} onClick={(e) => handleSubmit(this.onSubmitDoc())} className={jobStyles.MuiIconButton}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{ height: "14px", width: "14px", }}>
                <path fill="currentColor" d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
              </svg>
            </IconButton>
            <IconButton type="button" onClick={() => this.props.removeInlineUser(this.props.initialValues)} style={{ color: "red", height: "auto" }} className={jobStyles.MuiIconButton}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style={{ height: "14px", width: "14px" }}>
                <path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
              </svg>
            </IconButton>
          </div>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state, { serviceObject, form }) => {
  return {
    roles: state.roleManagement.roles,
    formValues:
      state.form[form] && state.form[form].values
        ? state.form[form].values
        : {
          photo_taken_by: JSON.parse(getStorage(ADMIN_DETAILS))
            ? JSON.parse(getStorage(ADMIN_DETAILS)).name +
            " " +
            (JSON.parse(getStorage(ADMIN_DETAILS)).last_name
              ? JSON.parse(getStorage(ADMIN_DETAILS)).last_name
              : "")
            : "",
        },
    filePath: state.sAJobMgmt.filePath,
  };
};

export default compose(
  connect(mapStateToProps, action),
  reduxForm({ validate: singleValidate, enableReinitialize: true, pure: false })
)(UpdateSingleDocument);
