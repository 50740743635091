import React, { useState, useEffect } from "react";
import { reduxForm, Field } from "redux-form";
import { CircularProgress } from "@mui/material";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import validator from "validator";

import { customInput } from '../../../common/custom-input';
import { MultiEmailInput } from "../../../common/chipInputRedux";
import EmailEditor from '../../../common/EmailEditor';
import colourStyles from "../../v2FormSelectStyle";

import v2Styles from "../../v2Styles.module.css";

const validate = (values) => {
  let errors = [];
  if (!values.to_field || (values.to_field && values.to_field.length === 0)) {
    errors.to_field = "Requried";
  } else {
    if (values.to_field.some(x => !validator.isEmail(x.value))) {
      errors.to_field = "Contains Invalid Email Address"
    }
  }
  if (values.cc_field && values.cc_field.some(x => !validator.isEmail(x.value))) {
    errors.cc_field = "Contains Invalid Email Address"
  }
  if (values.bcc_field && values.bcc_field.some(x => !validator.isEmail(x.value))) {
    errors.bcc_field = "Contains Invalid Email Address"
  }
  if (!values.subject || (values.subject && validator.isEmpty(values.subject, { ignore_whitespace: true }))) {
    errors.subject = "Required"
  }
  return errors;
}

const EmailJobReportForm = (props) => {

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [emailOptions, setEmailOptions] = useState({
    allowMutiToEmail: true
  });

  useEffect(() => {
    let blocksFromHtml = htmlToDraft(props.initialValues.body);
    if (blocksFromHtml) {
      let contentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks);
      let editorStateVal = EditorState.createWithContent(contentState);
      setEditorState(editorStateVal);
    }
  }, []);

  useEffect(() => {
    if (props.options) {
      setEmailOptions(props.options)
    }
  }, [props.options])

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    let body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    props.change("body", body);
  };

  return (
    <div>
      <form onSubmit={props.handleSubmit} onKeyDown={(e) => e.key !== "Enter"}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className={v2Styles.ModalHeading}>
                Email Job Report to Client
              </div>
              <div className="">
                <hr className="v3-modal-hr" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="pb-3">
                <div className={v2Styles.Label}>From</div>
                <Field
                  name="from_field"
                  type="text"
                  id="name"
                  disabled={true}
                  autoComplete="off"
                  className={v2Styles.TextField}
                  component={customInput} />
              </div>
              <div className="pb-3">
                <div className={v2Styles.Label}>To</div>
                <Field
                  name="to_field"
                  type="text"
                  id="name"
                  isMulti={emailOptions.allowMutiToEmail}
                  autoComplete="off"
                  component={MultiEmailInput}
                  otherProps={{
                    styles: colourStyles,
                    isDisabled: props.loading,
                  }} />
              </div>
              <div className="pb-3">
                <div className={v2Styles.Label}>CC</div>
                <Field
                  name="cc_field"
                  type="text"
                  id="name"
                  autoComplete="off"
                  component={MultiEmailInput}
                  otherProps={{
                    styles: colourStyles,
                    isDisabled: props.loading
                  }} />
              </div>
              <div className="pb-3">
                <div className={v2Styles.Label}>BCC</div>
                <Field
                  name="bcc_field"
                  type="text"
                  id="name"
                  autoComplete="off"
                  component={MultiEmailInput}
                  otherProps={{
                    styles: colourStyles,
                    isDisabled: props.loading
                  }} />
              </div>
              <div className="pb-3">
                <div className={v2Styles.Label}>Subject</div>
                <Field
                  name="subject"
                  type="text"
                  id="name"
                  className={v2Styles.TextField}
                  autoComplete="off"
                  component={customInput} disabled={props.loading} />
              </div>
              <div className="pb-3 form-group sf-form rich-textbox">
                <div className="sf-rich-txtbox quotes-email-textbox">
                  <fieldset>
                    <Field
                      name="body"
                      type="text"
                      id="name"
                      editorState={editorState}
                      onEditorStateChange={onEditorStateChange}
                      component={EmailEditor}
                      otherProps={{
                        editorStyle: {
                          height: "300px"
                        }
                      }}
                      disabled={props.loading} />
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-end" style={{ gap: "5px" }}>
                {props.loading ? (
                  <CircularProgress size={20} />
                ) : ""}
                <button type="button" className={v2Styles.OutlinedButton} onClick={() => props.onClose()}>
                  Cancel
                </button>
                <button disabled={props.loading} className={v2Styles.PrimaryButton} type="submit">
                  Send Email
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default reduxForm({
  validate: validate
})(EmailJobReportForm);
