import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../JobStyle.module.css";
import TaskFile from "./TaskFile";
import { getJobSiteTasks } from "../../../../../actions/v2/JobActions";
import AddNewFile from "./AddNewFile";
export default function TaskFileList(props) {

  const dispatch = useDispatch();
  const siteFiles = useSelector(state => state.Job.siteFiles)

  useEffect(() => {
    if (props.jobId) {
      dispatch(getJobSiteTasks(props.jobId))
    }
  }, [props.jobId])

  return (
    <div className={styles.blankContainer}>
      <div className="container-fluid my-3 text-dark pr-0">
        <div className="row">
          <div className="col">
            <div className={styles.card}>
              <div className="container-fluid py-2">
               <AddNewFile jobId={props.jobId} />
                <div className="row align-items-end">
                  <div className="col-12 py-3 px-0">
                    {siteFiles.map((file) => (
                      <TaskFile attachment={file} key={file.id} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
