import React from 'react'
import Select from 'react-select'
import colourStyles from "./reactSelectStyles"

export const CustomSingleSelect = ({ input, label = "", options, onChange, clearable, placeholder, styles = colourStyles, dropdownIndicator = null, indicatorSeparator = null, isMulti = false, value = null, otherProps = {}, touchValidate = true, meta: { touched, error, warning } }) => {
  return (
    <div>
      {label && label.length > 0 &&
        <label>{label}</label>
      }
      <Select
        onChange={onChange || input.onChange}
        clearable={clearable || true}
        styles={styles}
        placeholder={placeholder}
        components={{
          DropdownIndicator: () => dropdownIndicator,
          IndicatorSeparator: () => indicatorSeparator
        }}
        value={value || input.value}
        options={options}
        isMulti={isMulti}
        // menuPortalTarget={document.body}
        {...otherProps}
      />
      {touchValidate ? (touched && (error && (<span className="error-input">{error}</span>) || (warning && <span>{warning}</span>))) : (error && (<span className="error-input">{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
  )
}    