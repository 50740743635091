import React, { useEffect, useState } from "react";
import styles from "../../JobStyle.module.css";

export default function RiskMatrixSection({ consequences, likelihoods, risks }) {

  const [data, setData] = useState([]);

  useEffect(() => {
    let cons = consequences.sort((a, b) => a.value - b.value);
    let like = likelihoods.sort((a, b) => a.value - b.value);

    let data2 = [];
    for (let k = 0; k < like.length; k++) {
      let values = [];
      for (let j = 0; j < cons.length; j++) {
        if (j === 0) {
          if (like[k]) {
            values.push({ value: like[k].name, isHeading: true })
          }
        }
        let total = like[k].value * cons[j].value;
        let associatedRisk = risks.find(x => x.risk_rating_high_value >= total && x.risk_rating_low_value <= total)
        values.push({ value: total, risk: associatedRisk });
      }
      if (values.length > 0) {
        data2.push({ row: values })
      }
    }
    setData(data2);

  }, [consequences, likelihoods]);

  return (
    <>
      <div className="row mt-3">
        <div className="col-12 px-0">
          <div className="swms-v4-table-heading text-uppercase">
            Step 3 - Assess the Risk level determine the risk level by combining the likelihood and consequences
          </div>
          <div className="swms-v4-border">
            <table className="swms-v4-table">
              <thead>
                <tr>
                  <th width="20%" className="swms-v4-normal-th px-3 text-uppercase font-weight-bold" style={{ verticalAlign: "middle" }} rowSpan={2} colSpan={2}>Risk Matrix</th>
                  <th className="swms-v4-normal-th px-3 text-uppercase text-center font-weight-bold" colSpan={consequences.length}>Consequences</th>
                </tr>
                <tr>
                  {consequences.map((x, index) => (<th width="10%" className="swms-v4-light-bg-th px-3 font-weight-bold text-center" key={index}>{x.name ? x.name : ""}</th>))}
                </tr>
              </thead>
              <tbody>
                {data.map((x, index) => (<tr key={index}>
                  {index === 0 ? (
                    <td className="swms-v4-normal-th px-3 text-uppercase font-weight-bold" style={{ verticalAlign: "middle" }} rowSpan={likelihoods.length}>Likelihood</td>
                  ) : null}
                  {x.row.map((y, index) => (
                    <td key={index} className={y.isHeading ? "swms-v4-light-bg-th px-3 font-weight-bold text-right" : "px-3 text-center"}
                      style={{ ...(!y.isHeading ? { backgroundColor: y.risk && y.risk.risk_colours ? y.risk.risk_colours : "#fff" } : { textAlign: "end !important" }) }}>
                      {y.value}
                      ({y.risk ? (
                        <>
                          {y.risk.risk_rating_label}
                        </>
                      ) : ""})
                    </td>))}
                </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 px-0">
          <table className="swms-v4-table swms-v4-nohead-table">
            <tbody>
              <tr>
                <td className="swms-v4-normal-th px-3 text-uppercase font-weight-bold" rowSpan={2} width="10%" style={{ verticalAlign: "middle" }}>Risk Rating</td>
                {risks && risks.length > 0 ? risks.map(risk => (
                  <td key={risk.id} className="px-3 font-weight-bold text-center" style={{ backgroundColor: risk.risk_colours }} >{risk.risk_ratings} {risk.risk_rating_low_value} - {risk.risk_rating_high_value}</td>
                )) : null}
              </tr>
              <tr>
                {risks && risks.length > 0 ? risks.map(risk => (
                  <td key={risk.id} className="px-3" style={{ backgroundColor: risk.risk_colours, width: "250px" }} >{risk.risk_rating_description ? risk.risk_rating_description : ""}</td>
                )) : null}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {risks && risks.length ? (
        <div className="row mt-3">
          <div className="col-12 px-0">
            <div className="d-flex" style={{ gap: "10px" }}>
              <div className="swms-v4-light-bg-th py-2 px-3" style={{ flex: "0 0 auto" }}>
                Lowest Level of Control
              </div>
              <div className="d-flex" style={{ flex: "1 1 auto" }}>
                {risks.map(risk => (
                  <div className="d-flex" style={{ flex: "1 1 100%", justifyContent: "space-around" }} key={risk.id}>
                    {Array(Math.round(35 / (risks.length - 1))).fill("_").map((_, i) => (
                      <svg xmlns="http://www.w3.org/2000/svg" style={{ width: "16px" }} viewBox="0 0 80.593 122.88">
                        <g>
                          <polygon fill={risk.risk_colours} points="0,0 30.82,0 80.593,61.44 30.82,122.88 0,122.88 49.772,61.44 0,0" />
                        </g>
                      </svg>
                    ))}
                  </div>
                ))}
              </div>
              <div className="swms-v4-light-bg-th py-2 px-3" style={{ flex: "0 0 auto" }}>
                Highest Level of Control
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="row mt-3">
        <div className="col-12 px-0">
          <table className="swms-v4-table swms-v4-nohead-table">
            <tbody>
              <tr>
                <td className="swms-v4-light-bg-th px-3 text-center font-weight-bold">1 - Eliminate</td>
                <td className="swms-v4-light-bg-th px-3 text-center font-weight-bold">2 - Substitute</td>
                <td className="swms-v4-light-bg-th px-3 text-center font-weight-bold">3 - Isolation</td>
                <td className="swms-v4-light-bg-th px-3 text-center font-weight-bold">4 - Engineering</td>
                <td className="swms-v4-light-bg-th px-3 text-center font-weight-bold">5 - Administrative</td>
                <td className="swms-v4-light-bg-th px-3 text-center font-weight-bold">6 - PPE</td>
              </tr>
              <tr>
                <td className="px-3 swms-v4-normal-th">Remove the hazard completely.</td>
                <td className="px-3 swms-v4-normal-th">Substitute the product/ process for a less dangerous one.</td>
                <td className="px-3 swms-v4-normal-th">Isolate the hazard e.g. barricade or relocate a particular work area.</td>
                <td className="px-3 swms-v4-normal-th">Redesign the hazard, e.g. put guards on machine</td>
                <td className="px-3 swms-v4-normal-th">Policies, procedures, SOPs, checklists, training, inductions.</td>
                <td className="px-3 swms-v4-normal-th">High visibility clothing, steel cap boots, glasses, gloves etc</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>

    // <div className="">
    //   <div>
    //     <div className="container-fluid px-0">
    //       <div className="row">
    //         <div className="col-12">
    //           <table className="table_risk_matrix table-bordered" style={{ tableLayout: "fixed", margin: "0px auto", minWidth: "60vw", maxWidth: "80vw" }}>
    //             <thead>
    //               <tr>
    //                 <th width="10%">RISK MATRIX</th>
    //                 <th colSpan={consequences.length}>Consequence</th>
    //               </tr>
    //               <tr>
    //                 <th width="10%">Likelihood</th>
    //                 {consequences.map((x, index) => (<th width="10%" className="subbg" key={index}>{x.name ? x.name : ""}</th>))}
    //               </tr>
    //             </thead>
    //             <tbody>
    //               {data.map((x, index) => (<tr key={index}>
    //                 {x.row.map((y, index) => (
    //                   <td key={index} className={y.isHeading ? "subbg" : ""}
    //                     style={{ backgroundColor: y.risk && y.risk.risk_colours ? y.risk.risk_colours : "#fff" }}>
    //                     {y.risk ? (
    //                       <>
    //                         {y.risk.risk_rating_label} <br />
    //                       </>
    //                     ) : ""}
    //                     {y.value}
    //                   </td>))}
    //               </tr>))}
    //             </tbody>
    //           </table>
    //         </div>
    //       </div>
    //       <div className="row">
    //         <div className="col-12 py-3">
    //           <div className="font-weight-bold mb-1">Risk Ratings</div>
    //           <div className="d-flex justify-content-center" style={{ margin: "0px auto", gap: "5px", flexWrap: "wrap" }}>
    //             {risks && risks.length > 0 ? risks.map(risk => (
    //               <div key={risk.id} className="p-2" style={{ backgroundColor: risk.risk_colours, flex: "0 0 250px" }}>
    //                 <div>
    //                   <div className="text-center font-weight-bold">{risk.risk_ratings} {risk.risk_rating_low_value} - {risk.risk_rating_high_value}</div>
    //                   <hr />
    //                   <div style={{ fontSize: "12px" }}>{risk.risk_rating_description ? risk.risk_rating_description : ""}</div>
    //                 </div>
    //               </div>
    //             )) : ""}
    //           </div>
    //         </div>
    //       </div>
    //       <div className="row">
    //         <div className="col-12 py-3">
    //           <div className="font-weight-bold mb-1">Hierarchy of Control</div>
    //           <div>
    //             <table className="table_risk_matrix table-bordered" style={{ tableLayout: "fixed", margin: "0px auto", minWidth: "60vw", maxWidth: "80vw" }}>
    //               <thead>
    //                 <tr>
    //                   <th style={{ backgroundColor: "#25dfee" }}>1. Elimination</th>
    //                   <th style={{ backgroundColor: "#4ae8b7" }}>2. Substitution</th>
    //                   <th style={{ backgroundColor: "#aef027" }}>3. Isolation</th>
    //                   <th style={{ backgroundColor: "#ffff00" }}>4. Engineering</th>
    //                   <th style={{ backgroundColor: "#ff9900" }}>5. Administartion</th>
    //                   <th style={{ backgroundColor: "#ee1d22" }}>6. PPE</th>
    //                 </tr>
    //               </thead>
    //               <tbody>
    //                 <tr>
    //                   <td style={{ backgroundColor: "#25dfee" }}>Remove the hazard completely</td>
    //                   <td style={{ backgroundColor: "#4ae8b7" }}>Substitute the product/ process for a less dangerous one</td>
    //                   <td style={{ backgroundColor: "#aef027" }}>Isolate the hazard e.g. barricade or relocate a particular work area</td>
    //                   <td style={{ backgroundColor: "#ffff00" }}>Redesign the hazard, e.g. put guards on machine</td>
    //                   <td style={{ backgroundColor: "#ff9900" }}>Policies, procedures, SOPs, checklists, training, inductions</td>
    //                   <td style={{ backgroundColor: "#ee1d22" }}>High visibility clothing, steel cap boots, glasses, gloves etc</td>
    //                 </tr>
    //               </tbody>
    //             </table>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}