import JobBoardContainer from "./JobBoard/JobBoardContainer";
import JobAllocationsContainer from "./JobAllocation/JobAllocationContainer";
// import JobDocContainer from "../../v2/Jobs/Jobs";
import SWMSManagementReport from "../../v2/Jobs/SWMSManagement";
import ReportContainer from "../../v2/Jobs/ReportContainer";
import ShiftTimeSheetContainer from "./JobAllocation/ShiftTimeSheet/ShiftTimeSheetContainer";
import ShiftUpdates from "./ShiftUpdates/ShiftUpates";
import JobDocumentContainer from "../JobDocument/JobDocumentContainer/JobDocumentContainer";

const tabsData = [
  {
    id: "1",
    slug: "job_board",
    displayName: "Job Board",
    component: JobBoardContainer,
  },
  {
    id: "2",
    slug: "swms",
    displayName: "SWMS",
    component: SWMSManagementReport,
  },
  // {
  //   id: "99",
  //   slug: "job_document",
  //   displayName: "Job Document",
  //   component: JobDocContainer,
  // },
  {
    id: "3",
    slug: "job_document",
    displayName: "Job Document",
    // component: JobDocContainer,
    component: JobDocumentContainer
  },
  {
    id: "4",
    slug: "allocations",
    displayName: "Allocations",
    component: JobAllocationsContainer,
  },
  {
    id: "5",
    slug: "reports",
    displayName: "Job Reports",
    component: ReportContainer,
  },
  {
    id: "7",
    slug: "shift_updates",
    displayName: "Shift Updates",
    component: ShiftUpdates,
  },
  {
    id: "6",
    slug: "job_timesheet",
    displayName: "Job Timesheet",
    component: ShiftTimeSheetContainer,
  },
];

export const tabs = (selectedJobDetails) => {
  // let tabList = tabsData;
  // //Hide some tabs if the job is not outsourced yet
  // if (selectedJobDetails.status < 5) {
  //   tabList.splice(3, 4);
  // } else if (selectedJobDetails.status < 7) {
  //   tabList.splice(3, 1);
  // }
  return tabsData
}