
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import { CircularProgress } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { IconButton } from "rsuite";

import { getPOEmailFormat } from "../../../../../../actions/v2/JobActions"
import { Strings } from "../../../../../../dataProvider/localize";
import POEmailContentWrapper from "./POEmailContentWrapper";
import { getContractorDetails } from "../../../../../../actions/v2/JobActions"

import styles from "../../../JobStyle.module.css";

export default function POEmailWrapper({ selectedTasks, jobId, siteId, onClose, closeTaskSelectionModal, siteName, jobLabel, refreshJobBoard, isReIssue }) {

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [emailFormat, setEmailFormat] = useState({});
  const [current, setCurrent] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [tasksByContractors, setTasksByContractors] = useState([]);
  const [contractorsDetails, setContractorsDetails] = useState([]);

  const fetchEmailFormat = () => {
    setLoading(true);

    let contractorIds = []
    selectedTasks.forEach(task => {
      contractorIds.push(task.contractor_id)
    })
    contractorIds = contractorIds.filter((item, index) => contractorIds.indexOf(item) === index);

    Promise.all([dispatch(getPOEmailFormat()), dispatch(getContractorDetails(contractorIds.join(",")))]).then(res => {
      setEmailFormat({ ...res[0] });
      setContractorsDetails([...res[1]]);
      setLoading(false);
    }).catch(err => {
      if (err) {
        setLoading(false)
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: "ant-error"
        });
        onClose();
      }
    })
  }

  useEffect(() => {
    fetchEmailFormat();
  }, [])

  useEffect(() => {
    let tasksByContractors = [];
    selectedTasks.forEach(obj => {
      const groupKey = obj['contractor_id'];
      let group = tasksByContractors.find(group => group.contractor_id === groupKey);
      if (!group) {
        group = {
          contractor_id: groupKey,
          contractor_name: obj['contractor_name'],
          contractor_task_cost: obj.contractor_task_cost,
          contractor_address: obj.contractor_addresses && obj.contractor_addresses.length > 0 ? obj.contractor_addresses[0] : null,
          taskDetails: [],
          job_id: obj['job_id'],
          site_id: obj['site_id']
        };
        tasksByContractors.push(group);
      }
      group.taskDetails.push(obj);
    });
    setTasksByContractors(tasksByContractors)
  }, [selectedTasks]);

  return (
    <div className="job-swms-modal-container">
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <CircularProgress size={36} />
        </div>
      ) :
        <div>
          <div className="position-relative">
            {submitting ? (
              <div className={styles.OverlayBox}>
                <div className={styles.OverlayedContent}>
                  <CircularProgress size={23} />
                </div>
              </div>
            ) : ""}
            {tasksByContractors && tasksByContractors[current] ? (
              <div className="container-fluid px-0">
                <div className="row pt-2 pb-2 border-bottom align-items-center">
                  <div className="col-8">
                    <div className={styles.QuoteEmailHead}>{isReIssue ? "Re-Issue Purchase Order" : "Issue Purchase Order"} - {tasksByContractors[current].contractor_name} {tasksByContractors.length > 1 ? `(${current + 1}/${tasksByContractors.length})` : ""}</div>
                  </div>
                  {tasksByContractors.length > 1 ?
                    <div className="col-4">
                      <div className="d-flex items-center justify-content-end">
                        <IconButton disabled={current === 0} className={styles.ModalNextIcon} onClick={() => setCurrent(current - 1)}>
                          <ChevronLeftIcon sx={{ fontSize: "20px" }} />
                        </IconButton>
                        <IconButton className={`${styles.ModalNextIcon} ml-2`} disabled={current === tasksByContractors.length - 1} onClick={() => setCurrent(current + 1)}>
                          <ChevronRightIcon sx={{ fontSize: "20px" }} />
                        </IconButton>
                      </div>
                    </div>
                    : null}
                </div>
                <POEmailContentWrapper
                  tasksByContractors={tasksByContractors}
                  contractorsDetails={contractorsDetails}
                  emailFormat={emailFormat}
                  current={current}
                  isReIssue={isReIssue}
                  setSubmitting={setSubmitting}
                  submitting={submitting}
                  jobId={jobId}
                  siteId={siteId}
                  siteName={siteName}
                  onClose={onClose}
                  jobLabel={jobLabel}
                  closeTaskSelectionModal={closeTaskSelectionModal}
                  refreshJobBoard={refreshJobBoard}
                />
              </div>
            ) : ""}
          </div>
        </div>
      }
    </div>
  )
}

