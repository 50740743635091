import React from "react";
import { Icon } from "antd";
import JobBoardContainer from "./JobBoard/JobBoardContainer";
import SWMSManagement from "../Jobs/SWMSManagement";
import JobAllocationsContainer from "../JobDetails/JobAllocation/JobAllocationContainer";
import ReportContainer from "../Jobs/ReportContainer";
import ShiftUpdates from "../JobDetails/ShiftUpdates/ShiftUpates";
import JobDocumentContainer from "../JobDocument/JobDocumentContainer/JobDocumentContainer";
import ShiftTimeSheetContainer from "../JobDetails/JobAllocation/ShiftTimeSheet/ShiftTimeSheetContainer";
import TasksSignOffContainer from "./TasksSignOff/TasksSignOffContainer";
import JobSwmsContainer from "./JobSwms/JobSwmsContainer";
import TaskFrequencyContainer from "./TaskFrequency/TaskFrequencyContainer";

import styles from "./JobStyle.module.css";

export const jobTabs = (props) => {
  let tabs = [
    {
      id: "1",
      tab: <div className={styles.JobTab}>
        <svg className={styles.JobTabIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path fill="currentColor" d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM305 273L177 401c-9.4 9.4-24.6 9.4-33.9 0L79 337c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L271 239c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
        </svg>
        Job Board</div>,
      component: JobBoardContainer
    },
    {
      id: "10",
      tab: <div className={`${styles.JobTab} ${!props.selectedTask ? styles.disabledJobTab : ""} px-1`}>
        <div style={{ marginBottom: "-15px" }}>
          <svg className={`${styles.JobTabIcon} mb-0`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path fill="currentColor" d="M14 2.2C22.5-1.7 32.5-.3 39.6 5.8L80 40.4 120.4 5.8c9-7.7 22.3-7.7 31.2 0L192 40.4 232.4 5.8c9-7.7 22.3-7.7 31.2 0L304 40.4 344.4 5.8c7.1-6.1 17.1-7.5 25.6-3.6s14 12.4 14 21.8V488c0 9.4-5.5 17.9-14 21.8s-18.5 2.5-25.6-3.6L304 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L192 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L80 471.6 39.6 506.2c-7.1 6.1-17.1 7.5-25.6 3.6S0 497.4 0 488V24C0 14.6 5.5 6.1 14 2.2zM96 144c-8.8 0-16 7.2-16 16s7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96zM80 352c0 8.8 7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96c-8.8 0-16 7.2-16 16zM96 240c-8.8 0-16 7.2-16 16s7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96z" />
          </svg>
          <div className="d-block">Task Frequency <br />/ Invoicing</div></div>
      </div>,
      component: TaskFrequencyContainer
    },
    ...(props.oldSwms ? [{
      id: "2",
      tab: <div className={styles.JobTab}>
        <svg className={styles.JobTabIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
          <path fill="currentColor" d="M192 96a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm-8 384V352h16V480c0 17.7 14.3 32 32 32s32-14.3 32-32V192h56 64 16c17.7 0 32-14.3 32-32s-14.3-32-32-32H384V64H576V256H384V224H320v48c0 26.5 21.5 48 48 48H592c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H368c-26.5 0-48 21.5-48 48v80H243.1 177.1c-33.7 0-64.9 17.7-82.3 46.6l-58.3 97c-9.1 15.1-4.2 34.8 10.9 43.9s34.8 4.2 43.9-10.9L120 256.9V480c0 17.7 14.3 32 32 32s32-14.3 32-32z" />
        </svg>
        <div>SWMS {props.hasSwms ? <Icon type="check-circle" className="text-success" theme="filled" /> : <Icon type="close-circle" theme="filled" className="text-danger" />}
        </div>
      </div>,
      component: SWMSManagement
    }] : [{
      id: "9",
      tab: <div className={styles.JobTab}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className={styles.JobTabIcon}>
          <polygon fill="currentColor" points="42.87 56.93 48.22 62.28 51.44 59.08 46.04 53.69 40.69 48.33 37.47 51.53 42.87 56.93" />
          <path fill="currentColor" d="M79.27,35.77l6.8-6.8c.04-.11,.11-.2,.2-.27,.08-.08,.17-.15,.27-.2l7.52-7.54-15.05-15.05-14.84,14.78,15.08,15.08Z" />
          <path fill="currentColor" d="M87.3,31.69l-6.92,6.99-.03,.03c-.54,.52-1.4,.51-1.92-.03l-17.06-17.03c-.54-.55-.54-1.43,0-1.97l7.03-7.01-1.63-1.63c-.42-.41-.98-.63-1.57-.64-.58,0-1.14,.23-1.57,.64l-10.57,10.46c-.83,.82-1.3,1.94-1.29,3.11v9.66c0,.37-.15,.72-.41,.98l-3.83,3.83c-1.16,1.17-1.16,3.05,0,4.22l4.54,4.54,4.54,4.54c1.17,1.16,3.05,1.16,4.22,0l3.83-3.83c.26-.26,.61-.4,.98-.41h9.69c1.17,0,2.29-.46,3.11-1.29l10.46-10.43c.41-.42,.63-.98,.64-1.57,0-.58-.23-1.14-.64-1.57l-1.59-1.57Z" />
          <path fill="currentColor" d="M20.87,64.05l-14.76,14.78,15.08,15.08,8.25-8.24c.11-.2,.28-.37,.48-.48l6.03-6.06-15.08-15.08Z" />
          <path fill="currentColor" d="M53.55,61.17l-3.15,3.11c-.53,.54-1.26,.84-2.02,.84-.76,0-1.48-.3-2.02-.84l-5.4-5.38-5.47-5.31c-.54-.53-.84-1.26-.84-2.02,0-.76,.3-1.48,.84-2.02l3.2-3.2-3.58-3.67h-.01c-.89-.9-2.33-.89-3.21,0L12.02,62.49h-.01c-.88,.9-.88,2.34,.01,3.22l1.59,1.72,6.28-6.26c.26-.26,.61-.41,.98-.41,.37,0,.74,.14,1,.41l17.03,16.97,.03,.03c.52,.54,.51,1.4-.03,1.92l-6.28,6.28,1.54,1.57h.01c.89,.9,2.33,.89,3.21,0l19.85-19.87c.88-.88,.88-2.31,0-3.2l-3.67-3.7Z" />
        </svg>
        SWMS</div>,
      component: JobSwmsContainer,
    },]),
    {
      id: "3",
      tab: <div className={styles.JobTab}>
        <svg className={styles.JobTabIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path fill="currentColor" d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm54.2 253.8c-6.1 20.3-24.8 34.2-46 34.2H80c-8.8 0-16-7.2-16-16s7.2-16 16-16h8.2c7.1 0 13.3-4.6 15.3-11.4l14.9-49.5c3.4-11.3 13.8-19.1 25.6-19.1s22.2 7.7 25.6 19.1l11.6 38.6c7.4-6.2 16.8-9.7 26.8-9.7c15.9 0 30.4 9 37.5 23.2l4.4 8.8H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-6.1 0-11.6-3.4-14.3-8.8l-8.8-17.7c-1.7-3.4-5.1-5.5-8.8-5.5s-7.2 2.1-8.8 5.5l-8.8 17.7c-2.9 5.9-9.2 9.4-15.7 8.8s-12.1-5.1-13.9-11.3L144 349l-9.8 32.8z" />
        </svg>
        Documents</div>,
      component: JobDocumentContainer
    },
    {
      id: "4",
      tab: <div className={styles.JobTab}>
        <svg className={styles.JobTabIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M320 64A64 64 0 1 0 192 64a64 64 0 1 0 128 0zm-96 96c-35.3 0-64 28.7-64 64v48c0 17.7 14.3 32 32 32h1.8l11.1 99.5c1.8 16.2 15.5 28.5 31.8 28.5h38.7c16.3 0 30-12.3 31.8-28.5L318.2 304H320c17.7 0 32-14.3 32-32V224c0-35.3-28.7-64-64-64H224zM132.3 394.2c13-2.4 21.7-14.9 19.3-27.9s-14.9-21.7-27.9-19.3c-32.4 5.9-60.9 14.2-82 24.8c-10.5 5.3-20.3 11.7-27.8 19.6C6.4 399.5 0 410.5 0 424c0 21.4 15.5 36.1 29.1 45c14.7 9.6 34.3 17.3 56.4 23.4C130.2 504.7 190.4 512 256 512s125.8-7.3 170.4-19.6c22.1-6.1 41.8-13.8 56.4-23.4c13.7-8.9 29.1-23.6 29.1-45c0-13.5-6.4-24.5-14-32.6c-7.5-7.9-17.3-14.3-27.8-19.6c-21-10.6-49.5-18.9-82-24.8c-13-2.4-25.5 6.3-27.9 19.3s6.3 25.5 19.3 27.9c30.2 5.5 53.7 12.8 69 20.5c3.2 1.6 5.8 3.1 7.9 4.5c3.6 2.4 3.6 7.2 0 9.6c-8.8 5.7-23.1 11.8-43 17.3C374.3 457 318.5 464 256 464s-118.3-7-157.7-17.9c-19.9-5.5-34.2-11.6-43-17.3c-3.6-2.4-3.6-7.2 0-9.6c2.1-1.4 4.8-2.9 7.9-4.5c15.3-7.7 38.8-14.9 69-20.5z" />
        </svg>
        Allocation</div>,
      component: JobAllocationsContainer,
    },
    {
      id: "5",
      tab: <div className={styles.JobTab}>
        <svg className={styles.JobTabIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
          <path fill="currentColor" d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V285.7l-86.8 86.8c-10.3 10.3-17.5 23.1-21 37.2l-18.7 74.9c-2.3 9.2-1.8 18.8 1.3 27.5H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z" />
        </svg>
        Reports</div>,
      component: ReportContainer,
    },
    {
      id: "6",
      tab: <div className={styles.JobTab}>
        <svg className={styles.JobTabIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
          <path fill="currentColor" d="M224 0a128 128 0 1 1 0 256A128 128 0 1 1 224 0zM178.3 304h91.4c20.6 0 40.4 3.5 58.8 9.9C323 331 320 349.1 320 368c0 59.5 29.5 112.1 74.8 144H29.7C13.3 512 0 498.7 0 482.3C0 383.8 79.8 304 178.3 304zM352 368a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-80c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16s-7.2-16-16-16H512V304c0-8.8-7.2-16-16-16z" />
        </svg>
        Shift Updates</div>,
      component: ShiftUpdates,
    },
    {
      id: "7",
      tab: <div className={styles.JobTab}>
        <svg className={styles.JobTabIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path fill="currentColor" d="M0 448c0 35.3 28.7 64 64 64H224V384c0-17.7 14.3-32 32-32H384V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64V448zM171.3 75.3l-96 96c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l96-96c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6zm96 32l-160 160c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l160-160c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6zM384 384H256V512L384 384z" />
        </svg>
        Timesheet</div>,
      component: ShiftTimeSheetContainer,
    },
    {
      id: "8",
      tab: <div className={styles.JobTab}>
        <svg className={styles.JobTabIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" />
        </svg>
        Sign Off</div>,
      component: TasksSignOffContainer,
    }
  ]

  if (props.isOutsourced === false) {
    tabs = tabs.filter(t => t.id === "1");
  } else {
    if (props.isDisabled === true) {
      tabs = tabs.filter(t => t.id !== "8")
    }
  }

  return tabs;
}
