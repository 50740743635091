import React, { Component } from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { reduxForm } from "redux-form";
import MainFormFields from "./MainFormFields";
import Controls from "./Controls";
import * as SWMSMastersActions from "../../../../actions/SWMSMastersActions";
import * as roleManagementActions from "../../../../actions/roleManagementActions";
import { DragDropContext } from "react-beautiful-dnd";
import DraggableItemsListContainer from "./DraggableItemsListContainer/DraggableItemsListContainer";
import DropAreaListContainer from "./DropAreaListContainer/DropAreaListContainer";
import { notification } from "antd";
import { Strings } from "../../../../dataProvider/localize";
import { createSWMSActivityRequest, getFormattedIntialValues } from '../serializer'
import { getNewRiskRatingValueByMap } from "../utils";
import { validate } from '../validate';
import styles from './SWMSActivityContainer.module.css'
import { withRouter } from "react-router";

export class SWMSActivityContainer extends Component {
  state = {
    riskBefore: '1. Very Low',
    riskAfter: '1. Very Low'
  };

  componentDidMount() {
    const promises = [];

    for (let i = 1; i <= 7; i++) {
      promises.push(
        this.props.actions
          .getSWMS(i)
          .then(() => { })
          .catch((err) => { })
      );
    }

    promises.push(
      this.props.actions
        .getAllOrgSWMS()
        .then(() => { })
        .catch((err) => { })
    );

    promises.push(
      this.props.actions.getAllLikelihoodBeforeControl()
    )

    promises.push(
      this.props.actions.getAllBeforeConsequences()
    )

    Promise.all(promises)
      .then(() => {
        const { ppe, tools, standards, highRiskWork, licensesAndTraining, history, roles } = this.props;

        if (history.location.state.id) {
          this.props.actions.getSingleSWMSActivity(history.location.state.id)
            .then(res => {
              const values = this.props.viewActivityValues
              const formattedValues = getFormattedIntialValues(values, history.location.state.mode, roles)

              const { tags } = formattedValues

              const dropItemIds = {
                ppe: tags.ppe.map(d => d.ppe_id),
                tools: tags.tools.map(d => d.tool_id),
                standards: tags.standards.map(d => d.standard_id),
                highRiskWork: tags.highRiskWork.map(d => d.high_risk_id),
                licensesAndTraining: tags.licensesAndTraining.map(d => d.license_id),
              }

              const initialDragItems = {
                ppe: ppe.filter(d => !dropItemIds.ppe.includes(d.id)),
                tools: tools.filter(d => !dropItemIds.tools.includes(d.id)),
                standards: standards.filter(d => !dropItemIds.standards.includes(d.id)),
                highRiskWork: highRiskWork.filter(d => !dropItemIds.highRiskWork.includes(d.id)),
                licensesAndTraining: licensesAndTraining.filter(d => !dropItemIds.licensesAndTraining.includes(d.id)),
              }

              const initialDropItems = {
                ppe: ppe.filter(d => dropItemIds.ppe.includes(d.id)),
                tools: tools.filter(d => dropItemIds.tools.includes(d.id)),
                standards: standards.filter(d => dropItemIds.standards.includes(d.id)),
                highRiskWork: highRiskWork.filter(d => dropItemIds.highRiskWork.includes(d.id)),
                licensesAndTraining: licensesAndTraining.filter(d => dropItemIds.licensesAndTraining.includes(d.id)),
              }

              this.props.actions.recomputeDragAndDropLists(initialDragItems, initialDropItems)
              this.props.actions.setInitialValues(formattedValues)
            })
        } else {
          this.props.actions.initializeDragList({ tools, ppe, highRiskWork, licensesAndTraining, standards });
        }
      })
      .catch(() => { });
  }

  onSubmit = (values) => {
    const formValues = {
      ...values,
      ...this.props.dropItems,
      risk_rating_before_controls: this.state.riskBefore,
      risk_rating_after_controls: this.state.riskAfter,
      ...(this.props.history.location.state.mode === 'edit' ? { id: this.props.viewActivityValues[0].id } : {})
    };

    const reqBody = createSWMSActivityRequest(formValues)

    this.props.actions.addEditSWMSActivity(reqBody)
      .then(message => {
        notification.success({
          message: Strings.success_title,
          description: message,
          className: 'ant-success'
        });
        this.props.actions.getSWMSActivity()
          .then(() => {
            if (this.props.history.location.state.mode !== 'edit') {
              const q = this.props.newActivityList.length / 10
              const r = this.props.newActivityList.length % 10
              let page = 1
              if (r === 0) {
                page = q
              } else {
                page = q + 1
              }
              this.props.actions.setActivityPage(page)
            }
            this.props.history.goBack()
          })
          .catch((err) => { });
      })
      .catch(message => {
        notification.error({
          message: Strings.error_title,
          description: Strings.generic_error,
          onClick: () => { },
          className: "ant-error",
        });
      })
  };

  onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    } else {
      const dragType = source.droppableId.split("_")[1];
      const dropType = destination.droppableId.split("_")[1];

      const dragZone = source.droppableId.split("_")[0];
      const dropZone = destination.droppableId.split("_")[0];

      if (dragZone === "drop" && dragType === dropType) {
        return;
      }

      if (dropZone === "drag" || (dragZone === "drop" && dropZone === "drop")) {
        notification.warning({
          message: Strings.warning_title,
          description: "Please drop into relevant area",
          onClick: () => { },
          className: "ant-warning",
        });

        return;
      }

      if (dragType !== dropType) {
        notification.warning({
          message: Strings.warning_title,
          description: "Please drop into relevant area",
          onClick: () => { },
          className: "ant-warning",
        });

        return;
      } else {
        const dragItemIndex = source.index;

        const modifiedDragSWMS = this.props.dragItems[dragType].filter(
          (_, index) => index !== dragItemIndex
        );
        const newDragItems = {
          ...this.props.dragItems,
          [dragType]: modifiedDragSWMS,
        };

        const modifiedDropSWMS = [...this.props.dropItems[dropType]];
        const draggedItem = this.props.dragItems[dragType].filter(
          (_, index) => index === dragItemIndex
        );
        modifiedDropSWMS.push(draggedItem[0]);
        const newDropItems = {
          ...this.props.dropItems,
          [dropType]: modifiedDropSWMS,
        };

        this.props.actions.recomputeDragAndDropLists(
          newDragItems,
          newDropItems
        );
      }
    }
  };

  handleClose = (e, item, type) => {
    e.preventDefault();

    const modifiedDragSWMS = [...this.props.dragItems[type]];
    modifiedDragSWMS.unshift({ ...item });

    const modifiedDropSWMS = this.props.dropItems[type].filter(
      (d) => d.id !== item.id
    );

    const newDragItems = {
      ...this.props.dragItems,
      [type]: modifiedDragSWMS,
    };

    const newDropItems = {
      ...this.props.dropItems,
      [type]: modifiedDropSWMS,
    };

    this.props.actions.recomputeDragAndDropLists(newDragItems, newDropItems);
  };

  addToDropArea = (type, item, itemIndex) => {
    const { dragItems, dropItems, actions } = this.props

    const modifiedDropSWMS = [...dropItems[type]];
    modifiedDropSWMS.push({ ...item });

    const modifiedDragSWMS = dragItems[type].filter(
      (d) => d.id !== item.id
    );

    const newDragItems = {
      ...this.props.dragItems,
      [type]: modifiedDragSWMS,
    };

    const newDropItems = {
      ...this.props.dropItems,
      [type]: modifiedDropSWMS,
    };

    actions.recomputeDragAndDropLists(newDragItems, newDropItems)
  }

  calculateRiskValue = (likelihoodValue, consequenceValue, type) => {
    if (likelihoodValue && likelihoodValue !== -1 && consequenceValue && consequenceValue !== -1) {
      const riskValue =  getNewRiskRatingValueByMap[`${likelihoodValue}${consequenceValue}`]
      this.setState({ [type]: riskValue })
      return riskValue
    } else {
      return 'N/A'
    }
  }

  render() {
    const { handleSubmit, viewActivityValues, change, history } = this.props;

    return (
      <div className={styles.MainContainer}>
        <h1 className={styles.ActivityHeader}>SWMS Activity</h1>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <div className="row justify justify-content-center">
              <div className="col-md-9">
                <MainFormFields />
                <Controls change={change} calculateRiskValue={this.calculateRiskValue} viewActivityValues={viewActivityValues} SWMSActivityId={history.location.state} />
                <DropAreaListContainer onClose={this.handleClose} />
                <div
                  className={styles.SaveButton}
                >
                  <button type="submit" className="bnt bnt-active">
                    <i class="material-icons">save</i> Save
                  </button>
                </div>
              </div>
              <div className="col-md-3">
                <DraggableItemsListContainer addCb={this.addToDropArea} />
              </div>
            </div>

          </form>
        </DragDropContext>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  dragItems: state.SWMSMasters.activityDragItems,
  dropItems: state.SWMSMasters.activityDropItems,
  ppe: state.SWMSMasters.ppe,
  tools: state.SWMSMasters.tools,
  standards: state.SWMSMasters.standards,
  highRiskWork: state.SWMSMasters.highRiskWork,
  licensesAndTraining: state.SWMSMasters.licensesAndTraining,
  likelihoodBefore: state.SWMSMasters.likelihood_before,
  consequencesBefore: state.SWMSMasters.consequences_before,
  initialValues: state.SWMSMasters.initialValues,
  viewActivityValues: state.SWMSMasters.viewActivity,
  newActivityList: state.SWMSMasters.newActivityList,
  roles: state.roleManagement.roles
})

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(SWMSMastersActions, dispatch),
    roleManagementActions: bindActionCreators(roleManagementActions, dispatch),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "SWMSActivity",
    validate,
    enableReinitialize: true
  })
)(SWMSActivityContainer);
