import React from "react";
import HazardReport from './HazardReport';
import ToolboxTalk from './ToolboxTalk';

export const tabs = [
  {
    name: "toolbox_talk",
    title: "Toolbox Talk",
    id: 1,
    Component: ToolboxTalk
  },
  {
    name: "incidents_report",
    title: "Incident Reports",
    id: 2,
  },
  {
    name: "hazard_report",
    title: "Hazard Reports",
    id: 3,
    Component : HazardReport
  },
  {
    name: "cleaning_report",
    title: "Cleaning Reports",
    id: 4,
  },
  {
    name: "standards",
    title: "",
    id: 5,
  },
  {
    name: "licenses_and_training",
    title: "",
    id: 6,
  },
  {
    name: "hazards",
    title: "",
    id: 7,
  },
  // {
  //   name: "swms_category",
  //   title: "SWMS Category",
  //   id: 8,
  //   Component: SWMSCategory,
  // },
 
  // {
  //   name: "ppe",
  //   title: "PPE",
  //   id: 9,
  //   Component: PPE,
  // },
  // {
  //   name: "tool_type",
  //   title: "Tool Types",
  //   id: 10,
  //   Component: ToolType,
  // },
  // {
  //   name: "high_risk_work",
  //   title: "High Risk Work",
  //   id: 11,
  //   Component: HighRisk
  // },
  // {
  //   name: "chemicals",
  //   title: "Chemicals",
  //   id: 12,
  //   Component: Chemicals
  // },
];
