import {
  SET_ROSTER_DETAILS,
  SET_ROSTER_MANAGERS,
  SET_ROSTER_STATES,
  SET_ROSTER_SERVICES,
  SET_ROSTER_REGIONS,
  SET_ROSTER_LISTS,
  SET_STAFFS_DAILY_VIEW
} from "../../dataProvider/constant";
const INITIAL_STATE = {
  roster_jobs: [],
  roster_managers: [],
  roster_states: [],
  roster_services: [],
  roster_regions: [],
  roster_lists: [],
  roster_list_count: 0,
  staff_daily_view : []
}

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_ROSTER_DETAILS: {
      return {
        ...state,
        roster_jobs: payload,
      }
    }
    case SET_ROSTER_MANAGERS: {
      return {
        ...state,
        roster_managers: payload,
      }
    }
    case SET_ROSTER_STATES: {
      return {
        ...state,
        roster_states: payload,
      }
    }
    case SET_ROSTER_SERVICES: {
      return {
        ...state,
        roster_services: payload,
      }
    }
    case SET_ROSTER_REGIONS: {
      return {
        ...state,
        roster_regions: payload,
      }
    }
    case SET_ROSTER_LISTS: {
      return {
        ...state,
        roster_lists: payload.rows,
        roster_list_count: payload.count
      }
    }
    case SET_STAFFS_DAILY_VIEW: {
      return {
        ...state,
        staff_daily_view: payload,
      }
    }
    default: {
      return state;
    }
  }
}