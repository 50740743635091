import React, { useState, useEffect } from "react";
import { reduxForm, Field, FieldArray } from "redux-form";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { CircularProgress } from "@mui/material";
import { Icon } from "antd";
import { Collapse } from "@mui/material";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { customInput } from '../../../../common/custom-input';
import { MultiEmailInput } from "../../../../common/chipInputRedux";
import EmailEditor from '../../../../common/EmailEditor';
import DocumentImageContainer from "../JobSwmsForm/DocumentImageContainer";

import colourStyles from "../FormSelectStyle";
import styles from "../../JobStyle.module.css";

let SwmsVersionEmailModal = (props) => {

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    let blocksFromHtml = htmlToDraft(props.initialValues.body);
    if (blocksFromHtml) {
      let contentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks);
      let editorStateVal = EditorState.createWithContent(contentState);
      setEditorState(editorStateVal);
    }
  }, []);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    let body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    props.change("body", body);
  };

  const onSubmit = (values) => {
    let body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    let emailData = { ...values, body: body };
    props.onSubmitForm(emailData);
  }

  return (
    <div className="job-swms-modal-container">
      <form onSubmit={props.handleSubmit(onSubmit)}>
        <div className="container-fluid">
          <div className="row py-2">
            <div className="col-12">
              <div className={styles.ModalHeading}>
                Send Email to Client
              </div>
              <hr></hr>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="my-1">
                <div className={styles.Label}>From</div>
                <Field
                  name="from"
                  type="text"
                  id="name"
                  disabled={true}
                  autoComplete="off"
                  className={styles.TextField}
                  component={customInput} />
              </div>
              <div className="my-1">
                <div className={styles.Label}>To</div>
                <Field
                  name="to"
                  type="text"
                  id="name"
                  // isMulti={emailOptions.allowMutiToEmail}
                  autoComplete="off"
                  component={MultiEmailInput}
                  otherProps={{
                    styles: colourStyles,
                    isDisabled: props.loading
                  }} />
              </div>
              <div className="my-1">
                <div className={styles.Label}>CC</div>
                <Field
                  name="cc"
                  type="text"
                  id="name"
                  autoComplete="off"
                  component={MultiEmailInput}
                  otherProps={{
                    styles: colourStyles,
                    isDisabled: props.loading
                  }} />
              </div>
              <div className="my-1">
                <div className={styles.Label}>BCC</div>
                <Field
                  name="bcc"
                  type="text"
                  id="name"
                  autoComplete="off"
                  component={MultiEmailInput}
                  otherProps={{
                    styles: colourStyles,
                    isDisabled: props.loading
                  }} />
              </div>
              <div className="my-1">
                <div className={styles.Label}>Subject</div>
                <Field
                  name="subject"
                  type="text"
                  id="name"
                  autoComplete="off"
                  component={customInput}
                  className={styles.TextField}
                  disabled={props.loading}
                />
              </div>
              <div className="my-1">
                <div className="text-right">
                  <button type="button" className={`${styles.OutlinedButton} px-0`} onClick={() => setExpanded(!expanded)}>
                    <Icon type={expanded ? "minus" : "plus"} className={`${styles.PlusButn}`} /> Attachments
                  </button>
                </div>
                <Collapse in={expanded} timeout="auto">
                  <div className="mb-3">
                    <FieldArray
                      name="attachment_docs"
                      loading={props.loading}
                      component={DocumentImageContainer}
                      s3UploadPath={"job-swms/email-attachments"}
                      fieldName="attachment_docs"
                      formName={props.form}
                      maxSize={20971520}
                      label="Email Attachments"
                    />
                  </div>
                </Collapse>
                {/* <FieldArray name="s3_attachments" component={EmailAttachmentsContainer} quote={props.quote} loading={loading} attachments={props.attachments} /> */}
              </div>
              <div className="my-1 form-group sf-form rich-textbox">
                <div className="sf-rich-txtbox quotes-email-textbox">
                  <fieldset>
                    <Field
                      name="body"
                      type="text"
                      otherProps={{
                        editorStyle: {
                          height: "300px"
                        }
                      }}
                      id="name"
                      disabled={props.loading}
                      editorState={editorState}
                      onEditorStateChange={onEditorStateChange}
                      component={EmailEditor} />
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-12 text-right">
              <div className="d-flex align-items-center justify-content-end" style={{ gap: "5px" }}>
                {props.loading ? (
                  <CircularProgress size={20} />
                ) : ""}
                <button type="button" className={styles.OutlinedButton} onClick={() => props.onClose()}>
                  Cancel
                </button>
                <button disabled={props.loading} className={`${styles.PrimaryButton} ml-1`} type="submit">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

SwmsVersionEmailModal = reduxForm()(SwmsVersionEmailModal)

export default SwmsVersionEmailModal;