import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Icon, notification } from "antd";
import { Strings } from "../../../../../dataProvider/localize";
import { addUpdateTaskAdditionalCost, getJobTasks, deleteTaskAdditionalCost } from "../../../../../actions/v2/JobActions";
import { customInput } from "../../../../common/custom-input";
import { validateTaskAdjustmentSave } from "../validate"
import styles from "../../JobStyle.module.css";
import v2Styles from "../../../v2Styles.module.css";

const TaskAdjustment = (props) => {

  const dispatch = useDispatch();
  const [editAdjustment, setEditAdjustment] = useState(false);
  const [addClicked, setAddClicked] = useState(false);
  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if (Object.keys(props.adjustment).length === 0) {
      setIsNew(true);
    }
  })
  const handleAddClick = () => {
    setAddClicked(!addClicked);
    props.onNewTaskToggleClick();
  }
  const handleEditClick = () => {
    if (editAdjustment && !isNew) {
      props.change('adjustment_name', props.adjustment.adjustment_name)
    }
    setEditAdjustment(!editAdjustment)
  }

  const handleDelete = () => {
    if (props.adjustment.id) {
      let deletionData = {
        id: props.adjustment.id,
        freq_id: props.frequency && props.frequency.quote_task_id ? props.frequency.quote_task_id : null,
        freq_cost: props.frequency && props.frequency.task_amount ? props.frequency.task_amount : null,
        job_id: props.jobId,
        task_id: props.taskDetails && props.taskDetails.task_id ? props.taskDetails.task_id : null,
        job_board_task_state: props.frequency && props.frequency.job_board_task_state ? props.frequency.job_board_task_state : null,
        task_invoice_id: props.frequency && props.frequency.task_invoice_id ? props.frequency.task_invoice_id : null
      }
      dispatch(addUpdateTaskAdditionalCost(deletionData)).then((message) => {
        notification.success({
          message: Strings.success_title,
          description: message ? message : Strings.generic_error,
          className: 'ant-success'
        });
        props.fetchFrequencies();
      }).catch(err => {
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: 'ant-error'
        })
      })
    }
  }

  const onSubmit = (values) => {
    let data = {
      ...values,
      job_id: props.jobId,
      job_board_task_state: props.frequency && props.frequency.job_board_task_state ? props.frequency.job_board_task_state : null,
      task_invoice_id: props.frequency && props.frequency.task_invoice_id ? props.frequency.task_invoice_id : null,
      task_id: props.taskDetails.task_id
    }
    dispatch(addUpdateTaskAdditionalCost(data)).then((message) => {
      props.fetchFrequencies();
      // dispatch(getJobTasks(props.jobId))
      if (editAdjustment) {
        setEditAdjustment(!editAdjustment);
      }
      if (isNew) {
        props.onNewTaskToggleClick();
      }
      notification.success({
        message: Strings.success_title,
        description: message ? message : Strings.generic_error,
        className: 'ant-success'
      });

    }).catch(err => {
      notification.error({
        message: Strings.error_title,
        description: err ? err : Strings.generic_error,
        className: 'ant-error'
      })
    })
  }

  return (
    <form onSubmit={props.handleSubmit(onSubmit)}>
      <div className={`container-fluid px-0 pt-2 pb-1 ${styles.lightGreyTopBorder}`}>
        <div className={`row ${isNew ? 'align-items-center' : ''}`}>
          <div className="col-5">
            {isNew || editAdjustment ? (
              <Field name="adjustment_name"
                component={customInput}
                className={styles.SingleLine}
                placeholder="Adjustment Name"
                autoComplete="off"
                disabled={props.invoiceStatus === 3 || props.isDisabledJob}
              />
            ) : (
              <div className={`${styles.PrimaryText} font-weight-bold`}>
                {props.adjustment.adjustment_name}
              </div>
            )}
          </div>
          <div className="col-3 px-0">
            <div className="">
              <Field size="small"
                component={customInput}
                className={`${styles.SingleLine}`}
                disabled={props.invoiceStatus === 3 || props.isDisabledJob}
                placeholder="Cost"
                name="adjustment_cost"
                type="number" />
            </div>
          </div>
          <div className="col-4" style={{ paddingTop: "3px" }}>
            {props.invoiceStatus !== 3 &&
              <div className="d-flex justify-content-around align-items-center">
                <Icon type="edit" size="small" className="mx-2" style={{ visibility: !isNew ? 'visible' : 'hidden' }} onClick={() => !props.isDisabledJob && handleEditClick()} />
                <Icon type="delete" size="small" className="mx-2" style={{ visibility: !isNew ? 'visible' : 'hidden' }} onClick={() => !props.isDisabledJob && handleDelete()} />
                <button type="submit" className={`${v2Styles.PrimaryButton} ${v2Styles.SmallButton} mx-2`} disabled={props.pristine || props.isDisabledJob}>Save</button>
                <Icon type={!addClicked ? 'plus' : 'close'} size="small" onClick={() => !props.isDisabledJob && handleAddClick()} className="mx-2" style={{ visibility: props.showAddOption ? 'visible' : 'hidden' }} />
              </div>
            }
          </div>
        </div>
      </div>
    </form>
  )
}

export default reduxForm({
  validate: validateTaskAdjustmentSave,
  enableReinitialize: true
})(TaskAdjustment);