import React, { createRef, useEffect, useState } from "react";
import $ from "jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tooltip } from "antd";

const moment = require("moment");
export const CustomReactTimePicker = ({
  input,
  label,
  type,
  children,
  placeholder,
  disabled,
  defaultValue,
  timeFormat,
  onChange,
  className = "",
  wrapperClassName = "cust-dpicker",
  validationAsTooltip,
  meta: { touched, error, warning },
  placement
}) => {
  var id = "CustomReactTimePicker-" + Math.random().toString(36).substr(7);

  const ref = createRef();

  useEffect(() => {
    $(`#${id} .ant-time-picker-input`).attr("id", `${input.name}-focus`);
  });

  useEffect(() => {
    // console.log(ref)
  }, [ref]);

  return (
    <div id="stickDatepicker" className={wrapperClassName}>
      {label && <label>{label}</label>}
      <div>
        <DatePicker
          ref={ref}
          id={id}
          selected={
            input.value
              ? input.value instanceof Date
                ? input.value
                : moment(input.value, "HH:mm:ss").toDate()
              : ""
          }
          onChange={onChange ? onChange : input.onChange}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="HH:mm"
          timeFormat="HH:mm"
          disabled={disabled}
          defaultValue={defaultValue ? defaultValue : new Date()}
          popperPlacement={placement || "bottom-start"}
          className={className}
          wrapperClassName={wrapperClassName}
        />
        {!validationAsTooltip &&
          touched &&
          ((error && <span className="error-input">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};
