export default function validate(values) {
  let errors = {};
  if (!values.serviceType) {
    errors.serviceType = "Required";
  }
  if (!values.payClassification) {
    errors.payClassification = "Required";
  }
  if (!values.employmentType) {
    errors.employmentType = "Required";
  }
  if (!values.payCode) {
    errors.payCode = "Required";
  }
  return errors;
}