import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { Table, Icon, Button, notification, Empty } from "antd";
import * as payrollTimesheetAction from "../../../actions/payrollTimesheetAction";
import * as profileManagementActions from "../../../actions/profileManagementActions";
import * as organisationUserActions from "../../../actions/organizationUserAction";
import * as scopeDocActions from "../../../actions/scopeDocActions";
import * as jobActions from "../../../actions/jobAction";
import { Strings } from "../../../dataProvider/localize";
import { goBack, getStorage, getPermissions } from "../../../utils/common";
import { reduxForm } from "redux-form";
import moment from "moment";
import { ADMIN_DETAILS } from "../../../dataProvider/constant";
import { serializeParams } from "./serializer";
import { ERROR_NOTIFICATION_KEY } from "../../../config";
import {
  MODULE_TIMESHEET_MANAGEMENT, PERMISSION_EXPORT_TIMESHEET_FOR_XERO,
  PERMISSION_EXPORT_TIMESHEET_AS_DISPLAYED
} from "../../../dataProvider/permissionConstants";

import ShiftTimeSheetFilters from "./ShiftTimeSheetFilters";
import columns from "./tableDefinitions";
import styles from "../../v2/v2Styles.module.css";
import "../../v2/v2Styles.css";
import { IconButton, LinearProgress } from "@mui/material";

export class ShiftTimeSheetContainer extends Component {
  state = {
    selectedRowKeys: [], // Check here to configure the default column
    loading: false,
    filters: {},
    editingKey: "",
    currentPage: 1,
    pageSize: 15
  };

  currentOrganization = JSON.parse(getStorage(ADMIN_DETAILS))
    ? JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id
    : null;

  filter = () => {
    const params = serializeParams(this.state.filters);
    this.props.payrollTimesheetAction
      .getPayrollTImesheet(params)
      .then(() => {
        this.setState(
          { editingKey: "", selectedRowKeys: "", currentPage: 1 },
          () => this.onPageChange(1)
        );
      })
      .catch(err => {
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: 'ant-error'
        })
      });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys, editingKey: "" });
  };

  componentDidMount() {
    document.title = "Timesheet Exports - Service Farm";
    this.props.profileActions.getStates();
    this.props.orgUserActions.getOrganizationUsers(
      this.currentOrganization,
      0
    );
    this.props.scopeDocActions
      .getClients(this.currentOrganization)
      .then((message) => { })
      .catch((message) => { });
  }

  componentWillUnmount() {
    this.props.payrollTimesheetAction.clearPayrollList();
  }

  export = (type) => {
    const data = this.props.data.filter((_, index) =>
      this.state.selectedRowKeys.includes(index)
    );

    const selectedStaffUsers = data.map((item) => item.staff_id);

    const { filters, currentPage } = this.state;

    const params = serializeParams({
      ...filters,
      currentPage,
      selectedStaffUsers,
      type,
    });

    this.props.payrollTimesheetAction
      .exportTimesheet(params)
      .then(() => {
        if (this.props.csvFileLink) {
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = this.props.csvFileLink;
          a.download = `timesheet.csv`;
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
        }
      })
      .catch(err => {
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: 'ant-error'
        })
      });
  };

  onPageChange = (arg) => {
    this.setState({
      currentPage: arg,
      selectedRowKeys: "",
    });
  };

  handleEdit = (data, index) => {
    const { start_date, start_time, finish_date, finish_time } = data;
    this.setState({ editingKey: data.staff_id });
    this.props.change(
      `start_date_${data.staff_id}`,
      start_date ? start_date : moment(new Date()).format("YYYY-MM-DD")
    );
    this.props.change(
      `start_time_${data.staff_id}`,
      start_time ? start_time : moment(new Date()).format("HH:mm")
    );
    this.props.change(
      `finish_date_${data.staff_id}`,
      finish_date ? finish_date : moment(new Date()).format("YYYY-MM-DD")
    );
    this.props.change(
      `finish_time_${data.staff_id}`,
      finish_time ? finish_time : moment(new Date()).format("HH:mm")
    );
  };

  saveEdit = (data, rowId) => {
    const formData = {
      job_id: data.job_id,
      job_shift_timesheets: [
        {
          ...data,
          id: data.staff_id,
          start_date: moment(
            this.props.formValues[`start_date_${data.staff_id}`]
          ).format("YYYY-MM-DD"),
          start_time: `${moment(
            this.props.formValues[`start_date_${data.staff_id}`]
          ).format("YYYY-MM-DD")} ${moment(
            this.props.formValues[`start_time_${data.staff_id}`],
            "HH:mm:ss"
          ).format("HH:mm:ss")}`,
          finish_date: moment(
            this.props.formValues[`finish_date_${data.staff_id}`]
          ).format("YYYY-MM-DD"),
          finish_time: `${moment(
            this.props.formValues[`finish_date_${data.staff_id}`]
          ).format("YYYY-MM-DD")} ${moment(
            this.props.formValues[`finish_time_${data.staff_id}`],
            "HH:mm:ss"
          ).format("HH:mm:ss")}`,
        },
      ],
    };

    this.props.jobActions
      .updateJobShift(formData)
      .then(({ message }) => {
        this.filter();
        return notification.success({
          message: Strings.success_title,
          description: message ? message : 'Job shift timesheet updated"',
          onClick: () => { },
          className: "ant-success",
        });
      })
      .catch((message) => {
        return notification.error({
          key: ERROR_NOTIFICATION_KEY,
          message: Strings.error_title,
          description: message,
          onClick: () => { },
          className: "ant-error",
        });
      });
  };

  cancelEdit = (rowIndex) => {
    this.setState({ editingKey: "" });
  };

  handleFilter = (filters) => {
    this.setState({ filters: filters });
    let params = serializeParams(filters);
    this.props.payrollTimesheetAction
      .getPayrollTImesheet(params)
      .then(() => {
        this.setState(
          { editingKey: "", selectedRowKeys: "", currentPage: 1 },
          () => this.onPageChange(1)
        );
      })
      .catch(err => {
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: 'ant-error'
        });
      });
  }

  render() {
    const { loading, selectedRowKeys } = this.state;
    const { data } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <div style={{ background: "#fff", height: "100%" }}>
        <div style={{ background: "#fff", padding: "10px", overflow: "hidden" }}>
          <div className="v2-container">
            <div className={styles.ContainerPage}>
              <div className="container-fluid pt-1">
                <div className="row">
                  <div className="col-7">
                    <div className={styles.PageHeading}>
                      <div>Timesheet Exports</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 pt-3">
                    <ShiftTimeSheetFilters
                      onFilter={(filters) => this.handleFilter(filters)}
                      onReset={() => { this.setState({ filters: {}, selectedRowKeys: [], editingKey: "", currentPage: 1 }) }}
                    />
                    <div className="timesheet-main-container">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-12">
                            <div className="d-flex align-items-center justify-content-end" style={{ gap: "5px" }}>
                              {getPermissions(MODULE_TIMESHEET_MANAGEMENT, PERMISSION_EXPORT_TIMESHEET_FOR_XERO)
                                ?
                                <button
                                  className={styles.PrimaryButton}
                                  onClick={() => this.export("CSV_FOR_XERO")}
                                  disabled={loading}>
                                  Export Timesheet For XERO
                                </button>
                                : ''
                              }
                              {getPermissions(MODULE_TIMESHEET_MANAGEMENT, PERMISSION_EXPORT_TIMESHEET_AS_DISPLAYED)
                                ?
                                <button
                                  onClick={() => this.export("CSV_FOR_TEST")}
                                  loading={loading}
                                  className={styles.SecondaryButton}>
                                  Export Timesheet as Displayed
                                </button>
                                : ''
                              }
                            </div>
                            <div>
                              <div style={{ height: "4px" }}>
                                {loading ? (
                                  <LinearProgress />
                                ) : ""}
                              </div>
                              <Table
                                rowSelection={rowSelection}
                                rowClassName="cs-default-table-row"
                                columns={columns(this.state.editingKey, this.saveEdit, this.cancelEdit, this.handleEdit).slice(0, 14)}
                                dataSource={data}
                                ro
                                locale={{
                                  emptyText: <div className="text-center">No Timesheets Available.</div>
                                }}

                                pagination={{
                                  current: this.state.currentPage,
                                  pageSize: this.state.pageSize,
                                  onChange: (a) => this.onPageChange(a),
                                  className: "cs-pagination cs-pagination-v2"
                                }}
                                bordered
                                scroll={{ x: 1000 }}
                                className="table-centered-selection timesheet-table"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const values = {};
  const twoWeeksAgo = new Date();
  twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
  values.shift_start = moment(twoWeeksAgo).format("YYYY-MM-DD");
  values.shift_end = moment(new Date()).format("YYYY-MM-DD");
  values.staff_user = [];
  values.role = [];
  values.state = [];
  values.payrate = [];

  return {
    data: state.payrollReducer.payrollList,
    initialValues: values,
    csvFileLink: state.payrollReducer.csvFileLink,
    formValues: state.form.NewTimesheet && state.form.NewTimesheet.values,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    payrollTimesheetAction: bindActionCreators(
      payrollTimesheetAction,
      dispatch
    ),
    profileActions: bindActionCreators(profileManagementActions, dispatch),
    orgUserActions: bindActionCreators(organisationUserActions, dispatch),
    scopeDocActions: bindActionCreators(scopeDocActions, dispatch),
    jobActions: bindActionCreators(jobActions, dispatch),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "NewTimesheet",
    enableReinitialize: true,
  })
)(ShiftTimeSheetContainer);
