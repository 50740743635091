import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { notification, Modal } from "antd";
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment'

import { getFirstName, getStorage, getFormattedPhone } from "../../../../../../utils/common";
import { ADMIN_DETAILS } from "../../../../../../dataProvider/constant";
import { Strings } from "../../../../../../dataProvider/localize";
import { sendPOEmail } from "../../../../../../actions/v2/JobActions";
import POEmailButton from "./POEmailButton";
import POEmailForm from "./POEmailForm";
import PreviewPO from "../PreviewPO";

import styles from "../../../JobStyle.module.css";
import PreviewPORedesign from "../PreviewPORedesign";

export default function POEmail({ emailFormat, siteName, task, closeForm, onSubmitAll, validate, taskDetails, contractorsDetails, isReIssue = false, jobLabel, ...props }) {

  const [initialValues, setInitialValues] = useState({});
  const [noReplyData, setNoReplyData] = useState('');
  const [formLoading, setFormLoading] = useState(false);
  const [previewPo, setPreviewPo] = useState(false);

  const dispatch = useDispatch();
  const currDate = moment().format('DD/MM/YYYY');

  useEffect(() => {
    if (task) {
      let values = {};
      let contractorInfo = contractorsDetails && contractorsDetails.length > 0 ? contractorsDetails.find(c => c.id === task.contractor_id) : null;
      let adminDetails = JSON.parse(getStorage(ADMIN_DETAILS));
      let orgName = adminDetails && adminDetails.organisation && adminDetails.organisation.name ? adminDetails.organisation.name : '';
      let orgAddress = adminDetails && adminDetails.organisation && adminDetails.organisation.address ? adminDetails.organisation.address : "";
      let orgPhoneNumber = adminDetails && adminDetails.organisation && adminDetails.organisation.phone_number ? adminDetails.organisation.phone_number : "";
      let taskSiteName = siteName ? siteName : "";
      let userName = adminDetails ? adminDetails.name + " " + (adminDetails.last_name ? adminDetails.last_name : '') : [];

      let noReplyFrom = `${userName} (${orgName}) via ${Strings.SERVICE_NAME} <${emailFormat.from_email ? emailFormat.from_email : "no-reply@service.farm"}>`;
      let to = contractorInfo && contractorInfo.email_address ? [{ label: contractorInfo.email_address, value: contractorInfo.email_address }] : []
      let subject = emailFormat.subject ? emailFormat.subject : "";
      let contractorName = contractorInfo && contractorInfo.contractor_name ? contractorInfo.contractor_name : ''
      subject = subject.replace(/\{\{contractor_name\}\}/g, contractorName);
      subject = subject.replace(/\{\{site_name\}\}/g, siteName);

      let body = emailFormat.body ? emailFormat.body : "";
      let contractorPrimaryName = contractorInfo && contractorInfo.primary_person ? getFirstName(contractorInfo.primary_person) : '';
      let userPhoneNumber = adminDetails && adminDetails.phone_number ? getFormattedPhone(adminDetails.phone_number) : ''
      let userEmailAddress = adminDetails && adminDetails.email_address ? `<a href="mailto:${adminDetails.email_address}">${adminDetails.email_address}</a>` : "";
      let taskNames = taskDetails ? taskDetails.map((t, index) => `• Task ${index + 1} - ${t.task_name}`).join(`\n`) : [];

      body = body.replace(/\{\{purchase_date\}\}/g, currDate);
      body = body.replace(/\{\{task_details\}\}/g, taskNames);
      body = body.replace(/\{\{contractor_primary_person\}\}/g, contractorPrimaryName);
      body = body.replace(/\{\{site_name\}\}/g, taskSiteName);

      let hrSeparator = `<hr />`;

      body = body.replace(/\{\{user_name\}\}/g, userName);
      body = body.replace(/\{\{user_phone_number\}\}/g, userPhoneNumber);
      body = body.replace(/\{\{user_email\}\}/g, userEmailAddress);
      body = body.replace(/\{\{org_name\}\}/g, orgName);
      body = body.replace(/\{\{org_address\}\}/g, orgAddress);
      body = body.replace(/\{\{org_phone_number\}\}/g, orgPhoneNumber);
      body = body.replace(/<p>\{\{hr_separator\}\}<\/p>/g, hrSeparator);

      body = body.replace(/(?:\r\n|\r)/g, '');
      body = body.replace(/<p><\/p>/g, "<br />");
      body = body.replace(/\{\{service_name\}\}/g, Strings.SERVICE_NAME);
      body = body.replace(/\{\{service_email\}\}/g, `<a href="mailto:${Strings.SERVICE_EMAIL}">${Strings.SERVICE_EMAIL}</a>`);

      if (body.includes("<p>____no_reply</p>") && body.includes("<p>no_reply____</p>")) {
        let start = body.indexOf("<p>____no_reply</p>");
        let end = body.lastIndexOf("<p>no_reply____</p>")

        if (start !== -1 && end !== -1) {
          let signEnd = end + 15;
          let dataStart = start + 15;
          let noReply = body.substr(dataStart, (end - dataStart));
          noReply = `<!--[if mso]><table role="presentation" cellspacing="0" cellpadding="0" border="0" width="600"><tr><td><![endif]--><div style='width: auto;border:none;border-top:solid #000000 1.0pt; border-bottom: solid #000000 1.0pt; padding:4.0pt 0cm 4.0pt 0cm; margin: 20pt 0cm 10pt 0cm;'>`
            + noReply + `</div><!--[if mso]></td></tr></table><![endif]-->`;
          setNoReplyData(noReply);
          body = body.substring(0, start) + '' + body.substring(signEnd);
        }
      }
      let bcc = Array.isArray(emailFormat.to_bcc) ? emailFormat.to_bcc.map(address => ({ label: address, value: address })) : [{ label: emailFormat.to_bcc, value: emailFormat.to_bcc }];
      let cc = adminDetails && adminDetails.email_address ? [{ label: adminDetails.email_address, value: adminDetails.email_address }] : []

      values.subject = subject;
      values.from = noReplyFrom;
      values.to = to;
      values.body = body;
      values.bcc = bcc;
      values.cc = cc;
      setInitialValues(values);
    }
  }, [task, emailFormat, contractorsDetails, siteName]);

  const onSubmit = (values) => {
    props.setSubmitting(true);
    let content = values.body;

    content = content + noReplyData;
    content = `<!--[if mso]><table role="presentation" cellspacing="0" cellpadding="0" border="0"><tr><td><![endif]--><div style="font-family: 'calibri', sans-serif !important; font-size: 11pt !important; width: fit-content;">` + content + `</div><!--[if mso]></td></tr></table><![endif]-->`;
    content = content.replace(/font-family:.?inherit;/g, "font-family: 'calibri', sans-serif;");
    content = content.replace(/<p><\/p>/g, '');
    content = content.replace(/<ins>/g, "<u>");
    content = content.replace(/<\/ins>/g, "</u>");
    let contractorsTasks = [];
    taskDetails.map((t) => {
      contractorsTasks.push({
        task_id: `${t.task_id}`,
        quote_task_id: `${t.quote_task_id}`,
        task_label: t.task_label,
        task_name: t.task_name,
        task_amount: t.task_amount,
        contractor_task_cost: t.contractor_task_cost
      })
    })
    let formData = {
      job_id: `${taskDetails[0].job_id}`,
      site_id: `${taskDetails[0].site_id}`,
      po_batches: [
        {
          contractor_id: `${task.contractor_id}`,
          contractors_tasks: contractorsTasks,
          to: values.to.map(x => x.value).join(','),
          from: values.from,
          subject: values.subject,
          bcc: values.bcc && values.bcc.length > 0 ? values.bcc.map(x => x.value).join(',') : '',
          cc: values.cc && values.cc.length > 0 ? values.cc.map(x => x.value).join(',') : '',
          body: content,
          file_path: "",
          s3_attachements: []
        }
      ]
    }

    dispatch(sendPOEmail(formData, isReIssue)).then((message) => {
      notification.success({
        message: Strings.success_title,
        description: message,
        className: "ant-success"
      });
      props.setSubmitting(false);
      props.onClose();
      props.closeTaskSelectionModal();
      props.refreshJobBoard()
    }).catch(err => {
      props.setSubmitting(false);
      notification.error({
        message: Strings.error_title,
        description: err ? err : Strings.generic_error,
        className: "ant-error"
      })
    })
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          {initialValues && initialValues.body ? (
            <POEmailForm
              form={`po_email_${task.contractor_id}`}
              initialValues={initialValues}
              onSubmit={onSubmit} />
          ) : ""}
        </div>
      </div>
      <div className="row">
        <div className="col-3 pt-3">
          {contractorsDetails && contractorsDetails.length > 1 ?
            <POEmailButton contractor={task} sumbmitting={props.submitting} /> : null}
        </div>
        <div className="col-9 text-right pt-3">
          <div className="d-flex align-items-center justify-content-end" style={{ gap: "10px" }}>
            {formLoading ? (
              <CircularProgress
                size={20}
              />
            ) : ""}
            <button className={`${styles.OutlinedButton}`}
              onClick={() => { props.onClose() }}
            >
              Cancel
            </button>
            <button
              disabled={props.submitting}
              className={styles.JobPurpleButton}
              onClick={() => setPreviewPo(true)}
            >
              Preview PO
            </button>
            {contractorsDetails && contractorsDetails.length > 1 ?
              <button
                className={styles.PrimaryButton}
                disabled={props.submitting}
                onClick={() => onSubmitAll(noReplyData)}
              >
                Submit All
              </button>
              :
              <POEmailButton contractor={task} sumbmitting={props.submitting} />
            }
          </div>
        </div>
      </div>
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={previewPo}
        width={1200}
        onCancel={() => setPreviewPo(false)}
        // afterClose={() => { setSelectedField(null); fetchSwmsCategory(page, filterValues) }}
        maskClosable={false}
        destroyOnClose={true}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
      >
        <PreviewPORedesign jobLabel={jobLabel} task={task} onClose={() => setPreviewPo(false)} />
      </Modal>
    </div>
  )
}