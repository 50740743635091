import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Typography } from '@mui/material';
import { Field } from 'redux-form';
import { IconButton } from '@mui/material';

import { customInput } from '../../../../common/custom-input';
import styles from "../../JobStyle.module.css";

const style = {
  borderTop: '1px solid #000 !important',
  borderColor: "#000 !important",
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
};

export default function JSADraggableConsRow({ genId, consequence, index, moveRow, afterDrop, activityIndex, hazardIndex, loading }) {
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: `TABLEROW_${genId}`,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveRow(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag, preview] = useDrag({
    type: `TABLEROW_${genId}`,
    canDrag: !loading,
    item: () => {
      return { ...consequence, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (monitor.didDrop) {
        afterDrop(item);
      }
    }
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
    <tr ref={preview} style={{ ...style, opacity }} data-handler-id={handlerId}>
      <td title={consequence.consequences_name}><Typography noWrap className={styles.TableText}>{consequence.consequences_name}</Typography></td>
      <td title={consequence.initial_risk} className="text-center">{consequence.initial_risk}</td>
      <td title={consequence.control_measures}><Typography noWrap className={styles.TableText}>{consequence.control_measures}</Typography></td>
      <td title={consequence.residual_risk} className="text-center">{consequence.residual_risk}</td>
      <td>
        <Field
          name={`activities[${activityIndex}].fm_swms_activities_hazards[${hazardIndex}].fm_swms_hazards_consequences[${index}].action_by`}
          component={customInput}
          autoComplete="off"
          disabled={loading}
          className={styles.TextField}
        />
      </td>
      <td className="text-right">
        <IconButton ref={ref} style={{ height: "auto", cursor: "grab" }} className="p-0" disabled={loading}>
          <svg xmlns="http://www.w3.org/2000/svg" className={styles.DragIcon} style={{ height: "14px", cursor: "grab" }} viewBox="0 0 320 512">
            <path fill="currentColor" d="M40 352l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zm192 0l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 320c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 192l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 160c-22.1 0-40-17.9-40-40L0 72C0 49.9 17.9 32 40 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40z" />
          </svg>
        </IconButton>
      </td>

    </tr>
  )
}
