import {
  JOB_BOARD_HEADER_URL,
  SET_JOB_HEADER,
  JOB_BOARD_TASKS_URL,
  SET_JOB_TASKS,
  JOB_BOARD_ON_SITE_URL,
  SET_JOB_ON_SITE_INFO,
  JOB_BOARD_SITE_TASKS_URL,
  SET_JOB_SITE_TASKS,
  JOB_BOARD_DELETE_TASK_FILE_URL,
  JOB_BOARD_UPDATE_TASK_ADJUSTMENT_URL,
  JOB_UPDATE_PURCHASE_ORDER_URL,
  JOB_TASK_STATE_UPDATE_URL,
  JOB_TASK_INVOICE_URL,
  SET_JOB_SWMS_ID,
  CLEAR_JOB_HEADER,
  JOB_TASK_CONTRACTOR_STATE_URL,
  JOB_TASK_CONTRACTOR_INVOICE_STATE_URL,
  JOB_TASK_CANCEL_RE_ALLOCATE_URL,
  SLUG_EMAIL_CONTENT_URL,
  JOB_BOARD_INVOICE_DETAILS,
  JOB_BOARD_UPDATE_INVOICE,
  CONTRACTORS_DETAILS_URL,
  PO_SEND_EMAIL_URL,
  APPROVE_TASK_VALUE,
  REISSUE_PO_SEND_EMAIL_URL,
  GET_TASK_FREQUENCY_DETAILS,
  UPDATE_FREQUENCY_STATUS_URL,
  GET_RELATED_PLANNED_TASKS_URL,
  GET_JOB_PLANNED_SHIFTS,
  JOB_BOARD_NEW_TASK_URL,
  CREATE_PLANNED_TASKS_JOB,
  GET_PLANNED_JOB_DETAILS,
  SAVE_JOB_PLANNED_ROSTER,
  CREATE_PLANNED_JOB,
  TASK_FREQUENCY_INVOICES_URL,
  JOB_BOARD_FREQ_ADJUSTMENT_URL,
  DELETABLE_TASK_FREQUENCIES_URL,
  CANCEL_TASK_URL,
  EXTEND_FREQUENCY_URL,
  FREQUENCY_SHIFTS_URL,
  ADD_NEW_FREQUENCY_URL,
  REBOOK_TASK_URL
} from "../../dataProvider/constant";
import { startSipnner, stopSipnner } from "../../utils/spinner";
import { scopeAxiosInstance, scopeV2AxiosInstance, baseApiInstance, axiosV2Instance, scopeApiInstance } from "../../dataProvider/axiosHelper";
import { Strings } from "../../dataProvider/localize";
import { serializeSiteTasks } from "../../components/v2/Job/JobBoard/TaskFiles/serializer";

export const getJobHeader = (jobId) => dispatch => {
  startSipnner(dispatch);
  let url = JOB_BOARD_HEADER_URL + `?job_id=${jobId}`;
  return scopeV2AxiosInstance.get(url)
    .then((res) => {
      let data = res.data.data;
      if (data && data.job_board_details) {
        let header = data.job_board_details && data.job_board_details.length && Object.keys(data.job_board_details[0]).length ? data.job_board_details[0] : {}
        dispatch({
          type: SET_JOB_HEADER,
          payload: {
            header: header,
            job_swms_id: header.job_swms_id ? header.job_swms_id : null,
            swms_type: header.swms_type ? header.swms_type : null
          },
        });
        return Promise.resolve(res.data.message && res.data.message);
      } else {
        dispatch({ type: CLEAR_JOB_HEADER })
        return Promise.reject(Strings.network_error);
      }
    })
    .catch((error) => {
      dispatch({ type: CLEAR_JOB_HEADER })
      return Promise.reject(
        error.response && error.response.data && error.response ? error.response.data.message : Strings.network_error
      );
    }).finally(() => {
      stopSipnner(dispatch);
    });
}

export const getJobTasks = (jobId) => dispatch => {
  startSipnner(dispatch)
  let url = JOB_BOARD_NEW_TASK_URL + `?job_id=${jobId}`;
  return scopeApiInstance.get(url)
    .then((res) => {
      let data = res.data.data;
      if (data.job_board_details) {
        let job = data.job_board_details
        let tasks = job.tasks ? job.tasks : []
        dispatch({
          type: SET_JOB_TASKS,
          payload: job,
          tasks: tasks
        });
        return Promise.resolve(res.data && res.data.data ? res.data.data : null);
      } else {
        return Promise.reject(Strings.network_error);
      }
    })
    .catch((error) => {
      return Promise.reject(
        error.response && error.response.data && error.response && error.response.data && error.response ? error.response.data.message : Strings.network_error
      );
    }).finally(() => {
      stopSipnner(dispatch);
    });
}

export const getJobOnSiteInfo = (jobId) => dispatch => {
  startSipnner(dispatch)
  let url = JOB_BOARD_ON_SITE_URL + `?job_id=${jobId}`;
  return scopeV2AxiosInstance.get(url)
    .then((res) => {
      let data = res.data.data;
      if (data.length === 1) {
        data = data[0];
      } else {
        data = {}
      }
      dispatch({
        type: SET_JOB_ON_SITE_INFO,
        payload: data,
      });
      return Promise.resolve(res.data.message && res.data.message);
    })
    .catch((error) => {
      return Promise.reject(
        error.response && error.response.data && error.response ? error.response.data.message : Strings.network_error
      );
    }).finally(() => {
      stopSipnner(dispatch);
    });
}

export const setOnJobSiteInfo = (formData) => dispatch => {
  startSipnner(dispatch)
  let url = JOB_BOARD_ON_SITE_URL;
  return scopeV2AxiosInstance.put(url, formData).then(async (res) => {
    if (res.data.status) {
      return Promise.resolve(res.data.message && res.data.message);
    } else {
      return Promise.reject(Strings.generic_error)
    }
  })
    .catch((error) => {
      return Promise.reject(
        error.response && error.response.data && error.response ? error.response.data.message : Strings.network_error
      );
    }).finally(() => {
      stopSipnner(dispatch)
    })
}

export const getJobSiteTasks = (jobId) => dispatch => {
  startSipnner(dispatch)
  let url = JOB_BOARD_SITE_TASKS_URL + `?job_id=${jobId}`;
  return scopeV2AxiosInstance.get(url)
    .then((res) => {
      let data = res.data.data;
      dispatch({
        type: SET_JOB_SITE_TASKS,
        payload: serializeSiteTasks(data),
      });
      return Promise.resolve(res.data.message && res.data.message);
    })
    .catch((error) => {
      return Promise.reject(
        error.response && error.response.data && error.response ? error.response.data.message : Strings.network_error
      );
    }).finally(() => {
      stopSipnner(dispatch);
    });
}

export const addJobSiteTask = (formData) => dispatch => {
  startSipnner(dispatch)
  let url = JOB_BOARD_SITE_TASKS_URL;
  return scopeV2AxiosInstance.put(url, formData)
    .then(async (res) => {
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      } else {
        return Promise.reject(Strings.generic_error)
      }
    })
    .catch((error) => {
      return Promise.reject(
        error.response && error.response.data && error.response ? error.response.data.message : Strings.network_error
      );
    }).finally(() => {
      stopSipnner(dispatch)
    })
}

export const addUpdateJobTask = (formData) => dispatch => {
  startSipnner(dispatch);
  let url = JOB_BOARD_TASKS_URL;
  return scopeV2AxiosInstance.put(url, formData)
    .then(async (res) => {
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      } else {
        return Promise.reject(Strings.generic_error)
      }
    })
    .catch((error) => {
      return Promise.reject(
        error.response && error.response.data && error.response ? error.response.data.message : Strings.network_error
      );
    }).finally(() => {
      stopSipnner(dispatch)
    })
}

export const deleteTaskFile = (id) => dispatch => {
  startSipnner(dispatch);
  let url = JOB_BOARD_DELETE_TASK_FILE_URL;
  return scopeV2AxiosInstance.delete(url, { data: { id: id } })
    .then(async (res) => {
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      } else {
        return Promise.reject(Strings.generic_error)
      }
    })
    .catch((error) => {
      return Promise.reject(
        error.response && error.response.data && error.response ? error.response.data.message : Strings.network_error
      );
    }).finally(() => {
      stopSipnner(dispatch)
    })
}


export const addUpdateTaskAdditionalCost = (data) => dispatch => {
  startSipnner(dispatch);
  let url = JOB_BOARD_FREQ_ADJUSTMENT_URL;
  return scopeApiInstance.put(url, data).then(async (res) => {
    if (res.data.status) {
      return Promise.resolve(res.data.message && res.data.message);
    } else {
      return Promise.reject(Strings.generic_error)
    }
  })
    .catch((error) => {
      return Promise.reject(
        error.response && error.response.data && error.response ? error.response.data.message : Strings.network_error
      );
    }).finally(() => {
      stopSipnner(dispatch)
    })
}

export const deleteTaskAdditionalCost = (data) => dispatch => {
  startSipnner(dispatch);
  let url = JOB_BOARD_FREQ_ADJUSTMENT_URL
  return scopeApiInstance.delete(url, { data: data })
    .then(async (res) => {
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      } else {
        return Promise.reject(Strings.generic_error)
      }
    })
    .catch((error) => {
      return Promise.reject(
        error.response && error.response.data && error.response ? error.response.data.message : Strings.network_error
      );
    }).finally(() => {
      stopSipnner(dispatch)
    })
}

export const updatePurchaseOrder = (formData) => dispatch => {
  startSipnner(dispatch);
  let url = JOB_UPDATE_PURCHASE_ORDER_URL;
  return scopeAxiosInstance.put(url, formData).then(async (res) => {
    if (res.data.status) {
      return Promise.resolve(res.data.message && res.data.message);
    } else {
      return Promise.reject(Strings.generic_error)
    }
  })
    .catch((error) => {
      return Promise.reject(
        error.response && error.response.data && error.response ? error.response.data.message : Strings.network_error
      );
    }).finally(() => {
      stopSipnner(dispatch)
    })
}

export const updateTaskState = (formData) => dispatch => {
  startSipnner(dispatch);
  let url = JOB_TASK_STATE_UPDATE_URL;
  return scopeV2AxiosInstance.put(url, formData).then(async (res) => {
    if (res.data.status) {
      return Promise.resolve(res.data.message && res.data.message);
    } else {
      return Promise.reject(Strings.generic_error)
    }
  })
    .catch((error) => {
      return Promise.reject(
        error.response && error.response.data && error.response ? error.response.data.message : Strings.network_error
      );
    }).finally(() => {
      stopSipnner(dispatch)
    })
}


export const deleteSiteTask = (id, jobId, taskLabel) => dispatch => {
  startSipnner(dispatch);
  let url = JOB_BOARD_TASKS_URL;
  return scopeV2AxiosInstance.delete(url, { data: { id: id, job_id: jobId, task_label: taskLabel } })
    .then(async (res) => {
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      } else {
        return Promise.reject(Strings.generic_error)
      }
    })
    .catch((error) => {
      return Promise.reject(
        error.response && error.response.data && error.response ? error.response.data.message : Strings.network_error
      );
    }).finally(() => {
      stopSipnner(dispatch)
    })
}

export const updateTaskInvoiceState = (formData) => dispatch => {
  startSipnner(dispatch);
  let url = JOB_TASK_INVOICE_URL;
  return scopeV2AxiosInstance.put(url, formData)
    .then(async (res) => {
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      } else {
        return Promise.reject(Strings.generic_error)
      }
    })
    .catch((error) => {
      return Promise.reject(
        error.response && error.response.data && error.response ? error.response.data.message : Strings.network_error
      );
    }).finally(() => {
      stopSipnner(dispatch)
    })
}

export const setJobSwmsId = (id) => dispatch => {
  dispatch({
    type: SET_JOB_SWMS_ID,
    payload: id
  })
}


export const updateTaskContractorStatus = (formData) => dispatch => {
  startSipnner(dispatch);
  let url = JOB_TASK_CONTRACTOR_STATE_URL;
  return scopeV2AxiosInstance.put(url, formData).then(async (res) => {
    if (res.data.status) {
      return Promise.resolve(res.data.message && res.data.message);
    } else {
      return Promise.reject(Strings.generic_error)
    }
  })
    .catch((error) => {
      return Promise.reject(
        error.response && error.response.data && error.response ? error.response.data.message : Strings.network_error
      );
    }).finally(() => {
      stopSipnner(dispatch)
    })
}

export const updateContractorInvoiceState = (formData) => dispatch => {
  startSipnner(dispatch);
  let url = JOB_TASK_CONTRACTOR_INVOICE_STATE_URL;
  return scopeV2AxiosInstance.put(url, formData).then(async (res) => {
    if (res.data.status) {
      return Promise.resolve(res.data.message && res.data.message);
    } else {
      return Promise.reject(Strings.generic_error)
    }
  })
    .catch((error) => {
      return Promise.reject(
        error.response && error.response.data && error.response ? error.response.data.message : Strings.network_error
      );
    }).finally(() => {
      stopSipnner(dispatch)
    })
}

export const cancelReAllocateTask = (formData) => dispatch => {
  startSipnner(dispatch);
  let url = JOB_TASK_CANCEL_RE_ALLOCATE_URL;
  return scopeV2AxiosInstance.put(url, formData).then(async (res) => {
    if (res.data.status) {
      return Promise.resolve(res.data.message && res.data.message);
    } else {
      return Promise.reject(Strings.generic_error)
    }
  })
    .catch((error) => {
      return Promise.reject(
        error.response && error.response.data && error.response ? error.response.data.message : Strings.network_error
      );
    }).finally(() => {
      stopSipnner(dispatch)
    })
}

export const getPOEmailFormat = () => dispatch => {
  let url = SLUG_EMAIL_CONTENT_URL;
  return baseApiInstance.get(url, {
    params: {
      slug: "issue_po_email"
    }
  }).then(res => {
    if (res.data && res.data.data && res.data.data.result && res.data.data.result.length === 1) {
      return Promise.resolve(res.data.data.result[0]);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getInvoiceDetails = (page = 1, params = {}, abortController) => () => {
  return scopeApiInstance.get(JOB_BOARD_INVOICE_DETAILS, { params: { page, ...params }, ...abortController }).then((res) => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const updateInvoice = (formData) => dispatch => {
  startSipnner(dispatch);
  let url = JOB_BOARD_UPDATE_INVOICE;
  return scopeApiInstance.put(url, formData).then(async (res) => {
    stopSipnner(dispatch);
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject()
    }
  }).catch((error) => {
    stopSipnner(dispatch)
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getContractorDetails = (contractorId) => dispatch => {
  let url = `${CONTRACTORS_DETAILS_URL}?contractor_org_id=${contractorId}`;
  return axiosV2Instance.get(url).then((res) => {
    let data = res.data.data;
    if (data && data.length > 0) {
      return Promise.resolve(data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const sendPOEmail = (formData, poIssuedStatus = false) => dispatch => {
  let url = poIssuedStatus ? REISSUE_PO_SEND_EMAIL_URL : PO_SEND_EMAIL_URL;
  return scopeApiInstance.post(url, formData).then(res => {
    if (res.data && res.data.status) {
      return Promise.resolve(res.data && res.data.message ? res.data.message : "Email Sent Successfully.");
    } else {
      return Promise.reject(Strings.generic_error)
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const approveTaskValue = (formData) => dispatch => {
  let url = APPROVE_TASK_VALUE;
  return scopeApiInstance.put(url, formData).then(res => {
    if (res.data && res.data.status) {
      return Promise.resolve(res.data && res.data.message ? res.data.message : "");
    } else {
      return Promise.reject(Strings.generic_error)
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getTaskFrequency = (abortController = {}, params = {}) => () => {
  return scopeApiInstance.get(GET_TASK_FREQUENCY_DETAILS, { params: { ...params }, ...abortController }).then((res) => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const getRelatedPlannedTasks = (abortController = {}, params = {}) => () => {
  let url = GET_RELATED_PLANNED_TASKS_URL;
  return scopeApiInstance.get(url, { params: { ...params }, ...abortController }).then(res => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const updateFrequencyStatus = (formData, isAdding = false) => () => {
  return scopeApiInstance.post(UPDATE_FREQUENCY_STATUS_URL, formData).then(res => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject();
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getJobPlannedShifts = (abortController = {}, params = {}) => () => {
  let url = GET_JOB_PLANNED_SHIFTS;
  return scopeApiInstance.get(url, { params: { ...params }, ...abortController }).then(res => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const getNewTaskDetails = (params = {}) => () => {
  let url = JOB_BOARD_NEW_TASK_URL;
  return scopeApiInstance.get(url, { params: { ...params } }).then(res => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const saveNewPlannedJob = (formData) => () => {
  let url = CREATE_PLANNED_TASKS_JOB;
  return scopeApiInstance.post(url, formData).then(res => {
    if (res && res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject();
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getTempJobDetails = (abortController = {}, params = {}) => () => {
  let url = GET_PLANNED_JOB_DETAILS;
  return scopeApiInstance.get(url, { params: { ...params }, ...abortController }).then(res => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const saveTemporaryRosterShiftData = (formData) => () => {
  let url = SAVE_JOB_PLANNED_ROSTER;
  return scopeApiInstance.post(url, formData).then(res => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject();
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const createPlannedJob = (formData) => () => {
  let url = CREATE_PLANNED_JOB;
  return scopeApiInstance.post(url, formData).then(res => {
    if (res && res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject();
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getFrequencyInvoiceData = (abortController = {}, params = {}) => () => {
  let url = TASK_FREQUENCY_INVOICES_URL;
  return scopeApiInstance.get(url, { params: { ...params }, ...abortController }).then(res => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const getDeletableFrequencies = (abortController = {}, params = {}) => () => {
  const url = DELETABLE_TASK_FREQUENCIES_URL;
  return scopeApiInstance.get(url, { params: { ...params }, ...abortController }).then(res => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const cancelTask = (formData) => () => {
  let url = CANCEL_TASK_URL;
  return scopeApiInstance.put(url, formData).then(res => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject();
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const extendFrequency = (formData) => () => {
  const url = EXTEND_FREQUENCY_URL;
  return scopeApiInstance.put(url, formData).then(res => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject();
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const createFrequencyShifts = (formData) => () => {
  const url = FREQUENCY_SHIFTS_URL;
  return scopeApiInstance.post(url, formData).then(res => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject();
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const addNewFrequency = (formData) => () => {
  const url = ADD_NEW_FREQUENCY_URL;
  return scopeApiInstance.post(url, formData).then(res => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject();
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const rebookCancelledTask = (formData) => () => {
  const url = REBOOK_TASK_URL;
  return scopeApiInstance.put(url, formData).then(res => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject();
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}