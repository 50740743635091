import React, { Component } from "react";
import { connect } from "react-redux";
import { draggableSWMSItems } from "../../utils";
import styles from "./DropAreaListContainer.module.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Tag } from "antd";

export class DropAreaListContainer extends Component {
  render() {
    return (
      <div className="sf-card">
        <div className="sf-card-body">
          <div className={styles.Container}>
            {draggableSWMSItems.map((SWMS) => (
              <div className={styles.SWMSList}>
                <h1 style={{
                  textAlign: "center",
                  marginBottom: "10px",
                  fontWeight: 600,
                  color: "#777",
                  fontSize: '1rem'
                }}>
                  {SWMS.label}
                </h1>
                <div>
                  <Droppable droppableId={`drop_${SWMS.type}`}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={styles.DropArea}
                      >
                        {this.props.dropItems[SWMS.type].map((item, index) => (
                          <Draggable
                            key={`drop_${SWMS.type}_${index}`}
                            draggableId={`drop_${SWMS.type}_${index}`}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                data-placement="top"
                                data-toggle="tooltip"
                                title={item[SWMS.displayProperty]}
                              >
                                <Tag
                                  onClick={(e) =>
                                    this.props.onClose(e, item, SWMS.type)
                                  }
                                  closable
                                  className={styles.ListItem}
                                  onClose={(e) =>
                                    this.props.onClose(e, item, SWMS.type)
                                  }
                                >
                                  {item[SWMS.displayProperty]}
                                </Tag>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ppe: state.SWMSMasters.ppe,
  tools: state.SWMSMasters.tools,
  standards: state.SWMSMasters.standards,
  highRiskWork: state.SWMSMasters.highRiskWork,
  licensesAndTraining: state.SWMSMasters.licensesAndTraining,
  formValues:
    state.form && state.form.SWMSActivity ? state.form.SWMSActivity.values : {},
  dropItems: state.SWMSMasters.activityDropItems,
});

export default connect(mapStateToProps)(DropAreaListContainer);
