import React, { useEffect, useState } from "react";
import { Icon, notification, Popconfirm, Menu, Dropdown, Modal } from "antd";
import { currencyFormat } from "../../../../../utils/common";
import styles from "../../JobStyle.module.css";
import { useDispatch } from "react-redux";
import { getJobTasks, deleteSiteTask, updateTaskInvoiceState, cancelReAllocateTask, cancelTask } from "../../../../../actions/v2/JobActions";
import { Strings } from "../../../../../dataProvider/localize"
import AddNewTask from "./AddNewTask";
import moment from "moment";
import CloseIcon from '@mui/icons-material/Close';
import v2Styles from "../../../v2Styles.module.css";
import CancelTaskFrequenciesModal from "./CancelTaskFrequenciesModal";
import { CircularProgress } from "@mui/material";


export default function TaskHeader(props) {

  const dispatch = useDispatch();
  const [isJobTask, setIsJobTask] = useState(false);
  const [taskState, setTaskState] = useState("");
  const [visibleDeleteTask, setVisibleDeleteTask] = useState(false);
  const [confirmInvoicing, setConfirmInvoicing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [invoiceStatus, setInvoiceStatus] = useState(0);
  const [allCancelled, setAllCancelled] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [cancelFrequenies, setCancelFrequencies] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (props.taskDetails && props.taskDetails.task_type && props.taskDetails.task_type === "JOB_TASK") {
      setIsJobTask(true);
    }
    if (props.taskDetails && props.taskDetails.task_state) {
      setTaskState(props.taskDetails.task_state);
      let status = 0;
      if (props.taskDetails.invoice_number && props.taskDetails.invoice_number !== null && props.taskDetails.xero_invoice_state && props.taskDetails.xero_invoice_state !== null) {
        status = 3;
      } else if (props.taskDetails.job_board_task_state && props.taskDetails.job_board_task_state.toLowerCase() === "values approved for invoice") {
        status = 2;
      } else if (props.taskDetails.job_board_task_state && props.taskDetails.job_board_task_state.toLowerCase() === "confirm values approved for invoice") {
        status = 1;
      }
      setInvoiceStatus(status);
    }
    if (props.taskDetails && props.taskDetails.contractor_details && props.taskDetails.contractor_details.every(cont => cont.contractor_task_status && cont.contractor_task_status.toString().toLowerCase() === "cancelled")) {
      setAllCancelled(true);
    } else {
      setAllCancelled(false);
    }
  }, [props.taskDetails])


  const handleOptionsClick = ({ key }) => {
    // if (key === "photos") {
    //   if (props.taskDetails && props.taskDetails.task_files && props.job) {
    //     let url = process.env.REACT_APP_CLIENT_APP_URL ? process.env.REACT_APP_CLIENT_APP_URL : "";
    //     url = url + "/task_image_preview" + generateImgUrl(props.taskDetails.task_files, props.taskDetails, props.job)
    //     window.open(url, "_blank");
    //   }
    // } else if (key === "cancel" || key === "reAllocate") {
    //   let formData = {
    //     job_id: props.job.job_id,
    //     task_label: props.taskDetails.task_number,
    //     task_state: key === "cancel" ? "Cancelled" : "Allocated",
    //     ...(props.taskDetails.quote_task_id ? { quote_task_id: props.taskDetails.quote_task_id } : {}),
    //     ...(props.taskDetails.task_invoice_id ? { task_invoice_id: props.taskDetails.task_invoice_id } : {})
    //   }

    //   dispatch(cancelReAllocateTask(formData)).then((message) => {
    //     dispatch(getJobTasks(props.job.job_id))
    //     notification.success({
    //       message: Strings.success_title,
    //       description: message ? message : Strings.generic_error,
    //       className: 'ant-success'
    //     });
    //   }).catch(err => {
    //     notification.error({
    //       message: Strings.error_title,
    //       description: err ? err : Strings.generic_error,
    //       className: 'ant-error'
    //     })
    //   })
    // }
    if (key === "cancel-task") {
      setConfirmCancel(true);
    }
    if (key === "cancel-task-frequency") {
      setCancelFrequencies(true)
    }
  }

  const cancelCurrentTask = () => {
    if (props.taskDetails && props.taskDetails.task_id) {
      setSubmitting(true);
      const formData = {
        job_id: props.job.job_id,
        job_cost: props.job.job_total_amount,
        task_id: props.taskDetails.task_id,
        task_cost: props.taskDetails.task_cost,
        booking_type: props.taskDetails.booking_type
      }
      dispatch(cancelTask(formData)).then(message => {
        setConfirmCancel(false);
        props.refreshJobBoard();
        setSubmitting(false);
        notification.success({
          message: Strings.success_title,
          description: message ? message : Strings.generic_error,
          className: 'ant-success'
        });
      }).catch(err => {
        setSubmitting(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: 'ant-error'
        })
      })
    }
  }

  const generateImgUrl = (files, task, quoteDetails) => {
    return `?imgs=${encodeURIComponent(JSON.stringify(files.map(file => ({ image: `${file.file_base_path}${file.file_name}`, note: file.notes ? file.notes : "" }))))}${quoteDetails.job_name ? "&job=" + quoteDetails.job_name : ""}&task=${task.task_name}&v=2`;
  }

  const menu = (
    <Menu onClick={handleOptionsClick} className="cs-menu-dropdown">
      {/* <Menu.Item key="photos" disabled={!(props.taskDetails && props.taskDetails.task_files && props.taskDetails.task_files.length > 0)}>
        <div className="px-2">Photos</div>
      </Menu.Item>
      <Menu.Divider /> */}
      {/* {props.taskDetails && props.taskDetails.task_state && props.taskDetails.task_state.toString().toLowerCase() === "cancelled" ? (
        <Menu.Item key="reAllocate" disabled={invoiceStatus === 3 || allCancelled === false}>
          <div className="px-2">Re-Allocate Task</div>
        </Menu.Item>) : (
        <Menu.Item key="cancel" disabled={invoiceStatus === 3 || allCancelled === false}>
          <div className="px-2">Cancel Task</div>
        </Menu.Item>)
      } */}
      <Menu.Item key="cancel-task" disabled={props.taskDetails && props.taskDetails.task_state.toString().toLowerCase() !== "booked"}>
        <div className="px-2">Cancel Task</div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="cancel-task-frequency" disabled={props.taskDetails && !(props.taskDetails.task_state.toString().toLowerCase() === "booked" || props.taskDetails.task_state.toString().toLowerCase() === "in progress")}>
        <div className="px-2">Cancel Task Frequency</div>
      </Menu.Item>
    </Menu>
  )

  // const taskStates = ["Booked", "Allocated", "Outsourced", "Started", "Completed", "Signed Off", "Cancelled"];

  const sendTaskForInvoicing = () => {
    if (props.taskDetails.task_invoice_id) {
      let formData = {
        task_invoice_id: props.taskDetails.task_invoice_id,
        job_board_task_state: "Confirm Values Approved for Invoice",
        job_id: props.job.job_id
      }
      dispatch(updateTaskInvoiceState(formData)).then((message) => {
        setConfirmInvoicing(false);
        dispatch(getJobTasks(props.job.job_id))
        notification.success({
          message: Strings.success_title,
          description: message ? message : Strings.generic_error,
          className: 'ant-success'
        });
      }).catch(err => {
        cancelTaskStateChange();
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: 'ant-error'
        })
      })
    } else {
      setConfirmInvoicing(false)
      notification.error({
        message: Strings.error_title,
        description: Strings.generic_error,
        className: 'ant-error'
      })
    }
  }

  const deleteTask = () => {
    dispatch(deleteSiteTask(props.taskDetails.id, props.job.job_id, props.taskDetails.task_number)).then((message) => {
      dispatch(getJobTasks(props.job.job_id))
      notification.success({
        message: Strings.success_title,
        description: message ? message : Strings.generic_error,
        className: 'ant-success'
      });
    }).catch(err => {
      cancelTaskStateChange();
      notification.error({
        message: Strings.error_title,
        description: err ? err : Strings.generic_error,
        className: 'ant-error'
      })
    })
  }

  const cancelTaskStateChange = () => {
    setTaskState(props.taskDetails.task_state);
  }

  const getTaskInitialValues = () => {
    let values = {
      task_name: props.taskDetails.task_name,
      area_name: props.taskDetails.area_name,
      note: props.taskDetails.task_note,
      task_total: props.taskDetails.task_cost.toString()
    };
    return values;
  }

  return (
    <>
      {isEditing ? (
        <AddNewTask
          isEditing={true}
          form={`EditTaskForm-${props.taskDetails.id}`}
          task={props.taskDetails}
          initialValues={getTaskInitialValues()}
          onClose={() => setIsEditing(false)}
        />
      ) : (
        <div className={`${styles.TaskHeader}`}>
          <div className="container-fluid">
            <div className="row align-items-top">
              <div className="col-4">
                <div className={styles.PrimaryText}>
                  <div className="font-weight-bold">
                    {props.taskDetails && props.taskDetails.task_number && props.taskDetails.task_number}
                  </div>
                  <div>
                    {props.taskDetails && props.taskDetails.task_name && props.taskDetails.task_name}
                  </div>
                  <div>
                    {props.taskDetails && props.taskDetails.area_name && props.taskDetails.area_name}
                  </div>
                  {props.taskDetails && props.taskDetails.task_note &&
                    <div>{props.taskDetails.task_note}</div>
                  }
                </div>
              </div>
              <div className="col-8 px-0">
                <div className="container-fluid px-2">
                  <div className="row align-items-center">
                    <div className={`col-2 px-0 ${props.taskDetails.task_state && props.taskDetails.task_state === "Cancelled" && (props.taskDetails.task_cost && parseFloat(props.taskDetails.task_cost)) ? styles.redLineThrough : ""}`}>
                      {props.taskDetails && props.taskDetails.task_cost && currencyFormat(props.taskDetails.task_cost)}
                    </div>
                    <div className="col-3 pl-0 pr-4">
                      <div className={`${styles.jobStatusBox} job-board-status-${taskState ? taskState.toLowerCase().replaceAll(" ", "") : ""}`}>
                        <div style={{ lineHeight: '1.5' }}>{taskState}</div>
                      </div>
                    </div>
                    <div className="col-5">
                      {invoiceStatus === 2 || invoiceStatus === 3 ?
                        <div className={`text-center ${props.taskDetails && props.taskDetails.task_state === "Started" && invoiceStatus === 2 ? styles.DisabledText : ''}`}>
                          <div>Values Approved for Invoice</div>
                          <div>{props.taskDetails.invoice_approver_name && props.taskDetails.invoice_approver_name}{props.taskDetails.invoice_approved_at ? ` - ${moment(props.taskDetails.invoice_approved_at).format("DD/MM/YYYY")}` : ''}</div>
                        </div>
                        // // Commented for New Approve Invoice Button
                        // : invoiceStatus === 1 && !props.isDisabledJob ?
                        //   <Popconfirm
                        //     title={<div onClick={e => e.stopPropagation()}>
                        //       <div className="font-weight-bold">Are you sure you want to confirm values of this task for Invoicing?</div>
                        //       <div>This change is not reversible.</div>
                        //     </div>}
                        //     visible={confirmInvoicing}
                        //     onCancel={(e) => { e.stopPropagation(); setConfirmInvoicing(false) }}
                        //     onConfirm={(e) => { e.stopPropagation(); sendTaskForInvoicing() }}>
                        //     <button onClick={e => { e.stopPropagation(); setConfirmInvoicing(true) }} className={styles.textButton}>Confirm Values Approved for Invoice</button>
                        //   </Popconfirm>
                        : <div className="text-center font-weight-bold"></div>}
                    </div>
                    <div className="col-2 ml-auto px-0 align-self-center text-right" onClick={(e) => e.stopPropagation()}>
                      {isJobTask && invoiceStatus !== 3 && (props.taskDetails.task_state && props.taskDetails.task_state.toLowerCase() !== "cancelled") && (
                        <>
                          <Icon type="edit" onClick={() => setIsEditing(true)} className="mx-1" style={{ color: "green", cursor: "pointer" }} />
                          <Popconfirm
                            title={<div>
                              <div className="font-weight-bold">Are you sure you want  to delete this task?</div>
                              <div>Deleting a Task is not reversible.</div>
                            </div>}
                            visible={visibleDeleteTask}
                            onCancel={() => { setVisibleDeleteTask(false) }}
                            onConfirm={() => deleteTask()}
                          >
                            <Icon type="delete" className="mx-1" style={{ color: "red", cursor: "pointer" }} onClick={() => setVisibleDeleteTask(true)} />
                          </Popconfirm>
                        </>
                      )}
                      <Dropdown overlay={menu} trigger={['click']}>
                        <span style={{ cursor: "pointer" }} onClick={e => { e.preventDefault() }}>
                          <Icon type="more" style={{ fontSize: "16px", padding: "0px 5px" }} />
                        </span>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">

              </div>
            </div>
          </div>
          <Modal
            className="cs-modal-v2"
            centered
            footer={null}
            visible={confirmCancel}
            onCancel={() => setConfirmCancel(false)}
            maskClosable={false}
            bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
            destroyOnClose={true}
            closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
          >
            <div className="v2-modal-container">

              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className={v2Styles.ModalHeading}>Are you sure you want to cancel task <b>{props.taskDetails.task_number}</b>?</div>
                    <div className=""><hr /></div>
                    <div className="">The task along with all the assoiciated Task frequencies will be cancelled.</div>
                    <div style={{ color: "red" }}>This action is not reversible.</div>

                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-right">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "end", columnGap: "10px" }}>
                      {submitting ? <CircularProgress size={20} /> : ""}
                      <button type="button" className={`${v2Styles.OutlinedButton}`} onClick={() => setConfirmCancel(false)} style={{ padding: "0px 5px" }}>
                        Cancel
                      </button>
                      <button
                        type="button"
                        disabled={submitting}
                        onClick={() => cancelCurrentTask()}
                        className={v2Styles.PrimaryButton}>
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            className="cs-modal-v2"
            centered
            footer={null}
            visible={cancelFrequenies}
            onCancel={() => setCancelFrequencies(false)}
            maskClosable={false}
            bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
            destroyOnClose={true}
            width="700px"
            closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
          >
            <CancelTaskFrequenciesModal job_total_amount={props.job.job_total_amount} refreshJobBoard={props.refreshJobBoard} job_id={props.job.job_id} task={props.taskDetails} onClose={() => setCancelFrequencies(false)} />
          </Modal>
        </div>
      )}
    </>
  )
}