import React from "react";
import GeneralCategory from "./GeneralCategory/GeneralCategory";
import TaskType from './TaskType/TaskType'
import SWMSControls from './SWMSControls/SWMSControls'
import Consequences from './Consequences/Consequences'
import Hazards from './Hazards/Hazards'
import LicensesTraining from './LicensesTraining/LicensesTraining'
import Standards from './Standards/Standards'
import SWMSActivity from './SWMSActivity/SWMSActivity'
import ToolType from "./ToolType/ToolType";
import PPE from "./PPE/PPE";
import Chemicals from "./Chemicals/Chemicals";
import HighRisk from "./HighRisk/HighRisk";
import SWMSCategory from "./SWMSCategory/SWMSCategory";

export const tabs = [
  {
    name: "swms_activity",
    title: "SWMS Activity",
    id: 1,
    Component: SWMSActivity
  },
  {
    name: "swms_method",
    title: "SWMS Method",
    id: 2,
    Component: SWMSCategory,
  },
  {
    name: "general_category",
    title: "SWMS Category",
    id: 3,
    Component: GeneralCategory,
  },
  {
    name: "task_type",
    title: "Task Type",
    id: 4,
    Component: TaskType
  },
  {
    name: "hazards",
    title: "Hazards",
    id: 5,
    Component: Hazards
  },
  {
    name: "consequences",
    title: "Consequences",
    id: 6,
    Component: Consequences
  },
  {
    name: "swms_controls",
    title: "SWMS Controls",
    id: 7,
    Component: SWMSControls
  },
  {
    name: "standards",
    title: "Standards",
    id: 8,
    Component: Standards
  },
  {
    name: "licenses_and_training",
    title: "Licenses and Training",
    id: 9,
    Component: LicensesTraining
  },
  {
    name: "tool_type",
    title: "Tool Types",
    id: 10,
    Component: ToolType,
  },
  {
    name: "chemicals",
    title: "Chemicals",
    id: 11,
    Component: Chemicals,
  },
  {
    name: "high_risk_work",
    title: "High Risk Work",
    id: 12,
    Component: HighRisk,
  },
  {
    name: "ppe",
    title: "PPE",
    id: 13,
    Component: PPE,
  },
];
