import moment from "moment";

export const getPriority = (date, priorities = []) => {
  let diffs = priorities.map((s, i) => {
    let sj = s.priority_value.split(" ");
    return { ...s, val: moment().add(parseInt(sj[0]), sj[1]).diff(date) }
  });
  let diff_values = diffs.filter((x, i) => x.val > 0)
  let res = Math.max(...diffs.map(x => x.val))
  if (diff_values.length > 0) {
    res = Math.min(...diff_values.map(x => x.val));
  }
  return priorities.find(s => s.id === diffs.find(x => x.val === res).id)
}

export const getNumericInput = (num) => {
  let val = 1, regexVal = num.match(/^[1-9][0-9]*/is);
  if (regexVal && regexVal.length === 1 && !isNaN(regexVal[0].trim())) {
    val = regexVal[0].trim();
  }
  return val;
}

export const isOptionsFormat = (value) => {
  return (/^[1-9][0-9]*?\s?(days?|weeks?|months?|years?)?/is).test(value)
}

export const getFormattedOptions = (num) => {
  let value = 1;
  if (num && isOptionsFormat(num)) {
    value = getNumericInput(num);
  }
  else if (num && !isNaN(num)) {
    value = parseInt(num);
  }
  let labelOptions = ["Day", "Week", "Month", "Year"];
  return labelOptions.map((val) => ({ label: `${value} ${val}${value > 1 ? 's' : ''}`, value: `${value}_${val}${value > 1 ? 's' : ''}` }));
}
