import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Checkbox, Input, notification } from "antd";

import { Strings } from "../../../../../dataProvider/localize";
import { getSwmsCategories } from "../../../../../actions/v2/JobSwmsTaskActions";
import { CircularProgress } from "@material-ui/core";

import styles from "../../JobStyle.module.css";

export default function SwmsCategoriesSelection({ onNext }) {

  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [search, setSearch] = useState("");
  const [searchCategories, setSearchCategories] = useState([]);

  const controller = useRef();
  const dispatch = useDispatch();

  const fetchCategories = () => {
    controller.current = new AbortController();
    setLoading(true);
    dispatch(getSwmsCategories({ signal: controller.current.signal }, { pagination: 0, active: 1 })).then(res => {
      setCategories(res.rows);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
      notification.error({
        message: Strings.error_title,
        description: err ? err : "There was an error while fetching Swms Category Details.",
        className: "ant-error"
      })
    })
  }

  useEffect(() => {
    fetchCategories();
    return () => {
      if (controller.current) {
        controller.current.abort()
      }
    }
  }, []);

  useEffect(() => {
    setSearchCategories(categories);
  }, [categories])

  useEffect(() => {
    if (search && search.trim().length > 2) {
      setSearchCategories(categories.filter(x => x.category_name.toLowerCase().includes(search.trim().toLowerCase())));
    } else {
      setSearchCategories(categories);
    }
  }, [search])

  const toggleCategory = (id, checked) => {
    if (checked) {
      setSelectedCategories(allSelected => [...allSelected, id]);
    } else {
      setSelectedCategories(allSelected => allSelected.filter(x => x !== id))
    }
  }

  const onSubmit = () => {
    let allCategories = categories.filter(x => selectedCategories.includes(x.id));
    onNext(allCategories);
  }

  return (
    <div>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center" style={{ height: "120px" }}>
          <CircularProgress size={20} />
        </div>
      ) : (
        <div>
          {categories && categories.length > 0 ? (
            <div className="container-fluid px-0 py-2">
              <div className="row">
                <div className="col-4 pt-1 pb-2">
                  <Input onChange={e => setSearch(e.target.value)} value={search} className={styles.FilterTextField} placeholder="Search Categories" />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="container-fluid px-0" style={{ maxHeight: "200px", overflow: "hidden auto" }}>
                    <div className="row">
                      {searchCategories.map(category => (<div className="col-4" key={category.id}>
                        <Checkbox checked={selectedCategories.includes(category.id)} onChange={(v) => toggleCategory(category.id, v.target.checked)} className="cs-select-v2">{category.category_name}</Checkbox>
                      </div>))}
                    </div>
                    {search && searchCategories.length === 0 ? (
                      <div className="row">
                        <div className="col-12 text-center" style={{ color: "red" }}>
                          Unable to find any categories.
                        </div>
                      </div>
                    ) : ""}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex justify-content-end align-items-center pt-3">
                  <button type="button" onClick={() => onSubmit()} disabled={selectedCategories.length === 0} className={styles.PrimaryButton}>Next</button>
                </div>
              </div>
            </div>
          ) : !loading ? (
            <div className="container-fluid px-0 py-2">
              <div className="row">
                <div className="col-12 text-center py-4" style={{ color: "red" }}>No Categories Available.</div>
              </div>
            </div>
          ) : ""}
        </div>)}
    </div>
  )
}