import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Typography, IconButton } from '@mui/material';
import { Add, Remove } from "@mui/icons-material";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import JSADraggableConsRow from './JSADraggableConsRow';
import styles from "../../JobStyle.module.css";

const style = {
  borderTop: '1px solid #000 !important',
  borderColor: "#000 !important",
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
};

export default function JSADraggableHazardRow({ genId, activityIndex, hazard, index, moveRow, afterDrop, change, loading }) {
  const ref = useRef(null);

  const [expanded, setExpanded] = useState(false);
  const [consequences, setConsequences] = useState([]);

  useEffect(() => {
    if (hazard && hazard.fm_swms_hazards_consequences) {
      setConsequences(hazard.fm_swms_hazards_consequences.map((x, i) => ({ ...x, consequences_order: i + 1 })))
    }
  }, [hazard])

  const toggleRow = () => {
    setExpanded(x => !x);
  }

  const [{ handlerId }, drop] = useDrop({
    accept: `TABLEROW_${genId}`,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveRow(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag, preview] = useDrag({
    previewOptions: {
      captureDraggingState: true
    },
    type: `TABLEROW_${genId}`,
    item: () => {
      return { ...hazard, index }
    },
    canDrag: !loading,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (monitor.didDrop) {
        afterDrop(item);
      }
    }
  })

  const moveCons = useCallback((dragIndex, hoverIndex) => {
    setConsequences(prevConsequences => {
      let allCons = [...prevConsequences];
      let [el] = allCons.splice(dragIndex, 1);
      allCons.splice(hoverIndex, 0, el);
      return allCons.map(({ index, ...x }, i) => ({ ...x, consequences_order: i + 1 }));
    });
  });

  const afterConsDrop = useCallback(() => {
    change(`jsa_section.activities[${activityIndex}].fm_swms_activities_hazards[${index}].fm_swms_hazards_consequences`, [...consequences]);
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
    <>
      <tr ref={preview} style={{ ...style, opacity }} data-handler-id={handlerId}>
        <td className="text-center">
          <IconButton className="p-0" style={{ height: "auto", color: "#c0b4ff" }} onClick={() => toggleRow()}>
            {expanded ? <Remove /> : <Add />}
          </IconButton>
        </td>
        <td title={hazard.hazard_name}><Typography noWrap className={styles.TableText}>{hazard.hazard_name}</Typography></td>
        <td className="text-right">
          <IconButton ref={ref} style={{ height: "auto", cursor: "grab" }} className="p-0" disabled={loading}>
            <svg xmlns="http://www.w3.org/2000/svg" className={styles.DragIcon} style={{ height: "14px", cursor: "grab" }} viewBox="0 0 320 512">
              <path fill="currentColor" d="M40 352l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zm192 0l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 320c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 192l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 160c-22.1 0-40-17.9-40-40L0 72C0 49.9 17.9 32 40 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40z" />
            </svg>
          </IconButton>
        </td>
      </tr>
      {expanded ? (
        <tr className="has-table">
          <td colSpan={3}>
            <div className="container-fluid px-0">
              <div className="row">
                <div className="col-12 pb-2 pl-5">
                  <DndProvider backend={HTML5Backend}>
                    <table className="table-hover table-sm cs-table cs-table-v2 job-swms-table-child" style={{ tableLayout: "fixed", width: "100%" }}>
                      <thead>
                        <tr>
                          <th>Consequences</th>
                          <th width="40" className="text-center">IR</th>
                          <th>Control Measures</th>
                          <th width="40" className="text-center">RR</th>
                          <th width="160">Action By</th>
                          <th width="40"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {consequences.map((cons, i) => (
                          <JSADraggableConsRow
                            consequence={cons}
                            key={cons.consequence_id}
                            genId={hazard.hazard_id}
                            index={i}
                            hazardIndex={index}
                            loading={loading}
                            activityIndex={activityIndex}
                            moveRow={moveCons}
                            afterDrop={afterConsDrop}
                            change={change}
                          />
                        ))}
                        {consequences.length === 0 ? (
                          <tr>
                            <td colSpan={6} className="text-center" style={{ color: "red" }}>No Consequences Available.</td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </DndProvider>
                </div>
              </div>
            </div>
          </td>
        </tr>
      ) : null}
    </>
  )
}
