import React, { useRef, useState, useEffect } from "react";

import { Strings } from "../../../../../dataProvider/localize";
import { getSwmsShifts, attachSwmsToShifts } from "../../../../../actions/v2/JobSwmsActions";
import styles from "../../JobStyle.module.css";

import { CircularProgress } from "@mui/material";
import { DatePicker } from "antd";
import { useDispatch } from "react-redux";
import { notification, Pagination, Checkbox } from "antd";
import moment from 'moment'

const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;

export default function AttachSwmsModal({ onClose, jobId, selectedTask, selectedSwmsVersion, allSwmsVersions }) {

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [fieldCount, setFieldCount] = useState(0);
  const [page, setPage] = useState(1);
  const [filterDate, setFilterDate] = useState(null);
  const [swmsShifts, setSwmsShifts] = useState([]);
  const [selectedShifts, setSelectedShifts] = useState([]);
  const [swmsVersionName, setSwmsVersionName] = useState(null);

  const controller = useRef();
  const dispatch = useDispatch();

  const fetchSwmsShifts = (pageNo, filterDate = null) => {
    setLoading(true);
    let shiftDateRange = [];
    if (filterDate) {
      filterDate.forEach(item => {
        shiftDateRange.push(moment(item).format("YYYY-MM-DD"));
      })
    }
    controller.current = new AbortController();
    if (page !== pageNo) {
      setPage(pageNo)
    }

    dispatch(getSwmsShifts({ signal: controller.current.signal },
      { task_id: selectedTask.task_id, job_id: jobId, ...(shiftDateRange ? { shift_date_range: shiftDateRange } : {}) },
      pageNo))
      .then(res => {
        let shifts = res.rows.map((item) => ({ label: item.formatted_date, value: `${item.job_schedule_shift_id}` }))
        setSwmsShifts(shifts);
        setFieldCount(res.count);
        setLoading(false);
      }).catch(err => {
        if (err) {
          setLoading(false);
          notification.error({
            message: Strings.error_title,
            description: err ? err : Strings.generic_error,
            className: "ant-error"
          })
          onClose();
        }
      })
  }

  useEffect(() => {
    fetchSwmsShifts(1);
    const version = allSwmsVersions.flatMap(record => record.swms_form_ids).find(item => item.swms_form_id === selectedSwmsVersion[selectedTask.task_id])
    setSwmsVersionName(version.swms_revision ? `Version ${version.swms_revision}` : "")
    return () => {
      if (controller.current) {
        controller.current.abort();
      }
    }
  }, []);

  const onChange = (list) => {
    setSelectedShifts(list);
  };
  const onCheckAllChange = (e) => {
    if (e.target.checked) {
      const allCheckedValues = swmsShifts.map(option => option.value);
      setSelectedShifts(allCheckedValues)
    }
    else {
      setSelectedShifts([])
    }
  };

  const onSubmit = () => {
    setLoading(true);
    let formData = {
      job_id: jobId,
      swms_forms: [
        {
          swms_form_id: selectedSwmsVersion[selectedTask.task_id],
          task_id: selectedTask.task_id
        }
      ],
      shifts: selectedShifts
    };
    setSubmitting(true);
    dispatch(attachSwmsToShifts(formData)).then(message => {
      setLoading(false);
      notification.success({
        message: Strings.success_title,
        message: message,
        className: "ant-success"
      });
      setSubmitting(false);
      setLoading(false);
      onClose();
    }).catch(err => {
      if (err) {
        setLoading(false);
        setSubmitting(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: "ant-error"
        });
      }
    })
  }

  const onFilter = () => {
    fetchSwmsShifts(page, filterDate)
  }

  const onClear = () => {
    setFilterDate(null)
    fetchSwmsShifts(page, null)
  }

  return (
    <div className="job-swms-modal-container">
      {loading ? (
        <div className="d-flex align-items-center justify-content-center" style={{ height: "120px" }}>
          <CircularProgress size={20} />
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className={styles.ModalHeading}>
                {`Select Shifts to attach SWMS - ${swmsVersionName}`}
              </div>
              <div>

              </div>
              <div className="pt-0">
                <hr />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div>
                <RangePicker
                  className="cs-range-picker"
                  dropdownClassName="cs-datepicker-dropdown"
                  allowClear format="DD-MM-YYYY"
                  placeholder={"Select Date"}
                  value={filterDate}
                  onChange={v => setFilterDate(v)}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <button onClick={() => onFilter()} className={styles.BtnFilterTab}>Filter</button>
                <button className={`${styles.BtnResetTab} ml-1`} onClick={() => onClear()}>Clear</button>
              </div>
            </div>
          </div>
          {swmsShifts.length ?
            <div className="col-12 mt-2">
              <div className="row">
                <div>
                  <Checkbox onChange={onCheckAllChange} className="shifts-list-check-all">Select All</Checkbox>
                </div>
              </div>
              <div className="mt-3">
                <CheckboxGroup
                  className="shifts-list-checkbox-group"
                  value={selectedShifts}
                  onChange={onChange}
                >
                  {swmsShifts.map((option) => (
                    <div key={option.value} className="mb-2 border-bottom pb-2 d-flex align-items-center justify-content-between">
                      <Checkbox value={option.value}>
                        {option.label}
                      </Checkbox>
                    </div>
                  ))}
                </CheckboxGroup>
              </div>
            </div> :
            <div className="col-12 mt-2">
              <div className="row">
                <p style={{ color: 'red' }}>Shifts Data not Available.</p>
              </div>
            </div>

          }
          <div className="row">
            <div className="col-12 d-flex justify-content-end pt-1">
              <Pagination
                className="cs-pagination-v2"
                total={fieldCount}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Shifts`}
                onChange={(pageNo) => { fetchSwmsShifts(pageNo) }}
                pageSize={15}
                current={page}
                defaultCurrent={1}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 pt-3">
              <div className="d-flex align-items-center justify-content-end" style={{ gap: "5px" }}>
                {submitting ? (
                  <CircularProgress size={20} />
                ) : ""}
                <button className={styles.OutlinedButton} onClick={() => onClose()}>Cancel</button>
                <button disabled={submitting || selectedShifts.length === 0} className={styles.PrimaryButton} onClick={() => onSubmit()}>Attach SWMS</button>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  )
}

