import React from "react";
import Select from "react-select/creatable";
import colourStyles from "../../common/new-core-ui/reactSelectStyles";

const components = {
  DropdownIndicator: null,
};

export const MultiSelect = ({
  name,
  label,
  input,
  multi,
  value,
  onChange,
  className,
  placeholder,
  options,
  defaultValue,
}) => {
  return (
    <div>
      <label>{label}</label>
      <Select
        defaultValue={defaultValue}
        isMulti={multi}
        name={name}
        isClearable={false}
        components={components}
        onChange={input.onChange}
        options={options}
        className={`basic-multi-select ${className ? className : ""}`}
        placeholder={placeholder}
        value={input.value}
        styles={colourStyles}
      />
    </div>
  );
};
