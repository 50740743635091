import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators, compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { customInput } from "../../../../../common/custom-input";
import { CustomDatepicker } from "../../../../../common/customDatepicker";
import { CustomReactTimePicker } from "../../../../../common/customReactTimePicker";
import { components } from "react-select";
import styles from "./AllocationActionsForm.module.css";
import jobStyles from "../../../../Job/JobStyle.module.css";
import * as staffCalendarActions from "../../../../../../actions/staffCalendarActions";
import * as AllocationActions from "../../../../../../actions/v2/AllocationActions";
import { CustomCheckbox } from "../../../../../common/customCheckbox";
import { notification, Popconfirm } from "antd";
import { Strings } from "../../../../../../dataProvider/localize";
import { validate } from "../validate";
import { serializeCopyRequest, serializeCreateRequest, serializeNotificationParams, serializeUpdateRequest } from "../serializer";
import { serializeDeleteStaffRowRequest, serializeValidateRequest } from "../../serializer";
import { getFormattedDateTime, getUpdatedStaffTableValues, getUsernames } from "../../utils";
import { CustomSingleSelect } from "../../../../../common/new-core-ui/CustomSingleSelect";
import colorStyles, { colorStylesMulti } from "../../../../Job/SelectV2SingleStyle";

export class AllocationActionsForm extends Component {
  state = {
    validationError: false,
    selectedStaffIds: []
  };

  componentDidUpdate = (prevProps) => {
    if (this.props !== prevProps) {
      if (this.props.selectedShiftDetails && this.props.currentAction === "UPDATE") {
        let staffs = [];
        this.props.selectedShiftDetails.staffs.forEach(staff => {
          staffs.push(staff.user_name.toString());
        })
        if (this.props.staffRows && this.props.staffRows.length > 0) {
          this.props.staffRows.forEach(staff => {
            if (!staffs.includes(staff.user_name.toString())) {
              staffs.push(staff.user_name.toString());
            }
          })
        }
        if (staffs.length === 0) {
          notification.error({
            message: Strings.error_title,
            description: 'No Staffs available for the shift.',
            onClick: () => { },
            className: "ant-error",
          });
          this.handleCancel();
        } else {
          this.setState({ selectedStaffIds: staffs })
        }
      }
    }
  }

  onSubmit = (formValues) => {
    const {
      selectedJobDetails,
      currentAction,
      staffListFormValues,
      selectedShiftDetails,
      selectedStaffRowsForCopy,
    } = this.props;

    if (currentAction === "CREATE") {
      const formData = serializeCreateRequest(
        selectedJobDetails,
        { ...formValues, validate_location: formValues.validate_location.value, state_id: formValues.state_id.value },
        staffListFormValues,
        this.props.selectedJobDetails.quoteId
      );

      if (
        !formData.staffs ||
        (Array.isArray(formData.staffs) && !formData.staffs.length)
      ) {
        return notification.warning({
          message: Strings.warning_title,
          description: "Please select at least one row from the Staff List",
          onClick: () => { },
          className: "ant-warning",
        });
      }

      this.props.actions
        .createShiftAllocation(formData)
        .then((message) => {
          this.props.actions.resetAction();
          notification.success({
            message: Strings.success_title,
            description: message ? message : Strings.success_title,
            onClick: () => { },
            className: "ant-success",
          });
        })
        .catch((message) => {
          notification.error({
            message: Strings.error_title,
            description: message ? message : Strings.generic_error,
            onClick: () => { },
            className: "ant-error",
          });
        });

      return;
    }

    if (currentAction === "COPY") {
      const formData = serializeCopyRequest(
        selectedJobDetails,
        { ...formValues, validate_location: formValues.validate_location.value, state_id: formValues.state_id.value },
        selectedStaffRowsForCopy,
        selectedShiftDetails
      );

      if (
        !formData.staffs ||
        (Array.isArray(formData.staffs) && !formData.staffs.length)
      ) {
        return notification.warning({
          message: Strings.warning_title,
          description: "Please select at least one row from the Staff List",
          onClick: () => { },
          className: "ant-warning",
        });
      }

      this.props.actions
        .copyShiftAllocation(
          formData,
          selectedJobDetails.id,
          selectedJobDetails
        )
        .then((message) => {
          this.props.actions.resetAction();
          notification.success({
            message: Strings.success_title,
            description: message ? message : Strings.success_title,
            onClick: () => { },
            className: "ant-success",
          });
        })
        .catch((message) => {
          notification.error({
            message: Strings.error_title,
            description: message ? message : Strings.generic_error,
            onClick: () => { },
            className: "ant-error",
          });
        });
    }

    if (currentAction === "UPDATE") {
      const formData = serializeUpdateRequest(
        selectedJobDetails,
        { ...formValues, validate_location: formValues.validate_location.value, state_id: formValues.state_id.value },
        selectedShiftDetails,
        this.state.selectedStaffIds,
        this.props.selectedJobDetails.quoteId
      );
      this.props.actions
        .createShiftAllocation(formData)
        .then((message) => {
          notification.success({
            message: Strings.success_title,
            description: message ? message : Strings.success_title,
            onClick: () => { },
            className: "ant-success",
          });
          this.props.actions
            .getShiftAllocations(
              this.props.selectedJobDetails.id,
              this.props.selectedJobDetails
            )
            .then(() => {
              const allShifts = this.props.allocationShifts;
              const updatedSelectedShiftDetails = allShifts.find(
                (item) =>
                  item.job_schedule_shift_id ===
                  selectedShiftDetails.job_schedule_shift_id
              );
              this.props.actions.updateShiftDetails(
                updatedSelectedShiftDetails
              );

              this.props.actions.addOrRemoveStaffRow(
                undefined,
                undefined,
                undefined,
                undefined,
                true
              );
            });
        })
        .catch((message) => {
          notification.error({
            message: Strings.error_title,
            description: message ? message : Strings.generic_error,
            onClick: () => { },
            className: "ant-error",
          });
        });
    }
  };

  handleCancel = () => {
    this.props.actions.resetAction("COPY");
  };

  handleGetStaff = () => {
    const stateId = this.props.formValues.state_id.value;
    if (typeof stateId !== "number") {
      return notification.warning({
        message: Strings.warning_title,
        description: "Please select a state",
        onClick: () => { },
        className: "ant-warning",
      });
    }
    const { selectedJobDetails, formValues, actions, staffListForCopy } =
      this.props;

    const sAId = this.props.selectedJobDetails.tasks[0].serviceAgentId;

    actions.getAllocationStaffList(
      sAId,
      selectedJobDetails.id,
      moment(formValues.start_date).format("YYYY-MM-DD"),
      moment(formValues.start_time, "H:mm:ss A").format("HH:mm:ss"),
      moment(formValues.start_time, "H:mm:ss A").format("HH:mm:ss"),
      `${moment(formValues.finish_date).format("YYYY-MM-DD")} ${moment(
        formValues.finish_time,
        "H:mm:ss A"
      ).format("HH:mm:ss")}`,
      stateId
    );

    staffListForCopy.forEach((staff, index) => this.props.change(`copystaff_${staff.user_name}-${index}`, false))
  };

  handleValidate = () => {
    const {
      actions,
      selectedShiftDetails,
      selectedJobDetails,
      formValues,
      staffListForCopy,
    } = this.props;

    let newStartDate = `${moment(formValues.copy_start_date).format("YYYY-MM-DD")} ${moment(formValues.start_time, "HH:mm:ss").format("HH:mm:ss")}`;
    let newFinishDate = `${moment(formValues.copy_end_date).format("YYYY-MM-DD")} ${moment(formValues.finish_time, "HH:mm:ss").format("HH:mm:ss")}`;

    // validating if start date time is after finish date time
    if (moment(newStartDate).isAfter(moment(newFinishDate))) {
      notification.error({
        message: Strings.error_title,
        description: "Shift Start Date Time is after Shift End Date Time.",
        className: "ant-error"
      })
    } else {

      const params = serializeValidateRequest(
        selectedShiftDetails,
        selectedJobDetails,
        formValues
      );

      staffListForCopy.forEach((staff, index) =>
        this.props.change(`copystaff_${staff.user_name}-${index}`, false)
      );

      actions
        .validateStaffForCopy(params, formValues.state_id.value)
        .then(() => { })
        .catch(() => { });
    }
  };

  handleCopyListSelect = (checked, staff) => {
    const { actions, selectedStaffRowsForCopy } = this.props;

    const updatedRows = [...selectedStaffRowsForCopy];

    if (checked) {
      updatedRows.push(staff);
    } else {
      const index = selectedStaffRowsForCopy.findIndex(
        (item) =>
          item.shift_date === staff.shift_date &&
          item.user_name === staff.user_name
      );
      updatedRows.splice(index, 1);
    }
    actions.setSelectedStaff(updatedRows);
  };

  deleteRow = (staffDetails) => {

    let staffId = staffDetails.user_name.toString();
    let staffRows = this.props.staffRows.filter(staff => staff.user_name.toString() === staffId);
    //if the user is selected but shift is not updated with that user
    if (this.props.currentAction === "UPDATE" && staffRows.length === 1) {
      this.props.actions.addOrRemoveStaffRow(false, staffDetails, this.props.staffRows);
      this.props.actions
        .getShiftAllocations(
          this.props.selectedJobDetails.id,
          this.props.selectedJobDetails
        )
        .then(() => {
          const allShifts = this.props.allocationShifts;
          const updatedSelectedShiftDetails = allShifts.find(
            (item) =>
              item.job_schedule_shift_id ===
              this.props.selectedShiftDetails.job_schedule_shift_id
          );
          this.props.actions.updateShiftDetails(
            updatedSelectedShiftDetails
          );

          this.props.actions.addOrRemoveStaffRow(
            undefined,
            undefined,
            undefined,
            undefined,
            true
          );
        });
    } else {
      const serializedParams = serializeDeleteStaffRowRequest(
        this.props.selectedShiftDetails,
        staffDetails.user_name,
        this.props.selectedJobDetails
      );

      this.props.actions
        .deleteStaffRow(serializedParams)
        .then((res) => {
          notification.success({
            message: Strings.success_title,
            description: res.message,
            onClick: () => { },
            className: "ant-success",
          });
          this.props.actions
            .getShiftAllocations(
              this.props.selectedJobDetails.id,
              this.props.selectedJobDetails
            )
            .then(() => {
              const allShifts = this.props.allocationShifts;
              const updatedSelectedShiftDetails = allShifts.find(
                (item) =>
                  item.job_schedule_shift_id ===
                  this.props.selectedShiftDetails.job_schedule_shift_id
              );
              this.props.actions.updateShiftDetails(
                updatedSelectedShiftDetails
              );

              this.props.actions.addOrRemoveStaffRow(
                undefined,
                undefined,
                undefined,
                undefined,
                true
              );
            });
          const filteredStaff = this.props.selectedShiftDetails.staffs.filter(
            (staffItem) => staffItem.user_name !== staffDetails.user_name
          );
          const updatedSelectedShiftDetails = {
            ...this.props.selectedShiftDetails,
            staffs: filteredStaff,
          };
          this.props.actions.updateShiftDetails(updatedSelectedShiftDetails);
        })
        .catch((message) => {
          notification.error({
            message: Strings.error_title,
            description: message ? message : Strings.generic_error,
            onClick: () => { },
            className: "ant-error",
          });
        });
    }
  };

  sendNotification = (mode, staffDetails) => {
    const { formValues, selectedShiftDetails } = this.props;
    // alert('clicked')
    // return

    let validated = mode !== "all";
    const staffList = [];

    if (mode === "all") {
      Object.keys(formValues).forEach((key) => {
        if (key.includes("selected_") && formValues[key]) {
          let numbers = /^[0-9]+$/;
          let staffUsername = key.split("_")[1];
          if (staffUsername.match(numbers)) {
            staffList.push(Number(staffUsername));
            validated = true;
          }
        }
      });
    }

    if (!validated) {
      return notification.warning({
        message: Strings.warning_title,
        description: "Please select a staff to notify",
        onClick: () => { },
        className: "ant-warning",
      });
    }

    const serialized =
      mode === "all"
        ? serializeNotificationParams(selectedShiftDetails, false, staffList, this.props.selectedJobDetails.accountManagerUsername)
        : serializeNotificationParams(selectedShiftDetails, [staffDetails], null, this.props.selectedJobDetails.accountManagerUsername);

    this.props.actions
      .sendNotification({ ...serialized, quote_id: this.props.selectedJobDetails.quoteId })
      .then((message) => {
        notification.success({
          message: Strings.success_title,
          description: message ? message : Strings.success_title,
          onClick: () => { },
          className: "ant-success",
        });
        this.props.actions
          .getShiftAllocations(
            this.props.selectedJobDetails.id,
            this.props.selectedJobDetails
          )
          .then(() => {
            const allShifts = this.props.allocationShifts;
            const updatedSelectedShiftDetails = allShifts.find(
              (item) =>
                item.job_schedule_shift_id ===
                selectedShiftDetails.job_schedule_shift_id
            );
            this.props.actions.updateShiftDetails(updatedSelectedShiftDetails);
          })
          .catch(() => { });
      })
      .catch((message) => {
        notification.error({
          message: Strings.error_title,
          description: message ? message : Strings.generic_error,
          onClick: () => { },
          className: "ant-error",
        });
      });
  };

  deleteShift = () => {
    const { formValues, selectedShiftDetails } = this.props;

    const staffList = [];

    Object.keys(formValues).forEach((key) => {
      if (key.includes("selected_")) {
        let numbers = /^[0-9]+$/;
        let staffUsername = key.split("_")[1];
        if (staffUsername.match(numbers)) {
          staffList.push(Number(staffUsername));
        }
      }
    });

    var notificationData = serializeNotificationParams(
      selectedShiftDetails,
      false,
      staffList,
      this.props.selectedJobDetails.accountManagerUsername
    );
    this.props.actions
      .deleteShiftAllocation(
        this.props.selectedShiftDetails.job_schedule_shift_id,
        notificationData.job_schedule,
        this.props.selectedJobDetails
      )
      .then((message) => {
        notification.success({
          message: Strings.success_title,
          description: message ? message : Strings.success_title,
          onClick: () => { },
          className: "ant-success",
        });
        this.props.actions.resetAction();
        this.props.actions
          .getShiftAllocations(
            this.props.selectedJobDetails.id,
            this.props.selectedJobDetails
          )
          .then(() => { })
          .catch(() => { });
      })
      .catch((message) => {
        notification.error({
          message: Strings.error_title,
          description: message ? message : Strings.generic_error,
          onClick: () => { },
          className: "ant-error",
        });
      });
  };

  disableDate = (current) => {
    var startDate = moment(new Date());
    startDate = startDate.subtract(1, "days");
    return current && current.valueOf() < startDate;
  };

  getHourDifference = (start, finish, breakDuration) => {
    const startMoment = new Date(start);
    const finishMoment = new Date(finish);
    var seconds = Math.floor((finishMoment - startMoment) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    let result = "";
    if (minutes % 60 > 0) {
      const decimalMinutes = ((minutes / 60) % 1).toFixed(2).split(".")[1];
      if (breakDuration === "0.50") {
        result = (Number(`${hours}.${decimalMinutes}`) - 0.5).toFixed(2);
        if (isNaN(result)) {
          return "0.00";
        } else {
          return result;
        }
      } else {
        result = `${hours}.${decimalMinutes}`;
        if (isNaN(result)) {
          return "0.00";
        } else {
          return result;
        }
      }
    } else {
      if (breakDuration === "0.50") {
        result = (Number(hours) - 0.5).toFixed(2);
        if (isNaN(result)) {
          return "0.00";
        } else {
          return result;
        }
      } else {
        result = hours.toFixed(2);
        if (isNaN(result)) {
          return "0.00";
        } else {
          return result;
        }
      }
    }
  };

  calculateDurations = (startD, startT, finishD, finishT) => {
    const { actions, formValues, currentAction } = this.props;
    const {
      start_date,
      start_time,
      finish_date,
      finish_time,
      paid_break: paid,
    } = formValues;

    const startDate = getFormattedDateTime(startD, start_date, "YYYY-MM-DD");
    const startTime = getFormattedDateTime(
      startT,
      start_time,
      "HH:mm:ss",
      true
    );
    const finishDate = getFormattedDateTime(finishD, finish_date, "YYYY-MM-DD");
    const finishTime = getFormattedDateTime(
      finishT,
      finish_time,
      "HH:mm:ss",
      true
    );

    const start = new Date(`${startDate} ${startTime}`);
    const finish = new Date(`${finishDate} ${finishTime}`);

    const diffTime = finish - start;
    const diffHours = diffTime / (1000 * 60 * 60);

    if (diffHours < 0) {
      this.setState({ validationError: true });
      notification.warning({
        message: Strings.warning_title,
        description:
          "Start date/time cannot be after finish date/time. Please edit your entries and try again.",
        onClick: () => { },
        className: "ant-warning",
      });
    } else {
      this.setState({ validationError: false });
    }

    const usernames = getUsernames(formValues, currentAction);
    const updatedStaffTableValues = getUpdatedStaffTableValues(
      usernames,
      formValues,
      currentAction,
      start,
      finish,
      startDate,
      startTime,
      finishDate,
      finishTime
    );

    actions.modifyInitialValues({
      ...formValues,
      work_duration: diffHours,
      break: diffHours > 4 ? "0.5" : "0.0",
      total_duration: paid ? diffHours + 0.5 : diffHours,
      ...(startD ? { start_date: startD } : {}),
      ...(startT ? { start_time: startT } : {}),
      ...(finishD ? { finish_date: finishD } : {}),
      ...(finishT ? { finish_time: finishT } : {}),
      ...(diffHours > 0 ? updatedStaffTableValues : {}),
    });
  };

  handlePaidBreakChange = (val) => {
    const { actions, formValues } = this.props;
    actions.modifyInitialValues(
      val
        ? {
          ...formValues,
          paid_break: val,
          total_duration:
            Number(formValues.work_duration) + Number(formValues.break),
        }
        : {
          ...formValues,
          paid_break: val,
          total_duration: formValues.work_duration,
        }
    );
  };

  handleAllStaffSelect = (value, type) => {
    const { formValues } = this.props;
    if (type === "create") {
      Object.keys(formValues)
        .filter((key) => key.startsWith("createstaff_"))
        .forEach((key) => this.props.change(key, value));
    }

    if (type === "update") {
      Object.keys(formValues)
        .filter((key) => key.startsWith("selected_"))
        .forEach((key) => this.props.change(key, value));
    }
  };

  getFormTitle = (currentAction) => {
    return currentAction === "CREATE"
      ? "Create Shift"
      : currentAction === "COPY"
        ? "Copy Shift"
        : "Update Shift";
  };

  isBefore = (finishDate) => {
    const finish = moment(finishDate).format();
    const today = moment(new Date()).format();
    return moment(finish).isBefore(today) ? false : true;
  };

  isOngoing = (startDate, finishDate) => {
    const start = moment(startDate).format();
    const finish = moment(finishDate).format();
    const today = moment(new Date()).format();

    return moment(today).isBefore(finish) && moment(start).isBefore(today)
      ? true
      : false;
  };

  handleCopyAllListSelect = (e, val) => {
    const updatedRows = [];

    this.props.staffListForCopy.forEach((staff, index) => {
      this.props.change(`copystaff_${staff.user_name}-${index}`, val);
      updatedRows.push(staff);
    });

    this.props.actions.setSelectedStaff(updatedRows);
  };

  handleStaffRowDelete = (staff) => { };

  handleDisableSendNotification = () => {

    let isNewRowAdded = this.props.staffRows.filter(row =>
      row.hasOwnProperty('isNewRow')
    ).length > 0;

    return isNewRowAdded
  }


  render() {
    const {
      handleSubmit,
      statesList,
      selectedJobDetails,
      staffRows,
      currentAction,
      staffListForCopy,
    } = this.props;

    const tasksSourceData =
      selectedJobDetails &&
      selectedJobDetails.sites &&
      selectedJobDetails.tasks &&
      selectedJobDetails.tasks[0] &&
      selectedJobDetails.sites.find(
        (site) => site.id === selectedJobDetails.tasks[0].siteId
      ).tasks;

    return (
      <div>
        <div className={jobStyles.SectionHeading}>{this.getFormTitle(currentAction)}</div>
        <div className={jobStyles.card}>
          <form onSubmit={handleSubmit(this.onSubmit)} className="allocation-actions-form">
            <div className={styles.FormContainer}>
              <div className={styles.FetchFieldsWrapper}>
                <div className="col-md-12">
                  <div className="container-fluid m-0 p-0">
                    <div className="row">
                      <fieldset className="col-6 pl-0 pb-2">
                        <div className={jobStyles.CommonLabel}>Start Date</div>
                        <Field
                          name="start_date"
                          placeholder={moment(new Date()).format("YYYY-MM-DD")}
                          type="date"
                          component={CustomDatepicker}
                          disabledDate={this.disableDate}
                          onChange={(val) =>
                            this.calculateDurations(
                              val,
                              undefined,
                              undefined,
                              undefined
                            )
                          }
                          className="job-v2-date-picker"
                          dropdownClassName="cs-datepicker-dropdown"
                          disabled={currentAction === "COPY"}
                        />
                      </fieldset>
                      <fieldset className="col-6 px-0 pb-2">
                        <div className={jobStyles.CommonLabel}>Start Time</div>
                        <Field
                          name="start_time"
                          wrapperClassName="w-100"
                          component={CustomReactTimePicker}
                          onChange={(val) =>
                            this.calculateDurations(
                              undefined,
                              val,
                              undefined,
                              undefined
                            )
                          }
                          className={jobStyles.InputFormField}
                          disabled={currentAction === "COPY"}
                        />
                      </fieldset>
                      <fieldset className="col-6 pl-0 pb-2">
                        <div className={jobStyles.CommonLabel}>Finish Date</div>
                        <Field
                          name="finish_date"
                          placeholder={moment(new Date()).format("YYYY-MM-DD")}
                          type="date"
                          component={CustomDatepicker}
                          disabledDate={this.disableDate}
                          onChange={(val) =>
                            this.calculateDurations(
                              undefined,
                              undefined,
                              val,
                              undefined
                            )
                          }
                          disabled={currentAction === "COPY"}
                          className="job-v2-date-picker"
                          dropdownClassName="cs-datepicker-dropdown"
                        />
                      </fieldset>
                      <fieldset className="col-6 px-0 pb-2">
                        <div className={jobStyles.CommonLabel}>Finish Time</div>
                        <Field
                          name="finish_time"
                          component={CustomReactTimePicker}
                          onChange={(val) =>
                            this.calculateDurations(
                              undefined,
                              undefined,
                              undefined,
                              val
                            )
                          }
                          className={jobStyles.InputFormField}
                          wrapperClassName="w-100"
                          disabled={currentAction === "COPY"}
                        />
                      </fieldset>
                    </div>
                    <div className="row">
                      <div className="col-6 pl-0 pb-2">
                        <fieldset className="select-wibg">
                          <div className={jobStyles.CommonLabel}>Select Tasks</div>
                          <Field
                            name="tasks"
                            placeholder="Select Tasks"
                            // type="multiple"
                            options={tasksSourceData.map((task) => {
                              return {
                                value: task.id,
                                label: `${task.job_task_label}: ${task.task_name} - ${task.area_name}`,
                              };
                            })}
                            isMulti
                            component={CustomSingleSelect}
                            onClick={() => { }}
                            styles={colorStylesMulti}
                            otherProps={{
                              components: { dropdownIndicator: <components.DownChevron /> },
                              isDisabled: currentAction === "Copy",
                              mode: "tags",
                            }}
                          />
                        </fieldset>
                      </div>
                      <div className="col-6 px-0 pb-2">
                        <div className={jobStyles.CommonLabel}>Validate Location</div>
                        <Field
                          name="validate_location"
                          options={[
                            { label: "Yes", value: 1 },
                            { label: "No", value: 0 },
                          ]}
                          component={CustomSingleSelect}
                          styles={colorStyles}
                          otherProps={{
                            components: { dropdownIndicator: <components.DownChevron /> }
                          }}
                        />
                      </div>
                      <div className="col-6 pl-0 pb-2">
                        <div className={jobStyles.CommonLabel}>State</div>
                        <Field
                          name="state_id"
                          component={CustomSingleSelect}
                          placeholder="Select State"
                          options={statesList.map((state) => ({
                            label: state.name,
                            value: state.id,
                          }))}
                          disabled={currentAction === "COPY"}
                          styles={colorStyles}
                          otherProps={{
                            components: { dropdownIndicator: <components.DownChevron /> }
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4 pl-0 pb-2">
                        <div className={jobStyles.CommonLabel}>Work Duration</div>
                        <Field
                          name="work_duration"
                          type="text"
                          disabled
                          className={jobStyles.InputFormField}
                          component={customInput}
                        />
                      </div>
                      <div className="col-4 pl-0 pb-2">
                        <div className={jobStyles.CommonLabel}>Break</div>
                        <Field
                          name="break"
                          type="text"
                          disabled
                          className={jobStyles.InputFormField}
                          component={customInput}
                        />
                      </div>
                      <div className="col-4 px-0 pb-2">
                        <div className={jobStyles.CommonLabel}>Total Duration</div>
                        <Field
                          name="total_duration"
                          type="text"
                          className={jobStyles.InputFormField}
                          disabled
                          component={customInput}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {(currentAction !== "COPY") && (
                <div className="d-flex align-items-center justify-content-end mt-3">
                  <button
                    onClick={this.handleGetStaff}
                    type="button"
                    className={jobStyles.PrimaryButton}>
                    Get Staff
                  </button>
                </div>
              )}
              {(currentAction === "CREATE" &&
                this.props.staffFormValues &&
                Object.keys(this.props.staffFormValues).length > 0) ||
                currentAction === "UPDATE" ? (
                <div className={`${jobStyles.BackgroundSectionHeading} mt-3`}>Shift Details</div>
              ) : null}
              {currentAction === "CREATE" &&
                this.props.staffFormValues &&
                Object.keys(this.props.staffFormValues).length > 0 &&
                Object.keys(this.props.staffFormValues).some((k) =>
                  k.startsWith("addlist_staff_")
                ) &&
                staffRows.length > 0 && (
                  <>
                    <div className={jobStyles.card} style={{ borderTop: "0px", borderRadius: "0px 0px 8px 8px", borderColor: "#eaeaea" }}>
                      <table className="table table-sm cs-table-job-board cs-table-job-board-v2">
                        <thead>
                          <tr>
                            <th width="60">Docs</th>
                            <th>Staff Name</th>
                            <th>Role</th>
                            <th width="110">Is Supervisor?</th>
                            <th>Reporting at?</th>
                            <th>Start Date</th>
                            <th>Start Time</th>
                            <th>Finish Date</th>
                            <th>Finish Time</th>
                            <th width="50" className="text-center">
                              <Field
                                component={CustomCheckbox}
                                name="select_all_staff"
                                onChange={(_, val) =>
                                  this.handleAllStaffSelect(val, "create")
                                }
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {staffRows.map((staff) => (
                            <tr key={staff.user_name}>
                              <td></td>
                              <td>{staff.first_name}</td>
                              <td>{staff.role_name}</td>
                              <td>
                                <Field
                                  component={CustomCheckbox}
                                  name={`${staff.user_name}_is_supervisor`}
                                  className="cs-select-v2"
                                />
                              </td>
                              <td>
                                <Field
                                  className="report_to"
                                  name={`${staff.user_name}_start_point`}
                                  placeholder={"Select"}
                                  options={[
                                    {
                                      label: "Yard",
                                      value: "yard",
                                    },
                                    {
                                      label: "Site",
                                      value: "site",
                                    },
                                  ]}
                                  size="small"
                                  component={CustomSingleSelect}
                                  styles={colorStyles}
                                  otherProps={{
                                    components: { dropdownIndicator: <components.DownChevron /> }
                                  }}
                                />
                              </td>
                              <td>
                                <Field
                                  name={`${staff.user_name}_start_date`}
                                  placeholder={moment(new Date()).format(
                                    "YYYY-MM-DD"
                                  )}
                                  type="date"
                                  id="start_date"
                                  size="small"
                                  disabledDate={this.disableDate}
                                  component={CustomDatepicker}
                                  className="job-v2-date-picker"
                                  dropdownClassName="cs-datepicker-dropdown"
                                />
                              </td>
                              <td>
                                <Field
                                  name={`${staff.user_name}_start_time`}
                                  component={CustomReactTimePicker}
                                  placement="top-start"
                                  className={jobStyles.InputFormField}
                                />
                              </td>
                              <td>
                                <Field
                                  name={`${staff.user_name}_finish_date`}
                                  placeholder={moment(new Date()).format(
                                    "YYYY-MM-DD"
                                  )}
                                  type="date"
                                  id="finish_date"
                                  size="small"
                                  disabledDate={this.disableDate}
                                  component={CustomDatepicker}
                                  className="job-v2-date-picker"
                                  dropdownClassName="cs-datepicker-dropdown"
                                />
                              </td>
                              <td>
                                <Field
                                  name={`${staff.user_name}_finish_time`}
                                  component={CustomReactTimePicker}
                                  placement="top-start"
                                  className={jobStyles.InputFormField}
                                />
                              </td>
                              <td className="text-center">
                                <Field
                                  component={CustomCheckbox}
                                  className="cs-select-v2"
                                  name={`createstaff_${staff.user_name}`}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}

              {currentAction === "COPY" && (
                <div className={jobStyles.card}>
                  <div className="mb-3 p-0">
                    <div className={jobStyles.SectionHeading} style={{ margin: "0px" }}>Copy Shift </div>
                    <div className="d-flex align-items-center w-100" style={{ columnGap: "10px" }}>
                      <div style={{ flex: "1 0 auto" }}>
                        <div className={jobStyles.CommonLabel}>Start Date</div>
                        <Field
                          name="copy_start_date"
                          placeholder={moment(new Date()).format("YYYY-MM-DD")}
                          type="date"
                          id="copy_start_date"
                          disabledDate={this.disableDate}
                          component={CustomDatepicker}
                          className="job-v2-date-picker"
                          dropdownClassName="cs-datepicker-dropdown"
                        />
                      </div>
                      <div style={{ flex: "1 0 auto" }}>
                        <div className={jobStyles.CommonLabel}>End Date</div>
                        <Field
                          name="copy_end_date"
                          placeholder={moment(new Date()).format("YYYY-MM-DD")}
                          type="date"
                          id="copy_end_date"
                          disabledDate={this.disableDate}
                          component={CustomDatepicker}
                          className="job-v2-date-picker"
                          dropdownClassName="cs-datepicker-dropdown"
                        />
                      </div>
                    </div>
                    <div className="mt-3 text-right">
                      <button
                        type="button"
                        className={jobStyles.PrimaryButton}
                        onClick={this.handleValidate}>
                        Validate
                      </button>
                    </div>
                  </div>
                  {staffListForCopy && staffListForCopy.length > 0 ? (
                    <table className="table table-sm cs-table-job-board cs-table-job-board-v2">
                      <thead>
                        <tr>
                          <th width="60">
                            <Field
                              component={CustomCheckbox}
                              name={`copy_select_all_staff`}
                              onChange={this.handleCopyAllListSelect}
                              className="cs-select-v2"
                            />
                          </th>
                          <th>Name</th>
                          <th>Role</th>
                          <th>Shift Date</th>
                          <th width="110" className="text-center">Availability</th>
                        </tr>
                      </thead>
                      <tbody>
                        {staffListForCopy.map((staff, index) => (
                          <tr key={staff.user_name}>
                            <td>
                              <Field
                                component={CustomCheckbox}
                                name={`copystaff_${staff.user_name}-${index}`}
                                onChange={(event, checked) =>
                                  this.handleCopyListSelect(checked, staff)
                                }
                                className="cs-select-v2"
                              />
                            </td>
                            <td>{staff.staff_name}</td>
                            <td>{staff.role_name}</td>
                            <td>{staff.shift_date ? moment(staff.shift_date).format("DD-MM-YYYY") : ""}</td>
                            <td className="text-center">
                              {!staff.staff_status ? (
                                <span className="material-icons icon-task-panel complete-icon" style={{ fontSize: "20px" }}>
                                  check
                                </span>
                              ) : (
                                <span className="material-icons icon-task-panel incomplete-icon" style={{ fontSize: "20px" }}>
                                  clear
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : ""}
                </div>
              )}

              {currentAction === "UPDATE" &&
                this.props.selectedShiftDetails &&
                this.props.selectedShiftDetails.staffs && (
                  <div className={jobStyles.card} style={{ borderTop: "0px", borderRadius: "0px 0px 8px 8px", borderColor: "#eaeaea" }}>
                    <table className="table table-sm cs-table-job-board cs-table-job-board-v2">
                      <thead>
                        <tr>
                          <th width="50">
                            <Field
                              component={CustomCheckbox}
                              name="select_all_staff_update"
                              onChange={(_, val) =>
                                this.handleAllStaffSelect(val, "update")
                              }
                              className="cs-select-v2"
                            />
                          </th>
                          <th>Staff Name</th>
                          <th>Role</th>
                          <th width="110">Is Supervisor?</th>
                          <th>Reporting at?</th>
                          <th>Start Date</th>
                          <th>Start Time</th>
                          <th>Finish Date</th>
                          <th>Finish Time</th>
                          <th width="110" className="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.selectedShiftDetails.staffs
                          .concat(this.props.staffRows)
                          .map((staff) => (
                            <tr key={staff.user_name}>
                              <td>
                                <Field
                                  component={CustomCheckbox}
                                  name={`selected_${staff.user_name}`}
                                  className="cs-select-v2"
                                />
                              </td>
                              <td>
                                {staff.staff_name || staff.first_name}
                              </td>
                              <td>{staff.role_name}</td>
                              <td>
                                <Field
                                  component={CustomCheckbox}
                                  className="cs-select-v2"
                                  name={`update_${staff.user_name}_is_supervisor`}
                                />
                              </td>
                              <td>
                                <Field
                                  className="report_to"
                                  name={`update_${staff.user_name}_start_point`}
                                  placeholder={"Select"}
                                  options={[
                                    {
                                      label: "Yard",
                                      value: "yard",
                                    },
                                    {
                                      label: "Site",
                                      value: "site",
                                    },
                                  ]}
                                  size="small"
                                  component={CustomSingleSelect}
                                  styles={colorStyles}
                                  otherProps={{
                                    components: { dropdownIndicator: <components.DownChevron /> }
                                  }}
                                />
                              </td>
                              <td>
                                <Field
                                  name={`update_${staff.user_name}_start_date`}
                                  placeholder={moment(new Date()).format(
                                    "YYYY-MM-DD"
                                  )}
                                  type="date"
                                  id="start_date"
                                  size="small"
                                  disabledDate={this.disableDate}
                                  component={CustomDatepicker}
                                  className="job-v2-date-picker"
                                  dropdownClassName="cs-datepicker-dropdown"
                                />
                              </td>
                              <td>
                                <Field
                                  name={`update_${staff.user_name}_start_time`}
                                  component={CustomReactTimePicker}
                                  placement="top-start"
                                  className={jobStyles.InputFormField}
                                />
                              </td>
                              <td>
                                <Field
                                  name={`update_${staff.user_name}_finish_date`}
                                  placeholder={moment(new Date()).format(
                                    "YYYY-MM-DD"
                                  )}
                                  type="date"
                                  id="finish_date"
                                  size="small"
                                  disabledDate={this.disableDate}
                                  component={CustomDatepicker}
                                  className="job-v2-date-picker"
                                  dropdownClassName="cs-datepicker-dropdown"
                                />
                              </td>
                              <td>
                                <Field
                                  name={`update_${staff.user_name}_finish_time`}
                                  component={CustomReactTimePicker}
                                  placement="top-start"
                                  className={jobStyles.InputFormField}
                                />
                              </td>
                              <td>
                                {!staff.isNewRow ? (
                                  <div className="d-flex align-items-center justify-content-center" style={{ columnGap: "15px" }}>
                                    <span className={"dot " + (staff.notification_send_status === 1 ? "bg-yellow" : staff.job_shift_accept_status === 1 ? "bg-green" : "")} />
                                    <Popconfirm
                                      title={
                                        Strings.user_list_confirm_delete_alert
                                      }
                                      onConfirm={() => this.deleteRow(staff)}
                                      placement="topRight"
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <button
                                        type="button"
                                        className="delete-bnt user-actions m-0"
                                      >
                                        <i
                                          className="fa fa-trash"
                                          style={{
                                            color: "red",
                                          }}
                                        ></i>
                                      </button>
                                    </Popconfirm>
                                    <Popconfirm
                                      title={
                                        "Do you want to notify this staff member?"
                                      }
                                      onConfirm={() =>
                                        this.sendNotification("single", staff)
                                      }
                                      placement="topRight"
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <button
                                        type="button"
                                        className={`delete-bnt user-actions ${styles.BellIcon} m-0`}
                                        onClick={() =>
                                          this.handleStaffRowDelete(staff)
                                        }
                                      >
                                        <i className="fa fa-bell-o"></i>
                                      </button>
                                    </Popconfirm>
                                  </div>
                                ) : null}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                )}
            </div>
            <div className={styles.ActionButtonsWrapper}>
              {staffRows.length ||
                (this.props.selectedShiftDetails &&
                  this.props.selectedShiftDetails.staffs &&
                  this.props.selectedShiftDetails.staffs.length) ? (
                <div className={styles.GetStaffButtonLeft}>
                  <button
                    onClick={this.handleValidate}
                    type="button"
                    className={jobStyles.PrimaryButton}
                  >
                    Refresh
                  </button>
                </div>
              ) : null}
              <div className={styles.GetStaffButton}>
                <button
                  onClick={this.handleCancel}
                  type="button"
                  className={jobStyles.FilterResetButton}
                >
                  Cancel
                </button>
              </div>
              {currentAction === "UPDATE" &&
                this.props.selectedShiftDetails &&
                this.props.selectedShiftDetails.finish_time && (
                  <>
                    {!this.isOngoing(
                      `${this.props.selectedShiftDetails.shift_date} ${this.props.selectedShiftDetails.site_time}`,
                      this.props.selectedShiftDetails.finish_time
                    ) &&
                      this.isBefore(this.props.selectedShiftDetails.finish_time) ? (
                      <div className={styles.GetStaffButton}>
                        <button
                          onClick={this.deleteShift}
                          type="button"
                          className={jobStyles.SecondaryButton}
                        >
                          Delete Shift
                        </button>
                      </div>
                    ) : null}
                    <div className={styles.GetStaffButton}>
                      <button
                        onClick={() => this.sendNotification("all")}
                        type="button"
                        className={jobStyles.PrimaryButton}
                        disabled={this.handleDisableSendNotification()}
                      >
                        Send Notification
                      </button>
                    </div>
                  </>
                )}
              <div className={styles.GetStaffButton}>
                <button
                  disabled={this.state.validationError}
                  type="submit"
                  className={jobStyles.PrimaryButton}
                >
                  {currentAction === "UPDATE" ? "Update Shift" : "Create Shift"}
                </button>
              </div>
            </div>
          </form>
        </div >
      </div >
    );
  }
}

const mapStateToProps = (state) => ({
  allocationShifts: state.Allocations.shifts,
  selectedShiftDetails: state.Allocations.selectedShiftDetails,
  initialValues: {
    ...state.Allocations.initialValues,
    state_id: state.profileManagement.statesList ? state.profileManagement.statesList.filter(x => x.id === state.Allocations.initialValues.state_id).map(x => ({ label: x.name, value: x.id }))[0] : null,
    validate_location: state.Allocations.initialValues.validate_location === 1 ? { label: "Yes", value: 1 } : { label: "No", value: 0 }
  },
  statesList: state.profileManagement.statesList,
  selectedJobDetails: state.JobCalendar.selectedJobDetails,
  staffList: state.Allocations.staffList,
  formValues:
    state.form.AllocationActionsForm && state.form.AllocationActionsForm.values,
  staffFormValues: state.form.StaffListForm && state.form.StaffListForm.values,
  currentAction: state.Allocations.currentAction,
  staffRows: state.Allocations.staffRows,
  staffListForCopy: state.Allocations.staffListForCopy,
  selectedStaffRowsForCopy: state.Allocations.selectedStaffRowsForCopy,
  staffListFormValues:
    state.form.StaffListForm && state.form.StaffListForm.values,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(AllocationActions, dispatch),
    staffActions: bindActionCreators(staffCalendarActions, dispatch),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "AllocationActionsForm",
    enableReinitialize: true,
    validate: (values, { currentAction }) => validate(values, currentAction),
  })
)(AllocationActionsForm);
