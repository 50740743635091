import React from "react";
import { Field } from "redux-form";
import { customInput } from "../../../../../common/custom-input";
import { CustomSwitch } from "../../../../../common/customSwitch";
import styles from "../../../SWMSMastersContainer.module.css";
import { Tooltip, Popconfirm } from "antd";
import emailEditor from "../../../../../common/EmailEditor";
import ReactHtmlParser from "react-html-parser";
import { Strings } from '../../../../../../dataProvider/localize';

export const fetchColumns = (
  save,
  edit,
  cancel,
  changeStatus,
  editingKey,
  editorState,
  changeEditorState,
  formValues,
  editorStateValue,
  editorStateValueForEdit,
  deleteRow
) => [
    {
      title: "Control ID",
      dataIndex: "title",
      sorter: (a, b) => a.title < b.title ? -1 : a.title > b.title ? 1 : 0,
      editable: true,
      width: 150,
      render: (cellContent, rowData, rowIndex) =>
        rowData.key === "addRow" ? (
          <div>
            <Field name="title" component={customInput} autoFocus />
          </div>
        ) : editingKey === rowData.id ? (
          <div>
            <Field
              name={`title_${rowData.id}`}
              component={customInput}
              autoFocus
            />
          </div>
        ) : (
          <Tooltip title={cellContent}>
            <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
              {cellContent}
            </div>
          </Tooltip>
        ),
    },
    {
      title: "Details",
      dataIndex: "description",
      editable: true,
      width: 300,
      render: (cellContent, rowData, rowIndex) =>
        rowData.key === "addRow" ? (
          <div>
            <Field
              name="description"
              type="text"
              component={emailEditor}
              editorState={editorStateValue ? editorStateValue : editorState()}
              onEditorStateChange={(editorState) =>
                changeEditorState(editorState)
              }
            />
          </div>
        ) : editingKey === rowData.id ? (
          <div>
            <Field
              name="description"
              type="text"
              component={emailEditor}
              editorState={editorStateValueForEdit ? editorStateValueForEdit : editorState(cellContent)}
              onEditorStateChange={(editorState) =>
                changeEditorState(editorState, true)
              }
            />
          </div>
        ) : (
          <Tooltip title={ReactHtmlParser(cellContent)}>
            <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
              {ReactHtmlParser(cellContent)}
            </div>
          </Tooltip>
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      editable: true,
      width: 75,
      render: (cellContent, rowData, rowIndex) => {
        return rowData.key === "addRow" ? (
          <div className={styles.centeredCell}>
            <Field name="active" size="small" component={CustomSwitch} />
          </div>
        ) : editingKey === rowData.id ? (
          <div className={styles.centeredCell}>
            <Field
              name={`status_${rowData.id}`}
              size="small"
              component={CustomSwitch}
            />
          </div>
        ) : (
          <div className={styles.centeredCell}>
            <Field
              name={`status_${rowData.id}`}
              val={rowData.active}
              size="small"
              component={CustomSwitch}
              onChange={(e, value) => changeStatus(value, rowData, rowIndex)}
            />
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      editable: false,
      width: 75,
      render: (cellContent, rowData, rowIndex) =>
        rowData.key === "addRow" || editingKey === rowData.id ? (
          <div className={styles.spacedCell}>
            <i
              class="fa fa-save"
              style={{
                color:
                /* user.role && user.role_id > 0 ? */ "green" /* : 'red' */,
              }}
              aria-hidden="true"
              onClick={() =>
                editingKey
                  ? save(rowData, rowIndex, true)
                  : save(rowData, rowIndex, false)
              }
            ></i>
            <i
              class="fa fa-close"
              style={{
                color: /* user.role && user.role_id > 0 ? */ "red" /* : 'red' */,
              }}
              aria-hidden="true"
              onClick={() => cancel(rowData, rowIndex)}
            ></i>
          </div>
        ) : (
          <div className={styles.centeredCell}>
            <i
              class="fa fa-pencil"
              style={{
                color:
                /* user.role && user.role_id > 0 ? */ "green" /* : 'red' */,
              }}
              aria-hidden="true"
              onClick={() => edit(rowData, rowIndex)}
            ></i>
            <Popconfirm
              title={Strings.confirm_control_delete}
              onConfirm={() => deleteRow(rowData)}
              placement="topRight"
              okText="Yes"
              cancelText="No"
            >
              <button type="button" className='delete-bnt user-actions' >
                <i
                  class="fa fa-trash"
                  style={{
                    color: "#03A791",
                  }}
                ></i>
              </button>
            </Popconfirm>
          </div>
        ),
    },
  ];
