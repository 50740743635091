import React from 'react'
import { connect } from 'react-redux'
import { submit } from 'redux-form'
import styles from "../../../JobStyle.module.css";

const POEmailButton = ({ dispatch, contractor, submitting }) =>
  <button
    disabled={submitting}
    className={styles.PrimaryButton} onClick={() => dispatch(submit(`po_email_${contractor.contractor_id}`))}>
    Submit
  </button>

export default connect()(POEmailButton)