import { notification } from "antd";
import { Strings } from "../../../../dataProvider/localize";

export const downLoadDoc = (url) => {
  new Promise((resolve, reject) => {
    fetch(url, { headers: { "Cache-Control": 'no-cache' } }).then((res) => {
      if (!res.ok) {
        reject();
      } else {
        res.blob().then((blob) => {
          var file = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = file;
          let fileName = url.substring(url.lastIndexOf('/') + 1).toString();
          a.download = fileName;
          a.click();
          a.remove();
          resolve();
        }).catch(() => {
          reject()
        })
      }
    }).catch(() => {
      reject();
    })
  }).catch(() => {
    notification.error({
      message: Strings.error_title,
      description: Strings.file_download_error,
      className: "ant-error",
    })
  })
}
