import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function ClientRedirectPage(props) {

  const [errored, setErrored] = useState(false);

  const location = useLocation();
  useEffect(() => {
    try {
      if (process.env.REACT_APP_CLIENT_APP_URL) {
        let url = new URL(process.env.REACT_APP_CLIENT_APP_URL);
        url.pathname = location.pathname;
        url.search = location.search;
        window.location.href = url.toString();
      } else {
        setErrored(true);
      }
    } catch (err) {
      setErrored(true);
    }
  }, [])

  return (
    <div>
      {!errored ? (
        <div className="d-flex align-items-center justify-content-center vh-100">
          <h5>You are being redirected. Please wait.</h5>
        </div>
      ) : props.children ? props.children : (
        <div className="d-flex align-items-center justify-content-center vh-100">
          <h5>An error occured while redirecting you to the requested page.</h5>
        </div>
      )}
    </div>
  )

} 