import { scopeApiInstance } from "../../dataProvider/axiosHelper";
import { Strings } from "../../dataProvider/localize";
import { SWMS_CONSEQUENCES_URL } from "../../dataProvider/constant";

export const getConsequences = (abortController, params = {}) => () => {
  return scopeApiInstance.get(SWMS_CONSEQUENCES_URL, { params: params, ...abortController }).then(res => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const addUpdateConsequence = (formData, isAdding = false) => () => {

  let method = isAdding ? scopeApiInstance.post : scopeApiInstance.put;
  return method(SWMS_CONSEQUENCES_URL, formData).then(res => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject();
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}
