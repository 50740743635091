import moment from "moment";
import { getFormattedInitialValues } from "../../components/v2/JobDetails/JobAllocation/serializer";

const twoWeeksAgo = new Date();
twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

const INITIAL_STATE = {
  copying: 0,
  updating: 0,
  deleteing: 0,
  shifts: [],
  selectedShiftDetails: {},
  initialValues: {},
  currentAction: "",
  staffList: [],
  taskList: [],
  staffRows: [],
  staffListForCopy: [],
  selectedStaffRowsForCopy: [],
  timesheetList: [],
  currentSelectedShift: {},
  timesheetInitialValues: {
    shift_id: [],
    shift_status: [],
    approved: false,
    shift_start: moment(twoWeeksAgo).format("YYYY-MM-DD"),
    shift_end: moment(new Date()).format("YYYY-MM-DD"),
  },
  shiftIdDataSource: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "INIT_ALLOCATIONS_CALENDAR": {
      return {
        ...state,
        shifts: [],
      };
    }
    case "GET_SHIFT_ALLOCATIONS": {
      return {
        ...state,
        shifts: action.payload,
      };
    }
    case "START_ALLOCATION_FORM_ACTION": {
      return {
        ...state,
        currentAction: action.payload.actionType,
        ...(action.payload.actionType === "CREATE" ||
        action.payload.actionType === "UPDATE"
          ? {
              initialValues: getFormattedInitialValues(
                action.payload.actionType,
                action.payload.jobDetails,
                action.payload.actionType === "UPDATE"
                  ? action.payload.shiftDetails
                  : null,
                action.payload.event
              ),
              staffRows: [],
              staffList: [],
              selectedShiftDetails:
                action.payload.actionType === "UPDATE"
                  ? action.payload.shiftDetails
                  : {},
            }
          : {}),
        ...(action.payload.shiftDetails && action.payload.actionType === "COPY"
          ? {
              selectedShiftDetails: action.payload.shiftDetails,
              initialValues: getFormattedInitialValues(
                "COPY",
                action.payload.jobDetails,
                action.payload.shiftDetails,
                null
              ),
            }
          : {}),
      };
    }
    case "RESET_ALLOCATION_FORM_ACTION": {
      return {
        ...state,
        currentAction: action.payload.actionType,
        staffList: [],
        taskList: [],
        staffRows: [],
        staffListForCopy: [],
      };
    }
    case "GET_STAFF_LIST_FOR_ALLOCATION": {
      return {
        ...state,
        staffList: action.payload,
      };
    }
    case "ADD_OR_REMOVE_STAFF_ROW": {
      return {
        ...state,
        staffRows: action.payload.staffRows,
        initialValues: {
          ...state.initialValues,
          ...action.payload.initialValues,
        },
      };
    }
    case "MODIFY_INITIAL_VALUES": {
      return {
        ...state,
        initialValues: { ...state.initialValues, ...action.payload },
      };
    }
    case "GET_STAFF_LIST_FOR_COPY_ALLOCATION": {
      return {
        ...state,
        staffListForCopy: action.payload,
        selectedStaffRowsForCopy: [],
      };
    }
    case "SET_SELECTED_STAFF": {
      return {
        ...state,
        selectedStaffRowsForCopy: action.payload,
      };
    }
    case "UPDATE_SHIFT_DETAILS": {
      return {
        ...state,
        selectedShiftDetails: action.payload,
      };
    }
    case "GET_ALLOCATION_TIMESHEET": {
      return {
        ...state,
        timesheetList: action.payload.timesheets,
        shiftIdDataSource: action.payload.shiftIdDataSource,
      };
    }
    case "RESET_TIMESHEETS": {
      return {
        ...state,
        timesheetList: [],
      };
    }
    case "GET_TIMESHEET_INITIALVALUES": {
      return {
        ...state,
        timesheetInitialValues: {
          ...state.timesheetInitialValues,
          ...action.payload,
        },
        currentSelectedShift: action.currentSelectedShift,
      };
    }
    case "RESET_TIMESHEET_INITIALVALUES": {
      return {
        ...state,
        timesheetInitialValues: { ...action.payload },
        currentSelectedShift: {},
      };
    }
    default: {
      return state;
    }
  }
};
