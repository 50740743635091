import validator from "validator";
import moment from "moment";

export const validateSignOff = (values) => {
  const errors = {};
  if (!values.person_name || (values.person_name && validator.isEmpty(values.person_name, { ignore_whitespace: true }))) {
    errors.person_name = 'Required'
  }
  if (!values.person_role || (values.person_role && validator.isEmpty(values.person_role, { ignore_whitespace: true }))) {
    errors.person_role = 'Required'
  }
  if (!values.job_sign_off_date || (values.job_sign_off_date && !moment(values.job_sign_off_date).isValid())) {
    errors.job_sign_off_date = 'Required'
  }
  if (!values.feedback || (values.feedback && validator.isEmpty(values.feedback, { ignore_whitespace: true }))) {
    errors.feedback = 'Required'
  }
  return errors;
}