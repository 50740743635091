export const validate = values => {
    const errors = {}
    const valueKeys = ['activity_id', 'activity_name', 'general_category', 'category', 'responsible', 'task_type', 'client', 'site', 'hazards', /* 'consequences', */ 'likelihood_before_controls', 'consequence_before_controls', 'likelihood_after_controls', 'consequence_after_controls']

    valueKeys.forEach(key => {
        if (!values[key] || (Array.isArray(values[key]) && !values[key].length)) {
            errors[key] = 'This field is required.'
        }
    })

    if (!values.controls || (values.controls && values.controls.startsWith('<p></p>') && values.controls.length <= 8)) {
        errors.controls = 'This field is required.'
    }

    return errors
}

export const validateSWMS = values => {
    const SWMSTypeErrors = ['tools', 'ppe', 'highRiskWork', 'licensesAndTraining', 'standards']

    const errors = {
        tools: values.tools.length ? false : true,
        ppe: values.ppe.length ? false : true,
        highRiskWork: values.highRiskWork.length ? false : true,
        licensesAndTraining: values.licensesAndTraining.length ? false : true,
        standards: values.standards.length ? false : true,
    }

    return errors
}