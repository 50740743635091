import { ALL_JOB_SWMS_FORM_DROPDOWN_URL, SWMS_FORM_URL, SWMS_FORM_HIGH_RISK_DATA_URL, GET_EMAIL_CONTENT_URL, SLUG_EMAIL_CONTENT_URL, SWMS_EMAIL_TO_CLIENT_URL, SWMS_SHIFTS_URL } from "../../dataProvider/constant";
import { baseApiInstance, scopeApiInstance } from "../../dataProvider/axiosHelper";
import { Strings } from "../../dataProvider/localize";

export const getAllSwmsDropdowns = (abortController) => () => {
  return scopeApiInstance.get(ALL_JOB_SWMS_FORM_DROPDOWN_URL, { ...abortController }).then((res) => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const getSwmsFormData = (abortController, id) => () => {
  return scopeApiInstance.get(SWMS_FORM_URL, { ...abortController, params: { swms_form_id: id } }).then(res => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const getAllHighRisksData = (abortController) => () => {
  return scopeApiInstance.get(SWMS_FORM_HIGH_RISK_DATA_URL, { ...abortController }).then((res) => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const saveSwmsForm = (formData) => () => {
  return scopeApiInstance.put(SWMS_FORM_URL, formData).then(res => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject();
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const getEmailSwmsContent = (abortController) => () => {
  return baseApiInstance.get(SLUG_EMAIL_CONTENT_URL, { ...abortController, params: { slug: "swms_email_to_client" } }).then(res => {
    if (res && res.data && res.data.data && res.data.data.result && res.data.data.result.length === 1) {
      return Promise.resolve(res.data.data.result[0]);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const sendSwmsEmail = (formData) => () => {
  return scopeApiInstance.post(SWMS_EMAIL_TO_CLIENT_URL, formData).then(res => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject();
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const getSwmsShifts = (abortController, params = {}, page = 1) => () => {
  return scopeApiInstance.get(SWMS_SHIFTS_URL, { ...abortController, params: { page, ...params, limit: 15 } }).then((res) => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data.swmsShifts);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const attachSwmsToShifts = (formData) => () => {
  return scopeApiInstance.put(SWMS_SHIFTS_URL, formData).then(res => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject();
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}