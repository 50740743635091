export default {
  control: (styles, { isFocused, menuIsOpen, isDisabled }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? '#e7e7e7 !important' : '#fff !important',
      boxShadow: isFocused || menuIsOpen ? '0 0 0 0.05rem #c0b4ff' : 'none',
      border: '1px solid #ddd',
      fontSize: '14px',
      borderRadius: '10px',
      minHeight: 'auto',
      height: '32px',
      "&:hover": {
        border: '1px solid #dddddd',
      },
      fontSize: "13px !important"
    }
  },
  indicatorsContainer: (styles, state) => ({
    ...styles,
    height: '-webkit-fill-available'
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    backgroundColor: '#36214880'
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: '#36214880'
  }),
  valueContainer: (styles, state) => ({
    ...styles,
    display: "flex",
    flexDirection: "row",
    overflowX: "hidden",
    paddingTop: '0px',
    paddingBottom: '0px',
    flexWrap: "nowrap",
    fontSize: "13px"
  }),
  singleValue: (styles) => ({
    ...styles,
    fontSize: "13px !important"
  }),
  placeholder: (styles, state) => ({
    ...styles,
    color: '#362148 !important'
  }),
  multiValue: (styles, state) => ({
    ...styles,
    minWidth: "auto"
  }),
  loadingIndicator: (styles, state) => ({
    ...styles,
    padding: "0px",
  }),
  loadingDot: (styles, state) => ({
    width: "8px",
    height: "8px"
  }),
  menu: (styles, state) => ({
    ...styles,
    zIndex: 7
  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      color: isSelected ? '#fff !important' : '#000000a6 !important',
      padding: '4px 5px',
      alignItems: 'center',
      width: 'auto',
      marginBottom: '0px',
      fontSize: "13px",
      display: 'flex',
      lineHeight: '22px',
      backgroundColor: isSelected ? '#c0b4ff !important' : '#fff !important',
      "&:hover": {
        backgroundColor: (isSelected) ? '#c0b4ff !important' : '#c0b4ff80 !important',
      },
    };
  },
};
