export default {
  control: (styles, { isFocused, menuIsOpen, isDisabled }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? '#e7e7e7 !important' : '#eaeaea !important',
      boxShadow: isFocused || menuIsOpen ? '0 0 0 0.05rem #c0b4ff' : 'none',
      border: '1px solid #eaeaea',
      fontSize: '13px',
      borderRadius: '8px',
      minHeight: 'auto',
      height: '35px',
      "&:hover": {
        // border: '1px solid #dddddd',
      },
    }
  },
  indicatorsContainer: (styles, state) => ({
    ...styles,
    height: '-webkit-fill-available',
  
  }),
  menuPortal: (styles, state) => ({
    ...styles,
    zIndex: 1000
  }),
  indicatorContainer: (styles, state) => ({
    ...styles,
    padding: '0px !important',
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    display: "none",
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: '#36214880 !important',
    background: '#c0b4ff !important',
    borderRadius: '8px !important',
    padding: '7px 8px !important',
    height: "100%"
  }),
  valueContainer: (styles, state) => ({
    ...styles,
    display: "flex",
    flexDirection: "row",
    overflowX: "hidden",
    paddingTop: '0px',
    paddingBottom: '0px',
    flexWrap: "nowrap",
  }),
  placeholder: (styles, state) => ({
    ...styles,
    color: '#362148 !important'
  }),
  multiValue: (styles, state) => ({
    ...styles,
    minWidth: "auto"
  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      color: isSelected ? '#fff !important' : '#000000a6 !important',
      padding: '4px 5px',
      alignItems: 'center',
      width: 'auto',
      marginBottom: '0px',
      fontSize: '13px',
      display: 'flex',
      lineHeight: '22px',
      backgroundColor: isSelected ? '#b8acf4 !important' : '#fff !important',
      "&:hover": {
        backgroundColor: (isSelected) ? '#b8acf4 !important' : '#b8acf480 !important',
      },
    };
  },
};
