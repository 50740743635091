export const validateNewLicenseData = (values) => {
  const errors = [];
  if (!values || !Object.keys(values).length) {
    errors.push("Please fill in the values.");
  }

  if (!values.document_name) {
    errors.push("Please enter document name.");
  }

  if (!values.document_number) {
    errors.push("Please enter document number.");
  }

  if (!values.start_date) {
    errors.push("Please enter document issue date.");
  }

  if (!values.end_date) {
    errors.push("Please enter document expiry date.");
  }

  if (!values.file) {
    errors.push("Please upload a document.");
  }

  return errors;
};
