import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import moment from "moment";
import { DatePicker, Input, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getSitesBySearch, getManagers } from "../../../actions/payrollTimesheetAction";
import { getStorage } from "../../../utils/common";
import { ADMIN_DETAILS } from "../../../dataProvider/constant";
import { Strings } from "../../../dataProvider/localize";
import styles from "../../v2/v2Styles.module.css";
import { v2FilterSelectStyle } from "../../v2/v2FormSelectStyle";
import { getPayClassification } from "../../../actions/profileManagementActions";


const { RangePicker } = DatePicker;

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          className="cs-select"
          style={{ width: 'auto', margin: '0px 5px' }}
        />
        <label style={{ margin: '0px 5px' }}>{props.label}</label>
      </components.Option>
    </div>
  );
};

export default function ShiftTimeSheetFilters(props) {

  const [startDate, setStartDate] = useState(new moment().subtract(2, "weeks"));
  const [endDate, setEndDate] = useState(new moment());
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [jobIdSearch, setJobIdSearch] = useState('');
  const [selectedSites, setSelectedSites] = useState([]);
  const [lastSites, setLastSites] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [payClassifications, setPayClassifications] = useState([]);
  const [selectedPayClassifications, setSelectedPayClassifications] = useState([]);

  const clientsList = useSelector(state => state.scopeDocs.clientsList);
  const statesList = useSelector(state => state.profileManagement.statesList);
  const allUsers = useSelector(state => state.organizationUsers.users);
  const accountManagers = useSelector(state => state.advanceSeacrh.accMngList.map(acc => ({ label: acc.first_name, value: acc.user_name })));

  const dispatch = useDispatch();

  const resetSelectedDates = () => {
    let date1 = new moment().subtract(2, "weeks");
    let date2 = new moment();
    onFilter(date1, date2);
  }

  useEffect(() => {
    resetSelectedDates();
    const adminDetails = JSON.parse(getStorage(ADMIN_DETAILS));
    if (adminDetails && adminDetails.organisation && adminDetails.organisation.id) {
      dispatch(getManagers(adminDetails.organisation.id)).catch(message => {
        notification.error({
          message: Strings.error_title,
          description: message ? message : Strings.generic_error,
          onClick: () => { },
          className: "ant-error",
        });
      })
    }
  }, []);

  useEffect(() => {
    let users = [];
    if (allUsers && allUsers.length > 0) {
      if (selectedState && selectedState.value) {
        users = allUsers.filter((user) => user.role.slug === "STAFF" && user.state_id && user.state_id.toString() === selectedState.value.toString());
      } else {
        users = allUsers.filter((user) => user.role.slug === "STAFF")
      }
      setSelectedStaff([]);
      let staffList = users.map(staff => ({
        label: `${staff.name} ${staff.middle_name ? staff.middle_name.charAt(0).toUpperCase() : ""
          } ${staff.last_name} (ID:${staff.user_name})`,
        value: staff.user_name,
      }));
      setUsersList(staffList);
    }
  }, [selectedState, allUsers])

  const onPayPeriodChange = (momentDates) => {
    setStartDate(momentDates[0]);
    setEndDate(momentDates[1]);
  }

  const getSiteNames = () => {
    Promise.resolve(dispatch(getSitesBySearch()).then(res => {
      let sites = res.map(site => ({ label: site.site_name, value: site.id }))
      setLastSites(sites);
      return sites;
    }
    ))
  }

  useEffect(() => {
    getSiteNames();
  }, []);

  const onFilter = (date1 = startDate, date2 = endDate) => {
    setStartDate(date1);
    setEndDate(date2);
    let data = {
      start_date: date1,
      finish_date: date2,
      staff_user: selectedStaff,
      state: selectedState,
      jobId: jobIdSearch,
      siteIds: selectedSites,
      client: selectedClients,
      managers: selectedManagers,
      payClassifications: selectedPayClassifications
    }
    props.onFilter(data);
  }

  const resetFilters = () => {
    props.onReset();
    resetSelectedDates();
    setSelectedClients([]);
    setSelectedSites([]);
    setSelectedStaff([]);
    setSelectedState(null);
    setSelectedManagers([]);
    setJobIdSearch('');
    setSelectedPayClassifications([]);
  }

  useEffect(() => {
    dispatch(getPayClassification()).then((res) => {
      setPayClassifications(res && res.pay_classifications && res.pay_classifications.length ? res.pay_classifications.map(payClassification => ({ label: payClassification.classification_name, value: payClassification.id })) : []);
    })
      .catch(message => {
        notification.error({
          message: Strings.error_title,
          className: "ant-error",
        });
      })
  }, []);

  return (
    <div>
      <div className="container-fluid text-left bg-white">
        <div className="row align-items-center">
          <div className="col-10">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-3 py-1">
                  <RangePicker
                    value={[startDate, endDate]}
                    onChange={onPayPeriodChange}
                    format="DD-MM-YYYY"
                    allowClear={false}
                    className="cs-range-picker cs-filter-range-picker"
                    dropdownClassName="cs-datepicker-dropdown"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-3 py-1">
                  <Select placeholder="Manager"
                    onChange={(v) => setSelectedManagers(v)}
                    value={selectedManagers}
                    options={accountManagers}
                    hideSelectedOptions={false}
                    isClearable={true}
                    styles={v2FilterSelectStyle}
                    components={{ Option }}
                    aria-multiline={false}
                    isMulti
                    closeMenuOnSelect={false} />
                </div>
                <div className="col-3 py-1">
                  <Select
                    placeholder="Client Name"
                    onChange={(v) => setSelectedClients(v)}
                    isMulti
                    value={selectedClients}
                    options={clientsList.map((client) => ({
                      label: client.name,
                      value: client.id,
                    }))}
                    components={{ Option }}
                    hideSelectedOptions={false}
                    aria-multiline={false}
                    clearable
                    closeMenuOnSelect={false}
                    styles={v2FilterSelectStyle}
                  />
                </div>
                <div className="col-3 py-1">
                  <Select placeholder="State"
                    onChange={(v) => setSelectedState(v)}
                    value={selectedState}
                    options={statesList.map((state) => ({
                      label: state.name,
                      value: state.id,
                    }))}
                    hideSelectedOptions={false}
                    isClearable={true}
                    styles={v2FilterSelectStyle} />
                </div>
                <div className="col-3 py-1">
                  <Select
                    placeholder="Site"
                    onChange={(v) => setSelectedSites(v)}
                    value={selectedSites}
                    options={lastSites}
                    hideSelectedOptions={false}
                    aria-multiline={false}
                    clearable
                    styles={v2FilterSelectStyle}
                    isMulti
                  />
                </div>
                <div className="col-3 py-1">
                  <Select
                    placeholder="Staff"
                    onChange={(v) => setSelectedStaff(v)}
                    isMulti
                    value={selectedStaff}
                    options={usersList}
                    components={{ Option }}
                    hideSelectedOptions={false}
                    aria-multiline={false}
                    clearable
                    closeMenuOnSelect={false}
                    styles={v2FilterSelectStyle}
                  />
                </div>
                <div className="col-3 py-1">
                  <Input placeholder="Job Id" className={styles.FilterTextField} onChange={e => setJobIdSearch(e.target.value)} value={jobIdSearch} />
                </div>
                <div className="col-3 py-1">
                  <Select
                    placeholder="Pay Classification"
                    onChange={(v) => { setSelectedPayClassifications(v) }}
                    value={selectedPayClassifications}
                    options={payClassifications}
                    hideSelectedOptions={false}
                    aria-multiline={false}
                    clearable
                    styles={v2FilterSelectStyle}
                    isMulti
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-2 py-1 d-flex align-items-center" style={{ gap: "5px" }}>
            <button onClick={() => onFilter()} className={styles.PrimaryButton}>Filter</button>
            <button className={styles.OutlinedButton} onClick={() => resetFilters()}>Reset</button>
          </div>
        </div>
      </div>
    </div>
  )
}