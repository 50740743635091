import React, { useState, forwardRef, memo, createRef } from "react";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import { notification } from "antd";

import styles from "../../JobStyle.module.css";
import { approveRejectSwms } from "../../../../../actions/v2/JobSwmsTaskActions";
import { Strings } from "../../../../../dataProvider/localize.js";
import ApprovalSignature from "./ApprovalSignature";

const SignatureComponent = forwardRef((props, ref) => <ApprovalSignature forwardedRef={ref}  {...props} />)
const MemoizedSignature = memo(SignatureComponent)

export default function ApproveRejectSwmsModal({ onClose, task, swms }) {

  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const canvasRef = createRef(null);

  const onSubmit = (approve = 0) => {
    let canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext('2d');
      const pixelBuffer = new Uint32Array(
        context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
      );
      if (approve && !pixelBuffer.some(color => color !== 0)) {
        notification.warning({
          message: "Signature is required to approve SWMS.",
          className: 'ant-warning'
        })
      } else {
        setSubmitting(true);
        let sign = canvas.toDataURL("image/png");
        let formData = {
          swms_form_id: swms.swms_form_id,
          approval_status: approve ? "Manager Approved" : "Manager Rejected",
          ...(approve ? { sign_data: sign } : {})
        }
        dispatch(approveRejectSwms(formData)).then(message => {
          setSubmitting(false);
          notification.success({
            message: Strings.success_title,
            description: message,
            className: "ant-success"
          });
          onClose();
        }).catch(err => {
          if (err) {
            setSubmitting(false);
            notification.error({
              message: Strings.error_title,
              description: err ? err : Strings.generic_error,
              className: "ant-error"
            })
          }
        })
      }
    }
  }

  return (
    <div className="job-swms-modal-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={styles.ModalHeading}>
              Approve {task.task_number} - SWMS Version {swms.swms_revision}?
            </div>
            <div className="py-2">
              <hr />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div>
              <div style={{ color: "red" }}>This action is not reversible.</div>
            </div>
            <MemoizedSignature
              ref={canvasRef}
              onSave={onSubmit}
              submitting={submitting}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-between">
              <button onClick={() => onSubmit(0)} className={`${styles.PrimaryButton} ${styles.ErrorButton}`} disabled={submitting}>Reject</button>
              <div className="d-flex align-items-center justify-content-end" style={{ gap: "5px" }}>
                {submitting ? (
                  <CircularProgress size={20} />) : ""}
                <button className={`${styles.OutlinedButton}`} onClick={() => onClose()}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}