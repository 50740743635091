import {
  SET_RFQ_PRIORITY_OPTIONS,
  RFQ_LIST_URL, RFQ_SET_QUOTES,
  SET_RFQ_QUOTE_STATUSES,
  SET_RFQ_CLIENT_ADDITIONAL_DETAILS,
  SET_RFQ_DETAILS,
  SET_RFQ_SITE_ADDITIONAL_DETAILS,
  RESET_RFQ_DETAILS,
  SET_RFQ_SWMS,
  UPDATE_RFQ_TASKS_DATA,
  SET_RFQ_SITE_HISTORY,
  SET_RFQ_CONTRACTORS,
  SET_RFQ_CONTRACTOR_FILTERS,
  ADD_RFQ_SELECTED_CONTRACTOR,
  REMOVE_RFQ_SELECTED_CONTRACTOR,
  CLEAR_RFQ_SELECTED_CONTRACTORS,
  SET_RFQ_TASK_CONTRACTORS,
  CLEAR_RFQ_TASK_CONTRACTORS,
  SET_RFQ_ACTIONS,
  SET_RFQ_ADMINS,
  SET_RFQ_MANAGEMENT,
  SET_CONTRACTORS_OF_RFQ
} from "../../dataProvider/constant";

const INITIAL_STATE = {
  priorityOptions: [],
  rfqList: [],
  totalRfq: 0,
  quotesList: [],
  totalQuotes: 0,
  quoteStatuses: [],
  clientAdditionalDetails: [],
  rfqDetails: {},
  tasks: [],
  siteAdditionalDetails: [],
  history: [],
  historyCount: 0,
  swmsCategories: [],
  swmsActivities: [],
  chemicals: [],
  ppes: [],
  toolTypes: [],
  highRisks: [],
  siteHistory: [],
  siteHistoryCount: 0,
  contractors: [],
  contractorsCount: 0,
  contractorServiceSectors: [],
  contractorServiceStates: [],
  contractorServiceRegions: [],
  selectedContractors: [],
  taskContractors: [],
  quoteActions: [],
  quoteManagements: [],
  quoteManagementsCount: 0,
  quoteAdmins: []
}

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_RFQ_PRIORITY_OPTIONS: {
      return {
        ...state,
        priorityOptions: payload
      }
    }
    case RFQ_LIST_URL: {
      return {
        ...state,
        rfqList: payload.rfq_list,
        totalRfq: payload.rfq_count
      }
    }
    case RFQ_SET_QUOTES: {
      return {
        ...state,
        quotesList: payload.quoteList,
        totalQuotes: payload.quote_count
      }
    }
    case SET_RFQ_QUOTE_STATUSES: {
      return {
        ...state,
        quoteStatuses: payload.quote_statuses
      }
    }
    case SET_RFQ_CLIENT_ADDITIONAL_DETAILS: {
      return {
        ...state,
        clientAdditionalDetails: payload
      }
    }
    case SET_RFQ_DETAILS: {
      return {
        ...state,
        rfqDetails: payload.quote_details ? payload.quote_details : {},
        tasks: payload.task_details ? payload.task_details : []
      }
    }
    case SET_RFQ_SITE_ADDITIONAL_DETAILS: {
      return {
        ...state,
        siteAdditionalDetails: payload
      }
    }
    case RESET_RFQ_DETAILS: {
      return {
        ...state,
        rfqDetails: {},
        tasks: [],
        history: [],
        historyCount: 0
      }
    }
    case SET_RFQ_SWMS: {
      return {
        ...state,
        swmsCategories: payload.swms_cat,
        swmsActivities: payload.swms_activities,
        chemicals: payload.sds,
        ppes: payload.ppes,
        toolTypes: payload.tools,
        highRisks: payload.high_risk_works,
      }
    }
    case SET_RFQ_SITE_HISTORY: {
      return {
        ...state,
        siteHistory: payload.tasksHistoryList,
        siteHistoryCount: payload.task_count
      }
    }
    case UPDATE_RFQ_TASKS_DATA: {
      let tasks = [...state.tasks];
      tasks = tasks.map(task => {
        let newTask = payload.find(x => x.task_id.toString() === task.task_id.toString());
        return newTask ? { ...task, ...newTask } : task;
      })
      return {
        ...state,
        tasks: tasks
      }
    }
    case SET_RFQ_CONTRACTORS: {
      return {
        ...state,
        contractors: payload.contractors,
        contractorsCount: payload.contractors_count
      }
    }
    case SET_RFQ_CONTRACTOR_FILTERS: {
      return {
        ...state,
        contractorServiceSectors: payload.service_sectors ? payload.service_sectors : [],
        contractorServiceStates: payload.service_states ? payload.service_states : [],
        contractorServiceRegions: payload.service_regions ? payload.service_regions : []
      }
    }
    case ADD_RFQ_SELECTED_CONTRACTOR: {
      return {
        ...state,
        selectedContractors: [...state.selectedContractors, payload]
      }
    }
    case REMOVE_RFQ_SELECTED_CONTRACTOR: {
      return {
        ...state,
        selectedContractors: state.selectedContractors.filter(x => x.contractor_id !== payload)
      }
    }
    case CLEAR_RFQ_SELECTED_CONTRACTORS: {
      return {
        ...state,
        selectedContractors: []
      }
    }
    case SET_RFQ_TASK_CONTRACTORS: {
      return {
        ...state,
        taskContractors: payload
      }
    }
    case CLEAR_RFQ_TASK_CONTRACTORS: {
      return {
        ...state,
        taskContractors: []
      }
    }
    case SET_RFQ_ACTIONS: {
      return {
        ...state,
        quoteActions: payload
      }
    }
    case SET_RFQ_ADMINS: {
      return {
        ...state,
        quoteAdmins: payload
      }
    }
    case SET_RFQ_MANAGEMENT: {
      return {
        ...state,
        quoteManagements: payload.rows ? payload.rows : [],
        quoteManagementsCount: payload.count ? payload.count : 0
      }
    }
    case SET_CONTRACTORS_OF_RFQ: {
      return {
        ...state,
        contractorsDetails: payload
      }
    }
    default: {
      return state;
    }
  }
}