export default {
  control: (styles, {
    isFocused,
    menuIsOpen,
    isDisabled
  }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? '#eaeaea !important' : '#eaeaea !important',
      boxShadow: isFocused || menuIsOpen ? '0 0 0 0.05rem #c0b4ff' : 'none',
      border: '0px',
      fontSize: '13px',
      borderRadius: '10px 0 0 10px',
      height: '35px',
      minHeight: '35px',
      width: "70px",
      ...(isFocused || menuIsOpen ? {
        border: "0px",
        backgroundColor: "#fff",
        boxShadow: "0 0 0 0.18rem #c0b4ff inset !important"
      } : {})
    }
  },
  indicatorsContainer: (styles, state) => ({
    ...styles,
    height: '35px',
    paddingRight: "3px"
  }),
  menuPortal: (styles, state) => ({
    ...styles,
    zIndex: 1000
  }),
  indicatorContainer: (styles, {
    menuIsOpen,
    isFocused
  }) => ({
    ...styles,
    padding: '0px !important',
    height: "30px",
    background: isFocused || menuIsOpen ? "#fff !important" : '#eaeaea !important',
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    display: "none",
  }),
  dropdownIndicator: (styles, {
    isFocused,
    menuIsOpen
  }) => ({
    ...styles,
    color: '#000 !important',
    background: isFocused || menuIsOpen ? "#fff !important" : '#eaeaea !important',
    borderRadius: '0px !important',
    padding: '0px !important',
    height: "13px",
    width: "13px",
    fontSize: "13px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }),
  valueContainer: (styles, state) => ({
    ...styles,
    display: "flex",
    flexDirection: "row",
    overflowX: "hidden",
    paddingTop: '0px',
    paddingBottom: '0px',
    flexWrap: "nowrap",
  }),
  placeholder: (styles, state) => ({
    ...styles,
    color: '#362148 !important'
  }),
  multiValue: (styles, state) => ({
    ...styles,
    minWidth: "auto"
  }),
  singleValue: (styles) => ({
    ...styles,
    height: "25px",
    width: "100%",
    textAlign: "center",
    lineHeight: "1.4"
  }),
  option: (styles, {
    isFocused,
    isSelected
  }) => {
    return {
      ...styles,
      color: isSelected ? '#fff !important' : '#000000a6 !important',
      padding: '4px 5px',
      alignItems: 'center',
      width: 'auto',
      marginBottom: '0px',
      fontSize: '13px',
      display: 'flex',
      lineHeight: '22px',
      backgroundColor: isSelected ? '#b8acf4 !important' : '#fff !important',
      "&:hover": {
        backgroundColor: (isSelected) ? '#b8acf4 !important' : '#b8acf480 !important',
      },
    };
  },
};