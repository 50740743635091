import { serializeJobs } from "../../components/v2/JobCalendar/serializer";
import { scopeAxiosInstance, axiosInstance, scopeV3AxiosInstance, scopeApiInstance } from "../../dataProvider/axiosHelper";
import {
  JOBS,
  TASKS,
  FILTER_JOBS,
  GET_ACM_JOBS_URL,
  INIT_JOB_CALENDAR,
  GET_ADMIN_JOBS_URL,
  SWITCH_CALENDAR_VIEW,
  GET_ACCOUNT_MANAGERS,
  GET_JOB_CALENDAR_JOBS,
  SCOPE_DOC_DETAILS_URL,
  GET_ALLOCATION_DETAILS,
  GET_ALLOCATION_MANAGERS_URL,
  CALENDAR_UPDATE_TASK_URL,
  UPDATE_CURRENT_VISIBLE_DATE,
  GET_CALENDAR_JOB_DETAILS,
  GET_JOB_ROSTER_DETAILS
} from "../../dataProvider/constant";
import { Strings } from "../../dataProvider/localize";
import { startSipnner, stopSipnner } from "../../utils/spinner";

export const initJobCalendar = () => (dispatch) => {
  dispatch({
    type: INIT_JOB_CALENDAR,
  });
};

export const getJobs = (userRole, month, state) => (dispatch) => {
  startSipnner(dispatch);
  const url = `${userRole === "ACCOUNT_MANAGER" ? GET_ACM_JOBS_URL : GET_ADMIN_JOBS_URL
    }${`?month=${month}`}${state ? `&state=${state}` : ""}`;

  return scopeV3AxiosInstance
    .get(url)
    .then((res) => {
      stopSipnner(dispatch);

      const serialized = serializeJobs(res.data.data.jobs);

      dispatch({
        type: GET_JOB_CALENDAR_JOBS,
        payload: serialized,
      });

      // dispatch({
      //   type: GET_JOB_STATES,
      //   payload: getJobStates(res.data.data.jobs),
      // });

      return Promise.resolve(res.data.message && res.data.message);
    })
    .catch((error) => {
      stopSipnner(dispatch);

      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const getJobDetails = (userRole, job_id, loading = true) => (dispatch) => {
  if (loading) { startSipnner(dispatch); }
  const url = `${
    userRole === "ACCOUNT_MANAGER" ? GET_ACM_JOBS_URL : GET_ADMIN_JOBS_URL
  }${`?job_id=${job_id}`}`;

  return scopeV3AxiosInstance
    .get(url)
    .then((res) => {
      const serialized = serializeJobs(res.data.data.jobs, true);
      // console.log("serialized", serialized);
      dispatch({
        type: SWITCH_CALENDAR_VIEW,
        payload: {
          view: TASKS,
          tasks: serialized[0].tasks,
          selectedJob: serialized[0],
        }
      })
      if (loading) { stopSipnner(dispatch); }
      return Promise.resolve(res.data.message && res.data.message);
    })
    .catch((error) => {
      if (loading) { stopSipnner(dispatch); }
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const getNewJobDetails = (userRole, job_id, loading = true) => (dispatch) => {
  if (loading) { startSipnner(dispatch); }
  const url = GET_CALENDAR_JOB_DETAILS
  return scopeApiInstance
    .get(url, {
      params: {
        job_id: job_id
      }
    })
    .then((res) => {
      const serialized = serializeJobs(res.data.data.jobs, true);
      dispatch({
        type: SWITCH_CALENDAR_VIEW,
        payload: {
          view: TASKS,
          tasks: serialized[0].tasks,
          selectedJob: serialized[0],
        }
      })
      if (loading) { stopSipnner(dispatch); }
      return Promise.resolve(res.data.message && res.data.message);
    })
    .catch((error) => {
      if (loading) { stopSipnner(dispatch); }
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    });
};

export const switchCalendarView = (view, tasks, selectedJob) => (dispatch) => {
  if (view === JOBS) {
    dispatch({
      type: SWITCH_CALENDAR_VIEW,
      payload: {
        view: JOBS,
      },
    });
  } else {
    dispatch({
      type: SWITCH_CALENDAR_VIEW,
      payload: {
        view: TASKS,
        tasks: tasks,
        selectedJob: selectedJob,
      },
    });
  }
};

export const getFilteredJobs =
  (view, { filters, dropdowns, search }, events) =>
    (dispatch) => {
      const { jobs, tasks } = events;
      const {
        booked,
        allocated,
        outsourced,
        started,
        paused,
        completed,
        signedOff,
        invoiced,
        notApproved,
        cancelled
      } = filters;
      const { serviceAgent, client, accountManager, state } = dropdowns;
      let hasFilters = true;
      if (Object.values(filters).every((val) => val === false)) {
        hasFilters = false;
      }
      let filteredJobs = hasFilters ? jobs.filter(job => ((booked && job.status == 1) ||
        (allocated && job.status == 3) ||
        (outsourced && job.status == 5) ||
        (started && job.status == 7) ||
        (paused && job.status == 8) ||
        (completed && job.status == 9) ||
        (signedOff && job.signedOff && job.status == 10) ||
        (notApproved && job.status == 0) ||
        (cancelled && job.status == 12) ||
        (invoiced && job.invoiced)
      )) : jobs;

      if (typeof client !== 'undefined' && client) {
        filteredJobs = filteredJobs.filter(job => job.clientId == client);
      }

      if (typeof state !== 'undefined' && state) {
        filteredJobs = filteredJobs.filter(job => job.state && job.state.toLowerCase() === state.toLowerCase())
      }

      if (typeof accountManager !== 'undefined' && accountManager) {
        filteredJobs = filteredJobs.filter(job => job.accountManagerUsername == accountManager);
      }

      const filteredTasks = tasks.filter(
        (task) =>
          (invoiced && task.invoiced) ||
          (notApproved && task.status === 0) ||
          (booked && task.status === 1) ||
          (allocated && task.status === 3) ||
          (outsourced && task.status === 5) ||
          (started && task.status === 7) ||
          (paused && task.status === 8) ||
          (completed && task.status === 9) ||
          (signedOff && task.status === 10) ||
          (cancelled && task.status === 12) ||
          (client && task.clientId.toString() === client) ||
          (serviceAgent && task.serviceAgentId.toString() === serviceAgent) ||
          (accountManager &&
            task.accountManagerUsername &&
            task.accountManagerUsername === accountManager) ||
          (Object.values(filters).every((val) => val === false) &&
            Object.values(dropdowns).every((val) => !val))
      );

      const searchedJobs = [];
      const searchedTasks = [];

      if (search) {
        searchedJobs.push(
          ...filteredJobs.filter(
            (job) =>
              (search &&
                job.job_title.toLowerCase().includes(search.toLowerCase())) ||
              (search &&
                job.sites && job.sites.some((site) =>
                  site.street_address && typeof site.street_address === "string"
                    ? site.street_address
                      .toLowerCase()
                      .includes(search.toLowerCase())
                    : false
                ))
          )
        );

        searchedTasks.push(
          ...filteredTasks.filter(
            (task) =>
              search && task.task_title.toLowerCase().includes(search.toLowerCase())
          )
        );
      }

      dispatch({
        type: FILTER_JOBS,
        payload: {
          jobs: search ? searchedJobs : filteredJobs,
          tasks: search ? searchedTasks : filteredTasks,
        },
      });
    };

export const getAccountManagers = (org_id) => (dispatch) => {
  startSipnner(dispatch);
  return axiosInstance
    .get(`${GET_ALLOCATION_MANAGERS_URL}?organisation_id=${org_id}`)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: GET_ACCOUNT_MANAGERS,
          payload: res.data.data.account_managers,
        });
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const getAllocationDetails = (scopeDocId, quoteNumber) => (dispatch) => {
  startSipnner(dispatch);
  var url = SCOPE_DOC_DETAILS_URL + "?id=" + scopeDocId;
  if (quoteNumber) {
    url = url + "&quote_number=" + quoteNumber;
  }
  return scopeAxiosInstance
    .get(url)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: GET_ALLOCATION_DETAILS,
          payload: res.data.data.scope_docs[0],
        });
        return Promise.resolve("success");
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const updateTask = (formData) => (dispatch) => {
  startSipnner(dispatch);
  return scopeAxiosInstance
    .put(`${CALENDAR_UPDATE_TASK_URL}`, formData)
    .then(async (res) => {
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    })
    .finally(() => {
      stopSipnner(dispatch);
    });
};

export const updateCurrentVisibleDate = (date) => (dispatch) => {
  dispatch({
    type: UPDATE_CURRENT_VISIBLE_DATE,
    payload: date,
  });
};

export const getJobRosterDetails = (jobId, startDate, endDate) => dispatch => {
  startSipnner(dispatch);
  const url = GET_JOB_ROSTER_DETAILS;
  return scopeApiInstance.get(url, {
    params: {
      job_id: jobId,
      job_start_date: startDate,
      job_end_date: endDate
    }
  }).then(res => {
    if (res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject();
    }
  }).catch(error => {
    return Promise.reject(error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error)
  }).finally(() => {
    stopSipnner(dispatch);
  })
}