import moment from "moment";

export const serializeCreateRequest = (
  jobDetails,
  formValues,
  staffListFormValues,
  quoteId
) => {
  const staff = [];

  Object.keys(formValues).forEach((key) => {
    if (key.includes("createstaff_") && formValues[key]) {
      let numbers = /^[0-9]+$/;
      let staffUsername = key.split("_")[1];
      if (staffUsername.match(numbers)) staff.push(staffUsername);
    }
  });

  const staffList = getFormattedStaffs(staff, formValues).filter(
    (item) => staffListFormValues[`addlist_staff_${item.user_name}`]
  );

  return {
    job_label: jobDetails.label,
    job_id: jobDetails.id,
    shift_date: moment(formValues.start_date).format("YYYY-MM-DD"),
    yard_time: moment(formValues.start_time, "HH:mm:ss").format("HH:mm:ss"),
    site_time: moment(formValues.start_time, "HH:mm:ss").format("HH:mm:ss"),
    finish_time: `${moment(formValues.finish_date).format(
      "YYYY-MM-DD"
    )} ${moment(formValues.finish_time, "HH:mm:ss").format("HH:mm:ss")}`,
    state_id: formValues.state_id,
    selected_tasks: formValues.tasks.map((task) => task.value),
    acc_manager_user_name: jobDetails.accountManagerUsername,
    location_required: formValues.validate_location,
    paid_break: formValues.paid_break,
    service_agent_id: jobDetails.tasks[0].serviceAgentId,
    vehicles: "",
    shift_instructions: "",
    site_id: jobDetails.tasks[0].siteId,
    staffs: staffList,
    quote_id: quoteId
  };
};

const getFormattedStaffs = (staffs, formValues) => {
  return staffs.map((userName) => ({
    user_name: userName,
    is_site_supervisor: formValues[`${userName}_is_supervisor`] ? 1 : 0,
    start_time_type: formValues[`${userName}_start_point`].value,
    site_yard_time:
      moment(formValues[`${userName}_start_date`]).format("YYYY-MM-DD") +
      " " +
      moment(formValues[`${userName}_start_time`], "HH:mm:ss").format(
        "HH:mm:ss"
      ),
    staff_finish_time:
      moment(formValues[`${userName}_finish_date`]).format("YYYY-MM-DD") +
      " " +
      moment(formValues[`${userName}_finish_time`], "HH:mm:ss").format(
        "HH:mm:ss"
      ),
  }));
};

export const serializeCopyRequest = (
  jobDetails,
  formValues,
  staffList,
  selectedShiftDetails
) => {
  const staff = [];

  Object.keys(formValues).forEach((key) => {
    if (key.includes("copystaff_") && formValues[key]) {
      let numbers = /^[0-9]+$/;
      let staffUsername = key.split("_")[1].split("-")[0];
      if (staffUsername.match(numbers)) staff.push(staffUsername);
    }
  });

  return {
    job_id: jobDetails.id,
    state_id: formValues.state_id,
    selected_tasks: formValues.tasks.map((task) =>
      task.value
    ),
    acc_manager_user_name: jobDetails.accountManagerUsername,
    location_required: formValues.validate_location,
    paid_break: formValues.paid_break,
    service_agent_id: jobDetails.tasks[0].serviceAgentId,
    site_id: jobDetails.tasks[0].siteId,
    staffs: staffList.map((listItem) => ({
      user_name: listItem.user_name,
      is_site_supervisor: listItem.is_site_supervisor,
      start_time_type: listItem.start_time_type,
      shift_date: listItem.shift_date,
      site_yard_time: listItem.site_time,
      staff_finish_time: listItem.finish_time,
    })),
  };
};

export const serializeUpdateRequest = (
  jobDetails,
  formValues,
  shiftDetails,
  selectedStaffIds = [],
  quoteId
) => {
  const staff = [];

  Object.keys(formValues).forEach((key) => {
    if (key.includes("update_") && formValues[key]) {
      let numbers = /^[0-9]+$/;
      let staffUsername = key.split("_")[1];
      if (staffUsername.match(numbers) && selectedStaffIds.includes(staffUsername.toString())) staff.push(staffUsername);
    }
  });

  const uniqueStaff = [...new Set(staff)];

  const staffList = uniqueStaff.map((userName) => ({
    user_name: `${userName}`,
    is_site_supervisor: formValues[`update_${userName}_is_supervisor`] ? 1 : 0,
    start_time_type: formValues[`update_${userName}_start_point`].value,
    site_yard_time:
      moment(formValues[`update_${userName}_start_date`]).format("YYYY-MM-DD") +
      " " +
      moment(formValues[`update_${userName}_start_time`], "HH:mm:ss").format(
        "HH:mm:ss"
      ),
    staff_finish_time:
      moment(formValues[`update_${userName}_finish_date`]).format(
        "YYYY-MM-DD"
      ) +
      " " +
      moment(formValues[`update_${userName}_finish_time`], "HH:mm:ss").format(
        "HH:mm:ss"
      ),
  }));

  const staffCheckBoxValues = {};

  uniqueStaff.forEach(
    (userName) => (staffCheckBoxValues[`selected_${userName}`] = 1)
  );

  return {
    job_id: jobDetails.id,
    shift_date: moment(formValues.start_date).format("YYYY-MM-DD"),
    yard_time: moment(formValues.start_time, "HH:mm:ss").format("HH:mm:ss"),
    site_time: moment(formValues.start_time, "HH:mm:ss").format("HH:mm:ss"),
    finish_time: `${moment(formValues.finish_date).format(
      "YYYY-MM-DD"
    )} ${moment(formValues.finish_time, "HH:mm:ss").format("HH:mm:ss")}`,
    state_id: formValues.state_id,
    selected_tasks: formValues.tasks.map((t) => t.value),
    acc_manager_user_name: jobDetails.accountManagerUsername,
    location_required: formValues.validate_location,
    paid_break: formValues.paid_break,
    service_agent_id: jobDetails.tasks[0].serviceAgentId,
    vehicles: "",
    shift_instructions: "",
    site_id: jobDetails.tasks[0].siteId,
    staffs: staffList,
    job_schedule_shift_id: shiftDetails.job_schedule_shift_id,
    quote_id: quoteId
  };
};

export const serializeNotificationParams = (
  shiftDetails,
  staffList,
  selectedStaffList,
  accountManagerUsername = null
) => {
  const staff = staffList ? [...staffList] : [...shiftDetails.staffs];

  const selected = selectedStaffList
    ? staff
      .map((staffItem) => ({
        user_name: staffItem.user_name,
        is_site_supervisor: staffItem.is_site_supervisor,
        start_time_type: staffItem.start_time_type,
        site_yard_time: staffItem.site_yard_time,
        staff_finish_time: staffItem.staff_finish_time,
        job_shift_timesheet_id: staffItem.job_shift_timesheet_id ? staffItem.job_shift_timesheet_id : null
      }))
      .filter((item) => selectedStaffList.includes(item.user_name))
    : staff.map((staffItem) => ({
      user_name: staffItem.user_name,
      is_site_supervisor: staffItem.is_site_supervisor,
      start_time_type: staffItem.start_time_type,
      site_yard_time: staffItem.site_yard_time,
      staff_finish_time: staffItem.staff_finish_time,
      job_shift_timesheet_id: staffItem.job_shift_timesheet_id ? staffItem.job_shift_timesheet_id : null
    }));

  return {
    job_id: shiftDetails.job_id,
    acc_manager_user_name: accountManagerUsername,
    job_schedule: [
      {
        id: shiftDetails.job_schedule_shift_id,
        staff: selected,
      },
    ],
  };
};
