import React, { useEffect, useMemo, useState } from "react";
import { Field, FormSection, formValueSelector } from "redux-form";
import { IconButton } from "@mui/material";
import { connect } from "react-redux";
import { components } from "react-select";
import { Modal } from "antd";
import CloseIcon from '@mui/icons-material/Close';

import { CustomSingleSelect } from "../../../../common/new-core-ui/CustomSingleSelect";
import { CustomCheckbox } from "../../../../common/customCheckbox";
import { ADMIN_DETAILS } from "../../../../../dataProvider/constant";
import { getStorage } from "../../../../../utils/common";
import FormSelectStyle from "../FormSelectStyle";
import AddSdsForm from "./AddSdsForm";
import styles from "../../JobStyle.module.css";

const EnabledComponent = ({ selected, change, allData, loading, filesBasePath }) => {

  const onRemove = (sds) => {
    change(`sds_section.enabled.item_${sds.id}`, false);
    change("sds_section.selected", selected.filter(x => x.id !== sds.id));
    change("sds_section.data", allData.filter(x => x.id !== sds.id));
  }

  return (
    <div className="contianer-fluid px-0">
      <div className="row">
        {selected.length === 0 ? (<div className="col-12 text-center py-2" style={{ color: "red" }}>No Safety Data Sheets Selected</div>) : ""}
        {
          selected.map((sds, index) => (
            <div className="col-4 d-flex align-items-center py-1" key={index.toString()}>
              <div style={{ flex: "1 1 auto", flexWrap: "wrap" }} className="d-flex align-items-center">
                <Field
                  disabled={loading}
                  component={CustomCheckbox}
                  name={`item_${sds.id}`}
                  className="cs-select-v2"
                  label={sds.label}
                />{sds.sds_documents && sds.sds_documents.file_name ? (<div>- <a className={styles.SwmsPreviewLink} target="_blank" rel="noreferrer noopener" href={`${filesBasePath}${sds.sds_documents.file_name}`}>Attached Document</a></div>) : ""}
              </div>
              {sds.isNew ? (<div>
                <IconButton disabled={loading} style={{ height: "auto", padding: "0px" }} onClick={() => onRemove(sds)}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{ height: "12px", color: "red" }}>
                    <path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                  </svg>
                </IconButton>
              </div>) : ""}
            </div>
          ))
        }
      </div>
    </div>
  )
}
const SdsSection = ({ allData = [], selected = [], change, newEntry = "", sdsDoc = null, loading }) => {

  const [addModal, setAddModal] = useState(false);

  useEffect(() => {
    let obj = allData.filter(x => selected.findIndex(y => y.id.toString() === x.id.toString()) === -1);
    obj.forEach(el => {
      change(`sds_section.enabled.item_${el.id}`, false)
    })
  }, [selected, allData]);

  const filesBasePath = useMemo(() => {
    let filesPath = "";
    try {
      let organisation = JSON.parse(getStorage(ADMIN_DETAILS)).organisation;
      let domain = new URL(organisation.logo_path).origin;
      let slug = organisation.slug;
      filesPath = `${domain}/${slug}/`;
    } catch (e) {
      console.log(e);
    }
    return filesPath;
  }, []);

  const onNewAdd = (newVals) => {
    change("sds_section.data", [...allData, { ...newVals }]);
    change("sds_section.selected", [...selected, { ...newVals, label: newVals.method_item, value: newVals.id }])
    setAddModal(false);
  }

  return (
    <div className="m-1 mb-2">
      <FormSection name="enabled" component={EnabledComponent} selected={selected} filesBasePath={filesBasePath} change={change} allData={allData} loading={loading} />
      <div className="contianer-fluid px-0 mt-2">
        <div className="row">
          <div className="col-4 pb-3">
            <div className={styles.Label}>Select Safety Data Sheet</div>
            <Field
              name="selected"
              component={CustomSingleSelect}
              options={allData.map(x => ({ label: x.method_item, value: x.id, ...x }))}
              styles={FormSelectStyle}
              otherProps={{
                isDisabled: loading,
                isMulti: true,
                hideSelectedOptions: false,
                closeMenuOnSelect: false,
                menuPortalTarget: document.body,
                components: { dropdownIndicator: <components.DownChevron /> }
              }}
            />
          </div>
        </div>
        <div className="row align-items-end">
          <div className="col-4">
            <button type="button" disabled={loading} className={styles.PrimaryButton} onClick={() => setAddModal(true)}>Add Safety Data Sheet</button>
          </div>
        </div>
      </div>
      <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
        <Modal
          className="cs-modal-v2"
          centered
          footer={null}
          visible={addModal}
          width="600px"
          onCancel={() => setAddModal(false)}
          maskClosable={false}
          bodyStyle={{ paddingRight: "0px", paddingLeft: "0px", maxHeight: "80vh", overflow: "hidden auto" }}
          destroyOnClose={true}
          closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
        >
          <AddSdsForm onClose={() => setAddModal(false)} onNewAdd={onNewAdd} />
        </Modal>
      </div>
    </div>
  )
}

export default connect((state) => ({
  allData: (formValueSelector("job_swms_form"))(state, "sds_section.data"),
  selected: (formValueSelector("job_swms_form"))(state, "sds_section.selected"),
  newEntry: (formValueSelector("job_swms_form"))(state, "sds_section.new_entry"),
  sdsDoc: (formValueSelector("job_swms_form"))(state, "sds_section.sds_doc")
}))(SdsSection);