export const fields = [
  {
    title: "Ewp",
    key: "10",
    fieldName: "ewp_allowance",
  },
  {
    title: "WAH",
    key: "11",
    fieldName: "wah_allowance",
  },
  {
    title: "<22 RA",
    key: "12",
    fieldName: "ra_less_than_22",
  },
  {
    title: ">22 RA",
    key: "13",
    fieldName: "ra_greater_than_22",
  },
  {
    title: "Cold",
    key: "14",
    fieldName: "cold_places_allowance",
  },
  {
    title: "Hot Place 46c-56C",
    key: "15",
    fieldName: "hotPlaces_allowance_between_46and54_degrees",
  },
  {
    title: "Hot Place +54C",
    key: "16",
    fieldName: "hot_places_allowance_exceeding_54degrees",
  },
  {
    title: "First Aid",
    key: "17",
    fieldName: "first_aid_allowance",
  },
  {
    title: "Leading/hand (1-10)",
    key: "18",
    fieldName: "leading_hand_allowance_1to10_employees",
  },
  {
    title: "Leading/hand (11-20)",
    key: "19",
    fieldName: "leading_hand_allowance_11to20_employees",
  },
  {
    title: "Leading/hand (21-30)",
    key: "20",
    fieldName: "leading_hand_allowance_over_20_employees",
  },
  {
    title: "Refuse Collect",
    key: "21",
    fieldName: "refuse_collection_allowance",
  },
  {
    title: "Toilet Cleaning",
    key: "22",
    fieldName: "toilet_cleaning_allowance",
  },
  {
    title: "Meals",
    key: "23",
    fieldName: "meal_allowance",
  },
  {
    title: "Vehicle",
    key: "24",
    fieldName: "vehicle_allowance",
  },
  {
    title: "Motor Bike",
    key: "25",
    fieldName: "motor_cycle_allowance",
  },
];
