
import React from 'react';
import { Upload, notification } from 'antd';
import { reduxForm, Field, isDirty } from 'redux-form';
import { addNewLicencesValidator } from '../../../utils/Validations/addNewLicencesValidation';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { customInput } from '../../common/custom-input';
import * as actions from '../../../actions/profileManagementActions';
import { Strings } from '../../../dataProvider/localize';
import { CustomDatepicker } from '../../common/customDatepicker';
import moment from 'moment';
import { ADMIN_DETAILS, VALIDATE_STATUS } from '../../../dataProvider/constant';
import { getStorage, handleFocus, DeepTrim } from '../../../utils/common';
import { withRouter } from 'react-router';
import colourStyles from "./FilterSelectProfileStyles";
import { components } from "react-select";
import "./ProfileStyle.css";
import { CustomSingleSelect } from "../../common/new-core-ui/CustomSingleSelect";
import styles from "./ProfileStyles.module.css";

const Dragger = Upload.Dragger;
class AddNewLicences extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            fileList: [],
            fileError: 'none',
            backfileList: [],

        }

        this.org_user_id = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id : null;
        this.org_user_name = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)).user_name : null;
    }

    onSubmit = async (values) => {
        let formData = { ...values };

        formData.issued_date = moment(formData.issued_date).toString();
        formData.expiry_date = moment(formData.expiry_date).toString();
        formData.licence_type = formData.licenseType && formData.licenseType.label ? formData.licenseType.label : null;
        formData.type = formData.licenseType && formData.licenseType.value ? formData.licenseType.value : null;
        if (formData.licenseType) { delete formData.licenseType; }

        let profileProgress = this.props.profile && this.props.profile[0] && !this.props.profile[0].licences ?
            this.props.profileComplete + 10 : this.props.profileComplete
        formData = { ...formData, 'profile_progress': profileProgress }
        var finalFormData = new FormData();
        Object.keys(formData).forEach(key => {
            if (key === 'file' || key === "license_back_photo_file") {
                finalFormData.append(key, formData[key])
            }
            else {
                finalFormData.append(key, JSON.stringify(formData[key]));
            }
        });
        let staffUserName = false
        if (this.props.userName) {
            staffUserName = this.props.userName
        }
        if (formData.file) {
            this.props.userActions.adNewLicencesApi(finalFormData, staffUserName)
                .then(flag => {
                    this.handleCancel();
                    notification.success({
                        message: Strings.success_title,
                        description: flag,
                        onClick: () => { },
                        className: 'ant-success'
                    });
                    this.props.userActions.getOrgUserProfileDetails(this.org_user_id, staffUserName ? staffUserName : this.org_user_name);
                })
                .catch((error) => {
                    if (error.status === VALIDATE_STATUS) {
                        notification.warning({
                            message: Strings.validate_title,
                            description: error && error.data && typeof error.data.message == 'string'
                                ? error.data.message : Strings.generic_validate,
                            onClick: () => { },
                            className: 'ant-warning'
                        });
                    } else {
                        notification.error({
                            message: Strings.error_title,
                            description: error && error.data && error.data.message && typeof error.data.message == 'string'
                                ? error.data.message : Strings.generic_error,
                            onClick: () => { },
                            className: 'ant-error'
                        });
                    }
                });
        } else {
            notification.error({
                message: Strings.error_title,
                description: 'Please upload file.',
                className: 'ant-error'
            });
        }
    }

    handlePreUpload = (file, fileList) => {
        return false;
    }

    handleChange = info => {
        const isJpgOrPng = info.file.type === 'image/jpeg' || info.file.type === 'image/png' || info.file.type === 'application/pdf';
        const isLt2M = info.file.size / 1024 / 1024 < 2;

        if (!isJpgOrPng) {
            notification.error({
                message: Strings.error_title,
                description: 'You can only upload JPG/PNG/PDF file!',
                onClick: () => { },
                className: 'ant-error'
            });
        } else {
            if (!isLt2M) {
                notification.error({
                    message: Strings.error_title,
                    description: 'File must smaller than 2MB!',
                    onClick: () => { },
                    className: 'ant-error'
                });
            } else {
                if (this.state.fileList.length === 0 && info.file.status !== 'removed') {
                    this.setState({ fileList: [...info.fileList] })
                    this.props.change('file', info.file)
                    this.setState({
                        fileError: 'none'
                    })
                }
                if (this.state.fileList.length > 0) {
                    let fileList = [...info.fileList];
                    fileList = fileList.slice(-1);
                    this.setState({ fileList })
                    this.props.change('file', info.file)
                }
            }
        }
    }

    handleRemove = file => {
        file.status = 'removed';
        this.setState({ fileList: [] });
        delete this.props.formValues.file;
    }

    handleBackChange = info => {
        const isJpgOrPng = info.file.type === 'image/jpeg' || info.file.type === 'image/png' || info.file.type === 'application/pdf';
        const isLt2M = info.file.size / 1024 / 1024 < 2;

        if (!isJpgOrPng) {
            notification.error({
                message: Strings.error_title,
                description: 'You can only upload JPG/PNG file!',
                onClick: () => { },
                className: 'ant-error'
            });
        } else {
            if (!isLt2M) {
                notification.error({
                    message: Strings.error_title,
                    description: 'Image must smaller than 2MB!',
                    onClick: () => { },
                    className: 'ant-error'
                });
            } else {
                if (this.state.backfileList.length === 0 && info.file.status !== 'removed') {
                    this.setState({ backfileList: [...info.fileList] })
                    this.props.change('license_back_photo_file', info.file)
                    this.setState({
                        fileError: 'none'
                    })
                }
                if (this.state.backfileList.length > 0) {
                    let fileList = [...info.fileList];
                    fileList = fileList.slice(-1);
                    this.setState({ backfileList: fileList })
                    this.props.change('license_back_photo_file', info.file)
                }
            }
        }
    }

    handleBackRemove = file => {
        file.status = 'removed';
        this.setState({ backfileList: [] });
        delete this.props.formValues.license_back_photo_file;
    }

    handleCancel = () => {
        this.props.reset();
        this.props.onCancel();
    }

    render() {
        const { handleSubmit, licenceType } = this.props;

        const uploadLicenceProps = {
            accept: ".jpeg,.jpg,.png,.pdf",
            multiple: false,
            listType: "picture",
            fileList: this.state.fileList,
            beforeUpload: this.handlePreUpload,
            onChange: this.handleChange,
            onRemove: this.handleRemove,
        };

        const uploadBackLicenceProps = {
            accept: ".jpeg,.jpg,.png,.pdf",
            multiple: false,
            listType: "picture",
            fileList: this.state.backfileList,
            beforeUpload: this.handlePreUpload,
            onChange: this.handleBackChange,
            onRemove: this.handleBackRemove,
        };

        return (
            <div>
                <form onSubmit={handleSubmit(this.onSubmit)}>
                    <div className="container-fluid px-0">
                        <div className="row">
                            <div className="col-3 border-right">
                                <div className={styles.ProfileLabelV2}>Licence Type</div>
                                <div>
                                    <Field
                                        name="licenseType"
                                        placeholder={Strings.type_licen}
                                        // validate={isRequired}
                                        id="type"
                                        options={licenceType && licenceType.map((type) => ({
                                            label: type.name,
                                            value: type.id
                                        }))}
                                        component={CustomSingleSelect}
                                        styles={colourStyles}
                                        otherProps={{
                                            components: { dropdownIndicator: <components.DownChevron /> },
                                            menuPortalTarget: document.body
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-3 border-right">
                                <div className={styles.ProfileLabelV2}>Licence Number</div>
                                <div>
                                    <Field
                                        name="number"
                                        placeholder={Strings.number_licen}
                                        type="text"
                                        id="number"
                                        className={styles.ProfileFormField}
                                        component={customInput} />
                                </div>
                            </div>
                            <div className="col-3 border-right">
                                <div className={styles.ProfileLabelV2}>Licence Issues By</div>
                                <div>
                                    <Field
                                        name="issued_by"
                                        placeholder={Strings.issued_by_licen}
                                        type="text"
                                        id="issued_by"
                                        className={styles.ProfileFormField}
                                        component={customInput} />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className={styles.ProfileLabelV2}>Licence Issues Date</div>
                                <div>
                                    <Field
                                        name="issued_date"
                                        type="text"
                                        id="issued_date"
                                        className="profile-v2-date-picker"
                                        dropdownClassName="cs-datepicker-dropdown"
                                        component={CustomDatepicker} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3 border-right">
                                <div className={styles.ProfileLabelV2}>Licence Expiry Date</div>
                                <div>
                                    <Field
                                        name="expiry_date"
                                        type="text"
                                        id="expiry_date"
                                        className="profile-v2-date-picker"
                                        dropdownClassName="cs-datepicker-dropdown"
                                        component={CustomDatepicker} />
                                </div>
                            </div>
                            <div className="col-3 border-right">
                                <div className={styles.ProfileLabelV2}>Upload Front Side</div>
                                <div>
                                    <div className="logo-upload">
                                        <Dragger  {...uploadLicenceProps} >
                                            <p className="ant-upload-drag-icon">
                                                <i class="anticon material-icons">cloud_upload</i>
                                            </p>
                                            <p className="ant-upload-text">{Strings.img_upload_text}</p>
                                        </Dragger>
                                    </div>
                                    {this.state.fileError === 'block' ?
                                        <label className="error-input">Please Upload fill.</label>
                                        :
                                        ''
                                    }
                                </div>
                            </div>
                            <div className="col-3 border-right">
                                <div className={styles.ProfileLabelV2}>Upload Back Side</div>
                                <div>
                                    <div className="logo-upload">
                                        <Dragger  {...uploadBackLicenceProps} >
                                            <p className="ant-upload-drag-icon">
                                                <i class="anticon material-icons">cloud_upload</i>
                                            </p>
                                            <p className="ant-upload-text">{Strings.img_upload_text}</p>
                                        </Dragger>
                                    </div>
                                    {this.state.fileError === 'block' ?
                                        <label className="error-input">Please Upload fill.</label>
                                        :
                                        ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <div className="d-flex">
                                    <button type="button" onClick={this.handleCancel} className={`${styles.BtnResetTab} mr-1`}>
                                        Cancel</button>
                                    <button type="submit" className={styles.BtnSaveTab} disabled={!this.props.isDirty}>
                                        Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let value = ownProps && ownProps.licencesDetails

    return {
        formValues: state.form && state.form.AddNewLicences && state.form.AddNewLicences.values
            ? state.form.AddNewLicences.values : {},
        licenceType: state.profileManagement && state.profileManagement.licenceType,
        profile: state.profileManagement && state.profileManagement.profile,
        profileComplete: state.profileManagement && state.profileManagement.profileComplete,
        initialValues: value ? value : { type: [] },
        isDirty: isDirty('AddNewLicences')(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        userActions: bindActionCreators(actions, dispatch),
    }
}


export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'AddNewLicences', validate: addNewLicencesValidator, enableReinitialize: true,
        onSubmitFail: (errors, dispatch, sub, props) => {
            handleFocus(errors, "#");
        }
    })
)(AddNewLicences)