import React from "react";
import { Menu, Pagination, notification } from "antd";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { validate } from "../../../utils/Validations/subscriptionValidate";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import * as actions from "../../../actions/profileManagementActions";
import { Strings } from "../../../dataProvider/localize";
import { ADMIN_DETAILS, ACCESS_CONTROL } from "../../../dataProvider/constant";
import { getStorage, handleFocus } from "../../../utils/common";
import "./ProfileStyle.css";
import styles from "./ProfileStyles.module.css";
import LicenseView from "./LicenseView";

class Licences extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      minValue: 0,
      maxValue: 4,
      page_no: 1,
    };

    this.org_user_id = JSON.parse(getStorage(ADMIN_DETAILS))
      ? JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id
      : null;
    this.org_user_name = JSON.parse(getStorage(ADMIN_DETAILS))
      ? JSON.parse(getStorage(ADMIN_DETAILS)).user_name
      : null;
  }

  // more info
  editMenu = (
    <Menu>
      <Menu.Item onClick={this.handleEditClick}>
        {Strings.menu_edit_org}
      </Menu.Item>
    </Menu>
  );

  componentDidMount() {
    this.props.userActions.getLicencesType();
    if (this.hasPermission("sf_edit_licenses_controller_edit_licenses")) {
      this.setState({ hasEditPermission: true });
    }
  }

  componentDidUpdate(prevProps, prevState) { }

  onSubmit = (formData) => { };

  addNewLicence = () => {
    const show = "block";
    this.props.onPropsSet(show);
  };

  editLicence = (data) => {
    const show = "block";
    this.props.onPropsSet(show, data);
  };

  deleteLicence = (id) => {
    let formData;
    this.props.profile &&
      this.props.profile[0] &&
      this.props.profile[0].licences &&
      this.props.profile[0].licences.length === 1
      ? (formData = {
        id: id,
        profile_progress: this.props.profileComplete - 10,
      })
      : (formData = { id: id });
    this.props.userActions
      .deleteLicence(formData, this.props.userName)
      .then((message) => {
        notification.success({
          message: Strings.success_title,
          description: message,
          onClick: () => { },
          className: "ant-success",
        });
        this.props.userActions.getOrgUserProfileDetails(
          this.props.userId,
          this.props.userName
        );
      })
      .catch((message) => {
        notification.error({
          message: Strings.error_title,
          description: message ? message : Strings.generic_error,
          onClick: () => { },
          className: "ant-error",
        });
      });
    this.props.reset();
  };

  handleChange = (page) => {
    this.setState({
      page_no: page,
    });
    if (page) {
      this.props.userActions.getOrgUserProfileDetails(
        this.props.userId,
        this.props.userName,
        page
      );
    }
  };

  hasPermission = (permission) => {
    let allowed = false;
    let userPermissions = JSON.parse(getStorage(ACCESS_CONTROL))["userManagement"]
    let accessControl = userPermissions && userPermissions.permissions ? userPermissions.permissions : [];
    let permission_id = accessControl.findIndex(acess => acess.control_name.toLowerCase() === permission.toLowerCase());
    if (permission_id !== -1 || this.props.location.pathname === "/dashboard/profile") {
      allowed = true;
    }
    return allowed;
  }

  render() {
    const { handleSubmit, initialValues, licenceType, profile } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        {/* Personal Details */}

        <hr className={styles.DetailsDivider}></hr>
        <div className="container-fluid pt-3">
          <div className="row align-items-center">
            <h2 className={`${styles.ProfileLabel} font-weight-bold`}>Licences</h2>
          </div>
          <div className="row">
            <div className="col-12">
              {!initialValues ? (
                <div className="no-licence">
                  <img src="../../../images/no-licence.png" alt="preview" />
                  <h2>
                    <strong>Upload relevant licences</strong>
                    File type permitted are .png and .jpg.{" "}
                    <span>Max file size: 4MB</span>
                  </h2>
                </div>
              ) : (
                <div className="has-licence">
                  <div className="row">
                    {initialValues &&
                      initialValues.length &&
                      initialValues.length > 0 &&
                      initialValues.map((license) => {
                        return (
                          <LicenseView
                            profile={profile}
                            license={license}
                            editLicence={this.editLicence}
                            deleteLicence={this.deleteLicence}
                            isDisabled={!this.state.hasEditPermission}
                            key={license.id}
                            licenseType={licenceType} />
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button
                type="button"
                className={styles.BtnSaveTab}
                onClick={this.addNewLicence}
                disabled={!this.state.hasEditPermission}
              >
                Add Licences
              </button>
            </div>
          </div>
        </div>
        {
          profile && profile[0] && profile[0].licences_count > 10 ? (
            <Pagination
              className="sf-pagination"
              defaultCurrent={this.state.page_no}
              defaultPageSize={10}
              total={profile[0].licences_count}
              onChange={this.handleChange}
            />
          ) : (
            ""
          )
        }
      </form >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profileManagement && state.profileManagement.profile,
    licenceType: state.profileManagement && state.profileManagement.licenceType,
    profileComplete:
      state.profileManagement && state.profileManagement.profileComplete,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators(actions, dispatch),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "Licences",
    validate,
    onSubmitFail: (errors, dispatch, sub, props) => {
      handleFocus(errors, "#");
    },
  })
)(Licences);
