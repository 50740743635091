import React, { useEffect, useState } from 'react'
import { Input, Button, notification } from 'antd';
import { ADMIN_DETAILS } from '../../../../../../dataProvider/constant';
import { getStorage } from '../../../../../../utils/common';
import { useDispatch } from "react-redux";
import { updatePurchaseOrder } from "../../../../../../actions/jobAction"
import { Strings } from "../../../../../../dataProvider/localize"

const JobBoardHeader = ({ jobDetails, actions }) => {

  const [poNumber, setPoNumber] = useState();
  const dispatch = useDispatch();

  const handlePoChange = (e) => {
    setPoNumber(e.target.value)
  }

  useEffect(() => {
    if (!(!jobDetails.PO_number)) {
      setPoNumber(jobDetails.PO_number);
    }
  }, [jobDetails.PO_number])

  const handlePoSave = () => {
    if (!(!poNumber)) {
      var formData = {
        job_id: jobDetails.id,
        PO_number: poNumber,
      };
      Promise.resolve(dispatch(updatePurchaseOrder(formData))).then(res => {
        if (res.status && res.status === 1) {
          refreshData();
          notification.success({
            message: Strings.success_title,
            description: "Purchase Order Number Updated Successfully.",
            className: "ant-success",
          });
        } else {
          notification.error({
            message: Strings.error_title,
            description: "Error while Updating Purchase Order Number.",
            className: "ant-error",
          })
        }
      }).catch(() => {
        notification.error({
          message: Strings.error_title,
          description: "Error while Updating Purchase Order Number.",
          className: "ant-error",
        })
      });
    }
  }

  const refreshData = () => {
    const adminDetails = JSON.parse(getStorage(ADMIN_DETAILS));
    actions
      .getTaskJobDetails(
        jobDetails.id,
        adminDetails.role.role_id,
        adminDetails.organisation.organisation_type
      )
  }

  return (
    <>
      <div style={{ marginTop: '10px' }}></div>
      <div className="sf-card">
        <div className="sf-card-body">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-7 col-md-8">
                <div>
                  <label>Client Name: </label>
                  <span>{jobDetails.client_name}</span>
                </div>
                <div>
                  <label>ABN: </label>
                  <span>{jobDetails.abn_acn}</span>
                </div>
                <div>
                  <label>Address: </label>
                  <span>{jobDetails.client_address}</span>
                </div>
                <div>
                  <label>Contact: </label>
                  <span>{jobDetails.client_person_name}</span>
                </div>
                <div>
                  <label>Phone Number: </label>
                  <span>{jobDetails.client_person_phone}</span>
                </div>
                <div>
                  <label>Email: </label>
                  <span>{jobDetails.client_person_email}</span>
                </div>
              </div>
              <div className="col-12 col-sm-5 col-md-4">
                <div className="d-flex justify-content-end">
                  <div>
                    <div style={{ fontSize: "15px", fontWeight: "600", padding: "12px" }} >
                      Job ID: {jobDetails.job_label}
                    </div>
                    <button
                      type="primary"
                      className="bnt bnt-active"
                      onClick={refreshData}
                    >Refresh Data</button>
                  </div>
                </div>
                <div className="mt-3">
                  <label>Purchase Order Number</label>
                  <Input type="text" placeholder="Purchase Order Number" value={poNumber} onChange={handlePoChange} />
                  <div className="d-flex justify-content-end my-2">
                    <Button type="primary" disabled={!poNumber || poNumber.toString().trim() === "" || jobDetails.PO_number === poNumber} size="default" onClick={handlePoSave}>
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default JobBoardHeader
