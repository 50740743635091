import React, { useEffect, useRef, useState } from "react";
import styles from "../../JobStyle.module.css";

export default function ApprovalSignature(props) {
  const clearRef = useRef(null);

  const [width, setWidth] = useState(400);

  useEffect(() => {
    const clearButton = clearRef.current;
    if (props.forwardedRef && props.forwardedRef.current) {
      const canvas = props.forwardedRef.current;
      const ctx2 = canvas.getContext("2d");

      let x = 0;
      let y = 0;
      let curX = 0;
      let curY = 0;

      let mouseDown = false;
      let mouseIn = false;

      const handleMove = (e) => {
        e.preventDefault();
        x = curX;
        y = curY;
        if (e.type === "touchmove") {
          let touch = e.changedTouches.length && e.changedTouches[0];
          curX = touch.clientX - canvas.getBoundingClientRect().left;
          curY = touch.clientY - canvas.getBoundingClientRect().top;
        } else {
          curX = e.clientX - canvas.getBoundingClientRect().left;
          curY = e.clientY - canvas.getBoundingClientRect().top;
        }
        if (mouseDown && mouseIn) {
          // requestAnimationFrame(() => {
          ctx2.beginPath();
          ctx2.moveTo(x, y);
          ctx2.lineTo(curX, curY);
          ctx2.strokeStyle = "black";
          ctx2.lineWidth = 2;
          ctx2.stroke();
          ctx2.closePath();
          // });
        }
      };

      canvas.addEventListener("mousedown", (e) => {
        e.preventDefault();
        x = curX;
        y = curY;
        curX = e.clientX - canvas.getBoundingClientRect().left;
        curY = e.clientY - canvas.getBoundingClientRect().top;
        mouseDown = true;
      });

      canvas.addEventListener("mousemove", handleMove, false);

      canvas.addEventListener("mouseup", (e) => {
        e.preventDefault();
        mouseDown = false;
      });

      canvas.addEventListener("mouseover", (e) => {
        e.preventDefault();
        mouseIn = true;
      });

      canvas.addEventListener("mouseout", (e) => {
        e.preventDefault();
        mouseIn = false;
        mouseDown = false;
      });

      canvas.addEventListener("touchstart", (e) => {
        e.preventDefault();
        x = curX;
        y = curY;
        let touch = e.changedTouches.length && e.changedTouches[0];
        curX = touch.clientX - canvas.getBoundingClientRect().left;
        curY = touch.clientY - canvas.getBoundingClientRect().top;
        mouseIn = true;
        mouseDown = true;
      });

      canvas.addEventListener("touchmove", handleMove, false);
      canvas.addEventListener("touchend", (e) => {
        e.preventDefault();
        mouseDown = false;
      });

      canvas.addEventListener("touchend", (e) => {
        e.preventDefault();
        mouseIn = false;
      });
      if (clearButton) {
        clearButton.addEventListener("click", () => {
          ctx2.clearRect(0, 0, canvas.width, canvas.height);
        });
      }
    }
  }, [props.forwardedRef && props.forwardedRef.current]);

  return (
    <div>
      <div className="" style={{ margin: "5px auto", width: `${width}px`, ...(props.submitting ? { pointerEvents: "none", cursor: "not-allowed" } : {}) }}>
        <canvas
          {...props}
          ref={props.forwardedRef}
          className=""
          style={{ border: "1px solid #eaeaea", borderRadius: "10px" }}
          width={width}
          height={200}
        />
        <div className="d-flex align-items-center justify-content-between">
          <div>Use Cursor to draw your Signature.</div>
          <button disabled={props.submitting} className={`${styles.OutlinedButton}`} style={{ height: "auto" }} ref={clearRef}>Clear</button>
        </div>
        <div className="text-center my-3">
          <button onClick={() => props.onSave(1)} className={styles.PrimaryButton} disabled={props.submitting}>Sign & Approve</button>
        </div>
      </div>
    </div>
  );
}

