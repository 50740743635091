import React, { useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, Icon, Modal, notification, Popover } from 'antd';
import { ClickAwayListener, LinearProgress } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { getSwmsTask } from "../../../../../actions/v2/JobSwmsTaskActions";
import { Strings } from "../../../../../dataProvider/localize";
import { getStorage } from "../../../../../utils/common";
import { ADMIN_DETAILS } from "../../../../../dataProvider/constant";
import SwmsManagerApprovalModal from "./SwmsManagerApprovalModal";
import SwmsSelectionModal from "./SwmsSelectionModal";
import SwmsCopyTaskModal from "./SwmsCopyTaskModal";
import PreviewSwms from '../JobSwmsPreview/PreviewSwms';
import DeleteSwmsModal from "./DeleteSwmsModal";
import ApproveRejectSwmsModal from "./ApproveRejectSwmsModal";
import JobSwmsTaskVersionRow from "./JobSwmsTaskVersionRow";
import VersionSelectionModal from "./VersionSelectionModal";
import CopySiteSwmsModal from "./CopySiteSwmsModal";
import AttachSwmsModal from "./AttachSwmsModal";
import { getSwmsLatestVersions } from "../../../../../actions/v2/RosteringActions";
import SwmsVersionModal from "../../../Rostering/AllocateStaff/SwmsVersionModal";

import styles from "../../JobStyle.module.css";
import SignHistoryContainer from "./SignHistoryContainer";

const CreateButton = ({ onCopyFromSite, task, onCreateNew, loading }) => {
  const [showPop, setShowPop] = useState(false);
  return (
    <ClickAwayListener onClickAway={() => setShowPop(false)}>
      <div className="mr-4" role="presentation">
        <div className="row">
          <div>
            <Popover
              destroyTooltipOnHide={true}
              placement="topRight"
              onVisibleChange={(e) => { setShowPop(e) }}
              content={
                <div onClick={(e) => e.stopPropagation()}>
                  <div className={styles.PopText}>You want to copy previous versions available for {task.site_name ? task.site_name : ""} -<br />{task.task_name ? task.task_name : ""}{task.task_type_name ? " - " + task.task_type_name : ""} or Create New SWMS.<br />Please choose an action from below.</div>
                  <div style={{ columnGap: "10px" }} className="d-flex align-items-center justify-content-end mt-2">
                    <button className={styles.PrimaryButton} onClick={() => { setShowPop(false); onCopyFromSite(task) }}>Copy SWMS from Site</button>
                    <button className={styles.OutlinedButton} style={{ height: "auto", padding: "10px 25px" }} onClick={() => { setShowPop(false); onCreateNew(task); }}>Create New SWMS</button>
                  </div>
                </div>
              }
              trigger="click"
              visible={showPop}
            >
              <span className="text-right">
                <button disabled={loading} onClick={() => setShowPop(true)} className={styles.PrimaryButton}>
                  Create New SWMS
                </button>
              </span>
            </Popover>

          </div>
        </div >
      </div>
    </ClickAwayListener>)
}


export default function JobSwmsTasks({ goToForm, jobId, sections, setSections }) {

  const dispatch = useDispatch();
  const controller = useRef();
  const { Panel } = Collapse;

  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [selectionModal, setSelectionModal] = useState(false);
  const [approvalModal, setApprovalModal] = useState(false);
  const [copyTaskModal, setCopyTaskModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [previewSwmsModal, setPreviewSwmsModal] = useState(false);
  const [selectedTaskSWMS, setSelectedTaskSWMS] = useState(null);
  const [versionSelection, setVersionSelection] = useState(false);
  const [copySiteModal, setCopySiteModal] = useState(false);
  const [attachSwmsModal, setAttachSwmsModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [showSwmsVersionModal, setShowSwmsVersionModal] = useState(false);
  const [selectedSwmsVersion, setSelectedSwmsVersion] = useState({});
  const [swmsVersions, setSwmsVersions] = useState([]);

  const jobType = useSelector(state => state.Job.jobDetails && state.Job.jobDetails.hasOwnProperty('job_type') && state.Job.jobDetails.job_type);

  const fetchSwmsTasks = () => {
    setLoading(true);
    controller.current = new AbortController();
    dispatch(getSwmsTask({ signal: controller.current.signal }, jobId)).then(res => {
      let tasks = res.map(task => {
        const attachedSwmsRecord = task.swms_versions.find(item => item.is_swms_attched_to_shifts === 1);
        if (attachedSwmsRecord) {
          return { ...task, attached_swms_form_id: attachedSwmsRecord.swms_form_id }
        }
        else {
          return { ...task, attached_swms_form_id: null }
        }
      });
      setTasks(tasks);
      setLoading(false);
    }).catch((err) => {
      if (err) {
        setLoading(false);
        notification.error({
          message: Strings.error_title,
          description: "There was an error while fetching Job Swms Tasks Details.",
          className: "ant-error"
        })
      }
    })
  }

  const currentUser = useMemo(() => {
    let userName = "";
    let adminDetails = JSON.parse(getStorage(ADMIN_DETAILS));
    if (adminDetails && adminDetails.user_name) {
      userName = adminDetails.user_name;
    }
    return userName;
  }, [])

  useEffect(() => {
    document.title = "Job Swms - FM Farm";
    fetchSwmsTasks();
    return () => {
      if (controller.current) {
        controller.current.abort()
      }
    }
  }, []);

  const onCopySwms = (task, swms) => {
    setSelectedTaskSWMS({ task, swms });
    setCopyTaskModal(true);
  }

  const onDelete = (task, swms) => {
    setSelectedTaskSWMS({ task, swms });
    setDeleteModal(true);
  }

  const onSendApproval = (task, swms) => {
    setSelectedTaskSWMS({ task, swms });
    setApprovalModal(true);
  }

  const onApproveReject = (task, swms) => {
    setSelectedTaskSWMS({ task, swms });
    setConfirmModal(true);
  }

  const onCreateNew = (task) => {
    setSelectedTaskSWMS({ task });
    setSelectionModal(true);
  }

  const openPreviewModal = (task, swms) => {
    setSelectedTaskSWMS({ task, swms });
    setPreviewSwmsModal(true);
  }


  const handleActionOption = (key, task, version) => {
    if (key === "1") {
      openPreviewModal(task, version);
    }
    if (key === "2") {
      onCopySwms(task, version)
    }
    if (key === "3") {
      onDelete(task, version);
    }
    if (key === "4") {
      onApproveReject(task, version);
    }
    if (key === "5") {
      onSendApproval(task, version);
    }
    if (key === "6") {
      goToForm({ task: task, version: version });
    }
  }

  const onCopyFromSite = (task) => {
    setSelectedTaskSWMS({ task });
    setCopySiteModal(true);
  }

  const fetchSwmsLatestVersions = (task) => {
    setLoading(true);
    dispatch(getSwmsLatestVersions({ task_id: `${task.task_id}`, job_id: jobId }))
      .then(res => {
        if (res.swmsVersionDetails.length) {
          setSwmsVersions(res.swmsVersionDetails);
          const selectedVersion = {};
          if (task.attached_swms_form_id !== null) {
            selectedVersion[task.task_id] = task.attached_swms_form_id;
          }
          else {
            res.swmsVersionDetails.forEach(item => {
              selectedVersion[item.task_id] = item.swms_form_ids.filter(x => x.is_latest === 1)[0].swms_form_id;
            });
          }
          setSelectedSwmsVersion({ ...selectedVersion });
          if (res.swmsVersionDetails[0].swms_form_ids.length === 1) {
            setAttachSwmsModal(true);
          }
          else {
            setShowSwmsVersionModal(true);
          }
        }
        setLoading(false);
      }).catch(err => {
        if (err) {
          setLoading(false);
          notification.error({
            message: Strings.error_title,
            description: err ? err : Strings.generic_error,
            className: "ant-error"
          })
        }
      })
  }

  const handleAttachSwms = (task) => {
    setSelectedTask(task);
    fetchSwmsLatestVersions(task);
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className={styles.SwmsTitle}>
            Tasks List
          </div>
          <div style={{ height: "4px" }}>
            {loading ? (
              <LinearProgress />
            ) : ""}
          </div>
          {tasks && tasks.length ? (
            <div className={`${styles.TabsCover} pb-0`}>
              <Collapse
                bordered={false}
                expandIconPosition="right"
                defaultActiveKey="0"
                activeKey={sections}
                onChange={e => setSections(e)}
                className="custom-section-v2-collapse custom-job-swms-collapse"
                expandIcon={({ isActive }) => (
                  <Icon type="caret-right" rotate={isActive ? 90 : 0} style={{ color: "#2f1f41" }} />)
                }
              >
                {tasks.map((task, index) => (
                  <Panel key={index} header={<div className={`${styles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>{task.task_number} - {task.task_name}</div>}
                    className="custom-job-swms-collapse-panel"
                    style={{ border: '0px !important' }}>
                    {task.swms_versions.map((version, index) =>
                      <JobSwmsTaskVersionRow
                        currentUser={currentUser}
                        loading={loading}
                        task={task}
                        version={version}
                        key={index}
                        handleActionOption={handleActionOption} />
                    )}
                    <div className="d-flex justify-content-end py-3">
                      <CreateButton onCopyFromSite={onCopyFromSite} task={task} loading={loading} onCreateNew={onCreateNew} />
                      {task.swms_versions.find(item => item.approval_status === "Manager Approved") && jobType === 'Planned' ?
                        <div>
                          <button className={styles.PrimaryButton} disabled={loading} onClick={() => handleAttachSwms(task)}>
                            {`${task.attached_swms_form_id && task.attached_swms_form_id !== null ? "Update" : ""} Attach SWMS to Shift`}
                          </button>
                        </div> : null}
                    </div>
                  </Panel>

                ))}
              </Collapse>
            </div>
          ) : (
            !loading ? (<div>
              <div className="text-center" style={{ color: "red" }}>Unable to load Tasks.</div>
              <div className="text-center">
                <button onClick={() => fetchSwmsTasks()} className={styles.OutlinedButton}>Retry</button>
              </div>
            </div>) : ""
          )}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="row py-3">
          <div className="col-6">
            <button className={styles.PrimaryButton} disabled={loading} onClick={() => setVersionSelection(true)}>
              Send SWMS to Client
            </button>
          </div>
        </div>
      </div>
      <div className="row py-3">
        <div className="col-12">
          <SignHistoryContainer
            jobId={jobId}
          />
        </div>
      </div>
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={versionSelection}
        onCancel={() => setVersionSelection(false)}
        afterClose={() => fetchSwmsTasks()}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        destroyOnClose={true}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
      >
        <VersionSelectionModal
          allTasks={tasks}
          loading={loading}
          onClose={() => setVersionSelection(false)}
        />
      </Modal>
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={selectionModal}
        onCancel={() => setSelectionModal(false)}
        afterClose={() => { setSelectedTaskSWMS(null); fetchSwmsTasks() }}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        destroyOnClose={true}
        width="1180px"
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
      >
        <SwmsSelectionModal
          {...(selectedTaskSWMS ? selectedTaskSWMS : {})}
          onClose={() => setSelectionModal(false)}
        />
      </Modal>
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={approvalModal}
        onCancel={() => setApprovalModal(false)}
        afterClose={() => { setSelectedTaskSWMS(null); fetchSwmsTasks() }}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        destroyOnClose={true}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
      >
        <SwmsManagerApprovalModal
          {...(selectedTaskSWMS ? selectedTaskSWMS : {})}
          onClose={() => setApprovalModal(false)}
        />
      </Modal>
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={copyTaskModal}
        onCancel={() => setCopyTaskModal(false)}
        afterClose={() => { setSelectedTaskSWMS(null); fetchSwmsTasks() }}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        destroyOnClose={true}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
      >
        <SwmsCopyTaskModal
          allTasks={tasks}
          {...(selectedTaskSWMS ? selectedTaskSWMS : {})}
          onClose={() => setCopyTaskModal(false)}
        />
      </Modal>
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={deleteModal}
        onCancel={() => setDeleteModal(false)}
        afterClose={() => { setSelectedTaskSWMS(null); fetchSwmsTasks() }}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        destroyOnClose={true}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
      >
        <DeleteSwmsModal
          {...(selectedTaskSWMS ? selectedTaskSWMS : {})}
          onClose={() => setDeleteModal(false)}
        />
      </Modal>
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={confirmModal}
        onCancel={() => setConfirmModal(false)}
        afterClose={() => { setSelectedTaskSWMS(null); fetchSwmsTasks() }}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        destroyOnClose={true}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
      >
        <ApproveRejectSwmsModal
          {...(selectedTaskSWMS ? selectedTaskSWMS : {})}
          onClose={() => setConfirmModal(false)}
        />
      </Modal>
      <Modal
        className="cs-modal-v2"
        centered
        visible={previewSwmsModal}
        onCancel={() => setPreviewSwmsModal(false)}
        afterClose={() => { setSelectedTaskSWMS(null); fetchSwmsTasks() }}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        destroyOnClose={true}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
        height="95vh"
        width="80vw"
        footer={null}
      >
        {selectedTaskSWMS ? (
          <PreviewSwms
            {...selectedTaskSWMS}
            onClose={() => setPreviewSwmsModal(false)}
          />
        ) : ""}
      </Modal>
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={copySiteModal}
        onCancel={() => setCopySiteModal(false)}
        afterClose={() => { setSelectedTaskSWMS(null); fetchSwmsTasks() }}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        destroyOnClose={true}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
      >
        {selectedTaskSWMS ? (
          <CopySiteSwmsModal
            {...selectedTaskSWMS}
            onCreateSwms={() => { setCopySiteModal(false); onCreateNew(selectedTaskSWMS.task) }}
            onClose={() => setCopySiteModal(false)}
          />
        ) : ""}
      </Modal>
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={attachSwmsModal}
        onCancel={() => setAttachSwmsModal(false)}
        afterClose={() => { setSelectedTaskSWMS(null); setSelectedTask(null); fetchSwmsTasks() }}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px", maxHeight: "90vh", overflow: "hidden auto" }}
        destroyOnClose={true}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
        width="60vw"

      >
        <AttachSwmsModal
          jobId={jobId}
          onClose={() => setAttachSwmsModal(false)}
          selectedTask={selectedTask}
          selectedSwmsVersion={selectedSwmsVersion}
          allSwmsVersions={swmsVersions}
        />
      </Modal>
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={showSwmsVersionModal}
        maskClosable={false}
        onCancel={() => { setShowSwmsVersionModal(false) }}
        destroyOnClose={true}
        width="35vw"
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>
        }
      >
        <SwmsVersionModal
          swmsVersions={swmsVersions}
          selectedSwmsVersion={selectedSwmsVersion}
          onClose={(versions) => { setSelectedSwmsVersion(versions); setShowSwmsVersionModal(false); setAttachSwmsModal(true) }}
          jobType={jobType}
          selectedTask={selectedTask}
        />
      </Modal>
    </div>
  )
}
