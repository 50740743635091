import React from "react";
import { Icon, Dropdown, notification } from "antd";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import * as actions from "../../../../actions/SAJobMgmtAction";
import * as SWMSMastersActions from "../../../../actions/SWMSMastersActions";
import * as actionEmail from "../../../../actions/emailTemplateAction";
import { JobDocsEmailvalidate } from "../../../../utils/Validations/emailQuoteValidation";
import { customInput } from "../../../common/custom-input";
import { Strings } from "../../../../dataProvider/localize";
import EmailEditor from "../../../common/EmailEditor";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { handleFocus, goBackBrowser, getFormattedPhone, getFirstName } from "../../../../utils/common";
import { getStorage } from "../../../../utils/common";
import { ADMIN_DETAILS, SLUG_SIGN_SWMS } from "../../../../dataProvider/constant";
import { ERROR_NOTIFICATION_KEY } from "../../../../config";
import { MultiEmailInput } from "../../../common/chipInputRedux";
class EmailSwmsReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleCc: true,
      toggleBcc: true,
      editorState: "",
      togleSearch: true,
      adminDetails: JSON.parse(getStorage(ADMIN_DETAILS)),
      signature: "",
      noReply: ``
    };

  }


  componentDidMount(props) {
		document.title = "Email SWMS to Client - Service Farm";
    this.props.SWMSMastersActions.getOrgDetailsPreview(this.props.location.state.jobId, this.props.location.state.quoteId, this.props.location.state.jobSwmsId).then((res) => {
      let pdfFileName = res && res.pdf_file;
      let pdfLink = res && res.job_swms_pdf_link;

      if (this.props.location.state.pdfFileLink !== null) {
        pdfLink = this.props.location.state.pdfFileLink;
      }

      this.setState({ ...this.state, pdfLink: pdfLink, pdfFileName: pdfFileName })

      this.props.emailAction
        .getEmailTemplate(SLUG_SIGN_SWMS)
        .then((flag) => {
          this.editorState();

          this.props.change("from_profile", this.props.initialValues.from_profile);
          this.props.change("from_field", this.props.initialValues.from_field);
          // this.props.change('to_field', this.props.initialValues.to_field);
          this.props.change('cc_field', this.props.initialValues.cc_field);
          this.props.change('bcc_field', this.props.initialValues.bcc_field);
          this.props.change('subject', this.props.initialValues.subject);

        })
        .catch((message) => {
          notification.error({
            key: ERROR_NOTIFICATION_KEY,
            message: Strings.success_title,
            description: message ? message : Strings.generic_error,
            onClick: () => { },
            className: "ant-error",
          });
        });
    })
  }

  editorState = () => {
    let body = this.props.selectedMailContent && this.props.selectedMailContent.body
      ? this.props.selectedMailContent.body
      : "";
    
    let scopeDetails = this.props.selectedJobDetails ? this.props.selectedJobDetails : null

    let adminDetails = JSON.parse(getStorage(ADMIN_DETAILS));

    if (body.includes("{{link}}") && this.state.pdfLink) {
      body = body.replace("{{link}}", `<a href="${this.state.pdfLink}" target="_blank" style="text-decoration: underline;">click here</a>`);
    }
    
    if(body.includes("{{site_name}}")) {
      let site_name = scopeDetails && scopeDetails.sites && scopeDetails.sites.length > 0 && scopeDetails.sites[0].site_name ? scopeDetails.sites[0].site_name : ''
      body = body.replace("{{site_name}}", site_name);
    }

    if (body.includes("{{client_person_first_name")) {
      let client_person_first_name = this.props.clientDetails && this.props.clientDetails.client_person_name ? getFirstName(this.props.clientDetails.client_person_name) : ''
      body = body.replace("{{client_person_first_name}}", client_person_first_name);
    }

    if (body.includes("{{user_phone_number}}")) {
      let userPhoneNumber = adminDetails && adminDetails.phone_number ? getFormattedPhone(adminDetails.phone_number) : ''
      body = body.replace("{{user_phone_number}}", userPhoneNumber);
    }

    let name = adminDetails ? adminDetails.name + " " + adminDetails.last_name : "";
    let org_name = adminDetails.organisation ? adminDetails.organisation.name : ""
    body = body.replace(/\{\{user_name\}\}/g, `${name} (${org_name})`);

    if (body.includes("{{user_role}}")) {
      body = body.replaceAll("{{user_role}}", adminDetails && adminDetails.role && adminDetails.role.role_name ? adminDetails.role.role_name : '')
    }

    let userPhoneNumber = adminDetails && adminDetails.phone_number ? getFormattedPhone(adminDetails.phone_number) : ''
    body = body.replace(/\{\{user_phone_number\}\}/g, userPhoneNumber);

    body = body.replace(/\{\{user_email\}\}/g, adminDetails && adminDetails.email_address ? 
        `<a href="mailto:${adminDetails.email_address}">${adminDetails.email_address}</a>` : "")

    if (body.includes("{{org_phone}}")) {
      body = body.replaceAll("{{org_phone}}", adminDetails && adminDetails.organisation && adminDetails.organisation.phone_number ? adminDetails.organisation.phone_number : "")
    }

    if (body.includes("{{org_website}}")) {
      body = body.replaceAll("{{org_website}}", adminDetails && adminDetails.organisation && adminDetails.organisation.website ? adminDetails.organisation.website : '')
    }

    if (body.includes("{{org_address}}")) {
      body = body.replaceAll("{{org_address}}", adminDetails && adminDetails.organisation && adminDetails.organisation.address ? adminDetails.organisation.address : '')
    }

    if (body.includes("{{signature_image}}")) {
      body = body.replaceAll("{{signature_image}}", `<img naturalheight="0" naturalwidth="0" src="https://dev-sf-store.s3.ap-southeast-2.amazonaws.com/email_banner.jpg" crossorigin="use-credentials" border="0" style="max-height: 200px; width: auto; cursor: pointer;">`)
    }

    // signature modification --- must be done in the end.
    if (body.includes("<p>____sign</p>") && body.includes("<p>sign____</p>")) {
      // has signature
      let start = body.indexOf("<p>____sign</p>");
      let end = body.lastIndexOf("<p>sign____</p>")

      if (start !== -1 && end !== -1) {
          let signEnd = end + 15;
          let dataStart = start + 15;
          let signData = body.substr(dataStart, (end - dataStart));
          this.setState({ signature: signData });
          body = body.substring(0, start) + '' + body.substring(signEnd);
      }
  }

  if (body.includes("<p>____no_reply</p>") && body.includes("<p>no_reply____</p>")) {
    let start = body.indexOf("<p>____no_reply</p>");
    let end = body.lastIndexOf("<p>no_reply____</p>")

    if (start !== -1 && end !== -1) {
      let signEnd = end + 15;
      let dataStart = start + 15;
      let noReplyData = body.substr(dataStart, (end - dataStart));
      this.setState({ noReply: noReplyData });
      body = body.substring(0, start) + '' + body.substring(signEnd);
    }
  }

    const html = body;
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        editorState: editorState,
      });
      return editorState;
    }
  };

  handleSearchToggle = () => {
    this.setState({ togleSearch: !this.state.togleSearch });
  };

  handleCcToggle = (event) => {
    event.preventDefault();
    this.setState({ toggleCc: !this.state.toggleCc });
  };

  handleBccToggle = (event) => {
    event.preventDefault();
    this.setState({ toggleBcc: !this.state.toggleBcc });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  onSubmit = (formData) => {
    let to = formData.to_field ? formData.to_field.map((val) => val.value) : []
    let bcc = formData.bcc_field ? formData.bcc_field.map((val) => val.value) : []
    let cc = formData.cc_field ? formData.cc_field.map((val) => val.value) : []
    let fromEmail = formData.from_field ? formData.from_field : '';

    formData.to_field = to.toString()
    formData.bcc_field = bcc.toString()
    formData.cc_field = cc.toString()

    var value = this.state.editorState === "" ? "" : draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
    let message = this.state.editorState === "" ? this.editorState() : value;

    // change <ins> tags to <u>
    if (message.includes("<ins>")) {
      message = message.replace(/<ins>/g, "<u>");
    }
    if (message.includes("</ins>")) {
      message = message.replace(/<\/ins>/g, "</u>");
    }

    message = message + `<!--[if mso]><table role="presentation" cellspacing="0" cellpadding="0" border="0" width="600"><tr><td><![endif]--><div style='width: auto;border:none;border-top:solid #000000 1.0pt; border-bottom: solid #000000 1.0pt; padding:4.0pt 0cm 4.0pt 0cm; margin: 20pt 0cm 10pt 0cm;'>`
    + this.state.noReply + `</div><!--[if mso]></td></tr></table><![endif]-->`;

    //forcefully setting required font family and font size.
    message = `<!--[if mso]><table role="presentation" cellspacing="0" cellpadding="0" border="0"><tr><td><![endif]--><div style="font-family: 'calibri', sans-serif !important; font-size: 11pt !important; width: fit-content;">` + message + `</div><!--[if mso]></td></tr></table><![endif]-->`;
    message = message.replace(/font-family:.?inherit;/g, "font-family: 'calibri', sans-serif;");

    message = message.replace(/\{\{service_name\}\}/g, Strings.SERVICE_NAME);
    message = message.replace(/\{\{service_email\}\}/g, `<a href="mailto:${Strings.SERVICE_EMAIL}">${Strings.SERVICE_EMAIL}</a>`);
    message = message.replace(/<p><\/p>/g, '')

    //strip /n from body before sending email
    message = message.replace(/(?:\r\n|\r|\n)/g, '');

    formData.message = message;
    formData = {
      ...formData,
      job_id: getStorage('JOB_ID'),
      job_number: this.props.jobDetails.job_number,
      email_type: "SWMS_REPORT",
    };

    var finalFormData = new FormData();
    Object.keys(formData).forEach((key) => {
      finalFormData.append(key, JSON.stringify(formData[key]));
    });

    finalFormData.append("file_name", this.state.pdfFileName);
    finalFormData.append("file_path", this.state.pdfLink);

    this.props.action
      .sendEmailJobReport(finalFormData)
      .then((res) => {
        notification.success({
          message: Strings.success_title,
          description: res.data.data.message,
          onClick: () => { },
          className: "ant-success",
        });
        goBackBrowser(this.props);
      })
      .catch((message) => {
        notification.error({
          message: Strings.error_title,
          description: message ? message : Strings.generic_error,
          onClick: () => { },
          className: "ant-error",
        });
      });
  };

  render() {
    const { handleSubmit, clientDetails } = this.props;
    return (
      <div className="sf-page-layout">
        <div className="dash-header">
          <h2 className="page-mn-hd">
            <Icon
              type="arrow-left"
              onClick={() =>
                goBackBrowser(this.props)
              }
            />
            {Strings.send_job_report}
          </h2>
        </div>
        <div
          className={
            this.props.togleSearch ? "col-md-9" : "col-md-9 col-md-srch"
          }
        >
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <div className="sf-card-wrap">
              <div className="sf-card mt-4">
                <div className="sf-card-head abb-1 d-flex justify-content-between align-items-center">
                  <h2 className="sf-pg-heading">{Strings.send_job_report}</h2>
                  <div className="info-btn disable-dot-menu">
                    <Dropdown className="more-info" disabled>
                      <i className="material-icons">more_vert</i>
                    </Dropdown>
                  </div>
                </div>
                <div className="sf-card-body">
                  <div className="user-p-notxt send-email d-flex justify-content-start">
                    <img alt="" src="/images/owl-img.png" />
                    <span>This SWMS PDF Link will be sent to {clientDetails && clientDetails.name ? clientDetails.name : ''} on email id {clientDetails && clientDetails.client_person_email}. If you wish to speak to the client and give them a heads up call them on their number {clientDetails && clientDetails.client_person_phone ? getFormattedPhone(clientDetails.client_person_phone) : ''}.</span>
                  </div>
                  <div className="email-form">
                    <fieldset className="form-group sf-form">
                      <Field
                        label={Strings.from_profile}
                        name="from_profile"
                        type="text"
                        id="name"
                        disabled={true}
                        component={customInput}
                      />
                    </fieldset>
                    <fieldset className="form-group sf-form bcc-userid">
                      <div className="cc-bcc-email">
                        <button
                          onClick={(event) => this.handleCcToggle(event)}
                          className="normal-bnt"
                        >
                          Cc
                        </button>
                        <button
                          onClick={(event) => this.handleBccToggle(event)}
                          className="normal-bnt"
                        >
                          Bcc
                        </button>
                      </div>
                      <Field
                        label={Strings.from_txt}
                        name="from_field"
                        type="text"
                        id="name"
                        disabled={true}
                        component={customInput}
                      />
                    </fieldset>
                    <fieldset className="form-group sf-form">
                      <Field
                        label={Strings.to_txt}
                        name="to_field"
                        id="name"
                        component={MultiEmailInput}
                      />
                    </fieldset>
                    {/* cc and bcc fields here */}
                    <div className="bcc-fields">
                      <fieldset
                        className={
                          this.state.toggleCc
                            ? "form-group sf-form"
                            : "form-group sf-form d-none"
                        }
                      >
                        <Field
                          label="Cc"
                          name="cc_field"
                          type="text"
                          id="name"
                          component={MultiEmailInput}
                        />
                      </fieldset>
                      <fieldset
                        className={
                          this.state.toggleBcc
                            ? "form-group sf-form"
                            : "form-group sf-form d-none"
                        }
                      >
                        <Field
                          label="Bcc"
                          name="bcc_field"
                          type="text"
                          id="name"
                          component={MultiEmailInput}
                        />
                      </fieldset>
                    </div>
                    <fieldset className="form-group sf-form">
                      <Field
                        label={Strings.subject_txt}
                        name="subject"
                        type="text"
                        id="name"
                        component={customInput}
                      />
                    </fieldset>
                    <div className="form-group sf-form rich-textbox">
                      <label>{Strings.message_txt}</label>
                      <div className="sf-rich-txtbox">
                        <fieldset>
                          <Field
                            name="message"
                            type="text"
                            id="name"
                            editorState={
                              this.state.editorState === ""
                                ? this.editorState()
                                : this.state.editorState
                            }
                            onEditorStateChange={this.onEditorStateChange}
                            component={EmailEditor}
                          />
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="all-btn d-flex justify-content-end mt-4 sc-doc-bnt">
              <div className="btn-hs-icon">
                <button type="submit" className="bnt bnt-active">
                  {Strings.send_btn}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let initialOrgData = {}

  let selectedJobDetails = state.JobCalendar.selectedJobDetails

  let adminDetails = JSON.parse(getStorage(ADMIN_DETAILS))
  let user_name =  adminDetails ? adminDetails.name + " " + (adminDetails.last_name ? adminDetails.last_name : '') : ''
  let org_name = adminDetails && adminDetails.organisation && adminDetails.organisation.name ? adminDetails.organisation.name : '';

  let jobDetails = state.SWMSMasters && state.SWMSMasters.jobDetails ? state.SWMSMasters.jobDetails : {};

  let clientDetails = state.SWMSMasters && state.SWMSMasters.clientDetails ? state.SWMSMasters.clientDetails : {};

  let emailTemplateContent = state.emailTemplate && state.emailTemplate.emailTemplateList && state.emailTemplate.emailTemplateList.length && state.emailTemplate.emailTemplateList[0] ? state.emailTemplate.emailTemplateList[0] : '';

  let fromEmail = adminDetails && adminDetails.email_address ? adminDetails.email_address : "";

  let noReplyFrom = `${user_name} (${org_name}) via ${Strings.SERVICE_NAME} <${emailTemplateContent.from_email ? emailTemplateContent.from_email: adminDetails.email_address}>`;

  let to = clientDetails && clientDetails.client_person_email ? clientDetails.client_person_email : '';

  let bcc = emailTemplateContent && emailTemplateContent.to_bcc !== undefined ? emailTemplateContent.to_bcc : '';

  let subject = emailTemplateContent && emailTemplateContent.subject ? emailTemplateContent.subject : '';

  if (subject.includes("{{job_number}}")) {
    subject = subject.replace("{{job_number}}", jobDetails && jobDetails.job_label ? jobDetails.job_label : '');
  }

  let siteName = selectedJobDetails && selectedJobDetails.sites && selectedJobDetails.sites.length > 0 && selectedJobDetails.sites[0].site_name ? selectedJobDetails.sites[0].site_name : ''
  subject = subject.replace(/\{\{site_name\}\}/g, siteName)
  subject = subject.replace(/\{\{org_name\}\}/g, org_name)

  initialOrgData = emailTemplateContent ? {
    from_profile: 'FAMASO',
    from_field: noReplyFrom,
    to_field: [{ label: to, value: to }],
    cc_field: [{ label: fromEmail, value: fromEmail }],
    bcc_field: [{ label: bcc, value: bcc }],
    subject: subject,
  } : {};

  return {
    initialValues: initialOrgData ? initialOrgData : {},
    selectedScopeDoc: state.scopeDocs && state.scopeDocs.scopeDocsDetails ? state.scopeDocs.scopeDocsDetails : {},
    orgDetails: state.SWMSMasters.orgDetails,
    clientDetails: clientDetails,
    jobDetails: jobDetails,
    job_swms_id: state.SWMSMasters.job_swms_id,
    job: state.sAJobMgmt.jobDetails,
    jobReports: state.sAJobMgmt.jobReports,
    filePath: state.sAJobMgmt.filePath,
    selectedMailContent: emailTemplateContent ? emailTemplateContent : {},
    selectedJobDetails: selectedJobDetails
  };
};

const mapDispatchToprops = (dispatch) => {
  return {
    action: bindActionCreators(actions, dispatch),
    emailAction: bindActionCreators(actionEmail, dispatch),
    SWMSMastersActions: bindActionCreators(SWMSMastersActions, dispatch),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToprops),
  reduxForm({
    form: "EmailSwmsReport",
    validate: JobDocsEmailvalidate,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, sub, props) => {
      handleFocus(errors, "#");
    },
  })
)(EmailSwmsReport);
