import validator from 'validator';
import { removeCurrencyFormat } from '../../../../utils/common';
export const validateOnSiteSave = (values) => {
  const errors = {};
  if (!values.site_meeting_point || (values.site_meeting_point && validator.isEmpty(values.site_meeting_point, { ignore_whitespace: true }))) {
    errors.site_meeting_point = 'Required'
  }
  if (!values.site_emergency_muster_point || (values.site_emergency_muster_point && validator.isEmpty(values.site_emergency_muster_point, { ignore_whitespace: true }))) {
    errors.site_emergency_muster_point = 'Required'
  }
  if (values.job_on_site_contacts) {
    const onSiteContactErrors = [];
    values.job_on_site_contacts.forEach((contact, contactIndex) => {
      if (!(!contact.contact_name && !contact.phone)) {
        const contactErrors = {};
        if (!contact || !contact.contact_name || (contact.contact_name && validator.isEmpty(contact.contact_name, { ignore_whitespace: true }))) {
          contactErrors.contact_name = "Required"
          onSiteContactErrors[contactIndex] = contactErrors
        }
        if (!contact || !contact.phone || (contact.contact_name && validator.isEmpty(contact.phone, { ignore_whitespace: true }))) {
          contactErrors.phone = "Required"
          onSiteContactErrors[contactIndex] = contactErrors
        } else if (!validator.isMobilePhone(contact.phone)) {
          contactErrors.phone = "Must be a valid Phone Number"
          onSiteContactErrors[contactIndex] = contactErrors
        }
      }
    })
    if (onSiteContactErrors.length) {
      errors.job_on_site_contacts = onSiteContactErrors
    }
  }
  return errors;
}

export const validateNewTaskSave = (values) => {
  const errors = {};
  if (!values.task_name || (values.task_name && validator.isEmpty(values.task_name, { ignore_whitespace: true }))) {
    errors.task_name = 'Required'
  }
  if (!values.area_name || (values.area_name && validator.isEmpty(values.area_name, { ignore_whitespace: true }))) {
    errors.area_name = 'Required'
  }
  if (!values.task_total || (values.task_total && validator.isEmpty(values.task_total, { ignore_whitespace: true }))) {
    errors.task_total = 'Required'
  } else if (!validator.isFloat(values.task_total, { min: "0.01" })) {
    errors.task_total = 'Must be a valid Number'
  }
  return errors;
}

export const validateTaskAdjustmentSave = (values, props) => {
  const errors = {};
  if (!values.adjustment_name || (values.adjustment_name && validator.isEmpty(values.adjustment_name, { ignore_whitespace: true }))) {
    errors.adjustment_name = 'Required'
  }
  if (!values.adjustment_cost) {
    errors.adjustment_cost = 'Required'
  } else if (!validator.isFloat(values.adjustment_cost.toString())) {
    errors.adjustment_cost = 'Must be a valid Number'
  } else if (props.taskDetails && props.taskDetails.task_cost && !validator.isFloat(values.adjustment_cost.toString(), { min: -(parseInt(props.taskDetails.task_cost) - 1) })) {
    errors.adjustment_cost = "Adjustment must be less than task cost.";
  }
  return errors;
}

export const validateAddTaskFile = (values) => {
  const errors = {};
  if (!values.task_label) {
    errors.task_label = 'Required'
  }
  if (!values.docs_description || (values.docs_description && validator.isEmpty(values.docs_description, { ignore_whitespace: true }))) {
    errors.docs_description = 'Required'
  }
  return errors;
}

export const validatePurchaseOrder = (values) => {
  const errors = {}
  //check value and format
  if (values.PO_value) {
    if (!(/^\$(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/.test(values.PO_value))) {
      errors.PO_value = "Must be a valid value ( eg: $26,500.00 )"
    } else {
      let value = values.PO_value.replaceAll(/\$?,?/g, '');
      if (!isNaN(parseFloat(value)) && parseFloat(value) < 0.01) {
        errors.PO_value = "Must be a valid value"
      }
    }
  }
  return errors;
}


function checkArrOccurences(arr = [], val) {
  let counts = 0;
  arr.forEach(item => {
    if (item.toString() === val.toString()) {
      counts++;
    }
  })
  return counts;
}

export const validateJobBoardContractor = (values, props) => {
  const errors = {}
  let orgIds = props.task && props.task.contractor_details && props.task.contractor_details.length ?
    props.task.contractor_details.filter(cont => !values.contractor_id || (values.contractor_id && cont.contractor_id !== values.contractor_id)).map(cont => cont.org_id) : []
  var contractorValue = 0;
  if (!values.contractor) {
    errors.contractor = "Required"
  }
  else {
    orgIds.push(values.contractor.value);
    if (checkArrOccurences(orgIds, values.contractor.value) > 1) {
      errors.contractor = "Contractor already assigned"
    }
  }
  if (!values.outsourced_budget) {
    errors.outsourced_budget = "Required";
  } else if (values.outsourced_budget && !validator.isNumeric(removeCurrencyFormat(values.outsourced_budget))) {
    errors.outsourced_budget = "Invalid Outsource Value";
  } else {
    contractorValue = parseFloat(removeCurrencyFormat(values.outsourced_budget));
    props.task && props.task.contractor_details && props.task.contractor_details.forEach(contractor => {
      if (!values.id || (values.id && contractor.id !== values.id)) {
        if (contractor.contractor_task_status && contractor.contractor_task_status.toString().toLowerCase() !== "cancelled") {
          contractorValue += parseFloat(removeCurrencyFormat(contractor.outsourced_budget))
        }
      }
    })
  }

  if (props.task && props.task.task_cost && contractorValue > props.task.task_cost) {
    errors._error = "Total Outsource value must not exceeds task Value."
  }

  return errors;
}