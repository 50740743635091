import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { notification } from 'antd';
import { Strings } from '../../dataProvider/localize'
import * as actions from '../../actions';
import { customInput } from '../common/custom-input';
import { validate } from '../../utils/validations';
import GoogleCaptcha from '../common/GoogleCaptcha';
import {
	MFA_STA,
	SET_PWD,
	JWT_SESSION_TOKEN,
	LOGIN,
	JWT_ID_TOKEN,
	JWT_ACCESS_TOKEN,
	USER_NAME,
	REMEMBER_ME
} from '../../dataProvider/constant';
import logo from "../../assets/images/fm-farm-logo.png";

import { getStorage, setStorage, authRedirect, handleFocus, DeepTrim } from '../../utils/common';
import styles from "./Auth.module.css";
import "./AuthStyles.css";

class SignIn extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			password: '',
			count: 0,
			fromChild: '',
			rememberMeChecked: false
		}
		this.passwordStrength = this.passwordStrength.bind(this);
		this.handleData = this.handleData.bind(this);
		document.title = "Login to your Organization - Service Farm";
	}

	componentDidMount() {
		authRedirect(this.props.history);
		this.props.resetReduxStore();
	}

	onSubmit = async (formData) => {
		formData = await DeepTrim(formData);
		let newFormData = {
			...formData,
			email_address: formData.user_name,
			request_from: "WEB"
		}
		this.props.signIn(newFormData)
			.then((res) => {
				this.props.cleanErrorMsg()
				if (this.props.loginStatus) {
					setStorage(REMEMBER_ME, this.state.rememberMeChecked)
				}
				if (this.props.loginStatus && this.props.authType === MFA_STA && getStorage(JWT_SESSION_TOKEN)) {
					this.props.history.push('/signin_code');
				}
				else if (this.props.loginStatus && this.props.authType === SET_PWD && getStorage(JWT_SESSION_TOKEN)) {
					this.props.history.push('/signup');
				}
				else if (this.props.loginStatus && this.props.authType === LOGIN && getStorage(JWT_ID_TOKEN) && getStorage(JWT_ACCESS_TOKEN)) {
					this.props.history.push('/dashboard');
				}
			})
			.catch((message) => {
				if (this.props.errorMessage) {
					this.setState({
						count: this.state.count + 1
					})
				} else {
					return notification.error({
						message: Strings.error_title,
						description: message ? message : Strings.generic_error, onClick: () => { },
						className: 'ant-error'
					});
				}
			});
	}

	passwordStrength(e) {
		this.setState({
			password: e.target.value
		});
	}

	handleData(data) {
		this.setState({
			fromChild: data
		});
	}

	handleRememberMe = (value) => {
		this.setState({ rememberMeChecked: value })
	}

	handleNotMeClick = () => {
		localStorage.removeItem(USER_NAME);
		this.setState({});
		this.props.change("user_name", "");
	}

	render() {
		const { handleSubmit } = this.props;
		return (
			<div className="default-v2-container">
				<div className={styles.Background}>
					<div className="container-fluid vh-100">
						<div className="row h-100">
							<div className="col m-auto">
								<div className={`${styles.LoginCard} p-5 text-center mx-auto`}>
									<img src={logo} alt="FM Farm" style={{ maxHeight: "70px", width: "auto" }} />
									<h2 className={styles.LoginHeading}>Login</h2>
									<form onSubmit={handleSubmit(this.onSubmit)}>
										<fieldset className="userTxtbox fldset-box outr-notme">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="24" height="24" className="svg-ico-f">
												<path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
											</svg>
											<Field
												name="user_name"
												type="text"
												id="user_name"
												autoFocus
												className={styles.InputFieldV2}
												placeholder={Strings.sign_in_email_placeholder}
												readonly={getStorage(USER_NAME) ? true : false}
												component={customInput} />
											{getStorage(USER_NAME) ? <button className="notmetxt" onClick={this.handleNotMeClick} type="button">Not me</button> : null}
										</fieldset>
										<fieldset className="passTxtbox fldset-box">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-ico-f" width="24" height="24" >
												<path d="M336 352c97.2 0 176-78.8 176-176S433.2 0 336 0S160 78.8 160 176c0 18.7 2.9 36.8 8.3 53.7L7 391c-4.5 4.5-7 10.6-7 17v80c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24V448h40c13.3 0 24-10.7 24-24V384h40c6.4 0 12.5-2.5 17-7l33.3-33.3c16.9 5.4 35 8.3 53.7 8.3zM376 96a40 40 0 1 1 0 80 40 40 0 1 1 0-80z" /></svg>
											<Field
												name="password"
												type="password"
												id="password"
												className={styles.InputFieldV2}
												placeholder={Strings.sign_in_password_label}
												onChange={this.passwordStrength}
												component={customInput} />
										</fieldset>
										{this.props.errorMessage ?
											<div className="alert alert-danger">
												{this.props.errorMessage}
											</div> : null}
										{this.state.count > 2 ?
											<div className="sf-g-captcah">
												<GoogleCaptcha handlerFromParant={this.handleData} />
											</div> :
											null}
										{this.state.count > 2 && this.state.fromChild !== "Verified" ? <button type="submit" className={styles.SigninBtn} disabled>{Strings.sign_in_btn_sign_in}</button> : <button type="submit" className={styles.SigninBtn}>{Strings.sign_in_btn_sign_in}</button>}
										{this.state.count > 2 ?
											<div className="forgt-pass">{Strings.sign_in_forgot_password} <Link onClick={() => this.props.cleanErrorMsg()}
												key='forgotpass' to='/forgotpass' className={styles.ForgotLink}>{Strings.sign_in_password}</Link> | <Link
													onClick={() => this.props.cleanErrorMsg()}
													key='forgotusername' className={styles.ForgotLink} to='/Forgotusername'>{Strings.sign_in_forgot_username}</Link>
											</div> : null}
										<div className="rember-me">
											<input className="auth-v2-checkbox" type="checkbox" value="value1" checked={this.state.rememberMeChecked} onChange={(e) => this.handleRememberMe(e.target.checked)} />
											<label className={styles.RememberMe} htmlFor="styled-checkbox-1" style={{ cursor: "pointer" }} onClick={() => this.handleRememberMe(!this.state.rememberMeChecked)}>{Strings.sign_in_remember_me}</label>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	if (getStorage(USER_NAME)) {
		var initialValues = { user_name: getStorage(USER_NAME) };
	}
	return {
		errorMessage: state.auth.errorMessage,
		authType: state.auth.authType,
		loginStatus: state.auth.loginStatus,
		initialValues: initialValues ? initialValues : {}
	}
}

export const SignInComponent = connect(mapStateToProps, actions)(SignIn);
export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'signin', validate,
		onSubmitFail: (errors) => {
			handleFocus(errors, "#");
		}
	})
)(SignIn)