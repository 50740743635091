import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./SWMSActivityContainer.module.css";
import { Field } from "redux-form";
import { bindActionCreators } from "redux";
import { CustomSelect } from "../../../common/customSelect";
import emailEditor from "../../../common/EmailEditor";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import {
  getRiskRatingFormattedOptions,
  numerateOptions,
  getFormattedReactSelectOptions,
} from "../utils";
import * as SWMSMastersActions from "../../../../actions/SWMSMastersActions";
import { Popover } from "antd";
import { CustomSelect as Select } from "../../../common/new-core-ui/CustomSelect";

export class Controls extends Component {
  state = {
    consequences: [],
    selectedHazards: [],
    editorStateValue: null,
    editorStateText: "",
  };

  editorState = (value) => {
    var body = value ? value : "";

    const html = body;
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
  };

  changeEditorState = (editorState) => {
    this.props.change("controls_temp", editorState);
    this.props.change(
      "controls",
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  handleChangeHazards = (val, newValues, oldValues) => {
    const { actions, change, formValues } = this.props;

    if (newValues.length > oldValues.length) {
      const hazardId = parseInt(val[val.length - 1]);

      actions
        .getSingleSWMS(7, parseInt(hazardId))
        .then((res) => {
          const consequencesList = res.consequences;
          const controlsList = res.controls;

          const consequenceIds = consequencesList.map(
            (item) => item.consequence_id
          );
          const existingConsequenceIds = formValues.consequences.map((item) =>
            parseInt(item)
          );

          const newConsequences = [];
          consequencesList.forEach((item) => {
            if (
              consequenceIds.indexOf(item.consequence_id) !== -1 &&
              existingConsequenceIds.indexOf(item.consequence_id) === -1
            ) {
              newConsequences.push(item.consequence_id.toString());
            }
          });

          const updatedConsequences = [
            ...formValues.consequences,
            ...newConsequences,
          ];

          change("consequences", updatedConsequences);

          const hazardControlMeasures = [];
          controlsList.forEach((item) => {
            if (item.swms_control_description) {
              hazardControlMeasures.push(item.swms_control_description);
            }
          });

          const prevEditorStateValue = formValues.controls
            ? formValues.controls
            : "";
          const newEditorStateValue = hazardControlMeasures.length
            ? prevEditorStateValue + " - " + hazardControlMeasures.join(" - ")
            : prevEditorStateValue;

          const newEditorState = this.editorState(newEditorStateValue);
          this.changeEditorState(newEditorState);
        })
        .catch((err) => {});
    } else {
    }
  };

  displayRiskBefore = () => {
    const { formValues, calculateRiskValue } = this.props;

    const likelihoodValue = formValues && formValues.likelihood_before_controls;
    const consequenceValue =
      formValues && formValues.consequence_before_controls;

    return calculateRiskValue(likelihoodValue, consequenceValue, "riskBefore");
  };

  displayRiskAfter = () => {
    const { formValues, calculateRiskValue } = this.props;

    const likelihoodValue = formValues && formValues.likelihood_after_controls;
    const consequenceValue =
      formValues && formValues.consequence_after_controls;

    return calculateRiskValue(likelihoodValue, consequenceValue, "riskAfter");
  };

  render() {
    const {
      hazards,
      consequences,
      formValues,
      likelihoodBefore,
      consequencesBefore,
      initialValues,
    } = this.props;

    return (
      <div className="controls-container">
        <div className="sf-card">
          <div className="sf-card-body" style={{ marginBottom: "10px" }}>
            <div
              style={{
                float: "right",
              }}
            >
              <div className="info-btn disable-dot-menu">
                <Popover
                  className="swms-popup-dtl"
                  content={
                    <img
                      className="risk-asses-img"
                      src="/images/risk_assesment.png"
                      alt="risk-assesment"
                    />
                  }
                  placement="bottomRight"
                  overlayClassName="swms-act-popup"
                  title="Risk Assesment"
                >
                  <strong className="more-info">
                    <i class="material-icons swms-info-size">info</i>
                  </strong>
                </Popover>
              </div>
            </div>
            <div className={`${styles.ControlsContainer} controls-fields`}>
              <div className={styles.MultiselectBox}>
                <fieldset className="form-group sf-form adal-task-tags extended-width-multiselect">
                  <Field
                    label="Hazards"
                    name="hazards"
                    placeholder="Hazards"
                    options={
                      hazards && hazards.length
                        ? getFormattedReactSelectOptions(hazards)
                        : null
                    }
                    component={Select}
                    onChange={this.handleChangeHazards}
                    clearable={false}
                  />
                </fieldset>
              </div>
              <div className={styles.MultiselectBox}>
                <fieldset className="form-group sf-form adal-task-tags extended-width-multiselect">
                  <Field
                    label="Consequences"
                    name="consequences"
                    placeholder="Consequences"
                    options={
                      consequences && consequences.length
                        ? getFormattedReactSelectOptions(consequences)
                        : null
                    }
                    component={Select}
                    clearable={false}
                  />
                </fieldset>
              </div>
              <div className={styles.Dropdowns}>
                <p className={styles.DropdownHeader}>Before Controls</p>
                <fieldset className="form-group sf-form">
                  <Field
                    placeholder="Likelihood"
                    name="likelihood_before_controls"
                    label="Likelihood"
                    options={numerateOptions(
                      getRiskRatingFormattedOptions(likelihoodBefore, "name"),
                      true,
                      "L"
                    )}
                    component={CustomSelect}
                  />
                </fieldset>
                <fieldset className="form-group sf-form">
                  <Field
                    placeholder="Consequence"
                    name="consequence_before_controls"
                    label="Consequence"
                    options={numerateOptions(
                      getRiskRatingFormattedOptions(consequencesBefore, "name"),
                      true,
                      "C"
                    )}
                    component={CustomSelect}
                  />
                </fieldset>
                <fieldset className="form-group sf-form">
                  <div className={styles.RiskLabel}>
                    <label>Risk Rating</label>
                  </div>
                  <span
                    style={{
                      display: "block",
                      fontSize: "14px",
                      marginLeft: "11px",
                    }}
                  >
                    {this.displayRiskBefore()}
                  </span>
                </fieldset>
              </div>
              <div className={styles.TextEditor}>
                <label>Controls</label>
                <Field
                  name="controls"
                  type="text"
                  component={emailEditor}
                  editorState={
                    formValues && formValues.controls_temp
                      ? formValues.controls_temp
                      : this.editorState(initialValues.controls)
                  }
                  onEditorStateChange={(editorState) =>
                    this.changeEditorState(editorState)
                  }
                />
              </div>
              <div className={styles.Dropdowns}>
                <p className={styles.DropdownHeader}>After Controls</p>
                <fieldset className="form-group sf-form">
                  <Field
                    placeholder="Likelihood"
                    name="likelihood_after_controls"
                    label="Likelihood"
                    options={numerateOptions(
                      getRiskRatingFormattedOptions(likelihoodBefore, "name"),
                      true,
                      "L"
                    )}
                    component={CustomSelect}
                  />
                </fieldset>
                <fieldset className="form-group sf-form">
                  <Field
                    placeholder="Consequence"
                    name="consequence_after_controls"
                    label="Consequence"
                    options={numerateOptions(
                      getRiskRatingFormattedOptions(consequencesBefore, "name"),
                      true,
                      "C"
                    )}
                    component={CustomSelect}
                  />
                </fieldset>
                <fieldset className="form-group sf-form">
                  <div className={styles.RiskLabel}>
                    <label>Risk Rating</label>
                  </div>
                  <span
                    style={{
                      display: "block",
                      fontSize: "14px",
                      marginLeft: "11px",
                    }}
                  >
                    {this.displayRiskAfter()}
                  </span>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  hazards: state.SWMSMasters.hazards,
  consequences: state.SWMSMasters.consequences,
  formValues:
    state.form && state.form.SWMSActivity ? state.form.SWMSActivity.values : {},
  singleSWMSItems: state.SWMSMasters.singleSWMSItems,
  likelihoodBefore: state.SWMSMasters.likelihood_before,
  consequencesBefore: state.SWMSMasters.consequences_before,
  initialValues: state.SWMSMasters.initialValues,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(SWMSMastersActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
