import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AllocationActionsForm from "./AllocationActionsForm/AllocationActionsForm";
import StaffListContainer from "./StaffListContainer/StaffListContainer";
import * as profileManagementActions from "../../../../../actions/profileManagementActions";
import jobStyles from "../../../Job/JobStyle.module.css";

export class AllocationActionContainer extends Component {
  componentDidMount() {
    this.props.profileActions.getStates().then().catch();
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9">
              <AllocationActionsForm />
            </div>
            <div className="col-md-3 pl-0">
              {this.props.currentAction !== "COPY" && (
                <div>
                  <div className={jobStyles.SectionHeading}>Staff List</div>
                  <div className={jobStyles.card}>
                    <StaffListContainer />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  staffRows: state.Allocations.staffRows,
  currentAction: state.Allocations.currentAction,
});

const mapDispatchToProps = (dispatch) => {
  return {
    profileActions: bindActionCreators(profileManagementActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllocationActionContainer);
