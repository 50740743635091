import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { Modal, notification, Popconfirm } from "antd";
import Select, { components } from "react-select";
import { useDispatch } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";

import { getFrequencyInvoiceData, updateFrequencyStatus } from "../../../../actions/v2/JobActions";
import { Strings } from "../../../../dataProvider/localize";
import { capitalizeFirst } from "../../../../utils/common";
import ApproveTask from "../JobBoard/Tasks/ApproveTaskValue/ApproveTask";
import UploadInvoice from "../JobBoard/Tasks/UploadInvoice";
import InvoiceTable from "../JobBoard/Tasks/InvoiceTable";
import IssuePO from "../JobBoard/Tasks/IssuePO/IssuePO";
import ExtendFrequencyModal from "./ExtendFrequencyModal";
import Task from "../JobBoard/Tasks/Task";

import { JobBoardStatusSelectStyle } from "../../Job/SelectV2SingleStyle";
import styles from "../JobStyle.module.css";
import FrequencyShiftsModal from "./FrequencyShiftsModal";


const Frequency = ({ frequency, jobId, selectedTask, selectedJobDetails, refreshHistory, fetchFrequencies, getNextFrequency, setSelectedTask }) => {

  const dispatch = useDispatch();

  const [frequencyState, setFrequencyState] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [loadingInvoices, setLoadingInvoices] = useState(false);
  const [extendFreq, setExtendFreq] = useState(false);
  const [shiftsModal, setShiftsModal] = useState(false);

  const controller = useRef();

  const frequencyStates = ["Booked", "In Progress", "Completed"];

  const isDisabledState = useCallback((state) => {
    let availableStates = [];
    let currentState = frequency && frequency.freq_status ? frequency.freq_status : "";

    switch (currentState) {
      case "Booked":
        availableStates.push("In Progress", "Completed");
        break;
      case "In Progress":
        availableStates.push("Completed")
        break;
      case "Completed":
        availableStates.push("In Progress");
        break;
      // case "Signed Off":
      //   availableStates.push("In Progress", "Completed");
      //   break;
      case "":
        availableStates = [];
        break;
      default: availableStates.push("Booked");
    }
    return state !== currentState && !availableStates.includes(state)
  }, [frequency]);

  const getInvoicesData = useCallback(() => {
    setLoadingInvoices(true);
    controller.current = new AbortController();
    dispatch(getFrequencyInvoiceData({ signal: controller.current.signal }, { quote_task_id: frequency.quote_task_id, job_id: frequency.job_id })).then(res => {
      if (res && res.length) {
        setInvoices(res);
      }
      setLoadingInvoices(false);
    }).catch(err => {
      if (err) {
        setLoadingInvoices(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: "ant-error"
        })
      }
    })
  }, [frequency.quote_task_id, frequency.job_id, setInvoices, setLoadingInvoices])

  // const refreshJobBoard = useCallback(() => {
  //   getInvoicesData();
  //   refreshHistory();
  //   // if (jobId) {
  //   //   // props.onReload();
  //   //   Promise.all([
  //   //     dispatch(getJobHeader(jobId)),
  //   //     dispatch(getJobTasks(jobId)),
  //   //     dispatch(getJobSiteTasks(jobId)),
  //   //     dispatch(getJobOnSiteInfo(jobId))
  //   //   ])
  //   // }
  // }, [getInvoicesData, refreshHistory])

  const onFrequencyStatusChange = useCallback((status) => {
    setLoading(true);
    let formData = {
      status: status,
      quote_task_label: frequency.quote_task_label,
      task_id: selectedTask.task_id,
      job_id: jobId,
      quote_task_id: frequency.quote_task_id,
      ...(frequency.task_invoice_id ? { task_invoice_id: frequency.task_invoice_id } : {}),
      ...(frequency.contractor_task_invoice_id ? { contractor_task_invoice_id: frequency.contractor_task_invoice_id } : {}),
      ...(frequency.assignee ? { assignee_id: frequency.assignee } : {})
    }
    dispatch(updateFrequencyStatus(formData)).then(message => {
      notification.success({
        message: Strings.success_title,
        description: message,
        className: "ant-success"
      });
      setVisible(false);
      fetchFrequencies();
      refreshHistory();
    }).catch(err => {
      setVisible(false);
      cancelFrequencyStateChange();
      notification.error({
        message: Strings.error_title,
        description: err ? err : Strings.generic_error,
        className: "ant-error"
      })
    }).finally(() => {
      setLoading(false);
    });
  }, [frequency, setLoading, dispatch, updateFrequencyStatus, setVisible, fetchFrequencies, refreshHistory, cancelFrequencyStateChange])

  const onFrequencyStateChange = useCallback((e) => {
    setFrequencyState(e.value);
    setVisible(true);
  }, [setFrequencyState, setVisible])

  const cancelFrequencyStateChange = useCallback(() => {
    setVisible(false)
    if (frequency && frequency.freq_status) {
      setFrequencyState(frequency.freq_status);
    } else {
      setFrequencyState("Scheduled");
    }
  }, [setFrequencyState, frequency])

  useEffect(() => {
    getInvoicesData();
  }, [frequency])

  useEffect(() => {
    if (frequency && frequency.freq_status) {
      setFrequencyState(frequency.freq_status);
    } else {
      setFrequencyState("Booked");
    }
    return () => {
      if (controller.current) {
        controller.current.abort();
      }
    }
  }, [frequency])

  const onExtendClose = () => {
    setExtendFreq(false);
  }


  return (
    <div>
      <div className="container-fluid px-0">
        <hr className={`${styles.DetailsDivider} mt-0`}></hr>
        <div className="row align-items-top">
          {/* <div className="col-3">
          <div className={styles.PrimaryText}>
            <div className="font-weight-bold">
              {frequency.quote_task_label} <br />
              {frequency.task_name}
            </div>
          </div>
        </div>
        <div className="col-2">
          <div className={styles.Label}>Value</div>
          <div>
            {frequency && currencyFormat(frequency.task_amount) ? currencyFormat(frequency.task_amount) : " - "}
          </div>
        </div> */}
          <div className="col-2">
            <div className={styles.Label}>Start Date</div>
            <div>{frequency && frequency.start_date ? moment(frequency.start_date).format("DD/MM/YYYY") : " - "}
            </div>
          </div>
          <div className="col-3 d-flex align-items-end" style={{ gap: "10px" }} >
            <div>
              <div className={styles.Label}>End Date</div>
              {frequency && frequency.end_date ? moment(frequency.end_date).format("DD/MM/YYYY") : " - "}
            </div>
            {frequencyState && frequencyState.toString().toLowerCase() !== "cancelled" ? (<>
              <Tooltip title="Extend Frequency">
                <IconButton className="p-0 mb-1" style={{ height: "14px", width: "14px" }} onClick={() => { setExtendFreq(true); }}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={styles.SuccessIcon} >
                    <path fill="currentColor" d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
                  </svg>
                </IconButton>
              </Tooltip>
              {frequency.extend_freq_type ? (
                <Tooltip title="Create Shifts">
                  <IconButton className="p-0 mb-1" sx={{ height: "auto", color: "#c0b4ff", fontSize: "20px" }} size="small" onClick={() => setShiftsModal(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" height="16px">
                      <path fill="currentColor" d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z" />
                    </svg>
                  </IconButton>
                </Tooltip>
              ) : null}
            </>) : null}
          </div>
          <div className="col-4 text-right" style={{ paddingTop: "12px " }}>
            {loading ? (<CircularProgress size={20} />) : null}
          </div>
          <div className="col-3 text-center align-self-center" onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              title={<div>
                <div className="font-weight-bold">Are you sure you want to change Status for this Frequency?</div>
                <div>Status Changes are not reversible.</div>
              </div>}
              visible={visible}
              onCancel={() => cancelFrequencyStateChange()}
              onConfirm={() => onFrequencyStatusChange(frequencyState)}
            >
              <Select
                value={{ label: frequencyState, value: frequencyState }}
                className={`w-100 text-${frequencyState ? frequencyState.toString().replace(/\s/gm, '').toLowerCase() : ""}`}
                onChange={onFrequencyStateChange}
                isDisabled={frequencyState.toString().toLowerCase() === "cancelled"}
                options={frequencyStates.map(state => ({
                  label: state, value: state,
                  isDisabled: isDisabledState(state)
                }))}
                styles={JobBoardStatusSelectStyle}
                otherProps={{
                  components: { dropdownIndicator: <components.DownChevron /> }
                }}
              />
            </Popconfirm>
          </div>
        </div>
        <hr className={styles.DetailsDivider}></hr>
      </div>
      <Task isDisabledJob={frequencyState && frequencyState.toString().toLowerCase() === "cancelled"} frequency={frequency} taskDetails={selectedTask} jobId={jobId} fetchFrequencies={fetchFrequencies} />
      <hr className={styles.DetailsDivider}></hr>
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-12 d-flex align-items-center justify-content-between">
            <IssuePO
              jobId={jobId}
              frequency={frequency}
              siteId={selectedTask.site_id}
              siteName={selectedTask.site_name}
              task={selectedTask}
              isDisabled={frequencyState && frequencyState.toString().toLowerCase() === "cancelled"}
              jobLabel={selectedJobDetails.label}
              refreshJobBoard={() => { fetchFrequencies(); refreshHistory() }} />
            <ApproveTask
              jobId={jobId}
              task={selectedTask}
              frequency={frequency}
              refreshJobBoard={() => { fetchFrequencies(); refreshHistory(); }} />
            <UploadInvoice
              contractor={selectedTask.contractor_details[0]}
              task={selectedTask}
              frequency={frequency}
              jobId={jobId}
              refreshJobBoard={() => { fetchFrequencies(); refreshHistory(); }} />
          </div>
        </div>
        <hr className={styles.DetailsDivider}></hr>
        <div className="row">
          <div className="col-12">
            <InvoiceTable
              refreshJobBoard={() => { getInvoicesData(); refreshHistory(); }}
              task={selectedTask}
              jobId={jobId}
              frequency={frequency}
              dataLoading={loadingInvoices}
              invoices={invoices}
              isDisabled={frequencyState && frequencyState.toString().toLowerCase() === "cancelled"}
            // isDisabled={props.task && props.task.job_board_task_state && props.task.job_board_task_state.toLowerCase() === "values approved for invoice"}
            />
          </div>
        </div>
      </div>
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={extendFreq}
        onCancel={() => setExtendFreq(false)}
        afterClose={() => fetchFrequencies()}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        destroyOnClose={true}
        width="700px"
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
      >
        <ExtendFrequencyModal
          initialValues={{
            start_date: frequency.start_date,
            end_date: moment(frequency.end_date).add(1, "day"),
            freq_type: frequency.freq_type ? frequency.freq_type.toLowerCase() === "none" ? "Does not repeat" : capitalizeFirst(frequency.freq_type, true) : ""
          }}
          form={`extend_frequency_${frequency.quote_task_id}`}
          frequency={frequency}
          task={selectedTask}
          setSelectedTask={setSelectedTask}
          getNextFrequency={getNextFrequency}
          selectedJobDetails={selectedJobDetails}
          onClose={onExtendClose}
        />
      </Modal>
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={shiftsModal}
        afterClose={() => fetchFrequencies()}
        onCancel={() => setShiftsModal(false)}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        destroyOnClose={true}
        width="60vw"
        style={{ maxWidth: "900px" }}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
      >
        <FrequencyShiftsModal
          frequency={frequency}
          task={selectedTask}
          onClose={() => setShiftsModal(false)}
        />
      </Modal>
    </div>
  )
}

export default memo(Frequency);