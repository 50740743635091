import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { connect, useDispatch, useSelector } from "react-redux";
import { Input, Upload, Icon, notification, Menu, Dropdown } from "antd";
import { updatePurchaseOrder, getJobHeader } from "../../../../actions/v2/JobActions";
import { Strings } from "../../../../dataProvider/localize";
import { currencyFormat } from "../../../../utils/common";

import styles from "../JobStyle.module.css";
import { customInput } from "../../../common/custom-input";
import { validatePurchaseOrder } from "./validate";
import { downLoadDoc } from "./utils";

const PurchaseOrder = (props) => {

  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);

  const handleFileSelect = (files) => {
    setFiles(files);
  }

  const isAlreadyInvoiced = useSelector(state =>
    (state.Job.jobHeader && state.Job.jobHeader.ar_job_state && state.Job.jobHeader.ar_job_state.toLowerCase() === "not found") ? true : false
  );

  const getFileName = (address) => {
    if (address) {
      return address.substring(address.lastIndexOf("/") + 1)
    } else {
      return '';
    }
  }

  const onSubmit = (values) => {
    let formData = new FormData();
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });
    if (files.length) {
      formData.append("po_document", files[0].originFileObj)
    }
    formData.append("job_id", props.jobId);

    dispatch(updatePurchaseOrder(formData)).then((message) => {
      dispatch(getJobHeader(props.jobId))
      handleFileSelect([]);
      notification.success({
        message: Strings.success_title,
        description: message ? message : Strings.generic_error, onClick: () => { },
        className: 'ant-success'
      });
    }).catch(err => {
      notification.error({
        message: Strings.error_title,
        description: err ? err : Strings.generic_error,
        className: 'ant-error'
      })
    })
  }

  const handleClick = ({ key }) => {
    if (key === "view") {
      const newWindow = window.open(props.headerDetails.po_doc_file, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
    if (key === "download") {
      downLoadDoc(props.headerDetails.po_doc_file);
    }
  }

  const menu = (
    <Menu onClick={handleClick} className="job-board-menu-dropdown">
      <Menu.Item key="view">
        <div className="d-flex align-items-center">
          <Icon type="eye" />
          <div className="px-2">View</div>
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="download">
        <div className="d-flex align-items-center">
          <Icon type="download" />
          <div className="px-2">Download Attachment</div>
        </div>
      </Menu.Item>
      <Menu.Divider />
    </Menu>
  )

  return (
    <form onSubmit={props.handleSubmit(onSubmit)}>
      <div>
        <div>
          <div>Purchase Order No.</div>
          <Field className={styles.SingleLine} autoComplete="off" component={customInput} name="PO_number" disabled={isAlreadyInvoiced} />
        </div>
        <div>
          <div>Purchase Order Value</div>
          <div>
            <Field className={styles.SingleLine} autoComplete="off" component={customInput} name="PO_value" disabled={isAlreadyInvoiced} />
          </div>
        </div>
        <div>
          <div>Upload PO</div>
          <div>
            <div className="d-flex align-items-center">
              <Upload
                multiple={false}
                fileList={files}
                showUploadList={false}
                beforeUpload={() => { return false; }}
                accept='.jpeg,.jpg,.png,.pdf'
                onRemove={() => handleFileSelect([])}
                onChange={({ fileList }) => handleFileSelect([fileList.pop()])}
                disabled={isAlreadyInvoiced}
              >
                <div className="d-flex align-items-center">
                  <Icon rotate={135} type="paper-clip" />
                  <Input
                    readOnly
                    className={styles.SingleLine} autoComplete="off"
                    value={files.length > 0 ? files[0].name : props.headerDetails.po_doc_file ? getFileName(props.headerDetails.po_doc_file) : ''}
                    disabled={isAlreadyInvoiced}
                  />
                  {files.length > 0 && <Icon style={{ color: 'red', cursor: "pointer" }}
                    onClick={(e) => { e.stopPropagation(); handleFileSelect([]) }} type="delete" />
                  }
                </div>
              </Upload>
              {props.headerDetails && props.headerDetails.po_doc_file && files.length === 0 &&
                <Dropdown overlay={menu} style={{ zIndex: 10000}} trigger={['click']}>
                  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <Icon type="down" style={{ fontSize: "16px" }} />
                  </a>
                </Dropdown>
              }
            </div>
          </div>
          <div>
            <button type="submit" className="job-board-text-button mt-2" disabled={props.pristine && files.length === 0}>Save</button>
          </div>
        </div>
      </div>
    </form >
  )
}

const mapStateToProps = (state) => {
  let info = state.Job.jobHeader
  let values = {
    PO_number: info.PO_number ? info.PO_number : '',
    PO_value: info.PO_value ? info.PO_value : '',
  };
  return { initialValues: values }
}


export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: 'PurchaseOrder',
    validate: validatePurchaseOrder,
    enableReinitialize: true
  }))(PurchaseOrder)