import React from 'react';
import { Icon, Dropdown, Menu, notification } from 'antd';
import { reduxForm, Field, isDirty } from 'redux-form';
import { withRouter } from "react-router-dom";
import { backAccountValidation } from '../../../../utils/Validations/payrollDetailsVallidation';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { customInput } from '../../../common/custom-input';
import * as actions from '../../../../actions/profileManagementActions';
import { Strings } from '../../../../dataProvider/localize';
import { ADMIN_DETAILS, VALIDATE_STATUS, ACCESS_CONTROL } from '../../../../dataProvider/constant';
import { getStorage, handleFocus, DeepTrim } from '../../../../utils/common';
import "../ProfileStyle.css";
import styles from "../ProfileStyles.module.css";

class BankAccountForm extends React.Component {
    paymentMothod = ['eWAY']
    confirm_bank_ac_no_flag = true
    constructor(props) {
        super(props);
        this.state = {
            hasEditPermission: false,
            // checked: false,
            // superfundprovider: 'block'
        }

        this.org_user_id = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id : null;
        this.org_user_name = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)).user_name : null;
    }

    // more info
    editMenu = (
        <Menu>
            <Menu.Item onClick={this.handleEditClick}>{Strings.menu_edit_org}</Menu.Item>
        </Menu>
    )

    onSubmit = async (formData) => {
        formData = await DeepTrim(formData);

        formData = { ...formData, 'profile_progress': this.props.profileComplete }

        let staffUserName = false
        if (this.props.userDetails) {
            staffUserName = this.props.userDetails.user_name
        }

        this.props.action.updateUserBankDetails(formData, staffUserName)
            .then(flag => {
                notification.success({
                    message: Strings.success_title,
                    description: flag,
                    onClick: () => { },
                    className: 'ant-success'
                });
                this.props.action.getOrgUserProfileDetails(this.props.userId ? this.props.userId : this.org_user_id, staffUserName ? staffUserName : this.org_user_name, null, this.shouldUpdateAuthUser)
            })
            .catch((error) => {
                if (error.status === VALIDATE_STATUS) {
                    notification.warning({
                        message: Strings.validate_title,
                        description: error && error.data && typeof error.data.message == 'string'
                            ? error.data.message : Strings.generic_validate,
                        onClick: () => { },
                        className: 'ant-success'
                    });
                } else {
                    notification.error({
                        message: Strings.error_title,
                        description: error && error.data && error.data.message && typeof error.data.message == 'string'
                            ? error.data.message : Strings.generic_error,
                        onClick: () => { },
                        className: 'ant-error'
                    });
                }
            });
    }

    componentDidMount() {
        if (this.props.initialValues && this.props.initialValues.is_super_ac === 1) {
            this.setState({
                superfundprovider: 'none'
            })
        } else {
            this.setState({
                superfundprovider: 'block'
            })
        }
        if (this.hasPermission("sf_payroll_details_controller_update_payroll_details")) {
            this.setState({ hasEditPermission: true });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.initialValues.bank_ac_number !== parseInt(this.props.formValues.bank_ac_number)) {
            this.confirm_bank_ac_no_flag = false
        }
        if (this.props.initialValues.bank_ac_number === parseInt(this.props.formValues.bank_ac_number)) {
            this.confirm_bank_ac_no_flag = true
        }
    }

    handleChecked = (e) => {
        console.log(e.target.checked)
        if (!e.target.checked) {
            this.setState({
                superfundprovider: 'block'
            })
        } else {
            this.setState({
                superfundprovider: 'none'
            })
        }
    }

    hasPermission = (permission) => {
        let allowed = false;
        let userPermissions = JSON.parse(getStorage(ACCESS_CONTROL))["userManagement"]
        let accessControl = userPermissions && userPermissions.permissions ? userPermissions.permissions : [];
        let permission_id = accessControl.findIndex(acess => acess.control_name.toLowerCase() === permission.toLowerCase());
        if (permission_id !== -1 || this.props.location.pathname === "/dashboard/profile") {
            allowed = true;
        }

        return allowed;
    }

    render() {
        const { handleSubmit } = this.props;

        return (
            <form onSubmit={handleSubmit(this.onSubmit)} >
                <hr className={styles.DetailsDivider}></hr>
                <div className="container-fluid pt-3 pb-2">
                    <div className="row align-items-center">
                        <h2 className={`${styles.ProfileLabel} font-weight-bold`}>Bank Account Details</h2>
                    </div>
                    <div className="row">
                        <div className="col-3 border-right">
                            <div className={styles.ProfileLabelV2}>Bank Account Name</div>
                            <div>
                                <Field
                                    name="bank_ac_name"
                                    placeholder={Strings.bank_ac_name_payrole}
                                    type="text"
                                    id="bank_ac_name"
                                    component={customInput}
                                    className={styles.ProfileFormField}
                                    disabled={!this.state.hasEditPermission} />
                            </div>
                        </div>
                        <div className="col-3 border-right">
                            <div className={styles.ProfileLabelV2}>Bank Name</div>
                            <div>
                                <Field
                                    name="bank_name"
                                    placeholder={Strings.bank_name_payrole}
                                    type="text"
                                    id="bank_name"
                                    component={customInput}
                                    className={styles.ProfileFormField}
                                    disabled={!this.state.hasEditPermission} />
                            </div>
                        </div>
                        <div className="col-3 border-right">
                            <div className={styles.ProfileLabelV2}>Bank BSB</div>
                            <div>
                                <Field
                                    name="bank_bsb"
                                    placeholder={Strings.bank_bsb_payrole}
                                    type="text"
                                    id="bank_bsb"
                                    maxLength="6"
                                    component={customInput}
                                    className={styles.ProfileFormField}
                                    disabled={!this.state.hasEditPermission} />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className={styles.ProfileLabelV2}>Bank Account Number</div>
                            <div>
                                <Field
                                    name="bank_ac_number"
                                    placeholder={Strings.bank_ac_number_payrole}
                                    type="text"
                                    id="bank_ac_number"
                                    component={customInput}
                                    className={styles.ProfileFormField}
                                    disabled={!this.state.hasEditPermission} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        {!this.confirm_bank_ac_no_flag
                            ?
                            <div className="col-3">
                                <div className={styles.ProfileLabelV2}>Confirm Bank Account Number</div>
                                <div>
                                    <Field
                                        name="cnf_bank_ac_no"
                                        placeholder="Re-enter you bank account number"
                                        type="text"
                                        id="cnf_bank_ac_no"
                                        component={customInput}
                                        className={styles.ProfileFormField}
                                        disabled={!this.state.hasEditPermission} />
                                </div>
                            </div>
                            :
                            <div className="col-3 border-right">
                                <div className={styles.ProfileLabelV2}>Confirm Bank Account Number</div>
                                <div>
                                    <Field
                                        name="cnf_bank_ac_no1"
                                        placeholder="Re-enter you bank account number"
                                        type="text"
                                        id="cnf_bank_ac_no"
                                        component={customInput}
                                        className={styles.ProfileFormField}
                                        disabled={!this.state.hasEditPermission} />
                                </div>
                            </div>
                        }
                    </div>
                    <div className="mt-2">
                        <button type="submit" className={styles.BtnSaveTab} disabled={!this.props.isDirty}>
                            Save
                        </button>
                    </div>
                </div>
            </form >

        );
    }
}

const mapStateToProps = (state) => {
    let value = {}
    let temp = state.profileManagement && state.profileManagement.profile && state.profileManagement.profile[0];
    if (temp && temp.payroll_details) {
        value = {
            ...value, id: temp.payroll_details.id, bank_ac_name: temp.payroll_details.bank_ac_name, bank_name: temp.payroll_details.bank_name,
            bank_bsb: temp.payroll_details.bank_bsb, bank_ac_number: temp.payroll_details.bank_ac_number
        }
    }

    for (let key in value) {
        if (value[key] === "null") {
            value[key] = null;
        }
    }

    return {
        formValues: state.form && state.form.BankAccountForm &&
            state.form.BankAccountForm.values ? state.form.BankAccountForm.values : {},
        initialValues: (value ? value : ''),
        isDirty: isDirty('BankAccountForm')(state),
        profileComplete: state.profileManagement && state.profileManagement.profileComplete
    }
}

const mapDispatchToprops = dispatch => {
    return {
        action: bindActionCreators(actions, dispatch),
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToprops),
    reduxForm({
        form: 'BankAccountForm', validate: backAccountValidation, enableReinitialize: true,
        onSubmitFail: (errors, dispatch, sub, props) => {
            handleFocus(errors, "#");
        }
    })
)(BankAccountForm)