import { SWMS_TASK_URL, SWMS_CATEGORY_MANAGEMENT_URL, UPDATE_SWMS_SELECTION, SWMS_TASK_COPY_URL, SWMS_TASK_DELETE_URL, SWMS_APPROVE_ALL_ADMINS_URL, SWMS_SEND_MANAGER_APPROVAL_URL, SWMS_APPROVE_REJECT_URL, SWMS_CREATE_VERSION_URL, PREVIEW_SWMS, SWMS_GET_SITE_TASK_SWMS_URL, SWMS_SIGN_HISTORY_URL } from "../../dataProvider/constant";
import { baseApiInstance, scopeApiInstance } from "../../dataProvider/axiosHelper";
import { Strings } from "../../dataProvider/localize";

export const getSwmsTask = (abortController, job_id) => () => {
  return scopeApiInstance.get(SWMS_TASK_URL, { ...abortController, params: { job_id: job_id } }).then((res) => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const getSwmsCategories = (abortController, params) => () => {
  return scopeApiInstance.get(SWMS_CATEGORY_MANAGEMENT_URL, { ...abortController, params: { ...params } }).then((res) => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const updateSwmsSelection = (formData, isAdding = false) => () => {
  return scopeApiInstance.post(UPDATE_SWMS_SELECTION, formData).then(res => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject();
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const copySwms = (formData) => () => {
  return scopeApiInstance.post(SWMS_TASK_COPY_URL, formData).then(res => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const deleteSwms = (swmsFormId) => () => {
  return scopeApiInstance.delete(SWMS_TASK_DELETE_URL, { params: { swms_form_id: swmsFormId } }).then(res => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getSwmsAdmins = (orgId) => () => {
  return baseApiInstance.get(SWMS_APPROVE_ALL_ADMINS_URL, { params: { organisation_id: orgId } }).then(res => {
    let data = res.data.data;
    if (data && data.admins) {
      return Promise.resolve(data.admins)
    }
    else {
      return Promise.reject(Strings.generic_error)
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const sendSwmsForManagerApproval = (formData) => () => {
  return scopeApiInstance.post(SWMS_SEND_MANAGER_APPROVAL_URL, formData).then(res => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject();
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const approveRejectSwms = (formData) => () => {
  return scopeApiInstance.put(SWMS_APPROVE_REJECT_URL, formData).then(res => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject();
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const createNewSwms = (formData) => () => {
  return scopeApiInstance.post(SWMS_CREATE_VERSION_URL, formData).then(res => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject();
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getApprovedSiteSwms = (abortController, params = {}) => () => {
  return scopeApiInstance.get(SWMS_GET_SITE_TASK_SWMS_URL, { ...abortController, params: { ...params } }).then(res => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const getSignHistory = (params = {}, abortController) => () => {
  return scopeApiInstance.get(SWMS_SIGN_HISTORY_URL, { ...abortController, params: { ...params } }).then((res) => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}