import React from "react";
import { Tooltip } from "antd";
import { CustomDatepicker } from "../../common/customDatepicker";
import { CustomReactTimePicker } from "../../common/customReactTimePicker";
import { Field } from "redux-form";
import moment from "moment";

export default (editingKey, saveEdit, cancelEdit, handleEdit) => [
  {
    title: "Date",
    dataIndex: "shift_date",
    sorter: (a, b) => (a.day > b.day ? -1 : a.day < b.day ? 1 : 0),
    editable: true,
    width: 90,
    render: (cellContent) => (
      <span>{cellContent !== null ? moment(cellContent).format("YYYY-MM-DD") : ''}</span>
    )
  },
  {
    title: "Client Name",
    dataIndex: "client_name",
    sorter: (a, b) =>
      a.client_name > b.client_name
        ? -1
        : a.client_name < b.client_name
          ? 1
          : 0,
    editable: true,
    width: 150,
    render: (cellContent) => (
      <Tooltip title={cellContent}>
        <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
          {cellContent}
        </div>
      </Tooltip>
    ),
  },
  {
    title: "Site Name",
    dataIndex: "site_name",
    sorter: (a, b) =>
      a.site_name > b.site_name ? -1 : a.site_name < b.site_name ? 1 : 0,
    editable: true,
    width: 150,
    render: (cellContent) => (
      <Tooltip title={cellContent}>
        <div
          data-tip={cellContent}
          style={{ textOverflow: "ellipsis", overflow: "hidden" }}
        >
          {cellContent}
        </div>
      </Tooltip>
    ),
  },
  {
    title: "Job Name",
    dataIndex: "job_label",
    sorter: (a, b) =>
      a.job_label > b.job_label ? -1 : a.job_label < b.job_label ? 1 : 0,
    editable: true,
    width: 100,
    render: (cellContent, rowData, index) => (
      <Tooltip title={cellContent}>
        <div
          style={{ textOverflow: "ellipsis", overflow: "hidden" }}
        >{cellContent}</div>
      </Tooltip>
    ),
  },
  {
    //rank: 5
    title: "Staff Name",
    dataIndex: "staff_name",
    sorter: (a, b) =>
      a.staff_name > b.staff_name ? -1 : a.staff_name < b.staff_name ? 1 : 0,
    editable: true,
    width: 150,
    render: (cellContent) => (
      <Tooltip title={cellContent}>
        <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
          {cellContent}
        </div>
      </Tooltip>
    ),
  },
  {
    title: "Staff ID",
    dataIndex: "staff_user_name",
    sorter: (a, b) =>
      a.staff_user_name > b.staff_user_name
        ? -1
        : a.staff_user_name < b.staff_user_name
          ? 1
          : 0,
    editable: true,
    width: 100,
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    editable: true,
    render: (cellContent, rowData, index) => {
      if (editingKey === rowData.staff_id)
        return (
          <div className="timesheet-cell-editable jobinput76">
            <Field
              name={`start_date_${rowData.staff_id}`}
              size="small"
              component={CustomDatepicker}
            />
          </div>
        );
      else return cellContent;
    },
    width: 100,
  },
  {
    title: "Start Time",
    dataIndex: "start_time",
    editable: true,
    render: (cellContent, rowData, index) => {
      if (editingKey === rowData.staff_id)
        return (
          <div className="timesheet-cell-editable jobinput76">
            <Field
              name={`start_time_${rowData.staff_id}`}
              size="small"
              component={CustomReactTimePicker}
            />
          </div>
        );
      else return cellContent;
    },
    width: 100,
  },
  {
    title: "Finish Date",
    dataIndex: "finish_date",
    editable: true,
    render: (cellContent, rowData, index) => {
      if (editingKey === rowData.staff_id)
        return (
          <div className="timesheet-cell-editable jobinput76">
            <Field
              name={`finish_date_${rowData.staff_id}`}
              size="small"
              component={CustomDatepicker}
            />
          </div>
        );
      else return cellContent;
    },
    width: 100,
  },
  {
    //rank: 10
    title: "Finish Time",
    dataIndex: "finish_time",
    editable: true,
    render: (cellContent, rowData, index) => {
      if (editingKey === rowData.staff_id)
        return (
          <div className="timesheet-cell-editable jobinput76">
            <Field
              name={`finish_time_${rowData.staff_id}`}
              size="small"
              component={CustomReactTimePicker}
            />
          </div>
        );
      else return cellContent;
    },
    width: 100,
  },
  {
    title: "Total Hrs",
    dataIndex: "total_hrs",
    sorter: (a, b) => a.total_hrs - b.total_hrs,
    editable: true,
    width: 90,
  },

  {
    title: "Shift Type",
    dataIndex: "shift_type",
    editable: true,
    width: 150,
    render: (cellContent) => (
      <Tooltip title={cellContent}>
        <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
          {cellContent}
        </div>
      </Tooltip>
    ),
  },
  {
    title: "Pay Classification",
    dataIndex: "classification_name",
    width: 160,
    render: (cellContent) => (
      <Tooltip title={cellContent}>
        <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
          {cellContent}
        </div>
      </Tooltip>
    ),
  },

  {
    title: "Action",
    editable: true,
    fixed: "right",
    render: (cellContent, rowData, rowIndex) => {
      return editingKey !== rowData.staff_id ? (
        <div className="timesheet-action-buttons edit-line-item-timesheet">
          <i
            class="fa fa-pencil"
            style={{
              color:
                /* user.role && user.role_id > 0 ? */ "green" /* : 'red' */,
            }}
            aria-hidden="true"
            onClick={() => handleEdit(rowData, rowIndex)}
          ></i>
        </div>
      ) : (
        <div className="timesheet-action-buttons edit-line-item-timesheet">
          <i
            class="fa fa-save"
            style={{
              color:
                /* user.role && user.role_id > 0 ? */ "green" /* : 'red' */,
            }}
            aria-hidden="true"
            onClick={() => saveEdit(rowData, rowIndex)}
          ></i>
          <i
            class="fa fa-close"
            style={{
              color:
                /* user.role && user.role_id > 0 ? */ "red" /* : 'red' */,
            }}
            aria-hidden="true"
            onClick={() => cancelEdit(rowData, rowIndex)}
          ></i>
        </div>
      );
    },
    width: 50,
  },
  {
    title: "Base Hrs",
    dataIndex: "base_hr",
    sorter: (a, b) => a.base_hr - b.base_hr,

    editable: true,
  },
  {
    title: "Overtime Hrs",
    dataIndex: "overtime_hr",
    sorter: (a, b) => a.overtime_hr - b.overtime_hr,
    editable: true,
  },
  {
    title: "Double Time Hrs",
    dataIndex: "double_hr",
    sorter: (a, b) => a.double_hr - b.double_hr,
    editable: true,
  },
  {
    title: "Sunday Overtime",
    dataIndex: "sunday_overtime_hr",
    sorter: (a, b) => a.sunday_overtime_hr - b.sunday_overtime_hr,
    editable: true,
  },
  {
    //rank: 15
    title: "Public Holiday Overtime",
    dataIndex: "holiday_overtime_hr",
    sorter: (a, b) => a.holiday_overtime_hr - b.holiday_overtime_hr,
    editable: true,
  },
  {
    title: "Payment Type",
    dataIndex: "payment_type",
    editable: true,
  },
  {
    title: "EWP",
    dataIndex: "ewp_allowance",
    sorter: (a, b) => a.ewp_allowance - b.ewp_allowance,
    editable: true,
  },
  {
    title: "WAH",
    dataIndex: "wah_allowance",
    sorter: (a, b) => a.wah_allowance - b.wah_allowance,
    editable: true,
  },
  {
    title: "<22 RA",
    dataIndex: "ra_less_than_22",
    sorter: (a, b) => a.ra_less_than_22 - b.ra_less_than_22,
    editable: true,
  },
  {
    //rank: 20
    title: ">22 RA",
    dataIndex: "ra_greater_than_22",
    sorter: (a, b) => a.ra_greater_than_22 - b.ra_greater_than_22,
    editable: true,
  },
  {
    title: "Cold",
    dataIndex: "cold_places_allowance",
    sorter: (a, b) => a.cold_places_allowance - b.cold_places_allowance,
    editable: true,
  },
  {
    title: "Hot Place 46c-56C",
    dataIndex: "hotPlaces_allowance_between_46and54_degrees",
    sorter: (a, b) =>
      a.hotPlaces_allowance_between_46and54_degrees -
      b.hotPlaces_allowance_between_46and54_degrees,
    editable: true,
  },
  {
    title: "Hot Place +54C",
    dataIndex: "hot_places_allowance_exceeding_54degrees",
    sorter: (a, b) =>
      a.hot_places_allowance_exceeding_54degrees -
      b.hot_places_allowance_exceeding_54degrees,
    editable: true,
  },
  {
    title: "Broken Shift",
    dataIndex: "broken_shift_allowance",
    sorter: (a, b) => a.broken_shift_allowance - b.broken_shift_allowance,
    editable: true,
  },
  {
    //rank: 25
    title: "First Aid",
    dataIndex: "first_aid_allowance",
    sorter: (a, b) => a.first_aid_allowance - b.first_aid_allowance,
    editable: true,
  },
  {
    title: "Leading/hand (1-10)",
    dataIndex: "leading_hand_allowance_1to10_employees",
    sorter: (a, b) =>
      a.leading_hand_allowance_1to10_employees -
      b.leading_hand_allowance_1to10_employees,
    editable: true,
  },
  {
    title: "Leading/hand (11-20)",
    dataIndex: "leading_hand_allowance_11to20_employees",
    sorter: (a, b) =>
      a.leading_hand_allowance_11to20_employees -
      b.leading_hand_allowance_11to20_employees,
    editable: true,
  },
  {
    title: "Leading/hand (21-30)",
    dataIndex: "leading_hand_allowance_over_20_employees",
    sorter: (a, b) =>
      a.leading_hand_allowance_over_20_employees -
      b.leading_hand_allowance_over_20_employees,
    editable: true,
  },
  {
    title: "Refuse Collect",
    dataIndex: "refuse_collection_allowance",
    sorter: (a, b) =>
      a.refuse_collection_allowance - b.refuse_collection_allowance,
    editable: true,
  },
  {
    //rank: 30
    title: "Toilet Cleaning",
    dataIndex: "toilet_cleaning_allowance",
    sorter: (a, b) =>
      a.toilet_cleaning_allowance - b.toilet_cleaning_allowance,
    editable: true,
  },
  {
    title: "Meal Allowance",
    dataIndex: "meal_allowance",
    sorter: (a, b) => a.meal_allowance - b.meal_allowance,
    editable: true,
  },
  {
    title: "Vehicle",
    dataIndex: "vehicle_allowance",
    sorter: (a, b) => a.vehicle_allowance - b.vehicle_allowance,
    editable: true,
  },
  {
    title: "Motor Bike",
    dataIndex: "motor_cycle_allowance",
    sorter: (a, b) => a.motor_cycle_allowance - b.motor_cycle_allowance,
    editable: true,
  },
  {
    title: "Total",
    dataIndex: "total",
    sorter: (a, b) => a.total - b.total,
    editable: true,
  },
  {
    //rank: 35
    title: "Super",
    dataIndex: "super",
    editable: true,
  },
  {
    title: "Approved By",
    dataIndex: "approver_name",
    sorter: (a, b) =>
      a.approver_name > b.approver_name
        ? -1
        : a.approver_name < b.approver_name
          ? 1
          : 0,
    editable: true,
  },
  {
    title: "Approved?",
    dataIndex: "payroll_approved",
    sorter: (a, b) =>
      a.payroll_approved > b.payroll_approved
        ? -1
        : a.payroll_approved < b.payroll_approved
          ? 1
          : 0,
    editable: true,
  },
  {
    //rank: 38
    title: "Payroll Signature",
    dataIndex: "payroll_signature",
    sorter: (a, b) =>
      a.payroll_signature > b.payroll_signature
        ? -1
        : a.payroll_signature < b.payroll_signature
          ? 1
          : 0,
    editable: true,
  },
];