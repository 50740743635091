import React, { useState } from "react";
import styles from "../../JobStyle.module.css";
import { Field, FieldArray } from "redux-form";
import { customInput } from "../../../../common/custom-input";
import SingleUploadField from "../../../../common/new-core-ui/SingleUploadField";
import { IconButton } from "@mui/material";
import PreviewDocument from "./PreviewDocument";

const PermitField = ({ fields, loading, previewFile }) => (
  <div className="">
    {fields.map((member, index) => (
      <div key={index}>
        <div className="container-fluid mb-2 px-0">
          <div className="row">
            <div className="col-4">
              <div className={styles.Label}>Permit Name</div>
              <Field
                disabled={loading}
                component={customInput}
                name={`${member}.permit_name`}
                className={styles.TextField}
              />
            </div>
            <div className="col-4">
              <div className={styles.Label}>Permit Document</div>
              <Field
                previewFile={previewFile}
                name={`${member}.permit_document`}
                disabled={loading}
                component={SingleUploadField}
                s3UploadPath="job-swms/permits-section/permit_documents"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4 d-flex align-items-center">
              <IconButton disabled={loading} className="p-0" style={{ height: "auto" }} onClick={() => fields.remove(index)}>
                <svg xmlns="http://www.w3.org/2000/svg" className={styles.MinusIcon} viewBox="0 0 448 512">
                  <path fill="currentColor" d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                </svg>
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    ))}
    <button type="button" className={`${styles.PrimaryButton} my-2`} disabled={loading} onClick={() => fields.push({})}>
      Add Permit
    </button>
  </div>
)

export default function PermitsSection({ loading }) {

  const [previewFile, setPreviewFile] = useState(null);

  const toggleFilePreview = (file = null) => {
    setPreviewFile(file);
  }

  return (
    <div className="m-1 mb-2">
      <div className="contianer-fluid px-0">
        <div className="row">
          <div className="col-12">
            <FieldArray name="permits" previewFile={toggleFilePreview} loading={loading} component={PermitField} />
          </div>
        </div>
      </div>
      {previewFile &&
        <PreviewDocument file={previewFile} onCancel={toggleFilePreview} />
      }
    </div>
  )
}