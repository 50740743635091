import { Select, Icon } from 'antd';
import React, { useEffect, useState } from 'react';
import $ from 'jquery';

const Option = Select.Option;

export const CustomSelect = ({ className, input, label, defaultValue, dropdownClassName, options, placeholder, size, mode, showSearch, notFoundContent, onSearch, closable, disabled, filterOption, meta: { touched, error, warning } }) => {
  var id = "CustomSelect-" + Math.random().toString(36).substr(7)

  var [open, setOpen] = useState(false);

  //Force control Dropdown of the select as dropdown does not close in tags mode. 
  const handleOpen = () => {
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    $(`#${id} .ant-select-selection`).attr('id', `${input.name}-focus`)
  })

  return (
    <div id="stickDrop" className={"sf-select "} tabIndex={-1}
      onFocus={() => { handleOpen(); }}
      onBlur={() => { handleClose(); }}
    >
      {label && <label>{label}</label>}
      <Select
        disabled={disabled}
        className={className}
        id={id}
        defaultValue={defaultValue}
        open={open}
        placeholder={placeholder}
        suffixIcon={(<Icon type="caret-down" />)}
        value={/* options.findIndex(item => item.value === input.value) !== -1 ? input.value : "" */
          input.value !== null || input.value !== undefined ? input.value : undefined}
        mode={mode && mode}
        showSearch={showSearch ? true : false}
        notFoundContent={notFoundContent && notFoundContent}
        onSearch={onSearch && onSearch}
        filterOption={filterOption && filterOption}
        onChange={input.onChange}
        size={size ? size : 'middle'}
        dropdownClassName={dropdownClassName}
        onSelect={() => handleClose()}
      // getPopupContainer={() => document.getElementById(id)}
      >
        {options && options.length ? options.map((item) =>
          typeof item !== 'undefined' ? (item.disabled ?
            <Option value={item.value} disabled={item.disabled}>{item.title}</Option>
            : <Option value={item.value} >{item.title}</Option>) : null) : null}
      </Select>
      {touched && ((error && <span className="error-input">{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
  );
}