import moment from "moment";
import React from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import CustomWeek from "./CustomViews/CustomWeek";
import CustomDay from "./CustomViews/CustomDay";
import { getJobColorByMap } from "../../../JobCalendar/utils";

const DragAndDropCalendar = withDragAndDrop(Calendar);
const localizer = momentLocalizer(moment);

const CalendarContainer = ({ view, events, jumpToDate, onChangeView, tooltipAccessor = "title" }) => {
  const colorSetter = ({ status, invoiced, signedOff }) => {
    return {
      style: {
        background: getJobColorByMap[status],
        ...(status === 4
          ? {
              backgroundSize: "7.00px 7.00px",
              color: "black",
              fontWeight: 600,
            }
          : {}),
      },
    };
  };

  onChangeView = () => {};

  return (
    <div className="job-board-calendar-container">
      <div className="sf-card">
        <div className="job-calendar-view">
          <DragAndDropCalendar
            localizer={localizer}
            events={events}
            eventPropGetter={colorSetter}
            defaultView={Views.WEEK}
            view={view}
            tooltipAccessor={tooltipAccessor}
            defaultDate={jumpToDate ? new Date(jumpToDate) : new Date()}
            onView={(view) => onChangeView(view)}
            onEventResize={() => {}}
            views={{
              day: CustomDay,
              week: CustomWeek,
              month: true,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarContainer;
