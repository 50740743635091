import React, { useState } from "react";
import styles from "../../JobStyle.module.css";
import { Modal } from "antd";
import CloseIcon from '@mui/icons-material/Close';
import AddUpdateInvoiceModal from "./AddUpdateInvoiceModal";
import { useSelector } from "react-redux";
import PreviewDocument from "../../JobSwms/JobSwmsForm/PreviewDocument";

export default function UploadInvoice({ contractor, frequency, task, jobId, refreshJobBoard }) {

  const [uploadModal, setUploadModal] = useState(false);
  const [selectedField, setSelectedField] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);

  const jobDetails = useSelector(state => state.Job.jobHeader);

  const openFilePreview = (file = null) => {
    setPreviewFile(file);
  }

  const uploadInvoice = () => {
    // console.log(contractor);
    setSelectedField({
      contractor: contractor.organisation_type && contractor.organisation_type === 2 ? { label: contractor.contractor_name, value: contractor.org_id } : null,
      task_label: task.task_label,
      task_id: task.task_id,
      quote_task_id: frequency.quote_task_id
    });
    setUploadModal(true);
  }

  const afterClose = () => {
    refreshJobBoard();
    setSelectedField(null);
    setUploadModal(false);
  }

  return (
    <div>
      <button
        className={styles.PrimaryButton}
        onClick={() => uploadInvoice(true)}
        disabled={frequency.freq_status && frequency.freq_status.toString().toLowerCase() === "cancelled"}
      >
        Upload Invoice
      </button>
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={uploadModal}
        onCancel={() => setUploadModal(false)}
        afterClose={() => { setSelectedField(null); }}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        destroyOnClose={true}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
      >
        <AddUpdateInvoiceModal
          initialValues={selectedField ? selectedField : {}}
          contractor={contractor}
          onClose={() => afterClose()}
          previewFile={openFilePreview}
          jobId={jobId}
          task={task}
          jobDetails={jobDetails}
          po_options={frequency.fm_quote_task_pos && frequency.fm_quote_task_pos.length > 0 ? [{ label: frequency.fm_quote_task_pos[0].po_order_number, value: frequency.fm_quote_task_pos[0].po_id }] : []}
        />
      </Modal>
      {previewFile &&
        <PreviewDocument file={previewFile} onCancel={openFilePreview} />
      }
    </div>
  )
}