import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { CustomCheckbox } from "../../../../../common/customCheckbox";
import * as allocationActions from "../../../../../../actions/v2/AllocationActions";
import { Input } from 'antd';
import jobStyles from "../../../../Job/JobStyle.module.css";

const IS_NEW_ROW = true

export class StaffListContainer extends Component {

  state = {
    search: '',
    staff: this.props.staffList
  }

  componentDidUpdate(prevProps) {
    if (this.props.staffList !== prevProps.staffList) {
      this.setState({ staff: this.props.staffList, search: '' })
    }
  }

  handleCheckboxClick = (val, checked, staffItem, fieldName) => {
    const { currentAction, mainFormValues } = this.props;

    const { start_date, start_time, finish_date, finish_time, state_id } =
      mainFormValues;

    this.props.actions.modifyInitialValues({
      ...mainFormValues,
      ...(currentAction === "UPDATE" && checked
        ? {
          [`update_${staffItem.user_name}_start_date`]: start_date,
          [`update_${staffItem.user_name}_start_time`]: start_time,
          [`update_${staffItem.user_name}_finish_date`]: finish_date,
          [`update_${staffItem.user_name}_finish_time`]: finish_time,
        }
        : {}),
      [`${staffItem.user_name}_start_date`]: start_date,
      [`${staffItem.user_name}_start_time`]: start_time,
      [`${staffItem.user_name}_finish_date`]: finish_date,
      [`${staffItem.user_name}_finish_time`]: finish_time,
      [`createstaff_${staffItem.user_name}`]: false,
      state_id: state_id.value,
    });

    this.props.actions.addOrRemoveStaffRow(
      checked,
      staffItem,
      this.props.staffRows,
      undefined,
      undefined,
      IS_NEW_ROW
    );
  };

  disableCheckbox = (userName) => {
    return (
      Object.keys(this.props.mainFormValues).findIndex((keyName) =>
        keyName.includes(`update_${userName}_start_date`)
      ) !== -1 && this.props.selectedShiftDetails.staffs.map(staffItem => staffItem.user_name).includes(userName)
    );
  };

  onSearch = (e) => {
    let value = e.target.value;
    this.setState({ search: value })
    let filteredStaff = this.props.staffList.filter(i => (i.first_name !== null && i.first_name.toString().toLowerCase().includes(value.toLowerCase().trim())));
    this.setState({ staff: filteredStaff })
  }
  render() {
    const { currentAction } = this.props;

    return (
      <>
        <div style={{ padding: "5px 0px" }}>
          <Input placeholder="Search Staff" value={this.state.search} onChange={this.onSearch} className={jobStyles.InputFormField} />
          <div name="StaffList" className="staff-check-list" style={{ margin: "10px 5px 0px" }}>
            {this.state.staff.length ? (
              this.state.staff.map((staffListItem) => (
                <div className="staff-check-line-item" key={staffListItem.user_name} >
                  <Field
                    label={
                      <label
                        className={
                          "" + staffListItem.staff_status === 1
                            ? "green-uline"
                            : staffListItem.staff_status === 2
                              ? "red-uline"
                              : staffListItem.staff_status === 3
                                ? "purple-uline"
                                : staffListItem.staff_status === 4
                                  ? "blue-uline"
                                  : ""
                        }
                      >
                        {staffListItem.first_name}
                      </label>
                    }
                    disabled={
                      staffListItem.staff_status > 0 ||
                      (currentAction === "UPDATE" &&
                        this.disableCheckbox(staffListItem.user_name))
                    }
                    name={"addlist_staff_" + staffListItem.user_name}
                    component={CustomCheckbox}
                    className="cs-select-v2"
                    onChange={(val, checked) =>
                      this.handleCheckboxClick(
                        val,
                        checked,
                        staffListItem,
                        `addlist_staff_${staffListItem.user_name}`
                      )
                    }
                  />
                </div>
              ))
            ) : (
              <p>List of available staff members</p>
            )}
          </div>
          <div className="site-s-body mt-3 pl-0">
            <div className="view-text-value pl-0 mt-1 mb-1">
              <span>
                <label className="green-uline mr-1">Green Underline: </label>
                Staff allocated to this shift
              </span>
              <span>
                <label className="red-uline mr-1">Red Underline: </label>
                Staff Unavailable
              </span>
              <span>
                <label className="purple-uline mr-1">Purple Underline: </label>
                Staff working night shift
              </span>
              <span>
                <label className="blue-uline mr-1">Blue Underline: </label>
                Staff allocated to other job
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let values = {};
  const finalValues = {};
  if (
    state.form.AllocationActionsForm &&
    state.form.AllocationActionsForm.values
  ) {
    values = state.form.AllocationActionsForm.values;
    const keys = Object.keys(values);
    const userNames = [];
    keys.forEach(
      (keyName) =>
        keyName.startsWith("update_") &&
        keyName.includes("_start_date") &&
        userNames.push(keyName.split("_")[1])
    );
    userNames.forEach((uName) => (finalValues[`addlist_staff_${uName}`] = 1));
  }
  return {
    staffList: state.Allocations.staffList,
    staffRows: state.Allocations.staffRows,
    formValues: state.form.StaffListForm && state.form.StaffListForm.values,
    mainFormValues:
      state.form.AllocationActionsForm &&
      state.form.AllocationActionsForm.values,
    currentAction: state.Allocations.currentAction,
    initialValues: finalValues,
    selectedShiftDetails: state.Allocations.selectedShiftDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(allocationActions, dispatch),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "StaffListForm", enableReinitialize: true })
)(StaffListContainer);