import { getStorage } from "../../../../../utils/common";
import { ADMIN_DETAILS } from "../../../../../dataProvider/constant";

const formatData = (formData, dropdownData, toggle) => {
  let allData = [...dropdownData];
  let enabledData = {};
  let selData = [...formData.map(x => ({ ...x, label: x.method_item, value: x.id }))];

  selData = selData.map(x => {
    if (x.selected && x.selected === 1) {
      enabledData[`item_${x.id}`] = true;
    } else {
      enabledData[`item_${x.id}`] = false;
    }
    let val = allData.findIndex(y => y.id.toString() === x.id.toString());
    if (val === -1) {
      if (x.isNew) {
        allData.push({ id: x.id, method_item: x.method_item, isNew: true, linked_documents: x.linked_documents });
      }
      return {
        ...x,
        ...allData[val],
      }
    } else {
      return x;
    }
  });

  return {
    data: allData,
    enabled: enabledData,
    selected: selData,
    toggle: toggle === 1 ? true : false
  }
}

export const serializeInitialValues = (
  {
    key_contacts = {},
    ppe_details = {},
    tool_details = {},
    plant_and_equipment_details = {},
    training_and_licenses_details = {},
    legislation_safety_acts_and_standards_details = {},
    swms_consultations = {},
    material_details = {},
    activities = {},
    swms_revisions_and_approvals = {},
    risk_matrix_section = {},
    permits_section = {},
    task_details = {},
    high_risk_activities = {},
    risk_ratings = {},
    sds_details = {},
  },
  { ppe = [],
    tools = [],
    plant_equipment = [],
    licences_certificates_and_competencies = [],
    occupational_health_and_safety_legislation = [],
    code_of_practice = [],
    australian_standards = [],
    work_health_and_safety_acts_and_regulations = [],
    materials = [],
    safety_data_sheets = []
  }, {
    critical_risk = [],
    high_risk = [],
    enviromental_risk = []
  }) => {
  let values = {};
  try {
    let organisation = JSON.parse(getStorage(ADMIN_DETAILS)).organisation;
    let domain = new URL(organisation.logo_path).origin;
    let slug = organisation.slug;
    let files_path = `${domain}/${slug}/`;

    let hrs = {};
    let hrsItems = {
      critical_risk: [],
      enviromental_risk: [],
      high_risk: []
    };
    if (high_risk_activities && high_risk_activities.high_risk_activities_item) {
      if (high_risk_activities.high_risk_activities_item.critical_risk) {
        hrsItems.critical_risk = critical_risk.map(x => {
          let val = high_risk_activities.high_risk_activities_item.critical_risk.findIndex(y => y.id.toString() === x.id.toString());
          if (val === -1) {
            return { ...x }
          } else {
            return { ...high_risk_activities.high_risk_activities_item.critical_risk[val], ...x }
          }
        })
      }
      if (hrsItems.critical_risk) {
        hrsItems.critical_risk.forEach(x => {
          if (x.selected === 1) {
            hrs[`item_${x.id}`] = true;
          } else {
            hrs[`item_${x.id}`] = false;
          }
        })
      }
      if (high_risk_activities.high_risk_activities_item.enviromental_risk) {
        hrsItems.enviromental_risk = enviromental_risk.map(x => {
          let val = high_risk_activities.high_risk_activities_item.enviromental_risk.findIndex(y => y.id.toString() === x.id.toString());
          if (val === -1) {
            return { ...x }
          } else {
            return { ...high_risk_activities.high_risk_activities_item.enviromental_risk[val], ...x }
          }
        })
      }
      if (hrsItems.enviromental_risk) {
        hrsItems.enviromental_risk.forEach(x => {
          if (x.selected === 1) {
            hrs[`item_${x.id}`] = true;
          } else {
            hrs[`item_${x.id}`] = false;
          }
        })
      }
      if (high_risk_activities.high_risk_activities_item.high_risk) {
        hrsItems.high_risk = high_risk.map(x => {
          let val = high_risk_activities.high_risk_activities_item.high_risk.findIndex(y => y.id.toString() === x.id.toString());
          if (val === -1) {
            return { ...x }
          } else {
            return { ...high_risk_activities.high_risk_activities_item.high_risk[val], ...x }
          }
        })
      }
      if (hrsItems.high_risk) {
        hrsItems.high_risk.forEach(x => {
          if (x.selected === 1) {
            hrs[`item_${x.id}`] = true;
          } else {
            hrs[`item_${x.id}`] = false;
          }
        })
      }
    }
    values.high_risk_section = {
      data: { ...hrsItems },
      enabled: hrs,
      toggle: high_risk_activities.toggle ? true : false
    };

    let task_details_section = {}
    if (task_details) {
      let taskDocs = [];
      if (task_details.task_documents_images && task_details.task_documents_images.length > 0) {
        task_details.task_documents_images.forEach(doc => {
          taskDocs.push({
            file_name: {
              file_name: doc.file_name,
              visible_name: doc.visible_name,
              isUploaded: true,
              file_base_path: files_path
            }
          })
        })
      }
      task_details_section = { ...task_details, task_documents_images: taskDocs }
    }

    let permits = { toggle: false, permits: [] };
    if (permits_section) {
      let allPerms = [];

      if (permits_section.permits) {
        permits_section.permits.forEach(item => {
          let obj = {}
          if (item.permit_name) {
            obj.permit_name = item.permit_name;
          }
          if (item.permit_document) {
            obj.permit_document = {
              file_name: item.permit_document.file_name,
              visible_name: item.permit_document.visible_name,
              isUploaded: true,
              file_base_path: files_path
            }
          }
          if (Object.keys(obj).length > 0) {
            allPerms.push(obj);
          }
        })
      }
      if (permits_section.toggle === 1) {
        permits.toggle = true;
      }
      permits.permits = [...allPerms];
    }
    values.permits_section = permits;

    let ppe_data = formatData(ppe_details && ppe_details.ppe_items ? ppe_details.ppe_items : [], ppe ? ppe : [], ppe_details.toggle);
    let tools_data = formatData(tool_details && tool_details.tool_items ? tool_details.tool_items : [], tools ? tools : [], tool_details.toggle);
    let pe_data = formatData(plant_and_equipment_details && plant_and_equipment_details.plant_and_equipment_items ? plant_and_equipment_details.plant_and_equipment_items : [], plant_equipment ? plant_equipment : [], plant_and_equipment_details.toggle);
    let tlc_data = formatData(training_and_licenses_details && training_and_licenses_details.training_and_license_items ? training_and_licenses_details.training_and_license_items : [], licences_certificates_and_competencies ? licences_certificates_and_competencies : [], training_and_licenses_details.toggle)
    let lsc_data = formatData(legislation_safety_acts_and_standards_details && legislation_safety_acts_and_standards_details.legislation_safety_acts_and_standard_items ? legislation_safety_acts_and_standards_details.legislation_safety_acts_and_standard_items : [],
      [...(occupational_health_and_safety_legislation ? occupational_health_and_safety_legislation : []), ...(code_of_practice ? code_of_practice : []), ...(australian_standards ? australian_standards : []), ...(work_health_and_safety_acts_and_regulations ? work_health_and_safety_acts_and_regulations : [])], legislation_safety_acts_and_standards_details.toggle);

    let materials_data = formatData(material_details && material_details.material_items ? material_details.material_items : [], materials ? materials : [], material_details.toggle);
    let sds_data = formatData(sds_details && sds_details.sds_items ? sds_details.sds_items : [], safety_data_sheets ? safety_data_sheets : [], sds_details.toggle);
    values.ppe_section = { ...ppe_data };
    values.tools_section = { ...tools_data };
    values.plant_equipment_section = { ...pe_data };
    values.tlc_section = { ...tlc_data };
    values.lsc_section = { ...lsc_data };
    values.key_contacts_section = key_contacts ? { ...key_contacts, toggle: key_contacts.hasOwnProperty("toggle") && key_contacts.toggle === 1 ? true : false } : {};
    values.swms_consultation_section = swms_consultations ? { ...swms_consultations, toggle: swms_consultations.hasOwnProperty("toggle") && swms_consultations.toggle === 1 ? true : false } : {};
    values.materials_section = { ...materials_data }
    values.jsa_section = { activities: activities.swms_activities ? activities.swms_activities.map(x => ({ ...x, action_by: x.action_by ? x.action_by : "" })) : [], toggle: activities.toggle === 1 ? true : false };
    values.swms_review_section = { ...swms_revisions_and_approvals, toggle: swms_revisions_and_approvals.toggle === 1 ? true : false };
    values.risk_matrix_section = risk_matrix_section && risk_matrix_section.toggle === 1 ? { toggle: true } : { toggle: false }
    values.task_details_section = { ...task_details_section };
    values.risk_ratings = { ...risk_ratings }
    values.sds_section = { ...sds_data };
  } catch (err) {
    console.log(err);
  }
  return values;
}