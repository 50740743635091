import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { notification, Pagination } from "antd";
import { Strings } from '../../../../dataProvider/localize';
import { getSignOffFeedback } from "../../../../actions/v2/TaskSignOffActions";
import LinearProgress from '@mui/material/LinearProgress';
import moment from "moment";

import styles from "./SignOffStyles.module.css"
import jobStyles from "../JobStyle.module.css";

export default function SignOffFeedback(props) {

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    loadFeedbacks(currentPage);
  }, [])

  useEffect(() => {
    if (props.refresh !== 0) {
      loadFeedbacks();
    }
  }, [props.refresh])

  const loadFeedbacks = (page = 1) => {
    setLoading(true);
    setCurrentPage(page);

    let jobId = props.jobId;

    dispatch(getSignOffFeedback(jobId, page)).then((res) => {
      let allFeeds = res.job_sign_off_feedbacks && res.job_sign_off_feedbacks.rows ? res.job_sign_off_feedbacks.rows : [];
      let itemsCount = res.job_sign_off_feedbacks && res.job_sign_off_feedbacks.count ? res.job_sign_off_feedbacks.count : 0;
      setFeedbacks(allFeeds);
      setTotalItems(itemsCount);
    }).catch(err => {
      notification.error({
        message: Strings.error_title,
        description: err ? err : Strings.generic_error,
        className: 'ant-error'
      })
    }).finally(() => setLoading(false))
  }

  return <div className="mt-2 mb-4">
    <div className={jobStyles.SectionHeading}>
      Feedback
    </div>
    <div className={jobStyles.card}>
      <div className={`container-fluid ${styles.FeedbackContainer}`}>
        {feedbacks.map(feedback =>
          <div className={`row ${styles.BorderBottom}`} key={feedback.id}>
            <div className="col-2 py-2">
              <div className={`${styles.TableSecondaryText} font-weight-bold`}>{feedback.person_name ? feedback.person_name : ''}</div>
              <div className={styles.TableSecondaryText}>{feedback.person_role ? feedback.person_role : ''}</div>
              <div className={styles.TableSecondaryText}>{feedback.sign_off_date_time ? moment.utc(feedback.sign_off_date_time).format("DD-MM-YYYY") : ''} </div>
            </div>
            <div className="col-10 py-2">
              <div>
                {feedback.feedback ? feedback.feedback : ''}
              </div>
            </div>
          </div>
        )}
        {!loading && feedbacks.length === 0 &&
          <div className="row border">
            <div className="col">
              <div className={`text-center my-2 ${styles.SecondaryText}`}>No Feedbacks Available.</div>
            </div>
          </div>
        }
      </div>
      {loading &&
        <div className="row">
          <div className="col">
            <div style={{ height: '4px' }}>
              <LinearProgress />
            </div>
          </div>
        </div>
      }
      {totalItems > 3 &&
        <div className="my-3 text-right">

          <Pagination
            total={totalItems}
            onChange={(page) => loadFeedbacks(page)}
            pageSize={3}
            className="cs-pagination-v2"
            current={currentPage} />
        </div>

      }
    </div>
  </div>
}