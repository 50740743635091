import React from "react";
import Creatable from "react-select/creatable";
import colourStyles from "./reactSelectStyles";

export const CustomSelect = ({ input, label, options, onChange, clearable, otherProps = {} }) => {
  return (
    <div>
      {label && label.length > 0 &&
        <label>{label}</label>
      }
      <Creatable
        isMulti
        onChange={onChange || input.onChange}
        options={options}
        value={input.value}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        clearable={clearable || true}
        menuPortalTarget={document.body}
        styles={colourStyles}
        {...otherProps}
      />
    </div>
  );
};
