import React from "react";
import styles from "./Sections.module.css";

const JobBoardSites = ({ jobDetails }) => {

  return (
    <div className="sf-card">
      <div className="sf-card-body">
        {jobDetails &&
          jobDetails.sites &&
          jobDetails.sites.map((siteItem) => (
            <div className={styles.SingleSiteItem}>
              <div>
                <label>Site: </label>
                <span>{siteItem.site_name}</span>
              </div>
              <div>
                <label>Address: </label>
                <span>{`${siteItem.street_address ? siteItem.street_address : ""
                  }, ${siteItem.state
                    ? siteItem.state.substring(0, 3).toUpperCase()
                    : ""
                  }, ${siteItem.zip_code ? siteItem.zip_code : ""}`}</span>
              </div>

              {siteItem.tasks && siteItem.tasks.map(taskItem => (
                <div>
                  <label>{`${taskItem.job_task_label} ${taskItem.task_name} - ${taskItem.area_name}`}</label>
                </div>
              ))}

            </div>
          ))}
      </div>
    </div>
  );
};

export default JobBoardSites;
