import React from 'react';
import PayrollDetailsForm from './PayrollDetailsForms/PayrollDetailsForm';
import SuperFundForm from './PayrollDetailsForms/SuperFundForm';
import BankAccountForm from './PayrollDetailsForms/BankAccountForm';
import RosteringDetailsWrapper from './PayrollDetailsForms/RosteringDetailsWrapper';

export default function PayrollDetails(props) {
    return (
        <div>
            <div>
                <PayrollDetailsForm userDetails={props.userDetails} userId={props.userId} userName={props.userName} />
            </div>
            <div>
                <SuperFundForm userDetails={props.userDetails} userId={props.userId} userName={props.userName} />
            </div>
            <div>
                <BankAccountForm userDetails={props.userDetails} userId={props.userId} userName={props.userName} />
            </div>
            <div>
                <RosteringDetailsWrapper
                    userId={props.userId}
                    userName={props.userName}
                    userDetails={props.userDetails}
                    pay_classification={props.userDetails && props.userDetails.pay_classification && props.userDetails.pay_classification.length > 0 ? props.userDetails.pay_classification[0] : {}}
                />
            </div>
        </div>
    );
}
