import { Icon, Tabs } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators, compose } from "redux";
import { reduxForm } from "redux-form";
import { getStorage, goBackBrowser } from "../../../utils/common";
import { tabs } from "./tabDefinitions";
import * as allocationActions from "../../../actions/v2/AllocationActions";
import * as jobDocActions from "../../../actions/v2/JobDocumentActions";
import { ADMIN_DETAILS } from "../../../dataProvider/constant";

const { TabPane } = Tabs;

export class JobDetailsPageContainer extends Component {
  componentDidMount = () => {
    this.props.actions.resetTimesheets();
    jobDocActions.resetJobDoc();
  };

  handleTabChange = (tabKey) => {
    const { actions, jobDocActions, selectedJobDetails } = this.props;
    actions.resetAction("COPY");
    if (tabKey === "3") {
      jobDocActions.resetJobDoc();
      const currentOrganization = JSON.parse(getStorage(ADMIN_DETAILS))
        ? JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id
        : null;

      const requests = [];

      requests.push(jobDocActions.getJobDocumentStaffList());
      requests.push(
        jobDocActions.getJobDocumentOrgCertificates(
          currentOrganization,
          selectedJobDetails.id
        )
      );
      requests.push(jobDocActions.getJobDocumentDetails(selectedJobDetails.id, selectedJobDetails.scopeId));

      Promise.all(requests)
        .then((res) => {})
        .catch((err) => {});
    }
  };
  //
  render() {
    return (
      <div style={{ maxWidth: "94vw", overflowX: "auto" }}>
        <div className="sf-page-layout">
          <div className="dash-header">
            <h2 className="page-mn-hd">
              <Icon
                type="arrow-left"
                onClick={() => goBackBrowser(this.props)}
              />
            </h2>
          </div>
          <div style={{ minHeight: "5px" }}></div>
          <div className="sf-card">
            <div className="job-details-tabs-content">
              <Tabs tabPosition="top" onChange={this.handleTabChange}>
                {tabs(this.props.selectedJobDetails).map((tab) => (
                  <TabPane tab={tab.displayName} key={tab.id}>
                    {<tab.component selectedJobDetails={this.props.selectedJobDetails} />}
                  </TabPane>
                ))}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedJobDetails: state.JobCalendar.selectedJobDetails,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(allocationActions, dispatch),
    jobDocActions: bindActionCreators(jobDocActions, dispatch),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "JobDetailsPageContainer", enableReinitialize: true })
)(JobDetailsPageContainer);
