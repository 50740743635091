import {
  serializeAddInitialValues,
  serializeShiftAllocationEvents,
  serializeStaffList,
} from "../../components/v2/JobDetails/JobAllocation/serializer";
import {
  serializeTimesheetData,
  serializeShiftIds,
} from "../../components/v2/JobDetails/JobAllocation/ShiftTimeSheet/serializer";
import { scopeAxiosInstance, scopeV3AxiosInstance } from "../../dataProvider/axiosHelper";
import { UPDATEJOB_SHIFT_URL } from "../../dataProvider/constant";
import { Strings } from "../../dataProvider/localize";
import { startSipnner, stopSipnner } from "../../utils/spinner";

import moment from 'moment';

export const initAllocationsCalendar = () => (dispatch) => {
  dispatch({
    type: "INIT_ALLOCATIONS_CALENDAR",
  });
};

export const getShiftAllocations = (jobId, jobDetails) => (dispatch) => {
  startSipnner(dispatch);
  return scopeAxiosInstance
    .get(`/job-shifts?job_id=${jobId}`)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        const serializedShifts = serializeShiftAllocationEvents(
          res.data.data.shift_allocations,
          jobDetails,
          dispatch,
          deleteAllocation
        );

        dispatch({
          type: "GET_SHIFT_ALLOCATIONS",
          payload: serializedShifts,
        });
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const startAction =
  (actionType, jobDetails, shiftDetails, event) => (dispatch) => {
    dispatch({
      type: "START_ALLOCATION_FORM_ACTION",
      payload: {
        actionType: actionType,
        jobDetails: jobDetails,
        shiftDetails: shiftDetails,
        event: event,
      },
    });
  };

export const resetAction = (actionType) => (dispatch) => {
  dispatch({
    type: "RESET_ALLOCATION_FORM_ACTION",
    payload: {
      actionType: "",
    },
  });
};

export const getAllocationStaffList =
  (id, jobId, shiftDate, yardTime, siteTime, finishTime, stateId) =>
    (dispatch) => {
      startSipnner(dispatch);

      const url = `/org-staffs?service_agent_id=${id}&job_id=${jobId}&shift_date=${shiftDate}&yard_time=${yardTime}&site_time=${siteTime}&finish_time=${finishTime}&state_id=${stateId}`;

      return scopeAxiosInstance
        .get(url)
        .then((res) => {
          stopSipnner(dispatch);
          if (res.data.status) {
            const serialized = serializeStaffList(res.data.data, stateId);
            dispatch({
              type: "GET_STAFF_LIST_FOR_ALLOCATION",
              payload: serialized,
            });
            return Promise.resolve(res.data.message && res.data.message);
          }
        })
        .catch((error) => {
          stopSipnner(dispatch);
          return Promise.reject(
            error.response ? error.response.data.message : Strings.network_error
          );
        });
    };

export const createShiftAllocation = (formData) => (dispatch) => {
  startSipnner(dispatch);
  return scopeV3AxiosInstance
    .post(`/job-shifts`, formData)
    .then((res) => {
      stopSipnner(dispatch);
      return Promise.resolve(res.data.message && res.data.message);
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response && error.response.data
          ? error.response.data.message
          : Strings.network_error
      );
    });
};

const deleteAllocation = (
  shiftId,
  jobId,
  jobDetails,
  dispatch,
  shiftItem = null
) => {
  var staffs = [];
  if (shiftItem !== null) {
    shiftItem.staffs.forEach((staff) => {
      var schedule = {
        user_name: staff.user_name,
        is_site_supervisor: staff.is_site_supervisor,
        start_time_type: staff.start_time_type,
        site_yard_time: staff.site_yard_time,
        staff_finish_time: staff.staff_finish_time,
      };

      staffs.push(schedule);
    });
  }

  var job_schedule = [
    {
      id: shiftId,
      staff: staffs,
    },
  ];

  startSipnner(dispatch);

  const url = "/job-shifts";

  return scopeV3AxiosInstance
    .put(url, { job_schedule_shift_id: shiftId, job_schedule: job_schedule, job_id: jobId, quote_id: jobDetails.quoteId })
    .then((firstRes) => {
      return scopeAxiosInstance
        .get(`/job-shifts?job_id=${jobId}`)
        .then((res) => {
          stopSipnner(dispatch);
          if (res.data.status) {
            const serializedShifts = serializeShiftAllocationEvents(
              res.data.data.shift_allocations,
              jobDetails,
              dispatch,
              deleteAllocation
            );

            dispatch({
              type: "GET_SHIFT_ALLOCATIONS",
              payload: serializedShifts,
            });
            return Promise.resolve(
              firstRes.data.message && firstRes.data.message
            );
          }
        })
        .catch((error) => {
          stopSipnner(dispatch);
          return Promise.reject(
            error.response ? error.response.data.message : Strings.network_error
          );
        });
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const addOrRemoveStaffRow =
  (checked, staffItem, staffRows, formValues, removeAllRows = false, isNewRow = false) =>
    (dispatch) => {
      if (removeAllRows) {
        dispatch({
          type: "ADD_OR_REMOVE_STAFF_ROW",
          payload: { staffRows: [], initialValues: {} },
        });
        return;
      }
      const updated = [...staffRows];
      if (checked) {
        updated.push({ ...staffItem, isNewRow: isNewRow });
      } else {
        const index = staffRows.findIndex((item) => item.id == staffItem.id);
        updated.splice(index, 1);
      }
      dispatch({
        type: "ADD_OR_REMOVE_STAFF_ROW",
        payload: {
          staffRows: updated,
          initialValues: serializeAddInitialValues(staffItem, formValues),
        },
      });
    };

export const modifyInitialValues = (additionalValues) => (dispatch) => {
  dispatch({
    type: "MODIFY_INITIAL_VALUES",
    payload: additionalValues,
  });
};

export const validateStaffForCopy = (params, stateId) => (dispatch) => {
  startSipnner(dispatch);
  const url = "/validate-staff-availability";
  return scopeAxiosInstance
    .get(url, { params: params })
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: "GET_STAFF_LIST_FOR_COPY_ALLOCATION",
          payload: res.data.data,
        });
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const copyShiftAllocation =
  (formData, jobId, jobDetails) => (dispatch) => {
    startSipnner(dispatch);
    const url = "copy-job-shifts";

    return scopeAxiosInstance
      .post(url, formData)
      .then((firstRes) => {
        return scopeAxiosInstance
          .get(`/job-shifts?job_id=${jobId}`)
          .then((res) => {
            stopSipnner(dispatch);
            if (res.data.status) {
              const serializedShifts = serializeShiftAllocationEvents(
                res.data.data.shift_allocations,
                jobDetails,
                dispatch,
                deleteAllocation
              );

              dispatch({
                type: "GET_SHIFT_ALLOCATIONS",
                payload: serializedShifts,
              });
              return Promise.resolve(
                firstRes.data.message && firstRes.data.message
              );
            }
          })
          .catch((error) => {
            stopSipnner(dispatch);
            return Promise.reject(
              error.response
                ? error.response.data.message
                : Strings.network_error
            );
          });
      })
      .catch((error) => {
        stopSipnner(dispatch);
        return Promise.reject(
          error.response ? error.response.data.message : Strings.network_error
        );
      });
  };

export const setSelectedStaff = (staffList) => (dispatch) => {
  dispatch({
    type: "SET_SELECTED_STAFF",
    payload: staffList,
  });
};

export const sendNotification = (params) => (dispatch) => {
  startSipnner(dispatch);
  const url = "/send-job-notification";

  return scopeV3AxiosInstance
    .post(url, params)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const deleteShiftAllocation =
  (shiftId, schedule = [], jobDetails) =>
    (dispatch) => {
      startSipnner(dispatch);

      const url = "/job-shifts";

      return scopeV3AxiosInstance
        .put(url, { job_schedule_shift_id: shiftId, job_schedule: schedule, job_id: jobDetails.id, quote_id: jobDetails.quoteId })
        .then((res) => {
          stopSipnner(dispatch);
          if (res.data.status) {
            return Promise.resolve(res.data.message && res.data.message);
          }
        })
        .catch((error) => {
          stopSipnner(dispatch);
          return Promise.reject(
            error.response ? error.response.data.message : Strings.network_error
          );
        });
    };

export const updateShiftDetails = (shiftDetails) => (dispatch) => {
  dispatch({
    type: "UPDATE_SHIFT_DETAILS",
    payload: shiftDetails,
  });
};

export const getAllocationTimesheet = (values) => (dispatch) => {
  startSipnner(dispatch);

  const url = "/timesheet";

  const params = {
    pay_cycle_type: values.pay_cycle_type,
    job_id: values.jobId,
    ...(values.start_date
      ? { pay_period_from_date: `"${moment(values.start_date).format("YYYY-MM-DD")}"` }
      : {}),
    ...(values.finish_date ? { pay_period_to_date: `"${moment(values.finish_date).format("YYYY-MM-DD")}"` } : {}),
    ...(values.shiftId && values.shiftId.length
      ? {
        job_shift_ids: JSON.stringify(values.shiftId.map((s) => parseInt(s))),
      }
      : {}),
    ...(values.shiftStatus && values.shiftStatus.length
      ? {
        shift_status: JSON.stringify(
          values.shiftStatus.map((s) => parseInt(s))
        ),
      }
      : {}),
    ...(values.approved === 1
      ? { approved: 1 }
      : values.approved === 0
        ? { approved: 0 }
        : {}),
  };

  return scopeAxiosInstance
    .get(url, { params: params })
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: "GET_ALLOCATION_TIMESHEET",
          payload: {
            timesheets: serializeTimesheetData(res.data.data.job_timesheets),
            shiftIdDataSource: serializeShiftIds(res.data.data.job_timesheets),
          },
        });
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const resetTimesheets = () => (dispatch) => {
  dispatch({
    type: "RESET_TIMESHEETS",
  });
};

export const getTimesheetInitialValues = (values) => (dispatch) => {
  dispatch({
    type: "GET_TIMESHEET_INITIALVALUES",
    payload: values,
    currentSelectedShift: values,
  });
};

export const resetIntialValues = (values) => (dispatch) => {
  dispatch({
    type: "RESET_TIMESHEET_INITIALVALUES",
    payload: values,
  });
};

export const updateJobShift = (formData) => (dispatch) => {
  startSipnner(dispatch);
  return scopeAxiosInstance
    .put(UPDATEJOB_SHIFT_URL, formData)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve({
          message: res.data.message,
          result: res.data.data,
        });
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const deleteStaffRow = (params) => (dispatch) => {
  startSipnner(dispatch);
  return scopeV3AxiosInstance
    .delete("/job-shifts-allocation", { data: params })
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve({
          message: res.data.message,
          result: res.data.data,
        });
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};
