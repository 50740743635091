import React, { Component } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { customInput } from "../../../common/custom-input";
import { CustomSelect } from "../../../common/customSelect";
import { getFormattedPopBoxOptions, getFormattedSelectOptions } from "../utils";
import * as SWMSMastersActions from "../../../../actions/SWMSMastersActions";
import { bindActionCreators } from "redux";
import { MultiSelect } from "../../../core/Select/MultiSelect";

export class MainFormFields extends Component {
  state = {
    selectedClient: "",
  };

  componentDidMount() {
    this.props.actions
      .getClientSites()
      .then((res) => {})
      .catch((err) => {});
  }

  getSitesList = () => {
    const { sitesList, formValues } = this.props;

    const filteredSitesList = sitesList.filter(
      (s) => formValues.client.toString() === s.client_id.toString()
    );

    return getFormattedSelectOptions(filteredSitesList, "site_name", "site_id");
  };

  render() {
    const {
      roles,
      generalCategories,
      categories,
      taskTypes,
      clientsList,
      formValues,
    } = this.props;

    return (
      <div className="sf-card">
        <div
          className="main-form-fields sf-card-body"
          style={{ marginBottom: "10px" }}
        >
          <div className="row">
            <fieldset className="form-group sf-form col-2">
              <Field
                placeholder="Activity ID"
                name="activity_id"
                label="Activity ID"
                type="text"
                component={customInput}
              />
            </fieldset>
            <fieldset className="form-group sf-form col-2">
              <Field
                placeholder="Activity Name"
                name="activity_name"
                type="text"
                label="Activity Name"
                component={customInput}
              />
            </fieldset> 
            <fieldset className="form-group sf-form col-2">
              <Field
                placeholder="SWMS Category"
                name="general_category"
                label="SWMS Category"
                options={getFormattedSelectOptions(generalCategories)}
                component={CustomSelect}
              />
            </fieldset>
            <fieldset className="form-group sf-form col-2">
              <Field
                placeholder="SWMS Method"
                name="category"
                label="SWMS Method"
                options={getFormattedSelectOptions(categories, "category")}
                component={CustomSelect}
              />
            </fieldset>
            <div className="col-4">
              <fieldset className="form-group sf-form">
                <Field
                  placeholder="Responsible"
                  name="responsible"
                  label="Responsible"
                  buttonTitle="PR"
                  type="multiple"
                  multi
                  mode="tags"
                  options={getFormattedPopBoxOptions(roles, "name")}
                  component={MultiSelect}
                />
              </fieldset>
            </div>
            <fieldset className="form-group sf-form col-2">
              <Field
                placeholder="Task Type"
                name="task_type"
                label="Task Type"
                options={getFormattedSelectOptions(taskTypes)}
                component={CustomSelect}
              />
            </fieldset>
            <fieldset className="form-group sf-form col-2">
              <Field
                name="status"
                label="Status"
                options={[
                  { title: "Active", value: 1 },
                  { title: "Inactive", value: 0 },
                ]}
                component={CustomSelect}
              />
            </fieldset>
            <fieldset className="form-group sf-form col-2">
              <Field
                name="is_site_specific"
                label="Is Site Specific"
                options={[
                  { title: "Yes", value: 1 },
                  { title: "No", value: 0 },
                ]}
                component={CustomSelect}
              />
            </fieldset>
            {formValues && formValues.is_site_specific === 1 ? (
              <>
                <fieldset className="form-group sf-form col-2">
                  <Field
                    placeholder="Client Name"
                    name="client"
                    label="Client"
                    options={getFormattedSelectOptions(clientsList, "name")}
                    component={CustomSelect}
                  />
                </fieldset>
                <fieldset className="form-group sf-form col-2">
                  <Field
                    placeholder="Site Name"
                    name="site"
                    label="Site"
                    options={
                      formValues && formValues.client ? this.getSitesList() : []
                    }
                    component={CustomSelect}
                  />
                </fieldset>
              </>
            ) : (
              <>
                <fieldset className="form-group sf-form col-2"></fieldset>
                <fieldset className="form-group sf-form col-2"></fieldset>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  generalCategories: state.SWMSMasters.generalCategory,
  categories: state.SWMSMasters.category,
  taskTypes: state.SWMSMasters.taskType,
  clientsList: state.SWMSMasters.clientsList,
  sitesList: state.SWMSMasters.sitesList,
  roles: state.roleManagement.roles,
  formValues:
    state.form.SWMSActivity &&
    state.form.SWMSActivity.values &&
    state.form.SWMSActivity.values
      ? state.form.SWMSActivity.values
      : null,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(SWMSMastersActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainFormFields);
