import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import styles from "../ProfileStyles.module.css";
import { getTimeZones, updateUserTimeZone } from "../../../../actions/profileManagementActions";
import { notification } from "antd";
import { Strings } from "../../../../dataProvider/localize";
import Select, { components } from "react-select"
import colorStyles from "../FilterSelectProfileStyles";
import { CircularProgress } from "@mui/material";
import { getStorage, setStorage } from "../../../../utils/common";
import { ADMIN_DETAILS } from "../../../../dataProvider/constant";

export default function UpdateUserTimeZone({ timeZone = null, utc = null, userName = null, onClose }) {

  const [timeZones, setTimeZones] = useState([]);
  const [selected, setSelected] = useState(timeZone);
  const [loadingZones, setLoadingZones] = useState(false);
  const [loading, setLoading] = useState(false);
  const controller = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    fetchTimeZones();
    return () => {
      if (controller.current) {
        controller.current.abort();
      }
    }
  }, [])

  useEffect(() => {
    if (timeZone && utc) {
      setSelected({ label: utc, value: timeZone });
    }
  }, [timeZone, utc])


  const fetchTimeZones = () => {
    setLoadingZones(true);
    controller.current = new AbortController();
    dispatch(getTimeZones({ signal: controller.current.signal })).then(res => {
      setTimeZones(res.map((x) => ({ label: x.time, value: x.iana_timezone })));
      setLoadingZones(false);
    }).catch(err => {
      if (err) {
        setLoadingZones(false);
        console.log(err);
        notification.error({
          message: Strings.error_title,
          description: "There was an error while loading Time Zones.",
          className: "ant-error"
        })
      }
    })
  }

  const onSubmit = () => {
    if (selected) {
      setLoading(true);
      let formData = {
        utc_timezone: selected.label,
        timezone: selected.value
      }
      dispatch(updateUserTimeZone(formData, userName)).then(message => {
        notification.success({
          message: Strings.success_title,
          description: message,
          className: "ant-success"
        })
        try {
          let user = JSON.parse(getStorage(ADMIN_DETAILS));
          if (user && user.user_name && userName && user.user_name.toString() === userName.toString()) {
            setStorage(ADMIN_DETAILS, JSON.stringify({ ...user, user_utc_timezone: selected.label, user_timezone: selected.value }))
          }
        } catch (e) {
          console.log(e)
        }
        onClose(true);
      }).catch((err) => {
        setLoading(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: "ant-error"
        })
      })
    }
  }

  return (
    <div className="profile-modal-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={styles.ModalHeading}>Update Time Zone</div>
            <div className="py-3">
              <hr />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className={styles.Label}>Time Zone</div>
            <Select
              options={timeZones}
              onChange={(v) => setSelected(v)}
              value={selected}
              styles={colorStyles}
              isLoading={loadingZones}
              components={{ dropdownIndicator: <components.DownChevron /> }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-right pt-3">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "end", columnGap: "10px" }}>
              {loading ? (
                <CircularProgress size={20} />) : ""}
              <button type="button" className={`${styles.OutlinedButton}`} onClick={onClose} style={{ padding: "0px 5px" }}>
                Cancel
              </button>
              <button
                disabled={loading || loadingZones || !selected}
                className={styles.PrimaryButton}
                onClick={onSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}