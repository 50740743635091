import React, { useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import CustomDay from "../CoreCalendar/CustomViews/CustomDay";
import CustomWeek from "../CoreCalendar/CustomViews/CustomWeek";
import { getShiftColorByMap } from "../../utils";

const localizer = momentLocalizer(moment);

const CoreCalendar = ({
  view,
  events,
  jumpToDate,
  onChangeView,
  onEventSelection,
  onModifyEvent,
  handleSlotSelect,
  tooltipAccessor
}) => {
  const colorSetter = ({ job_shift_status }) => {
    return {
      style: {
        background: getShiftColorByMap[job_shift_status],
      },
    };
  };

  const getEventsByView = () => {
    return events.map((event) => ({ ...event, title: event[`${view}Title`] }));
  };

  return (
    <Calendar
      localizer={localizer}
      events={getEventsByView()}
      onEventDrop={onModifyEvent}
      // selectable
      resizable
      eventPropGetter={colorSetter}
      onEventResize={onModifyEvent}
      defaultView={Views.MONTH}
      view={view}
      defaultDate={jumpToDate ? new Date(jumpToDate) : new Date()}
      onView={(view) => onChangeView(view)}
      onSelectEvent={onEventSelection}
      selectable
      onSelectSlot={handleSlotSelect}
      tooltipAccessor={tooltipAccessor}
      views={{
        day: CustomDay,
        week: CustomWeek,
        month: true,
      }}
    />
  );
};

export default CoreCalendar;
