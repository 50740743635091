import moment from "moment";

export const splitDateTime = (dateTimeObj, format) => {
  if (format === "TIME") {
    return moment(dateTimeObj).format("HH:mm");
  } else if (format === "DATE") {
    return moment(dateTimeObj).format("YYYY-MM-DD");
  }
};

export const shiftStatusByMap = {
  1: "notified",
  2: "accepted",
  3: "declined",
  4: "started",
  5: "finished",
};

export const titleCase = (string) => {
  const leading = string.charAt(0).toUpperCase();
  const split = string.split("");
  split.shift();
  const trailing = split.join("");
  return leading + trailing;
};