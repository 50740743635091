import {
  GET_PAYROLL_LIST,
  IS_GET_PAY_ROLL_LIST,
  GET_EXPORTED_TIMESHEET,
  CLEAR_PAYROLL_LIST
} from "../dataProvider/constant";

const DEFAULT_STATE = {
  payrollList: [],
  totalPayRollCount: [],
  isGetPayrollList: false
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {

    case GET_PAYROLL_LIST:
      return {
        ...state,
        payrollList: action.payload,
        totalPayRollCount: action.total
      };
    case IS_GET_PAY_ROLL_LIST:
      return {
        ...state,
        isGetPayrollList: action.payload
      };
    case GET_EXPORTED_TIMESHEET:
      return {
        ...state,
        // payrollList: action.payload,
        csvFileLink: action.csvFileLink
      };
    case CLEAR_PAYROLL_LIST: {
      return {
        ...state,
        payrollList: []
      }
    }
    default:
      return state;
  }
};
