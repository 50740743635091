import React, { useState } from "react";
import SignOffHistory from "./SignOffHistory";
import TasksSignOffList from "./TasksSignOffList";
import SignOffFeedback from "./SignOffFeedback";

export default function TasksSignOffContainer(props) {

  const [refresh, setRefresh] = useState(0);

  return <div>
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-7 col-sm-12 pr-0">
          <TasksSignOffList jobId={props.jobId} onRefresh={() => setRefresh(refresh + 1)} refresh={refresh} jobDetails={props.selectedJobDetails} />
          <SignOffFeedback jobId={props.jobId} refresh={refresh} jobDetails={props.selectedJobDetails} />
        </div>
        <div className="col-md-5 col-sm-12">
          <SignOffHistory jobId={props.jobId} refresh={refresh} jobDetails={props.selectedJobDetails} />
        </div>
      </div>
    </div>
  </div>
}