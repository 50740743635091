import React from "react";
import styles from "../../JobStyle.module.css";

export default function JobSwmsDetailsSection({ clientDetails, swmsVersion, siteDetails, swmsNumber }) {
  return (
    <div className="">
      <div className={styles.TaskHeadRow}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-4">
              <div><b>SWMS Version:</b> {swmsVersion ? swmsVersion : ""}</div>
            </div>
            <div className="col-4">
              <div><b>SWMS Number:</b> {swmsNumber ? swmsNumber : ""}</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={styles.TaskHeadRow}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-4">
                <div><b>Client Company:</b> {clientDetails.client_name ? clientDetails.client_name : ""}</div>
              </div>
              <div className="col-4">
                <div><b>Client Contact Name:</b> {clientDetails.client_contact_name ? clientDetails.client_contact_name : ""}</div>
              </div>
              <div className="col-4">
                <div><b>Client Contact Email:</b> {clientDetails.client_contact_email ? clientDetails.client_contact_email : ""} </div>
              </div>
              <div className="col-4">
                <div><b>Client Contact Phone:</b> {clientDetails.client_contact_phone ? clientDetails.client_contact_phone : ""}</div>
              </div>
              <div className="col-4"><b>Client PO No:</b> {clientDetails.PO_number ? clientDetails.PO_number : ""}</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={`${styles.TaskHeadRow} border-0`}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-4">
                <div><b>Site:</b> {siteDetails.site_name ? siteDetails.site_name : ""}</div>
              </div>
              <div className="col-4">
                <div><b>Site Address:</b> {siteDetails.site_address ? siteDetails.site_address : ""}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}