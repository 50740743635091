import {
  MultiSelectSerializer,
  ObjToValueSerializer,
  ReactSelectSerializer,
} from "../../../utils/serializers";

export const getInitialValues = () => {
  const values = {
    general_category: [],
    category: [],
    responsible: [],
    task_type: [],
    is_site_specific: 0,
    client: [],
    site: [],
    status: 1,
    hazards: [],
    consequences: [],
    likelihood_before_controls: [],
    consequence_before_controls: [],
    likelihood_after_controls: [],
    consequence_after_controls: [],
  };

  return values;
};

export const createSWMSActivityRequest = (formValues) => {
  const hazards = formValues.hazards.map((item) =>
    item.__isNew__
      ? { title: item.label, master_type: "7", active: "1" }
      : { id: item.value.toString() }
  );
  const consequences = formValues.consequences.map((item) =>
    item.__isNew__
      ? { title: item.label, master_type: "3", active: "1" }
      : { id: item.value.toString() }
  );

  const responsibles = formValues.responsible.map((item) =>
    item.value
  );

  const requestBody = {
    // Existing SWMS Activity
    ...(formValues.id ? { id: formValues.id } : {}),

    // Main form section fields
    activity_id: formValues.activity_id,
    activity: formValues.activity_name,
    general_category_id: formValues.general_category,
    swms_category_id: formValues.category,
    person_responsible: responsibles,
    task_type_id: formValues.task_type,
    is_site_specific: formValues.is_site_specific,
    client_id: formValues.client,
    site_id: formValues.site,
    active: formValues.status,

    // Controls section fields
    hazards: hazards,
    consequences: consequences,
    likelihood_before_controls: formValues.likelihood_before_controls,
    consequence_before_controls: formValues.consequence_before_controls,
    risk_rating_before_controls: formValues.risk_rating_before_controls.split(". ")[0],
    control_measures: formValues.controls,
    likelihood_after_controls: formValues.likelihood_after_controls,
    consequence_after_controls: formValues.consequence_after_controls,
    risk_rating_after_controls: formValues.risk_rating_after_controls.split(". ")[0],

    // DnD items
    swms_tools: formValues.tools.map((item) =>
      ObjToValueSerializer.toServer(item, "id")
    ),
    ppes: formValues.ppe.map((item) =>
      ObjToValueSerializer.toServer(item, "id")
    ),
    high_risks: formValues.highRiskWork.map((item) =>
      ObjToValueSerializer.toServer(item, "id")
    ),
    licenses: formValues.licensesAndTraining.map((item) =>
      ObjToValueSerializer.toServer(item, "id")
    ),
    standards: formValues.standards.map((item) =>
      ObjToValueSerializer.toServer(item, "id")
    ),
  };

  return requestBody;
};

const mapKeysToServer = {
  is_site_specific: "is_site_specific",
  status: "active",
  hazards: "hazards",
  consequences: "consequences",
  likelihood_before_controls: "likelihood_before_controls",
  consequence_before_controls: "consequence_before_controls",
  likelihood_after_controls: "likelihood_after_controls",
  consequence_after_controls: "consequence_after_controls",
  activity_id: "activity_id",
  standards: "standards",
  activity_name: "activity",
  general_category: "general_category_id",
  category: "swms_category_id",
  responsible: "person_responsible",
  task_type: "task_type_id",
  client: "client_id",
  site: "site_id",
  controls: "control_measures",
  highRiskWork: "high_risk",
  licensesAndTraining: "licenses",
  ppe: "ppes",
  tools: "swms_tools",
  controls_temp: "controls_temp",
};

const formFieldsConfig = [
  {
    name: "activity_id",
    type: String,
    serializer: false,
  },
  {
    name: "activity_name",
    type: String,
    serializer: false,
  },
];

export const getFormattedIntialValues = (responseBody, mode, roles = []) => {
  if (responseBody.length) {
    const values = responseBody[0];

    return {
      activity_id:
        mode === "copy" ? "" : mode === "edit" ? values.activity_id : "",
      activity_name:
        mode === "copy" ? "" : mode === "edit" ? values.activity : "",
      general_category: values.general_category_id
        ? values.general_category_id
        : [],
      category: values.swms_category_id ? values.swms_category_id : [],
      responsible: values.swms_person_responsibles
        ? Array.isArray(values.swms_person_responsibles)
          ? values.swms_person_responsibles.map((s) => {
            let userRole = roles.find(role => role.id === s.person_responsible_id);
            if(userRole) {
              return { value: userRole.id, label: userRole.name }
            } else return false
          })
          : [values.swms_person_responsibles]
        : [],
      task_type: values.task_type_id ? values.task_type_id : [],
      is_site_specific: values.is_site_specific === 0 ? 0 : 1,
      client: values.client_id ? values.client_id : [],
      site: values.site_id ? values.site_id : [],
      status: values.active === 0 ? 0 : 1,
      hazards: values.swms_hazards.map((item) => ({
        label: item.hazard_title,
        value: item.hazard_id,
      })),
      consequences: values.swms_consequences.map((item) => ({
        label: item.consequences_title,
        value: item.consequnce_id,
      })),
      likelihood_before_controls: values.likelihood_before_controls,
      consequence_before_controls: values.consequence_before_controls,
      controls: values.control_measures,
      likelihood_after_controls: values.likelihood_after_controls,
      consequence_after_controls: values.consequence_after_controls,

      tags: {
        tools: values.swms_tools,
        ppe: values.swms_ppes,
        highRiskWork: values.swms_high_risks,
        licensesAndTraining: values.swms_licenses,
        standards: values.swms_standards,
      }
    };
  } else {
    return getInitialValues();
  }
};

export const serializeFilterParams = (filters) => {
  const filterKeys = Object.keys(filters);
  const serialized = [];

  filterKeys.forEach((key) => {
    if (filters[key] && filters[key].length > 0) {
      if (Array.isArray(filters[key])) {
        serialized.push(`${key}=[${filters[key]}]`);
      } else {
        serialized.push(`${key}=${filters[key]}`);
      }
    }
  });
  return serialized.join("&");
};
