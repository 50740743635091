export default {
  control: (styles, { isFocused, menuIsOpen, isDisabled }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? '#f2f2f2 !important' : '#f2f2f2 !important',
      boxShadow: isFocused || menuIsOpen ? '0 0 0 0.05rem #c0b4ff' : 'none',
      border: '0px',
      fontSize: '18px',
      borderRadius: '10px 0 0 10px',
      minHeight: '60px',
      width: "100px",
      ...(isFocused || menuIsOpen ? {
        border: "0px",
        backgroundColor: "#fff",
        boxShadow: "0 0 0 0.18rem #c0b4ff inset !important"
      } : {})
    }
  },
  indicatorsContainer: (styles, state) => ({
    ...styles,
    height: '60px',
    paddingRight: "3px"

  }),
  menuPortal: (styles, state) => ({
    ...styles,
    zIndex: 1000
  }),
  indicatorContainer: (styles, { menuIsOpen, isFocused }) => ({
    ...styles,
    padding: '0px !important',
    height: "50px",
    background: isFocused || menuIsOpen ? "#fff !important" : '#F2F2F2 !important',
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    display: "none",
  }),
  dropdownIndicator: (styles, { isFocused, menuIsOpen }) => ({
    ...styles,
    color: '#000 !important',
    background: isFocused || menuIsOpen ? "#fff !important" : '#F2F2F2 !important',
    borderRadius: '0px !important',
    padding: '0px !important',
    height: "30px",
    width: "30px",
    fontSize: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }),
  valueContainer: (styles, state) => ({
    ...styles,
    display: "flex",
    flexDirection: "row",
    overflowX: "hidden",
    paddingTop: '0px',
    paddingBottom: '0px',
    flexWrap: "nowrap",
  }),
  placeholder: (styles, state) => ({
    ...styles,
    color: '#362148 !important'
  }),
  multiValue: (styles, state) => ({
    ...styles,
    minWidth: "auto"
  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      color: isSelected ? '#fff !important' : '#000000a6 !important',
      padding: '4px 5px',
      alignItems: 'center',
      width: 'auto',
      marginBottom: '0px',
      fontSize: '18px',
      display: 'flex',
      lineHeight: '22px',
      backgroundColor: isSelected ? '#b8acf4 !important' : '#fff !important',
      "&:hover": {
        backgroundColor: (isSelected) ? '#b8acf4 !important' : '#b8acf480 !important',
      },
    };
  },
};
