export const getStringifiedCellContent = (listItems, property) => {
  return listItems.map((item) => item[property]).join(", ");
};

export const getMultiSelectInitialValues = (listItems, property) => {
  return listItems.map((item) => item[property]);
};

export const getFormattedSelectOptions = (
  list,
  displayKey,
  valueKey,
  tagsMode
) => {
  return list.length
    ? list.map((listItem) => ({
      title: displayKey ? listItem[displayKey] : listItem.title,
      value: valueKey
        ? tagsMode
          ? `${listItem[valueKey]}`
          : listItem[valueKey]
        : tagsMode
          ? `${listItem.id}`
          : listItem.id,
    }))
    : [];
};

export const getRiskRatingFormattedOptions = (list, displayKey) => {
  return list.length ? list.map((listItem, index) => ({
    title: displayKey ? listItem[displayKey] : listItem.title,
    value: listItem.order ? listItem.order : (list.length - index),
  }))
    : [];
}

export const getFormattedPopBoxOptions = (list, displayKey, valueKey) => {
  return list.length
    ? list.map((listItem) => ({
      label: displayKey ? listItem[displayKey] : listItem.title,
      value: valueKey ? listItem[valueKey] : listItem.id,
    }))
    : [];
};

export const getFormattedTypeaheadOptions = (list, displayKey, valueKey) => {
  return list.length
    ? list.map((listItem) => ({
      text: displayKey ? listItem[displayKey] : listItem.title,
      value: valueKey ? listItem[valueKey] : listItem.id,
    }))
    : [];
};

export const draggableSWMSItems = [
  { type: "tools", displayProperty: "name", label: "Tools", color: "" },
  { type: "ppe", displayProperty: "name", label: "PPE", color: "" },
  {
    type: "highRiskWork",
    displayProperty: "name",
    label: "High Risk",
    color: "",
  },
  {
    type: "licensesAndTraining",
    displayProperty: "title",
    label: "Licenses",
    color: "",
  },
  {
    type: "standards",
    displayProperty: "title",
    label: "Standards",
    color: "",
  },
];

export const numerateOptions = (list, reversed = false, prefix) => {
  return list.map((listItem, index) => ({
    ...listItem,
    title: `${prefix}${reversed ? list.length - index : index + 1}. ${listItem.title}`,
  }));
};

export const numerateV2Options = (list, reversed = false, prefix) => {
  return list.map((listItem, index) => ({
    ...listItem,
    label: `${prefix}${reversed ? list.length - index : index + 1}. ${listItem.title ? listItem.title : listItem.label}`,
  }));
};

export const calculateRisk = (riskValue) => {
  switch (true) {
    case riskValue > 0 && riskValue <= 3:
      return `${riskValue}. Very Low`;
    case riskValue > 3 && riskValue <= 6:
      return `${riskValue}. Moderate`;
    case riskValue > 6 && riskValue <= 10:
      return `${riskValue}. High`;
    case riskValue > 10 && riskValue <= 15:
      return `${riskValue}. Very High`;
    case riskValue > 15:
      return `${riskValue}. Extreme`;
    default:
      return "N/A";
  }
};

export const getFormattedReactSelectOptions = (list, displayKey, valueKey) => {
  return list.length
    ? list.map((listItem) => ({
      label: displayKey ? listItem[displayKey] : listItem.title,
      value: valueKey ? listItem[valueKey] : listItem.id,
    }))
    : [];
};

export const getNewRiskRatingValueByMap = {
  11: "VL1. Very Low",
  12: "VL2. Very Low",
  21: "VL3. Very Low",
  31: "LR4. Low Risk",
  22: "LR5. Low Risk",
  13: "LR6. Low Risk",
  41: "LR. Low Risk",
  51: "LR8. Low Risk",
  32: "LR9. Low Risk",
  23: "LR10. Low Risk",
  14: "LR11. Low Risk",
  15: "LR12. Low Risk",
  42: "MR13. Moderate Risk",
  52: "MR14. Moderate Risk",
  33: "MR15. Moderate Risk",
  24: "MR16. Moderate Risk",
  25: "MR17. Moderate Risk",
  43: "HR18. High Risk",
  53: "HR19. High Risk",
  34: "HR20. High Risk",
  44: "HR21. High Risk",
  35: "HR22. High Risk",
  45: "ER23. Extreme Risk",
  54: "ER24. Extreme Risk",
  55: "ER25. Extreme Risk",
};
