import React, { useEffect } from "react";
import { connect } from "react-redux";
import { formValueSelector, getFormInitialValues } from "redux-form";
import { Input } from "antd";
import plain from "redux-form/lib/structure/plain";

import styles from "../../JobStyle.module.css";

const JsaActivityRiskField = ({ input, likelihoodValue, consequenceValue, initialValue }) => {

  useEffect(() => {
    if (likelihoodValue && likelihoodValue.like_value && consequenceValue && consequenceValue.cons_value) {
      input.onChange(likelihoodValue.like_value * consequenceValue.cons_value);
    } else {
      input.onChange(null);
    }
  }, [likelihoodValue, consequenceValue])

  return (
    <div>
      <Input readOnly className={styles.TextField} value={input.value ? input.value : "N/A"} />
      <div style={{ height: "24px", fontSize: "12px", color: "orange" }}>
        {input.value && initialValue && initialValue !== input.value ?
          `Value is different than already saved value - ${initialValue}` : ""}
      </div>
    </div>
  )
}

export default connect((state, initialProps) => {
  return {
    likelihoodValue: (formValueSelector('add_jsa_activity'))(state, initialProps.likelihood),
    consequenceValue: (formValueSelector('add_jsa_activity'))(state, initialProps.consequence),
    initialValue: plain.getIn(getFormInitialValues('add_jsa_activity')(state), initialProps.input.name)
  }
})(JsaActivityRiskField);
