import React, { Component } from "react";
import { connect } from "react-redux";
import AllocationsCalendarContainer from "./AllocationsCalendarContainer/AllocationsCalendarContainer";
import AllocationActionContainer from "./AllocationActionContainer/AllocationActionContainer";
import { bindActionCreators } from "redux";
import * as allocationActions from "../../../../actions/v2/AllocationActions";
export class JobAllocationsContainer extends Component {

  componentWillUnmount = () => {
    this.props.actions.resetAction();
  }

  render() {
    const { currentAction } = this.props;
    return (
      <div>
        {currentAction ? (
          <AllocationActionContainer />
        ) : (
          <AllocationsCalendarContainer />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentAction: state.Allocations.currentAction,
  selectedJobDetails: state.JobCalendar.selectedJobDetails,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(allocationActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobAllocationsContainer);
