import { getStorage } from "../../../utils/common";
import {
  serializeAllocatedStaffList,
  serializeAllocatedTasks,
  serializeChemicalsForm,
  serializeJobSchedules,
  serializeTasksForm,
  serializeInsurancesForm,
} from "./serializers";

export const loadJobDocument = (state, actions, selectedJobDetails, organisations = []) => {
  const jobId = state ? state : getStorage("JOB_ID");

  const requests = [];

  let orgs = JSON.stringify(organisations && organisations.length ? organisations.filter(org => !org.isOrg).map(org => org.id) : [])

  requests.push(actions.getJobDocumentStaffList(orgs))
  requests.push(actions.getJobDocumentOrgCertificates(orgs, jobId, organisations));
  requests.push(actions.getJobDocumentDetails(jobId, selectedJobDetails.scopeId));

  Promise.all(requests)
    .then((res) => { })
    .catch((err) => { });
};

export const loadPostAllocateData = (
  jobDocumentDetails,
  allCertificates,
  additionalDocs,
  selectedAdditionalDocs,
  swmsDocument,
  jobDetails,
  chemicals,
  staffList,
  actions,
  change,
  organisations = [],
  isJobSwms = 0
) => {
  const {
    job_allocated_users: allocatedUsers = [],
    job_doc_orgs_certificates: selectedOrgCertificates = [],
  } = jobDocumentDetails;

  const allocatedData = {};

  if (staffList.service_agents && staffList.service_agents.length) {
    allocatedData.staff = serializeAllocatedStaffList(
      staffList,
      allocatedUsers
    );
  }

  allocatedData.tasksList = serializeAllocatedTasks(
    jobDetails,
    jobDocumentDetails
  );

  allocatedData.chemicalsList = [];

  allocatedData.insurancesList = [];

  const tasksCheckboxes = serializeTasksForm(allocatedData.tasksList);
  const chemicalsCheckboxes = serializeChemicalsForm(chemicals, jobDocumentDetails.job_docs_chemicals);
  const insuranceCheckboxes = serializeInsurancesForm(
    selectedOrgCertificates,
    allCertificates,
    additionalDocs,
    selectedAdditionalDocs,
    swmsDocument,
    isJobSwms ? jobDocumentDetails.swms_pdf_selected === 1 ? true : false : jobDocumentDetails.swms_forms_selected ? jobDocumentDetails.swms_forms_selected : [],
    organisations,
    isJobSwms
  );

  allocatedData.initialValues = {
    schedules: serializeJobSchedules(jobDocumentDetails.job_doc_schedules),
    ...tasksCheckboxes,
    ...chemicalsCheckboxes,
    ...insuranceCheckboxes,
    note: jobDocumentDetails.note
  };

  if (jobDetails.is_job_swms) {
    swmsDocument.forEach(doc => {
      change[`select_swms_document_${doc.id}`] = true
    })
  }

  allocatedUsers.forEach((user) => {
    if (user.selected_for_job_doc === 0) {
      change(`select_staff_${user.user_name}`, false);
    }
  });

  actions.setAllocatedData(
    allocatedData.staff,
    allocatedData.tasksList,
    allocatedData.chemicalsList,
    allocatedData.insurancesList,
    allocatedData.initialValues
  );
};
