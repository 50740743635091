import {
  JOB_DETAILS_URL,
  JOB_DETAILS,
  TASK_JOB_DETAILS,
  JOB_SHIFT_TIMESHEET_URL,
  GET_JOB_SHIFT_TIMESHEET_LIST,
  IS_GET_JOB_SHIFT_TIMESHEET_LIST,
  UPDATEJOB_SHIFT_URL,
  UPDATE_PURCHASE_ORDER,
  JOB_SHIFT_UPDATES
} from "../dataProvider/constant";
import { startSipnner, stopSipnner } from "../utils/spinner";
import { scopeAxiosInstance, scopeV3AxiosInstance } from "../dataProvider/axiosHelper";
import { Strings } from "../dataProvider/localize";

export const getJobDetails = (job_number) => (dispatch) => {
  startSipnner(dispatch);
  return scopeV3AxiosInstance
    .get(JOB_DETAILS_URL + "?job_number=" + job_number)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: JOB_DETAILS,
          payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const getTaskJobDetails = (job_number, role_id, organisation_type) => (
  dispatch
) => {
  startSipnner(dispatch);
  return scopeAxiosInstance
    .get(
      JOB_DETAILS_URL +
      "?job_id=" +
      job_number
    )
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: TASK_JOB_DETAILS,
          payload: res.data.data.job_details[0],
        });
        return Promise.resolve(res.data.data);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};


export const getJobShiftTimesheet = (job_id) => (dispatch) => {
  startSipnner(dispatch);
  dispatch({
    type: IS_GET_JOB_SHIFT_TIMESHEET_LIST,
    payload: true,
  });
  return scopeAxiosInstance
    .get(JOB_SHIFT_TIMESHEET_URL + "?job_id=" + job_id)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        let newSheet = res.data.data.job_timesheets && res.data.data.job_timesheets.length > 0 ?
          JSON.parse(JSON.stringify(res.data.data.job_timesheets)) : [];

        newSheet.map(sheet => {
          let shift_timesheet_notes = { shift_update_note: [], shift_update_image: [] }
          sheet.job_shift_timesheets_notes.forEach(data => {
            if (data.shift_update_note) {
              shift_timesheet_notes.shift_update_note.push(data.shift_update_note)
            }
            if (data.shift_update_image) {
              shift_timesheet_notes.shift_update_image.push(data.shift_update_image)
              // shift_timesheet_notes.shift_update_image.push('https://dummyimage.com/600x400/d145d1/2832b8&text=dsadsadsadad++ad')
            }
          })
          sheet['new_timesheet_notes'] = shift_timesheet_notes;
          return sheet;
        })
        dispatch({
          type: GET_JOB_SHIFT_TIMESHEET_LIST,
          payload: newSheet,
        });
        dispatch({
          type: IS_GET_JOB_SHIFT_TIMESHEET_LIST,
          payload: false,
        });
        return Promise.resolve(res.data.job_timesheets);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      dispatch({
        type: IS_GET_JOB_SHIFT_TIMESHEET_LIST,
        payload: false,
      });
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};


export const updateJobShift = (formData) => dispatch => {
  startSipnner(dispatch);
  return scopeAxiosInstance.put(UPDATEJOB_SHIFT_URL, formData)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve({ message: res.data.message, result: res.data.data })
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(error.response ?
        error.response.data.message
        : Strings.network_error)
    });
}


export const updatePurchaseOrder = (formData) => dispatch => {
  startSipnner(dispatch);

  return scopeAxiosInstance.put(UPDATE_PURCHASE_ORDER, formData)
    .then(async res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve({ message: res.data.message, result: res.data.data, status: res.data.status })
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response : Strings.network_error)
    });
}

export const getJobShiftUpdates = (query) => dispatch => {
  startSipnner(dispatch);
  const { job_id, start_date = null, end_date = null, job_schedule_shift_id = null } = query;
  let url = `${JOB_SHIFT_UPDATES}?job_id=${job_id}`
  if (start_date !== null && end_date !== null) {
    url += `&start_date=${start_date}&end_date=${end_date}`;
  }
  if (job_schedule_shift_id !== null) {
    url += `&job_schedule_shift_id=${job_schedule_shift_id}`;
  }
  return scopeAxiosInstance.get(url).then(async res => {
    if (res.data.status) {
      return Promise.resolve({ message: res.data.message, result: res.data.data, status: res.data.status })
    }
  }).catch(error => {
    return Promise.reject(error.response ? error.response : Strings.network_error)
  }).finally(() => {
    stopSipnner(dispatch);
  })
}