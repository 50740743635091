import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "antd";
import { Close } from "@mui/icons-material";

import styles from "../../JobStyle.module.css";

export default function PreviewDocument(props) {

  const [file, setFile] = useState(null);
  const [visible, setVisible] = useState(false);

  const previewableTypes = ["jpg", "png", "webp", "jpeg"];

  const isPreviewable = useMemo(() => {
    let res = "doc";
    if (props.file) {
      let nameSplit = props.file.split(".");
      let type = nameSplit[nameSplit.length - 1].toString().toLowerCase();
      if (previewableTypes.includes(type)) {
        res = "img";
      }
      if (type === "pdf") {
        res = "pdf";
      }
    }
    return res;
  }, [props.file])

  useEffect(() => {
    if (props.file) {
      setFile(props.file);
    }
  }, [props.file])

  useEffect(() => {
    setVisible(true);
  }, [])

  return (
    <div>
      <Modal
        className="cs-modal-v2"
        centered
        visible={visible}
        width="80vw"
        maskClosable={true}
        footer={null}
        destroyOnClose={true}
        onCancel={() => setVisible(false)}
        afterClose={() => props.onCancel()}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        closeIcon={<div className={styles.ModalCloseIcon}><Close /></div>}
      >
        <div style={{ height: "80vh" }}>
          {file ? (
            <div className="w-100 h-100">
              {isPreviewable === "pdf" ? (
                <embed type="application/pdf" src={file} style={{ height: '100%', width: '100%' }} />
              ) : isPreviewable === "img" ? (
                <div className="d-flex align-items-center justify-content-center">
                  <img src={file} alt="" style={{ maxHeight: "80vh", maxWidth: "80vw", objectFit: "scale-down" }} />
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center text-center w-100 h-100">
                  <div>
                    <div>
                      <h6 style={{ color: "red" }}>Unable to Preview the document.</h6>
                    </div>
                    <div>
                      <a rel="noopener noreferrer" href={file} target="_blank" download={file}>
                        <button className={styles.PrimaryButton}>Download Document</button>
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : ""}
        </div>
      </Modal>
    </div >
  )
}