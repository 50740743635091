const apiV1 = "/api/v1";
const apiV2 = "/api/v2";
const apiV3 = "/api/v3";

export const BASE_API_URL = `${process.env.REACT_APP_BASE_API_URL}${apiV1}`;
export const BASE_SCOPE_API_URL = `${process.env.REACT_APP_BASE_SCOPE_API_URL}${apiV1}`;

export const BASE_API_V2_URL = `${process.env.REACT_APP_BASE_API_URL}${apiV2}`;
export const BASE_SCOPE_API_V2 = `${process.env.REACT_APP_BASE_SCOPE_API_URL}${apiV2}`;

export const BASE_API_V3_URL = `${process.env.REACT_APP_BASE_API_URL}${apiV3}`;
export const BASE_SCOPE_API_V3 = `${process.env.REACT_APP_BASE_SCOPE_API_URL}${apiV3}`;

export const EWAY_CLIENT_KEY = process.env.REACT_APP_EWAY_CLIENT_KEY;
