import { SWMS_RISK_RATING_URL, SWMS_RISK_RATING_ORDER_URL } from "../../dataProvider/constant";
import { scopeApiInstance } from "../../dataProvider/axiosHelper";
import { Strings } from "../../dataProvider/localize";

export const getSwmsRiskRatings = (abortController, params = {},) => () => {
  return scopeApiInstance.get(
    SWMS_RISK_RATING_URL,
    {
      params: { ...params },
      ...abortController
    }
  )
    .then((res) => {
      if (res && res.data && res.data.data) {
        return Promise.resolve(res.data.data);
      } else {
        return Promise.reject(Strings.generic_error);
      }
    }).catch(error => {
      if (error) {
        return Promise.reject(
          error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
        );
      } else {
        return Promise.reject(null);
      }
    })
}

export const addUpdateRiskRating = (formData) => () => {
  return scopeApiInstance.post(SWMS_RISK_RATING_URL, formData).then(res => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject();
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const updateRiskRatingOrder = (formData) => () => {
  return scopeApiInstance.put(SWMS_RISK_RATING_ORDER_URL, formData).then(async (res) => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject()
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}
