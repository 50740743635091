import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Modal, Icon, notification } from 'antd'
import { Strings } from '../../dataProvider/localize';
import * as actions from '../../actions';
import { customInput } from '../common/custom-input';
import { validate } from '../../utils/validations';

import { goBack, handleFocus } from '../../utils/common'
import { removeLoginData } from '../../utils/sessions'
import { authRedirect } from '../../utils/common'
import { DeepTrim } from '../../utils/common';

import logo from "../../assets/images/fm-farm-logo.png";
import styles from "./Auth.module.css";
import { IconButton } from '@mui/material';

class Forgotpass extends React.Component {
	componentDidMount() {
		authRedirect(this.props.history);

	}
	onSubmit = async (formData) => {
		formData = await DeepTrim(formData);

		this.props.forgotPass(formData)
			.then((message) => {
				this.props.cleanErrorMsg()
				if (this.props.forgotPassStatus) {
					notification.success({
						message: Strings.success_title,
						description: message ? message : Strings.generic_error,
						// onOk: () => this.props.history.push('/reseturpass')
						onClick: () => { },
						className: 'ant-success'
					})
					this.props.history.push('/reseturpass')
				}
			})
			.catch((message) => {
				notification.error({
					message: Strings.error_title,
					description: message ? message : Strings.generic_error,
					onClick: () => { },
					className: 'ant-error'
				})
			});
	}

	handleGoBack = () => {
		removeLoginData();
		goBack(this.props);
	}

	render() {
		const { handleSubmit } = this.props;
		return (
			<div className="default-v2-container">
				<div className={styles.Background}>
					<div className="container-fluid vh-100">
						<div className="row h-100">
							<div className="col m-auto">
								<div className={`${styles.LoginCard} p-5 text-center mx-auto`}>
									<img src={logo} alt="FM Farm" style={{ maxHeight: "70px", width: "auto" }} />
									<h2 className={styles.LoginHeading}>Forgot Password</h2>
									<form onSubmit={handleSubmit(this.onSubmit)}>
										<fieldset className="userTxtbox fldset-box">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="24" height="24" className="svg-ico-f">
												<path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
											</svg>
											<Field
												name="user_name"
												type="text"
												id="user_name"
												className={styles.InputFieldV2}
												placeholder={Strings.forgot_pass_placeholder_enter_username}
												component={customInput} />
										</fieldset>

										{
											this.props.errorMessage
												? <div className="alert alert-danger">
													{this.props.errorMessage}
												</div>
												: null
										}
										<button type="submit" className={styles.SigninBtn} >{Strings.forgot_pass_btn_send_msg}</button>
									</form>
									<div className="pre-login-left-arrow mt-2">
										<IconButton style={{ height: "auto" }} onClick={this.handleGoBack}>
											<svg xmlns="http://www.w3.org/2000/svg" width="14" fill="#000" viewBox="0 0 448 512">
												<path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
											</svg>
										</IconButton>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		errorMessage: state.auth.errorMessage,
		forgotPassStatus: state.auth.forgotPassStatus
	}
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'forgotPass', validate,
		onSubmitFail: (errors, dispatch, sub, props) => {
			handleFocus(errors, "#");
		}
	})
)(Forgotpass)