import React, { useState, useEffect } from "react";
import { reduxForm, Field } from "redux-form";
import { notification, Icon } from "antd";
import { customInput } from "../../../../common/custom-input";
import { useSelector, useDispatch } from "react-redux";
import { addUpdateJobTask, getJobTasks } from "../../../../../actions/v2/JobActions";
import { Strings } from "../../../../../dataProvider/localize"
import { validateNewTaskSave } from "../validate"
import styles from "../../JobStyle.module.css";

const AddNewTask = (props) => {

  const dispatch = useDispatch();
  const tasks = useSelector(state => state.Job.tasks);
  const jobHeader = useSelector(state => state.Job.jobHeader)
  const [isEditing, setIsEditing] = useState(false);
  const [editingTask, setEditingTask] = useState({});

  const getNextTaskNum = () => {
    let taskNames = tasks.map(task => task.task_label && task.task_label);
    let largest = 0;
    taskNames.forEach(label => {
      let taskId = label.match(/^(T|t)[0-9]{1,3}/g)
      let idNum = 0;
      if (taskId && taskId.length) {
        idNum = parseInt(taskId[0].substring(1), 10);
      }
      if (!isNaN(idNum) && idNum > largest) {
        largest = idNum;
      }
    })
    return largest + 1;
  }

  useEffect(() => {
    if (props.isEditing) {
      setIsEditing(props.isEditing)
    }
    if (props.task) {
      setEditingTask(props.task)
    }
  }, [props.isEditing, props.task])

  const onSubmit = (values) => {
    let data = { ...values };
    if (!isEditing) {
      let newTaskId = getNextTaskNum()
      let newLabel = `T${newTaskId}${props.job && props.job.job_label ? props.job.job_label : ''}`;
      data.task_label = newLabel;
      data.job_id = jobHeader.id;
      data.task_total = parseFloat(values.task_total)
    } else {
      data.task_label = editingTask.task_label
      data.id = editingTask.id
      data.job_id = editingTask.job_id
      data.task_invoice_id = editingTask.task_invoice_id ? editingTask.task_invoice_id : null
      data.job_board_task_state = editingTask.job_board_task_state ? editingTask.job_board_task_state : null
    }
    dispatch(addUpdateJobTask(data)).then((message) => {
      dispatch(getJobTasks(jobHeader.id))
      props.onClose();
      notification.success({
        message: Strings.success_title,
        description: message ? message : Strings.generic_error,
        className: 'ant-success'
      });
    }).catch(err => {
      notification.error({
        message: Strings.error_title,
        description: err ? err : Strings.generic_error,
        className: 'ant-error'
      })
    })
  }

  return (
    <form onSubmit={props.handleSubmit(onSubmit)}>
      <div className={`${styles.TaskHeader} px-1`}>
        <div className={`${!isEditing ? 'pl-2 ' : ''}`}>
          <div className={`container-fluid ${isEditing ? 'py-0' : 'py-2'}`}>
            <div className="row align-items-center justify-content-end">
              <div className="col-11 px-1">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-3 pl-1">
                      <div className={`${styles.CommonLabel}`}>Task Name</div>
                      <Field
                        name="task_name"
                        component={customInput}
                        className={styles.SingleLine}
                        autoComplete="off"
                        autoFocus />
                    </div>
                    <div className="col-3">
                      <div className={`${styles.CommonLabel}`}>Area</div>
                      <Field
                        name="area_name"
                        component={customInput}
                        autoComplete="off"
                        className={styles.SingleLine} />
                    </div>
                    <div className="col-3">
                      <div className={`${styles.CommonLabel}`}>Note</div>
                      <Field
                        name="note"
                        component={customInput}
                        autoComplete="off"
                        className={styles.SingleLine} />
                    </div>
                    <div className="col-3 pr-1">
                      <div className={`${styles.CommonLabel}`}>Task Total</div>
                      <Field
                        name="task_total"
                        component={customInput}
                        autoComplete="off"
                        type="number"
                        className={styles.SingleLine} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-1 align-self-end">
                <div className="d-flex align-items-center">
                  <button type="submit" className={`${styles.PrimaryButton} ${styles.SmallPrimaryButton}`} disabled={props.pristine} style={{ lineHeight: "initial" }}>Save</button>
                  {isEditing && <Icon className={`${styles.iconButton} ${styles.redButton}`} type="close" onClick={(e) => { e.stopPropagation(); props.onClose() }} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'newTaskForm',
  validate: validateNewTaskSave
})(AddNewTask)
