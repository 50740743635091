import React, { useRef, useEffect, useState } from "react";
import styles from "../../JobStyle.module.css";
import { getNotesDetails } from "../../../../../actions/v2/JobAllocatonActions";
import { useDispatch } from "react-redux";
import { notification, Pagination } from "antd";
import { LinearProgress, Typography } from "@mui/material";
import { Strings } from "../../../../../dataProvider/localize";
import NotesForm from "./NotesForm";

export default function NotesHistory(props) {

  const dispatch = useDispatch();
  const controller = useRef();
  const moment = require("moment");

  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fieldCount, setFieldCount] = useState(0);
  const [page, setPage] = useState(1);

  const fetchNotes = (pageNo = 1) => {
    setLoading(true);
    if (page !== pageNo) {
      setPage(pageNo)
    }
    controller.current = new AbortController();
    dispatch(getNotesDetails(pageNo, { job_id: props.jobId, limit: 5 }, { signal: controller.current.signal })).then(res => {
      setNotes(res.rows);
      setFieldCount(res.count);
      setLoading(false);
    }).catch((err) => {
      if (err) {
        setLoading(false);
        notification.error({
          message: Strings.error_title,
          description: "There was an error while fetching Notes.",
          className: "ant-error"
        })
      }
    })
  }

  useEffect(() => {
    fetchNotes();
    return () => {
      if (controller.current) {
        controller.current.abort()
      }
    }
  }, []);

  return (
    <div className={styles.blankContainer}>
      <div className="container-fluid pr-0" style={{ color: "#000" }}>
        <div className="row">
          <div className="col">
            <div className={`${styles.SectionHeading} px-2`}>Add Notes</div>
            <div className={styles.card}>
              <div className="container-fluid px-0">
                <div className="row">
                  <div className="col-12">
                    <NotesForm
                      jobId={props.jobId}
                      loading={loading}
                      setLoading={setLoading}
                      reloadNotes={() => fetchNotes(page)}
                    />
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <hr />
                    <div className={styles.Label}>Notes History</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div style={{ height: "4px" }}>
                      {loading ? (
                        <LinearProgress />
                      ) : ""}
                    </div>
                    <table className="table-sm cs-table cs-table-v2" style={{ tableLayout: "fixed", width: "100%" }}>
                      <thead>
                        <tr>
                          <th width="12%">Task Id</th>
                          <th width="43%">Notes</th>
                          <th width="25%">Manager</th>
                          <th width="20%">Date / Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {notes.map(note => (
                          <tr key={note.id}>
                            <td title={note.task_name}>
                              <Typography className={styles.TableText} noWrap>
                                {note.task_name}
                              </Typography>
                            </td>
                            <td title={note.notes_desc}>
                              <Typography className={styles.TableText} noWrap>
                                {note.notes_desc}
                              </Typography>
                            </td>
                            <td title={note.manager_name}>
                              <Typography className={styles.TableText} noWrap>
                                {note.manager_name}
                              </Typography>
                            </td>
                            <td title={note.created_at ? moment.utc(note.created_at).format("DD/MM/YY HH:mm") : ""}>
                              <Typography className={styles.TableText} noWrap>
                                {note.created_at ? moment.utc(note.created_at).format("DD/MM/YY HH:mm") : ""}
                              </Typography>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-flex justify-content-end pt-1">
                    <Pagination
                      className="cs-pagination-v2"
                      total={fieldCount}
                      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Notes`}
                      onChange={(pageNo) => { fetchNotes(pageNo) }}
                      pageSize={5}
                      current={page}
                      defaultCurrent={1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}