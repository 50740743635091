import React, { useEffect, useState, useMemo } from "react";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { connect, useDispatch } from "react-redux";
import moment from "moment";

import { CustomTimePicker } from "../../../common/customTimePicker";
import { CustomDatepicker } from "../../../common/customDatepicker";

import v2Styles from "../../v2Styles.module.css";
import "../../v2Styles.css";
import "../../Calendar/CalendarStyles.css";
import { customInput } from "../../../common/custom-input";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import TableView from "../../views/table/TableView";
import validator from "validator";
import { notification } from "antd";
import { Strings } from "../../../../dataProvider/localize";
import { createFrequencyShifts } from "../../../../actions/v2/JobActions";

export default function FrequencyShiftsModal({ frequency, onClose, task }) {

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [shifts, setShifts] = useState([]);
  const [initialValues, setInitialValues] = useState({
    start_date: new moment(frequency.end_date).hours(8).minutes(0),
    end_date: new moment(frequency.end_date).hours(8).minutes(0),
    staffs: 1,
    supervisors: 0
  });

  useEffect(() => {
    if (frequency && frequency.extend_freq_details && frequency.extend_freq_details.length) {
      setShifts(frequency.extend_freq_details.map((x, i) => ({
        id: i + 1,
        start_date: x.shift_start_time,
        end_date: x.shift_finish_time,
        staffs: x.no_of_staffs,
        supervisors: x.no_of_supervisors
      })))
    }
  }, [frequency])

  const isDisabled = useMemo(() => frequency && frequency.extend_freq_details ? true : false);

  const loadDefaultShift = () => {
    setInitialValues({
      start_date: new moment(frequency.end_date).hours(8).minutes(0),
      end_date: new moment(frequency.end_date).hours(8).minutes(0),
      staffs: 1,
      supervisors: 0
    })
  }

  const onShiftEdit = (shift) => {
    setInitialValues({
      ...shift,
      start_date: moment(shift.start_date, "YYYY-MM-DD HH:mm"),
      end_date: moment(shift.end_date, "YYYY-MM-DD HH:mm"),
      duration: moment.utc(shift.end_date, "YYYY-MM-DD HH:mm").subtract(moment.utc(shift.start_date, "YYYY-MM-DD HH:mm")).format("HH:mm")
    })
  }

  const onDeleteShift = (shift) => {
    setShifts(s => s.filter(x => x.id !== shift.id));
  }

  const onCreateShifts = () => {
    if (!isDisabled && shifts.length > 0) {
      setLoading(true);
      const formData = {
        job_id: frequency.job_id,
        freq_id: frequency.quote_task_id,
        task_id: task.task_id,
        shifts: [...shifts.map(s => ({
          no_of_supervisors: s.supervisors,
          no_of_staffs: s.staffs,
          shift_start_time: s.start_date,
          shift_finish_time: s.end_date
        }))]
      }
      dispatch(createFrequencyShifts(formData)).then((message) => {
        setLoading(false);
        notification.success({
          message: Strings.success_title,
          description: message,
          className: "ant-success"
        })
        onClose();
      }).catch(err => {
        if (err) {
          setLoading(false)
          notification.error({
            message: Strings.error_title,
            description: err ? err : Strings.generic_error,
            className: "ant-error"
          })
        }
      })
    }
  }

  return (
    <div className="v2-modal-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={v2Styles.ModalHeading}>Create Shifts</div>
            <div>
              <hr />
            </div>
          </div>
        </div>
        <ShiftCreationForm
          isDisabled={isDisabled || loading}
          initialValues={initialValues}
          loadDefault={loadDefaultShift}
          shifts={shifts}
          frequency={frequency}
          setShifts={setShifts}
        />
        <ShiftsTable onDelete={onDeleteShift} onEdit={onShiftEdit} shifts={shifts} isDisabled={isDisabled} loading={loading}></ShiftsTable>

        {!isDisabled ? (
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-end pt-3" style={{ gap: "5px" }}>
              {loading ? <CircularProgress size={20} /> : null}
              <button type="button" disabled={isDisabled} onClick={onClose} className={v2Styles.OutlinedButton}>Cancel</button>
              <button type="button" onClick={onCreateShifts} disabled={isDisabled || loading || !shifts.length} className={v2Styles.PrimaryButton}>Create Shifts</button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

let ShiftCreationForm = ({ frequency, shifts, setShifts, change, isDisabled, handleSubmit, duration, pristine, startDate, reset, loadDefault, initialValues }) => {

  const onCancel = () => {
    reset();
    loadDefault();
  }

  const onSubmit = (values) => {
    const shiftData = {
      start_date: moment(values.start_date).format("YYYY-MM-DD HH:mm"),
      end_date: moment(values.end_date).format("YYYY-MM-DD HH:mm"),
      supervisors: values.supervisors ? values.supervisors : 0,
      staffs: values.staffs,
      ...(initialValues.id ? { id: initialValues.id } : { id: shifts.length + 1 })
    }
    if (initialValues.id) {
      setShifts(s => s.map(x => x.id === shiftData.id ? { ...shiftData } : { ...x }))
      onCancel();
    } else {
      setShifts(s => [...s, shiftData]);
      onCancel();
    }
  }

  useEffect(() => {
    if (duration && startDate) {
      let durationVal = new moment(duration);
      let endDate = moment(startDate).add(durationVal.hour(), "hours").add(durationVal.minutes(), "minutes");
      change("end_date", moment(endDate));
    }
  }, [duration, startDate]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-3 pt-2">
          <div className={v2Styles.Label}>Start Date</div>
          <Field
            name="start_date"
            component={CustomDatepicker}
            disabled={isDisabled}
            disabledDate={(v) => moment(v).isAfter(moment(frequency.end_date).endOf("day")) || moment(v).isBefore(moment(frequency.start_date).startOf("day"))}
            allowClear={false}
            className="calendar-v2-datepicker w-100"
            wrapperClassName="cs-datepicker-wrapper"
          />
        </div>
        <div className="col-3 pt-2">
          <div className={v2Styles.Label}>Start Time</div>
          <Field
            name="start_date"
            component={CustomTimePicker}
            className="calendar-v2-time-picker w-100"
            disabled={isDisabled}
            dropdownClassName="cs-datepicker-dropdown"
            otherProps={{
              format: "hh:mm A",
              allowClear: false
            }}
          />
        </div>
        <div className="col-3 pt-2">
          <div className={v2Styles.Label}>Duration ( HH:MM )</div>
          <Field
            name="duration"
            component={CustomTimePicker}
            className="calendar-v2-time-picker w-100"
            dropdownClassName="cs-datepicker-dropdown"
            disabled={isDisabled}
            otherProps={{
              format: "HH:mm",
              allowClear: false
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-3 pt-2">
          <div className={v2Styles.Label}>End Date</div>
          <Field
            name="end_date"
            component={CustomDatepicker}
            disabled
            className="calendar-v2-datepicker w-100"
            wrapperClassName="cs-datepicker-wrapper"
          />
        </div>
        <div className="col-3 pt-2">
          <div className={v2Styles.Label}>End Time</div>
          <Field
            name="end_date"
            component={CustomTimePicker}
            className="calendar-v2-time-picker w-100"
            disabled
            dropdownClassName="cs-datepicker-dropdown" allowClear
            otherProps={{
              format: "hh:mm A",
            }}
          />
        </div>
        <div className="col-3 pt-2">
          <div className={v2Styles.Label}>Supervisor</div>
          <Field
            component={customInput}
            className={v2Styles.TextField}
            type="number"
            max="999"
            min="0"
            disabled={isDisabled}
            name="supervisors" />
        </div>
        <div className="col-3 pt-2">
          <div className={v2Styles.Label}>Staff</div>
          <Field
            component={customInput}
            className={v2Styles.TextField}
            type="number"
            max="999"
            min="1"
            disabled={isDisabled}
            name="staffs" />
        </div>
        <div className="col-12 pt-3 d-flex align-items-center justify-content-end" style={{ gap: "10px" }}>
          <button type="button" disabled={isDisabled} onClick={onCancel} className={v2Styles.OutlinedButton}>Cancel</button>
          <button type="submit" disabled={pristine || isDisabled} className={v2Styles.PrimaryButton}>{initialValues && initialValues.id ? "Update Shift" : "Add Shift"}</button>
        </div>
        <div className="col-12 pt-2">
          <hr />
        </div>
      </div>
    </form>
  )
}


const validate = (values, props) => {
  const errors = {};
  if (!values.start_date) {
    errors.start_date = "Required";
  } else {
    if (props.frequency && props.frequency.end_date && props.frequency.start_date) {
      if (moment(values.start_date).isBefore(props.frequency.start_date, "day") || moment(values.start_date).isAfter(props.frequency.end_date, "day")) {
        errors.start_date = "Invalid Start Date / Time";
      }
    }
  }
  if (!values.duration || (values.duration && moment(values.duration).format("HH:mm") === "00:00")) {
    errors.duration = "Required";
  }
  if (!values.staffs) {
    errors.staffs = "Required";
  } else {
    if (isNaN(values.staffs) || !validator.isNumeric(values.staffs.toString(), { no_symbols: true }) || (parseInt(values.staffs) < 1 || parseInt(values.staffs) > 999)) {
      errors.staffs = "Invalid Value"
    }
  }
  if (values.supervisors && (isNaN(values.supervisors) || !validator.isNumeric(values.supervisors.toString(), { no_symbols: true }) || (parseInt(values.supervisors) < 0 || parseInt(values.supervisors) > 99))) {
    errors.supervisors = "Invalid Value"
  }
  return errors;
}

ShiftCreationForm = reduxForm({
  form: "shift_creation_form",
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate: validate
})(ShiftCreationForm);

ShiftCreationForm = connect((state) => ({
  startDate: (formValueSelector("shift_creation_form"))(state, "start_date"),
  duration: (formValueSelector("shift_creation_form"))(state, "duration")
}))(ShiftCreationForm)

const ShiftsTable = ({ shifts, isDisabled, onEdit, onDelete, loading }) => {

  const customView = (c, v, rowData) => {

    return (
      <div className="d-flex align-items-center justify-content-center">
        <Tooltip title="Edit Shift">
          <span>
            <IconButton disabled={loading} className="px-1" sx={{ height: "auto", color: "#90bf00", fontSize: "14px" }} size="small" onClick={() => onEdit(rowData)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="1em">
                <path fill="currentColor" d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
              </svg>
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Delete Shift">
          <span>
            <IconButton disabled={loading} sx={{ height: "auto", color: "#ff0000", fontSize: "14px" }} size="small" onClick={() => onDelete(rowData)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height="1em" >
                <path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
              </svg>
            </IconButton>
          </span>
        </Tooltip>
      </div>
    )
  }
  const tableConfig = {
    rowKey: "id",
    columns: [
      { columnName: "Shift Start", keyName: "start_date", width: "10%", getValue: (v) => v ? moment.utc(v).format("DD/MM/YYYY HH:mm A") : "" },
      { columnName: "Shift End", keyName: "end_date", width: "10%", getValue: (v) => v ? moment.utc(v).format("DD/MM/YYYY HH:mm A") : "" },
      { columnName: "Supervisor", keyName: "supervisors", width: "10%" },
      { columnName: "Staffs", keyName: "staffs", width: "10%" },
      ...(!isDisabled ? [{ columnName: "Actions", keyName: "", width: "10%", customView: customView, columnClassName: "text-center" }] : []),
    ]
  }

  return (
    <div className="row">
      <div className="col-12">
        <TableView rowData={shifts} dataTable={tableConfig} parentLoading={false} />

      </div>
    </div>
  )
}