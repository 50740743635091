import React, { useEffect, useState, useRef } from "react";
import { Field, FieldArray, reduxForm } from "redux-form";
import { notification } from "antd";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";

import { Strings } from "../../../../../dataProvider/localize";
import { customInput } from "../../../../common/custom-input";
import { customTextarea } from "../../../../common/customTextarea";
import { getLikelihoods } from "../../../../../actions/v2/SWMSLikelihoodActions";
import { getConsequences } from "../../../../../actions/v2/SWMSConsequencesActions";
import validate from "./JsaActivityValidate";

import styles from "../../JobStyle.module.css";
import JsaActivityHazardSection from "./JsaActivityHazardSection";

const AddJsaActivity = ({ onClose, handleSubmit, onNewAdd }) => {

  const dispatch = useDispatch();
  const controller = useRef();

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [likelihoods, setLikelihoods] = useState([]);
  const [consequences, setConsequences] = useState([]);

  const getMethodNames = () => {
    setLoadingData(true);
    controller.current = new AbortController();
    Promise.all([
      dispatch(getLikelihoods({ signal: controller.current.signal }, { active: 1 })),
      dispatch(getConsequences({ signal: controller.current.signal }, { active: 1 }))
    ]).then(res => {
      setLikelihoods(res[0].map(x => ({ label: x.name, value: x.id, like_value: x.value })));
      setConsequences(res[1].map(x => ({ label: x.name, value: x.id, cons_value: x.value })));
      setLoadingData(false);
    }).catch((err) => {
      if (err) {
        setLoadingData(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : "There was an error while fetching Methods Data.",
          className: "ant-error"
        })
        onClose();
      }
    })
  }

  useEffect(() => {
    getMethodNames();
    return () => {
      if (controller.current) {
        controller.current.abort()
      }
    }
  }, [])

  const onSubmit = (values) => {
    setLoading(true);
    let stamp = new Date().getTime();
    let newAct = {
      activity_id: stamp,
      activity_name: values.activity_name,
      description: values.description,
      activity_order: stamp,
      fm_swms_activities_hazards: values.activity_hazards.map((haz, index1) => ({
        hazard_id: `${stamp}_haz_${index1}`,
        hazard_name: haz.hazard_name,
        activity_hazard_order: index1 + 1,
        fm_swms_hazards_consequences: haz.consequences.map((cons, index2) => ({
          consequence_id: `${stamp}_haz_${index1}_cons_${index2}`,
          consequences_name: cons.consequences_name,
          control_measures: cons.control_measures,
          initial_risk: cons.initialRisk,
          residual_risk: cons.residualRisk,
          action_by: cons.action_by ? cons.action_by : "",
          consequences_order: index2 + 1
        }))
      }))
    }
    onNewAdd(newAct);
  }

  return (
    <div className="swms-masters-modal-container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className={styles.ModalHeading}>Add SWMS Activity</div>
              <div className="py-3">
                <hr />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div>
                <div className={styles.Label}>Activity</div>
                <Field
                  name="activity_name"
                  disabled={loading}
                  autoComplete="off"
                  autoFocus
                  className={styles.TextField}
                  component={customInput} />
              </div>
            </div>
            <div className="col-8">
              <div>
                <div className={styles.Label}>Description</div>
                <Field
                  name="description"
                  className={styles.TextArea}
                  component={customTextarea}
                  disabled={loading}
                  rows={2}
                />
              </div>
            </div>
            <div className="col-12">
              <FieldArray
                name="activity_hazards"
                component={JsaActivityHazardSection}
                likelihoods={likelihoods}
                consequences={consequences}
                loadingData={loadingData}
                loading={loading}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-right pt-3">
              <div style={{ display: "flex", alignItems: "center", justifyContent: "end", columnGap: "10px" }}>
                {loading ? (
                  <CircularProgress size={20} />) : ""}
                <button type="button" className={`${styles.OutlinedButton}`} onClick={onClose} style={{ padding: "0px 5px" }}>
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleSubmit(onSubmit)}
                  disabled={loading}
                  className={styles.PrimaryButton}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form >
    </div >
  )
}

export default reduxForm({
  enableReinitialize: true,
  destroyOnUnmount: true,
  initialValues: {
    activity_name: "",
    activity_hazards: [{
      consequences: [{}]
    }]
  },
  form: "add_jsa_activity",
  validate
})(AddJsaActivity);