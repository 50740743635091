import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { notification } from "antd";
import { useDispatch } from "react-redux";
import { Field, reduxForm } from "redux-form";
import moment from "moment";

import { CustomDatepicker } from "../../../common/customDatepicker";
import { customInput } from "../../../common/custom-input";
import { extendFrequency, getJobHeader, getJobTasks } from "../../../../actions/v2/JobActions";
import { Strings } from "../../../../dataProvider/localize";

import v2Styles from "../../v2Styles.module.css";
import "../../v2Styles.css";

const validate = (values) => {
  let errors = {};
  if (!values.end_date) {
    errors.end_date = "Required"
  }
  return errors;
}

const ExtendFrequencyModal = ({ frequency, task, selectedJobDetails, getNextFrequency, handleSubmit, onClose, change, setSelectedTask }) => {

  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const nextFreq = getNextFrequency(frequency);
    if (nextFreq) {
      if (moment(nextFreq.start_date).startOf("day").diff(moment(frequency.end_date).endOf("day"), "day") < 1) {
        change("end_date", null);
      }
      setEndDate(nextFreq.start_date);
    }
  }, [])

  const onSubmit = (values) => {
    setLoading(true);
    const formData = {
      job_id: frequency.job_id,
      task_id: task.task_id,
      freq_id: frequency.quote_task_id,
      task_end_date: frequency.task_end_date,
      end_date: moment(values.end_date).format("YYYY-MM-DD"),
      job_end_date: moment(selectedJobDetails.end).format("YYYY-MM-DD")
    }
    dispatch(extendFrequency(formData)).then((message) => {
      Promise.all([dispatch(getJobTasks(frequency.job_id)), dispatch(getJobHeader(frequency.job_id))]).then(res => {
        if (res[0] && res[0].job_board_details && res[0].job_board_details.task_level_data) {
          const selected = res[0].job_board_details.task_level_data.find(x => x.task_id === task.task_id);
          if (selected) {
            setSelectedTask({ ...selected, quote_task_id: selected.task_id, task_number: selected.task_label });
          }
        }
      })
        .catch(err => {
          notification.error({
            message: Strings.error_title,
            description: err ? err : Strings.generic_error,
            className: 'ant-error'
          })
        })
      setLoading(false);
      notification.success({
        message: Strings.success_title,
        description: message,
        className: "ant-success"
      })
      onClose();
    }).catch(err => {
      if (err) {
        setLoading(false)
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: "ant-error"
        })
      }
    })
  }

  return (
    <div className="v2-modal-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={v2Styles.ModalHeading}>Extend Frequency</div>
            <div>
              <hr />
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-4">
              <div className={v2Styles.Label}>Start Date</div>
              <Field
                name="start_date"
                component={CustomDatepicker}
                disabled
                className="calendar-v2-datepicker w-100"
                wrapperClassName="cs-datepicker-wrapper"
              />
            </div>
            <div className="col-4">
              <div className={v2Styles.Label}>End Date</div>
              <Field
                name="end_date"
                component={CustomDatepicker}
                disabled={loading}
                disabledDate={(v) => endDate ? moment(v).isBefore(moment(frequency.end_date).endOf("day")) || moment(v).isAfter(moment(endDate).startOf("day")) : moment(v).isBefore(moment(frequency.end_date).endOf("day"))}
                className="calendar-v2-datepicker w-100"
                wrapperClassName="cs-datepicker-wrapper"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4 pt-2">
              <div className={v2Styles.Label}>Frequency Type</div>
              <Field
                component={customInput}
                name="freq_type"
                disabled
                className={v2Styles.TextField}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 pt-3">
              <div className="d-flex align-items-center justify-content-end" style={{ gap: "5px" }}>
                {loading ? <CircularProgress size={20} /> : ""}
                <button type="button" className={`${v2Styles.OutlinedButton}`} onClick={() => onClose()}>
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className={v2Styles.PrimaryButton}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default reduxForm({
  enableReinitialize: true,
  validate: validate
})(ExtendFrequencyModal);