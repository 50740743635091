import {
  GET_EMAIL_CONTENT_URL,
  // JOB_TASK_SIGNOFF_TASKS_URL,
  JOB_SIGNOFF_SELECTED_TASKS_URL,
  JOB_SIGNOFF_EMAIL_URL,
  JOB_SIGNOFF_HISTORY_URL,
  JOB_SIGNOFF_FEEDBACK_URL,
  GET_JOB_SIGNOFF_TASKS_URL
} from "../../dataProvider/constant";
import { axiosInstance, scopeApiInstance, scopeV2AxiosInstance } from "../../dataProvider/axiosHelper";
import { Strings } from "../../dataProvider/localize";

export const getTaskSignOffEmailContent = () => dispatch => {
  let url = GET_EMAIL_CONTENT_URL + "?slug=task_signoff_template";
  return axiosInstance.get(url).then((res) => {
    return Promise.resolve(res.data.data);
  }).catch((error) => {
    return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
  });
}

export const getSignOffTasks = (jobId) => dispatch => {
  let url = GET_JOB_SIGNOFF_TASKS_URL;
  return scopeApiInstance.get(url, { params: { job_id: jobId } }).then((res) => {
    let data = res.data.data;
    return Promise.resolve(data);
  }).catch((error) => {
    return Promise.reject(error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error)
  })
}

export const getSignOffEmailCode = (formData) => dispatch => {
  let url = JOB_SIGNOFF_SELECTED_TASKS_URL;
  return scopeV2AxiosInstance.post(url, formData).then(res => {
    let data = res.data.data;
    return Promise.resolve(data);
  }).catch(error => {
    return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
  })
}

export const sendSignOffEmail = (formData) => dispatch => {
  let url = JOB_SIGNOFF_EMAIL_URL;
  return scopeV2AxiosInstance.post(url, formData).then(res => {
    let data = res.data.data;
    return Promise.resolve(data);
  }).catch(error => {
    return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
  })
}

export const getSignOffHistory = (jobId, page = 1) => dispatch => {
  let url = JOB_SIGNOFF_HISTORY_URL + `?job_id=${jobId}&page_no=${page}`;
  return scopeV2AxiosInstance.get(url).then(res => {
    let data = res.data.data;
    return Promise.resolve(data);
  }).catch(error => {
    return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
  })
}

export const getSignOffFeedback = (jobId, page = 1) => dispatch => {
  let url = JOB_SIGNOFF_FEEDBACK_URL + `?job_id=${jobId}&page_no=${page}`;
  return scopeV2AxiosInstance.get(url).then(res => {
    let data = res.data.data;
    return Promise.resolve(data);
  }).catch(error => {
    return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
  })
}