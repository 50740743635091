import React from "react";
import { Field, FieldArray } from "redux-form";
import { IconButton } from "@mui/material";
import { customInput } from "../../../../../components/common/custom-input";
import styles from "../../JobStyle.module.css";

const ContactsField = ({ fields, loading }) => (
  <div className="">
    {fields.map((member, index) => (
      <div key={index} className="">
        <div className="container-fluid mb-2 px-0">
          <div className="row align-center">
            <div className="col-3">
              <div>
                <div className={styles.Label}>Name</div>
                <Field name={`${member}.contact_name`} component={customInput} className={styles.TextField} disabled={loading} />
              </div>
            </div>
            <div className="col-3">
              <div>
                <div className={styles.Label}>Role</div>
                <Field name={`${member}.contact_role`} component={customInput} className={styles.TextField} disabled={loading} />
              </div>
            </div>
            <div className="col-3">
              <div>
                <div className={styles.Label}>Contact Number</div>
                <Field name={`${member}.contact_number`} component={customInput} className={styles.TextField} disabled={loading} />
              </div>
            </div>
            <div className="col-3 d-flex align-items-center justify-end">
              <IconButton disabled={loading} className="p-0" style={{ height: "auto" }} onClick={() => fields.remove(index)}>
                <svg xmlns="http://www.w3.org/2000/svg" className={styles.MinusIcon} viewBox="0 0 448 512">
                  <path fill="currentColor" d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                </svg>
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    ))}
    <button type="button" className={`${styles.PrimaryButton} my-2`} disabled={loading} onClick={() => fields.push({})}>
      Add Contact
    </button>
  </div>
)

export default function KeyContactsSection({ loading }) {
  return (
    <div className="m-1 mb-2">
      <div className="contianer-fluid px-0">
        <div className="row">
          <div className="col-12">
            <FieldArray name="contacts" loading={loading} component={ContactsField} />
          </div>
        </div>
      </div>
    </div>
  )
}