import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import { CircularProgress } from "@mui/material";

import { getEmailDate, getStorage } from "../../../../utils/common";
import { Strings } from "../../../../dataProvider/localize";
import { getFirstName, getFormattedPhone } from "../../../../utils/common";
import { ADMIN_DETAILS } from "../../../../dataProvider/constant";
import { SLUG_JOB_REPORT } from "../../../../dataProvider/constant";
import { getEmailTemplateV2 } from "../../../../actions/v2/EmailTemplateActions";
import { emailJobReport } from "../../../../actions/v2/JobReportActions";
import EmailJobReportForm from "./EmailJobReportForm";

export default function EmailJobReport({ jobReports, documents, isSignOff, onClose, job }) {

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [emailData, setEmailData] = useState({ body: "" });
  const [noReplyData, setNoReplyData] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const getPdfFLink = () => {
    const reportIds = jobReports && jobReports.map(reportItem => reportItem.id);
    const docIds = documents && documents.map(documentItem => documentItem.id);
    return `${process.env.REACT_APP_CLIENT_APP_URL ? process.env.REACT_APP_CLIENT_APP_URL : window.location.origin}/job_report_preview?job_id=${job.job_id}&reportIds=${reportIds}&docIds=${docIds}&isSignOff=${isSignOff}`;
  }

  const fetchEmailTemplate = () => {
    setLoading(true);
    dispatch(getEmailTemplateV2(SLUG_JOB_REPORT)).then(data => {

      let adminDetails = JSON.parse(getStorage(ADMIN_DETAILS));
      let body = data.body;
      let subject = data.subject;

      let bcc = data.to_bcc ? Array.isArray(data.to_bcc) ? data.to_bcc.map(address => ({ label: address, value: address })) : [{ label: data.to_bcc, value: data.to_bcc }] : [];
      let cc = adminDetails && adminDetails.email_address ? [{ label: adminDetails.email_address, value: adminDetails.email_address }] : []
      let to = job && job.client_person_email ? [{ label: job.client_person_email, value: job.client_person_email }] : ''

      let client_person_first_name = job && job.client_person_name ? getFirstName(job.client_person_name) : '';
      let orgName = job && job.org_name ? job.org_name : '';
      let dateToday = getEmailDate("");
      let siteName = job && job.sites && job.sites[0] && job.sites[0].site_name ? job.sites[0].site_name : '';
      let userPhoneNumber = adminDetails && adminDetails.phone_number ? getFormattedPhone(adminDetails.phone_number) : ''
      let userName = adminDetails ? adminDetails.name + " " + adminDetails.last_name : "";
      let userEmail = adminDetails && adminDetails.email_address ? `<a href="mailto:${adminDetails.email_address}">${adminDetails.email_address}</a>` : "";
      let noReplyFrom = `${userName} (${orgName}) via ${Strings.SERVICE_NAME} <${data.from_email}>`;

      let pdfLink = getPdfFLink();

      subject = subject.replace(/\{\{org_name\}\}/g, orgName);
      subject = subject.replace(/\{\{job_number\}\}/g, job && job.job_number ? job.job_number : '');
      subject = subject.replace(/\{\{job_label\}\}/g, job && job.job_number ? job.job_number : '');
      subject = subject.replace(/\{\{site_name\}\}/g, siteName)

      body = body.replace(/(?:\r\n|\r|\n)/g, '');
      body = body.replace(/\{\{pdf_link\}\}/g, `<a href="${pdfLink}" target="_blank" style="text-decoration: underline;">View Job Report</a>`);
      body = body.replace(/\{\{date_today\}\}/g, dateToday);
      body = body.replace(/\{\{client_person_first_name\}\}/g, client_person_first_name)
      body = body.replace(/\{\{org_name\}\}/g, orgName);
      body = body.replace(/\{\{user_phone_number\}\}/g, userPhoneNumber);
      body = body.replace(/\{\{user_email\}\}/g, userEmail);
      body = body.replace(/\{\{user_name\}\}/g, userName);
      body = body.replace(/\{\{site_name\}\}/g, siteName);
      body = body.replace(/\{\{service_name\}\}/g, Strings.SERVICE_NAME);
      body = body.replace(/\{\{service_email\}\}/g, `<a href="mailto:${Strings.SERVICE_EMAIL}">${Strings.SERVICE_EMAIL}</a>`);

      let tasks = job && job.sites && job.sites[0] && job.sites[0].tasks ? job.sites[0].tasks : []
      let taskDataStr = `<ul>`
      tasks.forEach((task, index) => {
        taskDataStr += `<li>Task ${index + 1} - ${task.task_name} at ${task.area_name}</li>`
      })
      taskDataStr += `</ul>`

      body = body.replace(/\{\{tasks_data\}\}/g, taskDataStr);

      let documentStr = `<ul>`;
      documents && documents.length > 0 && documents.forEach(document => {
        documentStr += `<li>${document.document_name}</li>`;
      })
      documentStr += `</ul>`;

      body = body.replace(/<p>\{\{documents_data\}\}<\/p>/g, "{{documents_data}}");
      body = body.replace(/\{\{documents_data\}\}/g, documentStr);

      if (body.includes("<p>____no_reply</p>") && body.includes("<p>no_reply____</p>")) {
        let start = body.indexOf("<p>____no_reply</p>");
        let end = body.lastIndexOf("<p>no_reply____</p>")

        if (start !== -1 && end !== -1) {
          let signEnd = end + 15;
          let dataStart = start + 15;
          let noReply = body.substr(dataStart, (end - dataStart));
          noReply = `<!--[if mso]><table role="presentation" cellspacing="0" cellpadding="0" border="0" width="600"><tr><td><![endif]--><div style='width: auto;border:none;border-top:solid #000000 1.0pt; border-bottom: solid #000000 1.0pt; padding:4.0pt 0cm 4.0pt 0cm; margin: 20pt 0cm 10pt 0cm;'>`
            + noReply + `</div><!--[if mso]></td></tr></table><![endif]-->`;
          setNoReplyData(noReply);
          body = body.substring(0, start) + '' + body.substring(signEnd);
        }
      }

      setEmailData({
        from_profile: orgName,
        subject: subject,
        body: body,
        from_field: noReplyFrom,
        cc_field: cc,
        to_field: to,
        bcc_field: bcc,
        job_id: job && job.job_id ? job.job_id : null,
        job_number: job && job.job_number ? job.job_number : null
      });
      setLoading(false);


    }).catch(err => {
      if (err) {
        setLoading(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: 'ant-error',
        })
      }
      onClose();
    })
  }

  useEffect(() => {
    fetchEmailTemplate();
  }, [])

  const sendEmail = (values) => {
    setSubmitting(true);
    let content = values.body;

    content = content + noReplyData;
    content = `<!--[if mso]><table role="presentation" cellspacing="0" cellpadding="0" border="0"><tr><td><![endif]--><div style="font-family: 'calibri', sans-serif !important; font-size: 11pt !important; width: fit-content;">` + content + `</div><!--[if mso]></td></tr></table><![endif]-->`;
    content = content.replace(/font-family:.?inherit;/g, "font-family: 'calibri', sans-serif;");

    content = content.replace(/<p><\/p>/g, '')

    //strip /n from body before sending email
    content = content.replace(/(?:\r\n|\r|\n)/g, '');
    content = content.replace(/<ins>/g, "<u>");
    content = content.replace(/<\/ins>/g, "</u>");

    let formData = {
      to_field: values.to_field.length > 0 ? values.to_field.map(x => x.value).join(',') : '',
      from_field: values.from_field,
      subject: values.subject,
      bcc_field: values.bcc_field.length > 0 ? values.bcc_field.map(x => x.value).join(',') : '',
      cc_field: values.cc_field.length > 0 ? values.cc_field.map(x => x.value).join(',') : '',
      job_id: values.job_id,
      job_number: values.job_number,
      email_type: "JOB_REPORT",
      from_profile: values.from_profile,
      message: content
    }

    let finalFormData = new FormData();

    Object.keys(formData).forEach((key) => {
      finalFormData.append(key, JSON.stringify(formData[key]));
    });
    
    dispatch(emailJobReport(finalFormData)).then(message => {
      notification.success({
        message: Strings.success_title,
        description: message ? message : "Email Sent Successfully.",
        className: 'ant-success'
      })
      setSubmitting(false);
      onClose();
    }).catch(err => {
      if (err) {
        setSubmitting(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: 'ant-error',
          style: { zIndex: 1301 }
        })
      }
    })
  }

  return (
    <div className="v2-modal-container">
      {loading ? (
        <div className="d-flex align-items-center justify-content-center py-3" style={{ color: "#362148" }}>
          <CircularProgress size={20} color="inherit" />
        </div>
      ) : emailData && Object.keys(emailData).length > 1 ? (
        <EmailJobReportForm
          form="email_job_report"
          enableReinitialize={true}
          initialValues={emailData}
          onSubmit={sendEmail}
          onClose={onClose}
          loading={submitting}
        />
      ) : ""}
    </div>
  )
}