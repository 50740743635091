import React, { Component } from "react";
import { connect } from "react-redux";
import { tabs } from "./tabDefinitions";
import { Tabs } from "antd";
import styles from "./TabsContainer.module.css";
import * as SWMSMastersActions from "../../../../actions/SWMSMastersActions";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import './reportmanagement.css'
const { TabPane } = Tabs;

export class ReportTabsContainer extends Component {
  state = {
    currentSelectedTab: 4,
  };

  componentDidMount() {
    this.props.actions
      .getSWMS(1)
      .then(() => {})
      .catch((err) => {});

    this.props.actions
      .getAllOrgSWMS()
      .then(() => {})
      .catch((err) => {});
  }

  onTabChange = (type) => {
    this.setState({ currentSelectedTab: type });
    if (type <= 7) {
      this.props.actions
        .getSWMS(type)
        .then(() => {})
        .catch((err) => {});
    } else {
      this.props.actions
        .getAllOrgSWMS()
        .then(() => {})
        .catch((err) => {});
    }
  };

  render() {
    return (
      <div className="sf-page-layout">
      {/* inner header  */}
      <div className="dash-header">
        <h2 className="page-mn-hd">
          {/* <Icon type="arrow-left" onClick={() => {}} /> */}
          <p>Report Management</p>
        </h2>
      </div>
      <div className="main-container swms-masters-container">
        <div className="sf-card-wrap">
      <Tabs tabPosition="left" onChange={(key) => this.onTabChange(key)}>
        {tabs.map((tab) => (
          <TabPane tab={tab.title} key={tab.id}>
            <div>
              <h1 className={styles.tabHeader}>{tab.title}</h1>
              {tab.Component ? (
                <tab.Component currentTab={this.state.currentSelectedTab} />
              ) : (
                <div>{tab.title}</div>
              )}
            </div>
          </TabPane>
        ))}
      </Tabs>
      </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(SWMSMastersActions, dispatch),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportTabsContainer)
);
