import React from "react";
import { Field } from "redux-form";
import { Icon, Menu, Dropdown } from "antd";
import { CustomCheckbox } from "../../../../../components/common/customCheckbox";
import styles from "./PreAllocateStyles.module.css";
import jobStyles from "../../../Job/JobStyle.module.css";

const getTaskLink = (files, task, jobDetails) => {
  let url = process.env.REACT_APP_CLIENT_APP_URL ? process.env.REACT_APP_CLIENT_APP_URL : ""
  return url + `/task_image_preview?imgs=${encodeURIComponent(JSON.stringify(files.map(file => ({ image: `${file.file_base_path}${file.file_name}`, note: file.notes ? file.notes : "" }))))}${jobDetails.job_name ? "&job=" + jobDetails.job_name : ""}${jobDetails.quote_number ? "&num=" + jobDetails.quote_number : ""}&task=${task.task_name}&v=2`;
}

const TasksTable = ({ sites, fromPostAllocation, allocatedTasks, jobDetails }) => {

  return (
    <div className={`table-responsive ${styles.OverFlowXAuto}`}>
      <div className={jobStyles.SectionHeading}>Tasks</div>
      <div className={jobStyles.card}>
        <table className={`cs-table cs-table-v2 v2-table-no-last-border w-100`}>
          <tbody>
            {sites &&
              sites.length ?
              sites[0].tasks.map((row, index) => (
                <tr key={index}>
                  <td>
                    <div className={styles.CheckboxCell}>
                      <Field
                        type="text"
                        className="cs-select-v2"
                        name={`select_task_${row.id}`}
                        component={CustomCheckbox}
                      />
                      <div className={styles.CheckboxCellName}>
                        {row.job_task_label}
                      </div>
                    </div>
                  </td>
                  <td>{row.task_name}</td>
                  <td>{row.area_name}</td>
                  <td style={{ textAlign: "center" }} width="100">
                    {(row.task_file && Array.isArray(row.task_file) && row.task_file.length > 0) && (
                      <Dropdown overlay={(
                        <Menu onClick={() => window.open(getTaskLink(row.task_file, row, jobDetails), '_blank')} className="cs-menu-dropdown">
                          <Menu.Item key="notes">
                            <div className="px-2">View Photos</div>
                          </Menu.Item>
                        </Menu>
                      )} trigger={['click']}>
                        <a onClick={e => { e.preventDefault() }}>
                          <Icon type="more" className={styles.IconMore} />
                        </a>
                      </Dropdown>)}
                  </td>
                </tr>
              )) : ''}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TasksTable;
