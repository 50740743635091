import React, { useState, useCallback, useRef, useEffect } from "react";
import styles from "../JobStyle.module.css";
import FrequencyList from "./FrequencyList";
import WorkFlowHistory from "../JobBoard/WorkFlowHistory/WorkFlowHistory";
import v2Styles from "../../v2Styles.module.css";
import { getTaskFrequency } from "../../../../actions/v2/JobActions";
import { Modal, notification } from "antd";
import { Strings } from "../../../../dataProvider/localize";
import { useDispatch } from "react-redux";
import { Close } from "@mui/icons-material";
import AddFrequencyModal from "./AddFrequencyModal";

export default function TaskFrequencyContainer({ jobId, selectedTask, selectedJobDetails, setSelectedTask }) {

  const dispatch = useDispatch();
  const controller = useRef();

  const [historyKey, setHistoryKey] = useState(1);
  const [frequencies, setFrequencies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addFreq, setAddFreq] = useState(false);

  useEffect(() => {
    fetchFrequencies();
    return () => {
      if (controller.current) {
        controller.current.abort()
      }
    }
  }, []);

  const refreshHistory = useCallback(() => {
    setHistoryKey(v => v + 1)
  }, [setHistoryKey])

  const fetchFrequencies = useCallback(() => {
    setLoading(true);
    controller.current = new AbortController();
    dispatch(getTaskFrequency({ signal: controller.current.signal }, { task_id: selectedTask.task_id, job_id: jobId })).then(res => {
      setFrequencies(res.taskFrequency.rows);
      setLoading(false);
    }).catch((err) => {
      if (err) {
        setLoading(false);
        notification.error({
          message: Strings.error_title,
          description: "There was an error while fetching Task Frequency Details.",
          className: "ant-error"
        })
      }
    })
  }, [selectedTask, jobId]);

  return (
    <div>
      <div className={`${styles.JobTasksListContainer}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-7 col-lg-12 pr-0">
              {selectedTask ?
                <FrequencyList
                  jobId={jobId}
                  setSelectedTask={setSelectedTask}
                  loading={loading}
                  fetchFrequencies={fetchFrequencies}
                  frequencies={frequencies}
                  selectedTask={selectedTask}
                  selectedJobDetails={selectedJobDetails}
                  refreshHistory={refreshHistory}
                /> : null}
            </div>
            <div className="col-xl-5 col-lg-12 pl-0">
              <div className="d-flex justify-content-end pt-2">
                <button
                  onClick={() => setAddFreq(true)}
                  disabled={loading || !selectedTask || (selectedTask && selectedTask.task_state && selectedTask.task_state.toString().toLowerCase() === "cancelled")}
                  className={`${v2Styles.PrimaryButton}`}
                >
                  Add Frequency
                </button>
              </div>
              <WorkFlowHistory jobId={jobId} key={historyKey} />
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={addFreq}
        onCancel={() => setAddFreq(false)}
        afterClose={() => fetchFrequencies()}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        destroyOnClose={true}
        width="700px"
        closeIcon={<div className={v2Styles.ModalCloseIcon}><Close /></div>}
      >
        <AddFrequencyModal setSelectedTask={setSelectedTask} jobId={jobId} task={selectedTask} onClose={() => setAddFreq(false)} />
      </Modal>
    </div>
  )
}