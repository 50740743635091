import React, { useEffect, useState } from "react";
import "./ProfileStyle.css";
import styles from "./ProfileStyles.module.css";
import { Icon, notification, Upload } from "antd";
import { updateProfilePicture } from '../../../actions/profileManagementActions';
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import { Strings } from "../../../dataProvider/localize";

export default function UploadProfilePic(props) {

  const dispatch = useDispatch();

  const [uploadPic, setUploadPic] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = () => {
    if (props.userDetails) {
      setSubmitting(true);
      let formData = new FormData();
      formData.append("id", props.userDetails.id);
      formData.append("profile_progress", props.userDetails.profile_progress);
      formData.append("profile_pic", uploadPic);
      dispatch(updateProfilePicture(formData, props.userDetails.user_name)).then((message) => {
        notification.success({
          message: Strings.success_title,
          description: message ? message : "Profile Photo Uploaded Successfully.",
          className: "ant-success"
        });
        props.onSubmitClose();
      }).catch(err => {
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: "ant-error"
        })
      }).finally(() => setSubmitting(false))
    } else {
      notification.error({
        message: Strings.error_title,
        description: Strings.generic_error,
        className: "ant-error"
      })
    }
  }

  const handleFileSelect = ({ file }) => {
    let allowedTypes = ["image/png", "image/jpg", "image/jpeg", "image/webp"];
    if (allowedTypes.includes(file.type.toString().toLowerCase())) {
      setUploadPic(file);
    } else {
      notification.error({
        message: "Invalid File Type.",
        description: "Please select a valid image (.jpg, .jpeg, .png, .webp).",
        className: "ant-error"
      })
    }
  }

  return (
    <div className="profile-modal-container">
      <div className={styles.PageHead}>Upload Profile Picture</div>
      <hr className="pt-1"></hr>
      <div className="container-fluid">
        <div className="row pt-2">
          <div className="col-12">
            <div className={`${styles.ProfilePic} profile-picture`} style={{ height: "150px", width: "150px" }}>
              {uploadPic ? (
                <img src={URL.createObjectURL(uploadPic)} />
              ) : props.userDetails && props.userDetails.profile_pic ? (
                <img src={props.userDetails.profile_pic} />
              ) : null}
            </div>
            <div style={{ width: "150px" }} className="text-center">
              <Upload
                beforeUpload={() => { return false; }}
                showUploadList={false}
                onChange={handleFileSelect}
                multiple={false}
                disabled={submitting}
                fileList={uploadPic ? [uploadPic] : []}
                accept=".jpg,.jpeg,.png,.webp"
              >
                <button
                  // disabled={isUploading || submitting}
                  type="button" className={`${styles.BtnFilterTab} mt-2`}>
                  <Icon type="upload" /> Upload Picture
                </button>
              </Upload>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 pt-3 text-right">
            <div className="d-flex align-items-center justify-content-end" style={{ columnGap: "10px" }}>
              {submitting ? (
                <CircularProgress
                  size={20}
                />
              ) : ""}
              <button
                type="button"
                disabled={submitting}
                onClick={props.onClose}
                className={`${styles.FilterResetButton}`}>
                Cancel
              </button>
              <div className="">
                <button onClick={onSubmit}
                  disabled={submitting}
                  className={`${styles.BtnFilterTab}`}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}