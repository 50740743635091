import React from "react";
import {
  Icon,
  Menu,
  Radio,
  notification,
} from "antd";
import { reduxForm, Field, isDirty } from "redux-form";
import { validate } from "../../../utils/Validations/medicalDeclarationValidation";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { customTextarea } from "../../common/customTextarea";
import * as actions from "../../../actions/profileManagementActions";
import { Strings } from "../../../dataProvider/localize";
import { CustomCheckbox } from "../../../components/common/customCheckbox";
import { isRequired } from "../../../utils/Validations/scopeDocValidation";
import { ADMIN_DETAILS, VALIDATE_STATUS, ACCESS_CONTROL } from "../../../dataProvider/constant";
import { DeepTrim, getStorage, handleFocus } from "../../../utils/common";
import "./ProfileStyle.css";
import styles from "./ProfileStyles.module.css";

import CustomRadioGroupV2 from "../../common/new-core-ui/CustomRadioGroupV2";

class MedicalDeclaration extends React.Component {
  constructor(props) {
    super(props);

    this.org_user_id = JSON.parse(getStorage(ADMIN_DETAILS))
      ? JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id
      : null;
    this.org_user_name = JSON.parse(getStorage(ADMIN_DETAILS))
      ? JSON.parse(getStorage(ADMIN_DETAILS)).user_name
      : null;
    this.shouldUpdateAuthUser = this.props.isFromUserManagement && this.props.isFromUserManagement === 'isFromUserMgmt' ? true : false
  }

  state = {
    hasEditPermission: false,
  };

  onSaveSignature = (signDetails, file) => {
    signDetails.file_index = 0;
    this.props.change("signature_file", file);
  };

  onSubmit = async (values) => {
    let formValues = await DeepTrim(values);
    let formData = {
      ...formValues,
      accept_job_requirement: values.accept_job_requirement ? 1 : 0,
      profile_progress: this.props.profileComplete
    }

    var finalFormData = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === "signature_file") {
        finalFormData.append(key, formData.signature_file);
      } else {
        finalFormData.append(key, JSON.stringify(formData[key]));
      }
    });

    let staffUserName = false
    if (this.props.userDetails) {
      staffUserName = this.props.userDetails.user_name
    }
    this.props.userActions
      .addMedicalDeclaration(finalFormData, staffUserName)
      .then((flag) => {
        notification.success({
          message: Strings.success_title,
          description: flag,
          onClick: () => { },
          className: "ant-success",
        });
        this.props.userActions.getOrgUserProfileDetails(staffUserName ? this.props.userDetails.user_id : this.org_user_id, staffUserName ? this.props.userDetails.user_name : this.org_user_name, null, this.shouldUpdateAuthUser)
      })
      .catch((error) => {
        console.log(error);
        if (error.status === VALIDATE_STATUS) {
          notification.warning({
            message: Strings.validate_title,
            description:
              error && error.data && typeof error.data.message == "string"
                ? error.data.message
                : Strings.generic_validate,
            onClick: () => { },
            className: "ant-warning",
          });
        } else {
          notification.error({
            message: Strings.error_title,
            description:
              error &&
                error.data &&
                error.data.message &&
                typeof error.data.message == "string"
                ? error.data.message
                : Strings.generic_error,
            onClick: () => { },
            className: "ant-error",
          });
        }
      });
  };

  componentDidMount() {
    if (this.hasPermission("sf_update_medical_declaration_update_medical_declaratio")) {
      this.setState({ hasEditPermission: true });
    }
  }

  hasPermission = (permission) => {
    let allowed = false;
    let userPermissions = JSON.parse(getStorage(ACCESS_CONTROL))["userManagement"]
    let accessControl = userPermissions && userPermissions.permissions ? userPermissions.permissions : [];
    let permission_id = accessControl.findIndex(acess => acess.control_name.toLowerCase() === permission.toLowerCase());
    if (permission_id !== -1 || this.props.location.pathname === "/dashboard/profile") {
      allowed = true;
    }

    return allowed;
  }

  render() {
    const { handleSubmit, initialValues } = this.props;
    const editMenu = (
      <Menu>
        <Menu.Item></Menu.Item>
      </Menu>
    );

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        {/* Personal Details */}

        <hr className={styles.DetailsDivider}></hr>
        <div className="container-fluid pt-3 pb-2">
          <div className="row align-items-center">
            <h2 className={`${styles.MedicalLabel} font-weight-bold`}>Medical Declaration</h2>
          </div>
          <div className="row">
            <div>
              <h3 className={`${styles.MedicalLabel} font-weight-bold pt-3`}>The inherent requirements of the job include: </h3>
              <p className={`${styles.MedicalLabel} font-weight-bold pt-3`}>
                Repetitive bending and stooping Repetitive kneeling. Reaching
                and stretching. Repetitive gripping and handling. Frequent
                twisting. Standing / walking for extended periods. Regular
                lifting of weights up to 10kgs. Work above shoulder height.
                Irregular lifting of weights 10–17kg. Assisted lifting of
                weights greater than 17kgs.
              </p>

              <div className={`${styles.MedicalLabel} font-weight-bold pt-2`}>
                <Field
                  name="accept_job_requirement"
                  label="Yes I understand and accept"
                  disabled={(initialValues && initialValues.id) || !this.state.hasEditPermission}
                  component={CustomCheckbox}
                  className="cs-select-v2"
                />
              </div>

              <p className={`${styles.MedicalLabel} font-weight-bold pt-3`}>
                Are you currently affected by any injury or condition that may
                impact upon your ability to perform all the duties of the job?
                If so, please provide details and advise on what measures may be
                taken to accommodate your injury or condition so that you could
                perform the job satisfactorily.
              </p>

              <div className={`${styles.MedicalLabel} font-weight-bold`}>
                <Field name="affected_by_injury" component={CustomRadioGroupV2}
                  className="cs-radio-v2"
                  disabled={(initialValues && initialValues.id) || !this.state.hasEditPermission}
                // value={this.state.affected_by_injury}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Field>
              </div>
              {initialValues && initialValues.id ? (
                <div className={`${styles.MedicalLabel} font-weight-bold mt-2`}>
                  <span>{initialValues.injury_details && initialValues.injury_details !== "null" ? initialValues.injury_details : ""}</span>
                  <br></br>
                </div>
              ) : (
                <fieldset className="form-group sf-form pt-1 mb-0">
                  <Field
                    name="injury_details"
                    type="text"
                    validate={this.state.affected_by_injury ? isRequired : []}
                    disabled={this.state.affected_by_injury == 0 || !this.state.hasEditPermission}
                    component={customTextarea}
                    className="cs-radio-v2"
                  />
                </fieldset>
              )}

              <i className={`${styles.MedicalLabel} pt-0`}>
                Please note, if you fail to disclose an existing condition, then
                any future aggravation of your injury arising out of your
                employment may be disqualified for compensation under applicable
                Worker’s Compensation legislation.
              </i>

              <p className={`${styles.MedicalLabel} font-weight-bold pt-3`}>
                Are you a citizen of Australia or otherwise authorized to work
                in Australia?
              </p>
              <div className={`${styles.MedicalLabel} font-weight-bold`}>
                <Field name="australian_citizen"
                  component={CustomRadioGroupV2}
                  className="cs-radio-v2"
                  disabled={(initialValues && initialValues.id) || !this.state.hasEditPermission}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Field>
              </div>

              <p className={`${styles.MedicalLabel} font-weight-bold pt-3`}>
                Have you ever been convicted* of a criminal offence? To be
                convicted a court would have made a finding that you were
                either: convicted by a single judge or jury of the offence or
                found guilty of the offence(s) charged but dismissed without
                conviction.
              </p>
              <div className="sf-chkbx-group mb-2">
                <Field name="convicted_of_offence"
                  component={CustomRadioGroupV2}
                  className="cs-radio-v2"
                  disabled={(initialValues && initialValues.id) || !this.state.hasEditPermission}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Field>
              </div>
              {initialValues && initialValues.id ? (
                <div className={`${styles.MedicalLabel} font-weight-bold`}>
                  <span>{initialValues.offence && initialValues.offence !== "null" ? initialValues.offence : ""}</span>
                  <br></br>
                </div>
              ) : (
                <fieldset className="form-group sf-form">
                  <Field
                    label="Provide details about the offence"
                    name="offence"
                    type="text"
                    validate={this.state.convicted_of_offence ? isRequired : []}
                    disabled={this.state.convicted_of_offence == 0 || !this.state.hasEditPermission}
                    component={customTextarea}
                    className="cs-radio-v2"
                  />
                </fieldset>
              )}
            </div>
          </div>
        </div>



        <div className="all-btn d-flex justify-content-end mt-4">
          <div className="btn-hs-icon">
            {initialValues && initialValues.id ? (
              ""
            ) : (
              <button
                type="submit"
                className={styles.BtnSaveTab}
                disabled={!this.props.isDirty || !this.hasPermission('sf_update_medical_declaration_update_medical_declaratio')}
              >
                <Icon type="save" theme="filled" /> Save
              </button>
            )}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formValues:
      state.form &&
        state.form.MedicalDeclaration &&
        state.form.MedicalDeclaration.values
        ? state.form.MedicalDeclaration.values
        : {},
    isDirty: isDirty("MedicalDeclaration")(state),
    profileComplete:
      state.profileManagement && state.profileManagement.profileComplete,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators(actions, dispatch),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "MedicalDeclaration",
    validate,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, sub, props) => {
      handleFocus(errors, "#");
    },
  })
)(MedicalDeclaration);
