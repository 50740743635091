import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { RichUtils } from 'draft-js';
import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent';

const emailEditor = ({ editorState, onEditorStateChange, toolbarCustomButtons, callBackUpload, options = callBackUpload ? ['history', 'blockType', 'inline', 'textAlign', 'list', 'image'] :
    ['history', 'blockType', 'inline', 'textAlign', 'list'], disabled = false, meta: { touched, error, warning }, otherProps = {} }) => {

    const handleReturn = (event) => {
        if (isSoftNewlineEvent(event)) {
            onEditorStateChange(
                RichUtils.insertSoftNewline(editorState)
            );
            return "handled";
        }
        return "not-handled";
    }

    return (
        <div>
            <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                toolbarCustomButtons={toolbarCustomButtons}
                handleReturn={handleReturn}
                readOnly={disabled}
                toolbar={{
                    options: options,
                    history: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['undo', 'redo'],
                    },
                    blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                    },
                    inline: {
                        options: ['bold', 'italic']
                    },
                    image: {
                        icon: '/images/attach-icon.png',
                        uploadEnabled: true,
                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg,.docx,.png',
                        urlEnabled: false,
                        alignmentEnabled: false,
                        uploadCallback: (file) => {
                            if (callBackUpload) {
                                callBackUpload(file);
                            }
                            return new Promise((resolve, reject) => {
                                resolve({ data: {} })
                            })
                        },
                        previewImage: false,
                        alt: false,
                        defaultSize: false,
                    }
                }}
                {...otherProps}
            />
            {touched && ((error && <span className="error-input">{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
    )
}

export default emailEditor