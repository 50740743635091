import { Switch } from "antd";
import React, { useEffect } from "react";
import $ from "jquery";
export const CustomSwitch = ({
  input,
  label,
  disabled,
  size,
  val,
  defaultChecked,
  className="",
  meta: { touched, error, warning },
}) => {
  var id = "CustomSwitch-" + Math.random().toString(36).substr(7);

  useEffect(() => {
    $(`#${id} .ant-switch`).attr("id", `${input.name}-focus`);
  });
  return (
    <div>
       {label !== undefined &&
        <label>{label}</label>
      }
      <div>
        <Switch
          id={id}
          disabled={disabled}
          checked={val ? Boolean(val) : Boolean(input.value)}
          onChange={input.onChange}
          size={size ? size : "default"}
          className={className}
        />
        {touched &&
          ((error && <span className="error-input">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};
