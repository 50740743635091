import React from "react";
import { Table } from "antd";
import { fetchColumns } from "./tableDefinitions";
import styles from "../PostAllocateStyles.module.css";
import "./StaffTableStyles.css";

const StaffTable = ({
  allocatedUsers,
  licenseTypes,
  onHeaderCheck,
  onCellCheck,
  change,
  onShowModal
}) => {
  const rowSelection = {
    onSelect: (record, selected) => {
      change(`select_staff_${record.user_name}`, selected);
    },
    onSelectAll: (selected, selectedRows) => {
      if (selectedRows.length)
        selectedRows.forEach((row) => {
          change(`select_staff_${row.user_name}`, true);
        });
      else
        allocatedUsers.forEach((user) => {
          change(`select_staff_${user.user_name}`, false);
        });
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
      className: "cs-select-v2"
    }),
  };

  return (
    <div className={styles.StaffTableWrapper}>
      <Table
        rowSelection={rowSelection}
        columns={fetchColumns(licenseTypes, onHeaderCheck, onCellCheck, onShowModal)}
        dataSource={allocatedUsers}
        scroll={{ x: "100%" }}
        size="small"
        bordered={true}
        pagination={{ pageSize: 10, className: "cs-pagination cs-pagination-v2" }}
        className="table-centered-selection staff-table"
      />
    </div>
  );
};

export default StaffTable;
