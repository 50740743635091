import moment from "moment";

export const costingOptions = [
  {
    label: "Hours",
    value: "hours"
  }, {
    label: "Area",
    value: "area"
  },
  {
    label: "Quantity",
    value: "quant"
  },
  {
    label: "Flat Rate",
    value: "flat_rate"
  },
  // {
  //   label: "Labour",
  //   value: "labour"
  // }
];

export const frequencyOptions = [
  {
    label: "Does not repeat",
    value: "none",
  },
  // {
  //   label: "Every weekday (Mon - Fri)",
  //   value: "weekdays",
  // },
  {
    label: "Daily",
    value: "daily"
  },
  {
    label: "Weekly",
    value: "weekly"
  },
  {
    label: "Monthly",
    value: "monthly"
  },
  {
    label: "Yearly",
    value: "yearly"
  }
]

export const repeatOptions = [{
  label: "Day",
  value: "Day"
}, {
  label: "Week",
  value: "Week"
}, {
  label: "Month",
  value: "Month"
}, {
  label: "Year",
  value: "Year"
}];

export const monthWeekOptions = [{
  label: "First",
  value: "first",
  sequence: 1
}, {
  label: "Second",
  value: "second",
  sequence: 2
}, {
  label: "Third",
  value: "third",
  sequence: 3
}, {
  label: "Fourth",
  value: "fourth",
  sequence: 4
}, {
  label: "Last",
  value: "last",
  sequence: 5
}];

export const dayOptions = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const weekDayOptions = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];


export const monthOptions = [
  {
    label: "January",
    value: "January",
    sequence: 1
  },
  {
    label: "February",
    value: "February",
    sequence: 2
  },
  {
    label: "March",
    value: "March",
    sequence: 3
  },
  {
    label: "April",
    value: "April",
    sequence: 4
  },
  {
    label: "May",
    value: "May",
    sequence: 5
  },
  {
    label: "June",
    value: "June",
    sequence: 6
  },
  {
    label: "July",
    value: "July",
    sequence: 7
  },
  {
    label: "August",
    value: "August",
    sequence: 8
  },
  {
    label: "September",
    value: "September",
    sequence: 9
  },
  {
    label: "October",
    value: "October",
    sequence: 10
  },
  {
    label: "November",
    value: "November",
    sequence: 11
  },
  {
    label: "December",
    value: "December",
    sequence: 12
  }
];

export const getAdditionalTotal = (fields) => {
  let additionalCost = parseFloat(fields.misc_rate);
  let units = parseFloat(fields.misc_units);
  let adjustment = parseFloat(fields.misc_adjustment_cost);
  let subTotal = 0;
  if (!isNaN(adjustment) && !isNaN(additionalCost)) {
    subTotal = additionalCost + adjustment;
  }
  if (!isNaN(units)) {
    if (units > 0) {
      subTotal = subTotal * units;
    }
  }

  return subTotal;
}

export const getFrequencyString = (frequency, selectedDays, frequency_type) => {
  let str = "";
  if (frequency) {
    let repeatType = frequency.repeat_type;
    if (repeatType && repeatType.value === "Week" && frequency.repeat_value && parseInt(frequency.repeat_value) > 0) {
      if (frequency_type && frequency_type.value && frequency_type.value === "weekdays") {
        str = "Occurs every Monday to Friday"
      } else {
        str = "Occurs every";
        if (frequency.repeat_value !== "1") {
          str += ` ${frequency.repeat_value} weeks on`
        }
        let days = [...dayOptions.filter(x => selectedDays.includes(x))];
        if (days.length === 7) {
          str += " day";
        } else if (days.length > 1) {
          str += ` ${days.splice(0, days.length - 1).join(", ")} and ${days[days.length - 1]}`
        } else {
          str += ` ${days.join(", ")}`
        }
      }
    } else if (repeatType && repeatType.value === "Day" && frequency.repeat_value && parseInt(frequency.repeat_value) > 0) {
      str = `Occurs every ${frequency.repeat_value && frequency.repeat_value === "1" ? "day" : frequency.repeat_value + " days"}`
    } else if (repeatType && repeatType.value === "Month" && frequency.repeat_value && parseInt(frequency.repeat_value) > 0) {
      str = `Occurs every`;
      if (frequency.repeat_value === "1") {
        str += ` month `
      } else {
        str += ` ${frequency.repeat_value} months`
      }

      if (frequency.month_value && frequency.month_value === 1 && frequency.month_day_number) {
        str += ` on day ${frequency.month_day_number}`
      } else if (frequency.month_value && frequency.month_value === 2 && frequency.month_week && frequency.month_day) {
        str += ` on the ${frequency.month_week.value} ${frequency.month_day.value}`
      }
    } else if (repeatType && repeatType.value === "Year") {
      str = `Occurs every year`
      if (frequency.year_value && frequency.year_value.toString() === "1" && frequency.year_month && frequency.year_month_day_number) {
        str += ` on day ${frequency.year_month_day_number} of ${frequency.year_month.value}`
      } else if (frequency.year_value && frequency.year_value.toString() === "2" && frequency.year_month_day && frequency.year_month_week && frequency.year_month_name) {
        str += ` on the ${frequency.year_month_week.value} ${frequency.year_month_day.value} of ${frequency.year_month_name.value}`
      }
    }

    if (frequency.freq_end_date && str.length > 0) {
      str += ` until ${moment(frequency.freq_end_date).format("DD-MM-YYYY")}.`
    }
  }
  return str;
}

export const getFrequencyPattern = (frequencyType, frequency, taskDuration = { value: "1_Day" }) => {
  let freqData = {};
  freqData.task_duration = taskDuration.value
  freqData.freq_start_date = moment(frequency.freq_start_date).format("YYYY-MM-DD")
  freqData.freq_end_date = moment(frequency.freq_end_date).format("YYYY-MM-DD")
  if (frequencyType.value.toLowerCase() === "daily") {
    freqData.freq_type = "DAILY";
    freqData.pattern = {
      type: "D_EVERY_DAY",
      repeat_every: frequency.repeat_value
    }
  }
  if (frequencyType.value.toLowerCase() === "weekdays") {
    freqData.freq_type = "WEEKLY";
    let weekDays = [];
    if (frequency.week_days) {
      weekDays = frequency.week_days.map(x => weekDayOptions.indexOf(x));
    }
    freqData.pattern = {
      type: "D_EVERY_WEEK_DAY",
      repeat_every: frequency.repeat_value,
      day: frequency.selected_days && frequency.selected_days.length > 0 ? frequency.selected_days.join("|") : dayOptions[1],
    }
    freqData.week_days = weekDays.filter(x => x !== -1).map(y => y + 1).sort().join(",");
  }

  if (frequencyType.value.toLowerCase() === "weekly") {
    freqData.freq_type = "WEEKLY";
    let weekDays = [];
    if (frequency.week_days) {
      weekDays = frequency.week_days.map(x => weekDayOptions.indexOf(x));
    }
    freqData.pattern = {
      type: "D_EVERY_WEEK",
      repeat_every: frequency.repeat_value,
      day: frequency.selected_days && frequency.selected_days.length > 0 ? frequency.selected_days.join("|") : dayOptions[1],
    }
    freqData.week_days = weekDays.filter(x => x !== -1).map(y => y + 1).join(",")
  }

  if (frequencyType.value.toLowerCase() === "monthly") {
    freqData.freq_type = "MONTHLY";
    if (frequency.month_value && frequency.month_day_number && frequency.month_value.toString() === "1") {
      freqData.pattern = {
        type: "M_DATE_OF_EVERY_MONTH",
        day_of_month: frequency.month_day_number,
        repeat_every: frequency.repeat_value
      }
    } else if (frequency.month_week && frequency.month_day && frequency.repeat_value) {
      freqData.pattern = {
        type: "M_DAY_OF_EVERY_MONTH",
        day_of_month: frequency.month_week.sequence,
        day: frequency.month_day.value,
        repeat_every: frequency.repeat_value
      }
    } else {
      freqData.pattern = null
    }
  }

  if (frequencyType.value.toLowerCase() === "yearly") {
    freqData.freq_type = "YEARLY"
    if (frequency.year_value && frequency.year_value.toString() === "1" && frequency.year_month_day_number && frequency.year_month && frequency.year_month.sequence) {
      freqData.pattern = {
        type: "Y_DATE_OF_MONTH",
        day_of_month: frequency.year_month_day_number,
        month: frequency.year_month.sequence,
        repeat_every: frequency.repeat_value
      }
    } else if (frequency.year_month_week && frequency.year_month_week.sequence && frequency.year_month_day && frequency.year_month_day.value && frequency.year_month_name && frequency.year_month_name.value) {
      freqData.pattern = {
        type: "Y_DAY_OF_MONTH",
        day_of_month: frequency.year_month_week.sequence,
        day: frequency.year_month_day.value,
        month: frequency.year_month_name.sequence,
        repeat_every: frequency.repeat_value
      }
    } else {
      freqData.pattern = null;
    }
  }

  if (frequencyType.value.toLowerCase() === "none") {
    freqData.freq_type = "NONE";
    freqData.pattern = { type: "NONE" };
  }

  return freqData;
}

export const getFirstFrequencyDates = (frequency, frequencyType, taskDuration) => {
  let start = new moment(frequency.freq_start_date).startOf("day");
  let end = new moment(frequency.freq_end_date).endOf("day");
  let durationVal = taskDuration.value.split("_")[0];
  let durationType = taskDuration.value.split("_")[1];
  if (frequencyType && frequencyType.value === "daily") {
    end = moment(frequency.freq_start_date).add(durationVal, durationType);
  } else if (frequencyType && (frequencyType.value === "weekdays" || frequencyType.value === "weekly")) {
    let supStart = new moment(start);
    let day = new moment(supStart).day();
    let weekDays = frequency.week_days && frequency.week_days.length ? frequency.week_days.sort((a, b) => dayOptions.indexOf(a) - dayOptions.indexOf(b)) : null;
    let startDay = frequency.week_days && frequency.week_days.length ? dayOptions.findIndex(x => x.toLowerCase() === frequency.week_days[0].toLowerCase()) : 1;
    while (day !== startDay) {
      supStart = moment(supStart).add(1, "day");
      day = moment(supStart).day();
    }
    end = moment(supStart).add(1, "week");
    start = moment(supStart);
  } else if (frequencyType && frequencyType.value === "monthly") {
    if (frequency.month_value && frequency.month_value === 1) {
      let supStart = new moment(start);
      let date = new moment(supStart).date();
      while (date.toString() !== frequency.month_day_number.toString()) {
        supStart = moment(supStart).add(1, "day");
        date = moment(supStart).date();
      }
      end = moment(supStart).add(durationVal, durationType);
      start = moment(supStart);
    } else {
      let supStart = new moment(start);
      let seqStart = getDayOfMonth(start, frequency.month_day.value, frequency.month_week.sequence);
      if (moment(seqStart).isAfter(supStart, "d") === false) {
        seqStart = getDayOfMonth(moment(start).add(1, "month"), frequency.month_day.value, frequency.month_week.sequence);
      }
      start = moment(seqStart).startOf("day");
      end = moment(seqStart).add(durationVal, durationType);
    }
  } else if (frequencyType && frequencyType.value === "yearly") {
    if (frequency.year_value && frequency.year_value === 1 && frequency.year_month_day_number && frequency.year_month && frequency.year_month.sequence) {
      let supStart = new moment(start);
      let month = new moment(supStart).month();
      let defMonth = parseInt(frequency.year_month.sequence) - 1;
      while (month.toString() !== defMonth.toString()) {
        supStart = moment(supStart).add(1, "month");
        month = moment(supStart).month();
      }
      supStart = moment(supStart).startOf("month");
      let date = new moment(supStart).date();
      while (date.toString() !== frequency.year_month_day_number.toString()) {
        supStart = moment(supStart).add(1, "day");
        date = moment(supStart).date();
      }
      if (moment(moment(supStart).format("YYYY-MM-DD")).isBefore(moment(start).format("YYYY-MM-DD"))) {
        supStart = moment(supStart).add(1, "year");
      }
      end = moment(supStart).add(durationVal, durationType);
      start = moment(supStart);
    } else if (frequency.year_value && frequency.year_value === 2 && frequency.year_month_day && frequency.year_month_name && frequency.year_month_week) {
      let supStart = new moment(start);
      let month = new moment(supStart).month();
      let defMonth = parseInt(frequency.year_month_name.sequence) - 1;
      while (month.toString() !== defMonth.toString()) {
        supStart = moment(supStart).add(1, "month");
        month = moment(supStart).month();
      }
      supStart = moment(supStart).startOf("month");

      let seqStart = getDayOfMonth(supStart, frequency.year_month_day.value, frequency.year_month_week.sequence);
      if (moment(seqStart).isAfter(start, "d") === false) {
        seqStart = getDayOfMonth(moment(supStart).add(1, "year"), frequency.year_month_day.value, frequency.year_month_week.sequence);
      }
      start = moment(seqStart).startOf("day");
      end = moment(seqStart).add(durationVal, durationType).subtract(1, "d");
    }
  }
  if (frequencyType && frequencyType.value !== "none") {
    end = moment(end).subtract(1, "d");
  }
  return [start, end];
}

const getDayOfMonth = (start, day, week) => {
  let supStart = new moment(start);
  let allDates = [];
  var monday = moment(supStart).startOf('month').day(day);
  if (monday.date() > 7) monday.add(7, 'd');
  var month = monday.month();
  if (monday.month === month) {
    allDates.push(moment(monday));
  }
  while (month === monday.month()) {
    allDates.push(moment(monday));
    monday.add(7, 'd');
  }
  let sequence = week - 1;
  if (allDates.length <= sequence) {
    sequence = allDates.length - 1
  }
  return allDates[sequence];
}

export const getNextDays = (day, duration = {}) => {
  let nextDays = [];
  let format = duration.value.split("_");
  if (format[1].toString().toLowerCase().includes("day") && parseInt(format[0]) < 7) {
    let i = dayOptions.indexOf(day);
    let j = parseInt(format[0]) - 1;

    let allDays = [];
    while (j > 0) {
      i++;
      if (i > 6) {
        i = 0;
      }
      allDays.push(dayOptions[i]);
      j--;
    }
    nextDays = [...allDays];
  } else {
    nextDays = dayOptions.filter(x => x !== day);
  }
  return nextDays;
}