import React from 'react';
import { connect } from 'react-redux';
import HeadRoute from './components/Routes/headRoute';
import { CircularProgress } from '@mui/material';

const App = ({ showSpinner = 0 }) => {
  return (
    <div className="App">
      {
        showSpinner > 0 ?
          <div className="sk-loder-st d-flex align-items-center justify-content-center" style={{ color: "#362148" }}>
            <CircularProgress size={40} thickness={2} color="inherit" />
          </div>
          : null
      }
      <HeadRoute />
    </div>
  );
}

export default connect((state) => ({
  showSpinner: state.auth.showSpinner
}))(App);
