import React, { useState } from "react";
import JobSwmsFormContainer from "./JobSwmsForm/JobSwmsFormContainer";
import JobSwmsTasks from "./JobSwmsTask/JobSwmsTasks";
import styles from "../JobStyle.module.css";

export default function JobSwmsContainer(props) {

  const [showForm, setShowForm] = useState(false);
  const [selectedSwms, setSelectedSwms] = useState(null);
  const [sections, setSections] = useState(["0"]);

  const toggleForm = (swms) => {
    setSelectedSwms(swms);
    setShowForm(!showForm);
  }

  return (
    <div className={styles.TabContainer}>
      <div className="job-swms-container">
        {showForm ? (
          <JobSwmsFormContainer
            swms={selectedSwms}
            onClose={toggleForm}
          />
        ) : (
          <JobSwmsTasks
            jobId={props.jobId}
            sections={sections}
            setSections={setSections}
            goToForm={toggleForm} />
        )}
      </div>
    </div>
  )
}