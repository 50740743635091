import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Upload, notification } from "antd";
import { saveJobReport } from '../../../../actions/SAJobMgmtAction';
import { customInput } from "../../../common/custom-input";
import { Strings } from "../../../../dataProvider/localize";
import { VALIDATE_STATUS } from '../../../../dataProvider/constant';
import { CustomSingleSelect } from '../../../common/new-core-ui/CustomSingleSelect';
import { whiteDefaultTheme } from '../../../v2/Job/SelectV2SingleStyle';
import jobStyles from "../../Job/JobStyle.module.css";
import { IconButton } from '@mui/material';
import { customTextarea } from '../../../common/customTextarea';
import { components } from 'react-select';

const validate = values => {
  const errors = {}
  if (!values.quote_task_id) {
    errors.quote_task_id = 'Required'
  }
  if (!values.area) {
    errors.area = 'Required'
  }
  if (!values.note) {
    errors.note = 'Required'
  }
  return errors
}

const AddNewReportRecord = (props) => {

  const dispatch = useDispatch();
  const { allTasks } = props;

  const [areaPhoto, setAreaPhoto] = useState(null);
  const [beforePhoto, setBeforePhoto] = useState(null);
  const [afterPhoto, setAfterPhoto] = useState(null);

  const handleFileUpload = (files, fieldName) => {
    if (files) {
      if (files.size < 1572864) {
        setPhoto(files, fieldName)
      } else {
        notification.error({
          message: Strings.error_title,
          description: 'Please select a file less than 1.5 MB.',
          className: "ant-error",
        })
      }
    } else {
      setPhoto(files, fieldName)
    }
  }

  const setPhoto = (files, fieldName) => {
    if (fieldName === "areaPhoto") {
      setAreaPhoto(files);
    }
    if (fieldName === "beforePhoto") {
      setBeforePhoto(files);
    }
    if (fieldName === "afterPhoto") {
      setAfterPhoto(files);
    }
  }

  const onSubmit = formValues => {
    if (!areaPhoto && !beforePhoto && !afterPhoto) {
      notification.error({
        message: Strings.error_title,
        description: 'Please select atleast one photo to continue.',
        className: "ant-error",
      })
    } else {

      let values = formValues;
      let formData = new FormData();

      values.location_photo = [];
      values.before_photo = [];
      values.after_photo = [];

      let uploadIndex = 0;

      if (areaPhoto) {
        formData.append("files", areaPhoto);
        values.location = [areaPhoto];
        values.location_photo.push(uploadIndex);
        uploadIndex++;
      }

      if (beforePhoto) {
        formData.append("files", beforePhoto);
        values.before = [beforePhoto];
        values.before_photo.push(uploadIndex);
        uploadIndex++;
      }

      if (afterPhoto) {
        formData.append("files", afterPhoto);
        values.after = [afterPhoto];
        values.after_photo.push(uploadIndex);
        uploadIndex++;
      }

      values = [{ ...values, quote_task_id: values.quote_task_id.value }];

      formData.append("job_cleaning_reports", JSON.stringify(values));
      formData.append("job_id", props.jobId);
      formData.append("job_cleaning_reports_documents", JSON.stringify([]))

      Promise.resolve(dispatch(saveJobReport(formData, props.jobId))).then(res => {
        notification.success({
          message: Strings.success_title,
          description: res,
          className: "ant-success",
        });
        props.onClose();
      }).catch(error => {
        if (error.status == VALIDATE_STATUS) {
          notification.warning({
            message: Strings.validate_title,
            description:
              error && error.data && typeof error.data.message == "string"
                ? error.data.message
                : Strings.generic_validate,
            className: "ant-warning",
          });
        } else {
          notification.error({
            message: Strings.error_title,
            description:
              error &&
                error.data &&
                error.data.message &&
                typeof error.data.message == "string"
                ? error.data.message
                : Strings.generic_error,
            className: "ant-error",
          });
        }
      })
    }
  }

  return (
    <form onSubmit={props.handleSubmit(onSubmit)}>
      <div>
        <div className={jobStyles.ReportCard}>
          <div className="mb-1">
            <div className='d-flex' style={{ columnGap: "5px" }}>
              <div style={{ flex: "0 0 50% " }}>
                <div className={jobStyles.CommonLabel}>Task</div>
                <Field
                  className="w-100"
                  name="quote_task_id"
                  component={CustomSingleSelect}
                  styles={whiteDefaultTheme}
                  otherProps={{
                    components: { dropdownIndicator: <components.DownChevron /> }
                  }}
                  options={
                    allTasks &&
                    allTasks.map((tsk) => ({
                      label: `${tsk.task_name}${tsk.area_name ? " - " + tsk.area_name : ""}`,
                      value: tsk.id,
                    }))
                  }
                />
              </div>
              <div style={{ flex: "0 0 50% " }}>
                <div className={jobStyles.CommonLabel}>Area Name</div>
                <Field name={`area`} type="text" className={`${jobStyles.InputFieldV2} ${jobStyles.SmallInputField}`} component={customInput} />
              </div>
            </div>
          </div>
          <div className="d-flex align-items-stretch mt-2" style={{ columnGap: "10px" }}>
            <div className="d-flex align-items-center" style={{ columnGap: "5px", flex: "1 1 auto" }}>
              <div className="w-100">
                <div style={{ height: "100px" }}>
                  <div className={jobStyles.ReportImageContainer}>
                    {areaPhoto ? (
                      <img className={jobStyles.ReportImage} src={URL.createObjectURL(areaPhoto)} />
                    ) : null}
                  </div>
                </div>
                <div>
                  <Upload
                    fileList={areaPhoto ? [areaPhoto] : []}
                    beforeUpload={() => { return false; }}
                    showUploadList={false}
                    multiple={false}
                    className="job-upload-v2"
                    accept=".jpeg,.jpg,.png"
                    onChange={({ file }) =>
                      handleFileUpload(file, "areaPhoto")
                    }
                  >
                    <div className={`${jobStyles.PrimaryText} justify-content-center d-flex align-items-center mt-1`} style={{ columnGap: "5px" }}>
                      <i style={{ fontSize: "17px" }} className="material-icons">add_a_photo</i>
                      <div>Upload Area Photo</div>
                    </div>
                  </Upload>
                </div>
              </div>
              <div className="w-100">
                <div style={{ height: "100px" }}>
                  <div className={jobStyles.ReportImageContainer}>
                    {beforePhoto ? (
                      <img className={jobStyles.ReportImage} src={URL.createObjectURL(beforePhoto)} />
                    ) : null}
                  </div>
                </div>
                <div>
                  <Upload
                    fileList={beforePhoto ? [beforePhoto] : []}
                    beforeUpload={() => { return false; }}
                    showUploadList={false}
                    multiple={false}
                    className="job-upload-v2"
                    accept=".jpeg,.jpg,.png"
                    onChange={({ file }) =>
                      handleFileUpload(file, "beforePhoto")
                    }
                  >
                    <div className={`${jobStyles.PrimaryText} justify-content-center d-flex align-items-center mt-1`} style={{ columnGap: "5px" }}>
                      <i style={{ fontSize: "17px" }} className="material-icons">add_a_photo</i>
                      <div>Upload Before Photo</div>
                    </div>
                  </Upload>
                </div>
              </div>
              <div className="w-100">
                <div style={{ height: "100px" }}>
                  <div className={jobStyles.ReportImageContainer}>
                    {afterPhoto ? (
                      <img className={jobStyles.ReportImage} src={URL.createObjectURL(afterPhoto)} />
                    ) : null}
                  </div>
                </div>
                <div>
                  <Upload
                    fileList={afterPhoto ? [afterPhoto] : []}
                    beforeUpload={() => { return false; }}
                    showUploadList={false}
                    multiple={false}
                    className="job-upload-v2"
                    accept=".jpeg,.jpg,.png"
                    onChange={({ file }) =>
                      handleFileUpload(file, "afterPhoto")
                    }
                  >
                    <div className={`${jobStyles.PrimaryText} justify-content-center d-flex align-items-center mt-1`} style={{ columnGap: "5px" }}>
                      <i style={{ fontSize: "17px" }} className="material-icons">add_a_photo</i>
                      <div>Upload After Photo</div>
                    </div>
                  </Upload>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-start" style={{ columnGap: "10px", flex: "0 0 300px" }}>
              <div style={{ flex: "1 1 auto" }}>
                <div className={jobStyles.CommonLabel}>Notes</div>
                <Field name={`note`} rows={3} component={customTextarea} className={jobStyles.ReportTextArea} />
              </div>
              <div style={{ flex: "0 0 30px", alignSelf: "center" }}>
                <div className="mb-2">
                  <IconButton type="submit" style={{ background: "#c0b4ff", color: "#fff", height: "auto" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{ height: "14px", width: "14px", }}>
                      <path fill="currentColor" d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                    </svg>
                  </IconButton>
                </div>
                <div>
                  <IconButton type="button" style={{ background: "red", color: "#fff", height: "auto" }} onClick={() => props.onClose()}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style={{ height: "14px", width: "14px" }}>
                      <path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                    </svg>
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default reduxForm({
  validate
})(AddNewReportRecord)