import React, { Component } from "react";
import { Field } from "redux-form";
import CustomRadioGroup from "../../../../../components/common/new-core-ui/CustomRadioGroup";
import { CustomCheckbox } from "../../../../../components/common/customCheckbox";
import styles from "./PreAllocateStyles.module.css";
import { connect } from "react-redux";
import { Input } from "antd";
import jobStyles from "../../../Job/JobStyle.module.css";

export class StaffAllocate extends Component {
  state = {
    serviceAgents: [],
    staff: [],
    selectedSA: null,
    searchString: "",
    saStaffs: []
  };

  componentDidMount() {
    const { service_agents } = this.props.staffList;
    this.setState({
      serviceAgents: service_agents.map((sa) => {
        let users = this.props.allocatedUsers.filter(user => user.org_id === sa.id).length;
        return {
          ...sa,
          label: <span><span>{sa.name}</span>{users > 0 ? <span className={styles.LabelBlue}> ({users})</span> : ''}</span>,
          value: sa.id,
          users: users
        }
      })
    });

    const staffUsers = [];

    // showing users from first service agents instead of all users
    let sa = service_agents[0];
    sa.site_supervisors.forEach((sup) =>
      staffUsers.push({
        ...sup,
        value: sup.user_name,
        label: sup.first_name,
        sa_id: sa.id
      })
    );
    sa.staffs.forEach((st) =>
      staffUsers.push({ ...st, value: st.user_name, label: st.first_name, sa_id: sa.id })
    );

    this.setState({ staff: this.getSortedStaff(staffUsers) });

    if (this.props.updating) {
      const { jobDocumentDetails, staffList } = this.props;

      const allocatedStaffUsernames =
        jobDocumentDetails.job_allocated_users.map((user) => user.user_name);
      const selectedServiceAgent = staffList.service_agents.find((sa) =>
        [...sa.site_supervisors, ...sa.staffs].some((user) =>
          allocatedStaffUsernames.includes(user.user_name)
        )
      );
      if (selectedServiceAgent && selectedServiceAgent.id) {
        this.props.change("select_sa", selectedServiceAgent.id);
        this.handleSAChange(selectedServiceAgent.id)
        allocatedStaffUsernames.forEach((uname) =>
          this.props.change(`select_staff_${uname}`, true)
        );
      } else {
        this.props.change("select_sa", null);
      }
    }
  }

  getSortedStaff = (staffList) => {
    return staffList.sort((a, b) => {
      let nameA = a.first_name.toUpperCase();
      let nameB = b.first_name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    })
  }

  handleSAChange = (id) => {
    let serviceAgent = this.props.staffList.service_agents.find(sa => sa.id === id);
    let staffUsers = [];
    if (serviceAgent) {
      // set users from the selected service agent
      serviceAgent.site_supervisors.forEach(sup => {
        staffUsers.push({
          ...sup,
          value: sup.user_name,
          label: sup.first_name,
          sa_id: serviceAgent.id
        })
      })
      serviceAgent.staffs.forEach((st) =>
        staffUsers.push({ ...st, value: st.user_name, label: st.first_name, sa_id: serviceAgent.id })
      );
    }
    // remove old selected staffs from the previous service agent
    // if(this.state.selectedSA) {
    //   let selectedSA = this.props.staffList.service_agents.find(sa => sa.id === this.state.selectedSA);
    //   selectedSA.site_supervisors.forEach(sup => {
    //     this.props.change(`select_staff_${sup.user_name}`, false)
    //   })
    //   selectedSA.staffs.forEach((st) =>
    //     this.props.change(`select_staff_${st.user_name}`, false)
    //   );
    // }
    staffUsers = this.getSortedStaff(staffUsers);
    this.setState({ staff: staffUsers, selectedSA: id, saStaffs: staffUsers });
  }

  onSearch = (search = "") => {
    if (search !== null) {
      search = search.trim();
      let staffList = this.state.saStaffs.filter(staff => staff.first_name.toLowerCase().includes(search.toLowerCase().trim()))
      this.setState({ staff: staffList });
    } else {
      this.setState({ staff: this.state.saStaffs })
    }
  }

  updateSelected = (sa, value) => {
    let serviceAgent = this.state.serviceAgents.find(serv => serv.id === sa);
    if (value) {
      serviceAgent.users = serviceAgent.users + 1;
    } else {
      serviceAgent.users = serviceAgent.users - 1;
    }
    serviceAgent.label = <span><span>{serviceAgent.name}</span>{serviceAgent.users > 0 ? <span className={styles.LabelBlue}> ({serviceAgent.users})</span> : ''}</span>
    this.setState({ serviceAgents: this.state.serviceAgents.map(serv => serv.id === sa ? serviceAgent : serv) })
  }

  render() {
    return (
      <div className={styles.DocsContainer}>
        <div className={`${styles.StaffFlow}`}>
          <div className="container-fluid py-0">
            <div className="row py-0">
              <div className="col-lg-3 col-md-12 col-sm-12 px-2">
                <div className={jobStyles.SectionHeading}>Allocate Contractor</div>
                <div>
                  <Field
                    name="select_sa"
                    disabled={false}
                    options={this.state.serviceAgents}
                    itemClassName="cs-radio-v2"
                    component={CustomRadioGroup}
                    onChange={(e) => this.handleSAChange(e.target.value)}
                  />
                  <div className={`${jobStyles.CommonLabel} mt-2`}>Search Staff</div>
                  <Input
                    placeholder="Search Staff"
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    onChange={(e) => this.onSearch(e.target.value)}
                    className={jobStyles.InputFieldV2}
                    disabled={
                      !this.props.formValues ||
                      !this.props.formValues.select_sa
                    }
                  />
                </div>
              </div>
              <div className="col-lg-9 col-md-12 col-sm-12 px-2">
                <div className={jobStyles.CommonLabel}>
                  Allocate Staff
                </div>
                <div>
                  <div className="container-fluid py-0 m-0">
                    <div className="row py-0">
                      {this.state.staff.map((staffItem) => (
                        <div className="col-sm-6 col-md-4 col-lg-3 px-0" key={staffItem.user_name}>
                          <div>
                            <Field
                              name={`select_staff_${staffItem.user_name}`}
                              label={staffItem.label}
                              component={CustomCheckbox}
                              className="cs-select-v2"
                              onChange={(e) => {
                                this.props.change(`select_staff_${staffItem.user_name}`, e.target.checked);
                                this.updateSelected(staffItem.sa_id, e.target.checked)
                              }}
                              disabled={
                                !this.props.formValues ||
                                !this.props.formValues.select_sa
                              }
                            />
                          </div>
                        </div>
                      ))}
                      {this.state.staff.length === 0 && (
                        <div className="text-center py-2" style={{ flex: 1 }}>
                          <div className={styles.LabelRed}>No Staff Available.</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  staffList: state.JobDocument.staffList,
  chemicals: state.JobDocument.chemicals,
  jobDetails: state.JobDocument.jobDetails,
  certificates: state.JobDocument.certificates,
  licenseTypes: state.JobDocument.licenseTypes,
  jobDocumentDetails: state.JobDocument.jobDocumentDetails,
  jobDocumentCreatorDetails: state.JobDocument.jobDocumentCreatorDetails,
  initialValues: state.JobDocument.initialValues,
  allocatedUsers: state.JobDocument.allocatedUsers,
  allocatedTasks: state.JobDocument.allocatedTasks,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StaffAllocate);
