import React, { useEffect, useMemo, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import { notification } from "antd";
import moment from "moment";

import { Strings } from "../../../../../dataProvider/localize";
import { useDispatch } from "react-redux";
import { getSwmsFormData } from "../../../../../actions/v2/JobSwmsActions";
import { getStorage } from "../../../../../utils/common";
import { ADMIN_DETAILS } from "../../../../../dataProvider/constant";
import RiskMatrixSection from "../JobSwmsForm/RiskMatrixSection";
import PreviewSwmsCheckboxSection, { PreviewSwmsSdsSection } from './PreviewSwmsCheckboxSection';
import { downLoadDoc } from "../../JobBoard/utils";
import { serializePreviewData } from "./PreviewSwmsSerializer";
import CallIcon from '@mui/icons-material/Call';
import LanguageIcon from '@mui/icons-material/Language';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';

import styles from "../../JobStyle.module.css";
import "./PreviewSwmsStyle.css";

export default function PreviewSwms({ swms, onClose }) {

  const supportedTypes = ["png", "jpg", "jpeg", "webp"];
  const filesBasePath = useMemo(() => {
    let filesPath = "";
    try {
      let organisation = JSON.parse(getStorage(ADMIN_DETAILS)).organisation;
      let domain = new URL(organisation.logo_path).origin;
      let slug = organisation.slug;
      filesPath = `${domain}/${slug}/`;
    } catch (e) {
      console.log(e);
    }
    return filesPath;
  }, []);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [previewData, setPreviewData] = useState(null);

  const controller = useRef();

  const taskImagesUrl = useMemo(() => {
    let url = "#";
    if (process.env.REACT_APP_CLIENT_APP_URL) {
      try {
        let organisation = JSON.parse(getStorage(ADMIN_DETAILS)).organisation;
        url = `${process.env.REACT_APP_CLIENT_APP_URL}/swms/${organisation.id}/${swms.swms_form_id}/task_files`;
      } catch (e) {
        console.log(e);
      }
    }
    return url;
  }, [swms]);

  const previewImages = useMemo(() => {
    let images = [];
    let map = { "img": 1, "pdf": 2, "doc": 3 }; // order for the docs && images.
    if (previewData && previewData.task_details && previewData.task_details.task_documents_images && previewData.task_details.task_documents_images.length > 0) {
      previewData.task_details.task_documents_images.forEach((doc, index) => {
        if (index < 3) {
          let type = "doc";
          let file_name = doc.file_name;
          let extension = file_name.split(".").pop().toString().toLowerCase();
          if (supportedTypes.includes(extension)) {
            type = "img";
          } else if (extension === "pdf") {
            type = "pdf";
          }
          images.push({
            ...doc,
            type: type,
          })
        }
      })
    }
    return images.sort((x, y) => map[x.type] - map[y.type]);
  }, [previewData])

  const getPreviewDetails = () => {
    setLoading(true);
    controller.current = new AbortController();
    dispatch(getSwmsFormData({ signal: controller.current.signal }, swms.swms_form_id)).then(res => {
      setPreviewData(res.swms_data ? serializePreviewData(res.swms_data) : {});
      setLoading(false);
    }).catch(err => {
      if (err) {
        setLoading(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: "ant-error"
        })
        onClose();
      }
    })
  }

  useEffect(() => {
    getPreviewDetails();
    return () => {
      if (controller.current) {
        controller.current.abort();
      }
    }
  }, [])

  const downloadSwmsPdf = async () => {
    downLoadDoc(swms.swms_pdf_link);
  }

  const highestRiskTotals = useMemo(() => {
    let res = 0;
    if (previewData && previewData.high_risk_activities && previewData.high_risk_activities.high_risk_activities_item) {
      let high_risk = previewData.high_risk_activities.high_risk_activities_item.high_risk ? previewData.high_risk_activities.high_risk_activities_item.high_risk.length : 0;
      let critical_risk = previewData.high_risk_activities.high_risk_activities_item.critical_risk ? previewData.high_risk_activities.high_risk_activities_item.critical_risk.length : 0;
      let enviromental_risk = previewData.high_risk_activities.high_risk_activities_item.enviromental_risk ? previewData.high_risk_activities.high_risk_activities_item.enviromental_risk.length : 0;
      let highest = high_risk;
      if (critical_risk > highest) {
        highest = critical_risk;
      }
      if (enviromental_risk > highest) {
        highest = enviromental_risk;
      }
      res = highest;
    }
    return res;
  }, [previewData])

  return (
    <div className={`${styles.PreviewContainer} d-flex`} style={{ flexDirection: "column" }}>
      <div className="container-fluid">
        {loading ?
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 d-flex align-items-center justify-content-center p-5">
                <CircularProgress size={20} />
              </div>
            </div>
          </div>
          :
          <div>
            <header className="container-fluid pt-3">
              <div className="row my-1 align-items-center">
                <div className="col-3">
                  <div className={styles.PreviewLogoWrapper}>
                    <img className={styles.JobPreviewLogo} src={previewData && previewData.org_details && previewData.org_details.logo ? previewData.org_details.logo : ''} alt="" />
                  </div>
                </div>
                <div className="col-3">
                  <div>
                    <span>{previewData && previewData.org_details && previewData.org_details.phone_number ?
                      (<span className="quote-icons-alignment"><CallIcon className="quote-preview-icons" /> {previewData.org_details.phone_number}</span>) : ""}</span>
                  </div>
                  <div>
                    <span >{previewData && previewData.org_details && previewData.org_details.website ?
                      (<span className="quote-icons-alignment"><LanguageIcon className="quote-preview-icons" /> {previewData.org_details.website}</span>) : ""}</span>
                  </div>
                  <div>
                    <span >{previewData && previewData.org_details && previewData.org_details.email_address ?
                      (<span className="quote-icons-alignment"><EmailIcon className="quote-preview-icons" /> {previewData.org_details.email_address}</span>) : ""}</span>
                  </div>
                </div>
                <div className="col-3">
                  <div>
                    <span>{previewData && previewData.org_details && previewData.org_details.address ?
                      (<span className="quote-icons-alignment"><LocationOnIcon className="quote-preview-icons" /> {previewData.org_details.address}</span>) : ""}</span>
                  </div>
                  <div>
                    <span >{previewData && previewData.org_details && previewData.org_details.abn_acn ?
                      (<span><span style={{ color: "#2aa3dd" }} className="bolded-text">ABN: </span>{previewData.org_details.abn_acn}</span>) : ""}</span>
                  </div>
                </div>
                <div className="col-3">
                  <div className="pdf-head-swms my-0">SAFE WORK METHOD STATEMENT (SWMS)</div>
                </div>
              </div>
            </header>
            <div className="container-fluid pt-3 px-5">
              <div>
                <div className="row">
                  <div className="col-3 d-flex swms-rev-box">
                    <div className="pr-3">Job Number</div>
                    <div>{previewData && previewData.job_number ? previewData.job_number : ""}</div>
                  </div>
                  <div className="col-3 d-flex swms-rev-box">
                    <div className="pr-3">SWMS Number</div>
                    <div>{swms && swms.swms_number ? swms.swms_number : ""}</div>
                  </div>
                  <div className="col-3 d-flex swms-rev-box">
                    <div className="pr-3">SWMS Revision</div>
                    <div>{swms && swms.swms_revision ? swms.swms_revision : ""}</div>
                  </div>
                  <div className="col-3 d-flex swms-rev-box">
                    <div className="pr-3">SWMS Date</div>
                    <div>{previewData && previewData.swms_created_date ? moment.utc(previewData.swms_created_date).format('DD/MM/YYYY') : ""}</div>
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <div className="row">
                  <div style={{ borderRight: "none" }} className="col-6 d-flex swms-border">
                    <div className="bolded-text pdf-heading-space">Site Name</div>
                    <div className="pdf-content-space">{previewData && previewData.site_details && previewData.site_details.site_name ? previewData.site_details.site_name : ""}</div>
                  </div>
                  <div className="col-6 d-flex swms-border">
                    <div className="bolded-text pdf-heading-space">Site Address</div>
                    <div style={{ width: "480px" }} className="pdf-content-space">{previewData && previewData.site_details && previewData.site_details.site_address ? previewData.site_details.site_address : ""}</div>
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <div className="row">
                  <div style={{ borderRight: "none" }} className="col-6 d-flex swms-border-no-bottom">
                    <div className="bolded-text pdf-heading-space">Client Name</div>
                    <div className="pdf-content-space">{previewData && previewData.client_details && previewData.client_details.client_name ? previewData.client_details.client_name : ""}</div>
                  </div>
                  <div className="col-6 d-flex swms-border-no-bottom">
                    <div className="bolded-text pdf-heading-space">Client Contact</div>
                    <div className="pdf-content-space">{previewData && previewData.client_details && previewData.client_details.client_contact_name ? previewData.client_details.client_contact_name : ""}</div>
                  </div>
                </div>
                <div className="row">
                  <div style={{ borderRight: "none" }} className="col-6 d-flex swms-border-no-bottom">
                    <div className="bolded-text pdf-heading-space">Client ABN</div>
                    <div className="pdf-content-space">{previewData && previewData.client_details && previewData.client_details.client_abn_acn ? previewData.client_details.client_abn_acn : ""}</div>
                  </div>
                  <div className="col-6 d-flex swms-border-no-bottom">
                    <div className="bolded-text pdf-heading-space">Client Phone</div>
                    <div className="pdf-content-space">{previewData && previewData.client_details && previewData.client_details.client_contact_phone ? previewData.client_details.client_contact_phone : ""}</div>
                  </div>
                </div>
                <div className="row">
                  <div style={{ borderRight: "none" }} className="col-6 d-flex swms-border">
                    <div className="bolded-text pdf-heading-space">Client Address</div>
                    <div className="pdf-content-space">{previewData && previewData.client_details && previewData.client_details.client_address ? previewData.client_details.client_address : ""}</div>
                  </div>
                  <div className="col-6 d-flex swms-border">
                    <div className="bolded-text pdf-heading-space">Client Email</div>
                    <div className="pdf-content-space">{previewData && previewData.client_details && previewData.client_details.client_contact_email ? previewData.client_details.client_contact_email : ""}</div>
                  </div>
                </div>
              </div>


              {previewData && previewData.swms_revisions_and_approvals && previewData.swms_revisions_and_approvals.toggle && previewData.swms_revisions_and_approvals.toggle === 1 ? (
                <div className="row pt-3">
                  <div className="col-12 px-0">
                    <div className="">
                      <table className={styles.SwmsPreviewTable}>
                        <thead>
                          <tr>
                            <th width="15%">Action</th>
                            <th width="30%">Name</th>
                            <th width="25%">Position</th>
                            <th width="10%" className="text-center">Date</th>
                            <th width="20%">Signature</th>
                          </tr>
                        </thead>
                        <tbody>
                          {previewData.swms_revisions_and_approvals.revision_and_approval_items && previewData.swms_revisions_and_approvals.revision_and_approval_items.map((item, index) => (
                            <tr key={index}>
                              <td>{item.actions ? item.actions : ""}</td>
                              <td>{item.approver_name ? item.approver_name : ""}</td>
                              <td>{item.position ? item.position : ""}</td>
                              <td className="text-center">{item.revision_date ? moment.utc(item.revision_date).format("DD/MM/YYYY") : ""}</td>
                              <td>{item.approval_sign ? <img src={item.approval_sign} alt="" style={{ maxHeight: "30px" }} /> : ""}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : ""}

              {previewData && previewData.task_details ? (
                <div>
                  <div className="row pt-3">
                    <div style={{ borderRight: "none" }} className="col-4 align-items-center d-flex swms-border-no-bottom">
                      <div className="bolded-text pr-3">Task Number</div>
                      <div>{previewData.task_details.task_number ? previewData.task_details.task_number : ""}</div>
                    </div>
                    <div style={{ borderRight: "none" }} className="col-4 align-items-center d-flex swms-border-no-bottom">
                      <div className="bolded-text pr-3">Task Name</div>
                      <div>{previewData.task_details.task_name ? previewData.task_details.task_name : ""}</div>
                    </div>
                    <div className="col-4 align-items-center d-flex swms-border-no-bottom">
                      <div className="bolded-text pr-3">Task Area</div>
                      <div style={{ width: "340px"}}>{previewData.task_details.task_areas ? previewData.task_details.task_areas : ""}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div style={{ borderRight: "none" }} className="col-6 align-items-center d-flex swms-border">
                      <div className="bolded-text pr-3">Task Details</div>
                      <div style={{ width: "580px"}}>{previewData.task_details.details ? previewData.task_details.details : ""}</div>
                    </div>
                    <div className="col-6 align-items-center d-flex swms-border">
                      <div className="bolded-text pr-3">Task Notes</div>
                      <div style={{ width: "580px"}}>{previewData.task_details.note ? previewData.task_details.note : ""}</div>
                    </div>
                  </div>
                  <div className="row">
                    {previewData.task_details.task_documents_images && previewData.task_details.task_documents_images.length > 0 ? (
                      <div style={{ borderTop: "none" }} className="col-12 align-items-center d-flex swms-border">
                        <div className="bolded-text pr-3">
                          Task Photos
                        </div>
                        <div>
                          <div className="d-flex align-items-stretch">
                            {previewImages.map((x, index) => (
                              <div className="d-flex align-items-center justify-content-center" style={{ flex: "0 0 120px", padding: "10px 10px", borderRight: "1px solid #ccc", height: "120px" }} key={index}>
                                <a href={`${filesBasePath}${x.file_name}`} alt="" rel="noopener noreferrer" target="_blank" >
                                  {x.type === "img" ? (
                                    <img src={`${filesBasePath}resized/100x100/${x.file_name}`} style={{ width: "100px", height: "100px", objectFit: "scale-down" }} />
                                  ) : x.type === "pdf" ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: "60px", width: "60px", color: "#29598e" }}>
                                      <path fill="currentColor" d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V304H176c-35.3 0-64 28.7-64 64V512H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM176 352h32c30.9 0 56 25.1 56 56s-25.1 56-56 56H192v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V448 368c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24H192v48h16zm96-80h32c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H304c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H320v96h16zm80-112c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V432 368z" />
                                    </svg>
                                  ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" style={{ height: "60px", width: "60px", color: "#29598e" }} viewBox="0 0 384 512">
                                      <path fill="currentColor" d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm54.2 253.8c-6.1 20.3-24.8 34.2-46 34.2H80c-8.8 0-16-7.2-16-16s7.2-16 16-16h8.2c7.1 0 13.3-4.6 15.3-11.4l14.9-49.5c3.4-11.3 13.8-19.1 25.6-19.1s22.2 7.7 25.6 19.1l11.6 38.6c7.4-6.2 16.8-9.7 26.8-9.7c15.9 0 30.4 9 37.5 23.2l4.4 8.8H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-6.1 0-11.6-3.4-14.3-8.8l-8.8-17.7c-1.7-3.4-5.1-5.5-8.8-5.5s-7.2 2.1-8.8 5.5l-8.8 17.7c-2.9 5.9-9.2 9.4-15.7 8.8s-12.1-5.1-13.9-11.3L144 349l-9.8 32.8z" />
                                    </svg>
                                  )}
                                </a>
                              </div>
                            ))}
                            <div style={{ flex: "0 0 120px" }} className="d-flex align-items-end justify-content-center text-center pb-3">
                              <a href={taskImagesUrl} target="_blank" className={styles.SwmsPreviewLinkImg}>View More</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : ""}
                  </div>
                </div>
              ) : ""}

              {previewData && previewData.high_risk_activities && previewData.high_risk_activities.high_risk_activities_item ? (
                <div className="row my-3">
                  <div className="col-12 px-0">
                    <div className="swms-v4-table-heading text-uppercase">
                      LIST HIGH RISK ACTIVITIES ASSOCIATED WITH THIS JSEA/SWMS
                    </div>
                    <div className="swms-v4-border">
                      <table className="swms-v4-table">
                        <thead>
                          <tr>
                            <th className="swms-v4-critical-th text-uppercase">Critical Risks</th>
                            <th className="swms-v4-high-th text-uppercase">High Risks</th>
                            <th className="swms-v4-env-th text-uppercase">Environmental Risks</th>
                          </tr>
                        </thead>
                        <tbody>
                          {highestRiskTotals ? Array(highestRiskTotals).fill("_").map((_, i) => (
                            <tr key={i}>
                              {previewData.high_risk_activities.high_risk_activities_item.critical_risk && previewData.high_risk_activities.high_risk_activities_item.critical_risk[i] ? (
                                <td className={`${previewData.high_risk_activities.high_risk_activities_item.critical_risk[i].selected === 1 ? "swms-v4-bg-light" : ""} text-center`}>
                                  {previewData.high_risk_activities.high_risk_activities_item.critical_risk[i].risk_name}
                                </td>
                              ) : (<td></td>)}
                              {previewData.high_risk_activities.high_risk_activities_item.high_risk && previewData.high_risk_activities.high_risk_activities_item.high_risk[i] ? (
                                <td className={`${previewData.high_risk_activities.high_risk_activities_item.high_risk[i].selected === 1 ? "swms-v4-bg-light" : ""} text-center`}>
                                  {previewData.high_risk_activities.high_risk_activities_item.high_risk[i].risk_name}
                                </td>
                              ) : (<td></td>)}
                              {previewData.high_risk_activities.high_risk_activities_item.enviromental_risk && previewData.high_risk_activities.high_risk_activities_item.enviromental_risk[i] ? (
                                <td className={`${previewData.high_risk_activities.high_risk_activities_item.enviromental_risk[i].selected === 1 ? "swms-v4-bg-light" : ""} text-center`}>
                                  {previewData.high_risk_activities.high_risk_activities_item.enviromental_risk[i].risk_name}
                                </td>
                              ) : (<td></td>)}
                            </tr>
                          )) : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* {previewData && previewData.high_risk_activities && previewData.high_risk_activities.high_risk_activities_item ? (
                <div className="row mt-3">
                  <div className="col-12">
                    <div className="content-box">
                      <div className="form-group">
                        <label className="half-width">
                          High Risk Activities
                        </label>
                        <div className="content-checkbox">
                          <div className="container-fluid ">
                            <div className="row">
                              {previewData.high_risk_activities.high_risk_activities_item.critical_risk ? (
                                <div className="col-4 box-check px-0">
                                  <div className={`px-3 ${styles.SwmsPreviewSubHeading}`}>Critical Risks</div>
                                  <ul className="mt-1">
                                    {previewData.high_risk_activities.high_risk_activities_item.critical_risk.map((item, index) => (
                                      <li className="w-100" key={index}>
                                        <div className="form-check">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={item.selected === 1}
                                            readOnly
                                          />
                                          <label className="form-check-label">
                                            {item.risk_name}
                                          </label>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </div>) : ""}
                              {previewData.high_risk_activities.high_risk_activities_item.high_risk ? (
                                <div className="col-4 box-check px-0">
                                  <div className={`px-3 ${styles.SwmsPreviewSubHeading}`}>High Risks</div>
                                  <ul className="mt-1">
                                    {previewData.high_risk_activities.high_risk_activities_item.high_risk.map((item, index) => (
                                      <li className="w-100" key={index}>
                                        <div className="form-check">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={item.selected === 1}
                                            readOnly
                                          />
                                          <label className="form-check-label">
                                            {item.risk_name}
                                          </label>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </div>) : ""}
                              {previewData.high_risk_activities.high_risk_activities_item.enviromental_risk ? (
                                <div className="col-4 box-check px-0">
                                  <div className={`px-3 ${styles.SwmsPreviewSubHeading}`}>Environmental Risks</div>
                                  <ul className="mt-1">
                                    {previewData.high_risk_activities.high_risk_activities_item.enviromental_risk.map((item, index) => (
                                      <li className="w-100" key={index}>
                                        <div className="form-check">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={item.selected === 1}
                                            readOnly
                                          />
                                          <label className="form-check-label">
                                            {item.risk_name}
                                          </label>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </div>) : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : ""} */}

              {previewData && previewData.permits_section && previewData.permits_section.toggle === 1 && previewData.permits_section.permits ? (
                <div className="row my-3">
                  <div className="col-12 px-0">
                    <div className="swms-v4-table-heading text-uppercase">
                      Permits Required
                    </div>
                    <div className="swms-v4-border">
                      <table className="swms-v4-table">
                        <thead>
                          <tr>
                            <th className="swms-v4-normal-th px-3 font-weight-bold">Permits</th>
                            <th className="swms-v4-normal-th px-3 font-weight-bold" width="20%">Document</th>
                          </tr>
                        </thead>
                        <tbody>
                          {previewData.permits_section.permits.map((permit, index) => (
                            <tr key={index}>
                              <td className="px-3">{permit.permit_name}</td>
                              <td className="px-3">
                                {permit.permit_document ? (
                                  <a target="_blank" href={`${filesBasePath}${permit.permit_document.file_name}`} className={styles.SwmsPreviewLink}>Attached Permit</a>
                                ) : ""}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* {previewData && previewData.permits_section && previewData.permits_section.toggle === 1 && previewData.permits_section.permits ? (
                <div className="row mt-3">
                  <div className="col-12">
                    <div className="content-box">
                      <div className="form-group">
                        <label className="half-width">
                          Permits Required
                        </label>
                        <div className="">
                          <table className={styles.SwmsPreviewTable}>
                            <thead>
                              <tr>
                                <th>Permit</th>
                                <th className="text-center" width="20%">Document</th>
                              </tr>
                            </thead>
                            <tbody>
                              {previewData.permits_section.permits.map((permit, index) => (
                                <tr key={index}>
                                  <td>{permit.permit_name}</td>
                                  <td className="text-center">
                                    {permit.permit_document ? (
                                      <a target="_blank" href={`${filesBasePath}${permit.permit_document.file_name}`} className={styles.SwmsPreviewLink}>Attached Permit</a>
                                    ) : ""}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : ""} */}

              {previewData && previewData.plant_and_equipment_details && previewData.plant_and_equipment_details.toggle === 1 ?
                <PreviewSwmsCheckboxSection
                  sectionHeader="Plant Equipment"
                  data={previewData.plant_and_equipment_details.plant_and_equipment_items}
                />
                : null}
              {previewData && previewData.material_details && previewData.material_details.toggle === 1 ?
                <PreviewSwmsCheckboxSection
                  sectionHeader="Materials Required"
                  data={previewData.material_details.material_items}
                />
                : null}
              {previewData && previewData.sds_details && previewData.sds_details.toggle === 1 ?
                <PreviewSwmsSdsSection
                  sectionHeader="Safety Data Sheets"
                  data={previewData.sds_details.sds_items}
                  filesBasePath={filesBasePath}
                /> : null}
              {previewData && previewData.tool_details && previewData.tool_details.toggle === 1 ?
                <PreviewSwmsCheckboxSection
                  sectionHeader="Tools"
                  data={previewData.tool_details.tool_items}
                />
                : null}

              {previewData && previewData.ppe_details && previewData.ppe_details.toggle === 1 ?
                <PreviewSwmsCheckboxSection
                  sectionHeader="PPE Requirements"
                  data={previewData.ppe_details.ppe_items}
                />
                : null}

              {previewData && previewData.training_and_licenses_details && previewData.training_and_licenses_details.toggle === 1 ?
                <PreviewSwmsCheckboxSection
                  sectionHeader="Training, Licenses and Competencies"
                  data={previewData.training_and_licenses_details.training_and_license_items}
                />
                : null}

              {previewData && previewData.legislation_safety_acts_and_standards_details && previewData.legislation_safety_acts_and_standards_details.toggle === 1 ?
                <PreviewSwmsCheckboxSection
                  sectionHeader="Legislation / Regulations / Standards and COP"
                  data={previewData.legislation_safety_acts_and_standards_details.legislation_safety_acts_and_standard_items}
                />
                : null}

              <div className="row mt-3">
                {previewData && previewData.likelihoods ? (
                  <div className="col-6 pl-0">
                    <div className="swms-v4-table-heading text-uppercase">
                      Step 1 - Determine the likelihood
                    </div>
                    <div className="swms-v4-border">
                      <table className="swms-v4-table">
                        <thead>
                          <tr className="">
                            <th width="30%" className="swms-v4-light-bg-th px-3 font-weight-bold">Likelihood</th>
                            <th width="15%" className="swms-v4-light-bg-th px-3 font-weight-bold text-center">Value</th>
                            <th className="swms-v4-light-bg-th px-3 font-weight-bold">Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {previewData.likelihoods.map(like => (
                            <tr key={like.id}>
                              <td className="px-3 font-weight-bold text-uppercase">{like.name}</td>
                              <td className="px-3 text-center">{like.value}</td>
                              <td className="px-3">{like.description}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : null}

                {previewData && previewData.consequences ? (
                  <div className="col-6 pr-0">
                    <div className="swms-v4-table-heading text-uppercase">
                      Step 2 - Determine the Consequences
                    </div>
                    <div className="swms-v4-border">
                      <table className="swms-v4-table">
                        <thead>
                          <tr className="">
                            <th width="30%" className="swms-v4-light-bg-th font-weight-bold px-3">Consequence</th>
                            <th width="15%" className="swms-v4-light-bg-th font-weight-bold px-3 text-center">Value</th>
                            <th className="swms-v4-light-bg-th font-weight-bold px-3">Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {previewData.consequences.map(cons => (
                            <tr key={cons.id}>
                              <td className="px-3 font-weight-bold text-uppercase">{cons.name}</td>
                              <td className="px-3 text-center">{cons.value}</td>
                              <td className="px-3">{cons.description}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : null}
              </div>

              {previewData && previewData.risk_matrix_section && previewData.risk_matrix_section.toggle === 1 ? (
                // <div className="row mt-3">
                //   <div className="col-12">
                //     <div className="content-box">
                //       <div className="form-group">
                //         <label className="half-width">
                //           Risk Assesment Matrix
                //         </label>
                //         <div className="content-checkbox">
                <RiskMatrixSection
                  consequences={previewData && previewData.consequences ? previewData.consequences : []}
                  likelihoods={previewData && previewData.likelihoods ? previewData.likelihoods : []}
                  risks={previewData && previewData.risk_ratings && previewData.risk_ratings.risk_rating_items ? previewData.risk_ratings.risk_rating_items : []} />
                //         </div>
                //       </div>
                //     </div>
                //   </div>
                // </div>
              ) : ""}

              {previewData && previewData.activities && previewData.activities.swms_activities ? (
                <div className="row mt-3">
                  <div className="col-12 px-0">
                    <div className="swms-v4-table-heading">
                      JOB SAFETY ANALYSIS (JSA) AND SAFE WORK METHOD STATEMENT (SWMS)
                    </div>
                    <div className="swms-v4-border">
                      <div className="mt-2"></div>
                      {previewData.risk_ratings && previewData.risk_ratings.risk_rating_items && previewData.risk_ratings.risk_rating_items.length > 0 ? (
                        <table className="swms-v4-table">
                          <thead>
                            <tr>
                              {previewData.risk_ratings.risk_rating_items.map(risk => (
                                <th key={risk.id} className="px-3 font-weight-bold text-center" style={{ backgroundColor: risk.risk_colours }} >{risk.risk_ratings} {risk.risk_rating_low_value} - {risk.risk_rating_high_value}</th>
                              ))}
                            </tr>
                          </thead>
                        </table>) : null}
                      <table className="swms-v4-table">
                        <thead>
                          <tr className="swms-v4-jsa-tr">
                            <th width="80px" className="text-center">Step No.</th>
                            <th className="text-center">Job Steps / Activity</th>
                            <th className="text-center">Potential Hazards</th>
                            <th className="text-center">Consequences</th>
                            <th width="40px" className="text-center">IR</th>
                            <th className="text-center">Hazard Control Measures</th>
                            <th width="40px" className="text-center">RR</th>
                            <th className="text-center" width="15%">Action By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {previewData.activities.swms_activities.map((item, index) => (
                            <React.Fragment key={index}>
                              <tr>
                                <td className="font-weight-bold" style={{ backgroundColor: "#96c6e7" }} colSpan={8}>
                                  {item.activity_name ? item.activity_name : ""}
                                </td>
                              </tr>
                              {item.fm_swms_activities_hazards.length === 0 ? (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td style={{ whiteSpace: "pre", textWrap: "wrap" }}>{item.description ? item.description : ""}</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              ) : item.fm_swms_activities_hazards.map((haz, i) => (
                                <React.Fragment key={`${index}_hazard_${i}`}>
                                  <tr>
                                    {i === 0 ? (
                                      <>
                                        <td rowSpan={item.rowCount}>{index + 1}</td>
                                        <td rowSpan={item.rowCount} style={{ whiteSpace: "pre", textWrap: "wrap" }}>{item.description ? item.description : ""}</td>
                                      </>
                                    ) : null}
                                  </tr>
                                  {haz.fm_swms_hazards_consequences.length === 0 ? (
                                    <tr>
                                      <td>{haz.hazard_name ? haz.hazard_name : ""}</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  ) : haz.fm_swms_hazards_consequences.map(((cons, i) => (
                                    <tr key={i}>
                                      {i === 0 ? (
                                        <td rowSpan={haz.rowCount}>{haz.hazard_name ? haz.hazard_name : ""}</td>
                                      ) : null}
                                      <td>{cons.consequences_name ? cons.consequences_name : ""}</td>
                                      <td style={{ background: cons.irColor ? cons.irColor : "" }} className="text-center">{cons.initial_risk ? cons.initial_risk : ""}</td>
                                      <td style={{ whiteSpace: "pre", textWrap: "wrap" }}>{cons.control_measures ? cons.control_measures : ""}</td>
                                      <td style={{ background: cons.rrColor ? cons.rrColor : "" }} className="text-center">{cons.residual_risk ? cons.residual_risk : ""}</td>
                                      <td>{cons.action_by ? cons.action_by : ""}</td>
                                    </tr>
                                  )))}
                                </React.Fragment>
                              ))}
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* {previewData && previewData.activities && previewData.activities.swms_activities ? (
                <div className="row mt-3">
                  <div className="col-12">
                    <div className="content-box">
                      <div className="form-group">
                        <label className="half-width">
                          Job Safety Analysis (JSA) and Safe Work Method Statement SWMS
                        </label>
                        <div className="">
                          <table className={`${styles.SwmsPreviewTable} ${styles.JSATable}`}>
                            <thead>
                              <tr>
                                <th width="80px" className="text-center">Step No.</th>
                                <th>Job Steps / Activity</th>
                                <th>Potential Hazards</th>
                                <th>Consequences</th>
                                <th width="40px" className="text-center">IR</th>
                                <th>Hazard Control Measures</th>
                                <th width="40px" className="text-center">RR</th>
                                <th width="15%">Action By</th>
                              </tr>
                            </thead>
                            <tbody>
                              {previewData.activities.swms_activities.map((item, index) => (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td style={{ backgroundColor: "#dbe2f1" }} colSpan={8}>
                                      {item.activity_name ? item.activity_name : ""}
                                    </td>
                                  </tr>
                                  {item.fm_swms_activities_hazards.length === 0 ? (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td style={{ whiteSpace: "pre", textWrap: "wrap" }}>{item.description ? item.description : ""}</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  ) : item.fm_swms_activities_hazards.map((haz, i) => (
                                    <React.Fragment key={`${index}_hazard_${i}`}>
                                      <tr>
                                        {i === 0 ? (
                                          <>
                                            <td rowSpan={item.rowCount}>{index + 1}</td>
                                            <td rowSpan={item.rowCount} style={{ whiteSpace: "pre", textWrap: "wrap" }}>{item.description ? item.description : ""}</td>
                                          </>
                                        ) : null}
                                      </tr>
                                      {haz.fm_swms_hazards_consequences.length === 0 ? (
                                        <tr>
                                          <td>{haz.hazard_name ? haz.hazard_name : ""}</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      ) : haz.fm_swms_hazards_consequences.map(((cons, i) => (
                                        <tr key={i}>
                                          {i === 0 ? (
                                            <td rowSpan={haz.rowCount}>{haz.hazard_name ? haz.hazard_name : ""}</td>
                                          ) : null}
                                          <td>{cons.consequences_name ? cons.consequences_name : ""}</td>
                                          <td className="text-center">{cons.initial_risk ? cons.initial_risk : ""}</td>
                                          <td style={{ whiteSpace: "pre", textWrap: "wrap" }}>{cons.control_measures ? cons.control_measures : ""}</td>
                                          <td className="text-center">{cons.residual_risk ? cons.residual_risk : ""}</td>
                                          <td>{cons.action_by ? cons.action_by : ""}</td>
                                        </tr>
                                      )))}
                                    </React.Fragment>
                                  ))}
                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              ) : ""} */}

              {previewData && previewData.key_contacts && previewData.key_contacts.toggle === 1 && previewData.key_contacts.contacts ? (
                <div className="row mt-3">
                  <div className="col-12 px-0">
                    <div className="swms-v4-table-heading text-uppercase">
                      Key Contacts
                    </div>
                    <div className="swms-v4-border">
                      <table className="swms-v4-table">
                        <thead>
                          <tr>
                            <th className="swms-v4-light-bg-th px-3 font-weight-bold">Contact Name</th>
                            <th className="swms-v4-light-bg-th px-3 font-weight-bold">Contact Role</th>
                            <th className="swms-v4-light-bg-th px-3 font-weight-bold">Contact Number</th>
                          </tr>
                        </thead>
                        <tbody>
                          {previewData.key_contacts.contacts.map((item, index) => (
                            <tr key={index}>
                              <td className="px-3">{item.contact_name ? item.contact_name : ""}</td>
                              <td className="px-3">{item.contact_role ? item.contact_role : ""}</td>
                              <td className="px-3">{item.contact_number ? item.contact_number : ""}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* {previewData && previewData.key_contacts && previewData.key_contacts.toggle === 1 && previewData.key_contacts.contacts ? (
                <div className="row mt-3">
                  <div className="col-12">
                    <div className="content-box">
                      <div className="form-group">
                        <label className="half-width">
                          Key Contacts
                        </label>
                        <div className="">
                          <table className={styles.SwmsPreviewTable}>
                            <thead>
                              <tr>
                                <th>Contact Name</th>
                                <th>Contact Role</th>
                                <th>Contact Number</th>
                              </tr>
                            </thead>
                            <tbody>
                              {previewData.key_contacts.contacts.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.contact_name ? item.contact_name : ""}</td>
                                  <td>{item.contact_role ? item.contact_role : ""}</td>
                                  <td>{item.contact_number ? item.contact_number : ""}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : ""} */}

              {previewData && previewData.swms_consultations && previewData.swms_consultations.toggle === 1 && previewData.swms_consultations.swms_consultation_items ? (
                <div className="row mt-3">
                  <div className="col-12 px-0">
                    <div className="swms-v4-table-heading text-uppercase">
                      SWMS Consultation &amp; Communication
                    </div>
                    <div className="swms-v4-border">
                      <table className="swms-v4-table">
                        <thead>
                          <tr>
                            <th className="swms-v4-light-bg-th px-3 font-weight-bold">Consultation Method</th>
                            <th className="swms-v4-light-bg-th px-3 font-weight-bold">Participants</th>
                            <th className="swms-v4-light-bg-th px-3 font-weight-bold">Procedure</th>
                          </tr>
                        </thead>
                        <tbody>
                          {previewData.swms_consultations.swms_consultation_items.map((item, index) => (
                            <tr key={index}>
                              <td className="px-3">{item.scc_method_name ? item.scc_method_name : ""}</td>
                              <td className="px-3">{item.required_participants ? item.required_participants : ""}</td>
                              <td className="px-3">{item.procedures ? item.procedures : ""}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* {previewData && previewData.swms_consultations && previewData.swms_consultations.toggle === 1 && previewData.swms_consultations.swms_consultation_items ? (
                <div className="row mt-3">
                  <div className="col-12">
                    <div className="content-box">
                      <div className="form-group">
                        <label className="half-width">
                          SWMS Consultation &amp; Communication
                        </label>
                        <div className="">
                          <table className={styles.SwmsPreviewTable}>
                            <thead>
                              <tr>
                                <th>Consultation Method</th>
                                <th>Participants</th>
                                <th>Procedure</th>
                              </tr>
                            </thead>
                            <tbody>
                              {previewData.swms_consultations.swms_consultation_items.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.scc_method_name ? item.scc_method_name : ""}</td>
                                  <td>{item.required_participants ? item.required_participants : ""}</td>
                                  <td>{item.procedures ? item.procedures : ""}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : ""} */}

              {previewData && previewData.swms_sign_off && previewData.swms_sign_off.length > 0 ? (
                <div>
                  <div className="row my-3 aviod-break">
                    <div className="col-12 px-0">
                      <div className="swms-v4-table-heading text-uppercase">
                        SWMS Sign off
                      </div>
                      <div className="swms-v4-border px-3 py-2">
                        <div className="container-fluid">
                          <div className="row" style={{ rowGap: "5px"}}>
                            <div className="col-12 px-0">
                              <div className="w-full">
                                We the undersigned, confirm that the SWMS nominated
                                above has been explained and its contents are clearly
                                understood and accepted. We also confirm that our
                                required qualifications to undertake this activity are
                                current. We also clearly understand that the controls
                                in this SWMS must be applied as documented; otherwise,
                                work will cease immediately.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-2">
                    <div className="col-12 px-0">
                      <div className="">
                        <table className={styles.SwmsPreviewTable}>
                          <thead>
                            <tr>
                              <th width="10%" className="text-center">Date</th>
                              <th width="20%">Name</th>
                              <th width="15%">Role</th>
                              <th>Sign</th>
                            </tr>
                          </thead>
                          <tbody>
                            {previewData.swms_sign_off.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center">{item.sign_off_date ? moment(item.sign_off_date).format("DD/MM/YYYY") : ""}</td>
                                <td>{item.staff_name ? item.staff_name : ""}</td>
                                <td>{item.role_name ? item.role_name : ""}</td>
                                <td>{item.staff_sign ? <img src={item.staff_sign} alt="" style={{ maxHeight: "30px" }} /> : ""}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ) : ""}
            </div>
          </div>
        }
      </div >
      {swms.swms_pdf_link ? (
        <div className="border-top pt-2 mx-4" style={{ flex: "0 0 auto" }}>
          <div className="d-flex align-items-center justify-content-end" style={{ gap: "5px" }}>
            <button
              className={styles.OutlinedButton}
              onClick={() => { onClose() }}
            >
              Cancel
            </button>
            <button
              className={`${styles.PrimaryButton} mx-2`}
              disabled={loading}
              onClick={() => { downloadSwmsPdf() }}
            >
              Download SWMS
            </button>
          </div>
        </div>) : ""}
    </div>

  )
}