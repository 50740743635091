import { SET_TIMESHEETS_V2, SET_STAFF_TIMESHEETSV2, SET_SITE_TIMESHEETSV2, SET_STATES_FILTER_TIMESHEETSV2, SET_SHIFT_HISTORY_TIMESHEETSV2, CLEAR_TIMESHEETS_V2_STAFFS, CLEAR_TIMESHEETS_V2_SITES } from "../../dataProvider/constant";
const INITIAL_STATE = {
  timesheets: [],
  sheetsCount: 0,
  staffSheets: [],
  siteSheets: [],
  statesList: [],
  statusList: [
    { label: "Approved", value: 1 },
    { label: "In Progress", value: 0 },
    { label: "Signed Off", value: 2 }
  ],
  shiftHistory: []
}

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_TIMESHEETS_V2: {
      return {
        ...state,
        timesheets: payload.rows,
        sheetsCount: payload.count
      }
    }
    case SET_STAFF_TIMESHEETSV2: {
      return {
        ...state,
        staffSheets: payload.map(x => ({...x, client_name: x.clients ? x.clients.length === 1 ? x.clients[0] : "Multiple" : ""}))
      }
    }
    case SET_SITE_TIMESHEETSV2: {
      return {
        ...state,
        siteSheets: payload
      }
    }
    case SET_STATES_FILTER_TIMESHEETSV2: {
      return {
        ...state,
        statesList: payload
      }
    }
    case SET_SHIFT_HISTORY_TIMESHEETSV2: {
      return {
        ...state,
        shiftHistory: payload
      }
    }
    case CLEAR_TIMESHEETS_V2_STAFFS: {
      return {
        ...state,
        staffSheets: []
      }
    }
    case CLEAR_TIMESHEETS_V2_SITES: {
      return {
        ...state,
        siteSheets: []
      }
    }
    default: {
      return state;
    }
  }
}