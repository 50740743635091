import { Component } from "react";
import { connect } from "react-redux";
import { customInput } from "../../../../../components/common/custom-input";
import { CustomDatepicker } from "../../../../../components/common/customDatepicker";
import React from "react";
import { Field, FieldArray } from "redux-form";
import styles from "./PostAllocateStyles.module.css";
import { CustomReactTimePicker } from "../../../../../components/common/customReactTimePicker";
import "./PostAllocateStyles.css";
import { Icon } from "antd";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import jobStyles from "../../../Job/JobStyle.module.css";

export class SchedulesTable extends Component {
  renderScheduleMembers = ({
    fields,
    meta: { error, submitFailed },
    schedules,
  }) => {
    return (
      <div className="jobs-form mx-2">
        {fields.map((member, index) => (
          <div className="container-fluid pt-2" key={index}>
            <div className={`row align-items-center justify-content-center ${jobStyles.card}`}>
              <div className="col-11 px-0">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-3">
                      <div className={styles.JobsFormLabel}>Start Date</div>
                      <Field
                        name={`${member}.start_date`}
                        component={CustomDatepicker}
                        className="allocate-date-picker"
                        dropdownClassName="cs-datepicker-dropdown"
                      />
                    </div>
                    <div className="col-3">
                      <div className={styles.JobsFormLabel}>Start Time</div>
                      <Field
                        name={`${member}.start_time`}
                        component={CustomReactTimePicker}
                        className="allocate-date-picker"
                        wrapperClassName="jobs-timepicker-wrapper"
                      />
                    </div>
                    <div className="col-3">
                      <div className={styles.JobsFormLabel}>End Date</div>
                      <Field
                        name={`${member}.finish_date`}
                        component={CustomDatepicker}
                        className="allocate-date-picker"
                        dropdownClassName="cs-datepicker-dropdown"
                      />
                    </div>
                    <div className="col-3">
                      <div className={styles.JobsFormLabel}>End Time</div>
                      <Field
                        name={`${member}.finish_time`}
                        component={CustomReactTimePicker}
                        className="allocate-date-picker"
                        wrapperClassName="jobs-timepicker-wrapper"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <div className={styles.JobsFormLabel}>Task Details</div>
                      <Field
                        name={`${member}.task_details`}
                        type="text"
                        component={customInput}
                        className={styles.JobsFormField}
                      />
                    </div>
                    <div className="col-4">
                      <div className={styles.JobsFormLabel}>Area</div>
                      <Field
                        name={`${member}.area`}
                        type="text"
                        component={customInput}
                        className={styles.JobsFormField}
                      />
                    </div>
                    <div className="col-4">
                      <div className={styles.JobsFormLabel}>Site Requirements</div>
                      <Field
                        name={`${member}.site_requirements`}
                        type="text"
                        component={customInput}
                        className={styles.JobsFormField}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-1">
                <IconButton
                  className="px-1" sx={{ height: "auto", color: "#90bf00" }}
                  size="small"
                  onClick={() => fields.remove(index)}
                >
                  <DeleteIcon sx={{ fontSize: "21px !important" }} />
                </IconButton>
              </div>
            </div>
          </div>
        ))}
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <button
                type="button"
                className={`${styles.TableButton} ${styles.LabelGreen}`}
                onClick={() => fields.push({})}
              >
                <Icon type="plus" className="mx-2" /> Add Schedule
              </button>
            </div>
          </div>
        </div>
      </div >
    )
  };

  render() {
    return (
      <div>
        <FieldArray name="schedules" component={this.renderScheduleMembers} />
        <div className="col-md-12">
          <div className="md-form">
            <div className={styles.JobsFormLabel}>Note</div>
            <Field
              name="note"
              type="text"
              className={styles.JobsFormField}
              autoComplete="off"
              id="note"
              component={customInput}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  initialValues: state.JobDocument.initialValues,
  formValues:
    state.form &&
      state.form.PostAllocateContainer &&
      state.form.PostAllocateContainer.values
      ? state.form.PostAllocateContainer.values
      : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulesTable);
