export const legends = [
  {
    key: "created",
    label: "Created",
    color: "#00B0F0",
  },
  {
    key: "notified",
    label: "Notified",
    color: "#F5A623",
  },
  {
    key: "inProgress",
    label: "In Progress",
    color: "#7ED321",
  },
  {
    key: "completed",
    label: "Completed",
    color: "#4A4A4A",
  },
];
