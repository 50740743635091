import { EMAIL_JOB_REPORT_URL_V1 } from "../../dataProvider/constant";
import { scopeApiInstance } from "../../dataProvider/axiosHelper";
import { Strings } from "../../dataProvider/localize";

export const emailJobReport = (formData) => () => {
  return scopeApiInstance.post(EMAIL_JOB_REPORT_URL_V1, formData).then(res => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject();
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}