import { scopeAxiosInstance, scopeV2AxiosInstance, scopeV3AxiosInstance } from "../../dataProvider/axiosHelper";
import { scopeApiInstance } from "../../dataProvider/axiosHelper";
import { Strings } from "../../dataProvider/localize";
import { startSipnner, stopSipnner } from "../../utils/spinner";
import {
  JOB_DETAILS,
  GET_JOB_DOCUMENT,
  SET_ALLOCATED_DATA,
  UPDATE_USERS_TABLE,
  SET_JOB_DOC_INITIALVALUES,
  CLEAR_JOB_DOC_INITIALVALUES,
  GET_JOB_DOCUMENT_STAFF_LIST,
  ADD_ADDITIONAL_DOCUMENT_URL,
  GET_ORGANISATION_CERTIFICATE_URL,
  GET_JOB_DOCUMENT_ORG_CERTIFICATES,
  RESET_JOB_DOC,
  GET_DETAILS_SITES,
  SET_JOB_DOC_ORGANISATIONS,
  ORG_JOB_STAFF_LIST_URL,
  ADD_UPDATE_JOB_DOC_URL,
  JOB_DOC_EMAIL_URL_V1
} from "../../dataProvider/constant";

export const getJobDocumentDetails = (jobId, scopeDocId) => (dispatch) => {
  startSipnner(dispatch);
  const url = `${JOB_DETAILS}${jobId}`;

  return scopeV3AxiosInstance
    .get(url)
    .then((res) => {
      stopSipnner(dispatch);

      dispatch({
        type: GET_JOB_DOCUMENT,
        payload: res.data.data,
      });

      return Promise.resolve(res.data.message && res.data.message);
    })
    .catch((error) => {
      stopSipnner(dispatch);

      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const getJobDocumentStaffList = (orgs) => (dispatch) => {
  startSipnner(dispatch);
  const url = `${ORG_JOB_STAFF_LIST_URL}?contractor_ids=${orgs}`;

  return scopeV2AxiosInstance
    .get(url)
    .then((res) => {
      stopSipnner(dispatch);

      dispatch({
        type: GET_JOB_DOCUMENT_STAFF_LIST,
        payload: res.data.data,
      });

      return Promise.resolve(res.data.message && res.data.message);
    })
    .catch((error) => {
      stopSipnner(dispatch);

      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const getJobDocumentOrgCertificates = (orgId, jobId, organisations = []) => (dispatch) => {
  startSipnner(dispatch);
  const url = `${GET_ORGANISATION_CERTIFICATE_URL}?orgnisation_id=${orgId}&job_id=${jobId}`;

  return scopeApiInstance
    .get(url)
    .then((res) => {
      stopSipnner(dispatch);

      let data = res.data.data;
      let organised = organisations.map(org => ({ ...org, certificates: data.certificateList && data.certificateList.length ? data.certificateList.filter(cert => cert.organisation_id === org.id) : [] }))

      dispatch({
        type: GET_JOB_DOCUMENT_ORG_CERTIFICATES,
        payload: { ...data, certificateList: organised },
      });

      return Promise.resolve(res.data.message && res.data.message);
    })
    .catch((error) => {
      stopSipnner(dispatch);

      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const createJobDocAllocation = (requestBody, isPut) => (dispatch) => {
  startSipnner(dispatch);
  const method = isPut ? scopeV2AxiosInstance.put : scopeV2AxiosInstance.post;
  return method(ADD_UPDATE_JOB_DOC_URL, requestBody)
    .then((res) => {
      stopSipnner(dispatch);
      return Promise.resolve(res.data.message);
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const setJobDocInitialValues = (values) => (dispatch) => {
  dispatch({
    type: SET_JOB_DOC_INITIALVALUES,
    payload: values,
  });
};

export const resetJobDocInitialValues = () => (dispatch) => {
  dispatch({
    type: CLEAR_JOB_DOC_INITIALVALUES,
  });
};

export const setAllocatedData =
  (
    staffList = [],
    tasksList = [],
    chemicalsList = [],
    insurancesList = [],
    initialValues = {}
  ) =>
    (dispatch) => {
      dispatch({
        type: SET_ALLOCATED_DATA,
        users: staffList,
        tasks: tasksList,
        chemicals: chemicalsList,
        insurances: insurancesList,
        initialValues: initialValues,
      });
    };

export const updateUsersTable = (staffList) => (dispatch) => {
  dispatch({
    type: UPDATE_USERS_TABLE,
    users: staffList,
  });
};

export const updateJobDocument = (requestBody) => (dispatch) => {
  startSipnner(dispatch);
  return scopeV2AxiosInstance
    .put(`${ADD_UPDATE_JOB_DOC_URL}`, requestBody)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const saveAdditionalDocument = (formData) => (dispatch) => {
  startSipnner(dispatch);
  return scopeAxiosInstance
    .post(ADD_ADDITIONAL_DOCUMENT_URL, formData)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(true);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const resetJobDoc = () => (dispatch) => {
  dispatch({
    type: RESET_JOB_DOC,
  });
};

export const setEmailJobDetails = (job_details) => (dispatch) => {
  dispatch({
    type: GET_DETAILS_SITES,
    payload: job_details
  })
}

export const setContractorOrganisations = (organisations) => dispatch => {
  dispatch({
    type: SET_JOB_DOC_ORGANISATIONS,
    payload: organisations
  })
}

export const emailJobDocument = (formData) => () => {
  return scopeApiInstance.post(JOB_DOC_EMAIL_URL_V1, formData).then(res => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject();
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}