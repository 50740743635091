import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { reduxForm, Field, Form, formValueSelector } from 'redux-form';
import moment from 'moment';
import { Popover, notification, Checkbox } from "antd";
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import htmlToDraft from 'html-to-draftjs';

import { Strings } from "../../../../dataProvider/localize";
import { putSiteSpecificSwms } from "../../../../actions/SWMSMastersActions";
import { popOverCheckBox2 } from "../../../common/popOverCheckBox2";
import {
  getRiskRatingFormattedOptions,
  getFormattedPopBoxOptions,
  numerateOptions,
  getNewRiskRatingValueByMap,
  numerateV2Options
} from "../../../../components/dashboard/SWMSMasters/utils";
import { CustomSelect } from "../../../common/customSelect";
import { customInput } from "../../../common/custom-input";
import { getStorage } from "../../../../utils/common";
import emailEditor from "../../../common/EmailEditor";
import { ReduxFormSelect } from "../../../common/chipInputRedux";
import { validateSiteSpecific } from "../validate";

import jobStyles from "../../Job/JobStyle.module.css";
import styles from "../../../dashboard/SWMSMasters/SWMSActivity/SWMSActivityContainer.module.css";
import { CustomSingleSelect } from '../../../common/new-core-ui/CustomSingleSelect';
import { components } from 'react-select';
import colorStyles, { colorStylesMulti } from '../../Job/SelectV2SingleStyle';

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <Checkbox
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          className="cs-select-v2"
          style={{ width: 'auto', margin: '0px 5px' }}
        />
        <label style={{ margin: '0px 5px' }}>{props.label}</label>
      </components.Option>
    </div>
  );
};

let SiteSpecificSwmsForm = (props) => {

  const dispatch = useDispatch();

  const [editorState, setEditorState] = useState(EditorState.createWithContent(
    ContentState.createFromBlockArray(
      convertFromHTML(props.selectedSiteSpecific.controls ? props.selectedSiteSpecific.controls : '')
    )
  ))

  useEffect(() => {
    if (Object.keys(props.selectedSiteSpecific).length !== 0) {
      if (props.selectedSiteSpecific.control_measures) {
        let contentBlock = htmlToDraft(props.selectedSiteSpecific.control_measures);
        if (contentBlock) {
          let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          let editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
          props.change("controls", draftToHtml(convertToRaw(editorState.getCurrentContent())));
        }
      }
      let likelihoodOptions = numerateV2Options(getRiskRatingFormattedOptions(props.likelihoodBefore, "name"), true, "L");
      if (props.selectedSiteSpecific.likelihood_before_controls) {
        let likelihoodBefore = likelihoodOptions.find(x => x.value === props.selectedSiteSpecific.likelihood_before_controls);
        if (likelihoodBefore) {
          props.change("likelihood_before_controls", likelihoodBefore)
        }
      }

      if (props.selectedSiteSpecific.likelihood_after_controls) {
        let likelihoodAfter = likelihoodOptions.find(x => x.value === props.selectedSiteSpecific.likelihood_after_controls);
        if (likelihoodAfter) {
          props.change("likelihood_after_controls", likelihoodAfter)
        }
      }

      if (props.selectedSiteSpecific.consequences) {
        let newConsequences = [];
        props.selectedSiteSpecific.consequences && props.selectedSiteSpecific.consequences.forEach(s => s.site_specific_swms_consequences &&
          newConsequences.push({ label: s.site_specific_swms_consequences, value: s.site_specific_swms_consequences, id: s.id }))
        props.change("consequences", newConsequences);
      }

      let consequenceOptions = numerateV2Options(getRiskRatingFormattedOptions(props.consequencesBefore, "name"), true, "C");
      if (props.selectedSiteSpecific.consequence_before_controls) {
        let consequenceBefore = consequenceOptions.find(x => x.value === props.selectedSiteSpecific.consequence_before_controls);
        if (consequenceBefore) {
          props.change("consequence_before_controls", consequenceBefore)
        }
      }
      if (props.selectedSiteSpecific.consequence_after_controls) {
        let consequenceAfter = consequenceOptions.find(x => x.value === props.selectedSiteSpecific.consequence_after_controls);
        if (consequenceAfter) {
          props.change("consequence_after_controls", consequenceAfter)
        }
      }

      if (props.selectedSiteSpecific.hazards) {
        let newHazards = [];
        props.selectedSiteSpecific.hazards && props.selectedSiteSpecific.hazards.forEach(h => h.site_specific_swms_hazards &&
          newHazards.push({ label: h.site_specific_swms_hazards, value: h.site_specific_swms_hazards, id: h.id }))
        props.change("hazards", newHazards);
      }
      if (props.selectedSiteSpecific.person_responsibles) {
        let newResponsibles = [];
        props.selectedSiteSpecific.person_responsibles.forEach(role => {
          let roleObj = props.roles.find(x => x.id === role.person_responsible_id);
          if (roleObj) {
            newResponsibles.push({ label: roleObj.name, value: roleObj.id })
          }
        })
        props.change("responsible", newResponsibles);
      }
    }
  }, [props.selectedSiteSpecific])

  const resetForm = () => {
    props.change("controls", "");
    setEditorState(EditorState.createEmpty());
    props.reset();
    props.onReset();
  }

  const displayRiskBefore = () => {
    let { likelihoodBeforeValue, consequenceBeforeValue } = props;
    let res = "N/A";
    if (likelihoodBeforeValue && consequenceBeforeValue && likelihoodBeforeValue.value && consequenceBeforeValue.value) {
      res = calculateRiskValue(likelihoodBeforeValue.value, consequenceBeforeValue.value, "riskBefore");
    }
    return res;
  };

  const calculateRiskValue = (likelihoodValue, consequenceValue, type) => {
    if (likelihoodValue && likelihoodValue !== -1 && consequenceValue && consequenceValue !== -1) {
      let riskValue = getNewRiskRatingValueByMap[`${likelihoodValue}${consequenceValue}`]
      return riskValue;
    } else {
      return 'N/A';
    }
  };

  const displayRiskAfter = () => {
    let { likelihoodAfterValue, consequenceAfterValue } = props;
    let res = "N/A"
    let likelihoodValue = likelihoodAfterValue && likelihoodAfterValue.value ? likelihoodAfterValue.value : null;
    let consequenceValue = consequenceAfterValue && consequenceAfterValue.value ? consequenceAfterValue.value : null;
    if (likelihoodValue && consequenceValue) {
      res = calculateRiskValue(likelihoodValue, consequenceValue, "riskAfter");
    }
    return res;
  }

  const changeEditorState = (value) => {
    props.change("controls", draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setEditorState(value)
  }

  const onSubmit = (formValues) => {

    let hazards = formValues && formValues.hazards.map((val) => val.value)
    let consequences = formValues && formValues.consequences.map((val) => val.value)

    let adminName = JSON.parse(getStorage("ADMIN_DETAILS"))
      ? JSON.parse(getStorage("ADMIN_DETAILS")).name +
      " " +
      (JSON.parse(getStorage("ADMIN_DETAILS")).last_name
        ? JSON.parse(getStorage("ADMIN_DETAILS")).last_name
        : "")
      : "";

    let riskBefore = displayRiskBefore();
    riskBefore = riskBefore.split(". ")[0]

    let riskAfter = displayRiskAfter();
    riskAfter = riskAfter.split(". ")[0]

    let responsibles = formValues.responsible.map(resp => resp.value);

    let urlencoded = new URLSearchParams();
    urlencoded.append("activity", formValues.activity);
    urlencoded.append(
      "likelihood_before_controls",
      formValues.likelihood_before_controls.value
    );
    urlencoded.append(
      "consequence_before_controls",
      formValues.consequence_before_controls.value
    );
    urlencoded.append("risk_rating_before_controls", riskBefore);
    urlencoded.append("control_measures", formValues.controls);
    urlencoded.append(
      "likelihood_after_controls",
      formValues.likelihood_after_controls.value
    );
    urlencoded.append(
      "consequence_after_controls",
      formValues.consequence_after_controls.value
    );
    urlencoded.append("risk_rating_after_controls", riskAfter);

    urlencoded.append("active", "1");
    urlencoded.append("hazards", hazards);
    urlencoded.append("consequences", consequences);
    urlencoded.append("person_responsibles", responsibles);
    urlencoded.append("job_id", props.jobId);
    urlencoded.append("created_by", adminName);
    urlencoded.append("created_at", moment().format("DD/MM/YYYY"));
    let isNewSwms = true;
    if (Object.keys(props.selectedSiteSpecific).length > 0) {
      urlencoded.append("id", props.selectedSiteSpecific.id)
      isNewSwms = false;
    }

    Promise.resolve(dispatch(putSiteSpecificSwms(urlencoded, props.jobId)))
      .then(([res, msg]) => {
        notification.success({
          message: Strings.success_title,
          description: msg,
          className: "ant-success",
        });
        resetForm();
        if (isNewSwms) {
          props.onNewSwmsAdded(res.id);
        } else
          props.reloadSwms();
      })
      .catch((err) => {
        notification.error({
          message: Strings.error_title,
          description: err,
          className: "ant-error",
        });
      })
  }

  return (
    <Form onSubmit={(e) => { e.stopPropagation(); props.handleSubmit(onSubmit)(e) }}>
      <div>
        <div>
          <div className="row ">
            <div className="col-lg-2">
              <div>
                <div className={jobStyles.SectionHeading}>
                  Site Specific
                </div>
                <div className={jobStyles.card}>
                  <div className={styles.CommonLabel}>Responsible</div>
                  <Field
                    name="responsible"
                    options={getFormattedPopBoxOptions(props.roles, "name")}
                    component={CustomSingleSelect}
                    otherProps={{
                      isMulti: true,
                      components: { dropdownIndicator: <components.DownChevron />, Option },
                      styles: colorStylesMulti,
                      closeMenuOnSelect: false,
                      hideSelectedOptions: false,
                      menuPortalTarget: document.body
                    }}
                  />
                  <div className={jobStyles.CommonLabel}>Created By</div>
                  <input
                    className={jobStyles.InputFormField}
                    type="text"
                    value={JSON.parse(getStorage("ADMIN_DETAILS"))
                      ? JSON.parse(getStorage("ADMIN_DETAILS")).name +
                      " " +
                      (JSON.parse(getStorage("ADMIN_DETAILS")).last_name
                        ? JSON.parse(getStorage("ADMIN_DETAILS")).last_name
                        : "")
                      : ""}
                    disabled
                  />
                  <div className={jobStyles.CommonLabel}>Created on</div>
                  <input
                    className={jobStyles.InputFormField}
                    value={moment().format("DD/MM/YYYY")}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-10 pl-0">
              <div className={jobStyles.SectionHeading}>
                Activity Details
              </div>
              <div className={jobStyles.card}>
                <div>
                  <div className={jobStyles.CommonLabel}>Activity Name</div>
                  <Field
                    name="activity"
                    type="text"
                    component={customInput}
                    className={styles.SwmsFormField}
                  />
                </div>
                <div className="controls-container ">
                  <div>
                    <div
                    // className="sf-card-body"
                    // style={{ marginBottom: "10px" }}
                    >
                      <div
                        style={{
                          float: "right",
                        }}
                      >
                        <div className="info-btn disable-dot-menu">
                          <Popover
                            className="swms-popup-dtl"
                            content={
                              <img
                                className="risk-asses-img"
                                src="/images/risk_assesment.png"
                              />
                            }
                            placement="bottomRight"
                            overlayClassName="swms-act-popup"
                            title="Risk Assesment"
                          >
                            <strong className="more-info">
                              <i className="material-icons swms-info-size">info</i>
                            </strong>
                          </Popover>
                        </div>
                      </div>
                      <div
                        className="container-fluid px-0 my-2"
                      // className={`${styles.ControlsContainer_JobSwms} controls-fields texteditorPadding`}
                      // style={{ paddingLeft: "0 !important" }}
                      >
                        <div className="row">
                          <div className='col-2'>
                            <div className={jobStyles.CommonLabel}>Hazards</div>
                            <Field
                              // style={{ height: "300px" }}
                              name="hazards"
                              placeholder="Hazards"
                              className={styles.SwmsFormField}
                              component={ReduxFormSelect}
                            />
                          </div>
                          <div className='col-2'>
                            <div className={jobStyles.CommonLabel}>Consequences</div>
                            <Field
                              name="consequences"
                              placeholder="Consequences"
                              type="text"
                              options={
                                []
                              }
                              component={ReduxFormSelect}
                              className={styles.SwmsFormField}
                            />
                          </div>
                          <div className="col-2">
                            <div className={jobStyles.CommonLabel}>Before Controls</div>
                            <div>
                              <div className={jobStyles.CommonLabel}>Likelihood</div>
                              <Field
                                placeholder="Likelihood"
                                name="likelihood_before_controls"
                                options={numerateV2Options(
                                  getRiskRatingFormattedOptions(
                                    props.likelihoodBefore,
                                    "name"
                                  ),
                                  true,
                                  "L"
                                )}
                                styles={colorStyles}
                                otherProps={{
                                  components: { dropdownIndicator: <components.DownChevron /> }
                                }}
                                component={CustomSingleSelect}
                              />
                            </div>
                            <div>
                              <div className={jobStyles.CommonLabel}>Consequence</div>
                              <Field
                                placeholder="Consequence"
                                name="consequence_before_controls"
                                options={numerateV2Options(
                                  getRiskRatingFormattedOptions(
                                    props.consequencesBefore,
                                    "name"
                                  ),
                                  true,
                                  "C"
                                )}
                                component={CustomSingleSelect}
                                styles={colorStyles}
                                otherProps={{
                                  components: { dropdownIndicator: <components.DownChevron /> }
                                }}
                              />
                            </div>
                            <div>
                              <div className={jobStyles.CommonLabel}>Risk Rating</div>
                              <div
                                style={{
                                  display: "block",
                                  fontSize: "14px",
                                  marginLeft: "11px",
                                }}
                              >
                                {displayRiskBefore()}
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className={styles.TextEditor} style={{ width: "100%", maxWidth: "100%", minWidth: "auto" }}>
                              <div className={jobStyles.CommonLabel}>Controls</div>
                              <Field
                                name="controls"
                                type="text"
                                component={emailEditor}
                                editorState={editorState}
                                onEditorStateChange={changeEditorState}
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className={jobStyles.CommonLabel}>After Controls</div>
                            {/* <p className={styles.DropdownHeader_JobSwms}>
                              After Controls
                            </p> */}
                            <div>
                              <div className={jobStyles.CommonLabel}>Likelihood</div>
                              <Field
                                placeholder="Likelihood"
                                name="likelihood_after_controls"
                                options={numerateV2Options(
                                  getRiskRatingFormattedOptions(
                                    props.likelihoodBefore,
                                    "name"
                                  ),
                                  true,
                                  "L"
                                )}
                                component={CustomSingleSelect}
                                styles={colorStyles}
                                otherProps={{
                                  components: { dropdownIndicator: <components.DownChevron /> }
                                }}
                              />
                            </div>
                            <div>
                              <div className={jobStyles.CommonLabel}>Consequence</div>
                              <Field
                                placeholder="Consequence"
                                name="consequence_after_controls"
                                options={numerateV2Options(
                                  getRiskRatingFormattedOptions(
                                    props.consequencesBefore,
                                    "name"
                                  ),
                                  true,
                                  "C"
                                )}
                                component={CustomSingleSelect}
                                styles={colorStyles}
                                otherProps={{
                                  components: { dropdownIndicator: <components.DownChevron /> }
                                }}
                              />
                            </div>
                            <div>
                              <div className={jobStyles.CommonLabel}>Risk Rating</div>
                              <span
                                style={{
                                  display: "block",
                                  fontSize: "14px",
                                  marginLeft: "11px",
                                }}
                              >
                                {displayRiskAfter()}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-right">
                      <button
                        type="button"
                        onClick={() => { resetForm(); }}
                        className={jobStyles.FilterResetButton}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        disabled={props.pristine}
                        className={jobStyles.PrimaryButton}
                      >Save Site Specific
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form >
  )
}

SiteSpecificSwmsForm = reduxForm({
  form: 'SiteSpecificSwmsForm',
  enableReinitialize: true,
  validate: validateSiteSpecific
})(SiteSpecificSwmsForm)

const selector = formValueSelector('SiteSpecificSwmsForm');
SiteSpecificSwmsForm = connect(state => {

  const likelihoodBeforeValue = selector(state, 'likelihood_before_controls');
  const consequenceBeforeValue = selector(state, 'consequence_before_controls')

  const likelihoodAfterValue = selector(state, 'likelihood_after_controls');
  const consequenceAfterValue = selector(state, 'consequence_after_controls');

  return {
    likelihoodBeforeValue,
    consequenceBeforeValue,
    likelihoodAfterValue,
    consequenceAfterValue,
  }
})(SiteSpecificSwmsForm)

export default SiteSpecificSwmsForm;
