import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import { CircularProgress } from "@mui/material";
import Select from "react-select";

import colourStyles from "../FilterSelectStyles.js";
import { copySwms } from "../../../../../actions/v2/JobSwmsTaskActions.js";
import { Strings } from "../../../../../dataProvider/localize.js";

import styles from "../../JobStyle.module.css";

export default function SwmsCopyTaskModal({ onClose, allTasks, task, swms }) {

  const dispatch = useDispatch();

  const [selectedTask, setSelectedTask] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const onCopy = () => {
    setSubmitting(true);
    let formData = {
      swms_form_id: swms.swms_form_id,
      task_to_be_copied: [selectedTask.value],
      job_id: task.job_id,
      job_number: task.job_number
    }
    dispatch(copySwms(formData)).then(message => {
      setSubmitting(false);
      notification.success({
        message: Strings.success_title,
        description: message,
        className: "ant-success"
      });
      onClose();
    }).catch(err => {
      if (err) {
        setSubmitting(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: "ant-error"
        })
      }
    })
  }

  return (
    <div className="job-swms-modal-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={styles.ModalHeading}>
              Select the Task to copy {task.task_number} - SWMS Version {swms.swms_revision}
            </div>
            <div className="py-2">
              <hr />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className={styles.Label}>Task</div>
            <div>
              <Select
                isDisabled={submitting}
                onChange={(v) => setSelectedTask(v)}
                value={selectedTask}
                placeholder="Select Task"
                hideSelectedOptions={false}
                options={allTasks.map(task => ({ label: `${task.task_number} - ${task.task_name}`, value: task.task_id }))}
                styles={colourStyles}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-right pt-3">
            <div className="d-flex align-items-center justify-content-end" style={{ gap: "5px" }}>
              {submitting ? (
                <CircularProgress size={20} />) : ""}
              <button className={`${styles.OutlinedButton}`} onClick={() => onClose()}>
                Cancel
              </button>
              <button type="button" onClick={onCopy} className={styles.PrimaryButton} disabled={!selectedTask || submitting}>
                Copy SWMS
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}