import React, { useState, useEffect } from "react";
import { Modal, Icon, notification } from "antd"
import EmailModal from "./EmailModal";
import { ADMIN_DETAILS } from "../../../../dataProvider/constant";
import { getStorage } from "../../../../utils/common";
import { Strings } from '../../../../dataProvider/localize';
import { getTaskSignOffEmailContent, getSignOffEmailCode, sendSignOffEmail } from "../../../../actions/v2/TaskSignOffActions";
import { getSerializedTasksData } from "./serializer";
import validator from "validator";
import CloseIcon from '@mui/icons-material/Close';
import { getFirstName, getFormattedPhone } from "../../../../utils/common";
import jobStyles from "../JobStyle.module.css";

import { useDispatch } from "react-redux";

export default function EmailSignOff(props) {

  const dispatch = useDispatch();
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const [emailData, setEmailData] = useState({});
  const [emailLoading, setEmailLoading] = useState(false);
  const [noReplyData, setNoReplyData] = useState('');

  useEffect(() => {
    let tasksData = getSerializedTasksData(props.tasks, props.jobDetails.id);
    Promise.all([
      dispatch(getSignOffEmailCode(tasksData)),
      dispatch(getTaskSignOffEmailContent())]).then(res => {
        let adminDetails = JSON.parse(getStorage(ADMIN_DETAILS))
        let orgId = adminDetails && adminDetails.organisation && adminDetails.organisation.id ? adminDetails.organisation.id : ''
        let { sign_off_code } = res[0];
        let signOffLink = `${window.location.origin}/signoff-tasks/${orgId}/${props.jobDetails.id}/${sign_off_code}`;
        if (process.env.REACT_APP_CLIENT_APP_URL) {
          signOffLink = `${process.env.REACT_APP_CLIENT_APP_URL}/signoff-tasks/${orgId}/${props.jobDetails.id}/${sign_off_code}`;
        }
        let data = res[1].result && res[1].result.length && res[1].result.length > 0 ? res[1].result[0] : {};
        let body = data.body;
        let subject = data.subject;
        let bcc = Array.isArray(data.to_bcc) ? data.to_bcc.map(address => ({ label: address, value: address })) : [{ label: data.to_bcc, value: data.to_bcc }];
        let cc = adminDetails && adminDetails.email_address ? [{ label: adminDetails.email_address, value: adminDetails.email_address }] : []
        let to = props.jobDetails && props.jobDetails.client_contact_email ? [{ label: props.jobDetails.client_contact_email, value: props.jobDetails.client_contact_email }] : ''

        let orgName = adminDetails && adminDetails.organisation && adminDetails.organisation.name ? adminDetails.organisation.name : '';
        let jobLabel = props.jobDetails && props.jobDetails.job_label ? props.jobDetails.job_label : '';
        let siteName = props.jobDetails && props.jobDetails.site_name ? props.jobDetails.site_name : '';
        let userName = adminDetails ? adminDetails.name + " " + (adminDetails.last_name ? adminDetails.last_name : '') : []

        let noReplyFrom = `${userName} (${orgName}) via ${Strings.SERVICE_NAME} <${data.from_email}>`

        let clientPersonFirstName = props.jobDetails.client_contact_name ? getFirstName(props.jobDetails.client_contact_name) : '';
        let userPhoneNumber = adminDetails && adminDetails.phone_number ? getFormattedPhone(adminDetails.phone_number) : ''
        let userEmailAddress = adminDetails && adminDetails.email_address ? `<a href="mailto:${adminDetails.email_address}">${adminDetails.email_address}</a>` : "";

        subject = subject.replace(/\{\{org_name\}\}/g, orgName)
        subject = subject.replace(/\{\{job_label\}\}/g, jobLabel)
        subject = subject.replace(/\{\{site_name\}\}/g, siteName)

        body = body.replace(/(?:\r\n|\r|\n)/g, '');

        body = body.replace(/\{\{client_person_first_name\}\}/g, clientPersonFirstName);
        body = body.replace(/\{\{user_phone_number\}\}/g, userPhoneNumber);
        body = body.replace(/\{\{user_email\}\}/g, userEmailAddress);
        body = body.replace(/\{\{user_name\}\}/g, userName);

        let tasksData = `<ul>`;
        props.tasks.forEach((task, index) => {
          tasksData += `<li>Task ${index + 1} - ${task.task_name}${task.area_name ? ', ' + task.area_name : ''}</li>`;
        })
        tasksData += `</ul>`;

        body = body.replace(/<p>\{\{tasks_data\}\}<\/p>/g, tasksData);
        body = body.replace(/<p><\/p>/g, "<br />")

        body = body.replace(/\{\{sign_off_link\}\}/g, `<a href="${signOffLink}" target="_blank" rel="noopener noreferrer">Click to Sign Off</a>`);

        body = body.replace(/\{\{service_name\}\}/g, Strings.SERVICE_NAME);
        body = body.replace(/\{\{service_email\}\}/g, `<a href="mailto:${Strings.SERVICE_EMAIL}">${Strings.SERVICE_EMAIL}</a>`);

        if (body.includes("<p>____no_reply</p>") && body.includes("<p>no_reply____</p>")) {
          let start = body.indexOf("<p>____no_reply</p>");
          let end = body.lastIndexOf("<p>no_reply____</p>")

          if (start !== -1 && end !== -1) {
            let signEnd = end + 15;
            let dataStart = start + 15;
            let noReply = body.substr(dataStart, (end - dataStart));
            noReply = `<!--[if mso]><table role="presentation" cellspacing="0" cellpadding="0" border="0" width="600"><tr><td><![endif]--><div style='width: auto;border:none;border-top:solid #000000 1.0pt; border-bottom: solid #000000 1.0pt; padding:4.0pt 0cm 4.0pt 0cm; margin: 20pt 0cm 10pt 0cm;'>`
              + noReply + `</div><!--[if mso]></td></tr></table><![endif]-->`;
            setNoReplyData(noReply);
            body = body.substring(0, start) + '' + body.substring(signEnd);
          }
        }

        setEmailData({
          subject: subject,
          body: body,
          from: noReplyFrom,
          cc: cc,
          to: to,
          bcc: bcc
        });
        setLoading(false);
      }).catch(err => {
        if (err) {
          setLoading(false);
          props.onCancel();
          notification.error({
            message: Strings.error_title,
            description: err ? err : Strings.generic_error,
            className: 'ant-error'
          })
        }
      })
  }, [])

  const validate = (values) => {
    const errors = {};
    //validate to email address
    if (!values.to || (values.to && !Array.isArray(values.to)) || (values.to && Array.isArray(values.to) && values.to.length === 0)) {
      errors.to = 'Required'
    } else if (values.to && values.to.length !== 1) {
      errors.to = 'Please enter only one email address.'
    } else {
      let emailValue = values.to[0].value;
      if (!emailValue || !validator.isEmail(emailValue)) {
        errors.to = 'Invalid Email Address'
      }
    }
    return errors;
  }

  const sendEmail = (values) => {
    setEmailLoading(true);
    let adminDetails = JSON.parse(getStorage(ADMIN_DETAILS))
    let orgName = adminDetails && adminDetails.organisation && adminDetails.organisation.name ? adminDetails.organisation.name : '';

    let content = values.body;

    content = content + noReplyData;
    content = `<!--[if mso]><table role="presentation" cellspacing="0" cellpadding="0" border="0"><tr><td><![endif]--><div style="font-family: 'calibri', sans-serif !important; font-size: 11pt !important; width: fit-content;">` + content + `</div><!--[if mso]></td></tr></table><![endif]-->`;
    content = content.replace(/font-family:.?inherit;/g, "font-family: 'calibri', sans-serif;");

    content = content.replace(/<p><\/p>/g, '')

    //strip /n from body before sending email
    content = content.replace(/(?:\r\n|\r|\n)/g, '');
    content = content.replace(/<ins>/g, "<u>");
    content = content.replace(/<\/ins>/g, "</u>");

    let formData = {
      to_field: values.to[0].value,
      from_field: values.from,
      subject: values.subject,
      bcc_field: values.bcc.length > 0 ? values.bcc.map(x => x.value).join(',') : '',
      cc_field: values.cc.length > 0 ? values.cc.map(x => x.value).join(',') : '',
      message: content,
      from_profile: orgName
    };
    dispatch(sendSignOffEmail(formData)).then(() => {
      notification.success({
        message: Strings.success_title,
        description: "Sign Off Email Sent Successfully!",
        className: 'ant-success'
      })
      setVisible(false);
      setEmailLoading(false);

    }).catch(err => {
      if (err) {
        setEmailLoading(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: 'ant-error'
        })
      }
    })
  }

  return <div>
    <Modal
      className="cs-modal-v2"
      visible={visible}
      afterClose={() => props.onCancel()}
      okButtonProps={{ style: { display: 'none' } }}
      width={(window.innerWidth * 70) / 100}
      onCancel={() => setVisible(false)}
      destroyOnClose={true}
      footer={null}
      closeIcon={<div className={jobStyles.ModalCloseIcon}><CloseIcon /></div>}
    >
      <div>
        {loading ? <div className="d-flex align-items-center justify-content-center h-100">
          <Icon type="loading" style={{ fontSize: "40px" }} />
        </div> :
          <EmailModal
            options={{ allowMutiToEmail: true }}
            initialValues={emailData}
            loading={emailLoading}
            form="EmailSingOff"
            onSubmitForm={sendEmail}
            validate={validate}
          />}
      </div>
    </Modal>
  </div >
}