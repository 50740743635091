import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { Table, Modal, Button, notification, DatePicker } from "antd";
import { fetchColumns } from "./tableDefinitions";
import * as payrollTimesheetAction from "../../../../../actions/payrollTimesheetAction";
import * as allocationActions from "../../../../../actions/v2/AllocationActions";
import * as jobActions from "../../../../../actions/jobAction";
import { Strings } from "../../../../../dataProvider/localize";
import { getStorage } from "../../../../../utils/common";
import { CustomSelect } from "../../../../common/customSelect";
import { Field, reduxForm, isDirty } from "redux-form";
import moment from "moment";
import { ADMIN_DETAILS } from "../../../../../dataProvider/constant";
import {
  getShiftStatusOptions,
  serializeUpdateTimesheetShiftRequest,
} from "./serializer";
import { popOverCheckBox } from "../../../../common/popOverCheckBox";
import { ERROR_NOTIFICATION_KEY } from "../../../../../config";
import { withRouter } from "react-router-dom";
import ModalContent from "./ModalContent";
import { fields } from "./fieldsConfig";
import jobStyles from "../../../Job/JobStyle.module.css";
import { CustomSingleSelect } from "../../../../common/new-core-ui/CustomSingleSelect";
import { FilterSelectStyle } from "../../../../v2/Job/SelectV2SingleStyle";
import Select, { components } from "react-select";
import { OptionV2 } from "../../../../common/new-core-ui/reactMultiSelectComponents";
import "./ShiftTimesheetTableStyles.css";
import CloseIcon from '@mui/icons-material/Close';
import styles from "./TimeheetUpdateStyles.module.css";

const { RangePicker } = DatePicker;

export class ShiftTimeSheetContainer extends Component {
  state = {
    selectedRowKeys: [], // Check here to configure the default column
    loading: false,
    filters: {},
    editingKey: "",
    currentPage: 1,
    pageSize: 10,
    visible: false,
    filterDate: []
  };

  currentOrganization = JSON.parse(getStorage(ADMIN_DETAILS))
    ? JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id
    : null;

  isEditing = (record) => record.key === this.state.editingKey;

  edit = (record) => {
    this.setState({ editingKey: record.key });
  };

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  filter = (stayOnPage) => {
    this.props.actions
      .getAllocationTimesheet({
        jobId: this.props.selectedJobDetails.id,
        pay_cycle_type: "current",
        ...this.state.filters,
        ...(Array.isArray(this.state.filterDate) && this.state.filterDate.length === 2 &&
          { start_date: moment(this.state.filterDate[0]).format("YYYY-MM-DD"), finish_date: moment(this.state.filterDate[1]).format("YYYY-MM-DD") }
        )
      })
      .then(() => {
        this.setState(
          {
            editingKey: "",
            selectedRowKeys: "",
            currentPage: stayOnPage ? this.state.currentPage : 1,
          },
          () => this.onPageChange(stayOnPage ? this.state.currentPage : 1)
        );
      })
      .catch(() => { });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys, editingKey: "" });
  };

  handleShiftIdChange = (val) => {
    this.setState({ filters: { ...this.state.filters, shiftId: val } });
  };

  handleShiftStatusChange = (val) => {
    this.setState({ filters: { ...this.state.filters, shiftStatus: val } });
  };

  handleApproved = (val) => {
    this.setState({ filters: { ...this.state.filters, approved: val } });
  };

  reset = () => {
    this.props.actions
      .getAllocationTimesheet({
        jobId: this.props.selectedJobDetails.id,
        pay_cycle_type: "current",
      })
      .then(() => {
        this.setState(
          { filters: {}, selectedRowKeys: [], editingKey: "", currentPage: 1, filterDate: [] },
          () => this.props.reset()
        );
      })
      .catch(() => { });
  };

  onPageChange = (arg) => {
    this.setState({
      currentPage: arg,
      selectedRowKeys: "",
    });
  };

  handleEdit = (data, index) => {
    const { start_date, start_time, finish_date, finish_time } = data;
    this.setState({ editingKey: data.staff_id });
    this.props.change(
      `start_date_${data.staff_id}`,
      start_date
        ? start_date
        : data.shift_status !== 1 &&
        data.shift_status !== 2 &&
        moment(new Date()).format("DD-MM-YYYY")
    );
    this.props.change(
      `start_time_${data.staff_id}`,
      start_time
        ? start_time
        : data.shift_status !== 1 &&
        data.shift_status !== 2 &&
        moment(new Date()).format("HH:mm")
    );
    this.props.change(
      `finish_date_${data.staff_id}`,
      finish_date
        ? finish_date
        : data.shift_status !== 1 &&
        data.shift_status !== 2 &&
        moment(new Date()).format("DD-MM-YYYY")
    );
    this.props.change(
      `finish_time_${data.staff_id}`,
      finish_time
        ? finish_time
        : data.shift_status !== 1 &&
        data.shift_status !== 2 &&
        moment(new Date()).format("HH:mm")
    );
  };

  saveEdit = (data, rowId) => {
    const formData = {
      job_id: data.job_id,
      job_shift_timesheets: [
        {
          ...data,
          approved: 1,
          allownace_status: data.allownace_status ? 1 : 0,
          id: data.staff_id,
          start_date: moment(
            this.props.formValues[`start_date_${data.staff_id}`]
          ).format("YYYY-MM-DD"),
          start_time: `${moment(
            this.props.formValues[`start_date_${data.staff_id}`]
          ).format("YYYY-MM-DD")} ${moment(
            this.props.formValues[`start_time_${data.staff_id}`],
            "HH:mm:ss"
          ).format("HH:mm:ss")}`,
          finish_date: moment(
            this.props.formValues[`finish_date_${data.staff_id}`]
          ).format("YYYY-MM-DD"),
          finish_time: `${moment(
            this.props.formValues[`finish_date_${data.staff_id}`]
          ).format("YYYY-MM-DD")} ${moment(
            this.props.formValues[`finish_time_${data.staff_id}`],
            "HH:mm:ss"
          ).format("HH:mm:ss")}`,
        },
      ],
    };

    this.props.jobActions
      .updateJobShift(formData)
      .then(({ message }) => {
        this.filter(true);
        this.props.actions
          .getAllocationTimesheet({
            jobId: this.props.selectedJobDetails.id,
            pay_cycle_type: "current",
            ...this.state.filters,
          })
          .then(() => { })
          .catch(() => { });
        return notification.success({
          message: Strings.success_title,
          description: message ? message : 'Job shift timesheet updated"',
          onClick: () => { },
          className: "ant-success",
        });
      })
      .catch((message) => {
        return notification.error({
          key: ERROR_NOTIFICATION_KEY,
          message: Strings.error_title,
          description: message,
          onClick: () => { },
          className: "ant-error",
        });
      });
  };

  cancelEdit = (rowIndex) => {
    this.setState({ editingKey: "" });
  };

  setPickerDefaultRange = () => {
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
    return [moment(twoWeeksAgo), moment(new Date())];
  };

  openModal = (status, rowData) => {
    this.setState({ visible: true });
    const { shift_id, shift_status, shift_start, shift_end } =
      this.props.formValues;
    this.props.actions.getTimesheetInitialValues({
      ...rowData,
      shift_id,
      shift_status,
      approved: rowData.approved,
      shift_start,
      shift_end,
    });
  };

  handleCancel = () => {
    this.setState({ visible: false });
    const { shift_id, shift_status, approved, shift_start, shift_end } =
      this.props.formValues;
    this.props.actions.resetIntialValues({
      shift_id,
      shift_status,
      approved,
      shift_start,
      shift_end,
    });
  };

  handleUpdate = () => {
    const allowanceFields = fields.map((f) => f.fieldName);
    const updatedAllowanceFields = Object.keys(this.props.formValues).filter(
      (keyName) =>
        allowanceFields.includes(keyName) &&
        parseInt(this.props.formValues[keyName]) !== 0
    );

    if (!updatedAllowanceFields.length) {
      return notification.warning({
        message: Strings.warning_title,
        description: "Please update at least one allowance field.",
        onClick: () => { },
        className: "ant-warning",
      });
    }

    const formData = {
      job_id: this.props.currentSelectedShift.job_id,
      job_shift_timesheets: serializeUpdateTimesheetShiftRequest(
        this.props.formValues
      ),
    };

    this.props.actions
      .updateJobShift(formData)
      .then(({ message }) => {
        this.filter(true);
        this.handleCancel();
        this.props.actions
          .getAllocationTimesheet({
            jobId: this.props.selectedJobDetails.id,
            pay_cycle_type: "current",
            ...this.state.filters,
          })
          .then(() => { })
          .catch(() => { });
        return notification.success({
          message: Strings.success_title,
          description: message ? message : 'Job shift timesheet updated"',
          onClick: () => { },
          className: "ant-success",
        });
      })
      .catch((message) => {
        return notification.error({
          key: ERROR_NOTIFICATION_KEY,
          message: Strings.error_title,
          description: message,
          onClick: () => { },
          className: "ant-error",
        });
      });
  };

  render() {
    const { loading, selectedRowKeys } = this.state;
    const {
      isDirty,
      timesheets,
      currentSelectedShift,
      selectedJobDetails,
      shiftIdDataSource,
    } = this.props;

    return (
      <div className="timesheet-v2-container" style={{ marginTop: "5px" }}>
        <div className="timesheet-main-container px-3">
          <div className={jobStyles.card}>
            <div className="container-fluid px-0">
              <div className="row">
                <div className="col-4">
                  <div className="d-flex">
                    <div className={`${jobStyles.CommonLabel} font-weight-bold`}>Job ID: </div>
                    <div className={`${jobStyles.CommonLabel} ml-2`}> {selectedJobDetails.label}</div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="d-flex">
                    <div className={`${jobStyles.CommonLabel} font-weight-bold`}>Client: </div>
                    <div className={`${jobStyles.CommonLabel} ml-2`}> {selectedJobDetails.clientName}</div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="d-flex">
                    <div className={`${jobStyles.CommonLabel} font-weight-bold`}>Contact Person: </div>
                    <div className={`${jobStyles.CommonLabel} ml-2`}> {selectedJobDetails.clientContactName}</div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="d-flex">
                    <div className={`${jobStyles.CommonLabel} font-weight-bold`}>Email: </div>
                    <div className={`${jobStyles.CommonLabel} ml-2`}> {selectedJobDetails.clientContactEmail}</div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="d-flex">
                    <div className={`${jobStyles.CommonLabel} font-weight-bold`}>Mobile: </div>
                    <div className={`${jobStyles.CommonLabel} ml-2`}> {selectedJobDetails.clientContactPhone}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="timesheet-filters-container">
            <div className="container-fluid px-0">
              <div className="row">
                <div className="col-2">
                  <RangePicker
                    className="job-range-picker-v2"
                    placeholder={["Shift Start Date", " Shift End Date"]}
                    dropdownClassName="cs-datepicker-dropdown"
                    value={this.state.filterDate}
                    format="DD-MM-YYYY"
                    onChange={(val) => this.setState({ filterDate: val })} />
                </div>
                <div className="col-2">
                  <Select
                    name="shift_id"
                    placeholder="Shift Id"
                    onChange={(val) => this.handleShiftIdChange(val)}
                    options={shiftIdDataSource}
                    isMulti={true}
                    styles={FilterSelectStyle}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option: OptionV2 }}
                    value={this.state && this.state.filters && this.state.filters.shiftId ? this.state.filters.shiftId : []}
                  />
                </div>
                <div className="col-2">
                  <Select
                    name="shift_status"
                    placeholder="Shift Status"
                    onChange={(val) => this.handleShiftStatusChange(val)}
                    options={getShiftStatusOptions()}
                    isMulti={true}
                    components={{ Option: OptionV2 }}
                    closeMenuOnSelect={false}
                    styles={FilterSelectStyle}
                    hideSelectedOptions={false}
                    value={this.state && this.state.filters && this.state.filters.shiftStatus ? this.state.filters.shiftStatus : []}
                  />
                </div>
                <div className="col-2">
                  <Select
                    name="approved"
                    placeholder="Approved"
                    onChange={(val) => this.handleApproved(val.value)}
                    options={[
                      { label: "Yes", value: 1 },
                      { label: "No", value: 0 },
                    ]}
                    styles={FilterSelectStyle}
                    value={this.state && this.state.filters && this.state.filters.hasOwnProperty("approved") ? { label: this.state.filters.approved ? "Yes" : "No", value: this.state.filters.approved } : null}
                  />
                </div>
                <div className="col-2">
                  <div className="d-flex align-items-center" style={{ columnGap: "10px" }}>
                    <button className={jobStyles.PrimaryButton} onClick={this.filter} disabled={loading}>Get Timesheets</button>
                    <button className={jobStyles.FilterResetButton} onClick={() => this.reset()}>Reset</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="timesheet-main-container">
            <div
              style={{
                maxWidth: "92.5vw",
                padding: "1rem",
              }}
            >
              <Table
                columns={fetchColumns(
                  this.state.editingKey,
                  this.handleEdit,
                  this.saveEdit,
                  this.cancelEdit,
                  this.openModal
                )}
                dataSource={timesheets}
                pagination={{
                  current: this.state.currentPage,
                  pageSize: this.state.pageSize,
                  onChange: (a) => this.onPageChange(a),
                  className: "cs-pagination cs-pagination-v2"
                }}
                bordered
                scroll={{ x: 1000 }}
                className="table-centered-selection timesheet-table"
              />
            </div>
          </div>
          <Modal
            className="cs-modal-v2"
            centered
            width="60vw"
            footer={null}
            visible={this.state.visible}
            onCancel={this.handleCancel}
            closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
          >
            <ModalContent
              onCancel={this.handleCancel}
              onUpdate={this.handleUpdate}
            />
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.payrollReducer.payrollList,
    initialValues: state.Allocations.timesheetInitialValues,
    statesList: state.profileManagement.statesList,
    isDirty: isDirty("AllocationTimesheet")(state),
    formValues:
      state.form.AllocationTimesheet && state.form.AllocationTimesheet.values,
    timesheets: state.Allocations.timesheetList,
    currentSelectedShift: state.Allocations.currentSelectedShift,
    shiftIdDataSource: state.Allocations.shiftIdDataSource,
    selectedJobDetails: state.JobCalendar.selectedJobDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(allocationActions, dispatch),
    payrollTimesheetAction: bindActionCreators(
      payrollTimesheetAction,
      dispatch
    ),
    jobActions: bindActionCreators(jobActions, dispatch),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "AllocationTimesheet",
    enableReinitialize: true,
  })
)(ShiftTimeSheetContainer);
