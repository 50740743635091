import { notification, Popconfirm } from "antd";
import moment from "moment";
import React from "react";
import { Strings } from "../../../../dataProvider/localize";
import styles from "./JobAllocationContainer.module.css";

const isBefore = (finishDate) => {
  const finish = moment(finishDate, "YYYY-MM-DD hh:mm A").format();
  const today = moment(new Date()).format();
  return moment(finish).isBefore(today) ? false : true;
};

const isOngoing = (startDate, finishDate) => {
  const start = moment(startDate).format();
  const finish = moment(finishDate).format();
  const today = moment(new Date()).format();

  return moment(today).isBefore(finish) && moment(start).isBefore(today)
    ? true
    : false;
};

export const serializeShiftAllocationEvents = (
  shifts,
  jobDetails,
  dispatch,
  deleteAllocation
) => {
  return shifts.map((shiftItem) => ({
    ...shiftItem,
    start: moment(shiftItem.shift_date + " " + shiftItem.site_time, "YYYY-MM-DD hh:mm A").toDate(), //shift_date + site_time
    end: shiftItem.finish_time ? moment(shiftItem.finish_time, "YYYY-MM-DD hh:mm A").toDate() : moment().toDate(), //finish_time
    dayTitle: (
      <div className={styles.DayTitleWrapper}>
        <div>
          <div className="d-flex">
            <div>{"Shift " + shiftItem.job_shift_id}</div>
            <div className={styles.FooterActions} style={{ margin: "0px" }}>
              <Popconfirm
                title={"Do you want to copy this shift?"}
                onConfirm={() => {
                  dispatch({
                    type: "START_ALLOCATION_FORM_ACTION",
                    payload: {
                      actionType: "COPY",
                      shiftDetails: shiftItem,
                      selectedJobDetails: jobDetails,
                    },
                  });
                }}
                placement="topRight"
                okText="Yes"
                cancelText="No"
              >
                <button type="button" className="delete-bnt user-actions">
                  <i
                    class="fa fa-files-o"
                    style={{
                      color: "white",
                    }}
                  ></i>
                </button>
              </Popconfirm>
              <Popconfirm
                title={"Do you want to edit this shift?"}
                onConfirm={() =>
                  dispatch({
                    type: "START_ALLOCATION_FORM_ACTION",
                    payload: {
                      actionType: "UPDATE",
                      shiftDetails: shiftItem,
                      selectedJobDetails: jobDetails,
                    },
                  })
                }
                placement="topRight"
                okText="Yes"
                cancelText="No"
              >
                <button type="button" className="delete-bnt user-actions">
                  <i
                    class="fa fa-pencil"
                    style={{
                      color: "white",
                    }}
                    aria-hidden="true"
                    onClick={() => { }}
                  ></i>
                </button>
              </Popconfirm>
              {!isOngoing(
                `${shiftItem.shift_date} ${shiftItem.site_time}`,
                shiftItem.finish_time
              ) &&
                isBefore(shiftItem.finish_time) && (
                  <Popconfirm
                    title={"Do you want to delete this shift?"}
                    onConfirm={() =>
                      deleteAllocation(
                        shiftItem.job_schedule_shift_id,
                        jobDetails.id,
                        jobDetails,
                        dispatch,
                        shiftItem
                      )
                        .then((message) => {
                          notification.success({
                            message: Strings.success_title,
                            description: message ? message : Strings.success,
                            onClick: () => { },
                            className: "ant-success",
                          });
                        })
                        .catch(() => { })
                    }
                    placement="topRight"
                    okText="Yes"
                    cancelText="No"
                  >
                    <button type="button" className="delete-bnt user-actions">
                      <i
                        class="fa fa-trash"
                        style={{
                          color: "#B22222",
                        }}
                      ></i>
                    </button>
                  </Popconfirm>
                )}
            </div>
            <div className={styles.DayViewStaffWrapper} style={{ position: "relative" }}>
              {Array.isArray(shiftItem.staffs) &&
                shiftItem.staffs.length &&
                shiftItem.staffs.map((staffItem) => (
                  <div
                    style={{
                      objectFit: "cover",
                      position: "absolute", top: "50%", transform: "translateY(-50%)", left: "20px"
                    }}
                  >
                    <img
                      className={styles.ProfileIcon}
                      src={`${staffItem.profile_pic}`}
                      alt=""
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    ),
    weekTitle: (
      <div className={styles.WeekTitleWrapper}>
        <div>{"Shift " + shiftItem.job_shift_id}</div>

        <div className={styles.FooterActions}>
          <Popconfirm
            title={"Do you want to copy this shift?"}
            onConfirm={() =>
              dispatch({
                type: "START_ALLOCATION_FORM_ACTION",
                payload: {
                  actionType: "COPY",
                  shiftDetails: shiftItem,
                  selectedJobDetails: jobDetails,
                },
              })
            }
            placement="topRight"
            okText="Yes"
            cancelText="No"
          >
            <button type="button" className="delete-bnt user-actions">
              <i
                class="fa fa-files-o"
                style={{
                  color: "white",
                }}
              ></i>
            </button>
          </Popconfirm>
          <Popconfirm
            title={"Do you want to edit this shift?"}
            onConfirm={() =>
              dispatch({
                type: "START_ALLOCATION_FORM_ACTION",
                payload: {
                  actionType: "UPDATE",
                  shiftDetails: shiftItem,
                  selectedJobDetails: jobDetails,
                },
              })
            }
            placement="topRight"
            okText="Yes"
            cancelText="No"
          >
            <button type="button" className="delete-bnt user-actions">
              <i
                class="fa fa-pencil"
                style={{
                  color: "white",
                }}
                aria-hidden="true"
                onClick={() => { }}
              ></i>
            </button>
          </Popconfirm>
          {!isOngoing(
            `${shiftItem.shift_date} ${shiftItem.site_time}`,
            shiftItem.finish_time
          ) &&
            isBefore(shiftItem.finish_time) && (
              <Popconfirm
                title={"Do you want to delete this shift?"}
                onConfirm={() =>
                  deleteAllocation(
                    shiftItem.job_schedule_shift_id,
                    jobDetails.id,
                    jobDetails,
                    dispatch,
                    shiftItem
                  )
                    .then((message) => {
                      notification.success({
                        message: Strings.success_title,
                        description: message ? message : Strings.success,
                        onClick: () => { },
                        className: "ant-success",
                      });
                    })
                    .catch(() => { })
                }
                placement="topRight"
                okText="Yes"
                cancelText="No"
              >
                <button type="button" className="delete-bnt user-actions">
                  <i
                    class="fa fa-trash"
                    style={{
                      color: "#B22222",
                    }}
                  ></i>
                </button>
              </Popconfirm>
            )}
        </div>
      </div>
    ),
    monthTitle: "Shift " + shiftItem.job_shift_id,
  }));
};

export const getFormattedInitialValues = (
  mode,
  jobDetails,
  shiftDetails,
  event
) => {
  if (mode === "CREATE") {
    const start = new Date(event.start);
    const end = new Date(event.end);
    const difference = (end - start) / 1000 / 60 / 60;

    return {
      start_date: moment(start).format("YYYY-MM-DD"),
      start_time: start.toLocaleTimeString("en-GB"),
      finish_date: moment(end).format("YYYY-MM-DD"),
      finish_time: end.toLocaleTimeString("en-GB"),
      tasks: [],
      state_id: [],
      work_duration: difference,
      break: difference > 4 ? "0.5" : "0",
      total_duration: difference,
      validate_location: {label: "Yes", value: 1},
      paid_break: 0,
    };
  }

  if (mode === "UPDATE") {
    const startTime = shiftDetails.shift_date + " " + shiftDetails.site_time;
    const endTime = shiftDetails.finish_time;

    const start = new Date(startTime);
    const end = new Date(endTime);
    const difference = (end - start) / 1000 / 60 / 60;

    const staffData = {};
    const staffCheckBoxValues = {};

    shiftDetails.staffs.forEach((staffItem) => {
      staffData[`update_${staffItem.user_name}_start_point`] =
        staffItem.start_time_type;
      staffData[`update_${staffItem.user_name}_start_date`] =
        staffItem.site_yard_time.split(" ")[0];
      staffData[`update_${staffItem.user_name}_start_time`] =
        staffItem.site_yard_time.split(" ")[1];
      staffData[`update_${staffItem.user_name}_finish_date`] =
        staffItem.staff_finish_time.split(" ")[0];
      staffData[`update_${staffItem.user_name}_finish_time`] =
        staffItem.staff_finish_time.split(" ")[1];
      staffData[`update_${staffItem.user_name}_finish_time`] =
        staffItem.staff_finish_time.split(" ")[1];
      staffData[`update_${staffItem.user_name}_is_supervisor`] =
        staffItem.is_site_supervisor;
      staffCheckBoxValues[`selected_${staffItem.user_name}`] = 1;
    });

    // const selectAll = Object.keys(staffCheckBoxValues).length === shiftDetails.staffs.length

    return {
      start_date: shiftDetails.shift_date,
      start_time: moment(shiftDetails.site_time, "hh:mm A").format("HH:mm"),
      finish_date: moment(
        shiftDetails.finish_time,
        "YYYY-MM-DD hh:mm a"
      ).format("YYYY-MM-DD"),
      finish_time: moment(
        shiftDetails.finish_time,
        "YYYY-MM-DD hh:mm a"
      ).format("HH:mm"),
      state_id: shiftDetails.state_id,
      tasks: shiftDetails.tasks.map((task) => ({
        label: `${task.job_task_label}:${task.area_name}`,
        value: task.quote_task_id,
      })),
      work_duration: difference,
      break: difference > 4 ? "0.5" : "0",
      total_duration: difference,
      validate_location: shiftDetails.location_required,
      paid_break: 0,
      select_all_staff_update: 1,
      ...staffData,
      ...staffCheckBoxValues,
    };
  }

  if (mode === "COPY") {
    const staffData = {};

    shiftDetails.staffs.forEach((staffItem) => {
      staffData[`${staffItem.user_name}_start_point`] =
        staffItem.start_time_type;
      staffData[`${staffItem.user_name}_start_date`] =
        staffItem.site_yard_time.split(" ")[0];
      staffData[`${staffItem.user_name}_start_time`] =
        staffItem.site_yard_time.split(" ")[1];
      staffData[`${staffItem.user_name}_finish_date`] =
        staffItem.staff_finish_time.split(" ")[0];
      staffData[`${staffItem.user_name}_finish_time`] =
        staffItem.staff_finish_time.split(" ")[1];
    });

    const startTime = shiftDetails.shift_date + " " + shiftDetails.site_time;
    const endTime = shiftDetails.finish_time;

    const start = new Date(startTime);
    const end = new Date(endTime);
    const difference = (end - start) / 1000 / 60 / 60;

    return {
      start_date: shiftDetails.shift_date,
      start_time: moment(shiftDetails.site_time, "hh:mm A").format("HH:mm"),
      finish_date: shiftDetails.finish_time.split(" ")[0],
      finish_time: moment(
        shiftDetails.finish_time,
        "YYYY-MM-DD hh:mm A"
      ).format("HH:mm"),
      state_id: shiftDetails.state_id,
      tasks: shiftDetails.tasks.map((task) => ({
        label: `${task.job_task_label}:${task.area_name}`,
        value: task.quote_task_id,
      })),
      work_duration: difference,
      break: difference > 4 ? "0.5" : "0",
      total_duration: difference,
      validate_location: shiftDetails.location_required,
      paid_break: 0,
      ...staffData,
    };
  }
};

export const serializeStaffList = (data, stateId) => {
  const { states } = data;
  const selectedStateStaff = states.find(
    (stateItem) => stateId == stateItem.id
  );
  const serializedStaffList = [];
  selectedStateStaff.site_spervisors && selectedStateStaff.site_spervisors.forEach((staff) =>
    serializedStaffList.push(staff)
  );
  selectedStateStaff.staffs && selectedStateStaff.staffs.forEach((staff) => serializedStaffList.push(staff));
  let sortedList = serializedStaffList.sort((a, b) => {
    let nameA = a.first_name.toUpperCase();
    let nameB = b.first_name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  })
  return sortedList;
};

export const serializeAddInitialValues = (staffItem, formValues) => { };

export const serializeValidateRequest = (
  shiftDetails,
  jobDetails,
  formValues
) => {
  return {
    service_agent_id: jobDetails.tasks[0].serviceAgentId,
    job_id: jobDetails.id,
    site_time: moment(formValues.start_time, "HH:mm:ss").format("HH:mm:ss"),
    finish_time: `${moment(formValues.finish_date).format(
      "YYYY-MM-DD"
    )} ${moment(formValues.finish_time, "HH:mm:ss").format("HH:mm:ss")}`,
    staffs: shiftDetails.staffs ? JSON.stringify(
      shiftDetails.staffs.map((staffItem) => {
        return {
          user_name: staffItem.user_name,
          is_site_supervisor: staffItem.is_site_supervisor ? 1 : 0,
          role_name: staffItem.role_name,
          staff_name: staffItem.staff_name,
          start_time_type: staffItem.start_time_type,
        };
      })
    ) : [],
    shift_start_date: moment(formValues.copy_start_date).format("YYYY-MM-DD"),
    shift_end_date: moment(formValues.copy_end_date).format("YYYY-MM-DD"),
  };
};

export const serializeDeleteStaffRowRequest = (shiftDetails, staffUserName, jobDetails) => {
  const filteredStaff = [];

  shiftDetails.staffs.forEach((staff) => {
    if (staff.user_name === staffUserName) {
      filteredStaff.push({
        user_name: staff.user_name,
        start_time_type: staff.start_time_type,
        site_yard_time: staff.site_yard_time,
        staff_finish_time: staff.staff_finish_time,
      });
    }
  });

  return {
    job_schedule: [
      {
        id: shiftDetails.job_schedule_shift_id,
        staff: [...filteredStaff],
      },
    ],
    job_id: shiftDetails.job_id,
    job_schedule_shift_id: shiftDetails.job_schedule_shift_id,
    quote_id: jobDetails.quoteId
  };
};
