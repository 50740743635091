import {
  ORG_USERS_URL,
  ORG_USERS,
  INVITE_USERS_URL,
  RESEND_CODE,
  ORG_USERS_DELETE_URL,
  ORG_USER_ROLE,
  UNBLOCK_USER_URL
} from '../dataProvider/constant'
import { startSipnner, stopSipnner } from '../utils/spinner';
import axiosInstance, { axiosV2Instance } from '../dataProvider/axiosHelper'
import { Strings } from '../dataProvider/localize'

export const getAdvanceSearchArgs = advanceSeacrhOpt => {
  if (
    Object.keys(advanceSeacrhOpt) &&
    Object.keys(advanceSeacrhOpt).length > 0
  ) {
    var args = "";
    Object.keys(advanceSeacrhOpt).forEach(key => {
      // args = `${args}&${key}=${JSON.stringify(advanceSeacrhOpt[key])}`
      args = `${args}&${key}=${encodeURIComponent(JSON.stringify(advanceSeacrhOpt[key]))}`;
    });
    return args;
  }
  return "";
};


const getOrganizationUsersDetails = (org_id, page = 1, dispatch, advanceSeacrhOpt) => {

  startSipnner(dispatch);
  var args = "";
  if (advanceSeacrhOpt) {
    args = getAdvanceSearchArgs(advanceSeacrhOpt);
  }

  const orgUserUrl = ORG_USERS_URL + '?org_user_id=' + org_id + args + '&page=' + page;
  return axiosInstance.get(orgUserUrl)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status === 1) {
        dispatch({
          type: ORG_USERS,
          payload: res.data.data,
        });
      }
    })
    .catch(() => {
      stopSipnner(dispatch);
    });
}

export const getOrganizationUsers = (org_id, page, advanceSeacrhOpt) => dispatch => {
  return getOrganizationUsersDetails(org_id, page, dispatch, advanceSeacrhOpt);
}

export const inviteUsers = userId => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.post(INVITE_USERS_URL, userId)
    .then(res => {
      stopSipnner(dispatch);
      return Promise.resolve(res.data.message);
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(error.response ? {
        message: error.response.data.message,
        invalidUsers: error.response.data.data.invalidUsers
      }
        : { message: Strings.network_error, invalidUsers: [] })
    });
}

export const resendAtrributeCode = userData => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.post(RESEND_CODE, userData)
    .then(res => {
      stopSipnner(dispatch);
      return Promise.resolve(true);
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const updateOrganizationUser = (formData, org_id, page) => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.put(ORG_USER_ROLE, formData)
    .then(async res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        await getOrganizationUsersDetails(org_id, page, dispatch);
        return Promise.resolve(res.data.message);
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const updateUsersFromView = (formData, org_id, page) => dispatch => {
  startSipnner(dispatch);
  return axiosInstance.put(ORG_USERS_URL, formData)
    .then(async res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        await getOrganizationUsersDetails(org_id, page, dispatch);
        return Promise.resolve(res.data.message);
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response && error.response.data ? error.response.data.message : Strings.network_error);
    });
}

export const deleteOrganizationUser = (param, org_id) => dispatch => {
  startSipnner(dispatch);

  return axiosInstance.delete(ORG_USERS_DELETE_URL, { data: param })
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message)
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const activateStaff = params => dispatch => {
  startSipnner(dispatch)
  return axiosInstance.put('/activate-staff', params)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message)
      }
    })
    .catch(error => {
      stopSipnner(dispatch)
      return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
    });
}

export const unblockUser = (formData) => dispatch => {
  startSipnner(dispatch);
  let url = UNBLOCK_USER_URL;
  return axiosV2Instance.put(url, formData).then(res => {
    if (res.data.status) {
      return Promise.resolve(res.data.message)
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
  }).finally(() => {
    stopSipnner(dispatch);
  })
}