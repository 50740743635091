import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import RosteringDetailsForm from "./RosteringDetailsForm";
import AddAvailabilityForm from "./AddAvailabilityForm";
import ErrorBoundary from "../../../common/ErrorBoundary";
import CloseIcon from '@mui/icons-material/Close';
import styles from "../ProfileStyles.module.css";

export default function RosteringDetailsWrapper(props) {

  const [initialValues, setInitialValues] = useState({});
  const [availabilities, setAvailabilities] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [savingAvail, setSavingAvail] = useState(null);
  const [availValues, setAvailValues] = useState({});

  const onSaveClose = ({ values, formData }) => {
    setSavingAvail(values);
    setAvailValues(formData);
    setShowModal(false);
  }


  useEffect(() => {
    if (props.pay_classification && Object.keys(props.pay_classification)) {
      let values = { ...props.pay_classification }
      // service_type
      if (values.service_type_id && values.service_type_name) {
        values.serviceType = { label: values.service_type_name, value: values.service_type_id }
      }
      if (values.service_type_id) {
        delete values.service_type_id;
      }
      if (values.service_type_name) {
        delete values.service_type_name;
      }
      // pay_classification
      if (values.pay_classification_id && values.classification_name) {
        values.payClassification = { label: values.classification_name, value: values.pay_classification_id }
      }
      if (values.pay_classification_id) {
        delete values.pay_classification_id;
      }
      if (values.classification_name) {
        delete values.classification_name;
      }
      // employement_type
      if (values.employment_type_id && values.employment_type) {
        values.employmentType = { label: values.employment_type, value: values.employment_type_id }
      }
      if (values.employment_type_id) {
        delete values.employment_type_id;
      }
      if (values.employment_type) {
        delete values.employment_type;
      }
      // pay_code
      if (values.pay_code_id && values.pay_code) {
        values.payCode = { label: values.pay_code, value: values.pay_code_id }
      }
      if (values.pay_code_id) {
        delete values.pay_code_id;
      }
      if (values.pay_code) {
        delete values.pay_code;
      }
      if (values.staff_availabilities && values.staff_availabilities.length > 0) {
        setAvailabilities(values.staff_availabilities);
      } else {
        setAvailabilities([]);
      }
      setInitialValues(values);
    } else {
      setInitialValues({});
      setAvailabilities([])
    }
  }, [props.pay_classification])

  return (
    <div>
      <RosteringDetailsForm
        initialValues={initialValues}
        userDetails={props.userDetails}
        availabilities={availabilities}
        savingAvail={savingAvail}
        setShowModal={setShowModal}
        onSaveClose={onSaveClose}
      />
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={showModal}
        maskClosable={false}
        onCancel={() => { setShowModal(false) }}
        destroyOnClose={true}
        width={650}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
      >
        <ErrorBoundary fallbackMessage="There was an error while loading Availability.">
          <AddAvailabilityForm
            onSaveClose={onSaveClose}
            onClose={() => { setShowModal(false) }}
            savingAvail={savingAvail}
            initialValues={availValues}
          />
        </ErrorBoundary>
      </Modal>
    </div>
  )
}