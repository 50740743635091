import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, NavLink, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Layout, Menu, Dropdown } from 'antd';
import { CircularProgress } from '@mui/material';

import { NAV_MENU } from './navigationMenu';
import { signOut } from '../../actions';
import { getLoggedInUser } from '../../actions/profileManagementActions';
import InviteUsers from './organization/InviteUsers';
import { ADMIN_DETAILS, JWT_ACCESS_TOKEN, JWT_ID_TOKEN, ACCESS_CONTROL } from '../../dataProvider/constant'
import { getRandomBetween, getStorage } from '../../utils/common';
import JobEmail from './job/JobEmail/JobEmail';
import SwmsPreviewPageContainer from '../v2/Jobs/swmsPreviewPageContainer/SwmsPreviewPageContainer'
import EmailJobReport from './serviceAgentJobMgmt/jobReport/EmailJobReport';
import EmailSwmsReport from './serviceAgentJobMgmt/jobReport/EmailSwmsReport';
import ChangePassword from './profile/ChangePassword';
import PayrollTimesheet from './timeSheet/PayrollTimesheet';
import JobDetailsPageContainer from '../v2/JobDetails/JobDetailsPageContainer';
import Profile from './profile/Profile';
import JobContainer from "../v2/Job/JobContainer";
import ErrorBoundary from '../common/ErrorBoundary';
import { renewUserAccessV2 } from '../../dataProvider/axiosHelper';

import logo from "../../assets/images/fm-farm-logo.png";
import styles from "./DashboardStyles.module.css";
import DashboardCategorization from './DashboardCategorization';

const { Header, Sider, Content } = Layout;

const Dashboard = ({ authProfile }) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const [appLoading, setAppLoading] = useState(true);

  const logout = useCallback(() => {
    dispatch(signOut()).catch(err => {
      console.log(err);
    });
    history.push({ pathname: '/signin' })
  }, [])

  const menuHeader = (
    <Menu className="cs-v2-user-drop-list">
      <Menu.Item key="1" rel="noopener noreferrer" onClick={() => history.push('/dashboard/profile')}>
        Profile
      </Menu.Item>
      <Menu.Item key="2" rel="noopener noreferrer" onClick={() => history.push('/dashboard/changePassword')}>
        Change Password
      </Menu.Item>
      <Menu.Item key="3" onClick={logout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    try {
      setAppLoading(true);
      const org_user_id = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id : null;
      const org_user_name = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)).user_name : null;
      if (org_user_id && org_user_name) {
        dispatch(getLoggedInUser(org_user_id, org_user_name)).then(() => {
          setAppLoading(false);
        }).catch(err => {
          setAppLoading(false);
          logout();
          console.error(err);
        })
      }
    } catch (err) {
      setAppLoading(false);
      logout();
      console.error(err);
    }
  }, []);

  useEffect(() => {
    let timer;

    const renewUserSession = () => {
      Promise.resolve(renewUserAccessV2()).then(() => {
        let random = getRandomBetween(2, 3, 2);
        clearInterval(timer);
        timer = setInterval(renewUserSession, random * 60 * 1000);
      }).catch((err) => {
        logout();
      })
    }

    let newRand = getRandomBetween(10, 12, 2);
    timer = setInterval(renewUserSession, newRand * 3600 * 1000);

    const onStorageChange = (e) => {
      if (e.key === JWT_ACCESS_TOKEN || e.key === JWT_ACCESS_TOKEN) {
        if (e.newValue) {
          let random = getRandomBetween(10, 12, 2);
          clearInterval(timer);
          timer = setInterval(renewUserSession, random * 3600 * 1000);
        } else {
          history.push({ pathname: '/signin' })
        }
      }
    }

    window.addEventListener("storage", onStorageChange);

    return () => {
      window.removeEventListener("storage", onStorageChange);
      clearInterval(timer);
    }
  }, []);

  const checkAuthOnEveryChange = () => {
    if (!getStorage(ADMIN_DETAILS) || !getStorage(JWT_ACCESS_TOKEN) || !getStorage(JWT_ID_TOKEN)) {
      history.push('/signin');
    }
  }

  useEffect(checkAuthOnEveryChange)

  const modulesAccess = useMemo(() => {
    try {
      return JSON.parse(getStorage(ACCESS_CONTROL));
    } catch (err) {
      return {}
    }
  }, [])

  return (
    <div>
      {appLoading ? (
        <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh", color: "#362148" }}>
          <CircularProgress size={120} thickness={1} color="inherit" />
          <img src={logo} style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", height: "auto", maxWidth: "80px" }} alt="Logo FM Farm" />
        </div>
      ) : (
        <Layout style={{ height: "100vh" }}>
          <Sider width={67} style={{ background: "#fff" }}>
           
                  <DashboardCategorization modules={modulesAccess} />
                  {/* <ul>
                    {modulesAccess && Object.keys(modulesAccess).length > 0 ? Object.keys(modulesAccess).map((link, index) => {
                      var menu = NAV_MENU.find(ele => ele.linkTo === '/' + link);

                      if (modulesAccess[link].show_in_sidebar === 1 && menu) {
                        return (
                          <li key={index} className={styles.NavItem} style={{ paddingTop: index === 0 ? "0px" : "10px" }}>
                            <NavLink activeClassName={styles.NavLinkActive} className={`${styles.NavItemLink} cs-v2-nav-link`} to={{ pathname: match.path + menu.linkTo, state: { fromLink: true } }}>
                              <span className={styles.NavItemLinkIcon}>{menu.iconType(modulesAccess[link].icon_name)}</span>
                              <span className={styles.NavItemLinkHeading}>{modulesAccess[link].module_name} </span>
                            </NavLink>
                          </li>
                        )
                      }
                    }) : ""}
                  </ul> */}
  
          </Sider>
          <Layout>
            <Header style={{ boxShadow: "none", borderBottom: "1px solid #F2F2F2" }}>
              <div>
                <img src={logo} style={{ height: "40px" }} alt="Logo FM Farm" />
              </div>
              <div className="dropdown fmf-account-btn" id="profile-drop">
                <Dropdown overlay={menuHeader} className={`dropdown-toggle ${styles.DropdownButton}`}>
                  <button className={`dropdown-toggle ${styles.DropdownButton}`} type="button">
                    <img src={authProfile && authProfile.profile_pic ? authProfile.profile_pic : null} className={styles.ProfileImage} />
                    <span className={styles.ProfileName}>
                      {authProfile && authProfile.name ? authProfile.name + " " + (authProfile.last_name ? authProfile.last_name : "") : null}
                    </span>
                  </button>
                </Dropdown>
              </div>
            </Header>

            <Layout>
              <Content>
                <ErrorBoundary>
                  <React.Suspense fallback={<div>Loading</div>}>
                    <Switch>
                      {modulesAccess && Object.keys(modulesAccess).length > 0 ? Object.keys(modulesAccess).map((link) => {
                        var menu = NAV_MENU.find(ele => ele.linkTo === '/' + link);
                        if (modulesAccess[link].show_in_sidebar === 1 && menu) {
                          return (<Route path={match.path + menu.linkTo} component={menu.component} key={link} />)
                        }
                      }
                      ) : ""}
                      <Route path={match.path + '/inviteUsers'} component={InviteUsers} />
                      <Route path={match.path + '/v2/swmsUpdatePreview'} component={SwmsPreviewPageContainer} />
                      <Route path={match.path + '/user/:userId/:userName'} component={Profile} key={7} />
                      <Route path={match.path + '/v2/job-details'} component={JobDetailsPageContainer} />
                      <Route path={match.path + '/time-sheets'} component={PayrollTimesheet} />
                      <Route path={match.path + '/changePassword'} component={ChangePassword} />
                      <Route path={match.path + '/job/:jobId/email-swms-report'} component={EmailSwmsReport} key={24} />
                      <Route path={match.path + '/job/:jobId/email-job-report'} component={EmailJobReport} key={48} />
                      <Route path={match.path + '/job/:jobId/email-job-document'} component={JobEmail} key={72} />
                      <Route path={match.path + '/job/:jobId'} component={JobContainer} />
                      <Route render={() => <Redirect to="/dashboard/dashboard" />} />
                    </Switch>
                  </React.Suspense>
                </ErrorBoundary>
              </Content>
            </Layout>
          </Layout>
        </Layout>)
      }
    </div>
  )
}

export default connect((state) => ({
  authProfile: state.auth.authUser,
}))(Dashboard)
