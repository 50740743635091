import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { notification } from "antd";

import styles from "../../../JobStyle.module.css";
import { approveTaskValue } from "../../../../../../actions/v2/JobActions";
import { Strings } from "../../../../../../dataProvider/localize";

export default function ApproveTaskModal({ onClose, task, frequency, refreshJobBoard, jobId }) {

  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  const onConfirm = () => {
    setSubmitting(true);
    let formValues = {
      status: frequency.freq_status,
      quote_task_label: frequency.quote_task_label,
      task_id: task.task_id,
      job_id: jobId,
      quote_task_id: frequency.quote_task_id,
      assignee_id: frequency.assignee,
      task_invoice_id: frequency.task_invoice_id,
      contractor_task_invoice_id: frequency.contractor_task_invoice_id ? frequency.contractor_task_invoice_id : null,
      // task_label: task.task_label,
      // task_value_status: 1,
    };

    dispatch(approveTaskValue(formValues)).then(message => {
      setSubmitting(false);
      notification.success({
        message: Strings.success_title,
        description: message,
        className: "ant-success"
      })
      refreshJobBoard();
      onClose();
    }).catch(err => {
      if (err) {
        setSubmitting(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: "ant-error"
        })
      }
    })
  }

  return (
    <div className="job-swms-modal-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={styles.ModalHeading}>
              Are you Sure you want to Approve Task value for Task {task.task_label}?
            </div>
            <div className="">
              <hr />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div style={{ color: "red" }}>This action is not reversible.</div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-right">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "end", columnGap: "10px" }}>
              {submitting ? (
                <CircularProgress size={20} />) : ""}
              <button type="button" className={`${styles.OutlinedButton}`} onClick={onClose} style={{ padding: "0px 5px" }}>
                Cancel
              </button>
              <button
                type="button"
                onClick={() => onConfirm()}
                disabled={submitting}
                className={styles.PrimaryButton}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}