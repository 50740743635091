import { Tooltip } from "antd";
import React from "react";
import { Field } from "redux-form";
import { CustomDatepicker } from "../../../../common/customDatepicker";
import { CustomTimePicker } from "../../../../common/customTimePicker";
import { shiftStatusByMap, titleCase } from "./utils";
import moment from "moment";
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import "./TimesheetUpdateStyles.css";
import jobStyles from "../../../Job/JobStyle.module.css";
import { IconButton } from "@mui/material";

export const fetchColumns = (
  editingKey,
  handleEdit,
  saveEdit,
  cancelEdit,
  openModal
) => [
    {
      title: "Shift ID",
      dataIndex: "job_schedule_shift_id",
      sorter: (a, b) => a.job_schedule_shift_id - b.job_schedule_shift_id,
      width: 80,
    },
    {
      title: "Staff Name",
      dataIndex: "staff_name",
      sorter: (a, b) =>
        a.staff_name > b.staff_name ? -1 : a.staff_name < b.staff_name ? 1 : 0,
      width: 150,
      render: (cellContent) => (
        <Tooltip title={cellContent}>
          <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
            {cellContent}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Shift Status",
      dataIndex: "shift_status",
      // sorter: (a, b) => (a.day > b.day ? -1 : a.day < b.day ? 1 : 0),
      width: 110,
      render: (cellContent) => (
        <span
          className={`shift-status ${shiftStatusByMap[parseInt(cellContent)]}`}
        >
          {titleCase(shiftStatusByMap[parseInt(cellContent)])}
        </span>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      render: (cellContent, rowData, index) => {
        if (editingKey === rowData.staff_id)
          return (
            <div className="timesheet-cell-editable jobinput76">
              <Field
                name={`start_date_${rowData.staff_id}`}
                size="small"
                component={CustomDatepicker}
                className="timesheet-v2-date-picker"
                dropdownClassName="cs-datepicker-dropdown"
              />
            </div>
          );
        else return cellContent ? moment(cellContent).format("DD-MM-YYYY") : '';
      },
      width: 110,
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      render: (cellContent, rowData, index) => {
        if (editingKey === rowData.staff_id)
          return (
            <div className="timesheet-cell-editable jobinput76">
              <Field
                name={`start_time_${rowData.staff_id}`}
                size="small"
                timeFormat="HH:mm" 
                component={CustomTimePicker}
                className="timesheet-v2-time-picker"
              />
            </div>
          );
        else return cellContent;
      },
      width: 100,
    },
    {
      title: "Finish Date",
      dataIndex: "finish_date",
      render: (cellContent, rowData, index) => {
        if (editingKey === rowData.staff_id)
          return (
            <div className="timesheet-cell-editable jobinput76">
              <Field
                name={`finish_date_${rowData.staff_id}`}
                size="small"
                component={CustomDatepicker}
                className="timesheet-v2-date-picker"
                dropdownClassName="cs-datepicker-dropdown"
              />
            </div>
          );
        else return cellContent ? moment(cellContent).format("DD-MM-YYYY") : '';
      },
      width: 110,
    },
    {
      title: "Finish Time",
      dataIndex: "finish_time",
      render: (cellContent, rowData, index) => {
        if (editingKey === rowData.staff_id)
          return (
            <div className="timesheet-cell-editable jobinput76">
              <Field
                name={`finish_time_${rowData.staff_id}`}
                size="small"
                component={CustomTimePicker}
                timeFormat="HH:mm" 
                className="timesheet-v2-time-picker"
              />
            </div>
          );
        else return cellContent;
      },
      width: 100,
    },
    {
      title: "OHS",
      dataIndex: "ohs_status",
      width: 75,
      render: (cellContent) => (
        <span
          className={`dot ${cellContent ? "bg-green" : "red-status-dot"}`}
        ></span>
      ),
    },
    {
      title: "Photo",
      dataIndex: "report_status",
      width: 75,
      render: (cellContent) => (
        <span
          className={`dot ${cellContent ? "bg-green" : "red-status-dot"}`}
        ></span>
      ),
    },
    {
      title: "Allowance",
      dataIndex: "allownace_status",
      width: 105,
      render: (cellContent, rowData) => {
        return (
          <button
            type="button"
            className={`allocation-timesheet-table-button ${cellContent ? "allowance-green" : "allowance-red"
              }`}
            onClick={() => openModal(cellContent, rowData)}
          >
            {cellContent ? "Added" : "Not Added"}
          </button>
        );
      },
    },
    {
      title: "Total Hrs",
      dataIndex: "total_hrs",
      sorter: (a, b) => a.total_hrs - b.total_hrs,
      width: 90,
    },
    {
      title: "Break Hrs",
      dataIndex: "break_time",
      sorter: (a, b) => a.break_time - b.break_time,
      width: 100,
    },
    {
      title: "Approved",
      dataIndex: "approved",
      sorter: (a, b) => a.approved - b.approved,
      width: 110,
      render: (cellContent) => (
        <span
          className={`timesheet-row-approved ${cellContent ? "approved-green" : "approved-red"
            }`}
        >
          {cellContent ? "Approved" : "Not Approved"}
        </span>
      ),
    },
    {
      title: "Approved By",
      dataIndex: "approver_name",
      sorter: (a, b) =>
        a.approver_name > b.approver_name
          ? -1
          : a.approver_name < b.approver_name
            ? 1
            : 0,
      width: 130,
      render: (cellContent) => (
        <Tooltip title={cellContent}>
          <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
            {cellContent}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Approved On",
      dataIndex: "approved_on",
      width: 170,
      render: (cellContent) => cellContent ? moment(cellContent).format("DD-MM-YYYY HH:mm:ss") : ''
    },
    {
      title: "Action",
      fixed: "right",
      render: (cellContent, rowData, rowIndex) => {
        return editingKey !== rowData.staff_id ? (
          <div className="timesheet-action-buttons edit-line-item-timesheet">
            <IconButton className={jobStyles.MuiIconButton}
              styles={{ height: "auto" }}
              onClick={() => handleEdit(rowData, rowIndex)}
            >
              <EditIcon
                style={{ color: "#c0b4ff", height: "16px", width: "16px" }}
              />
            </IconButton>
          </div>
        ) : (
          <div className="timesheet-action-buttons edit-line-item-timesheet">
            <IconButton className={jobStyles.MuiIconButton}
              style={{ color: "#c0b4ff", height: "auto" }}
              onClick={() => saveEdit(rowData, rowIndex)}
            >
              <SaveIcon
                style={{ height: "16px", width: "16px" }}
              />
            </IconButton>
            <IconButton className={jobStyles.MuiIconButton}
              style={{ color: "red", height: "auto" }}
              onClick={() => cancelEdit(rowData, rowIndex)}
            >
              <CloseIcon
                style={{ height: "16px", width: "16px" }}
              />
            </IconButton>
          </div>
        );
      },
      width: 50,
    },
  ];
