import { GET_SWMS_CATEGORY_FILTERS } from "../../dataProvider/constant";
const INITIAL_STATE = {
    categories: [],
    methods: []
}

export default (state = INITIAL_STATE, action) => {
    const { payload } = action;
    switch (action.type) {
        case GET_SWMS_CATEGORY_FILTERS: {

            return {
                ...state,
                categories: payload.categories,
                methods: payload.methods,
            }
        }
        default: {
            return state;
        }
    }
}