import React from "react";
import { Icon, Dropdown, notification } from "antd";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import * as actions from "../../../../actions/SAJobMgmtAction";
import * as actionEmail from "../../../../actions/emailTemplateAction";
import { JobDocsEmailvalidate } from "../../../../utils/Validations/emailQuoteValidation";
import { customInput } from "../../../common/custom-input";
import { Strings } from "../../../../dataProvider/localize";
import EmailEditor from "../../../common/EmailEditor";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { handleFocus, goBackBrowser, getFormattedPhone, getEmailDate, getFirstName } from "../../../../utils/common";
import { getStorage } from "../../../../utils/common";
import { ADMIN_DETAILS, SLUG_JOB_REPORT } from "../../../../dataProvider/constant";
import { ERROR_NOTIFICATION_KEY } from "../../../../config";
import { MultiEmailInput } from "../../../common/chipInputRedux";
import 'react-multi-email/style.css';

class EmailJobReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleCc: true,
      toggleBcc: true,
      editorState: "",
      togleSearch: true,
      adminDetails: JSON.parse(getStorage(ADMIN_DETAILS)),
      signature: "",
      noReply: ``
    };
  }

  handleSearchToggle = () => {
    this.setState({ togleSearch: !this.state.togleSearch });
  };

  handleCcToggle = (event) => {
    event.preventDefault();
    this.setState({ toggleCc: !this.state.toggleCc });
  };

  handleBccToggle = (event) => {
    event.preventDefault();
    this.setState({ toggleBcc: !this.state.toggleBcc });
  };

  componentDidMount(props) {
		document.title = "Email Job Report to Client - Service Farm";
    this.props.emailAction
      .getEmailTemplate(SLUG_JOB_REPORT)
      .then((flag) => {
        this.editorState();
      })
      .catch((message) => {
        notification.error({
          key: ERROR_NOTIFICATION_KEY,
          message: Strings.success_title,
          description: message ? message : Strings.generic_error,
          onClick: () => { },
          className: "ant-error",
        });
      });
  }

  generatePDFLink = () => {
    const { jobReports, jobDocument, isSignOff } = this.props.location.state;
    const reportIds = jobReports && jobReports.map(reportItem => reportItem.id);
    const docIds = jobDocument && jobDocument.map(documentItem => documentItem.id);
    return `${process.env.REACT_APP_CLIENT_APP_URL ? process.env.REACT_APP_CLIENT_APP_URL : window.location.origin}/job_report_preview?job_id=${this.props.job.job_id}&reportIds=${reportIds}&docIds=${docIds}&isSignOff=${isSignOff}`;
  }


  editorState = () => {
    let body = this.props.selectedMailContent && this.props.selectedMailContent.body
      ? this.props.selectedMailContent.body
      : "";

    let adminDetails = JSON.parse(getStorage(ADMIN_DETAILS));

    let pdfLink = this.generatePDFLink();
    body = body.replace( /\{\{pdf_link\}\}/g, `<a href="${pdfLink}" target="_blank" style="text-decoration: underline;">View Job Report</a>`);

    let client_person_first_name = this.props.job && this.props.job.client_person_name ? getFirstName(this.props.job.client_person_name) : '';
    body = body.replace(/\{\{client_person_first_name\}\}/g, client_person_first_name)

    let orgName = this.props.job && this.props.job.org_name ? this.props.job.org_name : ''
    body = body.replace(/\{\{org_name\}\}/g, orgName);

    let dateToday = getEmailDate("");
    body = body.replace(/\{\{date_today\}\}/g, dateToday);

    let siteName = this.props.job && this.props.job.sites && this.props.job.sites[0] && this.props.job.sites[0].site_name ? this.props.job.sites[0].site_name : ''
    body = body.replace(/\{\{site_name\}\}/g, siteName);

    body = body.replace(/<p>{{tasks_data}}<\/p>/g, "{{tasks_data}}")

    let tasks = this.props.job && this.props.job.sites && this.props.job.sites[0] && this.props.job.sites[0].tasks ? this.props.job.sites[0].tasks : []
    let taskDataStr = `<ul>`
    tasks.forEach((task, index) => {
      taskDataStr += `<li>Task ${index + 1} - ${task.task_name} at ${task.area_name}</li>`
    })
    taskDataStr += `</ul>`
    body = body.replace(/\{\{tasks_data\}\}/g, taskDataStr);

    body = body.replace(/<p>\{\{documents_data\}\}<\/p>/g, "{{documents_data}}")

    let documents = this.props.jobDocuments
    let documentStr = `<ul>`;
    documents && documents.length > 0 && documents.forEach(document => {
      documentStr += `<li>${document.document_name}</li>`;
    })
    documentStr += `</ul>`;
    body = body.replace(/\{\{documents_data\}\}/g, documentStr);

    let userPhoneNumber = adminDetails && adminDetails.phone_number ? getFormattedPhone(adminDetails.phone_number) : ''
    body = body.replace(/\{\{user_phone_number\}\}/g, userPhoneNumber);

    let userName = adminDetails ? adminDetails.name + " " + adminDetails.last_name : "";
    body = body.replace(/\{\{user_name\}\}/g, userName);

    body = body.replace(/\{\{user_role\}\}/g, adminDetails && adminDetails.role && adminDetails.role.role_name ? adminDetails.role.role_name : '')

    body = body.replace(/\{\{user_email\}\}/g, adminDetails && adminDetails.email_address ? 
        `<a href="mailto:${adminDetails.email_address}">${adminDetails.email_address}</a>` : "")

    body = body.replace(/\{\{org_phone\}\}/g, adminDetails && adminDetails.organisation && adminDetails.organisation.phone_number ? adminDetails.organisation.phone_number : "")

    body = body.replace(/\{\{org_website\}\}/g, adminDetails && adminDetails.organisation && adminDetails.organisation.website ? adminDetails.organisation.website : '')

    body = body.replace(/\{\{org_address\}\}/g, adminDetails && adminDetails.organisation && adminDetails.organisation.address ? adminDetails.organisation.address : '')

    body = body.replace(/\{\{signature_image\}\}/g, `<img naturalheight="0" naturalwidth="0" src="https://dev-sf-store.s3.ap-southeast-2.amazonaws.com/email_banner.jpg" crossorigin="use-credentials" border="0" style="max-height: 200px; width: auto; cursor: pointer;">`)

    // signature modification --- must be done in the end.
    if (body.includes("<p>____sign</p>") && body.includes("<p>sign____</p>")) {
      // has signature
      let start = body.indexOf("<p>____sign</p>");
      let end = body.lastIndexOf("<p>sign____</p>")

      if (start !== -1 && end !== -1) {
          let signEnd = end + 15;
          let dataStart = start + 15;
          let signData = body.substr(dataStart, (end - dataStart));
          this.setState({ signature: signData });
          body = body.substring(0, start) + '' + body.substring(signEnd);
      }
    }

    if (body.includes("<p>____no_reply</p>") && body.includes("<p>no_reply____</p>")) {
      // has signature
      let start = body.indexOf("<p>____no_reply</p>");
      let end = body.lastIndexOf("<p>no_reply____</p>")

      if (start !== -1 && end !== -1) {
          let signEnd = end + 15;
          let dataStart = start + 15;
          let noReplyData = body.substr(dataStart, (end - dataStart));
          this.setState({ noReply: noReplyData });
          body = body.substring(0, start) + '' + body.substring(signEnd);
      }
    }

    const html = body;
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        editorState: editorState,
      });
      return editorState;
    }
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  onSubmit = async (formData) => {
    var value = this.state.editorState === "" ? "" : draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
    let message = this.state.editorState === "" ? this.editorState() : value;

    let to = [];
    formData.to_field.forEach(email => {
      if (email.value) {
        to.push(email.value)
      }
    })

    let cc = [];
    formData.cc_field.forEach(email => {
      if (email.value) {
        cc.push(email.value)
      }
    }) 

    let bcc = [];
    formData.bcc_field.forEach(email => {
      if (email.value) {
        bcc.push(email.value)
      }
    }) 

    // change <ins> tags to <u>
    message = message.replace(/<ins>/g, "<u>");

    message = message.replace(/<\/ins>/g, "</u>");

    message = message + `<!--[if mso]><table role="presentation" cellspacing="0" cellpadding="0" border="0" width="600"><tr><td><![endif]--><div style='width: auto;border:none;border-top:solid #000000 1.0pt; border-bottom: solid #000000 1.0pt; padding:4.0pt 0cm 4.0pt 0cm; margin: 20pt 0cm 10pt 0cm;'>`
    + this.state.noReply + `</div><!--[if mso]></td></tr></table><![endif]-->`;

    message = `<!--[if mso]><table role="presentation" cellspacing="0" cellpadding="0" border="0"><tr><td><![endif]--><div style="font-family: 'calibri', sans-serif !important; font-size: 11pt !important; width: fit-content;">` + message + `</div><!--[if mso]></td></tr></table><![endif]-->`;
    message = message.replace(/font-family:.?inherit;/g, "font-family: 'calibri', sans-serif;");

    message = message.replace(/\{\{service_name\}\}/g, Strings.SERVICE_NAME);
    message = message.replace(/\{\{service_email\}\}/g, `<a href="mailto:${Strings.SERVICE_EMAIL}">${Strings.SERVICE_EMAIL}</a>`);
    message = message.replace(/<p><\/p>/g, '')

    //strip /n from body before sending email
    message = message.replace(/(?:\r\n|\r|\n)/g, '');
    
    formData.message = message;
    formData = {
      ...formData,
      job_id: this.props.job.job_id,
      job_number: this.props.job.job_number,
      email_type: "JOB_REPORT",
      to_field: to.join(','),
      cc_field: cc.join(','),
      bcc_field: bcc.join(',')
    };

    var finalFormData = new FormData();

    Object.keys(formData).forEach((key) => {
      finalFormData.append(key, JSON.stringify(formData[key]));
    });


    this.props.action
      .sendEmailJobReport(finalFormData)
      .then((res) => {
        notification.success({
          message: Strings.success_title,
          description: "Email Sent",
          onClick: () => { },
          className: "ant-success",
        });
        goBackBrowser(this.props);
      })
      .catch((message) => {
        notification.error({
          message: Strings.error_title,
          description: message ? message : Strings.generic_error,
          onClick: () => { },
          className: "ant-error",
        });
      });
  };

  render() {
    const { handleSubmit, job } = this.props;
    return (
      <div className="sf-page-layout">
        <div className="dash-header">
          <h2 className="page-mn-hd">
            <Icon
              type="arrow-left"
              onClick={() =>
                goBackBrowser(this.props)
              }
            />
            {Strings.send_job_report}
          </h2>
        </div>
        <div
          className={
            this.props.togleSearch ? "col-md-9" : "col-md-9 col-md-srch"
          }
        >
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <div className="sf-card-wrap">
              <div className="sf-card mt-4">
                <div className="sf-card-head abb-1 d-flex justify-content-between align-items-center">
                  <h2 className="sf-pg-heading">{Strings.send_job_report}</h2>
                  <div className="info-btn disable-dot-menu">
                    <Dropdown className="more-info" disabled>
                      <i className="material-icons">more_vert</i>
                    </Dropdown>
                  </div>
                </div>
                <div className="sf-card-body">
                  <div className="user-p-notxt send-email d-flex justify-content-start">
                    <img alt="" src="/images/owl-img.png" />
                    <span>
                      This job report will be sent to {job && job.client_name ? job.client_name : ''} on email id {job && job.client_person_email ? job.client_person_email : ''}.
                      If you wish to speak to the client and give them a heads up call them on their number {job && job.client_person_phone ? getFormattedPhone(job.client_person_phone) : ''}.
                    </span>
                  </div>
                  <div className="email-form">
                    <fieldset className="form-group sf-form">
                      <Field
                        label={Strings.from_profile}
                        name="from_profile"
                        type="text"
                        id="name"
                        disabled={true}
                        component={customInput}
                      />
                    </fieldset>
                    <fieldset className="form-group sf-form bcc-userid">
                      <div className="cc-bcc-email">
                        <button
                          onClick={(event) => this.handleCcToggle(event)}
                          className="normal-bnt"
                        >
                          Cc
                        </button>
                        <button
                          onClick={(event) => this.handleBccToggle(event)}
                          className="normal-bnt"
                        >
                          Bcc
                        </button>
                      </div>
                      <Field
                        label={Strings.from_txt}
                        name="from_field"
                        type="text"
                        id="name"
                        disabled={true}
                        component={customInput}
                      />
                    </fieldset>
                    <fieldset className="form-group sf-form">
                      <Field
                        label={Strings.to_txt}
                        name="to_field"
                        id="name"
                        component={MultiEmailInput}
                      />
                    </fieldset>
                    {/* cc and bcc fields here */}
                    <div className="bcc-fields">
                      <fieldset
                        className={
                          this.state.toggleCc
                            ? "form-group sf-form"
                            : "form-group sf-form d-none"
                        }
                      >
                        <Field
                          label="Cc"
                          name="cc_field"
                          type="text"
                          id="name"
                          component={MultiEmailInput}
                        />
                      </fieldset>
                      <fieldset
                        className={
                          this.state.toggleBcc
                            ? "form-group sf-form"
                            : "form-group sf-form d-none"
                        }
                      >
                        <Field
                          label="Bcc"
                          name="bcc_field"
                          type="text"
                          id="name"
                          component={MultiEmailInput}
                        />
                      </fieldset>
                    </div>
                    <fieldset className="form-group sf-form">
                      <Field
                        label={Strings.subject_txt}
                        name="subject"
                        type="text"
                        id="name"
                        component={customInput}
                      />
                    </fieldset>
                    <div className="form-group sf-form rich-textbox">
                      <label>{Strings.message_txt}</label>
                      <div className="sf-rich-txtbox">
                        <fieldset>
                          <Field
                            name="message"
                            type="text"
                            id="name"
                            editorState={
                              this.state.editorState === ""
                                ? this.editorState()
                                : this.state.editorState
                            }
                            onEditorStateChange={this.onEditorStateChange}
                            component={EmailEditor}
                          />
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="all-btn d-flex justify-content-end mt-4 sc-doc-bnt">
              <div className="btn-hs-icon">
                <button type="submit" className="bnt bnt-active">
                  {Strings.send_btn}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let initialOrgData = {};

  let adminDetails = JSON.parse(getStorage(ADMIN_DETAILS));

  let emailTemplateContent = state.emailTemplate && state.emailTemplate.emailTemplateList && state.emailTemplate.emailTemplateList.length && state.emailTemplate.emailTemplateList[0] ? state.emailTemplate.emailTemplateList[0] : '';

  let user_name = adminDetails ? adminDetails.name + " " + (adminDetails.last_name ? adminDetails.last_name : '') : ''
  let org_name = adminDetails && adminDetails.organisation && adminDetails.organisation.name ? adminDetails.organisation.name : ''
  let cc = adminDetails && adminDetails.email_address ? adminDetails.email_address : "";

  let to = state.sAJobMgmt && state.sAJobMgmt.jobDetails && state.sAJobMgmt.jobDetails.client_person_email ? state.sAJobMgmt.jobDetails.client_person_email : '';

  let bcc = emailTemplateContent && emailTemplateContent.to_bcc !== undefined ? emailTemplateContent.to_bcc : '';

  let subject = emailTemplateContent && emailTemplateContent.subject ? emailTemplateContent.subject : '';

  let jobLabel = state.sAJobMgmt.jobDetails && state.sAJobMgmt.jobDetails && state.sAJobMgmt.jobDetails.job_number ? state.sAJobMgmt.jobDetails.job_number : ''
  subject = subject.replace(/\{\{job_label\}\}/g, jobLabel);

  let siteName = state.sAJobMgmt.jobDetails && state.sAJobMgmt.jobDetails.sites && state.sAJobMgmt.jobDetails.sites[0] &&
    state.sAJobMgmt.jobDetails.sites[0].site_name ? state.sAJobMgmt.jobDetails.sites[0].site_name : '';
  subject = subject.replace(/\{\{site_name\}\}/g, siteName);

  let noReplyFrom = `${user_name} (${org_name}) via ${Strings.SERVICE_NAME} <${emailTemplateContent.from_email}>`

  subject = subject.replace(/\{\{org_name\}\}/g, org_name);
  
  initialOrgData = emailTemplateContent ? {
    from_profile: org_name,
    from_field: noReplyFrom,
    to_field: [{ label: to, value: to }],
    cc_field: [{ label: cc, value: cc }],
    bcc_field: [{ label: bcc, value: bcc }],
    subject: subject
  } : {};

  return {
    initialValues: initialOrgData,
    selectedScopeDoc: state.scopeDocs && state.scopeDocs.scopeDocsDetails ? state.scopeDocs.scopeDocsDetails : {},
    job: state.sAJobMgmt.jobDetails,
    jobReports: state.sAJobMgmt.jobReports,
    jobDocuments: state.sAJobMgmt.jobDocument,
    filePath: state.sAJobMgmt.filePath,
    selectedMailContent: emailTemplateContent ? emailTemplateContent : {},
  };
};

const mapDispatchToprops = (dispatch) => {
  return {
    action: bindActionCreators(actions, dispatch),
    emailAction: bindActionCreators(actionEmail, dispatch),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToprops),
  reduxForm({
    form: "EmailJobReport",
    validate: JobDocsEmailvalidate,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, sub, props) => {
      handleFocus(errors, "#");
    },
  })
)(EmailJobReport);
