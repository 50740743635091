import React, { Component } from "react";
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { serializeParams } from "./serializer";
import {
  reduxForm,
  Field,
  FieldArray,
  isDirty
} from 'redux-form';

import { validate } from '../../../../utils/Validations/industryValidation';
import * as action from '../../../../actions/reportManagementAction';
import { handleFocus, DeepTrim } from '../../../../utils/common';
import { customInput } from "../../../common/custom-input";
import { CustomSelect } from "../../../common/customSelect";
import { CustomDatepicker } from "../../../common/customDatepicker";
import { Strings } from "../../../../dataProvider/localize";
import PerfectScrollbar from 'react-perfect-scrollbar'
import { customTextarea } from "../../../common/customTextarea";
import moment from "moment";
import { DatePicker, Modal } from "antd";
import { popOverCheckBox } from "../../../common/popOverCheckBox";
import { Table, Tooltip, Icon, Button, notification, Typography } from "antd";
const { MonthPicker, RangePicker } = DatePicker;

export  class HazardReport extends Component {
  constructor(props) {
    super(props)
  }
  state = {
    selectedRowKeys: [], // Check here to configure the default column
    loading: false,
    filters: {},
    editingKey: "",
    currentPage: 1,
    pageSize: 10,
    status: 1
  };
  setPickerDefaultRange = () => {
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
    return [moment(twoWeeksAgo), moment(new Date())];
  };

  siteIds = [{}];
  clientIds = (val) => {
    let sites = this.props.clientListData.clients.sites;
    this.siteIds = sites.filter(item => val.includes(item.client_id));
    this.setState({ filters: { ...this.state.filters, client_ids: val } });
  }
  jobId = (val) => {
    this.setState({ filters: { ...this.state.filters, job_ids: val } });

  }
  jobIds = [{}];
  siteId = (val) => {

    let jobs = this.props.clientListData.clients.jobs;
    this.jobIds = jobs.filter(item => val.includes(item.site_id));
    this.setState({ filters: { ...this.state.filters, site_ids: val } });
    this.errorMessage("Somthing went wrong.");
  }
  successMessage = (msg) => {
    return notification.success({
      message: Strings.success_title,
      description: msg,
      onClick: () => { },
      className: "ant-success",
    });
  }
  errorMessage = (msg) => {
    return notification.error({
      message: Strings.error_title,
      description: msg,
      onClick: () => { },
      className: "ant-error",
    });
  }
  taskTypeId = (val) => {
    this.setState({ filters: { ...this.state.filters, type_ids: val } });

  }
  removeEliment = (fields, index) => {
    return fields.splice(index, 1);
  }
  componentDidMount() {
    this.props.getReportManagementToolboxTalk();
    this.props.getClientList();
    this.props.getTypeList();
  }
  filter = () => {
    const params = this.state.filters;
    this.props
      .getReportManagementToolboxTalk(params)
      .then(() => {
        this.setState(
          { editingKey: "", selectedRowKeys: "", currentPage: 1 },
          () => this.onPageChange(1)
          // this.setState({ ...this.state })
        );
      })
      .catch(() => { });
  };
  onPageChange = (arg) => {
    this.setState({
      currentPage: arg,
      /* editingKey: "", */ selectedRowKeys: "",
    });
  };
  reset = () => {
    this.setState({ filters: "", selectedRowKeys: [], editingKey: "", currentPage: 1 }, () =>
      this.props.reset()
    );
  };
  onSubmit = async (formData) => {
    console.log("++++++++++++++++++++++++++", formData)
    // // formData = await DeepTrim(formData);
    // // var finalFormData = new FormData();
    // // Object.keys(formData).forEach(key => {
    // //   finalFormData.append(key, JSON.stringify(formData[key]));
    // // })

    // // console.log("+++++++++++====+++++++++++++++",finalFormData)
    // this.props.createReportManagementToolboxTalk(formData)
    //   .then((data) => {
    //     // this.props.reset();

    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   });

  }
  getName = (val) => {
    this.setState({ filters: { ...this.state.filters, name: val.target.value } });

  }

  getStatus = (val) => {
    this.setState({ filters: { ...this.state.filters, status: val.target.value } });

  }
  getId = (val) => {
    console.log("88888888888val", val.target.value);
    this.setState({ filters: { ...this.state.filters, id: val.target.value } });

  }
  onSelectChange = (selectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    this.setState({ selectedRowKeys, editingKey: "" });
  };

  handleTaskDelete = (task_item) => {
    this.setState({ showTaskEdit: 'none' })
    Modal.confirm({
      title: "Delete Task",
      content: "Do you wants to delete this Task",
      onOk: () => this.handdleOk(task_item),
      cancelText: "Cancel",
    });
  }

  handdleOk = (task_item) => {
    // var scope_docs_id = this.props.selectedScopeDoc.id
    // this.props.action.deleteSiteTask(task_item.id, scope_docs_id, this.props.location.state).then((res) => {
    //   notification.success({
    //     message: Strings.success_title,
    //     description: res.message,
    //     onClick: () => { },
    //     className: 'ant-success'
    //   });
    // }).catch((message) => {
    //   notification.error({
    //     message: Strings.error_title,
    //     description: message ? message : Strings.generic_error,
    //     onClick: () => { },
    //     className: 'ant-error'
    //   });
    // });
  }

  columns = [
    {
      title: "Hazard Rep. No. (Name (ID)).",
      dataIndex: "id",
      sorter: (a, b) => (a.id > b.id ? -1 : a.id < b.id ? 1 : 0),
      editable: true,
      width: 120,
    },
    {
      title: "Client",
      dataIndex: "client_name",
      sorter: (a, b) =>
        a.client_name > b.client_name
          ? -1
          : a.client_name < b.client_name
            ? 1
            : 0,
      editable: true,
      width: 150,
      render: (cellContent) => (
        <Tooltip title={cellContent}>
          <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
            {cellContent}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Site",
      dataIndex: "site_name",
      sorter: (a, b) =>
        a.site_name > b.site_name ? -1 : a.site_name < b.site_name ? 1 : 0,
      editable: true,
      width: 100,
      render: (cellContent) => (
        <Tooltip title={cellContent}>
          <div
            data-tip={cellContent}
            style={{ textOverflow: "ellipsis", overflow: "hidden" }}
          >
            {cellContent}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Job",
      dataIndex: "job_name",
      sorter: (a, b) =>
        a.job_name > b.job_name ? -1 : a.job_name < b.job_name ? 1 : 0,
      editable: true,
      width: 150,
      render: (cellContent, rowData, index) => (
        <Tooltip title={cellContent}>
          <div
            style={{ textOverflow: "ellipsis", overflow: "hidden" }}
          >{`${cellContent} (Job ID: ${rowData.job_id})`}</div>
        </Tooltip>
      ),
    },
    {
      title: "Date Created",
      dataIndex: "created_at",
      sorter: (a, b) =>
        a.created_at > b.created_at ? -1 : a.created_at < b.created_at ? 1 : 0,
      editable: true,
      width: 150,
      render: (cellContent, rowData, index) => (
        <Tooltip title={cellContent}>
          <div
            style={{ textOverflow: "ellipsis", overflow: "hidden" }}
          >{`${cellContent ? cellContent : ''}`}</div>
        </Tooltip>
      ),
    },
    {
      title: "Date Revised",
      dataIndex: "updated_at",
      sorter: (a, b) =>
        a.updated_at > b.updated_at ? -1 : a.updated_at < b.updated_at ? 1 : 0,
      editable: true,
      width: 150,
      render: (cellContent, rowData, index) => (
        <Tooltip title={cellContent}>
          <div
            style={{ textOverflow: "ellipsis", overflow: "hidden" }}
          >{`${cellContent ? cellContent : ''}`}</div>
        </Tooltip>
      ),
    },
    {
      title: "Created by",
      dataIndex: "created_by",
      sorter: (a, b) =>
        a.created_by > b.created_by ? -1 : a.created_by < b.created_by ? 1 : 0,
      editable: true,
      width: 150,
      render: (cellContent, rowData, index) => (
        <Tooltip title={cellContent}>
          <div
            style={{ textOverflow: "ellipsis", overflow: "hidden" }}
          >{`${cellContent ? cellContent : ''} `}</div>
        </Tooltip>
      ),
    },
    {
      title: "Action",
      editable: true,
      fixed: "right",
      render: (cellContent, rowData, rowIndex) => {
        return (
          <div className="timesheet-action-buttons edit-line-item-timesheet">
            <i
              class="fa fa-pencil"
              style={{
                color: /* user.role && user.role_id > 0 ? */ "#03A791" /* : 'red' */,
              }}
              aria-hidden="true"
              onClick={() =>
                this.props.history.push({
                  pathname: '/dashboard/create-toolbox-report',
                  state: rowData.id
                })
              }
              // onClick={() => this.props.history.push('/dashboard/create-toolbox-report')}
            ></i>
            <i
              class="fa fa-eye"
              style={{
                color:
                  /* user.role && user.role_id > 0 ? */ "green" /* : 'red' */,
              }}
              aria-hidden="true"
              onClick={() => this.saveEdit(rowData, rowIndex)}
            ></i>
            <i
              class="fa fa-file-pdf-o"
              style={{
                color:
                  /* user.role && user.role_id > 0 ? */ "green" /* : 'red' */,
              }}
              aria-hidden="true"
              onClick={() => this.cancelEdit(rowData, rowIndex)}
            ></i>
            <i
              class="fa fa-trash"
              style={{
                color:
                  /* user.role && user.role_id > 0 ? */ "red" /* : 'red' */,
              }}
              aria-hidden="true"

              onClick={() => this.handleTaskDelete(rowData)}
            ></i>
          </div>
        )
      },
      width: 50,
    },


  ];
  render() {
    const { data, reportManagementToolboxList, handleSubmit, clientListData, typeListData } = this.props;
    const { loading, selectedRowKeys } = this.state;
    const hasSelected = selectedRowKeys.length > 0;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div>

        <h1 className="TabsContainer_tabHeader__3fQuw"> Search &amp; list:</h1>
        {/* <h1 className="TabsContainer_tabHeader__3fQuw">&amp; Search &amp; list Toolbox talk:</h1> */}

        <div className="new-swms-table toolbox-talk">

          {/* ======================== */}
          <form onSubmit={handleSubmit(this.onSubmit)} className="antt-form">
            <div className="oh-cont">
            <button className="bnt bnt-active add-row-swms-bnt" onClick={()=>this.props.history.push('/dashboard/create-hazard-report')}>Create Hazard Report</button>
              <button className="bnt bnt-active add-row-swms-bnt btn-reset" onClick={this.reset}><i class="fa fa-repeat mr-2" aria-hidden="true"></i> Reset</button>
            </div>
            <ul className="search-list">
              <li>
                <div className="form-group sf-form">
                  <label>Hazard Report ID</label>
                  <input name="name" id="name-focus"
                    onChange={(val) => this.getId(val)}
                    placeholder="HZ R ID" type="text" defaultValue="" />
                </div>
              </li>
              <li>
                <div className="form-group sf-form">
                  <label>Hazard Report</label>
                  <input id="report-name" placeholder="HZ R Name" onChange={(val) => this.getName(val)} type="text" />
                </div>
              </li>
              <li>
                <div className="form-group sf-form">
                  <label>Status</label>
                  <select name="Status" id="status" defaultValue={this.props.status}
                    onChange={this.getStatus}
                  >
                    <option value="1">Active</option>
                    <option value="0">Inactive </option>
                  </select>
                </div>
              </li>
              {/* ---------------- */}
              <li>
                <div className="form-group sf-form">
                  <Field
                    label="Site"
                    name="site_id"
                    component={popOverCheckBox}
                    options={
                      this.siteIds.map(item => ({
                        label: item.site_name,
                        value: item.site_id
                      }))}

                    buttonTitle="Site Id"
                    onChange={(val) => this.siteId(val)}
                  />

                </div>
              </li>
              <li>
                <div className="form-group sf-form">

                  <Field
                    label="Job"
                    name="job_id"
                    component={popOverCheckBox}
                    options={
                      this.jobIds.map(item => ({
                        label: item.job_name,
                        value: item.job_id
                      }))}

                    buttonTitle="Job Id"
                    onChange={(val) => this.jobId(val)}
                  />

                </div>
              </li>
              {/* -------------------- */}



              <li className="float-right">
                <fieldset className="form-group sf-form lsico">
                  <Button
                    type="primary"
                    onClick={this.filter}
                    // disabled={!hasSelected}
                    loading={loading}
                    className="timesheet-filter-button"
                  >
                    Filter
                </Button>
                  <span style={{ marginLeft: 8, fontSize: "0.75rem" }}>
                    {hasSelected
                      ? `Selected ${selectedRowKeys.length} items`
                      : ""}
                  </span>
                </fieldset>
              </li>

            </ul>
          </form>
          {/* ======================== */}

          <div className="sf-ps-scroll-content">
            <div class="table-responsive">
              <div
                style={{
                  maxWidth: "92.5vw",
                  // overflowY: "scroll",
                  padding: "1rem",
                }}
              >
                <Table
                  rowSelection={rowSelection}
                  columns={this.columns.slice(0, 13)}
                  dataSource={reportManagementToolboxList}
                  pagination={{
                    current: this.state.currentPage,
                    pageSize: this.state.pageSize,
                    onChange: (a) => this.onPageChange(a),
                  }}
                  bordered
                  scroll={{ x: 1000 }}
                />
              </div>

            </div>
          </div>
        </div>
      </div>


    )
  }
}
const mapStateToProps = (state) => {
  return {
    reportManagementToolboxList: state.reportManagement.reportManagementToolboxList,
    clientListData: state.reportManagement.clientListData,
    typeListData: state.reportManagement.typeListData,
    formValues: state.form.getToolboxTalk && state.form.getToolboxTalk.values,
    // initialValues: { item_id: [] }
  }
}
const mapDispatchToprops = dispatch => {
  return {
    // getReportManagementToolboxTalk:dispatch(action.getReportManagementToolboxTalk),
    getReportManagementToolboxTalk: bindActionCreators(action.getReportManagementToolboxTalk, dispatch),
    getClientList: bindActionCreators(action.getClientList, dispatch),
    getTypeList: bindActionCreators(action.getTypeList, dispatch),
  }
}
export default compose(
  connect(mapStateToProps, mapDispatchToprops),
  withRouter,  // connect(mapStateToProps, action),
  reduxForm({
    form: 'getToolboxTalk', validate, onSubmitFail: (errors, dispatch, sub, props) => {
      handleFocus(errors, "#");
    },
    enableReinitialize: true
  })
)(HazardReport)
