import React, { useEffect, useRef, useState } from "react";
import styles from "../../JobStyle.module.css";

import { Checkbox, Radio, notification } from 'antd';
import PreviewSwms from "../JobSwmsPreview/PreviewSwms";
import SwmsVersionEmailModal from "./SwmsVersionEmailModal";
import { Modal } from 'antd';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { getEmailSwmsContent, getSwmsFormData, sendSwmsEmail } from "../../../../../actions/v2/JobSwmsActions";
import { Strings } from "../../../../../dataProvider/localize";
import { getFormattedPhone, getStorage, getFirstName } from "../../../../../utils/common";
import { ADMIN_DETAILS } from "../../../../../dataProvider/constant";

export default function VersionSelectionModal({ allTasks, loading, onClose }) {

  const dispatch = useDispatch();
  const controller = useRef();

  const [emailModal, setEmailModal] = useState(false);
  const [versions, setVersions] = useState({});
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [selectedTaskSWMS, setSelectedTaskSWMS] = useState(null);
  const [previewSwmsModal, setPreviewSwmsModal] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [tasks, setTasks] = useState([]);
  const [noReplyData, setNoReplyData] = useState('');
  const [emailData, setEmailData] = useState({})


  useEffect(() => {
    let newTasks = [];
    if (allTasks) {
      allTasks.forEach(task => {
        let versions = task.swms_versions.filter(x => x.approval_status && x.approval_status.toString().toLowerCase() === "manager approved");
        if (versions && versions.length > 0) {
          newTasks.push({ ...task, swms_versions: [...versions] })
        }
      });
    }
    setTasks(newTasks);
  }, [allTasks])

  const onCheckChange = (e, taskId, task) => {
    if (e.target.checked) {
      setSelectedTasks(t => [...t, taskId]);
      setVersions((vers) => ({ ...vers, [taskId]: task.swms_versions[0].swms_form_id }))
    } else {
      setSelectedTasks(t => t.filter(x => x !== taskId));
      setVersions(vers => ({ ...vers, [taskId]: null }));
    }
  }

  const onPreview = (swms) => {
    setSelectedTaskSWMS({ swms });
    setPreviewSwmsModal(true);
  }

  const onSubmit = () => {
    controller.current = new AbortController();
    setSubmitting(true);
    let tasksArr = []
    for (let key in versions) {
      let task = tasks.find(x => x.task_id.toString() === key.toString());
      if (task) {
        let version = task.swms_versions.filter(x => x.swms_form_id.toString() === versions[key].toString())
        if (version && version.length === 1) {
          tasksArr.push({ ...version[0], task_name: task.task_name, task_number: task.task_number })
        }
      }
    }
    Promise.all([
      dispatch(getEmailSwmsContent({ signal: controller.current.signal })),
      dispatch(getSwmsFormData({ signal: controller.current.signal }, tasksArr[0].swms_form_id))]).then(res => {
        let data = res[0];
        let swmsData = res[1].swms_data;

        let adminDetails = JSON.parse(getStorage(ADMIN_DETAILS))

        let body = data.body;
        let subject = data.subject;
        let bcc = Array.isArray(data.to_bcc) ? data.to_bcc.map(address => ({ label: address, value: address })) : [{ label: data.to_bcc, value: data.to_bcc }];
        let cc = adminDetails && adminDetails.email_address ? [{ label: adminDetails.email_address, value: adminDetails.email_address }] : []
        let to = swmsData && swmsData.client_details && swmsData.client_details.client_contact_email ? [{ label: swmsData.client_details.client_contact_email, value: swmsData.client_details.client_contact_email }] : "";

        let orgName = adminDetails && adminDetails.organisation && adminDetails.organisation.name ? adminDetails.organisation.name : '';
        let siteName = swmsData && swmsData.site_details && swmsData.site_details.site_name ? swmsData.site_details.site_name : "";
        let userName = adminDetails ? adminDetails.name + " " + (adminDetails.last_name ? adminDetails.last_name : '') : []
        let userPhoneNumber = adminDetails && adminDetails.phone_number ? getFormattedPhone(adminDetails.phone_number) : ''
        let userEmailAddress = adminDetails && adminDetails.email_address ? `<a href="mailto:${adminDetails.email_address}">${adminDetails.email_address}</a>` : "";

        let noReplyFrom = `${userName} (${orgName}) via ${Strings.SERVICE_NAME} <${data.from_email}>`

        let clientPersonFirstName = swmsData.client_details && swmsData.client_details.client_contact_name ? getFirstName(swmsData.client_details.client_contact_name) : "";

        subject = subject.replace(/\{\{org_name\}\}/g, orgName)
        subject = subject.replace(/\{\{site_name\}\}/g, siteName)

        body = body.replace(/(?:\r\n|\r|\n)/g, '');

        body = body.replace(/\{\{client_person_first_name\}\}/g, clientPersonFirstName);
        body = body.replace(/\{\{user_phone_number\}\}/g, userPhoneNumber);
        body = body.replace(/\{\{user_email\}\}/g, userEmailAddress);
        body = body.replace(/\{\{user_name\}\}/g, userName);
        body = body.replace(/\{\{site_name\}\}/g, siteName)


        let tasksData = `<ul>`;
        tasksArr.forEach(task => {
          tasksData += `<li><a href="${task.swms_pdf_link}">Task ${task.task_number} - ${task.task_name}</a></li>`;
        })
        tasksData += `</ul>`;

        body = body.replace(/\{\{link\}\}/g, tasksData);
        body = body.replace(/<p><\/p>/g, "<br />")

        body = body.replace(/\{\{service_name\}\}/g, Strings.SERVICE_NAME);
        body = body.replace(/\{\{service_email\}\}/g, `<a href="mailto:${Strings.SERVICE_EMAIL}">${Strings.SERVICE_EMAIL}</a>`);


        if (body.includes("<p>____no_reply</p>") && body.includes("<p>no_reply____</p>")) {
          let start = body.indexOf("<p>____no_reply</p>");
          let end = body.lastIndexOf("<p>no_reply____</p>")

          if (start !== -1 && end !== -1) {
            let signEnd = end + 15;
            let dataStart = start + 15;
            let noReply = body.substr(dataStart, (end - dataStart));
            noReply = `<!--[if mso]><table role="presentation" cellspacing="0" cellpadding="0" border="0" width="600"><tr><td><![endif]--><div style='width: auto;border:none;border-top:solid #000000 1.0pt; border-bottom: solid #000000 1.0pt; padding:4.0pt 0cm 4.0pt 0cm; margin: 20pt 0cm 10pt 0cm;'>`
              + noReply + `</div><!--[if mso]></td></tr></table><![endif]-->`;
            setNoReplyData(noReply);
            body = body.substring(0, start) + '' + body.substring(signEnd);
          }
        }
        setEmailData({
          subject: subject,
          body: body,
          from: noReplyFrom,
          cc: cc,
          to: to,
          bcc: bcc
        })
        setEmailModal(true);
      }).catch(err => {
        if (err) {
          setSubmitting(false);
          notification.error({
            message: Strings.error_title,
            description: err ? err : Strings.generic_error,
            className: "ant-error"
          })
        }
      })
  }

  const onSubmitEmail = (values) => {
    setEmailLoading(true);
    let content = values.body;
    content = content + noReplyData;
    content = `<!--[if mso]><table role="presentation" cellspacing="0" cellpadding="0" border="0"><tr><td><![endif]--><div style="font-family: 'calibri', sans-serif !important; font-size: 11pt !important; width: fit-content;">` + content + `</div><!--[if mso]></td></tr></table><![endif]-->`;
    content = content.replace(/font-family:.?inherit;/g, "font-family: 'calibri', sans-serif;");

    content = content.replace(/<p><\/p>/g, '')

    //strip /n from body before sending email
    content = content.replace(/(?:\r\n|\r|\n)/g, '');
    content = content.replace(/<ins>/g, "<u>");
    content = content.replace(/<\/ins>/g, "</u>");

    let formData = {
      to: values.to.length > 0 ? values.to.map(x => x.value).join(',') : '',
      from: values.from,
      subject: values.subject,
      bcc: values.bcc.length > 0 ? values.bcc.map(x => x.value).join(',') : '',
      cc: values.cc.length > 0 ? values.cc.map(x => x.value).join(',') : '',
      body: content,
      file_path: `job-swms/email-attachments/`,
      s3_attachements: []
    };

    let uploading = false;

    if (values.attachment_docs) {
      if (values.attachment_docs.every(x => x.file_name && x.file_name.isUploaded && x.file_name.isUploaded === true)) {
        formData.s3_attachements = values.attachment_docs.map(x => x.file_name.name)
      } else {
        uploading = true;
        notification.error({
          message: Strings.error_title,
          description: "An Attachment is still uploading. Please wait for all uploads to finish.",
          className: 'ant-error'
        })
      }
    }

    if (!uploading) {
      dispatch(sendSwmsEmail(formData)).then(message => {
        notification.success({
          message: Strings.success_title,
          description: message ? message : "Email Sent Successfully.",
          className: 'ant-success'
        })
        setEmailModal(false);
        onClose();
      }).catch(err => {
        if (err) {
          setEmailLoading(false);
          notification.error({
            message: Strings.error_title,
            description: err ? err : Strings.generic_error,
            className: 'ant-error',
            style: { zIndex: 1301 }
          })
        }
      })
    } else {
      setEmailLoading(false);
    }
  }
  useEffect(() => {
    return () => {
      if (controller.current) {
        controller.current.abort();
      }
    }
  }, [])

  return (
    <div className="job-swms-modal-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={styles.ModalHeading}>
              Please select the SWMS to send to Client
            </div>
            <hr></hr>
          </div>
        </div>
        {tasks && tasks.length > 0 ? (
          <div className="row">
            <div className="col-12 pt-3" style={{ maxHeight: "60vh", overflow: "hidden auto" }}>
              {tasks.map(task => (
                <div key={task.task_id} className="">
                  <Checkbox disabled={submitting} onChange={(e) => onCheckChange(e, task.task_id, task)} checked={selectedTasks.indexOf(task.task_id) !== -1} className="cs-select-v2 font-weight-bold">{task.task_number} - {task.task_name}</Checkbox>
                  <div className="border-top my-2 px-3 py-2">
                    <Radio.Group size="small" onChange={(e) => setVersions(vers => ({ ...vers, [task.task_id]: e.target.value }))} value={versions[task.task_id]} disabled={selectedTasks.indexOf(task.task_id) === -1 || submitting} name={`task_${task.task_id}`} className="w-100">
                      {task.swms_versions.map(version => (
                        <div key={version.swms_form_id} className="mb-2 border-bottom pb-2 d-flex align-items-center justify-content-between">
                          <div style={{ flex: "1 1 auto" }}>
                            <Radio value={version.swms_form_id} className="cs-radio-v2">SWMS Version {version.swms_revision}</Radio>
                          </div>
                          <div style={{ flex: "0 0 40px" }} className="text-center">
                            <Tooltip title="Preview SWMS">
                              <span>
                                <IconButton onClick={() => onPreview(version)} className={`${styles.IconBtn} ${styles.SuccessIcon}`}>
                                  <svg xmlns="http://www.w3.org/2000/svg" style={{ height: "16px", width: "16px" }} viewBox="0 0 576 512">
                                    <path fill="currentColor" d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                                  </svg>
                                </IconButton>
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                      ))}
                    </Radio.Group>
                  </div>
                </div>
              ))}
            </div>
          </div>) : (
          <div className="row">
            <div className="col-12 text-center pt-4 pb-2" style={{ color: "red" }}>No Approved SWMS Available.</div>
          </div>
        )}
        <div className="row pt-2" onClick={(e) => e.stopPropagation()}>
          <div className="col-12 text-right">
            <div className="d-flex align-items-center justify-content-end" style={{ gap: "5px" }}>
              {submitting ? (
                <CircularProgress size={20} />
              ) : ""}
              <button type="button" className={styles.OutlinedButton} onClick={() => onClose()}>
                Cancel
              </button>
              <button disabled={selectedTasks.length === 0 || submitting} className={`${styles.PrimaryButton} ml-1`} onClick={onSubmit}>
                Send Email
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={emailModal}
        onCancel={() => setEmailModal(false)}
        afterClose={() => setSubmitting(false)}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px", maxHeight: "80vh", overflow: "hidden auto" }}
        destroyOnClose={true}
        width="768px"
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
      >
        <SwmsVersionEmailModal
          initialValues={emailData}
          loading={emailLoading}
          form="swms_email_to_client"
          onSubmitForm={onSubmitEmail}
          onClose={() => setEmailModal(false)}
        />
      </Modal>
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={previewSwmsModal}
        onCancel={() => setPreviewSwmsModal(false)}
        afterClose={() => { setSelectedTaskSWMS(null); }}
        maskClosable={false}
        destroyOnClose={true}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
        style={{ minWidth: "80vw", minHeight: "80vh" }}
      >
        {selectedTaskSWMS ? (
          <PreviewSwms
            {...selectedTaskSWMS}
            onClose={() => setPreviewSwmsModal(false)}
          />
        ) : ""}
      </Modal>
    </div>
  )
}