import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Tooltip, Typography, IconButton } from '@mui/material';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Add, Remove } from "@mui/icons-material";

import JSADraggableHazardRow from './JSADraggableHazardRow';
import styles from "../../JobStyle.module.css";

const style = {
  borderTop: '1px solid #000 !important',
  borderColor: "#000 !important",
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
};

export default function DraggableActivityRow({ genId, activity, index, moveRow, afterDrop, change, loading, onDelete }) {
  const ref = useRef(null);

  const [expanded, setExpanded] = useState(false);
  const [hazards, setHazards] = useState([]);

  useEffect(() => {
    if (activity && activity.fm_swms_activities_hazards) {
      setHazards(activity.fm_swms_activities_hazards.map((x, i) => ({ ...x, activity_hazard_order: i + 1 })));
    }
  }, [activity])

  const toggleRow = () => {
    setExpanded(x => !x);
  }

  const afterHazDrop = useCallback(() => {
    change(`jsa_section.activities[${index}].fm_swms_activities_hazards`, [...hazards])
  })

  const moveHazs = useCallback((dragIndex, hoverIndex) => {
    setHazards(prevHazards => {
      let allHazs = [...prevHazards];
      let [el] = allHazs.splice(dragIndex, 1);
      allHazs.splice(hoverIndex, 0, el);
      return allHazs.map(({ index, ...x }, i) => ({ ...x, activity_hazard_order: i + 1 }));
    });
  });


  const [{ handlerId }, drop] = useDrop({
    accept: `TABLEROW_${genId}`,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveRow(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag, preview] = useDrag({
    type: `TABLEROW_${genId}`,
    item: () => {
      return { ...activity, index }
    },
    canDrag: !loading,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (monitor.didDrop) {
        afterDrop(item);
      }
    }
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <>
      <tr ref={preview} style={{ ...style, opacity }} data-handler-id={handlerId}>
        <td className="text-center">
          <IconButton className="p-0" style={{ height: "auto", color: "#c0b4ff" }} onClick={() => toggleRow()}>
            {expanded ? <Remove /> : <Add />}
          </IconButton>
        </td>
        <td>
          <div className="d-flex align-items-center justify-content-around">
            <IconButton ref={ref} style={{ height: "auto", cursor: "grab" }} disabled={loading} className="p-0">
              <svg xmlns="http://www.w3.org/2000/svg" className={styles.DragIcon} style={{ height: "14px", cursor: "grab" }} viewBox="0 0 320 512">
                <path fill="currentColor" d="M40 352l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zm192 0l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 320c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 192l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 160c-22.1 0-40-17.9-40-40L0 72C0 49.9 17.9 32 40 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40z" />
              </svg>
            </IconButton>
            <div className="">
              {index + 1}
            </div>
          </div>
        </td>
        <td><Typography noWrap className={styles.TableText} title={activity.activity_name}>{activity.activity_name}</Typography></td>
        <td title={activity.description}><Typography noWrap className={styles.TableText}>{activity.description}</Typography></td>
        <td className="text-center">
          <Tooltip title="Delete Activity">
            <span>
              <IconButton className={`${styles.IconBtn} ${styles.DangerIcon}`} onClick={onDelete}>
                <svg xmlns="http://www.w3.org/2000/svg" style={{ height: "16px", width: "16px" }} viewBox="0 0 576 512">
                  <path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                </svg>
              </IconButton>
            </span>
          </Tooltip>
        </td>
      </tr>
      {expanded ? (
        <tr>
          <td colSpan={4}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 pb-2 pl-5">
                  <DndProvider backend={HTML5Backend}>
                    <table className="table-sm cs-table cs-table-v2 job-swms-table-child" style={{ tableLayout: "fixed", width: "100%" }}>
                      <thead>
                        <tr>
                          <th width="40"></th>
                          <th>Hazards</th>
                          <th width="40"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {hazards.map((haz, i) => (
                          <JSADraggableHazardRow
                            hazard={haz}
                            key={haz.hazard_id}
                            genId={activity.activity_id}
                            index={i}
                            loading={loading}
                            activityIndex={index}
                            change={change}
                            moveRow={moveHazs}
                            afterDrop={afterHazDrop}
                          />
                        ))}
                        {hazards.length === 0 ? (
                          <tr>
                            <td colSpan={3} className="text-center" style={{ color: "red" }}>No Hazards Available.</td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </DndProvider>
                </div>
              </div>
            </div>
          </td>
        </tr>
      ) : null}
    </>
  )
}
