import React from 'react';
import { connect } from 'react-redux';
import { Icon, notification, Pagination, Popconfirm } from 'antd';
import { goBack, handleFocus } from '../../../utils/common';
import { Strings } from '../../../dataProvider/localize';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { customInput } from '../../common/custom-input';
import { Field, reduxForm, autofill, reset } from 'redux-form';
import { validate, isRequired } from '../../../utils/Validations/payRatesValidations';
import $ from "jquery";
import * as payRateAction from '../../../actions/payRateAction';
import NoDataImg from '../../../images/no-data.png';
import * as payrollTimesheetAction from "../../../actions/payrollTimesheetAction";
import moment from 'moment';
import { CustomCheckbox } from '../../common/customCheckbox';

import { DatePicker } from 'antd';
const { MonthPicker, RangePicker } = DatePicker;

const mapRouteToTitle = {
  '/dashboard/payRoll': Strings.payroll_timesheet
}


class PayrollTimesheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: '',
          key: '1',
          fieldName: 'check'
        },
        {
          title: 'Job Date',
          key: '2',
          fieldName: 'shift_date'
        },
        {
          title: 'Day',
          key: '3',
          fieldName: 'day'
        },
        {
          title: 'Client Name',
          key: '4',
          fieldName: 'client_name'
        },
        {
          title: 'Site Name',
          key: '5',
          fieldName: 'site_name'
        },
        {
          title: 'Job Name',
          key: '6',
          fieldName: 'job_name'
        },
        {
          title: 'Staff ID',
          key: '7',
          fieldName: 'staff_id'
        },
        {
          title: 'Staff Name',
          key: '8',
          fieldName: 'staff_name'
        },
        {
          title: 'Start Date/Time',
          key: '9',
          fieldName: 'start_time'
        },
        {
          title: 'Finish Date/Time',
          key: '10',
          fieldName: 'finish_time'
        },
        {
          title: 'Total Hrs',
          key: '11',
          fieldName: 'total_hrs'
        },
        {
          title: 'Shift Type',
          key: '12',
          fieldName: 'shift_type'
        },
        {
          title: 'Base Hrs',
          key: '13',
          fieldName: 'base_hr '
        },
        {
          title: 'Overtime Hrs',
          key: '14',
          fieldName: 'overtime_hr'
        },
        {
          title: 'Double Time Hrs',
          key: '15',
          fieldName: 'double_hr'
        },
        {
          title: 'Sunday Overtime',
          key: '16',
          fieldName: 'sunday_overtime_hr'
        },
        {
          title: 'Public Holiday Overtime',
          key: '17',
          fieldName: 'holiday_overtime_hr'
        },
        {
          title: 'Payment Type',
          key: '18',
          fieldName: 'payment_type'
        },
        {
          title: 'EWP',
          key: '19',
          fieldName: 'ewp_allowance'
        },
        {
          title: 'WAH',
          key: '13',
          fieldName: 'wah_allowance'
        },
        {
          title: '<22 RA',
          key: '14',
          fieldName: 'ra_less_than_22'
        },
        {
          title: '>22 RA',
          key: '15',
          fieldName: 'ra_greater_than_22'
        },
        {
          title: 'Cold',
          key: '16',
          fieldName: 'cold_places_allowance'
        },
        {
          title: 'Hot Place 46c-56C',
          key: '17',
          fieldName: 'hotPlaces_allowance_between_46and54_degrees'
        },
        {
          title: 'Hot Place +54C',
          key: '18',
          fieldName: 'hot_places_allowance_exceeding_54degrees'
        },
        {
          title: 'Broken Shift',
          key: '19',
          fieldName: 'broken_shift_allowance'
        },
        {
          title: 'First Aid',
          key: '20',
          fieldName: 'first_aid_allowance'
        },
        {
          title: 'Leading/hand (1-10)',
          key: '21',
          fieldName: 'leading_hand_allowance_1to10_employees'
        },
        {
          title: 'Leading/hand (11-20)',
          key: '22',
          fieldName: 'leading_hand_allowance_11to20_employees'
        },
        {
          title: 'Leading/hand (21-30)',
          key: '23',
          fieldName: 'leading_hand_allowance_over_20_employees'
        },
        {
          title: 'Refuse Collect',
          key: '24',
          fieldName: 'refuse_collection_allowance'
        },
        {
          title: 'Toilet Cleaning',
          key: '25',
          fieldName: 'toilet_cleaning_allowance'
        },
        {
          title: 'Meal Allowance',
          key: '26',
          fieldName: 'meal_allowance'
        },
        {
          title: 'Vehicle',
          key: '27',
          fieldName: 'vehicle_allowance'
        },
        {
          title: 'Motor Bike',
          key: '28',
          fieldName: 'motor_cycle_allowance'
        },
        {
          title: 'Total',
          key: '29',
          fieldName: 'total'
        },
        {
          title: 'Super',
          key: '30',
          fieldName: 'super'
        },
        {
          title: 'Approved By ',
          key: '31',
          fieldName: 'approver_name'
        },
        {
          title: 'Approved? ',
          key: '32',
          fieldName: 'payroll_approved'
        },
        {
          title: 'Payroll Signature',
          key: '33',
          fieldName: 'payroll_signature'
        }
      ],

      page_no: 1,
      pay_period_from_date: null,
      pay_period_to_date: null,
    }
  }


  componentDidUpdate() {

  }

  componentDidMount() {
    this.props.payRateAction.getBroadcastMsg().subscribe(data => {
      if (data && data.type == 'leftmenu') {
        setTimeout(() => {
          this.adjustSize()
        }, 1000);
      }
    });
    setTimeout(() => {
      this.adjustSize()
    }, 1000);

    let obj = { page: 1 }
    this.props.payrollTimesheetAction.getPayrollTImesheet(obj);
  }

  adjustSize = () => {
    let minusWidth = 60;
    let { isNewAdd } = this.state;
    minusWidth = isNewAdd ? 60 : minusWidth
    $(document).ready(function () {
      let adjust_size = function () {
        let $windowWidth = $(window).width();
        let $leftSliderWidths = $('.ant-layout-sider').width();
        let $winWleflSlW = $windowWidth - ($leftSliderWidths && $leftSliderWidths > 0 ? $leftSliderWidths : 0);

        $('.swms-big-tb-scroll').css('width', ($winWleflSlW - minusWidth));
      };
      adjust_size();
      $(window).resize(adjust_size);
    });
  }

  handleFilterSubmit = () => {

  }

  onPayPeriodChange = (date, dateString) => {
    console.log(date, dateString);
    let obj = { page: 1 };
    if (dateString && dateString.length > 0) {
      if (dateString[0])
        obj['pay_period_from_date'] = dateString[0]

      if (dateString[1])
        obj['pay_period_to_date'] = dateString[1]

      this.setState({ pay_period_from_date: dateString[0], pay_period_to_date: dateString[1] });

      //this.props.payrollTimesheetAction.getPayrollTImesheet(obj);
    }
  }

  exportTimesheetIntoCSV = () => {
    let { pay_period_from_date, pay_period_to_date } = this.state;

    if (pay_period_from_date && pay_period_to_date) {
      console.log("Selected Dates are ", pay_period_from_date + " : " + pay_period_to_date);

      let obj = {
        page: 1,
        pay_period_from_date: pay_period_from_date,
        pay_period_to_date: pay_period_to_date
      }
      this.props.payrollTimesheetAction.exportTimesheet(obj)
        .then(() => {
          if (this.props.csvFileLink) {
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = this.props.csvFileLink;
            // the filename you want
            a.download = `timesheet.csv`;
            a.target = "_blank";
            document.body.appendChild(a);
            a.click();
          }
        });

    } else {
      notification.warning({
        message: Strings.warning_title,
        description: "Please select start and end date",
        onClick: () => { },
        className: "ant-warning",
      });
    }
  }

  onSubmit = (data, bit) => {
    console.log(data, 'data')
    data['payroll_approved'] = bit
    this.props.payrollTimesheetAction.updatePayrollApproved(data).then(response => {
      console.log(response, 'kkkkkkkkkkkkkkkkkkkkk')
      if (response.status) {
        let obj = { page: 1 }
        this.props.payrollTimesheetAction.getPayrollTImesheet(obj);
      } else {
        notification.error({
          message: Strings.error_title,
          onClick: () => { },
          className: 'ant-error'
        });
      }
    });
  }

  render() {
    let { columns, page_no } = this.state;
    let { handleSubmit, isGetPayrollList, totalPayRollCount, payrollList } = this.props;
    return (
      <>
        <div className="sf-page-layout">
          <div className="dash-header">
            <h2 className="page-mn-hd">
              <Icon type="arrow-left" onClick={() => goBack(this.props)} />
              {mapRouteToTitle[this.props.location.pathname] ? mapRouteToTitle[this.props.location.pathname] : Strings.payroll_timesheet}
            </h2>
            <div class="oh-cont">

            </div>
          </div>
          <div className="main-container">
            <div className="sf-card-wrap p-0 swms-big-tb-scroll">
              <div className="p-0">


                <div className="sf-card-body">
                  <form onSubmit={handleSubmit(this.handleFilterSubmit)}>

                    <div className="timesheet-filter ext-div">

                      <div className="row">
                        <fieldset className="sf-form lsico pr-2">
                          <div className="col" style={{ textAlign: 'left' }}>
                            <label>Pay Period</label>
                            <RangePicker onChange={this.onPayPeriodChange} />
                          </div>

                        </fieldset>


                        <fieldset className="sf-form pr-2">
                          <div className="col" style={{ textAlign: 'left' }}>
                            <label></label>
                            <button
                              type="button"
                              class="bnt bnt-normal"
                              onClick={this.exportTimesheetIntoCSV}
                            > Export Timesheet
                            </button>
                          </div>
                        </fieldset>

                      </div>

                    </div>
                  </form>

                  {payrollList && payrollList.length === 0 &&
                    <>
                      <table className="table pay-roll-table">

                        <thead>
                          <tr>  {columns.map((a, index) => <th key={index} style={{}} > {a.title}</th>)}</tr>
                        </thead>
                      </table>
                      <div className='nodata-img'>
                        <img src={NoDataImg} />
                      </div>
                    </>
                  }
                  {payrollList && payrollList.length > 0 &&
                    <div className=''>

                      {
                        <table className="table pay-roll-table" >

                          <tr>
                            {columns.map((a, index) =>
                              a.fieldName == 'payroll_approved' ? <td>
                                {a.title}
                                <br />
                                <i className="fa fa-times-circle fa-2x mr-15" style={{ color: 'red' }} aria-hidden="true"></i>
                                <i className="fa fa-check-circle fa-2x" style={{ color: 'green', float: 'right' }} aria-hidden="true"></i>
                              </td> :
                                <td key={index} style={{}}  > {a.title}</td>)}
                          </tr>

                          {payrollList && payrollList.map((data, index) => <>
                            <>
                              <tr key={index} /* style={{ backgroundColor: !data.payroll_approved ? '#EF9D9D' : '' }} */>
                                <td>
                                  <Field
                                    name={`${index}_select_row`}
                                    component={CustomCheckbox}
                                  />
                                </td>
                                <td>{data.day} </td>
                                {data.shift_date ? <td>{
                                  moment(data.shift_date).format("YYYY-MM-DD")
                                }</td> : <td></td>}
                                {data.client_name ? <td>{data.client_name}</td> : <td></td>}
                                {data.job_name ? <td>{data.job_name}</td> : <td></td>}
                                {data.site_name ? <td>{data.site_name}</td> : <td></td>}
                                {data.staff_id ? <td>{data.staff_id}</td> : <td></td>}
                                {data.staff_name ? <td>{data.staff_name}</td> : <td></td>}
                                {data.start_time ? <td>{data.start_time}</td> : <td></td>}
                                {data.finish_time ? <td>{data.finish_time}</td> : <td></td>}
                                {data.break_time ? <td>{data.break_time}</td> : <td></td>}
                                {data.total_hrs ? <td>{data.total_hrs}</td> : <td></td>}
                                {/* data.ewp_allowance */1 ? <td>{data.ewp_allowance}</td> : <td></td>}
                                {/* data.wah_allowance */1 ? <td>{data.wah_allowance}</td> : <td></td>}
                                {/* data.ra_less_than_22 */1 ? <td>{data.ra_less_than_22}</td> : <td></td>}
                                {/* data.ra_greater_than_22 */1 ? <td>{data.ra_greater_than_22}</td> : <td></td>}
                                {/* data.cold_places_allowance */1 ? <td>{data.cold_places_allowance}</td> : <td></td>}
                                {/* data.hotPlaces_allowance_between_46and54_degrees */1 ? <td>{data.hotPlaces_allowance_between_46and54_degrees}</td> : <td></td>}
                                {/* data.hot_places_allowance_exceeding_54degrees */1 ? <td>{data.hot_places_allowance_exceeding_54degrees}</td> : <td></td>}


                                <td className="cursor-not-ant-checkbox">
                                  <Field
                                    name={`${index}_broken_shift_allowance`}
                                    component={CustomCheckbox}
                                    checked={data.broken_shift_allowance} />
                                </td>

                                <td className="cursor-not-ant-checkbox">
                                  <Field
                                    name={`${index}_first_aid_allowance`}
                                    component={CustomCheckbox}
                                    checked={data.first_aid_allowance} />

                                </td>

                                <td className="cursor-not-ant-checkbox">
                                  <Field
                                    name={`${index}_leading_hand_allowance_1to10_employees`}
                                    component={CustomCheckbox}
                                    checked={data.leading_hand_allowance_1to10_employees} />
                                </td>

                                <td className="cursor-not-ant-checkbox">
                                  <Field
                                    name={`${index}_leading_hand_allowance_11to20_employees`}
                                    component={CustomCheckbox}
                                    checked={data.leading_hand_allowance_11to20_employees} />
                                </td>

                                <td className="cursor-not-ant-checkbox">
                                  <Field
                                    name={`${index}_leading_hand_allowance_over_20_employees`}
                                    component={CustomCheckbox}
                                    checked={data.leading_hand_allowance_over_20_employees} />
                                </td>

                                <td className="cursor-not-ant-checkbox">
                                  <Field
                                    name={`${index}_refuse_collection_allowance`}
                                    component={CustomCheckbox}
                                    checked={data._refuse_collection_allowance} />
                                </td>

                                <td className="cursor-not-ant-checkbox">
                                  <Field
                                    name={`${index}_toilet_cleaning_allowance`}
                                    component={CustomCheckbox}
                                    checked={data._toilet_cleaning_allowance} />
                                </td>


                                {data.meal_allowance ? <td>{data.meal_allowance}</td> : <td></td>}
                                {data.vehicle_allowance ? <td>{data.vehicle_allowance}</td> : <td></td>}
                                {data.motor_cycle_allowance ? <td>{data.motor_cycle_allowance}</td> : <td></td>}
                                {data.total_rate ? <td>${data.total_rate}</td> : <td></td>}
                                {data.super_rate ? <td>${data.super_rate}</td> : <td></td>}
                                {data.approver_name ? <td>{data.approver_name}</td> : <td></td>}
                                {<td className="">{
                                  data.payroll_approved ?
                                    <>
                                      <div className="first-checkbox">
                                        <Field
                                          name={`${index}_approved_false`}
                                          component={CustomCheckbox}
                                          checked={false} />
                                      </div>
                                      <div className={data.payroll_approved ? 'second-checkbox-not-allowed' : 'second-checkbox-allowed'}>
                                        <Field
                                          name={`${index}_approved_true`}
                                          component={CustomCheckbox}
                                          checked={true}
                                          style={{ marginLeft: "12px" }}
                                        />
                                      </div>
                                    </> :
                                    <>
                                      <div className="first-checkbox">
                                        <Field
                                          name={`${index}_approved_false`}
                                          component={CustomCheckbox}
                                          checked={true} />
                                      </div>
                                      <div className={data.payroll_approved ? 'second-checkbox-not-allowed' : 'second-checkbox-allowed'}>

                                        <Popconfirm
                                          title={"Are you sure You want to Approve this job"}
                                          onConfirm={() => this.onSubmit(data, true)}
                                          placement="topRight"
                                          okText="Yes"
                                          cancelText="No"
                                          className="delete-bnt"
                                        >
                                          <input type="checkbox" className="" name={`${index}.approved_true`}
                                            value={`${index}.approved_true`}
                                            checked={false} />
                                        </Popconfirm>

                                        {/* <Field
                                          name={`${index}_approved_true`}
                                          component={CustomCheckbox}
                                          checked={false}
                                          style={{ marginLeft: "12px" }}
                                        /> */}
                                      </div>
                                    </>}</td>}
                                {data.payroll_signature ? <td>{data.payroll_signature}</td> : <td></td>}
                              </tr>
                            </>

                          </>)}
                        </table>
                      }
                    </div>

                  }
                </div>
              </div>
            </div>

          </div>

        </div>


      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    payrollList: state.payrollReducer.payrollList,
    totalPayRollCount: state.payrollReducer.totalPayRollCount,
    isGetPayrollList: state.payrollReducer.isGetPayrollList,
    csvFileLink: state.payrollReducer.csvFileLink,
  }

}

const mapDispatchToprops = dispatch => {
  return {
    payRateAction: bindActionCreators(payRateAction, dispatch),
    payrollTimesheetAction: bindActionCreators(payrollTimesheetAction, dispatch),

  }

}

export default compose(
  connect(mapStateToProps, mapDispatchToprops),
  withRouter,
  reduxForm({
    form: 'Payroll', validate,
    onSubmitFail: (errors, dispatch, sub, props) => {
      handleFocus(errors, "#");
    },
    enableReinitialize: true,
  })
)(PayrollTimesheet)