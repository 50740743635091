import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, notification, Input } from "antd";
import { Strings } from "../../../../../dataProvider/localize";
import { fetchColumns } from "./config/tableDefinitions";
import { compose, bindActionCreators } from "redux";
import { reduxForm } from "redux-form";
import * as actions from "../../../../../actions/SWMSAction";

export class SWMSCategory extends Component {
  state = {
    currentPage: 1,
    pageSize: 10,
    editingKey: "",
    addMode: false,
    items: this.props.rows,
    search: ''
  };

  componentDidMount() {
    this.props.action.getOrgSWMS()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentTab !== this.props.currentTab) {
      this.setState({ addMode: false, editingKey: "", search: ""});
      this.props.reset();
      this.setState({ items: this.props.rows });
    }
    if (prevProps.rows !== this.props.rows) {
      this.setState({ items: this.props.rows })
      if(this.state.search && this.state.search.length >= 3) {
        this.onSearch({ target: { value: this.state.search }})
      }
    }
  }

  onSearch = (e) => {
    this.setState({ search: e.target.value })
    let value = e.target.value;
    if (value.trim().length >= 3) {
      let items = this.props.rows;
      let filteredItems = items.filter(i => (i.category !== null && i.category.toString().toLowerCase().includes(value.toLowerCase().trim())));
      this.setState({ items: filteredItems });
    } else {
      this.setState({ items: this.props.rows });
    }
  }

  onPageChange = (arg) => {
    this.setState({
      currentPage: arg,
    });
  };

  saveRow = (data, rowIndex, editing) => {
    const { formValues, action } = this.props;

    const formData = {};

    if (editing) {
      if (formValues && formValues[`title_${data.id}`]) {
        formData.id = data.id;
        formData.category = formValues[`title_${data.id}`].trim();
        formData.active = formValues[`status_${data.id}`] ? 1 : 0;
      }
    } else {
      if (formValues && formValues.title) {
        formData.category = formValues.title.trim();
        formData.active = formValues.active ? 1 : 0;
      }
    }
    if (!(!formData.category) && formData.active !== undefined) {
      if (!editing) {
        action
        .addSWMSCat({swms_category: [formData]})
        .then((message) => {
          notification.success({
            message: Strings.success_title,
            description: message ? message : Strings.generic_error,
            onClick: () => { },
            className: "ant-success",
          });
          this.setState({ addMode: false, editingKey: "" });
          this.props.reset();
        })
        .catch((message) => {
          notification.error({
            message: Strings.error_title,
            description: message
              ? Array.isArray(message)
                ? message[0].message
                : message
              : Strings.generic_error,
            onClick: () => { },
            className: "ant-error",
          });
        });
      } else {
        action
        .updateSWMSCat(formData)
        .then((message) => {
          notification.success({
            message: Strings.success_title,
            description: message ? message : Strings.generic_error,
            onClick: () => { },
            className: "ant-success",
          });
          this.setState({ addMode: false, editingKey: "" });
          this.props.reset();
        })
        .catch((message) => {
          notification.error({
            message: Strings.error_title,
            description: message
              ? Array.isArray(message)
                ? message[0].message
                : message
              : Strings.generic_error,
            onClick: () => { },
            className: "ant-error",
          });
        });
      }
    } else {
      notification.error({
        message: Strings.error_title,
        description: "All of the Fields are required.",
        className: "ant-error"
      })
    }
  };

  editRow = (data, rowIndex) => {
    this.setState({ editingKey: data.id, addMode: false });
    this.props.change(`title_${data.id}`, data.category);
    this.props.change(`status_${data.id}`, data.active);
  };

  cancel = (data, rowIndex) => {
    this.setState({ addMode: false, editingKey: "" });
    this.props.reset();
  };

  changeStatus = (value, data, rowIndex) => {
    if (!this.state.editingKey && !this.state.addMode) {
      const formData = {
        id: data.id,
        name: data.category,
        active: value ? 1 : 0,
      };

      this.props.action
        .updateSWMSCat(formData)
        .then((message) => {
          notification.success({
            message: Strings.success_title,
            description: message ? message : Strings.generic_error,
            onClick: () => { },
            className: "ant-success",
          });
        })
        .catch((message) => {
          notification.error({
            message: Strings.error_title,
            description: message
              ? Array.isArray(message)
                ? message[0].message
                : Strings.generic_error
              : Strings.generic_error,
            onClick: () => { },
            className: "ant-error",
          });
        });
    }
  };

  addRow = () => {
    this.setState({ addMode: true, editingKey: "", currentPage: 1 });
  };

  deleteRow = (data, rowIndex) => {
    const formData = {
      id: data.id,
    }

    this.props.action
      .deleteswmsCat(formData)
      .then((message) => {
        notification.success({
          message: Strings.success_title,
          description: message ? message : Strings.generic_error,
          onClick: () => { },
          className: "ant-success",
        });
      })
      .catch((message) => {
        notification.error({
          message: Strings.error_title,
          description: message
            ? Array.isArray(message)
              ? message[0].message
              : Strings.generic_error
            : Strings.generic_error,
          onClick: () => { },
          className: "ant-error",
        });
      });
  }

  render() {
    const { addMode, editingKey, currentPage, pageSize, items } = this.state;
    return (
      <div className="new-swms-table">
        <div className="d-flex justify-content-between px-2">
          <div className="">
            <Input placeholder="Search" value={this.state.search} onChange={this.onSearch} style={{ width: '250px', maxWidth: '500px' }} />
          </div>
          <div>
            <button
              type="button"
              className="bnt bnt-active add-row-swms-bnt"
              onClick={() => this.addRow()}
              disabled={addMode || editingKey}
            >
              Add Rows
            </button>
          </div>
        </div>
        <div
          style={{
            maxWidth: "90vw",
            padding: "0.5rem",
          }}
        ></div>
        <Table
          columns={fetchColumns(
            this.saveRow,
            this.editRow,
            this.cancel,
            this.changeStatus,
            editingKey,
            currentPage,
            this.deleteRow,
            this.props.orgSWMS
        )}
        dataSource={addMode ? [{ key: "addRow" }, ...items] : items}
          pagination={{
            current: this.state.currentPage,
            pageSize: this.state.pageSize,
            onChange: (a) => this.onPageChange(a),
          }}
          bordered
          scroll={{ x: 1000 }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rows: state.swmsReducer.orgSWMS.swms_cat,
  orgSWMS: state.swmsReducer.orgSWMS,
  formValues: state.form.SWMSCategoryTable && state.form.SWMSCategoryTable.values
});

const mapDispatchToProps = dispatch => {
  return {
    action: bindActionCreators(actions, dispatch),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "SWMSCategoryTable",
  })
)(SWMSCategory);
