import React, { useEffect, useState, useRef } from "react";
import styles from "../../JobStyle.module.css";
import { notification, Pagination } from "antd";
import { LinearProgress, Typography } from "@mui/material";
import { Strings } from "../../../../../dataProvider/localize";
import { IconButton } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { updateInvoice } from "../../../../../actions/v2/JobActions";
import { useDispatch } from "react-redux";
import { currencyFormat, downloadDocument } from "../../../../../utils/common";
import PreviewDocument from "../../JobSwms/JobSwmsForm/PreviewDocument";
import { CustomSingleSelect } from "../../../../common/new-core-ui/CustomSingleSelect";
import { components } from "react-select";
import { InvoiceStatusSelectStyle } from "../../SelectV2SingleStyle";

export default function InvoiceTable({ dataLoading, invoices, refreshJobBoard, isDisabled = false }) {

  const dispatch = useDispatch();
  const controller = useRef();

  const [loading, setLoading] = useState(false);
  // const [invoices, setInvoices] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);

  const toggleFilePreview = (file = null) => {
    setPreviewFile(file);
  }

  const invoiceStatuses = ["Approved", "Unapproved"];

  const approveInvoice = (id, status) => {
    setLoading(true);
    let formData = {
      id: id,
      invoice_state: status
    }
    dispatch(updateInvoice(formData)).then((message) => {
      notification.success({
        message: Strings.success_title,
        description: message ? message : Strings.generic_error, onClick: () => { },
        className: 'ant-success'
      });
      refreshJobBoard();
      setLoading(false);
    }).catch(err => {
      notification.error({
        message: Strings.error_title,
        description: err ? err : Strings.generic_error,
        className: 'ant-error'
      })
      setLoading(false);
    })
  }

  const downloadInvoice = async (invoice) => {
    setDownloading(true);
    try {
      downloadDocument(invoice.invoice_pdf_file);
    } catch (e) {
      notification.error({
        message: Strings.error_title,
        description: e ? e : Strings.generic_error,
        className: "ant-error"
      })
    }
  }

  // useEffect(() => {
  //   if (task && task.uploaded_invoices) {
  //     setInvoices(task.uploaded_invoices);
  //   }
  // }, [task])

  useEffect(() => {
    // fetchInvoices(1);
    return () => {
      if (controller.current) {
        controller.current.abort()
      }
    }
  }, []);

  return (
    <div className="font-weight-normal">
      <div style={{ height: "4px" }}>
        {loading || dataLoading ? (
          <LinearProgress />
        ) : ""}
      </div>
      <div>
        <table className="table-sm cs-table cs-table-v2" style={{ tableLayout: "fixed", width: "100%", color: "#000" }}>
          <thead>
            <tr>
              <th width="20%">Supplier</th>
              <th width="20%">PO No</th>
              <th width="10%">PO Value</th>
              <th width="10%">Inv. No</th>
              <th width="10%">Inv. Value</th>
              <th width="20%" className="text-center">Status</th>
              <th width="10%" className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map(invoice => (
              <tr key={invoice.id}>
                <td title={invoice.supplier_name}>
                  <Typography className={styles.TableText} noWrap>
                    {invoice.supplier_name}
                  </Typography>
                </td>
                <td title={invoice.po_order_number ? invoice.po_order_number : ""}>
                  <Typography className={styles.TableText} noWrap>
                    {invoice.po_order_number ? invoice.po_order_number : ""}
                  </Typography>
                </td>
                <td title={invoice.po_value ? currencyFormat(invoice.po_value) : ""}>
                  <Typography className={styles.TableText} noWrap>
                    {invoice.po_value ? currencyFormat(invoice.po_value) : ""}
                  </Typography>
                </td>
                <td title={invoice.invoice_number}>
                  <Typography className={styles.TableText} noWrap>
                    {invoice.invoice_number}
                  </Typography>
                </td>
                <td title={invoice.invoice_amount ? currencyFormat(invoice.invoice_amount) : ""}>
                  <Typography className={styles.TableText} noWrap>
                    {invoice.invoice_amount ? currencyFormat(invoice.invoice_amount) : ""}
                  </Typography>
                </td>
                <td title={invoice.invoice_state} className="text-center">
                  <CustomSingleSelect
                    value={{ label: invoice.invoice_state, value: invoice.invoice_state }}
                    onChange={(v) => approveInvoice(invoice.id, v.value)}
                    options={invoiceStatuses.map(x => ({ label: x, value: x }))}
                    styles={InvoiceStatusSelectStyle}
                    meta={{ touched: false, error: false, warning: false }}
                    otherProps={{
                      isDisabled: isDisabled,
                      components: { dropdownIndicator: <components.DownChevron /> },
                      className: "w-100",
                      menuPortalTarget: document.body
                    }}
                  />
                  {/* {invoice.invoice_state ? (
                    <button
                      className={styles.InvoiceStatusBox}
                      style={{ background: "#ffdbc6" }}
                    >
                      {invoice.invoice_state}
                    </button>
                  ) : null} */}
                </td>
                <td>
                  <div className="d-flex align-items-center justify-content-center" style={{ gap: "10px" }}>
                    {/* {invoice.invoice_state && invoice.invoice_state.toLowerCase() === "under review" ? (
                      <>
                        <Tooltip title="Approve Invoice">
                          <span>
                            <IconButton onClick={() => approveInvoice(invoice.uploaded_invoice_id, 1)} className="p-0" sx={{ height: "auto", color: "#90bf00" }} size="small">
                              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                                <path fill="currentColor" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                              </svg>
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Unapprove Invoice">
                          <span>
                            <IconButton onClick={() => approveInvoice(invoice.uploaded_invoice_id, 0)} className="p-0" sx={{ height: "auto", color: "red" }} size="small">
                              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                <path fill="currentColor" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                              </svg>
                            </IconButton>
                          </span>
                        </Tooltip>
                      </>
                    ) : null} */}
                    <Tooltip title="Preview">
                      <span>
                        <IconButton onClick={() => toggleFilePreview(invoice.invoice_pdf_file)} className="p-0" sx={{ height: "auto", color: "#90bf00" }} size="small">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
                            <path fill="currentColor" d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                          </svg>
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Download">
                      <span>
                        <IconButton onClick={() => downloadInvoice(invoice)} className="p-0" sx={{ height: "auto", color: "#362148" }} size="small">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                            <path fill="currentColor" d="M256 0a256 256 0 1 0 0 512A256 256 0 1 0 256 0zM127 281c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l71 71L232 136c0-13.3 10.7-24 24-24s24 10.7 24 24l0 182.1 71-71c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L273 393c-9.4 9.4-24.6 9.4-33.9 0L127 281z" />
                          </svg>
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
            {!loading && invoices.length === 0 ? (
              <tr>
                <td colSpan={7} className="text-center" style={{ color: "red" }}>No Invoices Available.</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
      {/* <div className="d-flex justify-content-end pt-1">
        <Pagination
          className="cs-pagination-v2"
          total={fieldCount}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Invoices`}
          onChange={(pageNo) => { fetchInvoices(pageNo) }}
          pageSize={10}
          current={page}
          size="small"
          defaultCurrent={1}
        />
      </div> */}
      {previewFile &&
        <PreviewDocument file={previewFile} onCancel={toggleFilePreview} />
      }
    </div>
  )
}