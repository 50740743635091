import React from "react";
import { connect, useDispatch } from "react-redux";
import { formValueSelector } from "redux-form";
import { notification } from "antd";

import { sendPOEmail } from "../../../../../../actions/v2/JobActions";
import { Strings } from "../../../../../../dataProvider/localize";
import POEmail from "./POEmail";

let POEmailContentWrapper = (props) => {
  
  const dispatch = useDispatch();

  const onSubmitAll = (noReplyData) => {
    props.setSubmitting(true);
    let formData = { job_id: props.jobId, site_id: `${props.siteId}` };
    let poBatches = [];
    props.po_forms.forEach(({ values, task }) => {
      let content = values.body;
      content = content + noReplyData;
      content = `<!--[if mso]><table role="presentation" cellspacing="0" cellpadding="0" border="0"><tr><td><![endif]--><div style="font-family: 'calibri', sans-serif !important; font-size: 11pt !important; width: fit-content;">` + content + `</div><!--[if mso]></td></tr></table><![endif]-->`;
      content = content.replace(/font-family:.?inherit;/g, "font-family: 'calibri', sans-serif;");
      content = content.replace(/<p><\/p>/g, '')
      content = content.replace(/<ins>/g, "<u>");
      content = content.replace(/<\/ins>/g, "</u>");

      let contractorsTasks = []
      task.taskDetails.map((t) => {
        contractorsTasks.push({
          task_id: `${t.task_id}`,
          quote_task_id: `${t.quote_task_id}`,
          task_label: t.task_label,
          task_name: t.task_name,
          task_amount: t.task_amount,
          contractor_task_cost: t.contractor_task_cost
        })
      })
      poBatches.push({
        contractor_id: `${task.contractor_id}`,
        contractors_tasks: contractorsTasks,
        to: values.to.map(x => x.value).join(','),
        from: values.from,
        subject: values.subject,
        bcc: values.bcc && values.bcc.length > 0 ? values.bcc.map(x => x.value).join(',') : '',
        cc: values.cc && values.cc.length > 0 ? values.cc.map(x => x.value).join(',') : '',
        body: content,
        file_path: "",
        s3_attachements: []
      })
    })
    formData.po_batches = poBatches
    dispatch(sendPOEmail(formData)).then((message) => {
      props.setSubmitting(false); 
      props.onClose();
      props.closeTaskSelectionModal();
      props.refreshJobBoard()
      notification.success({
        message: Strings.success_title,
        description: message,
        className: "ant-success"
      })
    }).catch(err => {
      props.setSubmitting(false); 
      notification.error({
        message: Strings.error_title,
        description: err ? err : Strings.generic_error,
        className: "ant-error"
      })
    })
  }
  
  return (
    <div className="row">
      <div className="col-12 px-0">
        {props.tasksByContractors.map((task, index) => (
          <div key={task.contractor_id.toString()} style={{ display: index === props.current ? "block" : "none" }}>
            <POEmail
              {...props}
              task={task}
              onSubmitAll={onSubmitAll}
              taskDetails={task.taskDetails}
              contractorsDetails={props.contractorsDetails}
              onClose={props.onClose}
              isReIssue={props.isReIssue}
              closeTaskSelectionModal={props.closeTaskSelectionModal}
              refreshJobBoard={props.refreshJobBoard}
              jobLabel={props.jobLabel}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

const mapState = (state, props) => {
  return {
    po_forms: props.tasksByContractors.map(task => ({
      values: formValueSelector(`po_email_${task.contractor_id}`)(state, "to", "from", "body", "cc", "bcc", "subject"),
      task: task
    })),
  }
}

export default connect(mapState)(POEmailContentWrapper)