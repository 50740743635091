import React from "react";
import styles from "../../JobStyle.module.css";
import { Field, FormSection, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { CustomCheckbox } from "../../../../common/customCheckbox";

const EnabledComponent = ({ allData, loading }) => {
  return (
    <div className="row align-ite">
      <div className="col-4">
        <div className="">
          <div className={styles.Label}>Critical Risks</div>
          <div className={styles.TaskListContainer}>
            <div className="container-fluid py-1">
              <div className="row">
                <div className="col-12">
                  {allData.critical_risk && allData.critical_risk.map(risk => (
                    <div key={risk.id}>
                      <Field
                        component={CustomCheckbox}
                        name={`item_${risk.id}`}
                        disabled={loading}
                        label={risk.risk_name}
                        className="cs-select-v2" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-4">
        <div className="">
          <div className={styles.Label}>High Risk Activities</div>
          <div className={styles.TaskListContainer}>
            <div className="container-fluid py-1">
              <div className="row">
                <div className="col-12">
                  {allData.high_risk && allData.high_risk.map(risk => (
                    <div key={risk.id}>
                      <Field
                        component={CustomCheckbox}
                        name={`item_${risk.id}`}
                        disabled={loading}
                        label={risk.risk_name}
                        className="cs-select-v2" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-4">
        <div className="">
          <div className={styles.Label}>Environmental</div>
          <div className={styles.TaskListContainer}>
            <div className="container-fluid py-1">
              <div className="row">
                <div className="col-12">
                  {allData.enviromental_risk && allData.enviromental_risk.map(risk => (
                    <div key={risk.id}>
                      <Field
                        component={CustomCheckbox}
                        name={`item_${risk.id}`}
                        disabled={loading}
                        label={risk.risk_name}
                        className="cs-select-v2" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const HighRisksSection = ({ allData, loading }) => {
  return (
    <div>
      <div className="m-1 mb-2">
        <div className="container-fluid px-0">
          <FormSection name="enabled" component={EnabledComponent} allData={allData} loading={loading} />
        </div>
      </div>
    </div>
  )
}


export default connect((state, initialProps) => ({
  allData: (formValueSelector("job_swms_form"))(state, "high_risk_section.data"),
  // selected: (formValueSelector("job_swms_form"))(state, "high_risk_section.selected"),
  // newEntry: (formValueSelector("job_swms_form"))(state, "high_risk_section.new_entry")
}))(HighRisksSection);