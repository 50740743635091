import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { customInput } from "../../../../common/custom-input";
import { CustomDatepicker } from "../../../../common/customDatepicker";
import { CustomTimePicker } from "../../../../common/customTimePicker";
import { fields } from "./fieldsConfig";
import "./TimesheetUpdateStyles.css";
import styles from "./TimeheetUpdateStyles.module.css";
import jobStyles from "../../../Job/JobStyle.module.css";

export class ModalContent extends Component {
  render() {
    const { onCancel, onUpdate } = this.props;
    return (
      <div className="timesheet-modal-font">
        <div className="allowances-modal-header">
          <fieldset className="form-group sf-form">
            <div className={jobStyles.CommonLabel}>Start Date</div>
            <Field
              name="start_date"
              placeholder={moment(new Date()).format("DD-MM-YYYY")}
              type="date"
              id="start_date"
              className="timesheet-v2-date-picker"
              dropdownClassName="cs-datepicker-dropdown"
              component={CustomDatepicker}
              disabled={true}
            />
          </fieldset>
          <fieldset className="form-group sf-form">
            <div className={jobStyles.CommonLabel}>Start Time</div>
            <Field
              name="start_time"
              component={CustomTimePicker}
              className="timesheet-v2-time-picker"
              disabled={true}
            />
          </fieldset>
          <fieldset className="form-group sf-form">
            <div className={jobStyles.CommonLabel}>Finish Date</div>
            <Field
              name="finish_date"
              placeholder={moment(new Date()).format("YYYY-MM-DD")}
              type="date"
              id="finish_date"
              component={CustomDatepicker}
              className="timesheet-v2-date-picker"
              dropdownClassName="cs-datepicker-dropdown"
              disabled={true}
            />
          </fieldset>
          <fieldset className="form-group sf-form">
            <div className={jobStyles.CommonLabel}>Finish Time</div>
            <Field
              name="finish_time"
              component={CustomTimePicker}
              className="timesheet-v2-time-picker"
              disabled={true}
            />
          </fieldset>
          {/* <fieldset className="form-group sf-form select-wibg">
            <Field
              label="Select Task"
              name="tasks"
              placeholder="Select Tasks"
              type="multiple"
              mode="tags"
              options={[]}
              component={CustomSelect}
              disabled={true}
            />
          </fieldset> */}
        </div>
        <div className="timesheet-allowance-update-modal">
          {fields.map((field) => (
            <div className="p-2">
              <div className={jobStyles.CommonLabel}>{field.title}</div>
              <Field
                name={field.fieldName}
                type="text"
                className={styles.RfqFormField}
                component={customInput}
                placeholder={field.title}
              />
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "1rem",
          }}
        >
          <div style={{ marginRight: "1rem" }}>
            <button onClick={onCancel} type="button" className={`${jobStyles.FilterResetButton}`}>
              Cancel
            </button>
          </div>
          <div style={{ marginRight: "1rem" }}>
            <button onClick={onUpdate} type="button" className={`${jobStyles.PrimaryButton}`}>
              {"Update & Close"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalContent);
