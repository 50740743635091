import validator from "validator";

export default function validate(values) {
  let errors = {};

  if (!values.activity_name || (values.activity_name && validator.isEmpty(values.activity_name, { ignore_whitespace: true }))) {
    errors.activity_name = "Required";
  }

  if (!values.activity_hazards || !values.activity_hazards.length) {
    errors.activity_hazards = { _error: "At least one hazard must be created." }
  } else {
    let hazardArrayErrors = [];
    values.activity_hazards.forEach((hazard, index) => {
      let hazardErrors = {};
      if (!hazard.hazard_name || (hazard.hazard_name && validator.isEmpty(hazard.hazard_name, { ignore_whitespace: true }))) {
        hazardErrors.hazard_name = "Required";
        hazardArrayErrors[index] = hazardErrors;
      }

      if (!hazard.consequences || !hazard.consequences.length) {
        hazardErrors.consequences = { _error: "At least one consequence is required." }
        hazardArrayErrors[index] = hazardErrors;
      } else {
        let consequencesArrayErrors = [];
        hazard.consequences.forEach((consequence, cIndex) => {
          let consequenceErrors = {};
          if (!consequence.consequences_name || (consequence.consequences_name && validator.isEmpty(consequence.consequences_name, { ignore_whitespace: true }))) {
            consequenceErrors.consequences_name = "Required";
            consequencesArrayErrors[cIndex] = consequenceErrors;
          }
          if (!consequence.before_likelihood) {
            consequenceErrors.before_likelihood = "Required";
            consequencesArrayErrors[cIndex] = consequenceErrors;
          }
          if (!consequence.before_consequence) {
            consequenceErrors.before_consequence = "Required";
            consequencesArrayErrors[cIndex] = consequenceErrors;
          }
          if (!consequence.control_measures || (consequence.control_measures && validator.isEmpty(consequence.control_measures, { ignore_whitespace: true }))) {
            consequenceErrors.control_measures = "Required";
            consequencesArrayErrors[cIndex] = consequenceErrors;
          }
          if (!consequence.after_likelihood) {
            consequenceErrors.after_likelihood = "Required";
            consequencesArrayErrors[cIndex] = consequenceErrors;
          }
          if (!consequence.after_consequence) {
            consequenceErrors.after_consequence = "Required";
            consequencesArrayErrors[cIndex] = consequenceErrors;
          }
        })

        if (consequencesArrayErrors.length) {
          hazardErrors.consequences = consequencesArrayErrors;
          hazardArrayErrors[index] = hazardErrors;
        }
      }
    });

    if (hazardArrayErrors.length) {
      errors.activity_hazards = hazardArrayErrors;
    }
  }

  return errors;
}