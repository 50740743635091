import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { notification } from 'antd';
import { Strings } from '../../dataProvider/localize';
import * as actions from '../../actions';
import { customInput } from '../common/custom-input';
import { validate } from '../../utils/validations';
import { FINISH } from '../../dataProvider/constant';
import { goBack, handleFocus } from '../../utils/common'
import { removeLoginData } from '../../utils/sessions'
import { authRedirect } from '../../utils/common'
import { countryCodes } from '../../dataProvider/countryCodes'
import { DeepTrim } from '../../utils/common';
import { IconButton } from '@mui/material';
import logo from "../../assets/images/fm-farm-logo.png";
import styles from "./Auth.module.css";
import { CustomSingleSelect } from '../common/new-core-ui/CustomSingleSelect';
import AuthSelectStyles from './AuthSelectStyles';
import { components } from "react-select";

class ForgotUserName extends React.Component {
	componentDidMount() {
		authRedirect(this.props.history);
	}
	onSubmit = async (values) => {
		let formData = { ...values, country_code: values.country_code && values.country_code.value ? values.country_code.value : null };
		this.props.forgetUser(formData)
			.then((message) => {
				this.props.cleanErrorMsg()
				if (this.props.forgetUserStatus && this.props.forgetUserType === FINISH) {
					notification.success({
						message: Strings.success_title,
						description: message ? message : Strings.generic_error, onClick: () => { },
						className: 'ant-success'
						// onOk: () => this.props.history.push('/signin')
					})
					this.props.history.push('/signin')
				}
			})
			.catch((message) => {
				notification.error({
					message: Strings.error_title,
					description: message ? message : Strings.generic_error,
					onClick: () => { },
					className: 'ant-error'
				})
			});
	}

	handleGoBack = () => {
		removeLoginData();
		goBack(this.props);
	}

	DropdownIndicator = props => {
		return (
			<components.DropdownIndicator {...props}>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height={20} width={20} fill="currentColor">
					<path d="M201.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 338.7 54.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
				</svg>
			</components.DropdownIndicator>
		);
	};


	render() {
		const { handleSubmit } = this.props;
		return (
			<div className="default-v2-container">
				<div className={styles.Background}>
					<div className="container-fluid vh-100">
						<div className="row h-100">
							<div className="col m-auto">
								<div className={`${styles.LoginCard} p-5 text-center mx-auto`}>
									<img src={logo} alt="FM Farm" style={{ maxHeight: "70px", width: "auto" }} />
									<h2 className={styles.LoginHeading}>Forgot user name</h2>
									<form onSubmit={handleSubmit(this.onSubmit)}>
										<div className="forgot-co-code">
											<fieldset className="">
												<Field
													name="country_code"
													options={countryCodes.map(country => ({
														label: country.dial_code,
														value: country.dial_code
													}))}
													component={CustomSingleSelect}
													otherProps={{
														styles: AuthSelectStyles,
														placeholder: "",
														components: {
															DropdownIndicator: this.DropdownIndicator
														}
													}}
												/>
											</fieldset>
											<fieldset className="userTxtbox fldset-box">
												<Field
													name="phone"
													type="text"
													id="phone"
													className={`${styles.InputFieldV2} ${styles.CustomPhone}`}
													placeholder={Strings.forgot_username_placeholder_enter_mobile_no}
													component={customInput} />
											</fieldset>
										</div>
										<fieldset className="userTxtbox fldset-box">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24" className="svg-ico-f">
												<path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
											</svg>
											<Field
												name="email"
												type="text"
												id="email"
												className={styles.InputFieldV2}
												placeholder={Strings.forgot_username_placeholder_enter_email}
												component={customInput} />
										</fieldset>
										{
											this.props.errorMessage
												? <div className="alert alert-danger">
													{this.props.errorMessage}
												</div>
												: null
										}
										<button type="submit" className={styles.SigninBtn}>{Strings.forgot_username_btn_send_msg}</button>
									</form>
									<div className="pre-login-left-arrow">
										<IconButton style={{ height: "auto" }} onClick={this.handleGoBack}>
											<svg xmlns="http://www.w3.org/2000/svg" width="14" fill="#000" viewBox="0 0 448 512">
												<path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
											</svg>
										</IconButton>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		errorMessage: state.auth.errorMessage,
		forgetUserStatus: state.auth.forgetUserStatus,
		forgetUserType: state.auth.forgetUserType
	}
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'ForgotUserName', validate,
		initialValues: {
			country_code: { label: "+1", value: "+1" }
		},
		onSubmitFail: (errors, dispatch, sub, props) => {
			handleFocus(errors, "#");
		}
	})
)(ForgotUserName)