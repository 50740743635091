import { Input, Icon, Collapse } from 'antd';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { getJobShiftUpdates } from '../../../../actions/jobAction';
import { Strings } from "../../../../dataProvider/localize";
import { notification, DatePicker, Modal } from 'antd';
import { downLoadDoc } from '../../Job/JobBoard/utils';
import styles from "./ShiftUpdates.module.css";
import jobStyles from "../../Job/JobStyle.module.css";
import { IconButton } from '@mui/material';

const { Panel } = Collapse;

function ShiftUpdates(props) {

  const { RangePicker } = DatePicker;

  const dispatch = useDispatch();

  const [shifts, setShifts] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [shiftId, setShiftId] = useState("");
  const [hasFilters, setHasFilters] = useState(false);

  const [modal, setModal] = useState(false);
  const [modalImage, setModalImage] = useState("")
  const [modalKey, setModalKey] = useState(4);

  const returnMomentDateRange = (start, finish) => {
    return [moment(start, "YYYY-MM-DD"), moment(finish, "YYYY-MM-DD")];
  };

  const openModal = (image) => {
    setModalKey(modalKey + 1);
    setModalImage(image)
    setModal(true);
  };

  const handleDateSelect = (momentDates) => {
    if (momentDates) {
      setDateRange(returnMomentDateRange(momentDates[0], momentDates[1]))
    } else {
      setDateRange(null);
    }
  }

  const reloadShifts = () => {
    setHasFilters(true)
    getJobDetails();
  }

  const clearFilters = () => {
    setDateRange(null);
    setHasFilters(false);
    setShiftId("");
    getJobDetails(false);
  }

  const getJobDetails = (filters = true) => {
    if (props.selectedJobDetails && props.selectedJobDetails.id !== undefined) {
      let data = {
        job_id: props.selectedJobDetails.id
      }
      if (filters && dateRange !== null) {
        data.start_date = moment(dateRange[0]).format("YYYY-MM-DD");
        data.end_date = moment(dateRange[1]).format("YYYY-MM-DD");
      }
      if (filters && !(!shiftId)) {
        data.job_schedule_shift_id = shiftId
      }
      Promise.resolve(dispatch(getJobShiftUpdates(data))).then(res => {
        if (res.status && res.status === 1) {
          let shifts = [];
          if (shifts !== res.result) {
            setShifts(res.result);
          }
        } else {
          notification.error({
            message: Strings.error_title,
            description: "There was an error while getting Job Shift Updates.",
            className: "ant-error",
          })
        }
      }).catch(() => {
        notification.error({
          message: Strings.error_title,
          description: "There was an error while getting Job Shift Updates.",
          className: "ant-error",
        })
      });
    }
  }

  const handleShiftId = (e) => {
    setShiftId(e.target.value);
  }

  useEffect(() => {
    if (props.selectedJobDetails && props.selectedJobDetails.id !== undefined) {
      getJobDetails();
    }
  }, [props.selectedJobDetails])


  return (
    <div className={styles.shift_updates}>
      <div className="container-fluid mt-3">
        <div className="row mb-2 align-items-end">
          <div className="col-4 col-sm-3 col-lg-2">
            <RangePicker
              className="job-range-picker-v2"
              placeholder={["Start Date", "End Date"]}
              dropdownClassName="cs-datepicker-dropdown"
              value={dateRange} format="DD-MM-YYYY"
              allowClear={false}
              onChange={handleDateSelect} />
          </div>
          <div className="col-4 col-sm-3 col-lg-2">
            <Input className={styles.InputFieldV2} placeholder="Shift Id" value={shiftId} onChange={handleShiftId} />
          </div>
          <div className="col-4 col-sm-3 col-lg-3">
            <button className={`${jobStyles.PrimaryButton} mx-2`} onClick={reloadShifts}>View Shift Updates</button>
            <button className={jobStyles.FilterResetButton} disabled={!hasFilters} onClick={clearFilters}>Clear</button>
          </div>
        </div>
        <div className="row">
          <div className="col-12 pt-3">
            <Collapse accordion className="cs-collapse-v2 shift-updates-collapse" collapsible="header" expandIconPosition="right">
              {shifts.map((shift, index) =>
                <Panel header={<div className="d-flex align-items-center" style={{ columnGap: "15px" }}>
                  <div><b>Shift Id:</b> {shift.job_schedule_shift_id ? shift.job_schedule_shift_id : ""}</div>
                  <div><b>Start Date:</b>  {shift.start_date ? moment(shift.start_date).format("DD/MM/YYYY") : ''}</div>
                  <div><b>Start Time:</b> {shift.start_time ? shift.start_time : ""}</div>
                  <div><b>Finish Date:</b> {shift.finish_date ? moment(shift.finish_date).format("DD/MM/YYYY") : ''}</div>
                  <div><b>Finish Time:</b> {shift.finish_time ? shift.finish_time : ""}</div>
                </div>} key={index}>
                  <div className={styles.shift_box} key={index}>
                    <div className="container-fluid my-1">
                      <div className="row">
                        <div className="col-9">
                          <div>
                            <div className={styles.shift_heading}>Shift Update:</div>
                            <div className={styles.ShiftDetailBox}>
                              {shift.shift_update_note}
                            </div>
                          </div>
                          <div>
                            <div className={styles.shift_heading}>Vehicle Update:</div>
                            <div className={styles.ShiftDetailBox}>
                              {shift.vehicle_update}
                            </div>
                          </div>
                          <div>
                            <div className={styles.shift_heading}>Equipment Update:</div>
                            <div className={styles.ShiftDetailBox}>
                              {shift.equipment_update}
                            </div>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className={jobStyles.card} style={{ maxHeight: "200px", height: "100%" }}>
                            {shift.job_shift_update_files && shift.job_shift_update_files.length > 0 ? (
                              <div className="d-flex align-items-center h-100">
                                <div className="slider-nav" style={{ flex: "0 0 30px" }}>
                                  <a style={{ left: "20px" }} href={`#carouselExampleControls_${shift.id}`} role="button" data-slide="prev" className="carousel-control-prev">
                                    <Icon type="left" />
                                  </a>
                                </div>
                                <div style={{ flex: "1 1 100%" }}>
                                  <div id={`carouselExampleControls_${shift.id}`} className="carousel slide" data-ride="carousel" style={{ maxHeight: '220px' }}>
                                    <div className="carousel-inner">
                                      {shift.job_shift_update_files.map((image, index) =>
                                        <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                                          <div className="d-block w-100">
                                            <div className="d-flex align-items-center justify-content-center" style={{ height: '150px' }}>
                                              <img className="img-fluid" alt="shift_img" src={image.shift_update_image} style={{ maxHeight: '150px' }} />
                                            </div>
                                            <div className="d-flex align-items-center justify-content-center mt-2" style={{ columnGap: "10px" }}>
                                              <IconButton style={{ height: "auto", padding: "0px" }} onClick={() => openModal(image.shift_update_image)}>
                                                <svg style={{ color: "#90BF00", height: "16px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                  <path fill="currentColor" d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                                                </svg>
                                              </IconButton>
                                              <IconButton style={{ height: "auto", padding: "0px" }} target="_blank" onClick={() => downLoadDoc(image.shift_update_image)}>
                                                <svg style={{ color: "#362148", height: "16px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                  <path fill="currentColor" d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                              </IconButton>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="slider-nav" style={{ flex: "0 0 30px" }}>
                                  <a style={{ right: "20px" }} href={`#carouselExampleControls_${shift.id}`} role="button" data-slide="next" className="carousel-control-next">
                                    <Icon type="right" />
                                  </a>
                                </div>
                              </div>) : (
                              <div className='d-flex h-100'>
                                <div className="my-auto text-center w-100">
                                  No Update Photos.
                                </div>
                              </div>
                            )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Panel>
              )}
            </Collapse>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      <Modal
        visible={modal}
        centered
        title="Shift Update Image"
        width="auto"
        key={modalKey}
        onCancel={() => setModal(false)}
        okButtonProps={{ disabled: true, style: { display: 'none' } }}
        cancelButtonProps={{ disabled: true, style: { display: 'none' } }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <img src={modalImage} alt="Shift Update Image" className="img-fluid" style={{ maxHeight: '700px', minWidth: '200px' }} />
            </div>
          </div>
        </div>
      </Modal>
    </div >
  )
}

export default ShiftUpdates;