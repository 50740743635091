import React from "react";

import {
  getIsAllDay,
  getOutsourceStatus,
  getJobState,
  getJobTasks,
  getEventModifyableStatus,
} from "./utils";

export const serializeJobs = (jobs, isSingleJob = false) => {
  const serializedJobs = jobs.map((jobItem) => {
    const {
      id,
      job_id,
      quote_id,
      scope_doc_id,
      client_id,
      job_title,
      job_start_date,
      job_end_date,
      sites,
      job_filter_status,
      invoice_generated,
      job_sheet_sign_off_status,
      acc_manager_user_name,
      quote_number,
      quote_version,
      client_name,
      job_name,
      job_number,
      client_person_name,
      client_person_email,
      client_person_phone,
      state_name,
      xero_invoice_state,
      ar_job_state,
      total_quote_amount,
      total_outsourced_budget_to_contractor,
      org_id,
      total_allocated_budget_to_acc_manager,
      account_manager_name,
      job_board_tasks,
      invoice_number
    } = jobItem;

    let siteTasks = [];
    if(isSingleJob) {
      siteTasks = getJobTasks(sites, {
        job_sheet_sign_off_status,
        acc_manager_user_name,
        quote_number,
        org_id
      });
    }

    let title = <span>{job_title}</span>;
    if(xero_invoice_state && xero_invoice_state !== null) {
      if(xero_invoice_state.toLowerCase().includes('partial')) {
        title = <span><i className="fa fa-star-half-o mr-1" aria-hidden="true"></i>{job_title}</span>
      } else {
        title = <span><i className="fa fa-star mr-1" aria-hidden="true"></i>{job_title}</span>
      }
    }

    if(ar_job_state && ar_job_state.toLowerCase() === "not found") {
      title = <span><i className="fa fa-ban mr-1" aria-hidden="true"></i>{title}</span>
    }

    let job = {
      id : id ? id : job_id,
      quoteId: quote_id,
      scopeId: scope_doc_id,
      clientId: client_id,
      clientName: client_name,
      clientContactName: client_person_name,
      accountManagerUsername: acc_manager_user_name,
      title: title,
      job_title: job_title,
      start: new Date(job_start_date),
      end: new Date(job_end_date),
      allDay: getIsAllDay(job_start_date, job_end_date),
      isJob: true,
      status: job_filter_status,
      ar_job_state: ar_job_state,
      invoiced: invoice_generated,
      signedOff: job_sheet_sign_off_status,
      quoteNumber: quote_number,
      name: job_name,
      state: state_name,
      label: job_number,
      xeroInvoiceState: xero_invoice_state,
      invoice_number: invoice_number
    };

    if(isSingleJob) {
      job.clientContactName = client_person_name;
      job.clientContactEmail = client_person_email;
      job.clientContactPhone= client_person_phone;
      job.outsource_status= getOutsourceStatus(sites);
      job.state= getJobState(sites);
      job.tasks= siteTasks;
      job.sites= sites;
      job.adminMoveable= getEventModifyableStatus(siteTasks, "admin");
      job.acmMoveable= getEventModifyableStatus(siteTasks, "acm");
      job.childTaskIds= siteTasks.map((t) => t.taskId);
      job.quoteLabel = `${quote_number}${quote_version}`;
      job.quoteTotal = total_quote_amount;
      job.totalOutsourceBudget = total_outsourced_budget_to_contractor;
      job.orgId = org_id;
      job.totalAllocatedBudget = total_allocated_budget_to_acc_manager;
      job.accountManagerName = account_manager_name;
      job.jobBoardTasks = job_board_tasks
    }
    return job;
  });

  return serializedJobs;
};

export const getJobStates = (jobs) => {
  const statesList = [];
  jobs.forEach((job) =>
    job.sites.forEach((site, index) => {
      statesList.push({
        id: index + 1,
        name: site.state ? site.state : "",
      });
    })
  );

  const uniqueStates = [
    ...new Set(
      statesList.map((stateItem) => stateItem.name.trim().toLowerCase())
    ),
  ];
  const statesData = uniqueStates.map((state, index) => ({
    name: state,
    id: index + 1,
  }));

  return statesData;
};
