import React from "react";
import styles from "../../JobStyle.module.css";
import { Menu, Dropdown } from 'antd';
import { Tooltip, IconButton } from '@mui/material';
import RuleIcon from '@mui/icons-material/Rule';

export default function JobSwmsTaskVersionRow({ loading, version, task, handleActionOption, currentUser }) {

  const optionMenu = () => (
    <Menu onClick={(e) => handleActionOption(e.key, task, version)}>
      <Menu.Item key="2" disabled={loading}>
        Copy Version
      </Menu.Item>
      <Menu.Item key="3" disabled={(version.approval_status && version.approval_status.toLowerCase() === "manager approved") || loading}>
        Delete Version
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="container-fluid px-0 py-2 border-bottom">
      <div className="row align-items-center" >
        <div className="col-4">
          <div>
            <div>SWMS Version {version.swms_revision}</div>
          </div>
        </div>
        <div className="col-8 text-right">
          <div className="d-flex align-items-center justify-content-end pr-2" style={{ gap: "14px" }}>
            <Tooltip title="Preview SWMS">
              <span>
                <IconButton className={`${styles.IconBtn} ${styles.SecondaryIcon}`} onClick={() => handleActionOption("1", task, version)} disabled={loading}>
                  <svg xmlns="http://www.w3.org/2000/svg" style={{ height: "16px", width: "16px" }} viewBox="0 0 576 512">
                    <path fill="currentColor" d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                  </svg>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Edit Version">
              <span>
                <IconButton className={`${styles.IconBtn} ${styles.SuccessIcon}`} onClick={() => handleActionOption("6", task, version)} disabled={(version.approval_status && version.approval_status.toLowerCase() === "manager approved") || loading}>
                  <svg style={{ height: "16px", width: "16px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor" d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
                  </svg>
                </IconButton>
              </span>
            </Tooltip>
            {version.approval_status === null || (version.approval_status && version.approval_status.toString().toLowerCase() === "manager rejected") ? (
              <Tooltip title="Send for Manager Approval">
                <span>
                  <IconButton className={`${styles.IconBtn} ${styles.WarningIcon}`} style={{ height: "auto", color: "#362148" }} onClick={() => handleActionOption("5", task, version)} disabled={loading}>
                    <svg style={{ height: "16px", width: "16px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                      <path fill="currentColor" d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384v38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM288 368a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm211.3-43.3c-6.2-6.2-16.4-6.2-22.6 0L416 385.4l-28.7-28.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l40 40c6.2 6.2 16.4 6.2 22.6 0l72-72c6.2-6.2 6.2-16.4 0-22.6z" />
                    </svg>
                  </IconButton>
                </span>
              </Tooltip>) : version.approval_status && version.approval_status.toLowerCase() === "pending manager approval" ?
              version.approval_request_sent_to && version.approval_request_sent_to.toString() === currentUser.toString() ? (
                <Tooltip title="Confirm Manager Approval">
                  <span>
                    <IconButton className={`${styles.IconBtn} ${styles.WarningIcon}`} disabled={loading} onClick={() => handleActionOption("4", task, version)}>
                      <RuleIcon sx={{ fontSize: "18px" }} />
                    </IconButton>
                  </span>
                </Tooltip>) : (
                <Tooltip title="Pending Manager Approval">
                  <span>
                    <IconButton className={`${styles.IconBtn} ${styles.WarningIcon}`} disabled={loading}>
                      <svg xmlns="http://www.w3.org/2000/svg" style={{ height: "16px", width: "16px" }} viewBox="0 0 384 512">
                        <path fill="currentColor" d="M32 0C14.3 0 0 14.3 0 32S14.3 64 32 64V75c0 42.4 16.9 83.1 46.9 113.1L146.7 256 78.9 323.9C48.9 353.9 32 394.6 32 437v11c-17.7 0-32 14.3-32 32s14.3 32 32 32H64 320h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V437c0-42.4-16.9-83.1-46.9-113.1L237.3 256l67.9-67.9c30-30 46.9-70.7 46.9-113.1V64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320 64 32zM288 437v11H96V437c0-25.5 10.1-49.9 28.1-67.9L192 301.3l67.9 67.9c18 18 28.1 42.4 28.1 67.9z" />
                      </svg>
                    </IconButton>
                  </span>
                </Tooltip>)
              : version.approval_status && version.approval_status.toLowerCase() === "manager approved" ? (
                <Tooltip title="SWMS Approved">
                  <span>
                    <IconButton className={`${styles.IconBtn} ${styles.SuccessIcon}`} disabled={loading}>
                      <svg xmlns="http://www.w3.org/2000/svg" style={{ height: "16px", width: "16px" }} viewBox="0 0 448 512">
                        <path fill="currentColor" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                      </svg>
                    </IconButton>
                  </span>
                </Tooltip>
              ) : ""}
            {version.approval_status && version.approval_status.toLowerCase() === "manager rejected" ? (
              <Tooltip title="SWMS Rejected">
                <span>
                  <IconButton className={`${styles.IconBtn} ${styles.DangerIcon}`} disabled={loading}>
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ height: "16px", width: "16px" }} viewBox="0 0 384 512">
                      <path fill="currentColor" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                    </svg>
                  </IconButton>
                </span>
              </Tooltip>
            ) : ""}
            <Dropdown overlayClassName="cs-v3-dropdown" disabled={loading} overlay={optionMenu}>
              <IconButton className={styles.IconBtn} disabled={loading}>
                <svg xmlns="http://www.w3.org/2000/svg" style={{ height: "16px", width: "auto" }} viewBox="0 0 128 512">
                  <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
                </svg>
              </IconButton>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>

  )
}