import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { components } from "react-select";
import { Field } from "redux-form";

import { CustomAsyncSelect } from "../../common/new-core-ui/CustomAsyncSelect";
import { getOrgUsersBySearch } from "../../../actions/v2/OrganizationActions";

import v2Styles from "../../v2/v2Styles.module.css";
import v2FormSelectStyle from "../../v2/v2FormSelectStyle";

export default function ManagersFields({ orgId, member }) {

  const dispatch = useDispatch();

  const defaultList = [
    { label: "Self Appointed", value: 0 }
  ]

  const [directManager, setDirectManager] = useState([...defaultList]);
  const [complianceManager, setComplianceManager] = useState([...defaultList]);

  const getManagers = (inputValue, isDirect = true) => {
    return inputValue.trim().length > 2 ?
      Promise.resolve(dispatch(getOrgUsersBySearch({ org_user_id: orgId, staff_name: `"${inputValue.trim()}"`, page: 1 })).then(res => {
        let managerList = res && res.length > 0 ? res.map(manager => ({ label: manager.name && manager.last_name ? `${manager.name} ${manager.last_name}` : manager.name, value: manager.user_name })) : []
        if (isDirect === true) {
          setDirectManager([...managerList, ...defaultList]);
        } else {
          setComplianceManager([...managerList, ...defaultList]);
        }
        return [...managerList, ...defaultList];
      })).catch(err => {
        if (err) {
          console.log(err);
        }
      }) : Promise.resolve([]);
  }

  return (
    <>
      <div className="col-3 pb-3">
        <div className={v2Styles.Label}>Direct Manager</div>
        <div>
          <Field
            name={`${member}.direct_manager`}
            component={CustomAsyncSelect}
            loadOptions={(v) => getManagers(v, true)}
            styles={v2FormSelectStyle}
            otherProps={{
              components: { dropdownIndicator: <components.DownChevron /> },
              defaultOptions: directManager,
              noOptionsMessage: ({ inputValue }) => inputValue.trim().length > 2 ? "No Options" : "Type to Search",
              cacheOptions: true,
              placeholder: "Search or Appoint Self",
              isValidNewOption: (inputValue) => inputValue && inputValue.trim().length > 2
            }}
          />
        </div>
      </div>
      <div className="col-3 pb-3">
        <div className={v2Styles.Label}>Compliance Manager</div>
        <div>
          <Field
            name={`${member}.compliance_manager`}
            component={CustomAsyncSelect}
            loadOptions={(v) => getManagers(v, false)}
            placeholder=""
            styles={v2FormSelectStyle}
            otherProps={{
              components: { dropdownIndicator: <components.DownChevron /> },
              defaultOptions: complianceManager,
              noOptionsMessage: ({ inputValue }) => inputValue.trim().length > 2 ? "No Options" : "Type to Search",
              cacheOptions: true,
              placeholder: "Search or Appoint Self",
              isValidNewOption: (inputValue) => inputValue && inputValue.trim().length > 2
            }}
          />
        </div>
      </div>
    </>
  )
}