import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import JobBoardHeader from "./Sections/JobBoardHeader";
import JobBoardMiddlePanel from "./Sections/JobBoardMiddlePanel";
import JobBoardSites from "./Sections/JobBoardSites";
import * as jobActions from "../../../../../actions/jobAction";

export class InformationPanelContainer extends Component {
  render() {
    const { jobDetails, actions } = this.props;

    return (
      <div>
        <JobBoardHeader jobDetails={jobDetails} actions={actions} />
        <JobBoardMiddlePanel jobDetails={jobDetails} />
        <JobBoardSites jobDetails={jobDetails} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedJobDetails: state.JobCalendar.selectedJobDetails,
  jobDetails: state.jobManagement.taskJobDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(jobActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InformationPanelContainer);
