import moment from "moment";

export default function validate(values) {

  let days = ["mon", "tue", "wed", "thur", "fri", "sat", "sun"];
  let errors = {};

  days.forEach(dayName => {
    if (values["selected_" + dayName] && values["selected_" + dayName] === true) {
      if (values["start_time_" + dayName] && values["end_time_" + dayName]) {
        let startTime = moment(values["start_time_" + dayName]).seconds(0).milliseconds(0);
        let endTime = moment(values["end_time_" + dayName]).seconds(0).milliseconds(0)
        if (startTime.isBefore(endTime)) {
          let duration = parseFloat(moment.duration(endTime.diff(startTime)).asHours());
          if (duration < 0.5) {
            errors["end_time_" + dayName] = "Minimum Half hour is required."
          }
        } else {
          errors["end_time_" + dayName] = "Invalid Time."
        }
      } else {
        if (!values["start_time_" + dayName]) {
          errors["start_time_" + dayName] = "Required";
        }
        if (!values["end_time_" + dayName]) {
          errors["end_time_" + dayName] = "Required";
        }
      }
    }
  })

  return errors;
}