import {
  SET_QUOTES,
  SET_QUOTE_PRIORITY_OPTIONS,
  SET_QUOTE_STATIC_FILTERS,
  SET_SITE_HISTORY, SET_QUOTES_SWMS,
  SET_QUOTES_TOOLBOX_TALK_SWMS,
  SET_QUOTE_DETAILS,
  SET_QUOTE_CLIENT_ADDITIONAL_DETAILS,
  SET_QUOTE_SITE_ADDITIONAL_DETAILS,
  RESET_QUOTE_DETAILS,
  SET_QUOTE_MANAGEMENT,
  SET_QUOTE_HISTORY,
  SET_QUOTE_ACTIONS,
  SET_QUOTE_ADMINS,
  UPDATE_TASKS_DATA,
  SET_QUOTE_TASK_TYPES
} from "../../dataProvider/constant";

const INITIAL_STATE = {
  quotesList: {},
  totalQuotes: 0,
  priorityFilterOptions: [],
  priorityOptions: [],
  quoteStatusFilterOptions: [],
  siteHistory: [],
  siteHistoryCount: 0,
  clientAdditionalDetails: [],
  siteAdditionalDetails: [],
  swmsCategories: [],
  swmsActivities: [],
  chemicals: [],
  ppes: [],
  toolTypes: [],
  highRisks: [],
  toolBoxTalks: [],
  quoteDetails: {},
  tasks: [],
  quoteManagements: [],
  quoteManagementsCount: 0,
  quoteActions: [],
  history: [],
  historyCount: 0,
  quoteAdmins: [],
  taskTypes: []
}

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_QUOTES: {
      return {
        ...state,
        quotesList: payload.quoteList,
        totalQuotes: payload.quote_count
      }
    }
    case SET_QUOTE_PRIORITY_OPTIONS: {
      return {
        ...state,
        priorityOptions: payload
      }
    }
    case SET_QUOTE_STATIC_FILTERS: {
      return {
        ...state,
        priorityFilterOptions: payload.priorities,
        quoteStatusFilterOptions: payload.quote_statuses
      }
    }
    case SET_SITE_HISTORY: {
      return {
        ...state,
        siteHistory: payload.tasksHistoryList,
        siteHistoryCount: payload.task_count
      }
    }
    case SET_QUOTES_SWMS: {
      return {
        ...state,
        swmsCategories: payload.swms_cat,
        swmsActivities: payload.swms_activities,
        chemicals: payload.sds,
        ppes: payload.ppes,
        toolTypes: payload.tools,
        highRisks: payload.high_risk_works,
      }
    }
    case SET_QUOTES_TOOLBOX_TALK_SWMS: {
      return {
        ...state,
        toolBoxTalks: payload
      }
    }
    case SET_QUOTE_DETAILS: {
      return {
        ...state,
        quoteDetails: payload.quote_details ? payload.quote_details : {},
        tasks: payload.task_details ? payload.task_details : []
      }
    }
    case SET_QUOTE_CLIENT_ADDITIONAL_DETAILS: {
      return {
        ...state,
        clientAdditionalDetails: payload
      }
    }
    case SET_QUOTE_SITE_ADDITIONAL_DETAILS: {
      return {
        ...state,
        siteAdditionalDetails: payload
      }
    }
    case RESET_QUOTE_DETAILS: {
      return {
        ...state,
        quoteDetails: {},
        tasks: [],
        history: [],
        historyCount: 0
      }
    }
    case SET_QUOTE_HISTORY: {
      return {
        ...state,
        history: payload.rows,
        historyCount: payload.count
      }
    }
    case SET_QUOTE_MANAGEMENT: {
      return {
        ...state,
        quoteManagements: payload.rows ? payload.rows : [],
        quoteManagementsCount: payload.count ? payload.count : 0
      }
    }
    case SET_QUOTE_ACTIONS: {
      return {
        ...state,
        quoteActions: payload
      }
    }
    case SET_QUOTE_ADMINS: {
      return {
        ...state,
        quoteAdmins: payload
      }
    }
    case UPDATE_TASKS_DATA: {
      let tasks = [...state.tasks];
      tasks = tasks.map(task => {
        let newTask = payload.find(x => x.task_id.toString() === task.task_id.toString());
        return newTask ? { ...task, ...newTask } : task;
      })
      return {
        ...state,
        tasks: tasks
      }
    }
    case SET_QUOTE_TASK_TYPES: {
      let taskTypes = [...payload.map(taskType => ({ label: taskType.task_type_name, value: taskType.id }))];
      return {
        ...state,
        taskTypes: taskTypes
      }
    }
    default: {
      return state;
    }
  }
}