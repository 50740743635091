import React from 'react';
import { notification } from 'antd';
import { withRouter } from "react-router-dom";
import { reduxForm, Field, isDirty } from 'redux-form';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { customInput } from '../../../common/custom-input';
import { Strings } from '../../../../dataProvider/localize';
import { ADMIN_DETAILS, VALIDATE_STATUS, ACCESS_CONTROL } from '../../../../dataProvider/constant';
import { handleFocus, DeepTrim } from '../../../../utils/common';
import * as actions from '../../../../actions/profileManagementActions';
import { getStorage, setStorage, countries } from '../../../../utils/common';
import { residentialAddressValidation } from '../../../../utils/Validations/profileValidation';

import colourStyles from "../FilterSelectProfileStyles";
import { components } from "react-select";
import { CustomSingleSelect } from "../../../common/new-core-ui/CustomSingleSelect";
import "../ProfileStyle.css";
import styles from "../ProfileStyles.module.css";

class ResidentialAddressForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            date: '',
            phnOtpErr: false,
            emailOtpErr: false,
            hasEditPermission: false
        }

        this.org_user_id = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id : null;
        this.org_user_name = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)).user_name : null;
    }

    static getDerivedStateFromProps(props, state) {
        var OrgSACountry = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)).country : null;
        if (!props.formValues && props.formValues.ec_country || props.formValues && props.formValues.ec_country === '') {
            props.change(`ec_country`, OrgSACountry);
        }
    }

    componentDidMount() {
        this.props.action.getStates()
        if (this.hasPermission("sf_personal_details_controller_update_personal_details")) {
            this.setState({ hasEditPermission: true });
        }
    }

    onSubmit = async (values) => {
        let accessControl = JSON.parse(getStorage(ACCESS_CONTROL))["userManagement"].permissions;
        if (accessControl.findIndex(acess => acess.control_name === 'sf_personal_details_controller_update_personal_details') === -1 && this.props.location.pathname !== "/dashboard/profile") {
            notification.error({
                message: Strings.error_title,
                description: "You don't have permissions to edit Residential Details.",
                className: 'ant-error'
            })
        } else {
            let formData = { ...values };
            formData = {
                ...formData,
                'profile_progress': this.props.profileComplete,
                request_from: 'web',
                state_id: formData.state && formData.state.value ? formData.state.value : null,
                state: formData.state && formData.state.label ? formData.state.label : null,
                country: formData.country && formData.country.value ? formData.country.value : null,
            }

            let staffUserName = false
            if (this.props.userDetails) {
                staffUserName = this.props.userDetails.user_name
            }

            this.props.action.updateOrgUserResidentialAddress(formData, staffUserName)
                .then((flag) => {
                    notification.success({
                        message: Strings.success_title,
                        description: flag,
                        onClick: () => { },
                        className: 'ant-success'
                    });
                    this.props.action.getOrgUserProfileDetails(staffUserName ? this.props.userDetails.user_id : this.org_user_id, staffUserName ? this.props.userDetails.user_name : this.org_user_name)
                        .then(() => {
                            if (staffUserName) {
                                return
                            } else {
                                setStorage(ADMIN_DETAILS, JSON.stringify({
                                    ...JSON.parse(getStorage(ADMIN_DETAILS)),
                                    name: this.props && this.props.profile && this.props.profile[0] && this.props.profile[0].name ?
                                        this.props.profile[0].name : JSON.parse(getStorage(ADMIN_DETAILS)).name,
                                    last_name: this.props && this.props.profile && this.props.profile[0] && this.props.profile[0].last_name ?
                                        this.props.profile[0].last_name : JSON.parse(getStorage(ADMIN_DETAILS)).last_name
                                }))
                            }
                        });
                }).catch((error) => {
                    if (error.status === VALIDATE_STATUS) {
                        notification.warning({
                            message: Strings.validate_title,
                            description: error && error.data && typeof error.data.message == 'string'
                                ? error.data.message : Strings.generic_validate,
                            onClick: () => { },
                            className: 'ant-warning'
                        });
                    } else {
                        notification.error({
                            message: Strings.error_title,
                            description: error && error.data && error.data.message && typeof error.data.message == 'string'
                                ? error.data.message : Strings.generic_error,
                            onClick: () => { },
                            className: 'ant-error'
                        });
                    }
                });
        }
    }

    hasPermission = (permission) => {
        let allowed = false;
        let userPermissions = JSON.parse(getStorage(ACCESS_CONTROL))["userManagement"]
        let accessControl = userPermissions && userPermissions.permissions ? userPermissions.permissions : [];
        let permission_id = accessControl.findIndex(acess => acess.control_name.toLowerCase() === permission.toLowerCase());
        if (permission_id !== -1 || this.props.location.pathname === "/dashboard/profile") {
            allowed = true;
        }
        return allowed;
    }

    render() {

        const { handleSubmit, statesList } = this.props;

        return (
            <form onSubmit={handleSubmit(this.onSubmit)}>
                <hr className={styles.DetailsDivider}></hr>
                <div className="container-fluid py-2 pt-3">
                    <div className="row align-items-center">
                        <h2 className={`${styles.ProfileLabel} font-weight-bold`}>Residential Address</h2>
                    </div>
                    <div className="row">
                        <div className="col-3 border-right">
                            <div className={styles.ProfileLabelV2}>Street Address</div>
                            <div>
                                <Field
                                    name="street_address"
                                    placeholder={Strings.street_address_acc_pd}
                                    type="text"
                                    id="street_address"
                                    component={customInput}
                                    className={styles.ProfileFormField}
                                    disabled={!this.state.hasEditPermission} />
                            </div>
                        </div>
                        <div className="col-3 border-right">
                            <div className={styles.ProfileLabelV2}>City</div>
                            <div>
                                <Field
                                    name="city"
                                    placeholder={Strings.city_acc_pd}
                                    type="text"
                                    id="city"
                                    component={customInput}
                                    className={styles.ProfileFormField}
                                    disabled={!this.state.hasEditPermission} />
                            </div>
                        </div>
                        <div className="col-3 border-right">
                            <div className={styles.ProfileLabelV2}>State</div>
                            <div>
                                <Field
                                    name="state"
                                    placeholder={Strings.state_acc_pd}
                                    options={statesList.map(state => ({
                                        label: state.name, value: state.id
                                    }))}
                                    component={CustomSingleSelect}
                                    styles={colourStyles}
                                    otherProps={{
                                        components: { dropdownIndicator: <components.DownChevron /> },
                                        isDisabled: !this.state.hasEditPermission
                                    }} />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className={styles.ProfileLabelV2}>Zip Code</div>
                            <div>
                                <Field
                                    name="zip_code"
                                    type="text"
                                    id="zip_code"
                                    component={customInput}
                                    className={styles.ProfileFormField}
                                    disabled={!this.state.hasEditPermission} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-3 border-right">
                            <div className={styles.ProfileLabelV2}>Country</div>
                            <div>
                                <Field
                                    name="country"
                                    type="text"
                                    id="country"
                                    options={countries.map(country => ({ label: country, value: country.toString() }))}
                                    component={CustomSingleSelect}
                                    styles={colourStyles}
                                    otherProps={{
                                        components: { dropdownIndicator: <components.DownChevron /> },
                                        isDisabled: !this.state.hasEditPermission
                                    }} />
                            </div>
                        </div>
                    </div>
                    <div className="mt-2">
                        <button type="submit" className={styles.BtnSaveTab} disabled={!this.props.isDirty || !this.hasPermission('sf_personal_details_controller_update_personal_details')}>
                            Save
                        </button>
                    </div>
                </div>
            </form >
        );
    }
}

const mapStateToProps = (state) => {
    let value = {}
    if (state.profileManagement && state.profileManagement.profile) {
        for (let item of state.profileManagement.profile) {
            let selectedState = item.state_id && item.state_id !== "null" ? state.profileManagement.statesList.find(x => x.id.toString() === item.state_id.toString()) : null
            value = {
                ...value, id: item.id, street_address: item.street_address, city: item.city, state: item.state, zip_code: item.zip_code,
                country: item.country && item.country !== "null" ? { label: item.country, value: item.country } : null,
                state: selectedState ? { label: selectedState.name, value: selectedState.id } : null,
                suburb: item.suburb
            }
        }
    }

    for (let key in value) {
        if (value[key] === "null") {
            value[key] = null;
        }
    }

    return {
        formValues: state.form && state.form.ResidentialAddressForm &&
            state.form.ResidentialAddressForm.values ? state.form.ResidentialAddressForm.values : {},
        isDirty: isDirty('ResidentialAddressForm')(state),
        profileComplete: state.profileManagement && state.profileManagement.profileComplete,
        profile: state.profileManagement && state.profileManagement.profile,
        initialValues: value ? value : {},
        statesList: state.profileManagement.statesList,
    }
}

const mapDispatchToprops = dispatch => {
    return {
        action: bindActionCreators(actions, dispatch),
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToprops),
    reduxForm({
        form: 'ResidentialAddressForm', validate: residentialAddressValidation, enableReinitialize: true,
        onSubmitFail: (errors, dispatch, sub, props) => {
            handleFocus(errors, "#");
        }
    })
)(ResidentialAddressForm)