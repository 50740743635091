import React from "react";
import { Field } from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import { Upload, notification } from "antd";
import { reduxForm } from "redux-form";
import * as action from "../../../../actions/SAJobMgmtAction";
import { customInput } from "../../../common/custom-input";
import { Strings } from "../../../../dataProvider/localize";
import { singleValidate } from "../../../../utils/Validations/jobReportValidation";
import { getStorage } from "../../../../utils/common";
import {
  VALIDATE_STATUS,
  ADMIN_DETAILS,
} from "../../../../dataProvider/constant";
import styles from "./SingleReportStyles.module.css";
import jobStyles from "../../../v2/Job/JobStyle.module.css";
import { customTextarea } from "../../../common/customTextarea";
import { IconButton } from "@mui/material";

class UpdateSingleReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: null,
      before: null,
      after: null
    }
  }
  handleChange = (file, keyName) => {
    this.setState({ [keyName]: file })
    this.props.change(`${keyName}`, file);
    this.props.change(`${keyName}_photo`, null);
  };

  handlePreUpload = () => {
    return false;
  };

  onSubmit = async () => {
    const { formValues } = this.props;
    var fileIndex = 0;
    var finalForm = new FormData();
    delete formValues.location_photo;
    if (this.state.location) {
      formValues.temp_location_photo = [];
      finalForm.append("files", this.state.location);
      formValues.temp_location_photo.push(fileIndex);
      fileIndex++;
      formValues.location_photo = formValues.temp_location_photo;
    }
    delete formValues.before_photo;
    if (this.state.before) {
      formValues.temp_before_photo = [];
      finalForm.append("files", this.state.before);
      formValues.temp_before_photo.push(fileIndex);
      fileIndex++;
      formValues.before_photo = formValues.temp_before_photo;
    }
    delete formValues.after_photo;
    if (this.state.after) {
      formValues.temp_after_photo = [];
      finalForm.append("files", this.state.after);
      formValues.temp_after_photo.push(fileIndex);
      fileIndex++;
      formValues.after_photo = formValues.temp_after_photo;
    }
    finalForm.append("id", formValues.id);
    var job_id = formValues.job_id;
    finalForm.append("job_cleaning_reports", JSON.stringify([formValues]));

    this.props
      .updateJobReport(finalForm, job_id)
      .then((flag) => {
        this.props.reset();
        this.props.removeInlineUser(this.props.initialValues);
        notification.success({
          message: Strings.success_title,
          description: flag,
          onClick: () => { },
          className: "ant-success",
        });
      })
      .catch((error) => {
        if (error.status == VALIDATE_STATUS) {
          notification.warning({
            message: Strings.validate_title,
            description:
              error && error.data && typeof error.data.message == "string"
                ? error.data.message
                : Strings.generic_validate,
            onClick: () => { },
            className: "ant-warning",
          });
        } else {
          notification.error({
            message: Strings.error_title,
            description:
              error &&
                error.data &&
                error.data.message &&
                typeof error.data.message == "string"
                ? error.data.message
                : Strings.generic_error,
            onClick: () => { },
            className: "ant-error",
          });
        }
      });
  };

  render() {
    const { handleSubmit, initialValues, formValues } = this.props;
    return (
      <form
        onSubmit={handleSubmit(this.onSubmit)}
        key={initialValues.id}
      >
        <div>
          <div className={jobStyles.ReportCard}>
            <div className="mb-1">
              <div className={jobStyles.CommonLabel}>Area Name</div>
              <Field name={`area`} type="text" className={`${jobStyles.InputFieldV2}`} component={customInput} />
            </div>
            <div className="d-flex align-items-stretch" style={{ columnGap: "10px" }}>
              <div className="d-flex align-items-center" style={{ columnGap: "5px", flex: "1 1 auto" }}>
                <div className="w-100">
                  <div style={{ height: "100px" }}>
                    <div className={jobStyles.ReportImageContainer}>
                      {this.state.location ? (
                        <img className={jobStyles.ReportImage} src={URL.createObjectURL(this.state.location)} />
                      ) : formValues.location_photo ? (
                        <img className={jobStyles.ReportImage} src={this.props.filePath + JSON.parse(formValues.location_photo)[0]} />
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <Upload
                      fileList={this.state.location ? [this.state.location] : []}
                      beforeUpload={this.handlePreUpload}
                      showUploadList={false}
                      multiple={false}
                      className="job-upload-v2"
                      accept=".jpeg,.jpg,.png"
                      onChange={({ file }) =>
                        this.handleChange(file, "location")
                      }
                    >
                      <div className={`${jobStyles.PrimaryText} justify-content-center d-flex align-items-center mt-1`} style={{ columnGap: "5px" }}>
                        <i style={{ fontSize: "17px" }} className="material-icons">add_a_photo</i>
                        <div>Upload Area Photo</div>
                      </div>
                    </Upload>
                  </div>
                </div>
                <div className="w-100">
                  <div style={{ height: "100px" }}>
                    <div className={jobStyles.ReportImageContainer}>
                      {this.state.before ? (
                        <img className={jobStyles.ReportImage} src={URL.createObjectURL(this.state.before)} />
                      ) : formValues.before_photo ? (
                        <img className={jobStyles.ReportImage} src={this.props.filePath + JSON.parse(formValues.before_photo)[0]} />
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <Upload
                      fileList={this.state.before ? [this.state.before] : []}
                      beforeUpload={this.handlePreUpload}
                      showUploadList={false}
                      multiple={false}
                      className="job-upload-v2"
                      accept=".jpeg,.jpg,.png"
                      onChange={({ file }) =>
                        this.handleChange(file, "before")
                      }
                    >
                      <div className={`${jobStyles.PrimaryText} justify-content-center d-flex align-items-center mt-1`} style={{ columnGap: "5px" }}>
                        <i style={{ fontSize: "17px" }} className="material-icons">add_a_photo</i>
                        <div>Upload Before Photo</div>
                      </div>
                    </Upload>
                  </div>
                </div>
                <div className="w-100">
                  <div style={{ height: "100px" }}>
                    <div className={jobStyles.ReportImageContainer}>
                      {this.state.after ? (
                        <img className={jobStyles.ReportImage} src={URL.createObjectURL(this.state.after)} />
                      ) : formValues.after_photo ? (
                        <img className={jobStyles.ReportImage} src={this.props.filePath + JSON.parse(formValues.after_photo)[0]} />
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <Upload
                      fileList={this.state.after ? [this.state.after] : []}
                      beforeUpload={this.handlePreUpload}
                      showUploadList={false}
                      multiple={false}
                      className="job-upload-v2"
                      accept=".jpeg,.jpg,.png"
                      onChange={({ file }) =>
                        this.handleChange(file, "after")
                      }
                    >
                      <div className={`${jobStyles.PrimaryText} justify-content-center d-flex align-items-center mt-1`} style={{ columnGap: "5px" }}>
                        <i style={{ fontSize: "17px" }} className="material-icons">add_a_photo</i>
                        <div>Upload After Photo</div>
                      </div>
                    </Upload>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-start" style={{ columnGap: "10px", flex: "0 0 300px" }}>
                <div style={{ flex: "1 1 auto" }}>
                  <div className={jobStyles.CommonLabel}>Notes</div>
                  <Field name={`note`} rows={3} component={customTextarea} className={jobStyles.ReportTextArea} />
                </div>
                <div style={{ flex: "0 0 30px", alignSelf: "center" }}>
                  <div className="mb-2">
                    <IconButton onClick={() => handleSubmit(this.onSubmit())} style={{ background: "#c0b4ff", color: "#fff", height: "auto" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{ height: "14px", width: "14px", }}>
                        <path fill="currentColor" d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                      </svg>
                    </IconButton>
                  </div>
                  <div>
                    <IconButton style={{ background: "red", color: "#fff", height: "auto" }} onClick={() => this.props.removeInlineUser(this.props.initialValues)}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style={{ height: "14px", width: "14px" }}>
                        <path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                      </svg>
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, { serviceObject, form }) => {
  return {
    roles: state.roleManagement.roles,
    formValues:
      state.form[form] && state.form[form].values
        ? state.form[form].values
        : {
          photo_taken_by: JSON.parse(getStorage(ADMIN_DETAILS))
            ? JSON.parse(getStorage(ADMIN_DETAILS)).name +
            " " +
            (JSON.parse(getStorage(ADMIN_DETAILS)).last_name
              ? JSON.parse(getStorage(ADMIN_DETAILS)).last_name
              : "")
            : "",
        },
    filePath: state.sAJobMgmt.filePath,
  };
};

export default compose(
  connect(mapStateToProps, action),
  reduxForm({

    validate: singleValidate, enableReinitialize: true, pure: false
  })
)(UpdateSingleReport);
