import axios from 'axios';
import {
  JWT_ACCESS_TOKEN,
  JWT_ID_TOKEN,
  USER_NAME,
  ADMIN_DETAILS,
  JWT_REFRESH_TOKEN,
  RENEW_ACCESS_URL,
  ADMIN_ORG_ID,
  RENEW_ACCESS_V2_URL
} from '../dataProvider/constant';
import {
  BASE_API_URL,
  BASE_API_V2_URL,
  BASE_SCOPE_API_URL,
  BASE_SCOPE_API_V2,
  BASE_API_V3_URL,
  BASE_SCOPE_API_V3
} from '../dataProvider/env.config';
import { getStorage, setStorage } from '../utils/common';
import { Store } from '../index';
import { logoutUser } from '../actions/index';

export const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  },
})

axiosInstance.defaults.params = {}

const renewAccess = async () => {
  return axiosInstance.post(RENEW_ACCESS_URL)
    .then(res => {
      if (res.data.status) {
        //Restored JWT_REFRESH_TOKEN,JWT_ID_TOKEN and JWT_ACCESS_TOKEN
        setStorage(JWT_REFRESH_TOKEN, res.data.data.auth_data.refresh_token);
        setStorage(JWT_ID_TOKEN, res.data.data.auth_data.id_token);
        setStorage(JWT_ACCESS_TOKEN, res.data.data.auth_data.access_token);
        return Promise.resolve(true);
      }
    }).catch(error => {
      return Promise.resolve(false);
    })
}

axiosInstance.interceptors.request.use((request) => {
  return requestInterceptor(request)
}, (error) => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  // If request is UNAUTHORIZED then call token refresh web service and again call old web service with updated token
  const { status } = error.response;
  if (status === 401) {
    let res = await renewAccess();
    if (res) {
      return axiosInstance();
    }
  } else if (status === 403) {
    logoutUser(Store.dispatch);
    return Promise.reject(error);
  } else return Promise.reject(error);
});

export const axiosV2Instance = axios.create({
  baseURL: BASE_API_V2_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  },
})

axiosV2Instance.defaults.params = {};

axiosV2Instance.interceptors.request.use((request) => {
  return requestInterceptor(request)
}, (error) => {
  return Promise.reject(error);
});

axiosV2Instance.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  // If request is UNAUTHORIZED then call token refresh web service and again call old web service with updated token
  const { status } = error.response;
  if (status === 401) {
    let res = await renewAccess();
    if (res) {
      return axiosInstance();
    }
  } else if (status === 403) {
    logoutUser(Store.dispatch);
    return Promise.reject(error);
  } else return Promise.reject(error);
});

// Axios Instance Admin V3
export const axiosV3Instance = axios.create({
  baseURL: BASE_API_V3_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  },
})

axiosV3Instance.defaults.params = {};

axiosV3Instance.interceptors.request.use((request) => {
  return requestInterceptor(request)
}, (error) => {
  return Promise.reject(error);
});

axiosV3Instance.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  // If request is UNAUTHORIZED then call token refresh web service and again call old web service with updated token
  const { status } = error.response;
  if (status === 401) {
    let res = await renewAccess();
    if (res) {
      return axiosInstance();
    }
  } else if (status === 403) {
    logoutUser(Store.dispatch);
    return Promise.reject(error);
  } else return Promise.reject(error);
});


/**Scope doc axios instance */
export const scopeAxiosInstance = axios.create({
  baseURL: BASE_SCOPE_API_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  },
})

scopeAxiosInstance.defaults.params = {}

scopeAxiosInstance.interceptors.request.use((request) => {
  return requestInterceptor(request)
}, (error) => {
  return Promise.reject(error);
});

scopeAxiosInstance.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  // If request is UNAUTHORIZED then call token refresh web service and again call old web service with updated token
  const { status } = error.response;
  if (status === 401) {
    let res = await renewAccess();
    if (res) {
      return axiosInstance();
    }
  } else if (status === 403) {
    logoutUser(Store.dispatch);
    return Promise.reject(error);
  } else return Promise.reject(error);
});

/**request interceptor for all instances */

const requestInterceptor = (request) => {
  if (getStorage(JWT_ACCESS_TOKEN) && getStorage(JWT_ID_TOKEN) &&
    getStorage(USER_NAME) && getStorage(ADMIN_DETAILS) && getStorage(USER_NAME) && getStorage(JWT_REFRESH_TOKEN)) {
    let orgDetails = JSON.parse(getStorage(ADMIN_DETAILS)).organisation;
    let adminDetails = JSON.parse(getStorage(ADMIN_DETAILS));
    request.headers.refreshtoken = getStorage(JWT_REFRESH_TOKEN);
    request.headers.accessToken = getStorage(JWT_ACCESS_TOKEN);
    request.headers.accessId = getStorage(JWT_ID_TOKEN);
    request.headers.user_name = getStorage(USER_NAME);
    request.headers.slug = orgDetails && orgDetails.slug ? orgDetails.slug : null;
    request.headers.org_id = orgDetails && orgDetails.id ? orgDetails.id : null;
    request.headers.user_timezone = adminDetails && adminDetails.user_timezone ? adminDetails.user_timezone : null;
    request.headers.user_utc_timezone = adminDetails && adminDetails.user_utc_timezone ? adminDetails.user_utc_timezone : null
  } else {
    const orgId = getStorage(ADMIN_ORG_ID);
    if (orgId && orgId !== null) {
      request.headers.org_id = getStorage(ADMIN_ORG_ID);
    }

  }


  if (getStorage(ADMIN_DETAILS)) {
    /*  if (request.method === 'get') {
       request.params["org_id"] = JSON.stringify(JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id);
       request.params["user_name"] = getStorage(USER_NAME);
     }
 
     else if (request.method === "put" || request.method === "post") {
 
       request.data.organisation_id = JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id;
       request.data.user_name = getStorage(USER_NAME);
       //  request.data = JSON.stringify(data);
 
 
     } */
  }
  return request;
}

/**Scope doc V2 axios instance */
export const scopeV2AxiosInstance = axios.create({
  baseURL: BASE_SCOPE_API_V2,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  },
})

scopeV2AxiosInstance.defaults.params = {}

scopeV2AxiosInstance.interceptors.request.use((request) => {
  return requestInterceptor(request)
}, (error) => {
  return Promise.reject(error);
});

scopeV2AxiosInstance.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  // If request is UNAUTHORIZED then call token refresh web service and again call old web service with updated token
  const { status } = error.response;
  if (status === 401) {
    let res = await renewAccess();
    if (res) {
      return axiosInstance();
    }
  } else if (status === 403) {
    logoutUser(Store.dispatch);
    return Promise.reject(error);
  } else return Promise.reject(error);
});



/**Scope doc V2 axios instance */
export const scopeV3AxiosInstance = axios.create({
  baseURL: BASE_SCOPE_API_V3,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  },
})

scopeV3AxiosInstance.defaults.params = {}

scopeV3AxiosInstance.interceptors.request.use((request) => {
  return requestInterceptor(request)
}, (error) => {
  return Promise.reject(error);
});

scopeV3AxiosInstance.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  // If request is UNAUTHORIZED then call token refresh web service and again call old web service with updated token
  const { status } = error.response;
  if (status === 401) {
    let res = await renewAccess();
    if (res) {
      return axiosInstance();
    }
  } else if (status === 403) {
    logoutUser(Store.dispatch);
    return Promise.reject(error);
  } else return Promise.reject(error);
});

// one instance for all scope requests and one instance for all base requests.
export const scopeApiInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_SCOPE_API_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  },
})

scopeApiInstance.defaults.params = {}

scopeApiInstance.interceptors.request.use((request) => {
  return requestInterceptor(request)
}, (error) => {
  return Promise.reject(error);
});

scopeApiInstance.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  if (error.response && error.response.status) {
    const { status } = error.response;
    if (status === 401) {
      let res = await renewAccess();
      if (res) {
        return axiosInstance();
      }
    } else {
      if (status === 403) {
        logoutUser(Store.dispatch);
      }
      return Promise.reject(error);
    }
  } else {
    return Promise.reject(error.code && error.code === "ERR_CANCELED" ? null : error);
  }
});

export const baseApiInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  },
})

baseApiInstance.defaults.params = {};

baseApiInstance.interceptors.request.use((request) => {
  return requestInterceptor(request)
}, (error) => {
  return Promise.reject(error);
});

baseApiInstance.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  if (error.response && error.response.status) {
    const { status } = error.response;
    let { headers } = error.config;
    if (status === 401) {
      if (!headers.disableAccessRenew) {
        let res = await renewAccess();
        if (res) {
          return axiosInstance();
        }
      } else {
        logoutUser(Store.dispatch);
        return Promise.reject(error);
      }
    } else {
      if (status === 403) {
        logoutUser(Store.dispatch);
      }
      return Promise.reject(error);
    }
  } else {
    return Promise.reject(error.code && error.code === "ERR_CANCELED" ? null : error);
  }
});

export default axiosInstance;


export const renewUserAccessV2 = async () => {
  return baseApiInstance.post(RENEW_ACCESS_V2_URL)
    .then(res => {
      if (res && res.data && res.data.data && res.data.data.auth_data) {
        setStorage(JWT_REFRESH_TOKEN, res.data.data.auth_data.refresh_token);
        setStorage(JWT_ID_TOKEN, res.data.data.auth_data.id_token);
        setStorage(JWT_ACCESS_TOKEN, res.data.data.auth_data.access_token);
        return Promise.resolve(true);
      } else {
        return Promise.reject();
      }
    }).catch(error => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : "Failed to Connect"
      );
    })
}
