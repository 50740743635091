import React, { useEffect, useState, useCallback } from "react";
import { Modal, notification } from "antd";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CloseIcon from '@mui/icons-material/Close';

import DraggableActivityRow from "./DraggableActivityRow";
import styles from "../../JobStyle.module.css";
import AddJsaActivity from "./AddJsaActivity";

const JSASection = ({ allActivities, change, loading }) => {

  const [activities, setActivities] = useState([]);
  const [addModal, setAddModal] = useState(false);

  useEffect(() => {
    setActivities(allActivities.map((x, i) => ({ ...x, activity_order: i + 1 })));
  }, [allActivities])

  const afterDrop = useCallback(() => {
    change("jsa_section.activities", [...activities])
  })

  const moveActivities = useCallback((dragIndex, hoverIndex) => {
    setActivities(prevActs => {
      let allActs = [...prevActs];
      let [el] = allActs.splice(dragIndex, 1);
      allActs.splice(hoverIndex, 0, el);
      return allActs.map(({ index, ...x }, i) => ({ ...x, activity_order: i + 1 }));
    });
  });

  const onNewAdd = (item) => {
    change("jsa_section.activities", [...allActivities, {...item, activity_order: allActivities.length + 1}]);
    notification.success({
      className: "ant-success",
      message: "New Activity Added Successfully.",
      description: "Please save the form to retain the activity."
    })
    setAddModal(false);
  }

  const onDeleteActivity = (index) => {
    let acts = [...activities];
    acts.splice(index, 1);
    change("jsa_section.activities", [...acts]);
  }

  return (
    <div className="m-1 mb-2">
      <div className="contianer-fluid px-0">
        <div className="row">
          <div className="col-12">
            <DndProvider backend={HTML5Backend}>
              <table className="table-sm cs-table cs-table-v2 cs-table-job-swms" style={{ tableLayout: "fixed", width: "100%" }}>
                <thead>
                  <tr>
                    <th width="40"></th>
                    <th width="50" className="text-center">Step</th>
                    <th>Activity</th>
                    <th>Description</th>
                    <th width="50"></th>
                  </tr>
                </thead>
                <tbody>
                  {activities.map((activity, index) => (
                    <DraggableActivityRow
                      activity={activity}
                      genId={"jsa_gen_id"}
                      change={change}
                      key={`${activity.activity_id}_${activity.hazard_id}_${activity.consequence_id}`}
                      index={index}
                      loading={loading}
                      onDelete={() => onDeleteActivity(index)}
                      afterDrop={afterDrop}
                      moveRow={moveActivities} />
                  ))
                  }
                  {activities.length === 0 ? (
                    <tr>
                      <td colSpan={8} className="text-center" style={{ color: "red" }}>No Records Available.</td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </DndProvider>
            <div className="pt-2 text-right border-top">
              <button type="button" onClick={() => setAddModal(true)} disabled={loading} className={styles.PrimaryButton}>Add New</button>
            </div>
          </div>
        </div>
      </div>
      <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
        <Modal
          className="cs-modal-v2"
          centered
          footer={null}
          visible={addModal}
          width="80vw"
          height="80vh"
          onCancel={() => setAddModal(false)}
          maskClosable={false}
          bodyStyle={{ paddingRight: "0px", paddingLeft: "0px", maxHeight: "80vh", overflow: "hidden auto" }}
          destroyOnClose={true}
          closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
        >
          <AddJsaActivity onClose={() => setAddModal(false)} onNewAdd={onNewAdd} />
        </Modal>
      </div>
    </div>

  )
}

export default connect((state) => ({
  allActivities: (formValueSelector("job_swms_form"))(state, "jsa_section.activities"),
}))(JSASection);