import React from "react";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import styles from "../../JobStyle.module.css";
import { customInput } from "../../../../common/custom-input";
import { IconButton } from "@mui/material";

const SwmsConsultationsSection = ({ loading, consultations = [], change, sccMethodName = "", requiredParticipants = "", procedures = "" }) => {

  const onRemove = (index) => {
    const newArr = [...consultations].filter((_, i) => index !== i);
    change("swms_consultation_section.swms_consultation_items", [...newArr])
  }

  const onAdd = () => {
    if (sccMethodName.trim().length > 0 || procedures.trim().length > 0 || requiredParticipants.trim().length > 0) {
      change("swms_consultation_section.swms_consultation_items", [...consultations, {
        scc_method_name: sccMethodName.trim(),
        required_participants: requiredParticipants.trim(),
        procedures: procedures.trim(),
        description: null
      }])
      change("swms_consultation_section.scc_method_name", "");
      change("swms_consultation_section.required_participants", "");
      change("swms_consultation_section.procedures", "");
    }
  }

  return (
    <div className="m-1 mb-2">
      <div className="contianer-fluid px-0">
        <div className="row">
          <div className="col-12">
            <table className="table-sm cs-table cs-table-v2 cs-table-job-swms" style={{ tableLayout: "fixed", width: "100%" }}>
              <thead>
                <tr>
                  <th width="32%">Consultation Method</th>
                  <th width="32%">Participants</th>
                  <th width="32%">Procedure</th>
                  <th width="4%"></th>
                </tr>
              </thead>
              <tbody>
                {consultations && consultations.length > 0 ? consultations.map((consult, index) => (
                  <tr key={index}>
                    <td>{consult.scc_method_name}</td>
                    <td>{consult.required_participants}</td>
                    <td>{consult.procedures}</td>
                    <td>
                      <IconButton type="button" style={{ height: "auto", padding: "0px" }} disabled={loading} onClick={() => onRemove(index)}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{ height: "16px", color: "red" }}>
                          <path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                        </svg>
                      </IconButton>
                    </td>
                  </tr>
                )) : (
                  <tr>
                    <td colSpan={4} className="text-center" style={{ color: "red" }}>No Consultation & Communication Available.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row my-2 py-2" onKeyDown={e => { if (e.key === "Enter") { e.preventDefault(); e.stopPropagation(); } }}>
          <div className="col-3">
            <div className={styles.Label}>Consultation Method</div>
            <Field name="scc_method_name" className={styles.TextField} component={customInput} disabled={loading} />
          </div>
          <div className="col-3">
            <div className={styles.Label}>Participants</div>
            <Field name="required_participants" className={styles.TextField} component={customInput} disabled={loading} />
          </div>
          <div className="col-3">
            <div className={styles.Label}>Procedure</div>
            <Field name="procedures" className={styles.TextField} component={customInput} disabled={loading} />
          </div>
          <div className="col-3 mt-3">
            <button className={styles.PrimaryButton} onClick={() => onAdd()} type="button" disabled={loading}>Save</button>
          </div>
        </div>
      </div>
    </div>
  )
}


export default connect((state) => ({
  consultations: (formValueSelector("job_swms_form"))(state, "swms_consultation_section.swms_consultation_items"),
  procedures: (formValueSelector("job_swms_form"))(state, "swms_consultation_section.procedures"),
  sccMethodName: (formValueSelector("job_swms_form"))(state, "swms_consultation_section.scc_method_name"),
  requiredParticipants: (formValueSelector("job_swms_form"))(state, "swms_consultation_section.required_participants"),
}))(SwmsConsultationsSection);