import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { notification } from "antd";

import { Strings, } from "../../../../../dataProvider/localize";
import { getStorage } from "../../../../../utils/common";
import { ADMIN_DETAILS } from "../../../../../dataProvider/constant";
import { getSwmsAdmins, sendSwmsForManagerApproval } from "../../../../../actions/v2/JobSwmsTaskActions";
import colourStyles from "../FilterSelectStyles.js";

import styles from "../../JobStyle.module.css";

export default function SwmsManagerApprovalModal({ onClose, task, swms }) {

  const dispatch = useDispatch();

  const [loadingAdmins, setLoadingAdmins] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    fetchAdmins();
  }, [])

  const fetchAdmins = () => {
    const adminDetails = JSON.parse(getStorage(ADMIN_DETAILS));
    if (adminDetails && adminDetails.organisation && adminDetails.organisation.id) {
      setLoadingAdmins(true);
      dispatch(getSwmsAdmins(adminDetails.organisation.id)).then((res) => {
        setAdmins(res);
        setLoadingAdmins(false);
      }).catch(err => {
        if (err) {
          setLoadingAdmins(false);
          notification.error({
            message: Strings.error_title,
            description: err ? err : Strings.generic_error,
            className: 'ant-error'
          })
        }
      });
    }
  }

  const onSend = () => {
    setSubmitting(true);
    let formData = {
      admin_user_name: selectedAdmin.value,
      swms_form_id: swms.swms_form_id,
      job_id: task.job_id,
      task_id: task.task_id,
      swms_revision: swms.swms_revision
    }
    dispatch(sendSwmsForManagerApproval(formData)).then(message => {
      setSubmitting(false);
      notification.success({
        message: Strings.success_title,
        description: message,
        className: "ant-success"
      });
      onClose();
    }).catch(err => {
      if (err) {
        setSubmitting(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: "ant-error"
        })
      }
    })
  }

  return (
    <div className="job-swms-modal-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={styles.ModalHeading}>
              Send {task.task_number} - SWMS Version {swms.swms_revision} for Manager Approval
            </div>
            <div className="py-2">
              <hr />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className={styles.Label}>Manager</div>
            <div>
              <Select
                placeholder="Select Manager"
                isDisabled={submitting}
                onChange={(v) => setSelectedAdmin(v)}
                value={selectedAdmin}
                options={admins.map(x => ({
                  label: x.first_name,
                  value: x.user_name
                }))}
                hideSelectedOptions={false}
                aria-multiline={false}
                isLoading={loadingAdmins}
                styles={colourStyles}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-right pt-3">
            <div className="d-flex align-items-center justify-content-end" style={{ gap: "5px" }}>
              {submitting ? (
                <CircularProgress size={20} />) : ""}
              <button className={`${styles.OutlinedButton}`} onClick={() => onClose()}>
                Cancel
              </button>
              <button type="button" onClick={onSend} className={styles.PrimaryButton} disabled={!selectedAdmin || submitting || loadingAdmins}>
                Send for Approval
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}