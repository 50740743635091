import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { notification } from "antd";

import styles from "../../JobStyle.module.css";
import { deleteSwms } from "../../../../../actions/v2/JobSwmsTaskActions";
import { Strings } from "../../../../../dataProvider/localize";

export default function DeleteSwmsModal({ onClose, task, swms }) {

  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  const onDelete = () => {
    setSubmitting(true);
    dispatch(deleteSwms(swms.swms_form_id)).then(message => {
      setSubmitting(false);
      notification.success({
        message: Strings.success_title,
        description: message,
        className: "ant-success"
      })
      onClose();
    }).catch(err => {
      if (err) {
        setSubmitting(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: "ant-error"
        })
      }
    })
  }

  return (
    <div className="job-swms-modal-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={styles.ModalHeading}>
              Are you Sure you want to delete {task.task_number} - SWMS Version {swms.swms_revision}?
            </div>
            <div className="py-2">
              <hr />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div style={{ color: "red" }}>This action is not reversible.</div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-right">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "end", columnGap: "10px" }}>
              {submitting ? (
                <CircularProgress size={20} />) : ""}
              <button type="button" className={`${styles.OutlinedButton}`} onClick={onClose} style={{ padding: "0px 5px" }}>
                Cancel
              </button>
              <button
                type="button"
                onClick={() => onDelete()}
                disabled={submitting}
                className={styles.PrimaryButton}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}