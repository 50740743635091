import moment from "moment";
import { getFormattedDateTime, getUsernames } from "../utils";
import { notification } from "antd";
import { Strings } from "../../../../../dataProvider/localize";

export const validate = (values, currentAction) => {
  const prefix =
    currentAction === "CREATE" ? "" : `${currentAction.toLowerCase()}_`;
  const usernames = getUsernames(values, currentAction);
  const errors = {};

  let showNotification = false;

  if (values.tasks && !values.tasks.length) {
    errors.tasks = "Please select at least one task";
  }

  const shiftStartDate = getFormattedDateTime(
    undefined,
    values.start_date,
    "YYYY-MM-DD"
  );
  const shiftStartTime = getFormattedDateTime(
    undefined,
    values.start_time,
    "HH:mm:ss",
    true
  );
  const shiftFinishDate = getFormattedDateTime(
    undefined,
    values.finish_date,
    "YYYY-MM-DD"
  );
  const shiftFinishTime = getFormattedDateTime(
    undefined,
    values.finish_time,
    "HH:mm:ss",
    true
  );

  usernames.forEach((user) => {
    const staffStartDate = getFormattedDateTime(
      undefined,
      values[`${prefix}${user}_start_date`],
      "YYYY-MM-DD"
    );
    const staffStartTime = getFormattedDateTime(
      undefined,
      values[`${prefix}${user}_start_time`],
      "HH:mm:ss",
      true
    );
    const staffFinishDate = getFormattedDateTime(
      undefined,
      values[`${prefix}${user}_finish_date`],
      "YYYY-MM-DD"
    );
    const staffFinishTime = getFormattedDateTime(
      undefined,
      values[`${prefix}${user}_finish_time`],
      "HH:mm:ss",
      true
    );

    if (moment(staffStartDate).isBefore(shiftStartDate)) {
      showNotification = true;
      errors[`${prefix}${user}_start_date`] = "Invalid date";
    }

    if (!values[`${prefix}${user}_start_point`]) {
      errors[`${prefix}${user}_start_point`] = "Required."
    }

    if (
      shiftStartDate === staffStartDate &&
      moment(`${staffStartDate} ${staffStartTime}`).isBefore(
        `${shiftStartDate} ${shiftStartTime}`
      )
    ) {
      showNotification = true;
      errors[`${prefix}${user}_start_time`] = "Invalid time";
    }

    if (moment(shiftFinishDate).isBefore(staffFinishDate)) {
      showNotification = true;
      errors[`${prefix}${user}_finish_date`] = "Invalid date";
    }

    if (
      shiftStartDate === staffStartDate &&
      moment(`${shiftFinishDate} ${shiftFinishTime}`).isBefore(
        `${staffFinishDate} ${staffFinishTime}`
      )
    ) {
      showNotification = true;
      errors[`${prefix}${user}_finish_time`] = "Invalid time";
    }

    if (
      errors[`${prefix}${user}_start_date`] ||
      errors[`${prefix}${user}_start_time`] ||
      errors[`${prefix}${user}_finish_date`] ||
      errors[`${prefix}${user}_finish_time`]
    ) {
      if (currentAction !== "COPY" && showNotification) {
        notification.warning({
          message: Strings.warning_title,
          description:
            "The staff member's shift date/time falls outside the allocated shift's date/time range. Please adjust your entries.",
          onClick: () => { },
          className: "ant-warning",
        });
        showNotification = false;
      }
    }
  });

  return currentAction === "COPY" ? {} : errors;
};
