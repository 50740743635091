import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Upload, notification } from "antd";
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw } from "draft-js";
import emailEditor from "../../../common/EmailEditor";
import { saveJobReport } from '../../../../actions/SAJobMgmtAction';
import { customInput } from "../../../common/custom-input";
import { Strings } from "../../../../dataProvider/localize";
import { VALIDATE_STATUS } from '../../../../dataProvider/constant';
import jobStyles from "../../Job/JobStyle.module.css";
import { IconButton } from '@mui/material';

const validate = (values) => {
  const errors = {}
  if (!values.document_name) {
    errors.document_name = 'Required'
  }
  if (!values.comments) {
    errors.comments = 'Required'
  }
  return errors
}

const AddNewReportDocument = (props) => {

  const dispatch = useDispatch();

  const [uploadDocument, setUploadDocument] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onSubmit = (formValues) => {
    if (uploadDocument && uploadDocument.length === 0) {
      notification.error({
        message: Strings.error_title,
        description: 'Please select a document to continue.',
        className: "ant-error",
      })
    } else {
      let values = formValues;
      let formData = new FormData();

      values.document_files = [];

      if (uploadDocument && uploadDocument.length === 1) {
        formData.append("document_files", uploadDocument[0].originFileObj)
        values.document_files.push(uploadDocument[0])
      }

      formData.append("job_cleaning_reports_documents", JSON.stringify([values]));
      formData.append("job_id", props.jobId);
      formData.append("job_cleaning_reports", JSON.stringify([]));

      Promise.resolve(dispatch(saveJobReport(formData, props.jobId))).then(res => {
        notification.success({
          message: Strings.success_title,
          description: res,
          className: "ant-success",
        });
        props.onClose();
      }).catch(error => {
        if (error.status === VALIDATE_STATUS) {
          notification.warning({
            message: Strings.validate_title,
            description:
              error && error.data && typeof error.data.message == "string"
                ? error.data.message
                : Strings.generic_validate,
            className: "ant-warning",
          });
        } else {
          notification.error({
            message: Strings.error_title,
            description:
              error &&
                error.data &&
                error.data.message &&
                typeof error.data.message == "string"
                ? error.data.message
                : Strings.generic_error,
            className: "ant-error",
          });
        }
      })
    }
  }

  const handleDocumentUpload = (files) => {
    if (files.length === 1) {
      if (files[0] && files[0].size && files[0].size < 4194304) {
        setUploadDocument([...files])
      } else {
        notification.error({
          message: Strings.error_title,
          description: 'Please select a file less than 4 MB.',
          className: "ant-error",
        })
      }
    } else {
      setUploadDocument([...files])
    }
  }

  const handleEditorChange = (value) => {
    props.change("comments", draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setEditorState(value)
  }

  const uploadButton = (
    <div className={`${jobStyles.PrimaryText} justify-content-center d-flex align-items-center mt-1`} style={{ columnGap: "5px" }}>
      <i style={{ fontSize: "17px" }} className="material-icons">add_a_photo</i>
      <div>Upload Document</div>
    </div>
  );

  const hanldePreUpload = () => {
    return false;
  }

  return (
    <form onSubmit={props.handleSubmit(onSubmit)}>
      <table className="table table-sm cs-table cs-table-v2 w-100">
        <tbody>
          <tr>
            <td style={{ width: "30px" }}></td>
            <td className="td">
              <Field
                className={`${jobStyles.InputFieldV2} w-100 mt-1`}
                name="document_name"
                type="text"
                placeholder="Document name"
                component={customInput}
                autoComplete="off"
              />
            </td>
            <td>
              <div className="location-afbf-pic py-2">
                <Upload
                  listType="pictured"
                  fileList={uploadDocument && uploadDocument.length >= 1 ? uploadDocument : []}
                  accept=".pdf,.doc,.docx"
                  onChange={({ fileList }) => handleDocumentUpload(fileList)}
                  beforeUpload={hanldePreUpload}
                >
                  {uploadDocument && uploadDocument.length >= 1 ? null : uploadButton}
                </Upload>
              </div>
            </td>

            <td>
              <fieldset className="sf-form no-label">
                <div>
                  <Field
                    name="comments"
                    type="text"
                    component={emailEditor}
                    editorState={editorState}
                    onEditorStateChange={handleEditorChange}
                  />
                </div>
              </fieldset>
            </td>
            <td width="120px">
              <div className="d-flex align-items-center" style={{ columnGap: "10px" }}>
                <IconButton type="submit" style={{ color: "#c0b4ff" }} className={jobStyles.MuiIconButton}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{ height: "14px", width: "14px", }}>
                    <path fill="currentColor" d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                  </svg>
                </IconButton>
                <IconButton type="button" onClick={() => props.onClose()} style={{ color: "red", height: "auto" }} className={jobStyles.MuiIconButton}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style={{ height: "14px", width: "14px" }}>
                    <path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                  </svg>
                </IconButton>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  )
}

export default reduxForm({
  validate
})(AddNewReportDocument)
