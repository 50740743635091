import validator from 'validator';
import { validationString } from '../../dataProvider/localize'

/** User Personal Details Validations */
export const validate = (values) => {
    const errors = {}

    /** Personal Details Validation*/
    if (!values.to) {
        errors.to = validationString.to_email_empty
    } else if(Array.isArray(values.to)) {
        let flag = true;
        values.to.forEach(email => {
            if(email.value && !validator.isEmail(email.value, { allow_display_name: true })) { flag = false }
        })
        if(!flag) { errors.to = validationString.to_email_invalid }
    }

    if (!values.from) {
        errors.from = validationString.from_email_empty
    } else if (!validator.isEmail(values.from, { allow_display_name: true })) {
        errors.from = validationString.from_email_invalid;
    }

    if (values.cc) {
        if(Array.isArray(values.cc)) {
            let flag = true;
            values.cc.forEach(email => {
                if(email.value && !validator.isEmail(email.value, { allow_display_name: true })) { flag = false }
            })
            if(!flag) { errors.cc = validationString.cc_email_invalid }
        }
    }

    if (values.bcc) {
        if(Array.isArray(values.bcc)) {
            let flag = true;
            values.bcc.forEach(email => {
                if(email.value && !validator.isEmail(email.value, { allow_display_name: true })) { flag = false }
            })
            if(!flag) { errors.bcc = validationString.bcc_email_invalid }
        }
    }

    if (!values.subject) {
        errors.subject = validationString.subject_empty;
    }

    return errors
}

/** JobDocs Email Validations */
export const JobDocsEmailvalidate = (values) => {
    const errors = {}

    /** Personal Details Validation*/

    if (!values.from_profile) {
        errors.from_profile = validationString.from_profile_empty
    }

    if (!values.to_field) {
        errors.to_field = validationString.to_email_empty
    }
    else if(Array.isArray(values.to_field)) {
        let flag = true;
        values.to_field.forEach(email => {
            if(email.value && !validator.isEmail(email.value)) { flag = false }
        })
        if(!flag) { errors.to_field = validationString.to_email_invalid }
    }  

    if (!values.from_field) {
        errors.from_field = validationString.from_email_empty
    } else if (!validator.isEmail(values.from_field, { allow_display_name: true })) {
        errors.from_field = validationString.from_email_invalid
    }

    if (values.cc_field) {
        if(Array.isArray(values.cc_field)) {
            let flag = true;
            values.cc_field.forEach(email => {
                if(email.value && !validator.isEmail(email.value)) { flag = false }
            })
            if(!flag) { errors.cc_field = validationString.cc_email_invalid }
        }  
    }

    if (values.bcc_field) {
        if(Array.isArray(values.bcc_field)) {
            let flag = true;
            values.bcc_field.forEach(email => {
                if(email.value && !validator.isEmail(email.value, { allow_display_name: true })) { flag = false }
            })
            if(!flag) { errors.bcc_field = validationString.bcc_email_invalid }
        } 
    }

    if (!values.subject) {
        errors.subject = validationString.subject_empty
    }

    if (!values.validity) {
        errors.validity = validationString.validity_empty
    }
    return errors
}