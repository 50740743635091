import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import { components } from "react-select";
import { CircularProgress } from "@mui/material";
import { notification } from "antd";
import moment from "moment";
import validator from "validator";

import { customInput } from "../../../../common/custom-input";
import { CustomDatepicker } from "../../../../common/customDatepicker";
import { CustomSingleSelect } from "../../../../common/new-core-ui/CustomSingleSelect";
import SingleUploadField from "../../../../common/new-core-ui/SingleUploadField";
import { Strings } from "../../../../../dataProvider/localize";
import colourStyles from "../../SelectV2SingleStyle";
import PreviewDocument from "./PreviewDocument";

import styles from "../../JobStyle.module.css";

const validate = (values) => {
  let errors = {};
  if (!values.sds_name || (values.sds_name && validator.isEmpty(values.sds_name, { ignore_whitespace: true }))) {
    errors.sds_name = "Required";
  }
  if (values.effective_date && values.expiry_date && moment(values.expiry_date).isBefore(values.effective_date)) {
    errors.expiry_date = "Invalid Expiry Date"
  }
  return errors;
}

const AddSdsForm = ({ onClose, handleSubmit, onNewAdd }) => {

  const [loading, setLoading] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);

  const onSubmit = (values) => {
    if (values.sds_doc && !values.sds_doc.isUploaded) {
      notification.error({
        message: Strings.error_title,
        description: "One or more files are still uploading or they failed to upload.",
        className: "ant-error"
      });
    } else {
      setLoading(true);
      let stamp = new Date().getTime();
      let formData = {
        disposal_instructions: null,
        effective_date: values.effective_date ? moment(values.effective_date).format("YYYY-MM-DD") : null,
        emergency_procedures: null,
        expiry_date: values.expiry_date ? moment(values.expiry_date).format("YYYY-MM-DD") : null,
        first_aid_measures: null,
        handling_and_storage: null,
        hazard_classification: null,
        id: stamp,
        is_hazardous: values.is_hazardous ? values.is_hazardous.value : null,
        linked_documents: [],
        manufacturer: values.manufacturer_supplier,
        method_item: values.sds_name,
        method_item_id: stamp,
        prevention_measures: null,
        sds_documents: values.sds_doc && values.sds_doc.file_name ? { file_name: values.sds_doc.file_name, visible_name: values.sds_doc.visible_name } : null,
        isNew: true
      }
      onNewAdd(formData);
    }
  }

  const toggleFilePreview = (file = null) => {
    setPreviewFile(file);
  }


  return (
    <div className="swms-masters-modal-container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className={styles.ModalHeading}>Add Saftey Data Sheet</div>
              <div className="py-3">
                <hr />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 pb-3">
              <div className={styles.Label}>Safety Data Sheet Name</div>
              <Field
                name="sds_name"
                autoComplete="off"
                autoFocus
                disabled={loading}
                className={styles.TextField}
                component={customInput} />
            </div>
            <div className="col-6 pb-3">
              <div className={styles.Label}>Manufacturer / Supplier</div>
              <Field
                name="manufacturer_supplier"
                autoComplete="off"
                disabled={loading}
                className={styles.TextField}
                component={customInput} />
            </div>
            <div className="col-6 pb-3">
              <div className={styles.Label}>Effective Date</div>
              <Field
                component={CustomDatepicker}
                name="effective_date"
                disabled={loading}
                className="job-v2-date-picker"
                dropdownClassName="cs-datepicker-dropdown"
                pickerFormat="DD-MM-YYYY"
              />
            </div>
            <div className="col-6 pb-3">
              <div className={styles.Label}>Expiry Date</div>
              <Field
                component={CustomDatepicker}
                name="expiry_date"
                className="job-v2-date-picker"
                disabled={loading}
                dropdownClassName="cs-datepicker-dropdown"
                pickerFormat="DD-MM-YYYY"
              />
            </div>
            <div className="col-6 pb-3">
              <div className={styles.Label}>Hazard Classification</div>
              <Field
                component={CustomSingleSelect}
                name="is_hazardous"
                placeholder="Hazard Classification"
                options={[
                  { label: "Hazardous", value: 1 },
                  { label: "Non-Hazardous", value: 0 }
                ]}
                styles={colourStyles}
                otherProps={{
                  components: { dropdownIndicator: <components.DownChevron /> },
                  isDisabled: loading
                }}
              />
            </div>
            <div className="col-8">
              <div className={styles.Label}>Upload Document</div>
              <Field
                previewFile={toggleFilePreview}
                name="sds_doc"
                flexDirection="column"
                disabled={loading}
                component={SingleUploadField}
                s3UploadPath="job-swms/sds-section/sds_documents"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-right pt-3">
              <div style={{ display: "flex", alignItems: "center", justifyContent: "end", columnGap: "10px" }}>
                {loading ? (
                  <CircularProgress size={20} />) : ""}
                <button type="button" className={`${styles.OutlinedButton}`} onClick={onClose} style={{ padding: "0px 5px" }}>
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleSubmit(onSubmit)}
                  disabled={loading}
                  className={styles.PrimaryButton}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      {previewFile &&
        <PreviewDocument file={previewFile} onCancel={toggleFilePreview} />
      }
    </div>
  )
}

export default reduxForm({
  enableReinitialize: true,
  destroyOnUnmount: true,
  form: "add_sds_form",
  validate: validate
})(AddSdsForm);