import { SET_CALENDAR_ACCOUNT_MANAGERS, SET_CALENDAR_TASKS } from "../../dataProvider/constant";

const INITIAL_STATE = {
  accountManagers: [],
  tasks: [
    {
      id: "Task 1",
      name: "Redesign website",
      start: "2016-12-28",
      end: "2016-12-31",
      progress: 10,
      dependencies: "",
    },
    {
      id: "Task 2",
      name: "Redesign website",
      start: "2016-12-28",
      end: "2016-12-31",
      progress: 20,
      dependencies: "Task 1",
    },
    {
      id: "Task 3",
      name: "Redesign website",
      start: "2016-12-28",
      end: "2016-12-31",
      progress: 0,
      dependencies: "Task 2, Task 1",
    },
  ]
}

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_CALENDAR_ACCOUNT_MANAGERS: {
      return {
        ...state,
        accountManagers: payload
      }
    }
    case SET_CALENDAR_TASKS: {
      return {
        ...state,
        tasks: payload
      }
    }
    default: {
      return state;
    }
  }
}