import { GET_SWMS_METHOD_ITEM, GET_SWMS_METHOD_ITEM_NAME_TYPES } from "../../dataProvider/constant";
const INITIAL_STATE = {
    methodItems: [],
    methodItemsFilters: {}
}

export default (state = INITIAL_STATE, action) => {
    const { payload } = action;
    switch (action.type) {
        case GET_SWMS_METHOD_ITEM: {
            return {
                ...state,
                methodItems: payload,
            }
        }
        case GET_SWMS_METHOD_ITEM_NAME_TYPES: {
            return {
                ...state,
                methodItemsFilters: payload,
            }
        }
        default: {
            return state;
        }
    }
}