import moment from "moment";
import {
  JOBS,
  TASKS,
  FILTER_JOBS,
  GET_JOB_STATES,
  INIT_JOB_CALENDAR,
  SWITCH_CALENDAR_VIEW,
  GET_ACCOUNT_MANAGERS,
  GET_JOB_CALENDAR_JOBS,
  GET_ALLOCATION_DETAILS,
  UPDATE_CURRENT_VISIBLE_DATE
} from "../../dataProvider/constant";

const INITIAL_STATE = {
  jobs: [
    {
      start: new Date(1980, 0, 1, 9, 30, 0, 0),
      end: new Date(2021, 0, 1, 18, 30, 0, 0),
      title: "",
    },
  ],
  tasks: [],
  currentView: JOBS,
  allJobs: [],
  allTasks: [],
  accountManagers: [],
  states: [],
  allocationDetails: {},
  selectedJobDetails: {},
  currentVisibleDate: moment(new Date()).format('YYYY-MM-DD'),
  currentSelectedState: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INIT_JOB_CALENDAR: {
      return {
        ...state,
        jobs: [],
        tasks: [],
        allJobs: [],
        allTasks: [],
      };
    }

    case GET_JOB_CALENDAR_JOBS: {
      return {
        ...state,
        jobs: action.payload,
        allJobs: action.payload,
      };
    }

    case SWITCH_CALENDAR_VIEW: {
      return {
        ...state,
        currentView: action.payload.view,
        ...(action.payload.view === TASKS
          ? {
              tasks: action.payload.tasks,
              allTasks: action.payload.tasks,
              selectedJobDetails: action.payload.selectedJob,
            }
          : {
              selectedJobDetails: {},
            }),
      };
    }

    case FILTER_JOBS: {
      const { jobs, tasks } = action.payload;
      return {
        ...state,
        ...(jobs ? { jobs: jobs } : {}),
        ...(tasks ? { tasks: tasks } : {}),
      };
    }

    case GET_ACCOUNT_MANAGERS:
      return {
        ...state,
        accountManagers: action.payload,
      };

    case GET_JOB_STATES:
      return {
        ...state,
        states: action.payload,
      };

    case GET_ALLOCATION_DETAILS:
      return {
        ...state,
        allocationDetails: action.payload,
      };

    case UPDATE_CURRENT_VISIBLE_DATE:
      return {
        ...state,
        currentVisibleDate: action.payload
      }

    default:
      return { ...state };
  }
};
