import React, { useRef, useEffect, useState } from "react";
import styles from "../../JobStyle.module.css";
import { Icon, notification } from "antd";
import { Field, reduxForm } from "redux-form";
import { CustomSingleSelect } from "../../../../common/new-core-ui/CustomSingleSelect";
import { components } from "react-select";
import { colorStylesMulti } from '../../SelectV2SingleStyle';
import { customInput } from "../../../../common/custom-input";
import { CircularProgress } from "@mui/material";
import SelectV2SingleStyle from "../../SelectV2SingleStyle";
import { getContractorsBySearch, getExpenses, getPurchases, uploadInvoice } from "../../../../../actions/v2/JobAllocatonActions";
import { useDispatch } from "react-redux";
import { Strings } from "../../../../../dataProvider/localize";
import SingleUploadField from "../../../../common/new-core-ui/SingleUploadField";
import { CustomAsyncCreatableSelect } from "../../../../common/new-core-ui/CustomAsyncCreatableSelect";
import { removeCurrencyFormat } from "../../../../../utils/common";
import validator from "validator";
import InputField from "../../../../common/new-core-ui/InputField";

let AddUpdateInvoiceModal = (props) => {

  const dispatch = useDispatch();
  const controller = useRef();

  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loadingPurchses, setLoadingPurchases] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [loadingExpenses, setLoadingExpenses] = useState(false);

  const getExpenseDetails = () => {
    setLoadingExpenses(true);
    controller.current = new AbortController();
    dispatch(getExpenses({ signal: controller.current.signal })).then(res => {
      setExpenses(res.rows);
      setLoadingExpenses(false);
    }).catch((err) => {
      if (err) {
        notification.error({
          message: Strings.error_title,
          description: "There was an error while fetching Expense Details.",
          className: "ant-error"
        })
      }
    })
  }

  // const getPurchaseDetails = () => {
  //   setLoadingPurchases(true);
  //   controller.current = new AbortController();
  //   dispatch(getPurchases({ quote_task_id: props.task.quote_task_id }, { signal: controller.current.signal })).then(res => {
  //     setPurchases(res.po_details);
  //     setLoadingPurchases(false);
  //   }).catch((err) => {
  //     if (err) {
  //       notification.error({
  //         message: Strings.error_title,
  //         description: "There was an error while fetching Purchase Details.",
  //         className: "ant-error"
  //       })
  //     }
  //   })
  // }

  useEffect(() => {
    getExpenseDetails();
    // getPurchaseDetails();
    return () => {
      if (controller.current) {
        controller.current.abort()
      }
    }
  }, []);

  const getDefaultContractorOption = () => {
    let defaultOrg = [];
    return props.contractor && props.contractor.org_id && props.contractor.organisation_type === 2 ? [
      {
        label: props.contractor.contractor_name,
        value: props.contractor.org_id
      },
    ] : defaultOrg
  }

  const handleInputChange = (search) => {
    return search.trim().length > 0 ?
      Promise.resolve(dispatch(getContractorsBySearch(search, 1)).then(res =>
        res.contractors.filter(x => x.contractor_type === 2).map(contractor => ({
          label: contractor.contractor_name,
          value: contractor.contractor_id
        }))
      )) : Promise.resolve([]);
  }

  const onSubmit = (values) => {
    if (!values.invoice || (values.invoice && Object.keys(values.invoice).length === 0)) {
      notification.error({
        message: "Please upload an invoice to save the record.",
        className: "ant-error"
      })
    } else {
      if (values.invoice && (!values.invoice.isUploaded || values.invoice.isFailed)) {
        notification.error({
          message: Strings.error_title,
          description: "One or more files are still uploading or they failed to upload.",
          className: "ant-error"
        });
      } else {
        setLoading(true);
        let formData = {
          client_id: props.jobDetails.client_id,
          contact_person_id: props.jobDetails.client_person_id,
          site_id: props.jobDetails.site_id,
          job_id: props.jobId,
          supplier_id: values.contractor.value,
          task_label: values.task_label,
          task_name: props.task.task_name,
          quote_task_id: values.quote_task_id,
          expense_account_id: values.expense_account.value,
          po_id: values.po && values.po.value ? values.po.value : null,
          po_value: props.task.po_value ? props.task.po_value : null,
          invoice_number: values.invoice_number,
          invoice_amount: removeCurrencyFormat(values.invoice_amount),
          invoice_pdf_file: values.invoice && values.invoice.file_name ? values.invoice.file_name : null,
          task_id: values.task_id
        }

        if (values.contractor["__isNew__"]) {
          formData.supplier_id = null;
          formData.supplier_name = values.contractor.value;
        }

        dispatch(uploadInvoice(formData)).then(message => {
          setLoading(false);
          notification.success({
            message: Strings.success_title,
            description: message,
            className: "ant-success"
          })
          props.onClose(true);
        }).catch(err => {
          if (err) {
            setLoading(false);
            notification.error({
              message: Strings.error_title,
              description: err ? err : Strings.generic_error,
              className: "ant-error"
            })
          }
        })
      }
    }
  }


  return (
    <form onSubmit={props.handleSubmit(onSubmit)}>
      <div className="container-fluid job-swms-modal-container">
        <div className="row">
          <div className="col-12">
            <div className={styles.ModalHeading}>
              Upload Invoice
            </div>
            <div className="">
              <hr />
            </div>
          </div>
        </div>
        <div className="row align-items-center justify-content-center">
          <div className="col-6">
            <div className={styles.Label}>Supplier</div>
          </div>
          <div className="col-6">
            <Field
              name="contractor"
              component={CustomAsyncCreatableSelect}
              clearable={true}
              placeholder="Select Contractor"
              styles={SelectV2SingleStyle}
              otherProps={{
                isDisabled: loading || isDisabled,
                cacheOptions: true,
                hideSelectedOptions: false, defaultOptions: selectedOption.length ? [...selectedOption] : getDefaultContractorOption(),
                onChange: (value => { setSelectedOption([value]); props.change("contractor", value) }),
                components: { dropdownIndicator: <components.DownChevron /> }
              }}
              loadOptions={handleInputChange}
              dropdownIndicator={<Icon type="down" style={{ fontSize: "13px" }} />}
            />
          </div>
        </div>
        <div className="row align-items-center justify-content-center mt-2">
          <div className="col-6">
            <div className={styles.Label}>Task Id</div>
          </div>
          <div className="col-6">
            <Field
              component={customInput}
              name="task_label"
              disabled={true}
              autoComplete="off"
              className={styles.TextField}
            />
          </div>
        </div>
        <div className="row align-items-center justify-content-center mt-2">
          <div className="col-6">
            <div className={styles.Label}>Purchase Order No</div>
          </div>
          <div className="col-6">
            <Field
              name="po"
              placeholder=""
              component={CustomSingleSelect}
              options={props.po_options ? props.po_options : []}
              otherProps={{
                isLoading: loading,
                components: { dropdownIndicator: <components.DownChevron /> },
                styles: colorStylesMulti,
                hideSelectedOptions: false,
                isClearable: true,
                menuPortalTarget: document.body
              }}
            />
          </div>
        </div>
        <div className="row align-items-center justify-content-center mt-2">
          <div className="col-6">
            <div className={styles.Label}>Expense Account</div>
          </div>
          <div className="col-6">
            <Field
              name="expense_account"
              placeholder="Select Expense Account"
              component={CustomSingleSelect}
              options={expenses.map(option => ({ label: option.expense_account, value: option.id }))}
              otherProps={{
                isLoading: loadingExpenses,
                components: { dropdownIndicator: <components.DownChevron /> },
                styles: colorStylesMulti,
                hideSelectedOptions: false,
                menuPortalTarget: document.body
              }}
            />
          </div>
        </div>
        <div className="row align-items-center justify-content-center mt-2">
          <div className="col-6">
            <div className={styles.Label}>Invoice No</div>
          </div>
          <div className="col-6">
            <Field
              component={customInput}
              name="invoice_number"
              disabled={loading}
              autoComplete="off"
              className={styles.TextField}
            />
          </div>
        </div>
        <div className="row align-items-center justify-content-center mt-2">
          <div className="col-6">
            <div className={styles.Label}>Invoice Amount</div>
          </div>
          <div className="col-6">
            <Field
              component={InputField}
              name="invoice_amount"
              disabled={loading}
              prefix="$"
              autoComplete="off"
              className={"v3-form-input-field"}
            />
          </div>
        </div>
        <div className="row align-items-center justify-content-center mt-2">
          <div className="col-6">
            <div className={styles.Label}>Upload Invoice</div>
          </div>
          <div className="col-6">
            <Field
              name="invoice"
              loading={loading}
              previewFile={props.previewFile}
              component={SingleUploadField}
              mimeType=".pdf,.png,.jpg,.jpeg,.webp"
              flexDirection="column"
              s3UploadPath={`account_payable_documents/invoices/${props.jobId}`}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "end", columnGap: "10px" }}>
              {loading ? (
                <CircularProgress size={20} />) : ""}
              <button type="button" className={`${styles.OutlinedButton}`} onClick={() => props.onClose()} style={{ padding: "0px 5px" }}>
                Cancel
              </button>
              <button
                type="submit"
                disabled={loading || loadingExpenses}
                className={styles.PrimaryButton}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

const validate = (values) => {
  let errors = {};
  if (!values.contractor) {
    errors.contractor = "Required";
  }
  if (!values.expense_account) {
    errors.expense_account = "Required";
  }
  if (!values.invoice_number) {
    errors.invoice_number = "Required";
  }
  if (!values.invoice_amount) {
    errors.invoice_amount = "Required";
  } else if (values.invoice_amount && !validator.isFloat(removeCurrencyFormat(values.invoice_amount), { min: 1.0 })) {
    errors.invoice_amount = "Invalid Amount."
  }

  return errors;
}

AddUpdateInvoiceModal = reduxForm({
  form: "addUpdateInvoiceModal",
  destroyOnUnmount: true,
  validate: validate
})(AddUpdateInvoiceModal);

export default AddUpdateInvoiceModal;