import React, { useEffect, useState } from "react";
import { Modal, Icon } from "antd";

export default function PreviewModal(props) {

  const [file, setFile] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setFile(props.file);
  }, [props.file])

  useEffect(() => {
    setVisible(true);
  }, [])


  return (
    <div>
      <Modal
        // title="Signed Off PDF"
        visible={visible}
        afterClose={() => props.onCancel()}
        maskClosable={true}
        header={null}
        footer={null}
        closeIcon={<Icon type="close" style={{ color: "#fff", position: 'absolute', top: "-30px" }} />}
        bodyStyle={{ padding: '0px' }}
        width={(window.innerWidth * 70) / 100}
        onCancel={() => setVisible(false)}
      >
        <div style={{ height: "80vh" }}>
          <embed type="application/pdf" src={file} style={{ height: '100%', width: '100%' }} />
        </div>
      </Modal>
    </div >
  )


}

