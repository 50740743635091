import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchColumns } from "./config/tableDefinitions";
import { Table, notification, Input } from "antd";
import * as SWMSMastersActions from "../../../../../actions/SWMSMastersActions";
import { compose, bindActionCreators } from "redux";
import { getSWMSTypeByMap } from "../../../../../utils/common";
import { reduxForm } from "redux-form";
import { Strings } from "../../../../../dataProvider/localize";

const MASTERS_TYPE = 1;
export class GeneralCategory extends Component {
  state = {
    currentPage: 1,
    pageSize: 10,
    editingKey: "",
    addMode: false,
    items: this.props.rowsData,
    search: ''
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentTab !== this.props.currentTab) {
      this.setState({ addMode: false, editingKey: "" });
      this.props.reset();
    }
    if (prevProps.rowsData !== this.props.rowsData) {
      this.setState({ items: this.props.rowsData })
      if(this.state.search && this.state.search.length >= 3) {
        this.onSearch({ target: { value: this.state.search }})
      }
    }
  }

  onSearch = (e) => {
    this.setState({ search: e.target.value })
    let value = e.target.value;
    if (value.trim().length >= 3) {
      let items = this.props.rowsData;
      let filteredItems = items.filter(i => (i.title !== null && i.title.toString().toLowerCase().includes(value.toLowerCase().trim())) ||
        (i.description !== null && i.description.toString().toLowerCase().includes(value.toLowerCase().trim())));
      this.setState({ items: filteredItems });
    } else {
      this.setState({ items: this.props.rowsData });
    }
  }

  onPageChange = (arg) => {
    this.setState({
      currentPage: arg,
      addMode: false,
      editingKey: "",
    });
    this.props.reset();
  };

  addRow = () => {
    this.setState({ addMode: true, editingKey: "", currentPage: 1 });
  };

  saveRow = (data, rowIndex, editing) => {
    const { formValues, actions } = this.props;

    const formData = {};

    if (editing) {
      if (formValues && formValues[`title_${data.id}`] && formValues[`description_${data.id}`]) {
        formData.id = data.id;
        formData.title = formValues[`title_${data.id}`].trim();
        formData.description = formValues[`description_${data.id}`].trim();
        formData.active = formValues[`status_${data.id}`] ? 1 : 0;
        formData.master_type = MASTERS_TYPE;
      }
    } else {
      if (formValues && formValues.title && formValues.description) {
        formData.title = formValues.title.trim();
        formData.description = formValues.description.trim();
        formData.active = formValues.active ? 1 : 0;
        formData.master_type = MASTERS_TYPE;
      }
    }
    if (!(!formData.title) && !(!formData.description) && !(!formData.master_type) && formData.active !== undefined) {
      actions
        .addEditSWMS(formData)
        .then((message) => {
          notification.success({
            message: Strings.success_title,
            description: message ? message : Strings.generic_error,
            onClick: () => { },
            className: "ant-success",
          });

          actions
            .getSWMS(MASTERS_TYPE)
            .then(() => {
              this.setState({ addMode: false, editingKey: "" });
              this.props.reset();
            })
            .catch((err) => { });
        })
        .catch((message) => {
          notification.error({
            message: Strings.error_title,
            description: message
              ? Array.isArray(message)
                ? message[0].message
                : message
              : Strings.generic_error,
            onClick: () => { },
            className: "ant-error",
          });
        });
    } else {
      notification.error({
        message: Strings.error_title,
        description: "All of the Fields are required.",
        className: "ant-error"
      })
    }
  };

  editRow = (data, rowIndex) => {
    this.setState({ editingKey: data.id, addMode: false });
    this.props.change(`title_${data.id}`, data.title);
    this.props.change(`description_${data.id}`, data.description);
    this.props.change(`status_${data.id}`, data.active);
  };

  cancel = (data, rowIndex) => {
    this.setState({ addMode: false, editingKey: "" });
    this.props.reset();
  };

  changeStatus = (value, data, rowIndex) => {
    if (!this.state.editingKey && !this.state.addMode) {
      const formData = {
        id: data.id,
        title: data.title,
        description: data.description,
        active: value ? 1 : 0,
        master_type: MASTERS_TYPE,
      };

      this.props.actions
        .addEditSWMS(formData)
        .then((message) => {
          notification.success({
            message: Strings.success_title,
            description: message ? message : Strings.generic_error,
            onClick: () => { },
            className: "ant-success",
          });

          this.props.actions
            .getSWMS(MASTERS_TYPE)
            .then(() => { })
            .catch((err) => { });
        })
        .catch((message) => {
          notification.error({
            message: Strings.error_title,
            description: message
              ? Array.isArray(message)
                ? message[0].message
                : Strings.generic_error
              : Strings.generic_error,
            onClick: () => { },
            className: "ant-error",
          });
        });
    }
  };

  deleteRow = (data, rowIndex) => {
    const formData = {
      id: data.id,
      master_type: MASTERS_TYPE
    }

    this.props.actions
      .deleteOrgCategory(formData)
      .then((message) => {
        notification.success({
          message: Strings.success_title,
          description: message ? message : Strings.generic_error,
          onClick: () => { },
          className: "ant-success",
        });

        this.props.actions
          .getSWMS(MASTERS_TYPE)
          .then(() => { })
          .catch((err) => { });
      })
      .catch((message) => {
        notification.error({
          message: Strings.error_title,
          description: message
            ? Array.isArray(message)
              ? message[0].message
              : Strings.generic_error
            : Strings.generic_error,
          onClick: () => { },
          className: "ant-error",
        });
      });
  }

  render() {
    const { addMode, editingKey, currentPage, pageSize, items } = this.state;

    return (
      <div className="new-swms-table">
        <div className="d-flex justify-content-between px-2">
          <div className="">
            <Input placeholder="Search" value={this.state.search} onChange={this.onSearch} style={{ width: '250px', maxWidth: '500px' }} />
          </div>
          <div>
            <button
              type="button"
              className="bnt bnt-active add-row-swms-bnt"
              onClick={() => this.addRow()}
              disabled={addMode || editingKey}
            >
              Add Rows
            </button>
          </div>
        </div>
        <div
          style={{
            maxWidth: "90vw",
            padding: "1rem",
          }}
        >
          <Table
            columns={fetchColumns(
              this.saveRow,
              this.editRow,
              this.cancel,
              this.changeStatus,
              editingKey,
              this.deleteRow
            )}
            dataSource={addMode ? [{ key: "addRow" }, ...items] : items}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              onChange: (a) => this.onPageChange(a),
            }}
            bordered
            scroll={{ x: 600 }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rowsData: state.SWMSMasters[getSWMSTypeByMap[MASTERS_TYPE]],
    formValues: state.form.SWMSTable && state.form.SWMSTable.values,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(SWMSMastersActions, dispatch),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "SWMSTable",
  })
)(GeneralCategory);
