import React, { Component } from "react";
import { connect } from "react-redux";
import TabsContainer from "./Tabs/TabsContainer";
import { Icon } from "antd";
import { Switch, Route } from "react-router-dom";
import SWMSActivityContainer from "./SWMSActivity/SWMSActivityContainer";
import { bindActionCreators } from "redux";
import * as SWMSMastersActions from "../../../actions/SWMSMastersActions";

export class SWMSMastersContainer extends Component {
  state = {
    page: 1
  };

  componentDidMount() {
		document.title = "Master - SWMS - Service Farm";
   }

  handleGoBack = () => {
    if (this.props.history.location.pathname.includes("/manage_swms_activity")) {
      this.props.actions.activateBreadCrumbs(this.state.page)
    }
    this.props.history.goBack()
  }

  pageChange = (page) => {
    this.setState({ page })
  }

  render() {
    return (
      <div className="sf-page-layout">
        <div className="dash-header">
          <h2 className="page-mn-hd">
            <Icon type="arrow-left" onClick={() => this.handleGoBack()} />
            Master - SWMS
          </h2>
        </div>
        <div className="main-container swms-masters-container">
          <div className="sf-card-wrap">
            <Switch>
              <Route
                path={this.props.match.path + "/"}
                exact
                render={props => <TabsContainer {...props} pageChangeCb={this.pageChange} />}
              />
              <Route
                path={this.props.match.path + "/manage_swms_activity"}
                exact
                render={(props) => <SWMSActivityContainer {...props} />}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  breadCrumbs: state.SWMSMasters.breadCrumbs
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(SWMSMastersActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SWMSMastersContainer);
