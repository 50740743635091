import {
    GET_PAYRATE_LIST,
    IS_GET_PAY_RATE_LIST,
    GET_PAYRATE_FOR_USER_MANAGEMENT,
    GET_TIMESHEET_BY_USERNAME,
    GET_TIMESHEET_BY_USERNAME_URL,
    ADD_USER_PAYRATE_URL,
    ADD_PAY_RATE_URL,
    GET_PAY_RATE_URL,
    IS_GET_TIMESHEET_BY_USERNAME,
    GET_PAY_RATE_FOR_USER_MANAGEMENT_URL
} from '../dataProvider/constant'
import { startSipnner, stopSipnner, stopMiniSpinner, startMiniSpinner, stopScrollSpinner, startScrollSpinner } from '../utils/spinner';
import { scopeAxiosInstance } from '../dataProvider/axiosHelper'
import axiosInstance from '../dataProvider/axiosHelper'
import { Strings } from '../dataProvider/localize'

//  NEW TIME SHEET 
import { BehaviorSubject } from 'rxjs'

const subscriber = new BehaviorSubject(0);
export const SetBroadcastMsg = (msg) => dispatch => {
    subscriber.next(msg)
}
export const getBroadcastMsg = () => dispatch => {
    return subscriber.asObservable();
}

export const addNewPayRate = (obj) => dispatch => {
    startSipnner(dispatch);
    return axiosInstance.post(ADD_PAY_RATE_URL, obj)
        .then(res => {
            stopSipnner(dispatch);
            if (res.data.status) {
                getPayrates(dispatch, { page_no: obj['page_no'] ? obj['page_no'] : 1 });
                return Promise.resolve(res.data)
            }
        })
        .catch((error) => {
            stopSipnner(dispatch);
            return Promise.reject(error.response ?
                error.response.data.message :
                Strings.network_error)
        });
};

const getPayrates = (dispatch, obj) => {
    startSipnner(dispatch);
    dispatch({
        type: IS_GET_PAY_RATE_LIST,
        payload: true
    })
    return axiosInstance.post(GET_PAY_RATE_URL, obj)
        .then(res => {
            stopSipnner(dispatch);
            dispatch({
                type: IS_GET_PAY_RATE_LIST,
                payload: false
            })
            if (res.data.status) {
                dispatch({
                    type: GET_PAYRATE_LIST,
                    payload: res.data.data.timesheet_list.rows,
                    total: res.data.data.timesheet_list.count
                })
                return Promise.resolve(res.data.message && res.data.message)
            }
        })
        .catch(error => {
            dispatch({
                type: IS_GET_PAY_RATE_LIST,
                payload: false
            })
            stopSipnner(dispatch)
            return Promise.reject(error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error)
        })
}

export const getPayrateList = (obj) => dispatch => {
    return getPayrates(dispatch, obj)
}


export const getPayrateForUsermanagement = () => dispatch => {
    startSipnner(dispatch);
    return axiosInstance.get(GET_PAY_RATE_FOR_USER_MANAGEMENT_URL)
        .then(res => {
            stopSipnner(dispatch);
            if (res.data.status) {
                dispatch({
                    type: GET_PAYRATE_FOR_USER_MANAGEMENT,
                    payload: res.data.data.timesheet_list
                })
                return Promise.resolve(res.data.message && res.data.message)
            }
        })
        .catch(error => {
            stopSipnner(dispatch)
            return Promise.reject(error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error)
        })
}


export const addUserPayrate = (formData) => dispatch => {
    startSipnner(dispatch);
    return axiosInstance.post(ADD_USER_PAYRATE_URL, formData)
        .then(async res => {
            stopSipnner(dispatch);
            if (res.data.status) {
                return Promise.resolve(res.data);
            }
        })
        .catch(error => {
            stopSipnner(dispatch)
            return Promise.reject(error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error)
        });
}


export const getTimesheetByUserName = (body) => dispatch => {
    startSipnner(dispatch);
    dispatch({
        type: IS_GET_TIMESHEET_BY_USERNAME,
        payload: true
    })
    return axiosInstance.post(GET_TIMESHEET_BY_USERNAME_URL, body)
        .then(async res => {
            stopSipnner(dispatch);
            if (res.data.status) {
                dispatch({
                    type: GET_TIMESHEET_BY_USERNAME,
                    payload: res.data.data.timesheet
                })
                dispatch({
                    type: IS_GET_TIMESHEET_BY_USERNAME,
                    payload: false
                })
                return Promise.resolve(res.data.data);
            }
        })
        .catch(error => {
            stopSipnner(dispatch)
            dispatch({
                type: IS_GET_TIMESHEET_BY_USERNAME,
                payload: false
            })
            return Promise.reject(error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error)
        });
}