import React, { useState, useEffect } from "react";
import { reduxForm, Field } from "redux-form";
import { EditorState, ContentState } from 'draft-js';
import { customInput } from '../../../../../common/custom-input';
import { MultiEmailInput } from "../../../../../common/chipInputRedux";
import EmailEditor from '../../../../../common/EmailEditor';
import colourStyles from "./POEmailStyles";
import htmlToDraft from 'html-to-draftjs';
import validator from "validator";

import styles from "../../../JobStyle.module.css";


let POEmailForm = (props) => {

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  useEffect(() => {
    let blocksFromHtml = htmlToDraft(props.initialValues.body);
    if (blocksFromHtml) {
      let contentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks);
      let editorStateVal = EditorState.createWithContent(contentState);
      setEditorState(editorStateVal);
    }
  }, []);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  return (
    <div className="container-fluid px-0">
      <div className="row">
        <div className="col-12">
          <form onSubmit={props.handleSubmit}>
            <div className="my-1">
              <div className={styles.RfqMailFormLabel}>From</div>
              <Field
                name="from"
                type="text"
                id="name"
                disabled={true}
                autoComplete="off"
                className={styles.RfqFormField}
                component={customInput} />
            </div>
            <div className="my-1">
              <div className={styles.RfqMailFormLabel}>To</div>
              <Field
                name="to"
                type="text"
                id="name"
                // isMulti={emailOptions.allowMutiToEmail}
                autoComplete="off"
                component={MultiEmailInput}
                otherProps={{
                  styles: colourStyles,
                  isDisabled: props.loading
                }} />
            </div>
            <div className="my-1">
              <div className={styles.RfqMailFormLabel}>CC</div>
              <Field
                name="cc"
                type="text"
                id="name"
                autoComplete="off"
                component={MultiEmailInput}
                otherProps={{
                  styles: colourStyles,
                  isDisabled: props.loading
                }} />
            </div>
            <div className="my-1">
              <div className={styles.RfqMailFormLabel}>BCC</div>
              <Field
                name="bcc"
                type="text"
                id="name"
                autoComplete="off"
                component={MultiEmailInput}
                otherProps={{
                  styles: colourStyles,
                  isDisabled: props.loading
                }} />
            </div>
            <div className="my-1">
              <div className={styles.RfqMailFormLabel}>Subject</div>
              <Field
                name="subject"
                type="text"
                id="name"
                autoComplete="off"
                component={customInput}
                className={styles.RfqFormField}
                disabled={props.loading}
              />
            </div>
            <div className="my-1">
            </div>
            <div className="my-1 form-group sf-form rich-textbox">
              <div className="sf-rich-txtbox quotes-email-textbox">
                <fieldset>
                  <Field
                    name="body"
                    type="text"
                    id="name"
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                    component={EmailEditor}
                    otherProps={{
                      editorStyle: {
                        height: "250px"
                      }
                    }}
                    disabled={props.loading} />
                </fieldset>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const validate = (values) => {
  const errors = {};
  if (!values.to || values.to && values.to.length === 0) {
    errors.to = "Required"
  } else if (Array.isArray(values.to)) {
    let flag = true;
    values.to.forEach(email => {
      if (email.value && !validator.isEmail(email.value, { allow_display_name: true })) { flag = false }
    })
    if (!flag) { errors.to = "Invalid Email Address" }
  }

  if (!values.from) {
    errors.from = "Required"
  } else if (!validator.isEmail(values.from, { allow_display_name: true })) {
    errors.from = "Invalid Email Address";
  }

  if (values.cc) {
    if (Array.isArray(values.cc)) {
      let flag = true;
      values.cc.forEach(email => {
        if (email.value && !validator.isEmail(email.value, { allow_display_name: true })) { flag = false }
      })
      if (!flag) { errors.cc = "Invalid Email Address" }
    }
  }

  if (values.bcc) {
    if (Array.isArray(values.bcc)) {
      let flag = true;
      values.bcc.forEach(email => {
        if (email.value && !validator.isEmail(email.value, { allow_display_name: true })) { flag = false }
      })
      if (!flag) { errors.bcc = "Invalid Email Address" }
    }
  }

  if (!values.subject) {
    errors.subject = "Required";
  }

  return errors
}

POEmailForm = reduxForm({
  enableReinitialize: true,
  validate: validate
})(POEmailForm)


export default POEmailForm;