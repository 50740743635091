import { GET_SWMS_METHOD_DETAILS, GET_METHOD_ITEMS_ADDITIONALS } from "../../dataProvider/constant";
const INITIAL_STATE = {
    methodItems: [],
    methodAdditionals: []
}

export default (state = INITIAL_STATE, action) => {
    const { payload } = action;
    switch (action.type) {
        case GET_SWMS_METHOD_DETAILS: {
            return {
                ...state,
                methodItems: payload,
            }
        }
        case GET_METHOD_ITEMS_ADDITIONALS: {
            return {
                ...state,
                methodAdditionals: payload

            }
        }
        default: {
            return state;
        }
    }
}