import React from "react";
import { Page, Text, View, Document, StyleSheet, Image, Link, Font } from "@react-pdf/renderer";
import { Html } from 'react-pdf-html';
import downloadIcon from './download-icon.png'
import moment from "moment";
import "./jobReportPdf.css";
import LatoRegularFont from './pdfFonts/lato-regular.ttf';
import LatoBoldFont from './pdfFonts/lato-700.ttf';

// Register Lato font from Google Fonts
Font.register({
  family: 'Lato',
  fonts: [
    {
      src: LatoRegularFont, // Poppins Regular
    },
    {
      src: LatoBoldFont, // Poppins Bold
      fontWeight: 700,
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    paddingTop: 10,
    paddingBottom: 20,
    fontSize: 10,
  },
  label: {
    fontFamily: 'Lato',
    color: "#000",
    fontSize: 10,
    paddingBottom: 3,
    fontWeight: "bold",
  },
  context: {
    fontFamily: 'Lato',
    color: "#4A4A4A",
    fontWeight: "normal",
    fontSize: 10,
    paddingBottom: 3,
    maxLines: 2,

  },
  contextBold: {
    color: "#13619a",
    fontWeight: "normal",
    fontSize: 9,
    paddingBottom: 3
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },

  topCard: {
    border: 1,
    backgroundColor: "#fff",
    borderLeftColor: "#EFEFEF",
    borderRightColor: "#EFEFEF",
    borderTopColor: "#EFEFEF",
    borderBottomColor: "#EFEFEF",
    marginTop: 10,
    marginRight: 10,
    marginLeft: 10,
    borderTopWidth: 3,
    borderTopColor: "#13619A"
  },
  card: {
    border: 1,
    backgroundColor: "#fff",
    borderTopStyle: "solid",
    borderLeftColor: "#EFEFEF",
    borderRightColor: "#EFEFEF",
    borderTopColor: "#EFEFEF",
    borderBottomColor: "#EFEFEF",
    marginTop: 10,
    marginRight: 10,
    marginLeft: 10,
  },

  cardHeading: {
    fontFamily: 'Lato',
    padding: 10,
    fontSize: 12,
    color: "#13619A",
    backgroundColor: "#f3f3f3",
    fontWeight: "bold"
  },
  section: {
    padding: 10,
    flexGrow: 1,
  },
  section2: {
    padding: 10,
  },
  comLogo: {
    maxHeight: '80px',
    objectFit: 'contain'
  },

  // table
  tableWrap: {
    padding: 10,
    width: "100%",
    marginTop: "4px"
  },
  table: {
    width: "100%",
  },
  tr: {
    flexDirection: "row",
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    borderRightWidth: 0,
  },
  th: {
    padding: 2, paddingBottom: 8, paddingLeft: 4, paddingRight: 10
  },

  td: {
    borderLeft: "2px solid #f3f3f3",
  },
  cellText: {
    fontFamily: 'Lato',
    fontSize: 10,
    color: "#4a4a4a",
  },

  tCol1: { width: "8.333333%" },
  tCol2: { width: "16.666667%", marginHorizontal: 1 },
  tCol3: { width: "22%" },
  tCol4: { width: "36.33%" },
  tCol5: { width: "41.666667%%" },
  tCol6: { width: "50%" },
  tCol7: { width: "58.333333%" },
  tCol8: { width: "66.666666%" },
  tCol9: { width: "75%" },
  tCol10: { width: "83.333333%" },
  tCol11: { width: "91.666667%" },
  tCol12: { width: "100%" },
  h4: {
    fontSize: 16,
    marginBottom: 5,
  },
  h3: {
    fontSize: 18,
    marginBottom: 8,
  },
  li: {
    fontSize: 12,
    marginBottom: 8,
  },
  rownumber: {
    width: 200,
  },
  lable: {
    fontSize: 12,
    marginBottom: 5,
  },
  span: {
    fontSize: 12,
    marginBottom: 5,
    color: "#000",
  },
  thFirst: {
    width: 420,
  },
  thead: {
    backgroundColor: "#424242",
    color: "#fff",
  },

  brTopNon: { borderTopWidth: 0 },
  rowHeader: {
    padding: "0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderStyle: "solid",
    marginHorizontal: "10px",
  },
  comtextB: {
    fontFamily: 'Lato',
    color: "#000000",
    fontWeight: "bold",
    fontSize: 16,
    paddingBottom: 3,

  },
  accordion: {
    fontFamily: 'Lato',
    color: "#4A4A4A",
    padding: "5px",
    paddingLeft: "8px",
    marginTop: "5px",
  },
  downloadIcon: {
    fontSize: 15,
    marginTop: 8
  },
  tableLabel: {
    fontFamily: 'Lato',
    color: "#000",
    fontSize: 10,
    fontWeight: "bold"
  },
  tableData: {
    fontFamily: 'Lato',
    color: "#4A4A4A",
    fontSize: 10,
    marginTop: 2
  },
  photosTr: {
    flexDirection: "row",
  },
  headerCell: {
    flex: 1,
    fontWeight: 'bold',
    padding: 10,
  },
  cellBorder: {
    borderWidth: 1,
    borderColor: "#f1f1f1",
  },
  cell: {
    flex: 1,
    textAlign: 'center',
    fontSize: 14,
    padding: 10,
  },
  centerText: {
    alignItems: 'center',
    textAlign: 'left'
  }
});


export const JobReportPdf = ({
  job,
  jobReports,
  filePath,
  resizedFilePath,
  jobDocument,
  allTasks,
  isDoc
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View>
          <View style={styles.rowHeader}>
            <View>
              <Image
                style={{ width: "140px", height: "auto" }}
                src={`${job.org_logo}?t=${moment(new Date())}`}
              />
            </View>
            <View>
              <Text style={styles.comtextB}> Job Report</Text>
            </View>
          </View>
        </View>
        <View style={styles.topCard}>
          <View style={styles.row}>
            <View style={styles.section}>
              <Text style={styles.label}>By</Text>
              <Text style={styles.contextBold}>
                Documents Prepared By: <Text style={styles.context}>{Object.keys(job).length > 0 && job.admin_name}</Text>
              </Text>
              <Text style={styles.contextBold}>
                Company: <Text style={styles.context}>{Object.keys(job).length > 0 && job.org_name}</Text>
              </Text>
              <Text style={styles.contextBold}>
                Address:<Text style={styles.context}>{Object.keys(job).length > 0 && job.address}</Text>
              </Text>
              <Text style={styles.contextBold}>
                Mobile: <Text style={styles.context}>{Object.keys(job).length > 0 && job.admin_phone_number}</Text>
              </Text>
              <Text style={styles.contextBold}>
                Email: <Text style={styles.context}>{Object.keys(job).length > 0 && job.admin_email_address}</Text>
              </Text>
            </View>
            <View style={styles.section2}>
              <Text style={styles.label}>To</Text>
              <Text style={styles.contextBold}>
                Attention: <Text style={styles.context}>{Object.keys(job).length > 0 && job.client_person_name}</Text>
              </Text>
              <Text style={styles.contextBold}>
                Company: <Text style={styles.context}>{Object.keys(job).length > 0 && job.client_name}</Text>
              </Text>
              <View style={{ maxWidth: 200 }}>
                <Text style={styles.contextBold}>
                  Address: <Text
                    style={styles.context}
                  >{Object.keys(job).length > 0 && job.client_address}
                  </Text>
                </Text>
              </View>

              <Text style={styles.contextBold}>
                Mobile: <Text style={styles.context}>{Object.keys(job).length > 0 && job.client_person_phone}</Text>
              </Text>
              <Text style={styles.contextBold}>
                Email: <Text style={styles.context}>{job ? job.client_person_email : " "}</Text>
              </Text>
            </View>
          </View>
        </View>
        {job &&
          job.sites.map((site) => {

            return <View style={styles.card} wrap>
              <Text style={styles.cardHeading}>Job Details</Text>
              <View style={styles.sfCardwe}>
                <View style={styles.tableWrap}>
                  <View style={styles.table}>
                    <View style={[styles.tr, styles.brTopNon]} fixed>
                      <View style={[styles.tCol4]}>
                        <Text style={styles.tableLabel}>Site Name</Text>
                      </View>
                      <View style={[styles.tCol4]}>
                        <Text style={styles.tableLabel}>Site Address</Text>
                      </View>
                      <View style={[styles.tCol4]}>
                        <View >
                          <Text style={styles.tableLabel}>
                            Quote Number: <Text style={styles.tableData}> {job.quote_number}</Text>
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View style={styles.tr}>
                      <View style={[styles.td, styles.tCol4]}>
                        <Text style={styles.tableData}>{site.site_name}{" "}</Text>
                      </View>
                      <View style={[styles.td, styles.tCol4]}>
                        <Text style={styles.tableData}>{site.street_address} {site.city},{site.state},{site.country}{" "}</Text>
                      </View>
                      <Text style={styles.tableLabel}>
                        Purchase Order: <Text style={styles.tableData}> {job.PO_number}</Text>
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          })}

        <View
          style={{ marginTop: 10, marginRight: 10, marginLeft: 10, backgroundColor: "#fff", }}
        >
          <Text style={styles.cardHeading}>Job Details</Text>
          {jobReports.map((report, index) => {
            return <View break={index.toString() === "2" ? true : false} >
              <Text style={styles.accordion}>Task -  {allTasks &&
                allTasks.find(
                  (tsk) => tsk.id === report.quote_task_id
                ) &&
                allTasks.find(
                  (tsk) => tsk.id === report.quote_task_id
                ).task_name} {report.area ? `,  Area-${report.area}` : " "}</Text>
              <View>
                <View style={[styles.photosTr]}>
                  <View style={[styles.headerCell, styles.tCol3, styles.cellBorder]}>
                    <Text style={styles.label}>Area Photo</Text>
                  </View>
                  <View style={[styles.headerCell, styles.tCol3, styles.cellBorder]}>
                    <Text style={styles.label}>Before Photo</Text>
                  </View>
                  <View style={[styles.headerCell, styles.tCol3, styles.cellBorder]}>
                    <Text style={styles.label}>After Photo</Text>
                  </View>
                  <View style={[styles.headerCell, styles.tCol4, styles.cellBorder]}>
                    <Text style={styles.label}>Notes</Text>
                  </View>
                </View>

                <View style={styles.photosTr}>
                  <View style={[styles.cell, styles.tCol3, styles.cellBorder]}>
                    <View>
                      {report.location_photo && JSON.parse(report.location_photo).length > 0 ?
                        JSON.parse(report.location_photo).map(link =>
                          <View>
                            <Link target="_blank" src={filePath + link}>
                              <Image style={styles.comLogo} src={resizedFilePath + link + "?t=" + moment(new Date())} />
                            </Link>
                          </View>) : null}
                    </View>
                  </View>
                  <View style={[styles.cell, styles.tCol3, styles.cellBorder]}>
                    {report.before_photo && JSON.parse(report.before_photo).length > 0 ?
                      JSON.parse(report.before_photo).map(link =>
                        <Link target="_blank" src={filePath + link}>
                          <Image style={styles.comLogo} src={resizedFilePath + link + "?t=" + moment(new Date())} />
                        </Link>
                      ) : null}
                  </View>
                  <View style={[styles.cell, styles.tCol3, styles.cellBorder]}>
                    {report.after_photo && JSON.parse(report.after_photo).length > 0 ?
                      JSON.parse(report.after_photo).map(link =>
                        <Link target="_blank" src={filePath + link}>
                          <Image style={styles.comLogo} src={resizedFilePath + link + "?t=" + moment(new Date())} />

                        </Link>
                      ) : null}
                  </View>
                  <View style={[styles.cell, styles.tCol4, styles.cellBorder, styles.centerText]}>
                    <Text style={styles.cellText}>{report.note !== null ? report.note : ''}</Text>
                  </View>
                </View>
              </View>
              {/* </View> */}
            </View>
          })}
        </View>

        {isDoc && jobDocument.length > 0 &&
          <View style={styles.card} wrap break>
            <Text style={styles.cardHeading}>Additional Documents</Text>

            <View style={styles.sfCardwe}>
              <View style={styles.tableWrap}>
                <View style={styles.table}>
                  <View style={[styles.tr, styles.brTopNon]} fixed>
                    <View style={[styles.th, styles.tCol4]}>
                      <Text style={styles.cellHdText}>Document Name</Text>
                    </View>
                    <View style={[styles.th, styles.tCol4]}>
                      <Text style={styles.cellHdText}>Document Comments</Text>
                    </View>
                    <View style={[styles.th, styles.tCol4]}>
                      <Text style={styles.cellHdText}>Action</Text>
                    </View>
                  </View>
                  {jobDocument && jobDocument.map((doc) => {
                    return <View style={styles.tr}>
                      <View style={[styles.td, styles.tCol4]}>
                        <Text style={styles.context}>{doc.document_name}{" "}</Text>
                      </View>
                      <View style={[styles.td, styles.tCol4]}>
                        <Html style={{ fontSize: "10px" }}>{doc.comments}</Html>
                      </View>
                      <View style={[styles.td, styles.tCol4]}>
                        <Text style={styles.downloadIcon}>
                          <Link target="_blank" src={doc.shift_report_document}>
                            <Image src={downloadIcon}></Image>
                          </Link>
                        </Text>
                      </View>
                    </View>
                  })}
                </View>
              </View>
            </View>
          </View>}
      </Page>
    </Document>
  );
};
export default JobReportPdf;
