import React, { useEffect, useState } from "react";
import styles from "../../JobStyle.module.css";
import TaskContractorForm from "./TaskContractorForm"
import { currencyFormat } from "../../../../../utils/common";
import ApproveTask from "./ApproveTaskValue/ApproveTask";

export default function TaskContractors(props) {

  const [addNewContractor, setAddNewContractor] = useState(false);
  const [isInvoiced, setIsInvoiced] = useState(false);
  const [allCancelled, setAllCancelled] = useState(false);

  useEffect(() => {
    if (props.task.invoice_number && props.task.invoice_number !== null) {
      setIsInvoiced(true);
    } else {
      setIsInvoiced(false);
    }
    if (props.task && props.task.contractor_details && props.task.contractor_details.every(cont => cont.contractor_task_status && cont.contractor_task_status.toString().toLowerCase() === "cancelled")) {
      setAllCancelled(true);
    } else {
      setAllCancelled(false);
    }
  }, [props.task]);

  return (
    <div>
      {props.task.contractor_details && props.task.contractor_details.length > 0 ? (
        <>
          <div className={styles.SectionHeading}>Assigned To</div>
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-12">
                {props.task.contractor_details && props.task.contractor_details.map(contractor => (
                  <div className={`${styles.card}`} key={contractor.contractor_id.toString()}>
                    <TaskContractorForm
                      setSelectedTask={props.setSelectedTask}
                      initialValues={{
                        ...contractor,
                        contractor: { label: `${contractor.contractor_name}${contractor.organisation_type && contractor.organisation_type === 1 ? ' (Org)' : ''}`, value: contractor.org_id },
                        outsourced_budget: contractor.outsourced_budget ? currencyFormat(contractor.outsourced_budget) : ''
                      }}
                      task={props.task}
                      jobId={props.jobId}
                      isDisabledJob={props.isDisabledJob}
                      contractor={contractor}
                      form={`TaskContractor_${props.task.task_id}_${contractor.contractor_id}`}
                      closeAddNew={() => setAddNewContractor(false)} />
                  </div>
                ))}
              </div>
            </div>

            <div className="row">
              <div className="col-10 font-weight-normal">
                {!isInvoiced && props.task && props.task.contractor_details && props.task.contractor_details.length > 0 && props.task.task_state && props.task.task_state.toString().toLowerCase() !== "cancelled" && allCancelled === true ? (
                  <span className="text-danger">All contractors have been cancelled. You must allocate new contractor; update cancelled contractor status or click on the action button to cancel Task.</span>
                ) : ''}
              </div>
            </div>
            {/* <div className="row">
              <div className="col-12 d-flex align-items-center justify-content-between pt-2">
                <IssuePO
                  jobId={props.jobId}
                  siteId={props.siteId}
                  siteName={props.siteName}
                  task={props.task}
                  jobLabel={props.job.job_label}
                  refreshJobBoard={props.refreshJobBoard}
                />
                <ApproveTask jobId={props.jobId} task={props.task} refreshJobBoard={props.refreshJobBoard} />
                <UploadInvoice
                  contractor={props.task.contractor_details[0]}
                  task={props.task}
                  jobId={props.jobId}
                  job={props.job}
                  refreshJobBoard={props.refreshJobBoard}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <InvoiceTable
                  refreshJobBoard={props.refreshJobBoard}
                  task={props.task}
                  jobId={props.jobId}
                // isDisabled={props.task && props.task.job_board_task_state && props.task.job_board_task_state.toLowerCase() === "values approved for invoice"}
                />
              </div>
            </div> */}
          </div>
        </>
      ) :
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center font-weight-normal" style={{ color: "red" }}>
              This task is not Resourced yet.
            </div>
          </div>
        </div>
      }
    </div>
  )
}