import React from "react";
import { Field } from "redux-form";
import { customInput } from "../../../../../common/custom-input";
import { CustomSwitch } from "../../../../../common/customSwitch";
import styles from "../../../SWMSMastersContainer.module.css";
import { Tooltip, Popconfirm } from "antd";
import { Strings } from '../../../../../../dataProvider/localize';

export const fetchColumns = (save, edit, cancel, changeStatus, editingKey, currentPage, deleteRow, orgSWMS) => [    
    {
      title: "Category",
      dataIndex: "category",
      sorter: (a, b) => a.category < b.category ? -1 : a.category > b.category ? 1 : 0,
      editable: true,
      width: 600,
      render: (cellContent, rowData, rowIndex) =>
        rowData.key === "addRow" ? (
          <div>
            <Field name="title" component={customInput} autoFocus />
          </div>
        ) : editingKey === rowData.id ? (
          <div>
            <Field
              name={`title_${rowData.id}`}
              component={customInput}
              autoFocus
            />
          </div>
        ) : (
          <Tooltip title={cellContent}>
            <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
              {cellContent}
            </div>
          </Tooltip>
        ),
    },

{
  title: "Status",
  dataIndex: "active",
  editable: true,
  width: 75,
  render: (cellContent, rowData, rowIndex) => {
    return rowData.key === "addRow" ? (
      <div className={styles.centeredCell}>
        <Field name="active" size="small" component={CustomSwitch} />
      </div>
    ) : editingKey === rowData.id ? (
      <div className={styles.centeredCell}>
        <Field
          name={`status_${rowData.id}`}
          size="small"
          component={CustomSwitch}
        />
      </div>
    ) : (
      <div className={styles.centeredCell}>
        <Field
          name={`status_${rowData.id}`}
          val={rowData.active}
          size="small"
          component={CustomSwitch}
          onChange={(e, value) => changeStatus(value, rowData, rowIndex)}
        />
      </div>
    );
  },
},
{
  title: "Action",
  dataIndex: "action",
  editable: false,
  width: 75,
  render: (cellContent, rowData, rowIndex) =>
    rowData.key === "addRow" || editingKey === rowData.id ? (
      <div className={styles.spacedCell}>
        <i
          class="fa fa-save"
          style={{
            color:
              /* user.role && user.role_id > 0 ? */ "green" /* : 'red' */,
          }}
          aria-hidden="true"
          onClick={() =>
            editingKey
              ? save(rowData, rowIndex, true)
              : save(rowData, rowIndex, false)
          }
        ></i>
        <i
          class="fa fa-close"
          style={{
            color: /* user.role && user.role_id > 0 ? */ "red" /* : 'red' */,
          }}
          aria-hidden="true"
          onClick={() => cancel(rowData, rowIndex)}
        ></i>
      </div>
    ) : (
      <div className={styles.centeredCell}>
        <i
          class="fa fa-pencil"
          style={{
            color:
              /* user.role && user.role_id > 0 ? */ "green" /* : 'red' */,
          }}
          aria-hidden="true"
          onClick={() => edit(rowData, rowIndex)}
        ></i>
        <Popconfirm
          title={Strings.confirm_task_type_delete}
          onConfirm={() => deleteRow(rowData)}
          placement="topRight"
          okText="Yes"
          cancelText="No"
        >
          <button type="button" className='delete-bnt user-actions' >
            <i
              class="fa fa-trash"
              style={{
                color: "#03A791",
              }}
            ></i>
          </button>
        </Popconfirm>
      </div>
    ),
},
];

export const viewDefaults = (defaultString, orgSWMS, isOptions = false) => {
  if (defaultString && orgSWMS) {
      try {
          var defaultArray = JSON.parse(defaultString)
      } catch{
          return "";
      }
      let res = [];
      defaultArray.forEach(def => {
          var arr = def.split("|");
          switch (arr[0]) {
              case "ppes":
                  if (orgSWMS.ppes.find(ppe => ppe.id == arr[1])) { 
                    let selectedPpe = orgSWMS.ppes.find(ppe => ppe.id == arr[1])
                    if (isOptions) {
                      res.push({ label: "PPE|" + selectedPpe.name, value: "ppes|" + selectedPpe.id })
                    } else {
                      res.push("PPE|" + selectedPpe.name)
                    }
                  }
                  break;
              case "tools":
                  if (orgSWMS.tools.find(tool => tool.id == arr[1])) {
                    let selectedTool = orgSWMS.tools.find(tool => tool.id == arr[1])
                    if (isOptions) {
                      res.push({ label: "Tool Type|" + selectedTool.name, value: "tool|" + selectedTool.id })
                    } else {
                      res.push("Tool Type|" + selectedTool.name)
                    }
                  }
                  break;
              case "high_risk_works":
                  if (orgSWMS.high_risk_works.find(hrw => hrw.id == arr[1])) {
                    let selectedHrw = orgSWMS.high_risk_works.find(hrw => hrw.id == arr[1])
                    if (isOptions) {
                      res.push({ label: "High Risk Work|" + selectedHrw.name, value: "high_risk_works|" + selectedHrw.id })
                    } else {
                      res.push("High Risk Work|" + selectedHrw.name)
                    }
                  } 
                  break;
              case "sds":
                  if (orgSWMS.sds.find(ppe => ppe.id == arr[1])) {
                    let selectedChemical = orgSWMS.sds.find(chemical => chemical.id == arr[1])
                    if (isOptions) {
                      res.push({ label: "Chemicals|" + selectedChemical.name, value: "chemicals|" + selectedChemical.id })
                    } else {
                      res.push("Chemicals|" + selectedChemical.name)
                    }
                  }
                  break;
              default:
                  break;
          }
      })
      if (isOptions) {
        return res;
      } else {
        return res.join(", ");
      }
  }
}

export const extactToolDefaults = (orgSWMS) => {
  let arrayDefaults = []
  if (orgSWMS) {
      Object.keys(orgSWMS).forEach(key => {
          if (key === "ppes" || key === "high_risk_works") {
              arrayDefaults = [...arrayDefaults, ...orgSWMS[key].map(obj => {
                  switch (key) {
                      case "ppes":
                          return ({ value: `${key}|${obj.id}`, label: `PPE|${obj.name}` });
                      case "high_risk_works":
                          return ({ value: `${key}|${obj.id}`, label: ` High Risk Work|${obj.name}` });
                      default:
                          break;
                  }
              })]
          }
      })
  }
  return arrayDefaults
}