import React, { useState } from "react";
import { Upload, Icon, notification, Input } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { reduxForm, Field } from "redux-form";
import styles from "../../JobStyle.module.css"
import { customInput } from "../../../../common/custom-input";
import { addJobSiteTask, getJobSiteTasks } from "../../../../../actions/v2/JobActions";
import { validateAddTaskFile } from "../validate";
import { Strings } from '../../../../../dataProvider/localize';
import { CustomSingleSelect } from "../../../../common/new-core-ui/CustomSingleSelect";
import SelectV2SingleStyle from "../../SelectV2SingleStyle";
import { components } from "react-select";

const selectField = ({
  input, children,
  className = "",
  meta: { touched, error, warning }
}) => (
  <>
    <select {...input} className={className}>
      {children}
    </select>
    {touched && ((error && <span className="error-input">{error}</span>) || (warning && <span>{warning}</span>))}
  </>
)

const AddNewFile = (props) => {

  const dispatch = useDispatch();
  const tasks = useSelector(state => state.Job.parentTasks);
  const [files, setFiles] = useState([]);

  const onSubmit = (values, tasksArr) => {
    let formData = new FormData();

    if (!files || (files && files.length !== 1)) {
      notification.error({
        message: Strings.error_title,
        description: "Please select a file to upload.",
        className: "ant-error"
      })
    } else {
      Object.keys(values).forEach((key) => {
        if (key !== "task_label") {
          formData.append(key, values[key]);
        }
      });

      let task = tasks.find(t => t.task_label.toLowerCase() === values.task_label.value.toLowerCase())
      if (task) {
        formData.append("task_name", task.task_name);
        formData.append("task_id", task.task_id);
        formData.append("task_label", task.task_label);
        formData.append("area_name", task.area_name ? task.area_name : '');
        formData.append("job_id", props.jobId);
        formData.append("task_attachments", files[0].originFileObj);
        dispatch(addJobSiteTask(formData)).then((message) => {
          dispatch(getJobSiteTasks(props.jobId))
          props.reset();
          handleFileSelect([]);
          notification.success({
            message: Strings.success_title,
            description: message ? message : Strings.generic_error, onClick: () => { },
            className: 'ant-success'
          });
        }).catch(err => {
          notification.error({
            message: Strings.error_title,
            description: err ? err : Strings.generic_error,
            className: 'ant-error'
          })
        })
      } else {
        notification.error({
          message: Strings.error_title,
          description: Strings.generic_error,
          className: 'ant-error'
        })
      }
    }
  }

  const handleFileSelect = (files) => {
    setFiles(files);
  }

  return (
    <form onSubmit={props.handleSubmit((values => onSubmit(values, tasks)))}>
      <div className="container-fluid">
        <div className="row row align-items-start justify-content-between">
          <div className="col-3 col-md-3 px-0">
            <div className={styles.Label}>
              Select Task
            </div>
            <div style={{ width: 'webkit-fill-auto' }}>
              <Field
                name="task_label"
                component={CustomSingleSelect}
                clearable={false}
                styles={SelectV2SingleStyle}
                placeholder=""
                options={tasks.map((task) => task && task.task_label && ({ value: task.task_label, label: `${task.task_label} - ${task.task_name}` }))}
                otherProps={{
                  components: { dropdownIndicator: <components.DownChevron /> }
                }}
              />
            </div>
          </div>
          <div className="col-3 col-md-3 px-0">
            <div className={styles.Label}>
              Task Description
            </div>
            <div>
              <Field
                name="docs_description"
                component={customInput}
                autoComplete="off"
                className={styles.SingleLine}
              />
            </div>
          </div>
          <div className="col-3 col-md-3 px-0">
            <div className={styles.Label}>
              Task File
            </div>
            <div className="d-flex align-items-center justify-space-between">
              <Upload
                fileList={files}
                multiple={false}
                showUploadList={false}
                beforeUpload={() => { return false; }}
                accept='.jpeg,.jpg,.png,.pdf'
                className="w-100"
                onRemove={() => handleFileSelect([])}
                onChange={({ fileList }) => handleFileSelect([fileList.pop()])}>
                <div className="d-flex align-items-center">
                  <Icon rotate={135} type="paper-clip" />
                  <Input autoComplete="off" className={`${styles.SingleLine} w-100`} value={files.length > 0 ? files[0].name : ''} />
                  <Icon style={{ color: 'red', cursor: "pointer", visibility: files.length > 0 ? 'visible' : 'hidden' }} onClick={(e) => { e.stopPropagation(); handleFileSelect([]) }} type="delete" />
                </div>
              </Upload>
            </div>
          </div>
          <div className="col-3 col-md-1 px-0 pb-2 align-self-end">
            <button className={`${styles.PrimaryButton} ${styles.SmallPrimaryButton}`} type="submit" disabled={props.pristine}>Save</button>
          </div>
        </div>
      </div>
    </form >
  )
}

export default reduxForm({
  form: 'AddNewSiteFile',
  validate: validateAddTaskFile,
  enableReinitialize: true
})(AddNewFile)