import React from "react";
import { Menu, Dropdown, Icon, notification } from 'antd';
import { useDispatch } from "react-redux";
import { deleteTaskFile, getJobSiteTasks } from "../../../../../actions/v2/JobActions";
import { Strings } from "../../../../../dataProvider/localize";
import styles from "../../JobStyle.module.css";
import { downLoadDoc } from "../utils";

export default function TaskFile(props) {

  const dispatch = useDispatch();

  const handleClick = ({ key }) => {
    if (key === "delete") {
      dispatch(deleteTaskFile(props.attachment.attachment_id))
        .then((message) => {
          dispatch(getJobSiteTasks(props.attachment.job_id))
          notification.success({
            message: Strings.success_title,
            description: message ? message : Strings.generic_error, onClick: () => { },
            className: 'ant-success'
          });
        }).catch(err => {
          notification.error({
            message: Strings.error_title,
            description: err ? err : Strings.generic_error,
            className: 'ant-error'
          })
        })
    }
    if (key === "download") {
      downLoadDoc(props.attachment.attached_file);
    }
    if (key === "view") {
      const newWindow = window.open(props.attachment.attached_file, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
  }

  const menu = (
    <Menu onClick={handleClick} className="job-board-menu-dropdown">
      <Menu.Item key="view">
        <div className="d-flex align-items-center">
          <Icon type="eye" />
          <div className="px-2">View</div>
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="download">
        <div className="d-flex align-items-center">
          <Icon type="download" />
          <div className="px-2">Download Attachment</div>
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="delete">
        <div className="d-flex align-items-center">
          <Icon type="close" />
          <div className="px-2">Remove Attachment</div>
        </div>
      </Menu.Item>
    </Menu>
  )

  return <div className={`${styles.blankContainer} ${styles.bordered} py-3`}>
    <div className="container">
      <div className="row">
        <div className="col-4">
          <span className="font-weight-bold mr-1">{props.attachment && props.attachment.task_label && props.attachment.task_label}</span>
          {props.attachment && props.attachment.task_name ? `- ${props.attachment.task_name}` : ''}
        </div>
        <div className="col-4">
          <div className="font-weight-normal">
            {props.attachment && props.attachment.description && props.attachment.description}
          </div>
        </div>
        <div className="col-3 font-weight-normal">
          <Dropdown overlay={menu} trigger={['click']}>
            <div className="ant-dropdown-link" style={{ cursor: "pointer" }} onClick={e => { e.preventDefault() }}>
              Attachment <Icon type="down" />
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  </div >;
}
