import React, { Component } from 'react';
import makeAnimated from 'react-select/animated';
import CreatableSelect from 'react-select/creatable';
import './chipInputRedux.css'
import 'react-multi-email/style.css';

const createOption = (label) => ({
  label,
  value: label,
});

const animatedComponents = makeAnimated();
export class ReduxFormSelect extends Component {
  state = {
    inputValue: '',
    value: [],
  };
  handleChange = (value) => {
    this.setState({ value });
  };

  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
  };

  handleKeyDown = (event) => {
    const { inputValue, value } = this.state;
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        console.group('Value Added');
        console.log(value);
        console.groupEnd();
        this.setState({
          inputValue: '',
          value: [...value, createOption(inputValue)],
        });
        event.preventDefault();
        break;
      default:
    }
  };


  render() {
    const { input, label, options, meta: { touched, error, warning }, otherProps = {} } = this.props

    return (
      <div id="stickDrop" className={"sf-select select-wibg drop-mw myChipInput"}>
        {label && <label>{label}</label>}
        <CreatableSelect
          {...input}
          onChange={value => input.onChange(value)}
          onBlur={() => input.onBlur(input.value)}
          options={options}
          closeMenuOnSelect={false}
          placeholder={label}
          components={animatedComponents}
          isMulti
          {...otherProps}
        />
        {touched && ((error && <span className="error-input">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    )
  }
}

export class MultiEmailInput extends React.Component {
  state = {
    inputValue: '',
    value: [],
  };
  handleChange = (value) => {
    this.setState({ value });
  };

  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
  };

  handleKeyDown = (event) => {
    const { inputValue, value } = this.state;
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        console.group('Value Added');
        console.log(value);
        console.groupEnd();
        this.setState({
          inputValue: '',
          value: [...value, createOption(inputValue)],
        });
        event.preventDefault();
        break;
      default:
    }
  };

  render() {
    const { input, label, options, meta: { touched, error, warning }, isMulti = true, otherProps = {} } = this.props
    return (
      <div id="stickDrop" className={"sf-select select-wibg drop-mw myEmailInput"}>
        {label !== undefined &&
          <label>{label}</label>
        }
        <CreatableSelect
          {...input}
          className="hide-options"
          onChange={value => input.onChange(value)}
          onBlur={() => input.onBlur(input.value)}
          options={options}
          closeMenuOnSelect={false}

          placeholder={label}
          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          isMulti={isMulti}
          {...otherProps}
        />
        {touched && ((error && <span className="error-input">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    )
  }
}
