import React, { Component, StrictMode } from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { getStorage } from "../../../utils/common";
import { Strings } from "../../../dataProvider/localize";
import TaskFileViews from "../../../components/dashboard/job/taskFilesView";
import { reduxForm } from "redux-form";
import CloseIcon from '@mui/icons-material/Close';
import { validateSiteSpecific } from "./validate";
import * as SWMSMastersActions from "../../../actions/SWMSMastersActions";
import * as roleManagementActions from "../../../actions/roleManagementActions";
import {
  Input,
  Button,
  notification,
  Modal,
  Table,
  Collapse,
  Icon,
  Popconfirm,
  Checkbox
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import SiteSpecificSwmsForm from "./JobSwms/SiteSpecificSwmsForm";
import "../../../App.css";
import "./swmsmanagement.css";
import SwmsPreviewPageContainer from "./swmsPreviewPageContainer/SwmsPreviewPageContainer";
import moment from "moment";
import jobStyles from "../Job/JobStyle.module.css";
import styles from "./ReportsStyles.module.css";
import { IconButton } from "@mui/material";
import { CustomSingleSelect } from "../../common/new-core-ui/CustomSingleSelect";
import { colorStylesMulti } from "../Job/SelectV2SingleStyle";
import Select, { components } from "react-select";
import { ADMIN_DETAILS } from "../../../dataProvider/constant";

const { Panel } = Collapse;

class SWMSManagement extends Component {

  state = {
    consequences: [],
    selectedHazards: [],
    selectedCheckPpe: [],
    selectedCheckActivity: [],
    selectedCheckHRW: [],
    selectedCheckCHEM: [],
    selectedCheckLicensees: [],
    selectedCheckSpecific: [],
    viewTaskFiles: false,
    taskFiles: [],
    allStafs: [],
    selectedColumns: [],
    selectedCheckToolTypes: [],
    showColumn: [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        align: "left",
        width: 130,
        render: (text) => <a>{text ? moment(text).format("DD/MM/YYYY") : ''}</a>,
        fixed: "left",
      },
    ],
    onlyShowCol: [],
    data: [],
    swms: [],
    editingSiteSpecific: false,
    selectedSiteSpecific: {},
    previewSwms: false,
    swmsPdfLink: null,
  };

  downloadFile = (url) => {
    fetch(url).then((res) => {
      if (!res.ok) {
        notification.error({
          message: Strings.error_title,
          description: Strings.file_download_error,
          className: "ant-error",
        })
      } else {
        res.blob().then((blob) => {
          var file = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = file;
          let fileName = url.substring(url.lastIndexOf('/') + 1).toString();
          a.download = fileName;
          a.click();
          a.remove();
        })
      }
    }).catch(() => {
      notification.error({
        message: Strings.error_title,
        description: Strings.file_download_error,
        className: "ant-error",
      })
    })
  }


  handleSwmsCategoryCheck = (swms) => {
    let { selectedCheckActivity } = this.state;
    if (this.isSWMSCategoryChecked(swms)) {
      swms.activities.forEach((activity) => {
        var index = selectedCheckActivity.findIndex((id) => id === activity.id);
        if (index !== -1) {
          selectedCheckActivity.splice(index, 1);
        }
      });
    } else {
      swms.activities.forEach((activity) => {
        var index = selectedCheckActivity.findIndex((id) => id === activity.id);
        if (index === -1) {
          selectedCheckActivity.push(activity.id);
        }
      });
    }

    this.setState({ selectedCheckActivity });
  }

  isSWMSCategoryChecked = (category) => {
    let res = true;
    category.activities.forEach(activity => {
      if (res !== false && !this.state.selectedCheckActivity.includes(activity.id)) {
        res = false;
      }
    })
    return res;
  }

  updateSwmsSiteSpecific = (siteSpecificId, removeSiteSpecific = false) => {
    let siteSpecifics = [...this.state.selectedCheckSpecific];
    if (removeSiteSpecific === false) {
      siteSpecifics.push(siteSpecificId)
    } else {
      siteSpecifics = siteSpecifics.filter(s => s !== siteSpecificId)
    }
    this.props.actions.updateSwmsManagement(
      this.state,
      siteSpecifics,
      getStorage("JOB_ID"),
      this.props.job_swms_id,
      this.props.selectedJobDetails.accountManagerUsername
    )
      .then((res) => {
        this.successMessage(res);
        this.ApiCalls(this.props.location.state, this.props.selectedJobDetails.scopeId)
      })
      .catch(err => {
        this.errorMessage(err);
      })
  }

  deleteSwmsSiteSpecific = (siteSpecificId) => {
    if (this.state.selectedSiteSpecific && this.state.selectedSiteSpecific.id === siteSpecificId) {
      this.setState({ editingSiteSpecific: false, selectedSiteSpecific: {} })
    }
    this.props.actions.deleteSiteSpecificSwms({ id: siteSpecificId }).then(res => {
      this.successMessage(res);
      this.updateSwmsSiteSpecific(siteSpecificId, true)
    }).catch(err => {
      this.errorMessage(err);
    })
  }

  ////////////////for search drop down in sign off table

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          className={jobStyles.InputFieldV2}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <div>
          <Button
            type="primary"
            className={`${jobStyles.PrimaryButton} ${jobStyles.SmallPrimaryButton}`}
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }} className={jobStyles.FilterResetButton}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            className={`${jobStyles.PrimaryButton} ${jobStyles.SmallPrimaryButton}`}
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: "#362148" }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  downloadShiftSwms = (shiftDetails) => {
    this.props.actions.downloadShiftSwms(this.props.selectedJobDetails.id, shiftDetails.shift_Id).then((data) => {
      if (data.shift_swms_details && data.shift_swms_details.length > 0 && data.shift_swms_details[0].shift_swms_pdf_link) {
        this.downloadFile(data.shift_swms_details[0].shift_swms_pdf_link);
      } else {
        notification.warn({
          message: "Historical shift records not available.",
          className: "ant-warning",
        })
      }
    }).catch(err => {
      notification.error({
        message: Strings.error_title,
        description: err ? err : Strings.generic_error,
        className: "ant-error",
      })
    })
  }

  //////////for all  default columns
  columns = [];
  staticColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "left",
      width: 130,
      render: (text) => <span>{text ? moment(text).format("DD/MM/YYYY") : ''}</span>,
      fixed: "left",
    },
    {
      title: "Shift Id",
      dataIndex: "shift_Id",
      key: "shift_Id",
      align: "left",
      width: 130,
      ...this.getColumnSearchProps("shift_Id"),
      render: (cellContent, row) => (
        <button
          className={`${jobStyles.PrimaryTextButton} p-0`}
          style={{ height: "auto" }}
          onClick={() => this.downloadShiftSwms(row)}>
          {cellContent}
        </button>
      ),
    }
  ]
  componentDidMount() {
    this.ApiCalls(this.props.location.state, this.props.selectedJobDetails.scopeId);
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (this.props.activity && this.props.categories && prevProps.activity !== this.props.activity && prevProps.categories !== this.props.categories) {
      //sorting swms activities and categories into groups
      let swmsActivities = this.props.activity !== undefined ? this.props.activity : [];
      let swmsCategories = this.props.categories !== undefined ? this.props.categories : [];

      let swms = [];

      swmsCategories.map(category => {
        category.activities = swmsActivities.filter(activity => activity.swms_category_id === category.id)
        if (category.activities.length > 0) {
          swms.push(category);
        }
      })
      this.setState({ swms: swms })
    }
    return null;
  }

  editSiteSpecificSwms = (siteSpecificSwms) => {
    this.setState({ editingSiteSpecific: true, selectedSiteSpecific: siteSpecificSwms })
  }

  ApiCalls = (jobidparam, scopeDocId) => {
    let orgId = JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id;
    this.props.actions.getAllSWMS();
    this.props.actions
      .getOrgDetails(jobidparam, scopeDocId, this.props.jobHeader.quote_id,
        this.props.jobHeader.client_id, this.props.jobHeader.client_person_id, this.props.jobHeader.job_swms_id)
      .then((res) => {
        let ppeIds = res.ppes && res.ppes.length
          ? res.ppes.filter(val => val.selected).map((val) => val.id)
          : [];

        let activityIds = res.swms && res.swms.length
          ? res.swms.map((val) => val.id)
          : [];

        let hrwIds = res.high_risk_works && res.high_risk_works.length
          ? res.high_risk_works.filter(val => val.selected).map((val) => val.id)
          : [];

        let chemicalIds = res.chemicals && res.chemicals.length
          ? res.chemicals.map((val) => val.id)
          : [];

        let toolTypeIds = res.tool_types && res.tool_types.length
          ? res.tool_types.map((val) => val.id)
          : [];

        let licenseIds = res.licenses && res.licenses.length
          ? res.licenses.map((val) => val.id)
          : [];

        let sitespecificIds = res.site_specific_swms && res.site_specific_swms.length
          ? res.site_specific_swms.map((val) => val.id)
          : [];

        this.setState({
          ...this.state,
          selectedCheckPpe: ppeIds,
          selectedCheckActivity: activityIds,
          selectedCheckCHEM: chemicalIds,
          selectedCheckToolTypes: toolTypeIds,
          selectedCheckHRW: hrwIds,
          selectedCheckLicensees: licenseIds,
          selectedCheckSpecific: sitespecificIds,
        });
      });
    this.props.actions.getSwmsLicensees().then((res) => { });
    this.props.roleManagementActions.getRoles(orgId).then((res) => { });
    this.props.actions.getSignHistory(jobidparam).then((res) => {
      let data = [];
      let column = [];

      res && res.staff && res.staff.map((val) => {
        column.push({
          title: val.staff_name,
          key: val.user_name,
          dataIndex: "staff",
          align: "center",
          render: (staff) => {
            let staffIndex = staff.findIndex(s => s.user_name === val.user_name)
            let selectedStaff = staffIndex !== -1 ? staff[staffIndex] : null
            return (
              <>
                <div className="text-center">
                  {selectedStaff !== null ? (selectedStaff.swms_completed === 1 ?
                    (
                      <Button
                        type="success"
                        shape="circle"
                        size="small"
                        style={{
                          background: "#19933e",
                          border: "1px solid grey",
                          minWidth: "20px",
                          height: "20px",
                          width: "20px"
                        }}
                      />
                    ) : (
                      <Button
                        type="warning"
                        shape="circle"
                        size="small"
                        style={{
                          background: "rgb(253 245 61)",
                          border: "1px solid grey",
                          minWidth: "20px",
                          height: "20px",
                          width: "20px"
                        }}
                      />
                    )
                  ) : (
                    <Button
                      type="warning"
                      shape="circle"
                      size="small"
                      style={{
                        background: "transparent",
                        border: "1px solid grey",
                        minWidth: "20px",
                        height: "20px",
                        width: "20px"
                      }}
                    />
                  )}
                </div>
              </>
            )
          },
        });
      });
      this.columns = [...this.staticColumns, ...column];

      res.history && res.history.map((val) => {

        let staff = val.staffs.map((staff) => {
          return staff;
        });

        data.push({
          key: val.job_shift_id,
          date: val.shift_date,
          shift_Id: val.job_shift_id,
          swms: val.swms_pdf_file,
          staff: staff,
        });
      });

      this.setState({ data: data, allStafs: res.staff });
    });
    const promises = [];

    for (let i = 1; i <= 7; i++) {
      this.props.actions
        .getSWMS(i)
        .then(() => { })
        .catch((err) => { });
    }
    promises.push(
      this.props.actions
        .getAllOrgSWMS()
        .then(() => { })
        .catch((err) => { })
    );
    promises.push(this.props.actions.getAllLikelihoodBeforeControl());
    promises.push(this.props.actions.getAllBeforeConsequences());
    Promise.all(promises).catch((err) => console.log(err));
  };

  historyColumnHide = (e) => {
    let oldColumn = [...this.state.showColumn];
    oldColumn.push(
      {
        title: "Shift Id",
        dataIndex: "shift_Id",
        key: "shift_Id",
        align: "left",
        width: 130,
        ...this.getColumnSearchProps("shift_Id"),
        render: (cellContent, row) => (
          <button
            className="btn btn-link p-0"
            style={{ height: "auto" }}
            onClick={() => this.downloadShiftSwms(row)}>
            {cellContent}
          </button>
        ),
      },
    )
    if (e && e.length) {
      e.map((column) => {

        oldColumn.push({
          title: column.value,
          key: column.key,
          dataIndex: "staff",
          align: "center",

          render: (staff) => {
            let staffIndex = staff.findIndex(s => s.user_name === column.key)
            let selectedStaff = staffIndex !== -1 ? staff[staffIndex] : null
            return (
              <>
                <div className="text-center">
                  {selectedStaff !== null ? (selectedStaff.swms_completed === 1 ?
                    (
                      <Button
                        type="success"
                        shape="circle"
                        size="small"
                        style={{
                          background: "#19933e",
                          border: "1px solid grey",
                        }}
                      />
                    ) : (
                      <Button
                        type="warning"
                        shape="circle"
                        size="small"
                        style={{
                          background: "rgb(253 245 61)",
                          border: "1px solid grey",
                        }}
                      />
                    )
                  ) : (
                    <Button
                      type="warning"
                      shape="circle"
                      size="small"
                      style={{
                        background: "transparent",
                        border: "1px solid grey",
                      }}
                    />
                  )}
                </div>
              </>
            )
          },
        });
      });
      this.setState({
        ...this.state,
        selectedColumns: e,

        onlyShowCol: oldColumn,
      });
    } else {
      this.setState({
        ...this.state,
        selectedColumns: e,
      });
    }
  };

  successMessage = (msg) => {
    return notification.success({
      message: Strings.success_title,
      description: msg,
      onClick: () => { },
      className: "ant-success",
    });
  };

  errorMessage = (msg) => {
    return notification.error({
      message: Strings.error_title,
      description: msg,
      onClick: () => { },
      className: "ant-error",
    });
  };

  selectCheck = (e) => {
    const name = e.target.name;
    const val = e.target.value;
    switch (name) {
      case "ppe":
        {
          const selectedCheckPpeNew = [...this.state.selectedCheckPpe];
          var index = selectedCheckPpeNew.findIndex((x) => x == parseInt(val));
          if (index == -1) {
            selectedCheckPpeNew.push(parseInt(val));
            this.setState({
              ...this.state,
              selectedCheckPpe: selectedCheckPpeNew,
            });
          } else if (selectedCheckPpeNew) {
            if (index > -1) {
              selectedCheckPpeNew.splice(index, 1);
              this.setState({
                ...this.state,
                selectedCheckPpe: selectedCheckPpeNew,
              });
            }
          }
        }

        break;
      case "activity":
        {
          const selectedCheckActivityNew = [
            ...this.state.selectedCheckActivity,
          ];
          var index = selectedCheckActivityNew.findIndex(
            (x) => x == parseInt(val)
          );
          if (index == -1) {
            selectedCheckActivityNew.push(parseInt(val));
            this.setState({
              ...this.state,
              selectedCheckActivity: selectedCheckActivityNew,
            });
          } else if (selectedCheckActivityNew) {
            if (index > -1) {
              selectedCheckActivityNew.splice(index, 1);
              this.setState({
                ...this.state,
                selectedCheckActivity: selectedCheckActivityNew,
              });
            }
          }
        }

        break;
      case "highRiskWork":
        {
          const selectedCheckHRWNew = [...this.state.selectedCheckHRW];
          var index = selectedCheckHRWNew.findIndex((x) => x == parseInt(val));
          if (index == -1) {
            selectedCheckHRWNew.push(parseInt(val));
            this.setState({
              ...this.state,
              selectedCheckHRW: selectedCheckHRWNew,
            });
          } else if (selectedCheckHRWNew) {
            if (index > -1) {
              selectedCheckHRWNew.splice(index, 1);
              this.setState({
                ...this.state,
                selectedCheckHRW: selectedCheckHRWNew,
              });
            }
          }
        }

        break;
      case "toolTypes":
        {
          const selectedCheckToolTypesNew = [...this.state.selectedCheckToolTypes];
          var index = selectedCheckToolTypesNew.findIndex(
            (x) => x == parseInt(val)
          );
          if (index == -1) {
            selectedCheckToolTypesNew.push(parseInt(val));
            this.setState({
              ...this.state,
              selectedCheckToolTypes: selectedCheckToolTypesNew,
            });
          } else if (selectedCheckToolTypesNew) {
            if (index > -1) {
              selectedCheckToolTypesNew.splice(index, 1);
              this.setState({
                ...this.state,
                selectedCheckToolTypes: selectedCheckToolTypesNew,
              });
            }
          }
        }

        break;
      case "chemicals":
        {
          const selectedCheckChemicalsNew = [...this.state.selectedCheckCHEM];
          var index = selectedCheckChemicalsNew.findIndex(
            (x) => x == parseInt(val)
          );
          if (index == -1) {
            selectedCheckChemicalsNew.push(parseInt(val));
            this.setState({
              ...this.state,
              selectedCheckCHEM: selectedCheckChemicalsNew,
            });
          } else if (selectedCheckChemicalsNew) {
            if (index > -1) {
              selectedCheckChemicalsNew.splice(index, 1);
              this.setState({
                ...this.state,
                selectedCheckCHEM: selectedCheckChemicalsNew,
              });
            }
          }
        }

        break;

      case "licensees":
        {
          const selectedCheckLicenseesNew = [
            ...this.state.selectedCheckLicensees,
          ];
          var index = selectedCheckLicenseesNew.findIndex(
            (x) => x == parseInt(val)
          );
          if (index == -1) {
            selectedCheckLicenseesNew.push(parseInt(val));
            this.setState({
              ...this.state,
              selectedCheckLicensees: selectedCheckLicenseesNew,
            });
          } else if (selectedCheckLicenseesNew) {
            if (index > -1) {
              selectedCheckLicenseesNew.splice(index, 1);
              this.setState({
                ...this.state,
                selectedCheckLicensees: selectedCheckLicenseesNew,
              });
            }
          }
        }

        break;

      case "site_specific_swms":
        {
          const selectedCheckSpecificNew = [
            ...this.state.selectedCheckSpecific,
          ];
          var index = selectedCheckSpecificNew.findIndex(
            (x) => x == parseInt(val)
          );
          if (index == -1) {
            selectedCheckSpecificNew.push(parseInt(val));
            this.setState({
              ...this.state,
              selectedCheckSpecific: selectedCheckSpecificNew,
            });
          } else if (selectedCheckSpecificNew) {
            if (index > -1) {
              selectedCheckSpecificNew.splice(index, 1);
              this.setState({
                ...this.state,
                selectedCheckSpecific: selectedCheckSpecificNew,
              });
            }
          }
        }

        break;

      default:
        break;
    }
  };

  ///////////////////preview page swms
  showSwmsPreview = () => {
    this.setState({ previewSwms: true })
  };

  cancelPreviewSwms = () => {
    this.setState({ previewSwms: false, swmsPdfLink: null })
  }

  ////////////////task view

  handleTaskFileView = (files, e) => {
    e.stopPropagation();
    if (files && files.length) {
      this.setState({
        viewTaskFiles: true,
        taskFiles: JSON.parse(files),
      });
    }
  };

  handleCancel = () => {
    this.setState({
      viewTaskFiles: false,
      taskFiles: [],
    });
  };

  onSubmit = (formData) => {
    this.props.actions
      .updateSwmsManagement(
        this.state,
        this.state.selectedCheckSpecific,
        getStorage("JOB_ID"),
        this.props.job_swms_id,
        this.props.swms_revision,
        this.props.selectedJobDetails.accountManagerUsername
      )
      .then((res) => {
        this.successMessage(res);
        // just to make the SWMS Available after Update.
        this.props.actions.getOrgDetailsPreview(this.props.location.state, this.props.selectedJobDetails.quoteId, this.props.job_swms_id).then(() => { }).catch(err => { })
        this.ApiCalls(this.props.location.state, this.props.selectedJobDetails.scopeId);
      })
      .catch((message) => {
        notification.error({
          message: Strings.error_title,
          description: message,
          onClick: () => { },
          className: "ant-error",
        });
      });
  };

  emailToClient = (e, pdfLink = null) => {
    this.props.history.push({
      pathname: this.props.match.params.jobId ? this.props.location.pathname + "/email-swms-report" : "./emailSwmsReport",
      state: {
        jobId: this.props.location.state,
        pdfFileLink: pdfLink,
        scopeId: this.props.selectedJobDetails.scopeId,
        quoteId: this.props.selectedJobDetails.quoteId,
        jobSwmsId: this.props.jobSwmsId
      },
    });
  };


  render() {
    const {
      handleSubmit,
      likelihoodBefore,
      consequencesBefore,
      chemicals,
      toolTypes,
      ppe,
      licensees,
      site_specific_swms,
      highRiskWork,
      orgDetails,
      clientDetails,
      jobDetails,
      sites,
      roles,
    } = this.props;

    return (
      <div className="mx-3">
        <div className="">
          <form onSubmit={handleSubmit(this.onSubmit)} className="antt-form">
            <div>
              <div className={jobStyles.SectionHeading}>Job Details</div>
              <div className={jobStyles.card}>
                {sites && Object.keys(sites).length
                  ? (
                    <div className="container-fluid">
                      {sites && sites.tasks && sites.tasks.map((task) => {
                        return (
                          <div className="row" key={task.id}>
                            <div className="col-4 pl-0">
                              <div>
                                <span className={styles.Title}>
                                  Site Name: {" "}
                                </span>
                                <span className={styles.Content}>{sites.site_name}</span>
                              </div>
                              <div className="d-flex" style={{ columnGap: "5px" }}>
                                <div className={styles.Title}>Task:</div>
                                <div className={styles.Content}>{task.task_name} , {task.job_task_label}</div>
                              </div>
                            </div>
                            <div className="col-4 pl-0">
                              <div>
                                <span className={styles.Title}>
                                  Site Address: {" "}
                                </span>
                                <span className={styles.Content}>
                                  {sites.site_address}
                                </span>
                              </div>
                              <div className="d-flex" style={{ columnGap: "5px" }}>
                                <div className={styles.Title}>Area:</div>
                                <div className={styles.Content}>{task.area_name}</div>
                              </div>
                            </div>
                            <div className="col-4 pl-0">
                              <div className={styles.Title}>Note: {" "}</div>
                              <div className={styles.CommonLabel} style={{ textAlign: "left" }}>
                                {task.note}
                              </div>
                            </div>

                            {task.file && JSON.parse(task.file).length ? (
                              <div
                                className="doc-sr-img"
                                onClick={(evt) =>
                                  this.handleTaskFileView(
                                    task.file,
                                    evt
                                  )
                                }
                              >
                                {task.file.length > 1 ? (
                                  task.file.length > 9 ? (
                                    <span className="sl-no-img">
                                      <i className="material-icons">
                                        filter_9_plus
                                      </i>
                                    </span>
                                  ) : (
                                    <span className="sl-no-img">
                                      <i className="material-icons">{`filter_${task.file.length}`}</i>
                                    </span>
                                  )
                                ) : null}
                                <img
                                  className="area-img"
                                  alt="taskImage"
                                  src={
                                    task.file.length
                                      ? task.file[0]
                                      : ""
                                  }
                                />
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  ) : " "}
              </div>
            </div>


            <div className="mt-2">
              <div className={styles.Title}>
                SWMS Management for JOB
              </div>
              <div className={styles.TabsCover}>
                <div aria-labelledby="headingOne" data-parent="#accordionExample">
                  <div className="sec-add-staff ">
                    <div className={styles.Title}>Activities</div>
                    <div className={`${styles.TabsCover} pb-0`}>
                      <div>
                        <div className="container-fluid px-1 mt-1">
                          <td className="row ml-0">
                            {this.state.swms && this.state.swms.map(swms => (
                              <div className="col-lg-4 pb-3 pl-0" key={swms.id}>
                                <Collapse
                                  bordered={false}
                                  expandIconPosition="right"
                                  className="custom-section-v2-collapse"
                                  expandIcon={({ isActive }) => (
                                    <Icon
                                      style={{ color: "#2f1f41" }}
                                      type="caret-right"
                                      rotate={isActive ? 90 : 0}
                                    />
                                  )}
                                >
                                  <Panel
                                    style={{ border: '0px !important' }}
                                    header={
                                      <div className={`${jobStyles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>
                                        <div>
                                          <Checkbox
                                            name="activity"
                                            checked={this.isSWMSCategoryChecked(swms)}
                                            onClick={(e) => { e.stopPropagation(); }}
                                            value={swms.id}
                                            onChange={() => { this.handleSwmsCategoryCheck(swms); }}
                                            className="cs-select-v2">
                                            {swms.category}
                                          </Checkbox>
                                        </div>
                                      </div>}
                                    className={`custom-swms-category-header ${jobStyles.card}`}>
                                    {swms.activities && swms.activities.sort((a, b) => a.activity_order - b.activity_order).map(activity => (
                                      <div>
                                        <Checkbox
                                          name="activity"
                                          key={activity.id}
                                          checked={this.state.selectedCheckActivity.includes(activity.id)}
                                          value={activity.id}
                                          onChange={(e) => this.selectCheck(e)}
                                          className="cs-select-v2">
                                          {activity.activity}
                                        </Checkbox>
                                      </div>
                                    ))}
                                  </Panel>
                                </Collapse>
                              </div>
                            ))}
                          </td>
                        </div>
                      </div>
                    </div>

                    <Collapse
                      accordion
                      bordered={false}
                      expandIconPosition="right"
                      style={{ marginTop: "10px" }}
                      className="custom-section-v2-collapse"
                      expandIcon={({ isActive }) => (
                        <Icon type="caret-right" rotate={isActive ? 90 : 0} style={{ color: "#2f1f41" }} />)
                      }>
                      <Panel
                        style={{ border: '0px !important' }}
                        header={
                          <div className={`${jobStyles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>PPE Required</div>
                        }>
                        <div className="container-fluid">
                          <div className="row">
                            {ppe
                              ? ppe.map((val) => {
                                return (
                                  <div className="col-lg-4 pl-0" key={val.id}>
                                    <Checkbox
                                      name="ppe"
                                      checked={this.state.selectedCheckPpe.includes(val.id)}
                                      value={val.id}
                                      className="cs-select-v2"
                                      onChange={(e) => this.selectCheck(e)}
                                    > {val.name}</Checkbox>
                                  </div>
                                )

                              }) : ""}
                          </div>
                        </div>
                      </Panel>
                      <Panel
                        style={{ border: '0px !important', marginTop: "10px" }}
                        header={
                          <div className={`${jobStyles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>High Risk Work Involves</div>
                        }>
                        <div className="container-fluid">
                          <div className="row">
                            {highRiskWork
                              ? highRiskWork.map((val) => {
                                return (
                                  <div className="col-lg-4 pl-0" key={val.id}>
                                    <Checkbox
                                      name="highRiskWork"
                                      checked={this.state.selectedCheckHRW.includes(val.id)}
                                      value={val.id}
                                      className="cs-select-v2"
                                      onChange={(e) => this.selectCheck(e)}
                                    > {val.name}</Checkbox>
                                  </div>
                                )
                              }) : ""}
                          </div>
                        </div>
                      </Panel>
                      <Panel
                        style={{ border: '0px !important', marginTop: "10px" }}
                        header={
                          <div className={`${jobStyles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>Tool Types</div>
                        }>
                        <div className="container-fluid">
                          <div className="row">
                            {toolTypes
                              ? toolTypes.map((val) => {
                                return (
                                  <div className="col-lg-4 pl-0" key={val.id}>
                                    <Checkbox
                                      name="toolTypes"
                                      checked={this.state.selectedCheckToolTypes.includes(val.id)}
                                      value={val.id}
                                      className="cs-select-v2"
                                      onChange={(e) => this.selectCheck(e)}
                                    > {val.name}</Checkbox>
                                  </div>
                                )
                              }) : ""}
                          </div>
                        </div>
                      </Panel>
                      <Panel
                        style={{ border: '0px !important', marginTop: "10px" }}
                        header={
                          <div className={`${jobStyles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>Chemicals</div>
                        }>
                        <div className="container-fluid">
                          <div className="row">
                            {chemicals
                              ? chemicals.map((val) => {
                                return (
                                  <div className="col-lg-4 pl-0" key={val.id}>
                                    <Checkbox
                                      name="chemicals"
                                      checked={this.state.selectedCheckCHEM.includes(val.id)}
                                      value={val.id}
                                      className="cs-select-v2"
                                      onChange={(e) => this.selectCheck(e)}
                                    > {val.name}</Checkbox>
                                  </div>
                                )
                              }) : ""}
                          </div>
                        </div>
                      </Panel>
                      <Panel
                        style={{ border: '0px !important', marginTop: "10px" }}
                        header={
                          <div className={`${jobStyles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>Licenses</div>
                        }>
                        <div className="container-fluid">
                          <div className="row">
                            {licensees
                              ? licensees.map((val) => {
                                return (
                                  <div className="col-lg-4 pl-0" key={val.id}>
                                    <Checkbox
                                      name="licensees"
                                      checked={this.state.selectedCheckLicensees.includes(val.id)}
                                      value={val.id}
                                      className="cs-select-v2"
                                      onChange={(e) => this.selectCheck(e)}
                                    > {val.title}</Checkbox>
                                  </div>
                                )
                              }) : ""}
                          </div>
                        </div>
                      </Panel>
                      {site_specific_swms && site_specific_swms.length > 0 ? (
                        <Panel
                          style={{ border: '0px !important', marginTop: "10px" }}
                          header={
                            <div className={`${jobStyles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>Site Specific SWMS</div>
                          }>
                          <div className="container-fluid">
                            <div className="row">
                              {site_specific_swms
                                ? site_specific_swms.map((val) => {
                                  return (
                                    <div className="col-lg-4 pl-0" key={val.id}>
                                      <div className="d-flex justify-content-between">
                                        <div style={{ flex: 1 }}>
                                          <Checkbox
                                            name="site_specific_swms"
                                            checked={this.state.selectedCheckSpecific.includes(val.id)}
                                            value={val.id}
                                            className="cs-select-v2"
                                            onChange={(e) => this.selectCheck(e)}
                                          >
                                            {val.activity}
                                          </Checkbox>
                                        </div>
                                        <div>
                                          <IconButton onClick={() => this.editSiteSpecificSwms(val)} className={jobStyles.MuiIconButton}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height={14} width={14} style={{ color: "#90bf00" }}>
                                              <path fill="currentColor" d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                            </svg>
                                          </IconButton>
                                          <Popconfirm
                                            title="Are you sure to delete this Site Specific SWMS?"
                                            onConfirm={() => this.deleteSwmsSiteSpecific(val.id)}
                                            okText="Yes"
                                            cancelText="No"
                                          >
                                            <IconButton className={jobStyles.MuiIconButton} style={{ marginLeft: "5px" }}>
                                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height={14} width={14} style={{ color: "#d2d2d2" }}>
                                                <path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                                              </svg>
                                            </IconButton>
                                          </Popconfirm>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                                : " "}
                            </div>
                          </div>
                        </Panel>
                      ) : ""}
                    </Collapse>

                  </div>
                </div>

                <div className="mt-2">
                  <div className={styles.TabsCover}>
                    <SiteSpecificSwmsForm
                      roles={roles}
                      consequencesBefore={consequencesBefore}
                      likelihoodBefore={likelihoodBefore}
                      editingSiteSpecific={this.state.editingSiteSpecific}
                      selectedSiteSpecific={this.state.selectedSiteSpecific}
                      jobId={this.props.jobDetails.job_id}
                      onReset={() => { this.setState({ editingSiteSpecific: false, selectedSiteSpecific: {} }) }}
                      onNewSwmsAdded={(newSiteSpecificId) => { this.updateSwmsSiteSpecific(newSiteSpecificId) }}
                      reloadSwms={() => { this.ApiCalls(this.props.location.state, this.props.selectedJobDetails.scopeId); }}
                      initialValues={this.state.editingSiteSpecific ? this.state.selectedSiteSpecific : {}}
                    />
                  </div>
                </div>
              </div>
            </div>
            {
              this.state.previewSwms &&
              <Modal
                title=""
                visible={this.state.previewSwms}
                centered
                footer={null}
                onCancel={this.cancelPreviewSwms}
                width="auto"
                style={{ minWidth: "60vw", minHeight: "80vh" }}
                className="cs-modal-v2"
                closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
              >
                <StrictMode>
                  <SwmsPreviewPageContainer
                    onSendEmail={() => this.emailToClient()}
                    scopeId={this.props.selectedJobDetails.scopeId}
                    jobId={this.props.jobDetails.job_id}
                    quoteId={this.props.selectedJobDetails.quoteId}
                    jobSwmsId={this.props.job_swms_id}
                  />
                </StrictMode>
              </Modal>
            }

            {/* task file view  */}

            <Modal
              visible={this.state.viewTaskFiles}
              className="job-img-gallery"
              zIndex="99999"
              footer={null}
              onCancel={this.handleCancel}
            >
              <TaskFileViews taskFiles={this.state.taskFiles} />
            </Modal>

            <div className="mt-3 mb-5 d-flex align-items-center justify-content-end" style={{ columnGap: "10px" }}>
              <button
                type="button" onClick={() => this.showSwmsPreview()}
                className={jobStyles.PrimaryButton}
              >
                <span>Preview</span>
              </button>
              <button
                type="submit"
                className={jobStyles.PrimaryButton}
              >
                <span>Update SWMS</span>
              </button>
            </div>
          </form >

          <div className="p-2 mb-3">
            <div className={jobStyles.card}>
              <div className="my-1">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className={`${jobStyles.SectionHeading} mb-0`}>Sign History</h4>
                  <div className="select-history">
                    <Select
                      isMulti
                      placeholder="Select Staff"
                      options={
                        this.state.allStafs &&
                        this.state.allStafs.map((val) => {
                          return { value: val.staff_name, label: val.staff_name, key: val.user_name };
                        })
                      }
                      styles={colorStylesMulti}
                      onChange={(e) => this.historyColumnHide(e)}
                      components={{ dropdownIndicator: <components.DownChevron /> }}
                    />
                  </div>
                </div>
                <div className="w-100">
                  <Table
                    columns={
                      this.state.selectedColumns.length
                        ? this.state.onlyShowCol
                        : this.columns
                    }
                    dataSource={this.state.data}
                    scroll={{ x: "auto" }}
                    className="staff-table swms-table-v2"
                    pagination={{ className: "cs-pagination-v2" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    );
  }
}

const customStyles = {
  option: (provided, state) => ({
    ...provided,

    textAlign: "center",
    color: state.isSelected ? "red" : "blue",
  }),
};

const mapStateToProps = (state) => {
  return {
    formValues:
      state.form && state.form.SWMSManagement
        ? state.form.SWMSManagement.values
        : {},
    orgDetails: state.SWMSMasters.orgDetails,
    clientDetails: state.SWMSMasters.clientDetails,
    jobDetails: state.SWMSMasters.jobDetails,
    job_swms_id: state.SWMSMasters.job_swms_id,
    swms_revision: state.SWMSMasters.swms_revision,
    swms_number: state.SWMSMasters.swms_number,
    sites: state.SWMSMasters.sites,
    signHistory: state.SWMSMasters.signHistory,
    historyStaff: state.SWMSMasters.historyStaff,
    site_specific_swms: state.SWMSMasters.site_specific_swms,
    activity: state.SWMSMasters.activity,
    categories: state.SWMSMasters.category,
    ppe: state.SWMSMasters.ppe,
    chemicals: state.SWMSMasters.chemicals,
    toolTypes: state.SWMSMasters.tools,
    highRiskWork: state.SWMSMasters.highRiskWork,
    licensees: state.SWMSMasters.licensees,
    chemicalsCheck: state.SWMSMasters.chemicalsCheck,
    ppesCheck: state.SWMSMasters.ppesCheck,
    activityCheck: state.SWMSMasters.activityCheck,
    highRiskWorkCheck: state.SWMSMasters.highRiskWorkCheck,
    siteSpecificCheck: state.SWMSMasters.siteSpecificCheck,
    hazards: state.SWMSMasters.hazards,
    consequences: state.SWMSMasters.consequences,
    singleSWMSItems: state.SWMSMasters.singleSWMSItems,
    likelihoodBefore: state.SWMSMasters.likelihood_before,
    consequencesBefore: state.SWMSMasters.consequences_before,
    initialValues: state.SWMSMasters.initialValues,
    roles: state.roleManagement.roles,
    selectedJobDetails: state.JobCalendar.selectedJobDetails,
    jobHeader: state.Job.jobHeader
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(SWMSMastersActions, dispatch),
    roleManagementActions: bindActionCreators(roleManagementActions, dispatch),
  };
};

export default compose(
  withRouter,

  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "SWMSManagement",
    validateSiteSpecific,

    enableReinitialize: true,
  })
)(SWMSManagement);
