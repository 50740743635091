import React, { Component } from "react";
import styles from "./PreAllocateStyles.module.css";
import { Icon, notification, Upload, Menu } from "antd";
import { Field, FieldArray } from "redux-form";
import { CustomCheckbox } from "../../../../../components/common/customCheckbox";
import moment from "moment";
import { customInput } from "../../../../../components/common/custom-input";
import { CustomDatepicker } from "../../../../../components/common/customDatepicker";
import { Strings } from "../../../../../dataProvider/localize";
import { connect } from "react-redux";
import { validateNewLicenseData } from "../../utils";
import * as actions from "../../../../../actions/v2/JobDocumentActions";
import { bindActionCreators } from "redux";
import { getStorage } from "../../../../../utils/common";
import { ADMIN_DETAILS } from "../../../../../dataProvider/constant";
import { downLoadDoc } from "../../../Job/JobBoard/utils"
import jobStyles from "../../../Job/JobStyle.module.css";
import { IconButton } from "@mui/material";

const { Dragger } = Upload;

export class InsurancesTable extends Component {
  state = {
    uploadDocument: [],
  };

  handleDocumentUpload = (files, fieldIndex) => {
    if (files.length === 1) {
      if (files[0] && files[0].size && files[0].size < 4194304) {
        const newDocumentsList = [...this.state.uploadDocument];
        newDocumentsList[fieldIndex] = newDocumentsList[fieldIndex]
          ? [...newDocumentsList[fieldIndex], files]
          : files;
        this.setState({ uploadDocument: newDocumentsList });
      } else {
        notification.error({
          message: Strings.error_title,
          description: "Please select a file less than 4 MB.",
          className: "ant-error",
        });
      }
    } else {
      this.setState({ uploadDocument: files });
    }
  };

  hanldePreUpload = () => {
    return false;
  };

  uploadButton = (
    <div className={styles.PaddedCenterCell}>
      <i style={{ color: "#2ED47A" }} class="material-icons">
        file_upload
      </i>
      <div className="ant-upload-text">Upload Document</div>
    </div>
  );

  saveDocument = () => {
    const formValues = this.props.formValues[`new_licenses_${this.props.jobId}_${this.props.orgId}`][0];
    const errors = validateNewLicenseData(formValues);
    if (errors.length) {
      return notification.warning({
        message: Strings.warning_title,
        description: errors[0],
        onClick: () => { },
        className: "ant-warning",
      });
    }

    const formData = new FormData();
    Object.keys(formValues).forEach((key) => {
      key !== "file" && formData.append(key, formValues[key]);
    });
    formData.append("job_id", this.props.jobDetails.job_id);
    formData.append("document_files", formValues.file[0].originFileObj);
    formData.append("contractor_id", this.props.orgId)

    this.props.actions
      .saveAdditionalDocument(formData)
      .then(async (res) => {
        notification.success({
          message: Strings.success_title,
          description:
            res && res.message ? res.message : "Document added successfully",
          onClick: () => { },
          className: "ant-success",
        });
        this.setState({ uploadDocument: [] });
        this.props.change(`new_licenses_${this.props.jobId}_${this.props.orgId}`, []);
        const currentOrganization = JSON.parse(getStorage(ADMIN_DETAILS))
          ? JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id
          : null;

        await this.props.actions.getJobDocumentOrgCertificates(
          this.props.organisations && this.props.organisations.length ? JSON.stringify(this.props.organisations.map(org => org.id)) : [currentOrganization],
          this.props.jobDetails.job_id,
          this.props.organisations
        );
      })
      .catch((message) => {
        notification.error({
          message: Strings.error_title,
          description: message ? message : Strings.generic_error,
          onClick: () => { },
          className: "ant-error",
        });
      });
  };

  renderInsurances = ({ fields, meta: { error, submitFailed } }) => {
    return (
      <>
        {fields.map((insurance, index) => (
          <tr key={index}>
            <td style={{ width: "35%" }} className={styles.InsurName}>
              <div>
                <Field
                  placeholder="Enter Document Name"
                  name={`${insurance}.document_name`}
                  type="text"
                  component={customInput}
                  className={jobStyles.InputFormField}
                />
              </div>
            </td>
            <td>
              <Field
                placeholder="Enter Document Number"
                name={`${insurance}.document_number`}
                type="text"
                component={customInput}
                className={jobStyles.InputFormField}
              />
            </td>
            <td>
              <Field
                name={`${insurance}.start_date`}
                className="job-v2-date-picker"
                dropdownClassName="cs-datepicker-dropdown"
                component={CustomDatepicker}
                placeholder="Select Start Date" />
            </td>
            <td>
              <Field
                name={`${insurance}.end_date`}
                className="job-v2-date-picker"
                dropdownClassName="cs-datepicker-dropdown"
                component={CustomDatepicker}
                placeholder="Select Expiry Date" />
            </td>
            <td width="120" className={styles.Bottom}>
              <Dragger fileList={this.state.uploadDocument}
                onChange={({ fileList }) => {
                  this.handleDocumentUpload(fileList, index);
                  this.props.change(`new_licenses_${this.props.jobId}_${this.props.orgId}.${[index]}.file`, fileList);
                }}
                multiple={false} beforeUpload={() => { return false; }} style={{ height: 'auto !important' }} showUploadList={false}
              >
                {this.state.uploadDocument.length > 0 ? (
                  <div className="d-flex align-items-end justify-content-around">
                    <div className={styles.UploadContentName} style={{ width: "80px" }}>{this.state.uploadDocument[0][0].name ? this.state.uploadDocument[0][0].name : 'File Selected'}</div>
                    <Icon type="close" style={{ fontSize: "12px", margin: "2px 0px", alignSelf: "center" }} onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.setState({ uploadDocument: [] })
                      this.props.change(`new_licenses_${this.props.jobId}_${this.props.orgId}.${[index]}.file`, []);
                    }} />
                  </div>
                ) : (
                  <div className="d-flex align-itmes-center justify-content-around">
                    <Icon type="upload" style={{ fontSize: "16px", margin: "2px 0px" }} />
                    <div className="font-weight-bold" style={{ fontSize: "14px", padding: "0px 10px" }}>Drag & Drop</div>
                  </div>)}
              </Dragger>
            </td>
          </tr>
        ))}
        <tr>
          <td colSpan="5">
            {!fields.length ? (
              <button
                type="button"
                className={`${styles.AddNewBtn}`}
                onClick={() => fields.push({})}
              >
                <Icon type="plus" className={styles.PlusIcon} /> Add Document
              </button>
            ) : (
              <div className={styles.AddDocumentActions}>
                <button
                  type="button"
                  className={`${jobStyles.FilterResetButton}`}
                  onClick={() => fields.remove(0)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={`${jobStyles.PrimaryButton} ${jobStyles.SmallPrimaryButton}`}
                  onClick={this.saveDocument}
                >
                  Save
                </button>
              </div>
            )}
          </td>
        </tr>
      </>
    );
  };

  render() {
    const { onShowModal } = this.props;

    return (
      <div className="">
        <table className="table table-sm cs-table cs-table-v2 v2-table-no-last-border">
          {(this.props.licenses && this.props.licenses.length > 0) || (this.props.additionalDocs && this.props.additionalDocs.length > 0) ||
            (this.props.swmsDocument && Object.keys(this.props.swmsDocument).length > 0) ? (
            <thead>
              <tr>
                <th>Document Name</th>
                <th width="250">Document Number</th>
                <th width="250">Start Date</th>
                <th width="250">Expiry Date</th>
                <th width="150" className="text-center">
                  Actions
                </th>
              </tr>
            </thead>
          ) : ''}
          <tbody>
            {this.props.licenses.map((row) => (
              <tr key={`licenses_${row.id}`}>
                <td>
                  <Field
                    type="text"
                    name={`select_insurance_${row.id}`}
                    component={CustomCheckbox}
                    className="cs-select-v2"
                    label={row.name}
                  />
                </td>
                <td>{row.certificate_number}</td>
                <td className={styles.LabelGreen}>
                  {row.start_date ? moment(row.start_date).format("DD/MM/YYYY") : ''}
                </td>
                <td className={styles.LabelRed}>
                  {row.expiry_date ? moment(row.expiry_date).format("DD/MM/YYYY") : ''}
                </td>
                <td className="text-center">
                  {row.s3FileUrl ? (
                    <>
                      <IconButton onClick={() => row.s3FileUrl && row.file_name && onShowModal(row.s3FileUrl, row.s3FileUrl.endsWith(".pdf"))} className={`${jobStyles.MuiIconButton} ${jobStyles.GreenTextV2} mx-2`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style={{ height: "16px", width: "16px" }}>
                          <path fill="currentColor" d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                        </svg>
                      </IconButton>
                      <IconButton onClick={() => downLoadDoc(row.s3FileUrl)} className={`${jobStyles.MuiIconButton} ${jobStyles.PrimaryText} mx-2`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: "16px", width: "16px" }}>
                          <path fill="currentcolor" d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                        </svg>
                      </IconButton>
                    </>
                  ) : ""}
                </td>
              </tr>
            ))}
            {this.props.additionalDocs.map((row) => (
              <tr key={`select_additional_docs_${row.id}`}>
                <td>
                  <Field
                    type="text"
                    name={`select_additional_doc_${row.id}`}
                    component={CustomCheckbox}
                    className="cs-select-v2"
                    label={row.name}
                  />
                </td>
                <td>{row.certificate_number}</td>
                <td className={styles.LabelGreen}>
                  {row.start_date ? moment(row.start_date).format("DD/MM/YYYY") : ''}
                </td>
                <td className={styles.LabelRed}>
                  {row.expiry_date ? moment(row.expiry_date).format("DD/MM/YYYY") : ''}
                </td>
                <td className="text-center">
                  {row.s3FileUrl ? (
                    <>
                      <IconButton onClick={() => onShowModal(row.s3FileUrl, row.s3FileUrl.endsWith(".pdf"))} className={`${jobStyles.MuiIconButton} ${jobStyles.GreenTextV2} mx-2`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style={{ height: "16px", width: "16px" }}>
                          <path fill="currentColor" d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                        </svg>
                      </IconButton>
                      <IconButton onClick={() => downLoadDoc(row.s3FileUrl)} className={`${jobStyles.MuiIconButton} ${jobStyles.PrimaryText} mx-2`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: "16px", width: "16px" }}>
                          <path fill="currentcolor" d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                        </svg>
                      </IconButton>
                    </>
                  ) : ""}
                </td>
              </tr>
            ))}
            {this.props.swmsDocument.map((row) => (
              <tr key={`swms_document_${row.id}`}>
                <td>
                  <Field
                    type="text"
                    name={`select_swms_document_${row.id}`}
                    component={CustomCheckbox}
                    className="cs-select-v2"
                    label={row.name}
                  />
                </td>
                <td>{row.certificate_number}</td>
                <td className={styles.LabelGreen}>
                  {row.start_date ? moment(row.start_date).format("DD/MM/YYYY") : ''}
                </td>
                <td className={styles.LabelRed}>
                  {row.expiry_date ? moment(row.expiry_date).format("DD/MM/YYYY") : ''}
                </td>
                <td className="text-center">
                  {row.s3FileUrl ? (
                    <>
                      <IconButton onClick={() => onShowModal(row.s3FileUrl, row.s3FileUrl.endsWith(".pdf"))} className={`${jobStyles.MuiIconButton} ${jobStyles.GreenTextV2} mx-2`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style={{ height: "16px", width: "16px" }}>
                          <path fill="currentColor" d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                        </svg>
                      </IconButton>
                      <IconButton onClick={() => downLoadDoc(row.s3FileUrl)} className={`${jobStyles.MuiIconButton} ${jobStyles.PrimaryText} mx-2`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: "16px", width: "16px" }}>
                          <path fill="currentcolor" d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                        </svg>
                      </IconButton>
                    </>
                  ) : ""}
                </td>
              </tr>
            ))}

            <FieldArray name={`new_licenses_${this.props.jobId}_${this.props.orgId}`} component={this.renderInsurances} />
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  jobDetails: state.JobDocument.jobDetails,
  organisations: state.JobDocument.organisations
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsurancesTable);
