import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux"
import styles from "./SignOffStyles.module.css";
import jobStyles from "../JobStyle.module.css";

import { Icon, notification, Pagination } from "antd";
import { getSignOffHistory } from "../../../../actions/v2/TaskSignOffActions";
import { Strings } from '../../../../dataProvider/localize';
import LinearProgress from '@mui/material/LinearProgress';
import moment from "moment";
import { IconButton } from "@mui/material";

import PreviewModal from "./PreviewModal";

export default function SignOffHistory(props) {

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  const [previewFile, setPreviewFile] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    loadHistory(currentPage);
  }, []);

  useEffect(() => {
    if (props.refresh !== 0) {
      loadHistory();
    }
  }, [props.refresh])

  const loadHistory = (page = 1) => {
    setLoading(true);
    setCurrentPage(page);

    let jobId = props.jobId;

    dispatch(getSignOffHistory(jobId, page)).then((res) => {
      let signatures = res.job_sign_off_tasks && res.job_sign_off_tasks.rows ? res.job_sign_off_tasks.rows : [];
      let itemsCount = res.job_sign_off_tasks && res.job_sign_off_tasks.count ? res.job_sign_off_tasks.count : 0;
      setHistory(signatures);
      setTotalItems(itemsCount);
    }).catch(err => {
      notification.error({
        message: Strings.error_title,
        description: err ? err : Strings.generic_error,
        className: 'ant-error'
      })
    }).finally(() => setLoading(false))
  }

  const openFilePreview = (file) => {
    setPreviewFile(file);
    setShowModal(true);
  }

  const closePreview = () => {
    setShowModal(false);
    setPreviewFile(null)
  }

  return <div>
    <div className={`${jobStyles.SectionHeading}`}>Sign Off History</div>
    <div class={jobStyles.card}>
      <table class="table table-sm cs-table-job-board cs-table-job-board-v2">
        <thead>
          <tr>
            <th>Date / Time</th>
            <th>Sender</th>
            <th>Status</th>
            <th>Signed Off Date</th>
            <th className="text-center" width="70">Action</th>
          </tr>
        </thead>
        <tbody className="table-body">
          {history.map((sign, i) =>
            <tr key={`${i}`}>
              <td>{sign.created_at ? moment.utc(sign.created_at).format("DD-MM-YYYY | HH:mm") : ''}</td>
              <td>{sign.person_name ? sign.person_name : ''}</td>
              <td>{sign.status ? sign.status : ''}</td>
              <td>{sign.sign_off_date_time ? moment.utc(sign.sign_off_date_time).format("DD-MM-YYYY") : ''}</td>
              <td>
                {sign.sign_off_pdf_link &&
                  <div className="d-flex align-items-center justify-content-around">
                    <IconButton style={{ height: "auto", padding: "0px" }} onClick={() => openFilePreview(sign.sign_off_pdf_link)}>
                      <svg style={{ color: "#90BF00", height: "16px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                        <path fill="currentColor" d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                      </svg>
                    </IconButton>
                    <IconButton style={{ height: "auto", padding: "0px" }} onClick={() => window.open(sign.sign_off_pdf_link, '_blank')}>
                    <svg style={{ color:"#362148", height: "16px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path fill="currentColor" d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/>
                    </svg>
                    </IconButton>
                  </div>
                }
              </td>
            </tr>
          )}
          {!loading && history.length === 0 &&
            <tr>
              <td colSpan={5}>
                <div className="text-center my-2">No History Available.</div>
              </td>
            </tr>
          }
        </tbody>
      </table>
      {loading &&
        <div style={{ height: '4px' }}>
          <LinearProgress />
        </div>
      }
    </div>
    {totalItems > 10 &&
      <div className="my-3 text-right">
        <Pagination
          total={totalItems}
          onChange={(page) => loadHistory(page)}
          pageSize={10}
          current={currentPage}
          className="cs-pagination-v2"
        />
      </div>
    }
    {showModal && previewFile &&
      <PreviewModal file={previewFile} onCancel={closePreview} />
    }
  </div>
}