import React from "react";
import {
  Table,
  Icon,
  notification,
  Upload,
  Progress,
  Input
} from "antd";
import * as actions from "../../../actions/organizationUserAction";
import { Strings } from "../../../dataProvider/localize";
import { connect } from "react-redux";
import {
  ADMIN_DETAILS,
  ACCESS_CONTROL,
  ORG_USERS_URL,
  JWT_ACCESS_TOKEN,
  JWT_ID_TOKEN,
  JWT_REFRESH_TOKEN,
  ADMIN_ORG_ID,
  USER_NAME
} from "../../../dataProvider/constant";
import {
  getStorage,
  handleFocus,
} from "../../../utils/common";
import { reduxForm, Field, isDirty, FieldArray } from "redux-form";
import { compose, bindActionCreators } from "redux";
import { validate } from "../../../utils/Validations/organizationValidaton";
import { countryCodes } from "../../../dataProvider/countryCodes";
import { customInput } from "../../common/custom-input";
import * as rolesActions from "../../../actions/roleManagementActions";
import * as orgUserActions from "../../../actions/organizationUserAction";
import * as orgV2Actions from "../../../actions/v2/OrganizationActions";
import { BASE_API_URL } from "../../../dataProvider/env.config";
import moment from "moment";
import { ERROR_NOTIFICATION_KEY } from "../../../config";
import styles from "./UserManagementStyle.module.css";
import "./UserManagementStyle.css";
import PhoneDropdownStyle from "./PhoneDropdownStyles";
import { CustomSingleSelect } from "../../common/new-core-ui/CustomSingleSelect";
import colourStyles from "./FilterSelectUserStyles.js";
import { components } from 'react-select';

import ManagersFields from "./ManagersFields";
import v2Styles from "../../v2/v2Styles.module.css";

const Dragger = Upload.Dragger;
const { Search } = Input;

class InviteUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      responseData: "",
      fileUploaded: false,
      errorMessage: "",
      loaderPercent: 0,
      uploading: "",
      fileUploadStatus: "",
      page: 1,
      userSearch: ""
    };

    this.currentOrgId = JSON.parse(getStorage(ADMIN_DETAILS))
      ? JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id
      : null;

    this.currentOrganization =
      typeof this.props.location.state !== "object"
        ? this.props.location.state
        : JSON.parse(getStorage(ADMIN_DETAILS))
          ? JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id
          : null;

    this.props.rolesActions.getRoles(this.currentOrgId);
  }

  componentDidMount() {
    this.getOrgUsers(this.state.page)
  }

  getOrgUsers = (page = 1) => {
    let currentOrganization = (this.props.location.state && typeof this.props.location.state !== 'object') ? this.props.location.state :
      JSON.parse(getStorage(ADMIN_DETAILS)) ?
        JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id : null;
    if (this.state.userSearch && this.state.userSearch.length >= 1) {
      this.props.orgUserActions.getOrganizationUsers(currentOrganization, page, { search_key: this.state.userSearch });
    } else {
      this.props.orgUserActions.getOrganizationUsers(currentOrganization, page)
    }
    this.setState({ page: page });
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  errorMessage = (message) =>
    notification.error({
      message: Strings.error_title,
      description: message ? message : Strings.generic_error,
      onClick: () => { },
      className: "ant-error",
    });

  columns = [
    {
      title: Strings.user_table_name,
      dataIndex: "name",
    },
    {
      title: Strings.user_table_email,
      dataIndex: "email_address",
    },
    {
      title: Strings.user_table_phone,
      dataIndex: "phone_number",
    },
    {
      title: Strings.user_table_role,
      dataIndex: "role.name",
    },
    {
      title: Strings.user_table_sms,
      dataIndex: "smsCode",
      render: (user_name, user) => {
        if (Boolean(user.is_authorized) && !user.phone_verified) {
          return (
            <button
              className="bnt-simple-v2"
              onClick={() =>
                this.props.action
                  .resendAtrributeCode({
                    org_user_name: user_name,
                    attribute: "PHONE",
                  })
                  .then((message) => {
                    notification.success({
                      message: Strings.success_title,
                      description: message,
                      onClick: () => { },
                      className: "ant-success",
                    });
                    this.getOrgUsers(this.state.page)
                  })
                  .catch((message) => this.errorMessage(message))
              }
            >
              Resend SMS
            </button>
          );
        }
      },
    },
    {
      title: Strings.user_table_email_code,
      dataIndex: "emailCode",
      render: (user_name, user) => {
        if (Boolean(user.is_authorized) && !user.email_verified) {
          return (
            <button
              className="bnt-simple-v2"
              onClick={() =>
                this.props.action
                  .resendAtrributeCode({
                    org_user_name: user_name,
                    attribute: "EMAIL",
                  })
                  .then((message) => {
                    notification.success({
                      message: Strings.success_title,
                      description: message,
                      onClick: () => { },
                      className: "ant-success",
                    });
                    this.getOrgUsers(this.state.page)
                  })
                  .catch((message) => this.errorMessage(message))
              }
            >
              Resend Email
            </button>
          );
        }
      },
    },
  ];

  handleInviteClick = () => {
    if (!this.state.selectedRowKeys.length > 0) {
      notification.warning({
        message: Strings.warning_title,
        description: "Please select atleast one user to invite",
        onClick: () => { },
        className: "ant-warning",
      });
      return;
    }
    const inviteUsersIds = {
      id: this.state.selectedRowKeys,
      user_org_id: this.props.users[0].organisation_id,
    };
    this.props.orgUserActions
      .inviteUsers(inviteUsersIds)
      .then((message) => {
        this.setState({ selectedRowKeys: [] });
        notification.success({
          message: Strings.success_title,
          description: message,
          onClick: () => { },
          className: "ant-success",
        });
        this.getOrgUsers(this.state.page)
      })
      .catch((content) => {
        notification.error({
          message: Strings.error_title,
          description: content.message ? (
            <div>
              {content.message}
              <br />
              {content && content.invalidUsers &&
                content.invalidUsers.map((id) => (
                  <div>
                    <span>
                      {this.props.users.find((user) => user.user_name === id)
                        .name +
                        " " +
                        (this.props.users.find((user) => user.user_name === id)
                          .last_name
                          ? this.props.users.find(
                            (user) => user.user_name === id
                          ).last_name
                          : "")}
                    </span>
                    <br />
                  </div>
                ))}
            </div>
          ) : (
            Strings.generic_error
          ),
          onClick: () => { },
          className: "ant-error",
        });
      });
  };

  handleOrgUserSubmit = async (values) => {

    let finalForm = { org_users: [] };
    if (values.org_users) {
      values.org_users.forEach((user) => {
        user.phone_number = user.phone_number.replace(/\s/g, "");
        user.active = 0;
        finalForm.org_users.push({
          ...user,
          country_code: user.country_code.value,
          role_id: user.role_id.value,
          organisation_id: JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id,
          is_self_direct_manager: user.direct_manager && user.direct_manager.value === 0 ? 1 : 0,
          direct_manager: user.direct_manager && user.direct_manager.value === 0 ? null : user.direct_manager.value,
          is_self_compliance_manager: user.compliance_manager && user.compliance_manager.value === 0 ? 1 : 0,
          compliance_manager: user.compliance_manager && user.compliance_manager.value === 0 ? null : user.compliance_manager.value,
        });
      });

      this.props.orgV2Actions
        .updateInviteNewUser(finalForm)
        .then((message) => {
          this.props.change("org_users", []);
          this.getOrgUsers(this.state.page)
          notification.success({
            message: Strings.success_title,
            description: message,
            onClick: () => { },
            className: "ant-success",
          });
        })
        .catch((message) => {
          notification.error({
            message: "Error!",
            description: message
              ? message
              : "Something went wrong please try again later",
            onClick: () => { },
            className: "ant-error",
          });
        });
    }
  };

  renderMembers = ({ fields, meta: { error, submitFailed } }) => (
    <>
      <div className="btn-hs-icon sm-bnt bnt-error">
        <button
          className={`${styles.AddNewBtn}`}
          type="button"
          onClick={() => fields.push({ country_code: "+61", role_id: [] })}
        >
          {Strings.add_user_btn}
        </button>
        {submitFailed && error && <span className="error-input">{error}</span>}
      </div>
      <div className="container-fluid py-2">
        {fields.map((member, index) => (
          <div className="row py-2">
            <div className="col-11 px-0">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-3 pb-3">
                    <div className={v2Styles.Label}>Name</div>
                    <Field
                      name={`${member}.name`}
                      placeholder={Strings.name_org}
                      component={customInput}
                      className={v2Styles.TextField}
                    />
                  </div>
                  <div className="col-3 pb-3">
                    <div className={v2Styles.Label}>Email Address</div>
                    <Field
                      name={`${member}.email_address`}
                      placeholder={Strings.email_address_org}
                      component={customInput}
                      className={v2Styles.TextField}
                    />
                  </div>
                  <div className="col-3 pb-3">
                    <div className={v2Styles.Label}>Phone</div>
                    <div className="co-code-mobile-no">
                      <div className="d-flex w-100">
                        <div style={{ flex: "0 0 70px" }}>
                          <Field
                            name={`${member}.country_code`}
                            showSearch={1}
                            defaultValue="+61"
                            options={countryCodes.map((country) => ({
                              label: country.dial_code,
                              value: country.dial_code,
                            }))}
                            otherProps={{
                              styles: PhoneDropdownStyle, placeholder: "+00",
                            }}
                            component={CustomSingleSelect}
                          />
                        </div>
                        <div style={{ flex: "1 1 auto" }}>
                          <Field
                            name={`${member}.phone_number`}
                            placeholder={Strings.phone_number_org}
                            component={customInput}
                            className={v2Styles.TextField}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-3 pb-3">
                    <div className={v2Styles.Label}>Role</div>
                    <Field
                      name={`${member}.role_id`}
                      placeholder={Strings.user_role}
                      options={
                        this.props.roles
                          ? this.props.roles.map((role) => ({
                            label: role.name,
                            value: role.id,
                          }))
                          : []
                      }
                      styles={colourStyles}
                      component={CustomSingleSelect}
                      otherProps={{
                        components: { dropdownIndicator: <components.DownChevron /> },
                      }}
                    />
                  </div>
                  <ManagersFields
                    member={member}
                    orgId={this.currentOrgId}
                  />
                </div>
              </div>
            </div>
            <div className="col-1 pb-3">
              <button
                className="delete-bnt"
                type="button"
                onClick={() => fields.remove(index)}
              >
                <i class="fa fa-trash-o"></i>
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );

  handleChange = (info) => {
    if (info && info.file && info.file.name) {
      this.setState({ originalFileName: info.file.name });
    }
    this.setState({
      startDate: moment().format("MMM DD YYYY hh:mm:ss"),
      errorMessage: "",
    });
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    this.setState({ fileList: fileList });
    this.setState({
      loaderPercent: info.file.percent,
      uploading: info.file.status,
    });
    const status = info.file.status;

    if (status === "done") {
      this.setState({ endDate: moment().format("MMM DD YYYY hh:mm:ss") });
      if (info.file && info.file.response && info.file.response.data) {
        this.setState({
          fileUploadStatus: "Success",
          responseData: info.file.response.data,
          fileUploaded: true,
        });
      }

      this.getOrgUsers(this.state.page)

      notification.success({
        message: Strings.success_title,
        description: info.file.response.message
          ? info.file.response.message
          : Strings.success_title,
        onClick: () => { },
        className: "ant-success",
      });
    } else if (status === "error") {
      this.setState({
        endDate: moment().format("MMM DD YYYY hh:mm:ss"),
        errorMessage: "",
      });
      if (info.file.response) {
        if (info.file.response.data) {
          if (
            info.file.response.data.error_type == 1 &&
            info.file.response.data.invald_messages &&
            info.file.response.data.invald_messages.length > 0
          ) {
            this.setState({
              errorMessage: info.file.response.data.invald_messages[0].message,
            });
          }

          this.setState({
            fileUploadStatus: "Failed",
            responseData: info.file.response.data,
            fileUploaded: true,
          });

          var invalidMessages = (
            <table className="rap-cell-table add-user-table table">
              <tr>
                <th>{"Row Number"}</th>
                <th>{"Message"}</th>
                <th></th>
              </tr>
              {info.file.response.data.invald_messages && info.file.response.data.invald_messages.map((item) => (
                <tr key={item.line_no}>
                  <td>{item.line_no}</td>
                  <td>{item.message}</td>
                </tr>
              ))}
            </table>
          );
          if (info.file.response.data.error_type == 2) {
            notification.error({
              key: ERROR_NOTIFICATION_KEY,
              message: Strings.error_title,
              description: invalidMessages,
              onClick: () => { },
              className: "ant-error",
            });
          }
        }
      }
      this.getOrgUsers(this.state.page)
    }
  };

  render() {
    const { selectedRowKeys } = this.state;
    let { handleSubmit } = this.props;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: (record) => ({
        disabled: Boolean(record.org_invitation_sent),
        className: "cs-select-v2" // Column configuration not to be checked
      }),
    };
    const data = this.props.users.map((user) => {
      return {
        key: user.id,
        name: user.name + " " + (user.last_name ? user.last_name : ""),
        email_address: user.email_address,
        phone_number: `${user.country_code}${user.phone_number}`,
        "role.name": user.role.name,
        smsCode: user.user_name,
        emailCode: user.user_name,
        is_authorized: user.is_authorized,
        phone_verified: user.phone_verified,
        email_verified: user.email_verified,
        org_invitation_sent: user.org_invitation_sent,
      };
    });

    var accessControl = JSON.parse(getStorage(ACCESS_CONTROL))["userManagement"]
      .permissions;
    /**Permissions for the module */
    var permissions = {
      sf_org_bulk_user: accessControl.findIndex(
        (acess) => acess.control_name === "sf_org_bulk_user"
      ),
      sf_org_invite_users: accessControl.findIndex(
        (acess) => acess.control_name === "sf_org_invite_users"
      ),
      sf_org_list_users: accessControl.findIndex(
        (acess) => acess.control_name === "sf_org_list_users"
      ),
    };
    const props = {
      accept: ".csv",
      action: BASE_API_URL + ORG_USERS_URL,
      onChange: this.handleChange,
      name: "file",
      multiple: false,
      // data: { organisation_id: this.currentOrganization },
      data: { organisation_id: JSON.stringify(JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id) || getStorage(ADMIN_ORG_ID) },
      headers: {
        org_id: JSON.stringify(JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id) || getStorage(ADMIN_ORG_ID),
        refreshtoken: getStorage(JWT_REFRESH_TOKEN),
        accessid: getStorage(JWT_ID_TOKEN),
        accesstoken: getStorage(JWT_ACCESS_TOKEN),
        user_name: getStorage(USER_NAME)
      }
    };
    return (
      <>
        {/* <div className="dash-header">
          <h2 className="page-mn-hd">
            <Icon
              type="arrow-left"
              onClick={() =>
                // goBack(this.props)
                goBackBrowser(this.props)
              }
            />
            Invite Users
          </h2> 
        </div> */}


        <div className="cs-timesheets-container">
          <div className={styles.ContainerPage}>
            <div className={styles.PageBackground}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className={styles.PageHeading}>Invite Users</div>
                    <hr></hr>
                  </div>
                </div>
                <div className="row pt-2">
                  <div className="col-9">
                    <div>
                      <form
                        className="po-relative"
                        onSubmit={handleSubmit(this.handleOrgUserSubmit)}
                      >
                        <FieldArray name="org_users" component={this.renderMembers} />
                        {this.props.formValues &&
                          this.props.formValues.org_users &&
                          this.props.formValues.org_users.length > 0 ? (
                          <div className="s-n-bnt btn-hs-icon sm-bnt">
                            <button type="submit" className={`${styles.AddNewBtn} mb-2`}>
                              {Strings.save_btn}
                            </button>
                          </div>
                        ) : null}
                      </form>
                    </div>
                  </div>
                  <div className="col-3 text-right">
                    <div className="d-flex align-items-center justify-content-end">
                      {this.state.selectedRowKeys && this.state.selectedRowKeys.length ? (
                        <div>
                          <button className={`${styles.ResetBtn} mx-1`} onClick={() => this.setState({ selectedRowKeys: [] })}>Clear</button>
                          <span>{this.state.selectedRowKeys.length} User{this.state.selectedRowKeys.length === 1 ? '' : 's'} Selected</span>
                        </div>
                      ) : ''}
                      <button onClick={this.handleInviteClick} className={`${styles.AddNewBtn} mx-1`}>
                        Invite
                      </button>
                    </div>
                  </div>
                </div>
                <hr className="pb-1"></hr>
                <div className="row align-items-center pb-2">
                  <div className="col-6">
                    <div className={styles.UserFormLabel}>Invite Users</div>
                    <div className="d-flex">
                      <Field
                        value={this.state.userSearch}
                        onChange={(e) => this.setState({ userSearch: e.target.value })}
                        placeholder="Search"
                        component={customInput}
                        className={styles.UserFormField}
                        onSearch={() => this.getOrgUsers()}
                      />
                      <button onClick={() => this.getOrgUsers()} className={`${styles.AddNewBtn} mx-1`}>
                        Search
                      </button>
                      <button className={`${styles.ResetBtn} mx-1`}>Clear</button>
                    </div>
                  </div>
                  <div className="col-1 offset-3 text-right px-0">
                    <h6 className="font-weight-bold text-right">Bulk Upload</h6>
                  </div>
                  <div className="col-2 text-right">
                    <Dragger {...props} fileList={this.state.fileList}>
                      <div>Drag and Drop files here</div>
                      <div className="file-up-msg">
                        {this.state.loaderPercent !== 0 ? (
                          <Progress percent={this.state.loaderPercent} />
                        ) : null}
                        {this.state.uploading === "uploading" ? (
                          <span className="uplod-txt">Uploading...</span>
                        ) : null}
                        {this.state.fileUploadStatus === "Success" ? (
                          <span className="uplf-success">
                            <Icon type="check" />
                            {this.state.fileUploadStatus}
                          </span>
                        ) : this.state.fileUploadStatus === "" ? null : (
                          <span className="uplf-failed">
                            <Icon type="warning" theme="filled" />
                            {this.state.fileUploadStatus}
                          </span>
                        )}
                      </div>
                    </Dragger>
                    {this.state.errorMessage ? (
                      <div className="big-error">{this.state.errorMessage}</div>
                    ) : null}
                  </div>
                </div>
                <hr className="pt-2"></hr>
                <div className="row pt-2">
                  <div className="col-12">
                    <div>
                      <Table
                        rowSelection={rowSelection}
                        columns={this.columns}
                        dataSource={data}
                        className="swms-table-v2 table table-hover table-sm cs-table cs-table-v2"
                        pagination={{
                          className: "cs-pagination-v2",
                          pageSize: 17,
                          current: this.state.page,
                          defaultCurrent: 1,
                          onChange: (page) => this.getOrgUsers(page),
                          total: this.props.totalUsers
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >



        {/* <div className="main-container">
          <div className="sf-card">
            <div
              className={
                permissions.sf_org_bulk_user !== -1 ? "sf-card" : "d-none"
              }
            >
              <div className="sf-card-body">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-center">
                    <div className="bulk-import-files">
                      <i className="material-icons wnt-img">group_add</i>
                      <div className="blki-msg">
                        <h5> {Strings.user_bulk_import_que} </h5>
                        <span>
                          Easily upload users by importing records in .csv
                          format. You can{" "}
                          <a href="https://dev-sf-store.s3-ap-southeast-2.amazonaws.com/sample-files/sample_user_bulk_upload.csv">
                            click here
                          </a>{" "}
                          to download simple CSV template ready to import.
                        </span>
                      </div>
                    </div>

                    {this.state.fileUploaded ? (
                      <div className="bulk-import-details">
                        <strong>{`${Strings.user_import_summary} "${this.state.originalFileName}"`}</strong>
                        <ul className="imp-sum-us">
                          <li>
                            <span>{Strings.user_imported_by} :</span>{" "}
                            {this.loginUserName ? this.loginUserName : ""}
                          </li>
                          <li>
                            <span>{Strings.user_started} :</span>{" "}
                            {this.state.startDate.toString()}
                          </li>
                          <li>
                            <span>{Strings.user_started} :</span>{" "}
                            {this.state.endDate.toString()}
                          </li>
                        </ul>

                        <ul className="imp-status-us">
                          <li>
                            <b>
                              {this.state.responseData.added_rows
                                ? this.state.responseData.added_rows
                                : 0}
                            </b>
                            <span>{Strings.user_add}</span>
                          </li>
                          <li>
                            <b>
                              {this.state.responseData.updated_rows
                                ? this.state.responseData.updated_rows
                                : 0}
                            </b>
                            <span>{Strings.user_update}</span>
                          </li>

                          {this.state.responseData.invalid_rows_count ? (
                            <li className="error">
                              <b>
                                {this.state.responseData.invalid_rows_count}
                              </b>
                              <span>{Strings.user_error}</span>{" "}
                            </li>
                          ) : (
                            <li>
                              <b>{0}</b>
                              <span>{Strings.user_error}</span>{" "}
                            </li>
                          )}
                          <li>
                            <b>
                              {this.state.responseData.duplicate_rows_count
                                ? this.state.responseData.duplicate_rows_count
                                : 0}
                            </b>
                            <span>{Strings.user_dublicate}</span>
                          </li>
                        </ul>
                      </div>
                    ) : null}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="upload-sfv-file">
                      <Dragger {...props} fileList={this.state.fileList}>
                        <p className="ant-upload-drag-icon">
                          <i class="material-icons">cloud_upload</i>
                        </p>
                        <p className="ant-upload-text">Choose file to upload</p>
                        <p className="ant-upload-hint">
                          {Strings.img_upload_text}
                        </p>
                        <div className="file-up-msg">
                          {this.state.loaderPercent !== 0 ? (
                            <Progress percent={this.state.loaderPercent} />
                          ) : null}
                          {this.state.uploading === "uploading" ? (
                            <span className="uplod-txt">Uploading...</span>
                          ) : null}
                          {this.state.fileUploadStatus === "Success" ? (
                            <span className="uplf-success">
                              <Icon type="check" />
                              {this.state.fileUploadStatus}
                            </span>
                          ) : this.state.fileUploadStatus === "" ? null : (
                            <span className="uplf-failed">
                              <Icon type="warning" theme="filled" />
                              {this.state.fileUploadStatus}
                            </span>
                          )}
                        </div>
                      </Dragger>
                    </div>
                    {this.state.errorMessage ? (
                      <div className="big-error">{this.state.errorMessage}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="sf-card-body">
              <form
                className="po-relative"
                onSubmit={handleSubmit(this.handleOrgUserSubmit)}
              >
                <FieldArray name="org_users" component={this.renderMembers} />
                {this.props.formValues &&
                  this.props.formValues.org_users &&
                  this.props.formValues.org_users.length > 0 ? (
                  <div className="s-n-bnt btn-hs-icon sm-bnt">
                    <button type="submit" className="bnt bnt-active usr-sb-btn">
                      <i class="material-icons">save</i>
                      {Strings.save_btn}
                    </button>
                  </div>
                ) : null}
              </form>
            </div>

            <div className="sf-card-head abb-1 d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div>
                  <h2 className="sf-pg-heading">{Strings.invite_users}</h2>
                </div>
                <div className="px-2">
                  <Search
                    value={this.state.userSearch}
                    onChange={(e) => this.setState({ userSearch: e.target.value })}
                    placeholder="Search"
                    className="user-mgmt-search"
                    onSearch={() => this.getOrgUsers()}
                    enterButton={<button onClick={() => this.getOrgUsers()} className={styles.InputSearchButton}><Icon type="search" className="mx-3" /></button>} />
                </div>
              </div>
              <div className="info-btn disable-dot-menu">
                <div className="d-flex align-items-center justify-content-end">
                  {this.state.selectedRowKeys && this.state.selectedRowKeys.length ? (
                    <div className={`${styles.SelectionText} px-2`}>
                      <button className={`${styles.SmallTextBtn} mx-1`} onClick={() => this.setState({ selectedRowKeys: [] })}>Clear</button>
                      <span>{this.state.selectedRowKeys.length} User{this.state.selectedRowKeys.length === 1 ? '' : 's'} Selected</span>
                    </div>
                  ) : ''}
                  <button className="bnt bnt-active" onClick={this.handleInviteClick}>Invite</button>
                </div>
              </div>
            </div>
            <div className="sf-card-body">
              <Table
                rowSelection={rowSelection}
                columns={this.columns}
                dataSource={data}
                className="staff-table swms-table-v2"
                pagination={{
                  className: "cs-pagination-v2",
                  pageSize: 25,
                  current: this.state.page,
                  defaultCurrent: 1,
                  onChange: (page) => this.getOrgUsers(page),
                  total: this.props.totalUsers
                }}
              />
            </div>
          </div>
        </div> */}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    users: state.organizationUsers.users,
    totalUsers: state.organizationUsers.totalUsers,
    roles: state.roleManagement ? state.roleManagement.roles : [],
    formValues:
      state.form &&
      state.form.viewEditOrganization &&
      state.form.viewEditOrganization.values,
    isDirty: isDirty("viewEditOrganization")(state),
    initialValues: {
      ...state.organization.organizationDetails,
      org_industries: [],
    },
  };
};

const mapDispatchToprops = (dispatch) => {
  return {
    action: bindActionCreators(actions, dispatch),
    rolesActions: bindActionCreators(rolesActions, dispatch),
    orgUserActions: bindActionCreators(orgUserActions, dispatch),
    orgV2Actions: bindActionCreators(orgV2Actions, dispatch)
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToprops),
  reduxForm({
    form: "viewEditOrganization",
    validate,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, sub, props) => {
      handleFocus(errors, "#");
    },
  })
)(InviteUsers);
