import React, { memo, useEffect, useRef, useState } from "react";
import { Collapse, Icon } from "antd";
import styles from "../JobStyle.module.css";
import FrequencyHeader from "./FrequencyHeader";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom"
// import { getTaskFrequency } from "../../../../actions/v2/JobActions";
// import { Strings } from "../../../../dataProvider/localize";
// import { notification } from "antd";
import Frequency from "./Frequency";
import { LinearProgress } from "@mui/material";

const { Panel } = Collapse;

const FrequencyList = ({ selectedTask, jobId, selectedJobDetails, refreshHistory, fetchFrequencies, frequencies, loading, setSelectedTask }) => {

  const location = useLocation();
  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    if (location.goToTab === "ViewFrequency") {
      setActiveKey(location.frequencyNumber)
    }
  }, [location])

  const togglePanelKey = (key) => {
    if (activeKey !== key) {
      setActiveKey(key)
    } else {
      setActiveKey(null)
    }
  }

  const getNextFrequency = (frequency) => {
    const index = frequencies.findIndex(x => x.quote_task_id === frequency.quote_task_id);
    if (index === -1 || index + 1 === frequencies.length) {
      return null;
    } else {
      return frequencies[index + 1];
    }
  }

  return (
    <div>
      <div className={`${styles.JobTasksListContainer}`}>
        <div style={{ height: "4px" }}>
          {loading ? (
            <LinearProgress />
          ) : null}
        </div>
        <Collapse
          bordered={false}
          className={styles.Collapse}
          accordion
          onChange={(e) => togglePanelKey(e)}
          destroyInactivePanel={true}
          activeKey={activeKey}
          expandIcon={({ isActive, showIcon = true }) => (
            <Icon
              type={isActive ? "minus" : "plus"}
              className={`${styles.PrimaryText} ${!showIcon ? styles.transparentIcon : ''}`}
            />
          )}
        >
          {frequencies.map(frequency =>
            <Panel
              key={frequency.frequency_number}
              className={`${styles.PanelV2} ${styles.FrequencyPanelItem}`}
              header={<FrequencyHeader
                frequency={frequency}
                onActive={() => togglePanelKey(frequency.frequency_number)}
              />}
              // disabled={task.task_type === "JOB_TASK"}
              isActive={activeKey === frequency.frequency_number}
              showIcon={true}
              openAnimation={Collapse.defaultProps.openAnimation}
            >
              <Frequency
                frequency={frequency}
                key={frequency.frequency_number}
                jobId={jobId}
                setSelectedTask={setSelectedTask}
                getNextFrequency={getNextFrequency}
                refreshHistory={refreshHistory}
                selectedTask={selectedTask}
                fetchFrequencies={fetchFrequencies}
                selectedJobDetails={selectedJobDetails}
              />
            </Panel>
          )}
        </Collapse>
      </div>
    </div >
  )
}

export default memo(FrequencyList);