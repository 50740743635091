import {
  GET_PAYROLL_LIST,
  IS_GET_PAY_ROLL_LIST,
  GET_PAYROLL_URL,
  UPDATE_PAYROLL_URL,
  EXPORT_TIMESHEET,
  GET_EXPORTED_TIMESHEET,
  SEARCH_SITE_URL,
  GET_ALLOCATION_MANAGERS_URL,
  GET_ACCOUNT_MANAGERS,
  CLEAR_PAYROLL_LIST
} from "../dataProvider/constant";
import { startSipnner, stopSipnner } from "../utils/spinner";
import { scopeAxiosInstance, scopeV2AxiosInstance, axiosInstance } from "../dataProvider/axiosHelper";
import { Strings } from "../dataProvider/localize";
import { serializeTimesheetData } from "../components/dashboard/ShiftTimeSheet/serializer";

export const getAdvanceSearchArgs = (advanceSeacrhOpt) => {
  if (
    Object.keys(advanceSeacrhOpt) &&
    Object.keys(advanceSeacrhOpt).length > 0
  ) {
    var args = "";
    Object.keys(advanceSeacrhOpt).forEach((key) => {
      args = `${args}${args && args.length > 0 ? "&" : ""}${key}=${encodeURIComponent(
        JSON.stringify(advanceSeacrhOpt[key])
      )}`;
    });
    return args;
  }
  return "";
};

const getPayroll = (dispatch, advanceSeacrhOpt) => {
  startSipnner(dispatch);
  dispatch({
    type: IS_GET_PAY_ROLL_LIST,
    payload: true,
  });

  var args = "";
  if (advanceSeacrhOpt) {
    args = getAdvanceSearchArgs(advanceSeacrhOpt);
  }
  return scopeAxiosInstance
    .get(GET_PAYROLL_URL + "?" + args)
    .then((res) => {
      stopSipnner(dispatch);
      dispatch({
        type: IS_GET_PAY_ROLL_LIST,
        payload: false,
      });
      if (res.data.status) {
        dispatch({
          type: GET_PAYROLL_LIST,
          payload: serializeTimesheetData(res.data.data.job_timesheets),
          total: 111,
        });
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      dispatch({
        type: IS_GET_PAY_ROLL_LIST,
        payload: false,
      });
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const getPayrollTImesheet = (obj) => (dispatch) => {
  return getPayroll(dispatch, obj);
};

export const updatePayrollApproved = (obj) => (dispatch) => {
  startSipnner(dispatch);
  return scopeAxiosInstance
    .post(UPDATE_PAYROLL_URL, obj)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data && res.data);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const exportTimesheet = (advanceSeacrhOpt) => (dispatch) => {
  startSipnner(dispatch);
  dispatch({
    type: IS_GET_PAY_ROLL_LIST,
    payload: true,
  });

  var args = "";
  if (advanceSeacrhOpt) {
    args = getAdvanceSearchArgs(advanceSeacrhOpt);
  }
  return scopeAxiosInstance
    .get(EXPORT_TIMESHEET + "?" + args)
    .then((res) => {
      stopSipnner(dispatch);
      dispatch({
        type: IS_GET_PAY_ROLL_LIST,
        payload: false,
      });
      if (res.data.status) {
        dispatch({
          type: GET_EXPORTED_TIMESHEET,
          csvFileLink: res.data.data.csvFileLink,
        });
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      dispatch({
        type: IS_GET_PAY_ROLL_LIST,
        payload: false,
      });
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

//timesheet redesign

export const getPayrollTimesheet = (advanceSeacrhOpt) => (dispatch) => {
  startSipnner(dispatch);

  return scopeAxiosInstance
    .get("/timesheet")
    .then((res) => {
      stopSipnner(dispatch);
      dispatch({
        type: "GET_PAYROLL_TIMESHEET",
        payload: false,
      });
      if (res.data.status) {
        dispatch({
          type: GET_EXPORTED_TIMESHEET,
          payload: res.data.data.job_timesheets,
          csvFileLink: res.data.data.csvFileLink,
        });
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      dispatch({
        type: IS_GET_PAY_ROLL_LIST,
        payload: false,
      });
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};


export const getSitesBySearch = () => dispatch => {
  let url = SEARCH_SITE_URL;
  return scopeV2AxiosInstance.get(url).then((res) => {
    let data = res.data.data;
    if (data) {
      return Promise.resolve(data);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    })
}

export const getManagers = (org_id) => (dispatch) => {
  startSipnner(dispatch);
  return axiosInstance
    .get(`${GET_ALLOCATION_MANAGERS_URL}?organisation_id=${org_id}`)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: GET_ACCOUNT_MANAGERS,
          payload: res.data.data.account_managers,
        });
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const clearPayrollList = () => dispatch => {
  dispatch({
    type: CLEAR_PAYROLL_LIST,
    payload: null
  })
}