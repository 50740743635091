import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import * as actions from "../../../../actions/v2/JobDocumentActions";
import { withRouter } from "react-router";
import PreAllocateContainer from "./PreAllocate/PreAllocateContainer";
import PostAllocateContainer from "./PostAllocate/PostAllocateContainer";
import { Modal } from "antd";
import { PDFObject } from "react-pdfobject";
import CloseIcon from '@mui/icons-material/Close';
import jobStyles from "../../Job/JobStyle.module.css";
import "../../v2Styles.css";
export class JobDocumentContainer extends Component {
  state = {
    editStaff: false,
    visible: false,
    isPdfDocument: null,
    document: null,
  };

  handleEditStaff = (value) => {
    this.setState({ editStaff: value });
  };

  handleOk = () => {
    this.setState({
      visible: false,
      document: null,
      isPdfDocument: null,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      document: null,
      isPdfDocument: null,
    });
  };

  showModal = (documentUrl, isPdfObject) => {
    this.setState({
      visible: true,
      document: documentUrl,
      isPdfDocument: isPdfObject,
    });
  };

  render() {
    const { jobDocumentDetails } = this.props;
    return (
      <>
        {Object.keys(jobDocumentDetails).length ? (
          <PostAllocateContainer
            routerParams={{ state: this.props.location.state }}
            editStaff={this.state.editStaff}
            onEditStaff={this.handleEditStaff}
            onShowModal={this.showModal}
            selectedJobDetails={this.props.selectedJobDetails}
          />
        ) : (
          <PreAllocateContainer
            editStaff={this.state.editStaff}
            onEditStaff={this.handleEditStaff}
            onShowModal={this.showModal}
            selectedJobDetails={this.props.selectedJobDetails}
          />
        )}
        <div className="docmnt-lists-mn">
          <Modal
            className="cs-modal-v2"
            visible={this.state.visible}
            footer={null}
            closeIcon={<div className={jobStyles.ModalCloseIcon}><CloseIcon /></div>}
            onCancel={this.handleCancel}
            width={1500}
          >
            {this.state.isPdfDocument ? (
              <div className="w-100 pdf-main-mdl">
                <PDFObject url={this.state.document} />
              </div>
            ) : (
              <div className="w-100 text-center mb-3 pdfimg-tab">
                <img src={this.state.document} alt="" />
              </div>
            )}
          </Modal>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  staffList: state.JobDocument.staffList,
  jobDetails: state.JobDocument.jobDetails,
  certificates: state.JobDocument.certificates,
  licenseTypes: state.JobDocument.licenseTypes,
  jobDocumentDetails: state.JobDocument.jobDocumentDetails,
  jobDocumentCreatorDetails: state.JobDocument.jobDocumentCreatorDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(JobDocumentContainer);
