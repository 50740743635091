import {
  JOB_DETAILS, TASK_JOB_DETAILS,
  GET_JOB_SHIFT_TIMESHEET_LIST,
  IS_GET_JOB_SHIFT_TIMESHEET_LIST
} from "../dataProvider/constant";

const INITIAL_STATE = {
  jobDetails: {},
  taskJobDetails: {},
  jobShiftTimesheets: [],
  isJobShiftTimesheets: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case JOB_DETAILS:
      return {
        ...state,
        jobDetails: action.payload,
      };
    case TASK_JOB_DETAILS:
      return {
        ...state,
        taskJobDetails: action.payload,
      };
    case GET_JOB_SHIFT_TIMESHEET_LIST:
      return {
        ...state,
        jobShiftTimesheets: action.payload,
      };
    case IS_GET_JOB_SHIFT_TIMESHEET_LIST:
      return {
        ...state,
        isJobShiftTimesheets: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
