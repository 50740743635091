import React from "react";
import moment from "moment";

export default function ReviewsSection({ revisionsData }) {
  return (
    <div className="">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-12">
            <table className="table-sm cs-table cs-table-v2 cs-table-job-swms" style={{ tableLayout: "fixed", width: "100%" }}>
              <thead>
                <tr>
                  <th width="20%">Actions</th>
                  <th width="20%">Name</th>
                  <th width="20%">Position</th>
                  <th width="10%">Date</th>
                  <th width="20%">Signature</th>
                </tr>
              </thead>
              <tbody>
                {revisionsData && revisionsData.length > 0 ? revisionsData.map((revision, index) => (
                  <tr key={index}>
                    <td>{revision.actions ? revision.actions : ""}</td>
                    <td>{revision.approver_name ? revision.approver_name : ""}</td>
                    <td>{revision.position ? revision.position : ""}</td>
                    <td>{revision.revision_date ? moment.utc(revision.revision_data).format("DD/MM/YYYY") : ""}</td>
                    <td>{revision.approval_sign ? <img src={revision.approval_sign} style={{ maxHeight: "30px" }} alt="" /> : ""}</td>
                  </tr>
                )) : (
                  <tr>
                    <td colSpan={4} className="text-center" style={{ color: "red" }}>No Revisions Available.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}