import React, { useState, useEffect, useRef } from "react";
import { notification } from "antd";
import JobSwmsForm from "./JobSwmsForm";
import styles from "../../JobStyle.module.css";
import { KeyboardBackspace } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { getAllHighRisksData, getAllSwmsDropdowns, getSwmsFormData } from "../../../../../actions/v2/JobSwmsActions";
import { Strings } from "../../../../../dataProvider/localize";
import { CircularProgress } from "@mui/material";
import { serializeInitialValues } from "./SwmsFormSerializer";
import { getLikelihoods } from "../../../../../actions/v2/SWMSLikelihoodActions";
import { getConsequences } from "../../../../../actions/v2/SWMSConsequencesActions";
import { getSwmsRiskRatings } from "../../../../../actions/v2/SwmsRiskRatingActions";

export default function JobSwmsFormContainer({ onClose, swms }) {

  const dispatch = useDispatch();
  const controller = useRef();
  const [initialValues, setInitialValues] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [dropdownData, setDropDownData] = useState([]);
  const [formData, setFormData] = useState(null);
  const [highRiskData, setHighRiskData] = useState({});
  const [consequences, setConsequences] = useState([]);
  const [likelihoods, setLikelihoods] = useState([]);
  const [risks, setRisks] = useState([]);


  const getDropdowns = () => {
    setLoadingData(true);
    controller.current = new AbortController();
    let promises = [
      dispatch(getAllSwmsDropdowns({ signal: controller.current.signal })),
      dispatch(getSwmsFormData({ signal: controller.current.signal }, swms.version.swms_form_id)),
      dispatch(getAllHighRisksData({ signal: controller.current.signal })),
      dispatch(getLikelihoods({ signal: controller.current.signal }, { active: 1 })),
      dispatch(getConsequences({ signal: controller.current.signal }, { active: 1 })),
      dispatch(getSwmsRiskRatings({ signal: controller.current.signal }, { active: 1 }))
    ];
    Promise.all(promises).then(data => {
      setDropDownData(data[0]);
      setFormData(data[1]);
      let values = serializeInitialValues(data[1].swms_data, data[0], data[2] && data[2].getFormActivites ? data[2].getFormActivites : {})
      setInitialValues({ ...values })
      setHighRiskData(data[2] && data[2].getFormActivites ? data[2].getFormActivites : {});
      setLikelihoods(data[3]);
      setConsequences(data[4]);
      setRisks(data[5]);
      setLoadingData(false);
    }).catch(err => {
      if (err) {
        setLoadingData(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: "ant-error"
        })
        onClose();
      }
    })
  }

  useEffect(() => {
    getDropdowns();
    return () => {
      if (controller.current) {
        controller.current.abort()
      }
    }
  }, [])

  return (
    <div className="container-fluid px-0">
      <div className="row">
        {loadingData ? (
          <div className="col-12 d-flex align-items-center justify-content-center p-5">
            <CircularProgress size={20} />
          </div>
        ) : (
          <div className="col-12">
            <button className={`${styles.BlueTextButton} px-2 ${styles.PrimaryText}`} type="button" onClick={() => onClose(null)}><KeyboardBackspace /> Back to SWMS</button>
            <div>
              <JobSwmsForm
                risks={risks}
                likelihoods={likelihoods}
                consequences={consequences}
                onClose={onClose}
                allData={formData}
                highRiskData={highRiskData}
                {...(formData ? formData : {})}
                dropdownData={dropdownData}
                initialValues={initialValues}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}