import React, { useRef, useEffect, useState } from "react";
import { LinearProgress, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { notification, Pagination } from "antd";
import Select, { components } from "react-select";

import { colorStylesMulti } from "./SelectV2SingleStyle";
import { getSignHistory } from "../../../../../actions/v2/JobSwmsTaskActions";
import { Strings } from "../../../../../dataProvider/localize";

import styles from "../../JobStyle.module.css";

const SignIndicator = ({ staffs, currentStaff }) => {

  const [signValue, setSignValue] = useState(3);

  useEffect(() => {
    let value = 3;
    if (currentStaff && staffs.length > 0) {
      let findStaff = staffs.find(x => x.user_name === currentStaff.user_name);
      if (findStaff) {
        if (findStaff.staff_sign) {
          value = 1;
        } else {
          value = 2;
        }
      }
    }
    setSignValue(value);
  }, [])

  return (
    <td className={`${styles.SignIndicatorHeader} text-center`} title={signValue === 1 ? "Signed" : signValue === 2 ? "Not Signed" : "Shift not Scheduled"}>
      <div className={styles.SignIndicatorBox}>
        {signValue === 1 ? (
          <div className={styles.SignedIndicator}></div>
        ) : signValue === 2 ? (
          <div className={styles.UnsignedIndicator}></div>
        ) : (<div></div>)
        }
      </div>
    </td>
  )
}

export default function SignHistoryContainer({ jobId }) {

  const dispatch = useDispatch();
  const controller = useRef();

  const [loading, setLoading] = useState(false);
  const [fieldCount, setFieldCount] = useState(0);
  const [signs, setSigns] = useState([]);
  const [page, setPage] = useState(1);
  const [staffs, setStaffs] = useState([]);
  const [selectedStaffs, setSelectedStaffs] = useState([]);

  const fetchSignHistory = (pageNo) => {
    setLoading(true);
    if (page !== pageNo) {
      setPage(pageNo)
    }
    controller.current = new AbortController();
    dispatch(getSignHistory({ job_id: jobId }, { signal: controller.current.signal })).then(res => {
      setSigns(res.swmsFormSignData.slice((pageNo - 1) * 10, ((pageNo - 1) * 10) + 10));
      setStaffs(res.swmsFormSignUser);
      setFieldCount(res.swmsFormSignData.length);
      setLoading(false);
    }).catch((err) => {
      if (err) {
        setLoading(false);
        notification.error({
          message: Strings.error_title,
          description: "There was an error while fetching Swms Sign History.",
          className: "ant-error"
        })
      }
    })
  }

  useEffect(() => {
    fetchSignHistory(1);
    return () => {
      if (controller.current) {
        controller.current.abort()
      }
    }
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-9 px-0">
            <div className={styles.SwmsTitle}>
              Sign History
            </div>
          </div>
          <div className="col-3 text-right">
            <Select
              isMulti
              value={selectedStaffs}
              placeholder="Select Staff"
              options={staffs.map(option => ({ ...option, label: option.staff_name, value: option.user_name }))}
              styles={colorStylesMulti}
              onChange={(v) => setSelectedStaffs(v)}
              components={{ dropdownIndicator: <components.DownChevron /> }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 px-0 pt-2">
            <div style={{ height: "4px" }}>
              {loading ? (
                <LinearProgress />
              ) : ""}
            </div>
            <div className={`${styles.TabsCover} pb-0`} style={{ overflow: "auto hidden" }}>
              <table className="table-sm cs-table cs-table-v2 sign-history-table-v2">
                <thead>
                  <tr>
                    <th className={`${styles.SmallCol} text-center`}>Date</th>
                    <th className={`${styles.SmallCol} ${styles.SmallCol2} text-center`}>Shift Id</th>
                    <th className={styles.MidCol}>Task Name</th>
                    {selectedStaffs.length > 0 ? selectedStaffs.map((staff) => <th className={`${styles.SignIndicatorHeader} text-center`} key={staff.value}>{staff.staff_name}</th>) :
                      staffs.length > 0 ? staffs.map(staff => (
                        <th className={`${styles.SignIndicatorHeader} text-center`} key={staff.user_name}>{staff.staff_name}</th>
                      )) :
                        <th className={`${styles.SignIndicatorHeader} text-center`}></th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {signs.map(sign => (
                    <tr key={`${sign.task_id}_${sign.shift_id}`}>
                      <td title={sign.shift_date} className={`${styles.SmallCol} text-center`}>{sign.shift_date}</td>
                      <td title={sign.shift_id} className={`${styles.SmallCol} ${styles.SmallCol2} text-center`}>
                        {sign.pdf_link ? (
                          <a href={sign.pdf_link} className={styles.Link} rel="noopener noreferrer" target="_blank">{sign.shift_id}</a>
                        ) : sign.shift_id
                        }
                      </td>
                      <td title={sign.task_name} className={styles.MidCol}>
                        <Typography className={styles.TableText} noWrap>{sign.task_name}</Typography></td>
                      {
                        selectedStaffs.length > 0 ?
                          selectedStaffs.map((staff) => (
                            <SignIndicator staffs={sign.staffs} currentStaff={staff} key={`${sign.task_id}_${sign.shift_id}_${staff.user_name}`} />
                          )) :
                          staffs.map(staff => (
                            <SignIndicator staffs={sign.staffs} currentStaff={staff} key={`${sign.task_id}_${sign.shift_id}_${staff.user_name}`} />
                          ))
                      }
                    </tr>
                  ))}
                  {!loading && signs.length === 0 ? (
                    <tr>
                      <td colSpan={staffs.length ? staffs.length + 3 : 4} className="text-center" style={{ color: "red" }}>No Sign History Available.</td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row pt-1">
          <div className="col-12 d-flex justify-content-end pt-1">
            <Pagination
              className="cs-pagination-v2"
              total={fieldCount}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Histories`}
              pageSize={10}
              current={page}
              onChange={(pageNo) => { fetchSignHistory(pageNo) }}
              defaultCurrent={1}
            />
          </div>
        </div>
      </div>
    </div>
  )
}