import { startSipnner, stopSipnner } from "../utils/spinner";
import { scopeApiInstance, scopeAxiosInstance, scopeV3AxiosInstance } from "../dataProvider/axiosHelper";
import { Strings } from "../dataProvider/localize";
import {
  GENERAL_MASTERS_URL,
  GET_MASTERS_DATA,
  GET_ORG_SWMS,
  GET_ORIGINAL_SWMS_DATA,
  RECOMPUTE_DRAG_DROP_LISTS,
  INIT_DRAG_LIST,
  GET_CLIENT_SITES_LIST,
  GET_SINGLE_SWMS_ITEM,
  GET_LIKELIHOOD_URL,
  GET_CONSEQUENCES_URL,
  GET_ALL_LIKELIHOOD_BEFORE_CONTROL,
  GET_ALL_BEFORE_CONSEQUENCES,
  GET_CONSEQUENCES_BY_HAZARDS,
  SWMS_ACTIVITY_URL,
  GET_SINGLE_SWMS_ACTIVITY,
  SET_SWMS_ACTIVITY_INITIALVALUES,
  RESET_SWMS_ACTIVITY_INITIALVALUES,
  GET_NEW_SWMS_ACTIVITY_LIST,
  ACTIVATE_BREADCRUMBS,
  DEACTIVATE_BREADCRUMBS,
  SET_ACTIVITY_PAGE,
  GET_ORG_SWMS_URL,
  GET_ALL_SWMS,
  GET_JOB_SWMS_URL,
  POST_JOB_SWMS_URL,
  GET_SWMS_ORG_DETAILS,
  GET_JOB_SWMS_TASK_URL,
  GET_LICENSEES_URL,
  GET_LICENSEES_DATA,
  GET_SWMS_TASK_DETAILS,
  GET_SIGN_HISTORY_URL,
  GET_SIGN_HISTORY_DATA,
  PUT_SITE_SPECIFIC_SWMS_URL,
  PUT_SITE_SPECIFIC_SWMS_DATA,
  DOWNLOAD_SHIT_SWMS_URL,
  ALL_CONSEQUENCES_URL,
  ALL_LIKELIHOODS_URL
} from "../dataProvider/constant";
import { BASE_SCOPE_API_URL } from '../dataProvider/env.config';
import { setJobSwmsId } from "./v2/JobActions";

export const getSWMS = (type) => (dispatch) => {
  startSipnner(dispatch);
  return scopeAxiosInstance
    .get(`${GENERAL_MASTERS_URL}?master_type=${type}`)
    .then((res) => {
      stopSipnner(dispatch);
      dispatch({
        type: GET_MASTERS_DATA,
        payload: {
          type: type,
          data: res.data.data,
        },
      });
      return Promise.resolve(res.data.message);
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const addEditSWMS = (data) => (dispatch) => {
  startSipnner(dispatch);
  return scopeAxiosInstance
    .put(GENERAL_MASTERS_URL, data)
    .then((res) => {
      stopSipnner(dispatch);
      return Promise.resolve(res.data.message);
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const getAllOrgSWMS = () => (dispatch) => {
  startSipnner(dispatch);
  return scopeAxiosInstance
    .get(GET_ORG_SWMS)
    .then((res) => {
      stopSipnner(dispatch);
      dispatch({
        type: GET_ORIGINAL_SWMS_DATA,
        payload: res.data.data.area_swms,
      });
      return Promise.resolve(res.data.message);
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const initializeDragList = (dragObjects) => (dispatch) => {
  dispatch({
    type: INIT_DRAG_LIST,
    payload: dragObjects,
  });
};

export const recomputeDragAndDropLists = (dragItems, dropItems) => (
  dispatch
) => {
  dispatch({
    type: RECOMPUTE_DRAG_DROP_LISTS,
    payload: {
      dragList: dragItems,
      dropList: dropItems,
    },
  });
};

export const getClientSites = () => dispatch => {
  startSipnner(dispatch);
  return scopeAxiosInstance.get('/client-filter-items')
    .then(res => {
      stopSipnner(dispatch);
      dispatch({
        type: GET_CLIENT_SITES_LIST,
        payload: {
          clients: res.data.data.clients.clients,
          sites: res.data.data.clients.sites
        }
      })
      return Promise.resolve(res.data.message);
    })
    .catch(err => {
      stopSipnner(dispatch);
      return Promise.reject(err.response ? err.response.data.message : Strings.network_error);
    })
}

export const getSingleSWMS = (type, id) => (dispatch) => {
  startSipnner(dispatch);
  return scopeAxiosInstance
    .get(`${GENERAL_MASTERS_URL}?master_type=${type}&id=${id}`)
    .then((res) => {

      stopSipnner(dispatch);
      dispatch({
        type: GET_SINGLE_SWMS_ITEM,
        payload: {
          type: type,
          data: res.data.data,
        },
      });
      return Promise.resolve({ consequences: res.data.data[0].hazards_consequences, controls: res.data.data[0].hazards_swms_controls });
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const getAllLikelihoodBeforeControl = () => dispatch => {
  startSipnner(dispatch)
  return scopeApiInstance.get(ALL_LIKELIHOODS_URL)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: GET_ALL_LIKELIHOOD_BEFORE_CONTROL,
          payload: res.data.data.likelihood_before.filter(x => x.active === 1)
        })
        return Promise.resolve()
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(error.response ?
        error.response.data.message
        : Strings.network_error)
    });
}

export const getAllBeforeConsequences = () => dispatch => {
  startSipnner(dispatch)
  return scopeApiInstance.get(ALL_CONSEQUENCES_URL)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: GET_ALL_BEFORE_CONSEQUENCES,
          payload: res.data.data.Consequences.filter(x => x.active === 1)
        })
        return Promise.resolve()
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(error.response ?
        error.response.data.message
        : Strings.network_error)
    });
}

export const getConsequencesByHazards = (consequences) => dispatch => {
  dispatch({
    type: GET_CONSEQUENCES_BY_HAZARDS,
    payload: consequences
  })
}

export const getSWMSActivity = (filterParams) => dispatch => {
  startSipnner(dispatch)
  const url = SWMS_ACTIVITY_URL

  return scopeAxiosInstance.get(`${url}${filterParams ? `?${filterParams}` : ''}`)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: GET_NEW_SWMS_ACTIVITY_LIST,
          payload: res.data.data
        });
        return Promise.resolve(res.data.message)
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(error.response ?
        error.response.data.message
        : Strings.network_error)
    });
}

export const addEditSWMSActivity = formValues => dispatch => {
  startSipnner(dispatch)
  return scopeAxiosInstance.put(SWMS_ACTIVITY_URL, formValues)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message)
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(Strings.network_error)
    });
}

export const getSingleSWMSActivity = (id) => dispatch => {
  startSipnner(dispatch)
  const url = SWMS_ACTIVITY_URL
  return scopeAxiosInstance.get(`${url}?id=${id}`)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: GET_SINGLE_SWMS_ACTIVITY,
          payload: res.data.data,
        });
        return Promise.resolve(res.data.message)
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(error.response ?
        error.response.data.message
        : Strings.network_error)
    });
}

export const deleteActivity = activityId => dispatch => {
  startSipnner(dispatch)
  const url = '/swms-activity-status'
  return scopeAxiosInstance.put(url, { id: activityId })
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message)
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(error.response ?
        error.response.data.message
        : Strings.network_error)
    });
}

export const changeActivityStatus = (activityId, status) => dispatch => {
  startSipnner(dispatch)
  const url = '/swms-activity-status'
  return scopeAxiosInstance.put(url, { id: activityId, active: status })
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message)
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(error.response ?
        error.response.data.message
        : Strings.network_error)
    });
}

export const setInitialValues = (values) => dispatch => {
  dispatch({
    type: SET_SWMS_ACTIVITY_INITIALVALUES,
    payload: values,
  });
}

export const resetSWMSActivityInitialValues = () => dispatch => {
  dispatch({
    type: RESET_SWMS_ACTIVITY_INITIALVALUES,
  })
}

export const activateBreadCrumbs = (pageNumber) => dispatch => {
  dispatch({ type: ACTIVATE_BREADCRUMBS, payload: pageNumber })
}

export const deactivateBreadCrumbs = () => dispatch => {
  dispatch({ type: DEACTIVATE_BREADCRUMBS })
}

export const setActivityPage = (page) => dispatch => {
  dispatch({ type: SET_ACTIVITY_PAGE, payload: page })
}


////////////////get job swms org details and client details/////////////


export const getOrgDetails = (jobId, scopeDocId, quoteId, clientId, clientPersonId, jobSwmsId = null) => dispatch => {
  startSipnner(dispatch);
  let url = `${GET_JOB_SWMS_URL}?job_id=${jobId}&quote_id=${quoteId}&client_id=${clientId}&client_person_id=${clientPersonId}`;
  if (jobSwmsId) {
    url += `&job_swms_id=${jobSwmsId}`;
  }
  return scopeV3AxiosInstance.get(url)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: GET_SWMS_ORG_DETAILS,
          payload: res.data.data.swms_details[0]
        })
        let smws_id = res.data.data.swms_details[0].job_swms_id ? res.data.data.swms_details[0].job_swms_id : null
        dispatch(setJobSwmsId(smws_id));
        return Promise.resolve(res.data.data.swms_details[0])
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      )
    });
}

export const getOrgDetailsPreview = (job_id, quote_id, job_swms_id = null) => dispatch => {
  startSipnner(dispatch);
  let url = `/api/v4/download-swms?job_id=${job_id}&quote_id=${quote_id}&pdf_type=1`;
  if (job_swms_id) {
    url += `&job_swms_id=${job_swms_id}`
  }
  return scopeApiInstance.get(url)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: GET_SWMS_ORG_DETAILS,
          payload: res.data.data.swms_details[0]
        })
        let smws_id = res.data.data.swms_details[0].job_swms_id ? res.data.data.swms_details[0].job_swms_id : null
        dispatch(setJobSwmsId(smws_id));
        return Promise.resolve(res.data.data.swms_details[0])
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    });
}




/////////////swms api actions//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const getAllSWMS = () => dispatch => {
  startSipnner(dispatch)
  return scopeAxiosInstance.get(BASE_SCOPE_API_URL + GET_ORG_SWMS_URL)
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: GET_ALL_SWMS,
          payload: res.data.data.area_swms
        })
        return Promise.resolve()
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error)
    });
}



////////////////get task swms details  not in use/////////////


export const getSwmsTaskDetails = () => dispatch => {
  startSipnner(dispatch)
  return scopeAxiosInstance.get(BASE_SCOPE_API_URL + GET_JOB_SWMS_TASK_URL + "?job_id=18&scope_doc_id=6")
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: GET_SWMS_TASK_DETAILS,
          payload: res.data.data.swms_details[0]
        })
        return Promise.resolve()
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(error.response ?
        error.response.data.message
        : Strings.network_error)
    });
}


/////////////get lincesees swms/////////////


export const getSwmsLicensees = () => dispatch => {
  startSipnner(dispatch)
  return scopeAxiosInstance.get(BASE_SCOPE_API_URL + GET_LICENSEES_URL + "?master_type=6")
    .then(res => {
      stopSipnner(dispatch);
      if (res) {
        dispatch({
          type: GET_LICENSEES_DATA,
          payload: res.data.data
        })
        return Promise.resolve()
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(error && error.response && error.response.data && error.response.data.message ?
        error.response.data.message
        : Strings.network_error)
    });
}


////////////////put swms  details  details/////////////


export const updateSwmsManagement = (data, sitespecificID, Jobid, job_swms_id, swms_revision, account_manager_name = null) => {


  let data1 = job_swms_id ? {
    job_id: Jobid,
    job_swms_id: job_swms_id,
    swms_revision: swms_revision,
    site_specific_swms_ids: (sitespecificID.map(String).toString()),
    swms_ids: (data.selectedCheckActivity.map(String).toString()),
    ppe_ids: data.selectedCheckPpe.map(String).toString(),
    high_risk_work_ids: data.selectedCheckHRW.map(String).toString(),
    chemical_ids: data.selectedCheckCHEM.map(String).toString(),
    license_ids: data.selectedCheckLicensees.map(String).toString(),
    tool_type_ids: data.selectedCheckToolTypes.map(String).toString()

  } : {
    job_id: Jobid,
    site_specific_swms_ids: JSON.stringify(sitespecificID),
    swms_ids: (data.selectedCheckActivity.map(String).toString()),
    ppe_ids: data.selectedCheckPpe.map(String).toString(),
    high_risk_work_ids: data.selectedCheckHRW.map(String).toString(),
    chemical_ids: data.selectedCheckCHEM.map(String).toString(),
    license_ids: data.selectedCheckLicensees.map(String).toString(),
    tool_type_ids: data.selectedCheckToolTypes.map(String).toString()

  }

  if (account_manager_name) {
    data1.acc_manager_user_name = account_manager_name
  }


  return (dispatch) => {
    startSipnner(dispatch)
    return scopeAxiosInstance.put(BASE_SCOPE_API_URL + POST_JOB_SWMS_URL, data1)
      .then(res => {
        stopSipnner(dispatch);
        if (res.data.status) {
          // dispatch({
          //   type: GET_SWMS_ORG_DETAILS,
          //   payload: res.data.data.swms_details[0]
          // })
          return Promise.resolve(res.data.message)
        }
      })
      .catch((error) => {
        stopSipnner(dispatch);
        return Promise.reject(error.response ?
          error.response.data.message
          : Strings.network_error)
      });
  }
}




/////////////get history swms/////////////


export const getSignHistory = (jobId) => dispatch => {
  startSipnner(dispatch)
  return scopeAxiosInstance.get(BASE_SCOPE_API_URL + GET_SIGN_HISTORY_URL + "?job_id=" + jobId)
    .then(res => {
      stopSipnner(dispatch);
      if (res) {
        dispatch({
          type: GET_SIGN_HISTORY_DATA,
          payload: { history: res.data.data.swms_sign_history, staff: res.data.data.swms_sign_off_staffs }
        })
        return Promise.resolve({ history: res.data.data.swms_sign_history, staff: res.data.data.swms_sign_off_staffs })
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(error.response ?
        error.response.data.message
        : Strings.network_error)
    });
}



/////////site-specific-swms/////


export const putSiteSpecificSwms = (formData) => dispatch => {
  startSipnner(dispatch)
  return scopeAxiosInstance.put(BASE_SCOPE_API_URL + PUT_SITE_SPECIFIC_SWMS_URL, formData, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" }
  })
    .then(res => {
      stopSipnner(dispatch);
      if (res) {
        dispatch({
          type: PUT_SITE_SPECIFIC_SWMS_DATA,
          payload: res.data.data
        })
        return Promise.resolve([res.data.data, res.data.message])
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(error.response ?
        error.response.data.message
        : Strings.network_error)
    });
}

export const deleteSiteSpecificSwms = (formData) => dispatch => {
  startSipnner(dispatch);
  return scopeAxiosInstance.delete(BASE_SCOPE_API_URL + PUT_SITE_SPECIFIC_SWMS_URL, { data: formData }, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" }
  }).then((res) => {
    if (res.data.status) {
      return Promise.resolve(res.data.message)
    }
  }).catch(err => {
    return Promise.reject(err.response ? err.response.data.message : Strings.network_error)
  }).finally(() => {
    stopSipnner(dispatch);
  })
}

///////////swms update after specific not in use


export const addEditSWMSManagement = formValues => dispatch => {
  startSipnner(dispatch)
  return scopeAxiosInstance.put(SWMS_ACTIVITY_URL, formValues, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" }
  })
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message)
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(Strings.network_error)
    });
}

// for org master api not for SWMS Avtivity
export const deleteOrgCategory = formData => dispatch => {
  startSipnner(dispatch)
  return scopeAxiosInstance.delete(GENERAL_MASTERS_URL, { data: formData })
    .then(res => {
      if (res.data.status) {
        return Promise.resolve(res.data.message)
      }
    })
    .catch((error) => {
      return Promise.reject(Strings.network_error)
    }).finally(() => {
      stopSipnner(dispatch);
    })
}

export const downloadShiftSwms = (jobId, shiftId) => dispatch => {
  startSipnner(dispatch);
  let url = `${DOWNLOAD_SHIT_SWMS_URL}?job_id=${jobId}&job_schedule_shift_id=${shiftId}`;
  return scopeV3AxiosInstance.get(url).then((res) => {
    if (res.data.status) {
      return Promise.resolve(res.data.data)
    } else {
      return Promise.reject(Strings.generic_error);
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    }).finally(() => {
      stopSipnner(dispatch);
    })
}