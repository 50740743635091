import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchColumns } from "./config/tableDefinitions";
import { compose, bindActionCreators } from "redux";
import * as SWMSMastersActions from "../../../../../actions/SWMSMastersActions";
import * as roleManagementActions from "../../../../../actions/roleManagementActions";
import { Table, notification, Button } from "antd";
import { reduxForm, Field, isDirty } from "redux-form";
import { withRouter } from "react-router-dom";
import { Strings } from "../../../../../dataProvider/localize";
import { customInput } from "../../../../common/custom-input";
import { popOverCheckBox } from "../../../../common/popOverCheckBox";
import { getFormattedPopBoxOptions } from "../../utils";
import { serializeFilterParams } from "../../serializer";
import { getPermissions } from "../../../../../utils/common";
import {
  MODULE_SWMS_ACTIVITY, PERMISSION_ADD_SWMS_ACTIVITY,
  PERMISSION_EDIT_SWMS_ACTIVITY, PERMISSION_DELETE_SWMS_ACTIVITY
} from "../../../../../dataProvider/permissionConstants";

export class SWMSActivity extends Component {
  state = {
    pageSize: 10,
  };

  componentDidMount() {
    this.props.actions.getSWMSActivity()
    .then(() => { })
    .catch((err) => { });

    this.props.actions
      .getAllOrgSWMS()
      .then(() => { })
      .catch((err) => { });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentTab !== this.props.currentTab) {
      this.props.reset();
    }
    if (!prevProps.breadCrumbs.active && this.props.breadCrumbs.active) {
      this.onPageChange(this.props.breadCrumbs.page)
    }
  }

  onPageChange = (arg) => {
    this.props.actions.setActivityPage(arg)
  };

  addRow = () => {
    this.props.history.push({
      pathname: this.props.history.location.pathname + "/manage_swms_activity",
      state: { mode: "add" }
    })
  };

  changeStatus = (rowId, value) => {
    this.props.actions.changeActivityStatus(rowId, value ? 1 : 0)
      .then(message => {
        notification.success({
          message: Strings.success_title,
          description: message ? message : Strings.generic_error,
          onClick: () => { },
          className: "ant-success",
        });
        this.props.actions.getSWMSActivity()
          .then(() => { })
          .catch((err) => { });
      })
      .catch((message) => {
        notification.error({
          message: Strings.error_title,
          description: message
            ? Array.isArray(message)
              ? message[0].message
              : Strings.generic_error
            : Strings.generic_error,
          onClick: () => { },
          className: "ant-error",
        });
      })
  };

  deleteRow = rowId => {
    this.props.actions.deleteActivity(rowId)
      .then(message => {
        notification.success({
          message: Strings.success_title,
          description: message ? message : Strings.generic_error,
          onClick: () => { },
          className: "ant-success",
        });
        this.props.actions.getSWMSActivity()
          .then(() => { })
          .catch((err) => { });
      })
      .catch((message) => {
        notification.error({
          message: Strings.error_title,
          description: message
            ? Array.isArray(message)
              ? message[0].message
              : Strings.generic_error
            : Strings.generic_error,
          onClick: () => { },
          className: "ant-error",
        });
      })
  }

  filter = (formValues) => {
    const serializedParams = serializeFilterParams(formValues)
    this.props.actions.getSWMSActivity(serializedParams)
      .then(() => { })
      .catch((err) => { });
  }

  reset = () => {
    this.props.actions.getSWMSActivity()
      .then(() => this.props.reset())
      .catch((err) => { });
  }

  render() {
    const { rowsData, generalCategory, category, taskType, roles, currentPage, history, handleSubmit, isDirty } = this.props;
    const { pageSize } = this.state;
    const hasAddAccess = getPermissions(MODULE_SWMS_ACTIVITY, PERMISSION_ADD_SWMS_ACTIVITY) ? true : false;
    const hasEditAccess = getPermissions(MODULE_SWMS_ACTIVITY, PERMISSION_EDIT_SWMS_ACTIVITY) ? true : false;
    const hasDeleteAccess = getPermissions(MODULE_SWMS_ACTIVITY, PERMISSION_DELETE_SWMS_ACTIVITY) ? true : false;

    return (
      <div className="new-swms-table">
        {getPermissions(MODULE_SWMS_ACTIVITY, PERMISSION_ADD_SWMS_ACTIVITY)
          ? <button
            type="button"
            className="bnt bnt-active add-row-swms-bnt add-activity-btn"
            onClick={() => this.addRow()}>
            Add New Activity
          </button>
          : <div></div>
        }

        <div className="swms-activity-filters-container">
          <fieldset className="form-group sf-form lsico">
            <Field
              name="activity_id"
              label="Activity ID"
              type="text"
              placeholder="Activity ID"
              component={customInput}
            />
          </fieldset>
          <fieldset className="form-group sf-form lsico">
            <Field
              name="activity"
              label="Activity Name"
              type="text"
              placeholder="Activity Name"
              component={customInput}
            />
          </fieldset>
          <fieldset className="form-group sf-form select-wibg popover-checkbox-filter">
            <Field
              name="general_category_id"
              label="SWMS Category"
              placeholder="SWMS Category"
              buttonTitle="Gen Cat"
              options={getFormattedPopBoxOptions(generalCategory)}
              component={popOverCheckBox}
            />
          </fieldset>
          <fieldset className="form-group sf-form select-wibg popover-checkbox-filter">
            <Field
              label="SWMS Method"
              placeholder="SWMS Method"
              name="swms_category_id"
              buttonTitle="Cat"
              options={getFormattedPopBoxOptions(category, "category")}
              component={popOverCheckBox}
            />
          </fieldset>
          <fieldset className="form-group sf-form lsico popover-checkbox-filter">
            <Field
              label={"Person Responsible"}
              placeholder={"Person Responsible"}
              name="person_responsible"
              buttonTitle="Resp"
              options={getFormattedPopBoxOptions(roles, "name")}
              component={popOverCheckBox}
            />
          </fieldset>
          <fieldset className="form-group sf-form select-wibg popover-checkbox-filter">
            <Field
              label={"Task Type"}
              placeholder={"Task Type"}
              name="task_type_id"
              buttonTitle="TT"
              options={getFormattedPopBoxOptions(taskType)}
              component={popOverCheckBox}
            />
          </fieldset>
          <fieldset className="form-group sf-form select-wibg popover-checkbox-filter filter-reset-swms-activity">
            <Button
              type="primary"
              onClick={handleSubmit(this.filter)}
              className="timesheet-filter-button"
            >Filter</Button>
          </fieldset>
          <fieldset className="form-group sf-form lsico filter-reset-swms-activity">
            <Button
              type="primary"
              onClick={() => this.reset()}
              disabled={!isDirty}
              className="timesheet-filter-button"
            >Reset</Button>
          </fieldset>
        </div>
        <div
          style={{
            maxWidth: "90vw",
            padding: "1rem"
          }}
        >
          <Table
            columns={fetchColumns(history, generalCategory, category, taskType, roles, this.deleteRow, this.changeStatus, hasAddAccess, hasEditAccess, hasDeleteAccess)}
            dataSource={rowsData}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              onChange: (a) => this.onPageChange(a),
            }}
            rowKey="id"
            bordered
            scroll={{ x: 1200 }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    breadCrumbs: state.SWMSMasters.breadCrumbs,
    rowsData: state.SWMSMasters.newActivityList,
    formValues: state.form.SWMSTable && state.form.SWMSTable.values,
    generalCategory: state.SWMSMasters.generalCategory,
    category: state.SWMSMasters.category,
    taskType: state.SWMSMasters.taskType,
    roles: state.roleManagement.roles,
    currentPage: state.SWMSMasters.activityCurrentPage,
    initialValues: {
      activity_id: '',
      activity: '',
      general_category_id: [],
      swms_category_id: [],
      person_responsible: [],
      task_type_id: [],
    },
    isDirty: isDirty('SWMSTable')(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(SWMSMastersActions, dispatch),
    roleManagementActions: bindActionCreators(roleManagementActions, dispatch),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "SWMSTable",
  })
)(SWMSActivity);
