import React, { useEffect } from "react";
import { Field, FormSection, formValueSelector } from "redux-form";
import { IconButton } from "@mui/material";
import { connect } from "react-redux";
import { components } from "react-select";

import { CustomSingleSelect } from "../../../../common/new-core-ui/CustomSingleSelect";
import { CustomCheckbox } from "../../../../common/customCheckbox";
import { customInput } from "../../../../common/custom-input";
import FormSelectStyle from "../FormSelectStyle";

import styles from "../../JobStyle.module.css";

const EnabledComponent = ({ selected, change, allData, loading }) => {

  const onRemove = (tlc) => {
    change(`tlc_section.enabled.item_${tlc.id}`, false);
    change("tlc_section.selected", selected.filter(x => x.id !== tlc.id));
    change("tlc_section.data", allData.filter(x => x.id !== tlc.id));
  }

  return (
    <div className="contianer-fluid px-0">
      <div className="row">
        {selected.length === 0 ? (<div className="col-12 text-center py-2" style={{ color: "red" }}>No Training, Licenses & Competencies Selected</div>) : ""}
        {
          selected.map((tlc, index) => (
            <div className="col-4 d-flex align-items-center py-1" key={index.toString()}>
              <div style={{ flex: "1 1 auto" }}>
                <Field
                  disabled={loading}
                  component={CustomCheckbox}
                  name={`item_${tlc.id}`}
                  className="cs-select-v2"
                  label={tlc.label}
                />
              </div>
              {tlc.isNew ? (<div>
                <IconButton disabled={loading} style={{ height: "auto", padding: "0px" }} onClick={() => onRemove(tlc)}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{ height: "12px", color: "red" }}>
                    <path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                  </svg>
                </IconButton>
              </div>) : ""}
            </div>
          ))
        }
      </div>
    </div>
  )
}


const TrainingLicensesCompetenciesSection = ({ allData = [], selected = [], change, newEntry = "", loading }) => {

  const onAdd = () => {
    if (newEntry && newEntry.trim().length > 2) {
      let newItem = newEntry.trim();
      let id = `custom_${newItem.replace(/\s/gm, "_")}`.toLowerCase();
      if (allData.findIndex(x => x.method_item.toLowerCase() === newItem.toLowerCase()) === -1) {
        let item = {
          method_item: newItem, id: id, isNew: true, linked_documents: null
        }
        change("tlc_section.data", [...allData, { ...item }]);
        change("tlc_section.selected", [...selected, { ...item, label: newItem, value: id }])
      }
      change("tlc_section.new_entry", "");
    }
  }

  useEffect(() => {
    let obj = allData.filter(x => selected.findIndex(y => y.value === x.id) === -1);
    obj.forEach(el => {
      change(`tlc_section.enabled.item_${el.id}`, undefined)
    })
  }, [selected, allData]);

  return (
    <div className="m-1 mb-2">
      <FormSection name="enabled" component={EnabledComponent} selected={selected} change={change} allData={allData} loading={loading} />
      <div className="contianer-fluid px-0 mt-2">
        <div className="row">
          <div className="col-4">
            <div className={styles.Label}>Select Training, Licenses & Competencies</div>
            <Field
              name="selected"
              component={CustomSingleSelect}
              options={allData.map(x => ({ label: x.method_item, value: x.id, ...x }))}
              styles={FormSelectStyle}
              otherProps={{
                isMulti: true,
                isDisabled: loading,
                closeMenuOnSelect: false,
                hideSelectedOptions: false,
                menuPortalTarget: document.body,
                components: { dropdownIndicator: <components.DownChevron /> }
              }}
            />
          </div>
          <div className="col-4 d-flex align-items-end" style={{ gap: "5px" }}>
            <div style={{ flex: "1 1 auto" }} onKeyDown={e => { if (e.key === "Enter") { e.preventDefault(); e.stopPropagation(); } }}>
              <div className={styles.Label}>Add Training, Licenses & Competencies</div>
              <Field
                name="new_entry"
                autoComplete="off"
                className={styles.TextField}
                component={customInput}
                disabled={loading}
              />
            </div>
            <div>
              <button type="button" className={styles.PrimaryButton} onClick={onAdd}>Add</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect((state) => ({
  allData: (formValueSelector("job_swms_form"))(state, "tlc_section.data"),
  selected: (formValueSelector("job_swms_form"))(state, "tlc_section.selected"),
  newEntry: (formValueSelector("job_swms_form"))(state, "tlc_section.new_entry")
}))(TrainingLicensesCompetenciesSection);