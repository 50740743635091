import React, { useEffect, useRef, useState } from "react";
import styles from "./SignOffStyles.module.css";

export default function CreateSignature(props) {
  const clearRef = useRef(null);

  const [width, setWidth] = useState(295);

  useEffect(() => {
    const clearButton = clearRef.current;
    const canvas = props.forwardedRef.current;
    const ctx2 = canvas.getContext("2d");
    let width = canvas.parentElement.clientWidth - 30
    if(width < 430) {
      setWidth(width);
    } else {
      setWidth(400)
    }

    let x = 0;
    let y = 0;
    let curX = 0;
    let curY = 0;

    let mouseDown = false;
    let mouseIn = false;

    const handleMove = (e) => {
      e.preventDefault();
      x = curX;
      y = curY;
      if (e.type === "touchmove") {
        let touch = e.changedTouches.length && e.changedTouches[0];
        curX = touch.clientX - canvas.getBoundingClientRect().left;
        curY = touch.clientY - canvas.getBoundingClientRect().top;
      } else {
        curX = e.clientX - canvas.getBoundingClientRect().left;
        curY = e.clientY - canvas.getBoundingClientRect().top;
      }
      if (mouseDown && mouseIn) {
        // requestAnimationFrame(() => {
        ctx2.beginPath();
        ctx2.moveTo(x, y);
        ctx2.lineTo(curX, curY);
        ctx2.strokeStyle = "black";
        ctx2.lineWidth = 2;
        ctx2.stroke();
        ctx2.closePath();
        // });
      }
    };

    canvas.addEventListener("mousedown", (e) => {
      e.preventDefault();
      x = curX;
      y = curY;
      curX = e.clientX - canvas.getBoundingClientRect().left;
      curY = e.clientY - canvas.getBoundingClientRect().top;
      mouseDown = true;
    });

    canvas.addEventListener("mousemove", handleMove, false);

    canvas.addEventListener("mouseup", (e) => {
      e.preventDefault();
      mouseDown = false;
    });

    canvas.addEventListener("mouseover", (e) => {
      e.preventDefault();
      mouseIn = true;
    });

    canvas.addEventListener("mouseout", (e) => {
      e.preventDefault();
      mouseIn = false;
      mouseDown = false;
    });

    
    canvas.addEventListener("touchstart", (e) => {
      e.preventDefault();
      x = curX;
      y = curY;
      let touch = e.changedTouches.length && e.changedTouches[0];
      curX = touch.clientX - canvas.getBoundingClientRect().left;
      curY = touch.clientY - canvas.getBoundingClientRect().top;
      mouseIn = true;
      mouseDown = true;
    });

    canvas.addEventListener("touchmove", handleMove, false);
    canvas.addEventListener("touchend", (e) => {
      e.preventDefault();
      mouseDown = false;
    });

    canvas.addEventListener("touchend", (e) => {
      e.preventDefault();
      mouseIn = false;
    });

    window.addEventListener("resize", () => {
      let width = canvas.parentElement.clientWidth - 30;
      if(width < 430) {
        setWidth(width)
      } else {
        setWidth(400);
      }
    })

    clearButton.addEventListener("click", () => {
      ctx2.clearRect(0, 0, canvas.width, canvas.height);
    });
  }, []);

  return (
    <div>
      <div className="my-2" style={{ width: '100%'}}>
        <canvas
          {...props}
          ref={props.forwardedRef}
          className="mx-auto d-block"
          style={{ border: "1px solid #ccc" }}
          width={width}
        />
      </div>
      <div className="text-center">
        <button className={`${styles.SmallGreyButton}`} ref={clearRef}>Clear</button>
      </div>
    </div>
  );
}
