import React, { Component } from "react";
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import {
  reduxForm,
  Field,
  FieldArray,
} from 'redux-form';
import moment from "moment";
import { validate } from '../../../../utils/Validations/industryValidation';
import * as action from '../../../../actions/reportManagementAction';
import { handleFocus } from '../../../../utils/common';
import { customInput } from "../../../common/custom-input";

import { CustomSelect } from "../../../common/customSelect";
import PerfectScrollbar from 'react-perfect-scrollbar'
import emailEditor from "../../../common/EmailEditor";
import { popOverCheckBox } from "../../../common/popOverCheckBox";
import styles from "../OHSContainer.module.css";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import { DatePicker, notification } from "antd";
import { Strings } from "../../../../dataProvider/localize";
const { RangePicker } = DatePicker;

class EditToolboxTalk extends Component {
  constructor(props) {
    super(props);
  }


  goBack =()=>{
     this.props.history.push('/dashboard/report-management');
  }
  onPayPeriodChange = (stringDates) => {
    if (stringDates && stringDates.length > 0) {
      this.props.change("start_date", stringDates[0]._d);
      this.props.change("end_date", stringDates[1]._d);
       this.setState({
        filters: {
          ...this.state.filters,
          start_date: stringDates[0]._d,
          end_date: stringDates[1]._d,
        },
      });
    }
  };
  state = {
    consequences: [],
    selectedHazards: [],
    editorStateValue: null,
    editorStateText: '',
    check:true
  }
  dateArr =[
    moment((new Date().setDate(new Date().getDate() - 14))),
    moment(new Date())
    ];
    setPickerDefaultRange = (date1, date2) => {
      this.dateArr[0] = moment(date1);
      this.dateArr[1] = moment(date2);
      return this.dateArr;
    };


  siteIds = [{}];
  clientIds = (val) => {
    let sites = this.props.clientListData.clients.sites;
    this.siteIds = sites.filter(item => val.includes(item.client_id));
  }
  jobIds = [{}];
  length = 10;
  siteId = (val) => {
    let jobs = this.props.clientListData.clients.jobs;
    this.jobIds = jobs.filter(item => val.includes(item.site_id));
  }
  editorState = (value) => {
    var body = value ? value : "";

    const html = body;
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
  };
  componentDidMount() {
    this.props.getClientList();
    this.props.getTypeList();
    this.props.getLicenceList();
  
    if (this.props.history.location.state && this.props.history.location.state.id) {
      this.props.getToolboxTalkbyId(this.props.history.location.state.id);
    }
  }
  getJobData() {
    console.log("get job api call")
  }
  removeEliment = (fields, index) => {
    fields= fields.splice(index, 1);
    return fields
  }


  successMessage = (msg) => {
    return notification.success({
      message: Strings.success_title,
      description: msg,
      onClick: () => { },
      className: "ant-success",
    });
  }
  errorMessage = (msg) => {
    return notification.error({
      message: Strings.error_title,
      description: msg,
      onClick: () => { },
      className: "ant-error",
    });
  }
  onSubmit = async (formData, event) => {
      if (this.props.history.location.state && this.props.history.location.state.id) {
      formData.id = this.props.history.location.state.id;
      this.props.updateReportManagementToolboxTalk(formData)
        .then((data) => {
          this.successMessage("Toolbox Talk updated successfully")
          this.props.history.push('/dashboard/report-management');
        })
        .catch((error) => {
          this.errorMessage("Something went wrong.");
        });
    } else {
      this.props.createReportManagementToolboxTalk(formData)
        .then((data) => {
          this.props.reset();
          this.successMessage("Toolbox Talk created successfully")
          this.props.history.push('/dashboard/report-management');

        })
        .catch((error) => {
          this.errorMessage("Something went wrong.");
        });
    }

  }
  dataPush = (e)=>{

if (this.props.initialValues) {
  this.props.initialValues.item.push(e);
}
  }
  renderMembers = ({ fields, meta: { error, submitFailed } }) => {

      if (this.props.initialValues.item && this.state.check) {
        this.setState({check:false})
        const item_data =this.props.initialValues.item
        if (fields.length <= item_data.length && this.props.initialValues.item) {
        item_data.map(item=>{
          if (fields.length !== item_data.length && this.props.initialValues.item) {
            fields.push()
          } 
        })
      }
      }else {
        if (fields.length === 0) {
          fields.push()
        }
      }


    return (
      <div className="sf-card-wrap mb-4">
        
        <div className="items-box">
          <h4 className="TabsContainer_tabHeader__3fQuw mb-0">Items</h4>
          <button className="bnt bnt-active add-row-swms-bnt btnw-50 mb-2 btn-item" type="button" onClick={(e) => fields.push(e)}><i class="fa fa-plus-circle mr-1" aria-hidden="true"></i> Add Item</button>
          <div className="item-table">
            <div class="table-responsive">
              <table className="table-border table w-100 item-table">
                <tbody>
                  <tr className="bg-color">
                    <th>Items</th>
                    <th>Description</th>
                    <th>Required Fields</th>
                  </tr>
        {/* {(this.props.initialValues && this.props.initialValues.item) && this.props.initialValues.item.map((member, index) => { */}
           {fields.map((member, index) => { 

return (

  <tr className="sf-form" key={index}>
    <td><fieldset className="sf-form">
      <Field
        label=""
        name={`${member}.item_name`}
        placeholder=""
        type="text"
        component={customInput}
      />
    </fieldset>
    </td>
    <td>
      <div className={styles.TextEditor}>
        <Field
          name={`${member}.description`}
          className={styles.TextEditorBg}
          type="text"
          component={emailEditor}
          editorState={
            this.props.formValues && this.props.formValues[`description_temp${index}`]
              ? this.props.formValues[`description_temp${index}`]
              : this.editorState(this.props.initialValues[`description_${index}`])
          }
          onEditorStateChange={(editorState) =>
            this.changeEditorState(editorState, member, index)
          }
        />
      </div>
    </td>
    <td>
      <div className="multi-fields required_field">

        <Field
          name={`${member}.licence_ids`}
          id={`licence_ids_${index}`}
          options={this.props.licenceListData && this.props.licenceListData.license_type_list.map(licence => ({
            label: licence.name,
            value: licence.id

          }))}


          component={popOverCheckBox}

          buttonTitle="License"
        />

      </div>
    </td>
    <td className="bg-white align-middle">
      <div className="row-delete-btn">
        <a onClick={() => this.removeEliment(fields, index)}><i className="fa fa-trash" aria-hidden="true" /></a>
      </div>
    </td>
  </tr>
)
}
)} 

                  {/* {fields.map((member, index) => {

                    return (

                      <tr className="sf-form" key={index}>
                        <td><fieldset className="sf-form">
                          <Field
                            label=""
                            id={`item_${index}`}
                            name={`item_${index}`}
                            placeholder=""
                            type="text"
                            component={customInput}
                          />
                        </fieldset>
                        </td>
                        <td>
                          <div className={styles.TextEditor}>
                            <Field
                              id={`description_${index}`}
                              name={`description_${index}`}
                              className={styles.TextEditorBg}
                              type="text"
                              component={emailEditor}
                              editorState={
                                this.props.formValues && this.props.formValues[`controls_temp${index}`]
                                  ? this.props.formValues[`controls_temp${index}`]
                                  : this.editorState()
                              }
                              onEditorStateChange={(editorState) =>
                                this.changeEditorState(editorState, index)
                              }
                            />
                          </div>
                        </td>
                        <td>
                          <div className="multi-fields required_field">

                            <Field
                              name={`required_field_${index}`}
                              id={`required_field_${index}`}
                              options={this.props.licenceListData && this.props.licenceListData.license_type_list.map(licence => ({
                                label: licence.name,
                                value: licence.id

                              }))}
                              component={popOverCheckBox}

                              buttonTitle="License"
                            />

                          </div>
                        </td>
                        <td className="bg-white align-middle">
                          <div className="row-delete-btn">
                            <a onClick={() => this.removeEliment(fields, index)}><i className="fa fa-trash" aria-hidden="true" /></a>
                          </div>
                        </td>
                      </tr>
                    )
                  }
                  )} */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    )
  }


  changeEditorState = (editorState, member, index) => {
    this.props.change(`description_temp${index}`, editorState);
    this.props.change(`${member}.description`,

      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };
  changeEditorStateComments = (editorState) => {
    this.props.change("comment_temp", editorState);
    this.props.change("comments",
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };
  render() {
    const { handleSubmit, formValue, initialValues } = this.props;
    if (initialValues && initialValues.comments) {
      this.editorState(initialValues.comments);

    }
    if (formValue && formValue.client_id) {
      this.clientIds(formValue.client_id)
      this.siteId(formValue.site_id)
    this.setPickerDefaultRange(formValue.start_date ? formValue.start_date : this.dateArr[0],formValue.end_date ? formValue.end_date : this.dateArr[1])
      //this.props.change("start_date",stringDates[0]._d);
      //this.props.change("end_date",stringDates[1]._d);
    }
    return (

      <div className="main-container">
        {/* <form onSubmit={handleSubmit(this.onSubmit)} className="antt-form"> */}
        <form onSubmit={handleSubmit(this.onSubmit)} className="antt-form" id="addNewToolboxTalk">
          <div className="new-swms-table toolbox-talk ">
            <div className="sf-card-wrap mb-4">
              {/* <h4 className="section-heading"></h4> */}
              <h4 className="TabsContainer_tabHeader__3fQuw mb-0">Create Toolbox Talk:</h4>
              <ul className="search-list">
                <li>
                  <div className="form-group sf-form">
                    <fieldset className="form-group sf-form">
                      <Field
                        label="Record ID"
                        name="id"
                        placeholder=""
                        type="text"
                        id="id"
                        component={customInput} />
                    </fieldset>
                  </div>
                </li>
                <li>
                  <div className="form-group sf-form">
                    <fieldset className="form-group sf-form">
                      <Field
                        label="Record Name"
                        name="record_name"
                        placeholder=""
                        type="text"
                        id="record_name"
                        component={customInput} />
                    </fieldset>
                  </div>
                </li>
                <li>
                  <div className="form-group sf-form">
                    <fieldset className="form-group sf-form">
                      <Field
                        label="Status"
                        name="status"
                        placeholder=""
                        type="text"
                        id="status"
                        options={
                          [{
                            title: "Active",
                            value: "1"
                          },
                          {
                            title: "Inactive",
                            value: "2"
                          }
                          ]
                        }
                        component={CustomSelect} />

                    </fieldset>
                  </div>
                </li>
                <li>
                  <div className="form-group sf-form">
                    <fieldset className="form-group sf-form">
                      <Field
                        label="Priority"
                        name="priority"
                        placeholder=""
                        type="number"
                        id="priority"
                        component={customInput} />
                    </fieldset>
                  </div>
                </li>
              </ul>
            </div>
            <div className="sf-card-wrap mb-4">
              <h4 className="TabsContainer_tabHeader__3fQuw mb-0">Applicability:</h4>
              <ul className="search-list applicability-list">
                <li>
                  <div className="form-group sf-form">
                    <Field
                      label="Client"
                      name="client_id"
                      component={popOverCheckBox}
                      options={this.props.clientListData.clients && this.props.clientListData.clients.clients.map(client => ({
                        label: client.name,
                        value: client.id

                      }))}

                      buttonTitle="Client"
                      onChange={(val) => this.clientIds(val)}
                    />
                  </div>
                </li>
                <li>
                  <div className="form-group sf-form">
                    <Field
                      label="Site Id"
                      name="site_id"
                      component={popOverCheckBox}
                      options={
                        this.siteIds.map(item => ({
                          label: item.site_name,
                          value: item.site_id
                        }))}

                      buttonTitle="Site Id"
                      onChange={(val) => this.siteId(val)}
                    />

                  </div>
                </li>
                <li>
                  <div className="form-group sf-form">

                    <Field
                      label="Job Id"
                      name="job_id"
                      component={popOverCheckBox}
                      options={
                        this.jobIds.map(item => ({
                          label: item.job_name,
                          value: item.job_id
                        }))}

                      buttonTitle="Job Id"
                    />

                  </div>
                </li>
                <li className="date-range">
                  <div className="form-group sf-form">
                    <label>Valid Date Range </label>

                    <fieldset className="form-group sf-form lsico">
                      <div style={{ maxWidth: "250px" }}>

                        <RangePicker
                        defaultValue={this.dateArr}
                          onChange={this.onPayPeriodChange}
                          size="small"
                        />
                      </div>
                    </fieldset>


                  </div>
                </li>
                <li>
                  <div className="form-group sf-form">

                    <Field
                      label="Task Type"
                      name="task_type"
                      id="task_type"
                      component={popOverCheckBox}
                      options={this.props.typeListData && this.props.typeListData.data.map(type => ({
                        label: type.title,
                        value: type.id

                      }))}
                      buttonTitle="Task Type"
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div className="sf-card-body p-0">
              <PerfectScrollbar className="sf-ps-scroll" onScrollX>
                
                <div className="sf-ps-scroll-content">
                  <FieldArray
                    name="org_items" component={this.renderMembers} />
                </div>
  
              </PerfectScrollbar>

            </div>

            <div className="comments-box sf-form">
              <h4 className="section-heading">Comments:</h4>
              <div className={styles.TextEditor}>
                <Field
                  id="comments"
                  name="comments"
                  className={styles.TextEditorBg}
                  type="text"
                  component={emailEditor}
                  editorState={
                    this.props.formValues && this.props.formValues.comment_temp
                      ? this.props.formValues.comment_temp
                      : this.editorState(initialValues.comments)
                  }
                  onEditorStateChange={(editorState) =>
                    this.changeEditorStateComments(editorState)
                  }
                />
              </div>

            </div>
            <div className="bottom-save-btn">
              <button className="bnt bnt-active" onClick={this.goBack}><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button> 
              <button type="submit" className="bnt bnt-active"><i class="fa fa-floppy-o" aria-hidden="true"></i> Save</button>
            </div>

          </div>
        </form>
      </div>
    )
  }
}


const mapStateToProps = (state) => {

  const values = state.reportManagement && state.reportManagement.toolboxTalkDetail;
  const items_list = (values && values.items.length > 0) && values.items.reduce((acc, item, index) => {
    acc[`item_${index}`] = item.item_name
    return acc
  }, {})
  const items_description_list = (values && values.items.length > 0) && values.items.reduce((acc, item, index) => {
    acc[`description_${index}`] = item.item
    return acc
  }, {})
  const licence_ids = (values && values.items.length > 0) && values.items.reduce((acc, item, index) => {
    acc[`licence_ids_${index}`] = item.licence_ids
    return acc
  }, {})


  return {
    clientListData: state.reportManagement.clientListData,
    typeListData: state.reportManagement.typeListData,
    licenceListData: state.reportManagement.licenceListData,
    reportManagementToolboxList: state.reportManagement.reportManagementToolboxList,
    formValues: state.form.addNewToolboxTalk && state.form.addNewToolboxTalk.values,
    toolboxTalkDetail: state.reportManagement.toolboxTalkDetail,
    initialValues: {
      id: values && values.id,
      record_name: values && values.record_name,
      status: values && values.status == 1 ? "Active" : "Inactive",
      priority: values && values.priority,
      client_id: values && values.client_id,
      site_id: values && values.site_id,
      job_id: values && values.job_id,
      comments: values && values.comments,
      task_type: values && values.type_id,
      org_items: values && values.items,
      item: values && values.items,
      ...items_list,
      ...items_description_list,
      ...licence_ids
    },
  itemsLength: values && values.items.length ,

    formValue: values
  }
}


const mapDispatchToprops = dispatch => {

  return {
    createReportManagementToolboxTalk: (formData) => dispatch(action.createReportManagementToolboxTalk(formData)),
    updateReportManagementToolboxTalk: (formData) => dispatch(action.updateReportManagementToolboxTalk(formData)),
    getClientList: bindActionCreators(action.getClientList, dispatch),
    getTypeList: bindActionCreators(action.getTypeList, dispatch),
    getLicenceList: bindActionCreators(action.getLicenceList, dispatch),
    getToolboxTalkbyId: bindActionCreators(action.getToolboxTalkbyId, dispatch),

  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToprops),
  reduxForm({
    form: 'addNewToolboxTalk', validate, onSubmitFail: (errors, dispatch, sub, props) => {
      handleFocus(errors, "#");
    },
    enableReinitialize: true
  })
)(EditToolboxTalk)
