export const serializeSiteTasks = (tasks) => {
  if (tasks && tasks.length) {
    let allTasks = [];
    tasks.forEach((task) => {
      let taskFiles = task.job_on_site_task_attachments && task.job_on_site_task_attachments.map(attachment => {
        return { ...attachment, job_id: task.job_id, attachment_id: task.id, task_label: task.task_label, task_name: task.task_name }
      })
      allTasks.push(...taskFiles)
    });
    return allTasks;
  } else {
    return [];
  }
}