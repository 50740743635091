import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import SignUp from '../auth/signup';
import SignIn from '../auth/signin';
import verifyAttributes from '../auth/verifyAttributes';
import Forgotpass from '../auth/forgotpass';
import Reseturpass from '../auth/reseturpass';
import Forgotusername from '../auth/forgotusername';
import Dashboard from '../dashboard/dashboard';
import SignInCode from '../auth/login-sms-code-form';
import PreviewTaskImages from "../v2/JobDocument/Preview/PreviewTaskImages";
import ClientSignOff from '../v2/Job/TasksSignOff/ClientSignOff';
import ClientRedirectPage from '../common/ClientRedirect/ClientRedirectPage';

const PreviewJobDocument = React.lazy(() => import('../v2/JobDocument/Preview/PreviewJobDocument'));

export const browserHistory = createBrowserHistory();

export default function HeadRoute() {

	return (
		<React.Suspense fallback={<span>Loading...</span>}>
			<Router history={browserHistory}>
				<Switch>
					<Route path='/dashboard' component={Dashboard} />
					<Route exact path='/' component={() => <Redirect to="/signin" />} />
					<Route exact path='/verify-account' component={verifyAttributes} />
					<Route exact path='/signup' component={SignUp} />
					<Route exact path='/signin' component={SignIn} />
					<Route exact path='/forgotpass' component={Forgotpass} />
					<Route exact path='/reseturpass' component={Reseturpass} />
					<Route exact path='/forgotusername' component={Forgotusername} />
					<Route exact path='/signin_code' component={SignInCode} />
					<Route exact path='/job_report_preview' render={() => (
						<ClientRedirectPage />)} />
					<Route exact path='/task_image_preview' render={() => (
						<ClientRedirectPage>
							<PreviewTaskImages />
						</ClientRedirectPage>)} />
					<Redirect from='/client/job-doc-preview/:orgid/:jobid' to="/client/v2/job-doc-preview/:orgid/:jobid" />
					<Route path='/client/v2/job-doc-preview/:orgid/:jobid' render={() => (
						<ClientRedirectPage>
							<PreviewJobDocument />
						</ClientRedirectPage>)} />
					<Route path="/signoff-tasks/:orgId/:jobId/:signOffCode" render={() => (
						<ClientRedirectPage>
							<ClientSignOff />
						</ClientRedirectPage>)} />
					<Redirect path="*" to={{ pathname: "/dashboard" }} />
				</Switch>
			</Router>
		</React.Suspense>
	)
}
