import React from 'react';

export const customTextarea = ({ input, label, placeholder, disabled, type, maxLength, autoComplete = "none", meta: { touched, error, warning }, className = "", rows = 2, otherProps = {} }) => {

  return (
    <div>
      {label && <label>{label}</label>}
      <div>
        <textarea id={`${input.name}-focus`} {...input} label={label} disabled={disabled} placeholder={placeholder} rows={rows} type={type} maxLength={maxLength} autoComplete={autoComplete} className={className} {...otherProps} />
        {touched && ((error && <span className="error-input">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </div>
  )
}


export const customTextarea2 = ({ input, label, placeholder, disabled, type, autoComplete = "off", maxLength, meta: { touched, error, warning } }) => {

  return (
    <div>
      <label>{label}</label>
      <div >
        <textarea autoComplete={autoComplete} style={{ height: "170px" }} id={`${input.name}-focus`} {...input} label={label} disabled={disabled} placeholder={placeholder} type={type} maxLength={maxLength} />
        {touched && ((error && <span className="error-input">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </div>
  )
}
