import React, { useEffect, useMemo, useRef, useState, useCallback, memo } from "react";
import styles from "./DashboardStyles.module.css";
import { useDispatch } from 'react-redux';
import { IconButton, Tooltip } from "@mui/material";
import { NAV_MENU_ICONS } from "./navigationMenu";
import { NavLink, useRouteMatch, useHistory } from "react-router-dom";
import { signOut } from '../../actions';

import logo from "../../assets/images/logov2.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const DashboardLink = ({ module }) => {
  const match = useRouteMatch();
  return (
    <NavLink
      activeClassName={styles.ActiveGroup}
      className={styles.ModuleLink}
      to={{ pathname: match.path + module.linkTo, state: { fromLink: true } }}
    >
      <div style={{ fontSize: "25px" }}>{module.iconType()}</div><div>{module.module_name}</div>
    </NavLink>
  )
}

const DashboardCategorization = ({ modules = {} }) => {

  const [group, setGroup] = useState("profile");
  const subMenu = useRef();
  const match = useRouteMatch();

  const history = useHistory();
  const dispatch = useDispatch();
  const [active, setActive] = useState("dashboard");

  const location = useLocation();

  const groups = ["dashboard", "profile", "operations", "finance", "masters"];

  const groupedModuleData = {
    "dashboard": ["dashboard", "reports"],
    "profile": ["profile"],
    "operations": ["contracts", "request_for_quotes", "quotes", "tasks", "calendar", "rostering", "timesheet", "ohs"],
    "finance": ["accounts_receivable", "accounts-payable", "timesheet-management"],
    "masters": ["organization", "clients", "site_management", "userManagement", "rolesmanagement", "swms", "organisationCertificates", "service_type_management",
      "pay_classification_management", "expense-accounts", "contractors_management",
      "license-types", "additional-fields", "priority_management", "sms-templates", "manage_email_templates", "pdf_template_management"]
  }

  const groupedModules = useMemo(() => {
    let allModulesGroups = {};
    for (let s in groupedModuleData) {
      let mod = [];
      groupedModuleData[s].forEach(k => {
        if (modules[k] && modules[k].show_in_sidebar) {
          let link = "/" + modules[k].slug;
          let icon = NAV_MENU_ICONS.filter(x => x.linkTo === link);
          if (icon && icon.length > 0) {
            mod.push({ ...modules[k], ...icon[0] });
          }
        }
      })
      if (mod.length > 0) {
        allModulesGroups[s] = [...mod];
      }
    }
    return allModulesGroups;
  }, [modules, groupedModuleData])


  useEffect(() => {
    if (location && location.pathname && groupedModules) {
      groups.forEach(group => {
        if (groupedModules[group]) {
          groupedModules[group].forEach(module => {
            let index = location.pathname.indexOf(match.path + module.linkTo);
            if (index === 0) {
              // console.log(location.pathname, match.path + module.linkTo, group)
              setActive(group);
            }
          })
        }
      })

    }
  }, [location, setActive, groupedModules])

  // useEffect(() => {
  //   console.log(modules);
  // }, [modules])

  const handleHover = (name) => {
    subMenu.current.classList.remove(styles.SubMenu);
    setGroup(name);
  }

  const handleOut = () => {
    subMenu.current.classList.add(styles.SubMenu);
  }

  const logout = useCallback(() => {
    dispatch(signOut()).catch(err => {
      console.log(err);
    });
    history.push({ pathname: '/signin' })
  }, []);

  // const setActiveGroup = useCallback((value = null, group) => {
  //   console.log(value, group)
  //   // console.log(group, isActive);
  //   if (value && active !== group) {
  //     setActive(group);
  //   }
  //   // return styles.ModuleLink;
  // }, [setActive, active])


  return (
    <>
      <nav className={styles.Nav}>
        <div className="py-2">
          <img src={logo} style={{ height: "40px" }} alt="Logo FM Farm" />
        </div>
        <div className={styles.NavItemsWrap}>
          <div>
            <ul className={styles.CategAlign}>
              {groupedModules.dashboard ? (
                <li className={styles.IconsGap}>
                  <Tooltip title="Dashboard">
                    <IconButton onMouseEnter={() => handleHover("dashboard")} className={`${styles.IconBtn} ${styles.CategoryIconColor} ${active === "dashboard" ? styles.ActiveGroup : ""}`}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: "30px", width: "30px" }}>
                        <path fill="currentColor" d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM288 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM256 416c35.3 0 64-28.7 64-64c0-17.4-6.9-33.1-18.1-44.6L366 161.7c5.3-12.1-.2-26.3-12.3-31.6s-26.3 .2-31.6 12.3L257.9 288c-.6 0-1.3 0-1.9 0c-35.3 0-64 28.7-64 64s28.7 64 64 64zM176 144a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM96 288a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm352-32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
                      </svg>
                    </IconButton>
                  </Tooltip>
                </li>
              ) : null}
              <li className={styles.IconsGap}>
                <NavLink to={{ pathname: match.path + "/profile", state: { fromLink: true } }}>
                  <Tooltip title="Profile">
                    <IconButton onMouseOver={() => handleHover("profile")} className={`${styles.IconBtn} ${styles.CategoryIconColor} ${active === "profile" ? styles.ActiveGroup : ""}`}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style={{ height: "30px", width: "30px" }}>
                        <path fill="currentColor" d="M0 96l576 0c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96zm0 32V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128H0zM64 405.3c0-29.5 23.9-53.3 53.3-53.3H234.7c29.5 0 53.3 23.9 53.3 53.3c0 5.9-4.8 10.7-10.7 10.7H74.7c-5.9 0-10.7-4.8-10.7-10.7zM176 192a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm176 16c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16z" />
                      </svg>
                    </IconButton>
                  </Tooltip>
                </NavLink>
              </li>
              {groupedModules.operations ? (
                <li className={styles.IconsGap}>
                  <Tooltip title="Operations">
                    <IconButton onMouseEnter={() => handleHover("operations")} className={`${styles.IconBtn} ${styles.CategoryIconColor} ${active === "operations" ? styles.ActiveGroup : ""}`}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: "30px", width: "30px" }}>
                        <path fill="currentColor" d="M256 96c38.4 0 73.7 13.5 101.3 36.1l-32.6 32.6c-4.6 4.6-5.9 11.5-3.5 17.4s8.3 9.9 14.8 9.9H448c8.8 0 16-7.2 16-16V64c0-6.5-3.9-12.3-9.9-14.8s-12.9-1.1-17.4 3.5l-34 34C363.4 52.6 312.1 32 256 32c-10.9 0-21.5 .8-32 2.3V99.2c10.3-2.1 21-3.2 32-3.2zM132.1 154.7l32.6 32.6c4.6 4.6 11.5 5.9 17.4 3.5s9.9-8.3 9.9-14.8V64c0-8.8-7.2-16-16-16H64c-6.5 0-12.3 3.9-14.8 9.9s-1.1 12.9 3.5 17.4l34 34C52.6 148.6 32 199.9 32 256c0 10.9 .8 21.5 2.3 32H99.2c-2.1-10.3-3.2-21-3.2-32c0-38.4 13.5-73.7 36.1-101.3zM477.7 224H412.8c2.1 10.3 3.2 21 3.2 32c0 38.4-13.5 73.7-36.1 101.3l-32.6-32.6c-4.6-4.6-11.5-5.9-17.4-3.5s-9.9 8.3-9.9 14.8V448c0 8.8 7.2 16 16 16H448c6.5 0 12.3-3.9 14.8-9.9s1.1-12.9-3.5-17.4l-34-34C459.4 363.4 480 312.1 480 256c0-10.9-.8-21.5-2.3-32zM256 416c-38.4 0-73.7-13.5-101.3-36.1l32.6-32.6c4.6-4.6 5.9-11.5 3.5-17.4s-8.3-9.9-14.8-9.9H64c-8.8 0-16 7.2-16 16l0 112c0 6.5 3.9 12.3 9.9 14.8s12.9 1.1 17.4-3.5l34-34C148.6 459.4 199.9 480 256 480c10.9 0 21.5-.8 32-2.3V412.8c-10.3 2.1-21 3.2-32 3.2z" />
                      </svg>
                    </IconButton>
                  </Tooltip>
                </li>
              ) : null}
              {groupedModules.finance ? (
                <li className={styles.IconsGap}>
                  <Tooltip title="Finance">
                    <IconButton onMouseEnter={() => handleHover("finance")} className={`${styles.IconBtn} ${styles.CategoryIconColor} ${active === "finance" ? styles.ActiveGroup : ""}`}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: "30px", width: "30px" }}>
                        <path fill="currentColor" d="M326.7 403.7c-22.1 8-45.9 12.3-70.7 12.3s-48.7-4.4-70.7-12.3c-.3-.1-.5-.2-.8-.3c-30-11-56.8-28.7-78.6-51.4C70 314.6 48 263.9 48 208C48 93.1 141.1 0 256 0S464 93.1 464 208c0 55.9-22 106.6-57.9 144c-1 1-2 2.1-3 3.1c-21.4 21.4-47.4 38.1-76.3 48.6zM256 84c-11 0-20 9-20 20v14c-7.6 1.7-15.2 4.4-22.2 8.5c-13.9 8.3-25.9 22.8-25.8 43.9c.1 20.3 12 33.1 24.7 40.7c11 6.6 24.7 10.8 35.6 14l1.7 .5c12.6 3.8 21.8 6.8 28 10.7c5.1 3.2 5.8 5.4 5.9 8.2c.1 5-1.8 8-5.9 10.5c-5 3.1-12.9 5-21.4 4.7c-11.1-.4-21.5-3.9-35.1-8.5c-2.3-.8-4.7-1.6-7.2-2.4c-10.5-3.5-21.8 2.2-25.3 12.6s2.2 21.8 12.6 25.3c1.9 .6 4 1.3 6.1 2.1l0 0 0 0c8.3 2.9 17.9 6.2 28.2 8.4V312c0 11 9 20 20 20s20-9 20-20V298.2c8-1.7 16-4.5 23.2-9c14.3-8.9 25.1-24.1 24.8-45c-.3-20.3-11.7-33.4-24.6-41.6c-11.5-7.2-25.9-11.6-37.1-15l-.7-.2c-12.8-3.9-21.9-6.7-28.3-10.5c-5.2-3.1-5.3-4.9-5.3-6.7c0-3.7 1.4-6.5 6.2-9.3c5.4-3.2 13.6-5.1 21.5-5c9.6 .1 20.2 2.2 31.2 5.2c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-6.5-1.7-13.7-3.4-21.1-4.7V104c0-11-9-20-20-20zM48 352H64c19.5 25.9 44 47.7 72.2 64H64v32H256 448V416H375.8c28.2-16.3 52.8-38.1 72.2-64h16c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V400c0-26.5 21.5-48 48-48z" />
                      </svg>
                    </IconButton>
                  </Tooltip>
                </li>
              ) : null}
              {groupedModules.masters ? (
                <li className={styles.IconsGap}>
                  <Tooltip title="Masters">
                    <IconButton onMouseEnter={() => handleHover("masters")} className={`${styles.IconBtn} ${styles.CategoryIconColor} ${active === "masters" ? styles.ActiveGroup : ""}`}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: "30px", width: "30px" }}>
                        <path fill="currentColor" d="M176 88l0 40 160 0 0-40c0-4.4-3.6-8-8-8L184 80c-4.4 0-8 3.6-8 8zm-48 40l0-40c0-30.9 25.1-56 56-56l144 0c30.9 0 56 25.1 56 56l0 40 28.1 0c12.7 0 24.9 5.1 33.9 14.1l51.9 51.9c9 9 14.1 21.2 14.1 33.9l0 92.1-128 0 0-32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 32-128 0 0-32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 32L0 320l0-92.1c0-12.7 5.1-24.9 14.1-33.9l51.9-51.9c9-9 21.2-14.1 33.9-14.1l28.1 0zM0 416l0-64 128 0c0 17.7 14.3 32 32 32s32-14.3 32-32l128 0c0 17.7 14.3 32 32 32s32-14.3 32-32l128 0 0 64c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64z" />
                      </svg>
                    </IconButton>
                  </Tooltip>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
        <div className="py-2">
          <Tooltip title="Logout">
            <IconButton onClick={logout} className={`${styles.IconBtn} ${styles.CategoryIconColor}`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: "30px", width: "30px" }}>
                <path fill="currentColor" d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z" />
              </svg>
            </IconButton>
          </Tooltip>
        </div>
      </nav>
      <div className={`${styles.Drawer} ${styles.SubMenu}`} onMouseLeave={handleOut} ref={subMenu}>
        <div className={styles.ModuleGroup} style={{ overflow: "hidden auto" }}>
          <div className="d-flex" style={{ gap: "15px", flexDirection: "column" }}>
            {groupedModules[group] ? groupedModules[group].map(module => (
              <DashboardLink module={module} group={group} key={module.slug} />
            )) : null}
          </div>
        </div>
      </div>
    </>
  )
}


export default memo(DashboardCategorization);