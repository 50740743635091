import React, { useEffect, useRef, useState } from "react"
import { Checkbox, DatePicker, notification, Pagination } from "antd";
import { useDispatch } from "react-redux";
import { cancelTask, getDeletableFrequencies } from "../../../../../actions/v2/JobActions";
import { Strings } from "../../../../../dataProvider/localize";
import { CircularProgress, LinearProgress, Typography } from "@mui/material";
import { currencyFormat } from "../../../../../utils/common";
import moment from "moment";

import v2Styles from "../../../v2Styles.module.css";
import "../../../v2Styles.css";

const { RangePicker } = DatePicker;

export default function CancelTaskFrequenciesModal({ task, job_id, job_total_amount, refreshJobBoard, onClose }) {

  const abortController = useRef();
  const dispatch = useDispatch();

  const [taskDates, setTaskDates] = useState(null);
  const [frequencies, setFrequencies] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [selected, setSelected] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [lastDates, setLastDates] = useState(null);
  const [allFreqs, setAllFreqs] = useState({});

  const [submitting, setSubmitting] = useState(false);

  const onClear = () => {
    loadFrequencies(1, null);
    setAllSelected(false);
    setSelected([]);
  }

  const onFilter = () => {
    loadFrequencies(1, taskDates);
    setAllSelected(false);
    setSelected([]);
  }

  const loadFrequencies = (pageNo = 1, date = null) => {
    setTaskDates(date);
    setLastDates(date);
    if (page !== pageNo) {
      setPage(pageNo);
    }
    setLoading(true);
    abortController.current = new AbortController();
    const params = {
      job_id: job_id,
      task_id: task.task_id,
      page: pageNo,
      ...(date && date.length === 2 ? ({
        date_range: `["${moment(date[0]).format("YYYY-MM-DD")}", "${moment(date[1]).format("YYYY-MM-DD")}"]`
      }) : {})
    }
    dispatch(getDeletableFrequencies(abortController.current.signal, params)).then((data) => {
      setCount(data.count);
      setFrequencies(data.rows);
      setAllFreqs(d => ({ ...d, ...data.rows.reduce((obj, x) => ({ ...obj, [x.quote_task_id]: x }), {}) }))
      setLoading(false);
    }).catch(err => {
      if (err) {
        setLoading(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: "ant-error"
        })
        onClose();
      }
    })
  }

  useEffect(() => {
    loadFrequencies();
  }, [])

  const goToPage = (pageNo) => {
    loadFrequencies(pageNo, taskDates);
  }

  const onCheckChange = (e, freq_id) => {
    if (e.target.checked) {
      setSelected(freqs => [...freqs, freq_id])
    } else {
      setSelected(freqs => freqs.filter(x => x !== freq_id));
    }
  }

  const onAllCheck = (e) => {
    if (e.target.checked) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
      setSelected([]);
    }
  }

  const cancelFrequencies = () => {
    if (allSelected || selected.length > 0 && task && task.task_id) {
      setSubmitting(true);
      let selectedFreq = [];
      if (selected && selected.length > 0) {
        selected.forEach(id => {
          selectedFreq.push(allFreqs[id]);
        })
      }
      const formData = {
        job_id: job_id,
        job_cost: job_total_amount,
        task_id: task.task_id,
        task_cost: task.task_cost,
        booking_type: task.booking_type,
        ...(allSelected ? { is_select_all: 1 } : { selected_frequencies: selectedFreq.map(x => ({ id: x.quote_task_id, freq_cost: x.task_amount, start_date: x.start_date, end_date: x.end_date })) }),
        ...(lastDates && lastDates.length === 2 ? ({
          date_range: `["${moment(lastDates[0]).format("YYYY-MM-DD")}", "${moment(lastDates[1]).format("YYYY-MM-DD")}"]`
        }) : {})
      }
      dispatch(cancelTask(formData)).then(message => {
        setSubmitting(false);
        notification.success({
          message: Strings.success_title,
          description: message ? message : Strings.generic_error,
          className: 'ant-success'
        });
        refreshJobBoard();
        onClose();
      }).catch(err => {
        setSubmitting(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: 'ant-error'
        })
      })
    }
  }

  return (
    <div className="v2-modal-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={v2Styles.ModalHeading}>Select the Task Frequencies for Task {task.task_number} to Cancel</div>
            <div>
              <hr />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <RangePicker
              className="cs-v3-range-picker"
              dropdownClassName="cs-datepicker-dropdown"
              allowClear format="DD-MM-YYYY"
              placeholder={["Start Date", "End Date"]}
              value={taskDates}
              onChange={v => setTaskDates(v)} />
          </div>
          <div className="col-6 text-right">
            <button onClick={() => onFilter()} className={v2Styles.PrimaryButton}>Filter</button>
            <button className={`${v2Styles.OutlinedButton} ml-1`} onClick={() => onClear()}>Clear</button>
          </div>
        </div>
        <div className="row">
          <div className="col-12 pb-1 pt-4">
            <div className="pl-1 d-flex justify-content-between">
              <Checkbox onChange={onAllCheck} className="cs-select-v2" checked={allSelected || (count > 0 && selected.length === count)}>Select All</Checkbox>
              <div className="text-right">{(allSelected || (count > 0 && selected.length === count)) ? "All Frequencies Selected for Cancellation" : (selected.length > 0) ? `${selected.length} ${selected.length === 1 ? "Frequency" : "Frequencies"} selected for Cancellation` : ""} </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div style={{ height: "4px" }}>
              {loading ? (<LinearProgress />) : null}
            </div>
            <div style={{ maxHeight: "500px", overflow: "hidden auto" }}>
              <table className="table table-sm cs-table cs-table-v2">
                <thead>
                  <tr>
                    <th style={{ width: "30px" }}></th>
                    <th style={{ width: "50%" }}>Task</th>
                    <th style={{ width: "20%" }}>Value</th>
                    <th style={{ width: "30%" }}>Dates</th>
                  </tr>
                </thead>
                <tbody>
                  {frequencies ? frequencies.map(freq => (
                    <tr key={freq.quote_task_id}>
                      <td><Checkbox onChange={e => onCheckChange(e, freq.quote_task_id)} checked={allSelected || selected.includes(freq.quote_task_id)} className="cs-select-v2"></Checkbox></td>
                      <td title={`${freq.quote_task_label} - ${task.task_name}`}><Typography noWrap className={v2Styles.tableText} style={{ fontSize: "13px" }}>{freq.quote_task_label} - {task.task_name}</Typography></td>
                      <td>{freq.task_amount ? currencyFormat(freq.task_amount) : ""}</td>
                      <td>{freq.start_date ? moment(freq.start_date).format("DD/MM/YYYY") : ""} - {freq.end_date ? moment(freq.end_date).format("DD/MM/YYYY") : ""}</td>
                    </tr>
                  )) : null}
                  {!loading && frequencies.length === 0 ? (
                    <tr>
                      <td colSpan={4} className="text-center" style={{ color: "red" }}>No Frequencies found.</td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 pt-2 d-flex justify-content-end">
            {count && count > 0 ?
              <Pagination
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Frequencies`}
                className="cs-pagination-v2"
                total={count}
                current={page}
                pageSize={15}
                onChange={goToPage}
              /> : ''
            }
          </div>
        </div>
        <div className="row">
          <div className="col-12 pt-4 d-flex align-items-center justify-content-end" style={{ gap: "10px" }}>
            {submitting ? <CircularProgress size={20} /> : ""}
            <button onClick={() => cancelFrequencies()} className={v2Styles.PrimaryButton} disabled={loading || submitting || count < 1 || (!allSelected && selected.length === 0)}>
              Cancel Task Frequencies
            </button>
          </div>
        </div>
      </div>
    </div>
  )
} 