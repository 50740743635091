const defaultTheme = {
  control: (styles, { isFocused, menuIsOpen, isDisabled }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? '#e7e7e7 !important' : '#eaeaea !important',
      boxShadow: isFocused || menuIsOpen ? '0 0 0 0.05rem #c0b4ff' : 'none',
      border: '1px solid #eaeaea',
      fontSize: '13px',
      borderRadius: '8px',
      minHeight: 'auto',
      height: '35px',
      "&:hover": {
        // border: '1px solid #dddddd',
      },
    }
  },
  indicatorsContainer: (styles, state) => ({
    ...styles,
    height: '-webkit-fill-available',

  }),
  menuPortal: (styles, state) => ({
    ...styles,
    zIndex: 1000
  }),
  indicatorContainer: (styles, state) => ({
    ...styles,
    padding: '0px !important',
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    display: "none",
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: '#36214880 !important',
    background: '#c0b4ff !important',
    borderRadius: '8px !important',
    padding: '7px 8px !important',
    height: "100%"
  }),
  valueContainer: (styles, state) => ({
    ...styles,
    display: "flex",
    flexDirection: "row",
    overflowX: "hidden",
    paddingTop: '0px',
    paddingBottom: '0px',
    flexWrap: "nowrap",
  }),
  placeholder: (styles, state) => ({
    ...styles,
    color: "#999 !important",
    fontWeight: "normal",
    fontSize: "12px"
  }),
  multiValue: (styles, state) => ({
    ...styles,
    minWidth: "auto"
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "#362148 !important",
    fontWeight: "normal"
  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      color: isSelected ? '#fff !important' : '#000000a6 !important',
      padding: '4px 5px',
      alignItems: 'center',
      width: 'auto',
      marginBottom: '0px',
      fontSize: '13px',
      display: 'flex',
      lineHeight: '22px',
      backgroundColor: isSelected ? '#b8acf4 !important' : '#fff !important',
      "&:hover": {
        backgroundColor: (isSelected) ? '#b8acf4 !important' : '#b8acf480 !important',
      },
    };
  },
};

export const colorStylesMulti = {
  control: (styles, { isFocused, menuIsOpen, isDisabled }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? '#e7e7e7 !important' : '#eaeaea !important',
      boxShadow: isFocused || menuIsOpen ? '0 0 0 0.05rem #c0b4ff' : 'none',
      border: '1px solid #eaeaea',
      fontSize: '13px',
      borderRadius: '8px',
      minHeight: 'auto',
      height: '35px',
      "&:hover": {
        // border: '1px solid #dddddd',
      },
    }
  },
  indicatorsContainer: (styles, state) => ({
    ...styles,
    height: '-webkit-fill-available',

  }),
  menuPortal: (styles, state) => ({
    ...styles,
    zIndex: 1000
  }),
  indicatorContainer: (styles, state) => ({
    ...styles,
    padding: '0px !important',
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    display: "none",
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: '#36214880 !important',
    background: '#c0b4ff !important',
    borderRadius: '8px !important',
    padding: '7px 8px !important',
    height: "100%"
  }),
  valueContainer: (styles, state) => ({
    ...styles,
    display: "flex",
    flexDirection: "row",
    overflowX: "hidden",
    paddingTop: '0px',
    paddingBottom: '0px',
    flexWrap: "nowrap",
    height: "35px"
  }),
  placeholder: (styles, state) => ({
    ...styles,
    color: '#000000a6 !important',
    fontSize: "12px"
  }),
  multiValue: (styles, state) => ({
    ...styles,
    minWidth: "auto",
    background: "#ccc"
  }),
  input: (styles, state) => ({
    ...styles,
    "&:focus": {
      boxShadow: "none !important"
    }
  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      color: isSelected ? '#fff !important' : '#000000a6 !important',
      padding: '4px 5px',
      alignItems: 'center',
      width: 'auto',
      marginBottom: '0px',
      fontSize: '13px',
      display: 'flex',
      lineHeight: '22px',
      backgroundColor: isSelected ? '#b8acf4 !important' : '#fff !important',
      "&:hover": {
        backgroundColor: (isSelected) ? '#b8acf4 !important' : '#b8acf480 !important',
      },
    };
  }
}

const statusColorMap = {
  "outsourced": "#5cbbff",
  "resourced": "#5cbbff",
  "scheduled": "#5cbbff",
  "booked": "#45B0E1",
  "inprogress": "#FFC000",
  "started": "#7ed321",
  "completed": "#92D050",
  "signedoff": "#7b1fa2",
  "cancelled": "#d0021b",
}

export const JobBoardStatusSelectStyle = {
  control: (styles, { isFocused, menuIsOpen, isDisabled, selectProps: { value } }) => {
    // console.log(rest)
    return {
      ...styles,
      backgroundColor: isDisabled ? '#e7e7e7 !important' : value && value.value ? statusColorMap[value.value.toString().toLowerCase().replace(' ', '')] + '4d !important' : "#eaeaea",
      boxShadow: isFocused || menuIsOpen ? `0 0 0 0.05rem ${value && value.value ? statusColorMap[value.value.toString().toLowerCase().replace(' ', '')] : "#c0b4ff"}` : 'none',
      border: '1px solid #eaeaea',
      fontSize: '13px',
      borderRadius: '8px',
      minHeight: 'auto',
      height: '35px',
      "&:hover": {
        // border: '1px solid #dddddd',
      },
    }
  },
  indicatorsContainer: (styles, state) => ({
    ...styles,
    height: '-webkit-fill-available',

  }),
  menuPortal: (styles, state) => ({
    ...styles,
    zIndex: 1000
  }),
  indicatorContainer: (styles, state) => ({
    ...styles,
    padding: '0px !important',
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    display: "none",
  }),
  dropdownIndicator: (styles, { selectProps: { value } }) => ({
    ...styles,
    color: '#fff !important',
    background: value && value.value ? statusColorMap[value.value.toString().toLowerCase().replace(' ', '')] : '#c0b4ff !important',
    borderRadius: '8px !important',
    padding: '7px 8px !important',
    height: "100%"
  }),
  valueContainer: (styles, state) => ({
    ...styles,
    display: "flex",
    flexDirection: "row",
    overflowX: "hidden",
    paddingTop: '0px',
    paddingBottom: '0px',
    flexWrap: "nowrap",
    height: "35px"
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    color: data && data.value ? statusColorMap[data.value.toString().toLowerCase().replace(' ', '')] + '!important' : "#362148 !important",
    fontWeight: "normal"
  }),
  placeholder: (styles, state) => ({
    ...styles,
    color: '#000000a6 !important',
    fontSize: "12px"
  }),
  multiValue: (styles, state) => ({
    ...styles,
    minWidth: "auto",
    background: "#ccc"
  }),
  input: (styles, state) => ({
    ...styles,
    "&:focus": {
      boxShadow: "none !important"
    }
  }),
  option: (styles, { isFocused, isSelected, isDisabled, data }) => {
    return {
      ...styles,
      color: data && data.value ? statusColorMap[data.value.toString().toLowerCase().replace(' ', '')] : "#000000a6",
      padding: '4px 5px',
      alignItems: 'center',
      width: 'auto',
      marginBottom: '0px',
      fontSize: '13px',
      display: 'flex',
      lineHeight: '22px',
      cursor: isDisabled ? 'not-allowed' : 'default',
      backgroundColor: isDisabled ? "#e2e2e2" : isSelected ? data && data.value ? statusColorMap[data.value.toString().toLowerCase().replace(' ', '')] + '4d !important' : "#b8acf4 !important" : '#fff !important',
      "&:hover": {
        color: data && data.value ? statusColorMap[data.value.toString().toLowerCase().replace(' ', '')] : "#362148",
        backgroundColor: isDisabled ? "#e2e2e2" : data && data.value ? statusColorMap[data.value.toString().toLowerCase().replace(' ', '')] + '4d !important' : "#b8acf480",
      },
    };
  }
}

export const FilterSelectStyle = {
  control: (styles, { isFocused, menuIsOpen, isDisabled }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? '#e7e7e7 !important' : '#fff !important',
      boxShadow: isFocused || menuIsOpen ? '0 0 0 0.05rem #c0b4ff' : 'none',
      border: '1px solid #ddd',
      fontSize: '13px',
      borderRadius: '10px',
      minHeight: 'auto',
      height: '32px',
      "&:hover": {
        border: '1px solid #dddddd',
      },
    }
  },
  indicatorsContainer: (styles, state) => ({
    ...styles,
    height: '-webkit-fill-available'
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    backgroundColor: '#36214880'
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: '#36214880'
  }),
  valueContainer: (styles, state) => ({
    ...styles,
    display: "flex",
    flexDirection: "row",
    overflowX: "hidden",
    paddingTop: '0px',
    paddingBottom: '0px',
    flexWrap: "nowrap",
  }),
  placeholder: (styles, state) => ({
    ...styles,
    color: '#362148 !important'
  }),
  multiValue: (styles, state) => ({
    ...styles,
    minWidth: "auto"
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 9999
  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      color: isSelected ? '#fff !important' : '#000000a6 !important',
      padding: '4px 5px',
      alignItems: 'center',
      width: 'auto',
      marginBottom: '0px',
      fontSize: '13px',
      display: 'flex',
      lineHeight: '22px',
      backgroundColor: isSelected ? '#c0b4ff !important' : '#fff !important',
      "&:hover": {
        backgroundColor: (isSelected) ? '#c0b4ff !important' : '#c0b4ff80 !important',
      },
    };
  },
};

const invoiceStatusColorMap = {
  "approved": "#7ed321",
  "underreview": "#FB8C00",
  "unapproved": "#d0021b",
}

export const InvoiceStatusSelectStyle = {
  control: (styles, { isFocused, menuIsOpen, isDisabled, selectProps: { value } }) => {
    // console.log(rest)
    return {
      ...styles,
      backgroundColor: isDisabled ? '#e7e7e7 !important' : value && value.value ? invoiceStatusColorMap[value.value.toString().toLowerCase().replace(' ', '')] + '4d !important' : "#eaeaea",
      boxShadow: isFocused || menuIsOpen ? `0 0 0 0.05rem ${value && value.value ? invoiceStatusColorMap[value.value.toString().toLowerCase().replace(' ', '')] : "#c0b4ff"}` : 'none',
      border: '1px solid #eaeaea',
      fontSize: '13px',
      borderRadius: '8px',
      minHeight: 'auto',
      height: '35px',
      "&:hover": {
        // border: '1px solid #dddddd',
      },
    }
  },
  indicatorsContainer: (styles, state) => ({
    ...styles,
    height: '-webkit-fill-available',

  }),
  menuPortal: (styles, state) => ({
    ...styles,
    zIndex: 1000
  }),
  indicatorContainer: (styles, state) => ({
    ...styles,
    padding: '0px !important',
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    display: "none",
  }),
  dropdownIndicator: (styles, { selectProps: { value } }) => ({
    ...styles,
    color: '#fff !important',
    background: value && value.value ? invoiceStatusColorMap[value.value.toString().toLowerCase().replace(' ', '')] : '#c0b4ff !important',
    borderRadius: '8px !important',
    padding: '7px 8px !important',
    height: "100%"
  }),
  valueContainer: (styles, state) => ({
    ...styles,
    display: "flex",
    flexDirection: "row",
    overflowX: "hidden",
    paddingTop: '0px',
    paddingBottom: '0px',
    flexWrap: "nowrap",
    height: "35px"
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    color: data && data.value ? invoiceStatusColorMap[data.value.toString().toLowerCase().replace(' ', '')] + '!important' : "#362148 !important",
    fontWeight: "normal"
  }),
  placeholder: (styles, state) => ({
    ...styles,
    color: '#000000a6 !important',
    fontSize: "12px"
  }),
  multiValue: (styles, state) => ({
    ...styles,
    minWidth: "auto",
    background: "#ccc"
  }),
  input: (styles, state) => ({
    ...styles,
    "&:focus": {
      boxShadow: "none !important"
    }
  }),
  option: (styles, { isFocused, isSelected, isDisabled, data }) => {
    return {
      ...styles,
      color: data && data.value ? invoiceStatusColorMap[data.value.toString().toLowerCase().replace(' ', '')] : "#000000a6",
      padding: '4px 5px',
      alignItems: 'center',
      width: 'auto',
      marginBottom: '0px',
      fontSize: '13px',
      display: 'flex',
      lineHeight: '22px',
      cursor: isDisabled ? 'not-allowed' : 'default',
      backgroundColor: isDisabled ? "#e2e2e2" : isSelected ? data && data.value ? invoiceStatusColorMap[data.value.toString().toLowerCase().replace(' ', '')] + '4d !important' : "#b8acf4 !important" : '#fff !important',
      "&:hover": {
        color: data && data.value ? invoiceStatusColorMap[data.value.toString().toLowerCase().replace(' ', '')] : "#362148",
        backgroundColor: isDisabled ? "#e2e2e2" : data && data.value ? invoiceStatusColorMap[data.value.toString().toLowerCase().replace(' ', '')] + '4d !important' : "#b8acf480",
      },
    };
  }
}

export const whiteDefaultTheme = {
  ...defaultTheme,
  control: (styles, { isFocused, menuIsOpen, isDisabled }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? '#e7e7e7 !important' : '#fff !important',
      boxShadow: isFocused || menuIsOpen ? '0 0 0 0.05rem #c0b4ff' : 'none',
      border: '1px solid #fff',
      fontSize: '13px',
      borderRadius: '8px',
      minHeight: 'auto',
      height: '35px',
      "&:hover": {
        // border: '1px solid #dddddd',
      },
    }
  },
}

export default defaultTheme;