import React from "react";
import { Field } from "redux-form";
import { IconButton } from "@mui/material";
import { components } from "react-select";

import { customInput } from "../../../../common/custom-input";
import { CustomSingleSelect } from "../../../../common/new-core-ui/CustomSingleSelect";
import { customTextarea } from "../../../../common/customTextarea";

import FormSelectStyle from "../FormSelectStyle";
import JsaActivityRiskField from "./JsaActivityRiskField";
import styles from "../../JobStyle.module.css";

export default function JsaActivityConsequenceSection({ fields, consequences, likelihoods, loading, loadingData, meta: { error } }) {
  return (
    <div>
      {error ? <div className="error mt-2">{error}</div> : ""}
      {fields.map((member, index) => (
        <div key={index}>
          <div className="container-fluid px-0 mt-2 border-bottom pb-3">
            <div className="row">
              <div className="col-4">
                <div>
                  <div className={styles.Label}>Consequence</div>
                  <Field
                    name={`${member}.consequences_name`}
                    className={styles.TextField}
                    disabled={loading}
                    component={customInput}
                    autoComplete="off"
                  />
                </div>
                {fields.length > 1 ? (
                  <IconButton disabled={loading} className="p-0" style={{ height: "auto" }} onClick={() => fields.remove(index)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className={styles.MinusIcon} viewBox="0 0 448 512">
                      <path fill="currentColor" d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                    </svg>
                  </IconButton>
                ) : ""}
              </div>
              <div className="col-2">
                <div className={styles.Label}>Before Controls</div>
                <div>
                  <div className={styles.Label}>Likelihood</div>
                  <Field
                    name={`${member}.before_likelihood`}
                    component={CustomSingleSelect}
                    options={likelihoods}
                    styles={FormSelectStyle}
                    otherProps={{
                      isDisabled: loading,
                      isLoading: loadingData,
                      components: { dropdownIndicator: <components.DownChevron /> },
                    }}
                  />
                </div>
                <div>
                  <div className={styles.Label}>Consequence</div>
                  <Field
                    name={`${member}.before_consequence`}
                    component={CustomSingleSelect}
                    options={consequences}
                    otherProps={{
                      isDisabled: loading,
                      isLoading: loadingData,
                      components: { dropdownIndicator: <components.DownChevron /> },
                    }}
                    styles={FormSelectStyle}
                  />
                </div>
                <div>
                  <div className={styles.Label}>Initial Risk</div>
                  <Field
                    name={`${member}.initialRisk`}
                    likelihood={`${member}.before_likelihood`}
                    consequence={`${member}.before_consequence`}
                    component={JsaActivityRiskField} />
                </div>
              </div>
              <div className="col-4">
                <div>
                  <div className={styles.Label}>Control Measures</div>
                  <Field
                    className={styles.TextArea}
                    component={customTextarea}
                    disabled={loading}
                    rows={5}
                    name={`${member}.control_measures`}
                  />
                </div>
              </div>
              <div className="col-2">
                <div className={styles.Label}>After Controls</div>
                <div>
                  <div className={styles.Label}>Likelihood</div>
                  <Field
                    name={`${member}.after_likelihood`}
                    component={CustomSingleSelect}
                    options={likelihoods}
                    styles={FormSelectStyle}
                    otherProps={{
                      isDisabled: loading,
                      isLoading: loadingData,
                      components: { dropdownIndicator: <components.DownChevron /> },
                    }}
                  />
                </div>
                <div>
                  <div className={styles.Label}>Consequence</div>
                  <Field
                    name={`${member}.after_consequence`}
                    component={CustomSingleSelect}
                    options={consequences}
                    otherProps={{
                      isDisabled: loading,
                      isLoading: loadingData,
                      components: { dropdownIndicator: <components.DownChevron /> },
                    }}
                    styles={FormSelectStyle}
                  />
                </div>
                <div>
                  <div className={styles.Label}>Residual Risk</div>
                  <Field
                    name={`${member}.residualRisk`}
                    likelihood={`${member}.after_likelihood`}
                    consequence={`${member}.after_consequence`}
                    component={JsaActivityRiskField} />
                </div>
              </div>
            </div>
            <div className="row justify-content-end">
              <div className="col-3">
                <div>
                  <div className={styles.Label}>Action By</div>
                  <Field
                    name={`${member}.action_by`}
                    className={styles.TextField}
                    disabled={loading}
                    component={customInput}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="text-right">
        <button disabled={loading} className={`${styles.PrimaryButton} mt-2`} onClick={() => fields.push({})}>
          Add Consequence
        </button>
      </div>
    </div>
  )
}