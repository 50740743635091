import React from "react";
import { Modal, Select, notification, Checkbox, Button, Collapse, Icon } from "antd";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { reduxForm } from "redux-form";
import { pdf } from "@react-pdf/renderer";
import JobReportPdf from "../../dashboard/serviceAgentJobMgmt/jobReport/JobReportPdf";
import PreviewJobReport from "./PreviewJobReport";
import HistoryJobReport from "../../dashboard/serviceAgentJobMgmt/jobReport/HistoryJobReport";
import * as actions from "../../../actions/SAJobMgmtAction";
import { Strings } from "../../../dataProvider/localize";
import { handleFocus } from "../../../utils/common";
import UpdateSingleReport from "../../dashboard/serviceAgentJobMgmt/jobReport/UpdateSingleReport";
import UpdateSingleDocument from "./UpdateSingleDocument";
import ViewReportDocModel from "./viewReportDocModel";

import { DeepTrim } from "../../../utils/common";
import { VALIDATE_STATUS } from "../../../dataProvider/constant";
import { withRouter } from "react-router-dom";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import CloseIcon from '@mui/icons-material/Close';
import { ContentState, EditorState, convertToRaw } from "draft-js";
import AddNewReportRecord from "./JobReport/AddNewReportRecord";
import AddNewReportDocument from "./JobReport/AddNewReportDocument";

import jobStyles from "../Job/JobStyle.module.css";
import styles from "./ReportsStyles.module.css";
import { IconButton } from "@mui/material";

import v2Styles from "../v2Styles.module.css";
import "../v2Styles.css"
import EmailJobReport from "./JobReport/EmailJobReport";

const { Panel } = Collapse;

const imageTypes = ['png', 'jpg', 'jpeg', 'webp', 'bmp'];

const validate = values => {
  const errors = {}
  if (!values.clubName) {
    errors.clubName = 'Required'
  }
  if (!values.members || !values.members.length) {
    errors.members = { _error: 'At least one member must be entered' }
  } else {
    const membersArrayErrors = []
    values.members.forEach((member, memberIndex) => {
      const memberErrors = {}
      if (!member || !member.firstName) {
        memberErrors.firstName = 'Required'
        membersArrayErrors[memberIndex] = memberErrors
      }
      if (!member || !member.lastName) {
        memberErrors.lastName = 'Required'
        membersArrayErrors[memberIndex] = memberErrors
      }
      if (member && member.hobbies && member.hobbies.length) {
        const hobbyArrayErrors = []
        member.hobbies.forEach((hobby, hobbyIndex) => {
          if (!hobby || !hobby.length) {
            hobbyArrayErrors[hobbyIndex] = 'Required'
          }
        })
        if (hobbyArrayErrors.length) {
          memberErrors.hobbies = hobbyArrayErrors
          membersArrayErrors[memberIndex] = memberErrors
        }
        if (member.hobbies.length > 5) {
          if (!memberErrors.hobbies) {
            memberErrors.hobbies = []
          }
          memberErrors.hobbies._error = 'No more than five hobbies allowed'
          membersArrayErrors[memberIndex] = memberErrors
        }
      }
    })
    if (membersArrayErrors.length) {
      errors.members = membersArrayErrors
    }
  }
  return errors
}
// select label add staff

const { Option } = Select;
const children = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

class CleaningReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      inlineUsers: [],
      uploadLoader: false,
      hisVisible: false,
      versionNo: 0,
      allCheck: false,
      allDocCheck: false,
      viewTaskFiles: false,
      taskFiles: "",
      fileType: '',
      selectedRowCheck: [],
      selectedDocRowCheck: [],
      isSignOff: false,
      editorState: "",
      editorReportState: "",
      recordForms: [],
      documentForms: [],
      emailJobReportModal: false
    };
  }

  componentDidMount() {
    // this.props.change(`job.id`,48);
    const jobId = this.props.jobId;
    this.props.action.getJobReport(jobId, this.props.job.job_number);

    this.props.action.getJobDetails(jobId).then(() => {
      this.setState({ allTasks: this.getAllTasks(this.props.location.state) });
    });
    //this.props.action.getAllJobDetail
  }

  getAllTasks = (props) => {
    let allTasks = [];
    let sites =
      props && props.job && props.job.sites
        ? props.job.sites
        : this.props.job.sites;
    sites &&
      sites.forEach((site) => {
        allTasks = [...allTasks, ...site.tasks];
      });
    return allTasks;
  };

  signOffToggle = () => {

    this.setState({ ...this.state, isSignOff: !this.state.isSignOff })

  }

  handleTaskFileView = (files, e) => {
    e.stopPropagation();
    if (files && files.length > 0) {
      let fileType = files.substring(files.lastIndexOf('.') + 1, files.length) || files;
      this.setState({
        fileType: fileType.toLowerCase(),
        taskFiles: files,
        viewTaskFiles: true,
      });
    }
  };

  handleViewImageCancel = () => {
    this.setState({
      viewTaskFiles: false,
      taskFiles: [],
    });
  };

  showModal = () => {
    if ((this.state.selectedRowCheck && this.state.selectedRowCheck.length > 0) ||
      (this.state.selectedDocRowCheck.length > 0 && this.state.selectedDocRowCheck && this.state.selectedDocRowCheck.length >= 0)) {
      this.setState({ visible: true });
    } else {
      notification.warning({
        message: "Please select atleast one Cleaning report and Document.",
        description: "",
        onClick: () => { },
        className: "ant-warning",
      });
    }
  };

  handleOk = (e) => {
    this.setState({ emailJobReportModal: true });
    // this.props.history.push({
    //   pathname: this.props.match.params.jobId ? this.props.location.pathname + "/email-job-report" : "./emailJobReport",
    //   state: {
    //     jobReports: this.state.selectedRowCheck,
    //     jobDocument: this.state.selectedDocRowCheck,
    //     isSignOff: this.state.isSignOff
    //   }
    // });
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  ////////////alll checks for report
  headerClick = (id, status) => {
    if (status === true) {
      let AllRowIds = this.props.jobReports.map((report) => report);
      this.setState({
        ...this.state,
        allCheck: status,
        selectedRowCheck: AllRowIds,
      });
    } else {
      this.setState({
        ...this.state,
        allCheck: false,
        selectedRowCheck: [],
      });
    }
  };
  rowCheckClick = (data) => {
    const selectedCheckRowNew = [...this.state.selectedRowCheck];
    var index = selectedCheckRowNew.findIndex((x) => x.id.toString() === data.id.toString());
    if (index === -1) {
      selectedCheckRowNew.push(data);
      this.setState({
        ...this.state,
        allCheck: false,
        selectedRowCheck: selectedCheckRowNew,
      });
    } else if (selectedCheckRowNew) {
      if (index > -1) {
        selectedCheckRowNew.splice(index, 1);
        this.setState({
          ...this.state,
          allCheck: false,
          selectedRowCheck: selectedCheckRowNew,
        });
      }
    }
  };

  /////////row check end//
  /////////doc row check start//

  headerDocClick = (id, status) => {
    if (status === true) {
      let AllRowIds = this.props.jobDocument.map((report) => report);
      this.setState({
        ...this.state,
        allDocCheck: status,
        selectedDocRowCheck: AllRowIds,
      });
    } else {
      this.setState({
        ...this.state,
        allDocCheck: false,
        selectedDocRowCheck: [],
      });
    }
  };
  rowDocCheckClick = (data) => {
    const selectedCheckRowNew = [...this.state.selectedDocRowCheck];
    var index = selectedCheckRowNew.findIndex((x) => x.id.toString() === data.id.toString());
    if (index === -1) {
      selectedCheckRowNew.push(data);
      this.setState({
        ...this.state,
        allDocCheck: false,
        selectedDocRowCheck: selectedCheckRowNew,
      });
    } else if (selectedCheckRowNew) {
      if (index > -1) {
        selectedCheckRowNew.splice(index, 1);
        this.setState({
          ...this.state,
          allDocCheck: false,
          selectedDocRowCheck: selectedCheckRowNew,
        });
      }
    }
  };

  /////////doc row check end//

  // History modal function
  showHistoryModal = (versionNumber) => {
    this.props.action
      .getJobReportVersionHistory(
        this.props.job.id,
        this.props.job.job_number,
        versionNumber
      )
      .then(() => {
        this.setState({
          hisVisible: true,
          versionNo: versionNumber,
        });
      })
      .catch((err) => { });
  };
  hisHandleCancel = (e) => {
    this.setState({
      hisVisible: false,
    });
  };

  handleAddNewRecord = () => {
    let formLength = 0;
    if (this.state.recordForms.length >= 1) {
      formLength = this.state.recordForms[(this.state.recordForms.length - 1)].id;
    }
    let newForms = { id: formLength + 1 };
    this.setState({ recordForms: [...this.state.recordForms, newForms] });
  }

  closeAddRecordForm = (formId) => {
    let newForms = this.state.recordForms.filter((form) => form.id !== formId);
    this.setState({ recordForms: [...newForms] });
  }

  handleAddNewDocument = () => {
    let formLength = 0;
    if (this.state.documentForms.length >= 1) {
      formLength = this.state.documentForms[(this.state.documentForms.length - 1)].id;
    }
    let newForms = { id: formLength + 1 };
    this.setState({ documentForms: [...this.state.documentForms, newForms] })
  }

  closeAddDocumentForm = (formId) => {
    let newForms = this.state.documentForms.filter((form) => form.id !== formId);
    this.setState({ documentForms: [...newForms] });
  }

  ////File upload funtion//////////
  handleChange = (file, index, keyName) => {
    this.props.change(`job_cleaning_reports[${index}].${keyName}`, file);
  };

  ////File doc upload funtion//////////
  handleDocChange = (file, index, keyName) => {
    if (file.length === 1) {
      if (file[0] && file[0].size && file[0].size < 4194304) {
        this.props.change(`job_document_reports[${index}].${keyName}`, file);
      } else {
        notification.error({
          message: Strings.error_title,
          description: 'Please select a file less than 4 MB.',
          onClick: () => { },
          className: "ant-error",
        })
      }
    } else {
      this.props.change(`job_document_reports[${index}].${keyName}`, file);
    }
  };

  handlePreUpload = () => {
    return false;
  };

  ///////Download PDF/////////////
  printDocument = (isSignOff, isDoc) => {
    if ((this.state.selectedRowCheck && this.state.selectedRowCheck.length > 0) ||
      (this.state.selectedDocRowCheck.length > 0 && this.state.selectedDocRowCheck && this.state.selectedDocRowCheck.length >= 0)) {
      this.toggleLoader();
      var obj = pdf(
        <JobReportPdf
          job={this.props.job}
          jobReports={this.state.selectedRowCheck}
          jobDocument={this.state.selectedDocRowCheck}
          filePath={this.props.filePath}
          resizedFilePath={this.props.resizedFilePath}
          isSignOff={isSignOff}
          allTasks={this.state.allTasks}
          isDoc={isDoc}
        />
      ).toBlob();

      obj
        .then((blob) => {
          var url = URL.createObjectURL(blob);
          //////////////for downloading///////////////////////
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = `jobReport_${this.props.job.job_number}.pdf`;
          document.body.appendChild(a);
          this.toggleLoader();

          a.click();
          window.URL.revokeObjectURL(url)
          return Promise.resolve(blob);
        })
        .then((res) => {
          this.blobData = res;
        });
      // }
    } else {
      notification.warning({
        message: "Please select atleast one Cleaning report and Document...",
        description: "",
        onClick: () => { },
        className: "ant-warning",
      });
    }
  };
  toggleLoader = () => {
    this.setState({ uploadLoader: !this.state.uploadLoader });
  };

  profileLoaderView = (
    <div className="profile-loader otherloader">
      <div className="sk-circle">
        <div className="sk-circle1 sk-child"></div>
        <div className="sk-circle2 sk-child"></div>
        <div className="sk-circle3 sk-child"></div>
        <div className="sk-circle4 sk-child"></div>
        <div className="sk-circle5 sk-child"></div>
        <div className="sk-circle6 sk-child"></div>
        <div className="sk-circle7 sk-child"></div>
        <div className="sk-circle8 sk-child"></div>
        <div className="sk-circle9 sk-child"></div>
        <div className="sk-circle10 sk-child"></div>
        <div className="sk-circle11 sk-child"></div>
        <div className="sk-circle12 sk-child"></div>
      </div>
    </div>
  );
  showPdf = (signOff, isDoc) => {
    // this.toggleLoader();

    this.printDocument(signOff, isDoc);
  };
  ///////////////////////////////

  editorState = (value) => {
    var body = value ? value : "";

    const html = body;
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        editorState: editorState,
      });
      return editorState;
    }
  };

  /////////////report editor
  editorReportState = (value) => {
    var body = value ? value : "";

    const html = body;
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        editorReportState: editorState,
      });
      return editorState;
    }
  };

  changeEditorReportState = (editorState, key) => {
    this.props.change(
      key,
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    this.setState({
      editorState,
    });
  };

  //doc editor
  changeEditorState = (editorState, key) => {
    this.props.change(
      key,
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    this.setState({
      editorState,
    });
  };

  /////////doc view
  handleDocView = (files, e) => {
    e.stopPropagation();
    if (files && files.length > 0) {
      let fileType = files.substring(files.lastIndexOf('.') + 1, files.length) || files;
      this.setState({
        fileType: fileType.toLowerCase(),
        viewTaskFiles: true,
        taskFiles: files,
      });
    }
  };

  handleDocCancel = () => {
    this.setState({
      viewDoc: false,
      taskFiles: [],
    });
  };

  /////////download doc
  downLoadDoc = (url) => {
    fetch(url).then((res) => {
      if (!res.ok) {
        notification.error({
          message: Strings.error_title,
          description: Strings.file_download_error,
          className: "ant-error",
        })
      } else {
        res.blob().then((blob) => {
          var file = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = file;
          let fileName = url.substring(url.lastIndexOf('/') + 1).toString();
          a.download = fileName;
          a.click();
          a.remove();
        })
      }
    }).catch(() => {
      notification.error({
        message: Strings.error_title,
        description: Strings.file_download_error,
        className: "ant-error",
      })
    })
  }

  /////////////on form submit //////
  onSubmit = async (formData, flag) => {
    formData = await DeepTrim(formData);
    var fileIndex = 0;
    var finalForm = new FormData();

    formData.job_cleaning_reports &&
      formData.job_cleaning_reports.forEach((report, reportIndex) => {
        //////////////////for location image//////////////////////////
        formData.job_cleaning_reports[reportIndex].location_photo = [];
        if (report.location && report.location.length > 0) {
          report.location.forEach((file, index) => {
            finalForm.append("files", file.originFileObj);
            formData.job_cleaning_reports[reportIndex].location_photo[index] =
              fileIndex;
            fileIndex++;
          });
        }
        ////////////////////For before image//////////////////////
        formData.job_cleaning_reports[reportIndex].before_photo = [];
        if (report.before && report.before.length > 0) {
          report.before.forEach((file, index) => {
            finalForm.append("files", file.originFileObj);
            formData.job_cleaning_reports[reportIndex].before_photo[index] =
              fileIndex;
            fileIndex++;
          });
        }
        ////////////////////For after image//////////////////////
        formData.job_cleaning_reports[reportIndex].after_photo = [];
        if (report.after && report.after.length > 0) {
          report.after.forEach((file, index) => {
            finalForm.append("files", file.originFileObj);
            formData.job_cleaning_reports[reportIndex].after_photo[index] =
              fileIndex;
            fileIndex++;
          });
        }
      });

    ////////////set data in form
    finalForm.append(
      "job_cleaning_reports",
      JSON.stringify(formData.job_cleaning_reports)
    );
    finalForm.append("job_id", this.props.location.state);

    finalForm.append(
      "job_cleaning_reports_documents",
      JSON.stringify(formData.job_document_reports)
    );

    //////////////////for document///////////
    formData.job_document_reports &&
      formData.job_document_reports.forEach((report, reportIndex) => {
        //////////////////for doc image//////////////////////////
        if (report.document_files && report.document_files.length > 0) {
          report.document_files.forEach((file, index) => {
            finalForm.append("document_files", file.originFileObj);
          });
        }
      });

    ///////////call action  to save report////////
    this.props.action
      .saveJobReport(finalForm, this.props.location.state)
      .then((flag) => {
        this.props.reset();
        notification.success({
          message: Strings.success_title,
          description: flag,
          onClick: () => { },
          className: "ant-success",
        });
      })
      .catch((error) => {
        if (error.status.toString() === VALIDATE_STATUS.toString()) {
          notification.warning({
            message: Strings.validate_title,
            description:
              error && error.data && typeof error.data.message == "string"
                ? error.data.message
                : Strings.generic_validate,
            onClick: () => { },
            className: "ant-warning",
          });
        } else {
          notification.error({
            message: Strings.error_title,
            description:
              error &&
                error.data &&
                error.data.message &&
                typeof error.data.message == "string"
                ? error.data.message
                : Strings.generic_error,
            onClick: () => { },
            className: "ant-error",
          });
        }
      });
  };

  ////////////////Inline edit funtions////////////////////////////
  handleUserEditClick = (user, updateFor) => {
    var { inlineUsers } = this.state;
    inlineUsers.push(user.id);
    this.setState({ inlineUsers });
  };

  removeInlineUser = (user) => {
    var { inlineUsers } = this.state;
    var index = inlineUsers.findIndex((id) => id === user.id);
    if (index !== -1) {
      inlineUsers.splice(index, 1);
    }
    this.setState({ inlineUsers });
  };
  ////////////////////////////////////////////////////////////////////

  deleteReport = (report) => {
    this.props.action
      .deleteReport(report)
      .then((res) => {
        notification.success({
          message: Strings.success_title,
          description: res.message,
          onClick: () => { },
          className: "ant-success",
        });
      })
      .catch((message) => {
        notification.error({
          message: Strings.error_title,
          description: message ? message : Strings.generic_error,
          onClick: () => { },
          className: "ant-error",
        });
      });
  };

  //////////////doc delete

  deleteDocument = (report) => {
    this.props.action
      .deleteDocument(report)
      .then((res) => {
        notification.success({
          message: Strings.success_title,
          description: res.message,
          onClick: () => { },
          className: "ant-success",
        });
      })
      .catch((message) => {
        notification.error({
          message: Strings.error_title,
          description: message ? message : Strings.generic_error,
          onClick: () => { },
          className: "ant-error",
        });
      });
  };

  checkAllRecords = (e) => {
    if (e.target.checked) {
      this.setState({ selectedRowCheck: this.props.jobReports })
    } else {
      this.setState({ selectedRowCheck: [] })
    }
  }

  render() {
    // file upload

    const { job } = this.props;

    return (
      <div className={styles.DocsContainer}>
        <div className="preview-section">
          <div className="row">
            <div className="col-6">
              <span className={jobStyles.SectionHeading}>Report Info</span>
            </div>
          </div>
          <div className={jobStyles.card}>
            <div className="row">
              <div className="col-12 col-lg-6 col-md-6 col-sm-12 pr-0">
                <div className={jobStyles.SectionHeading}>By</div>
                <div className={jobStyles.card}>
                  <ul className="mb-0">
                    <li className={styles.Content}><span className={styles.Title}>Documents Prepared By: </span>{job.admin_name}</li>
                    <li className={styles.Content}><span className={styles.Title}> Company: </span>{job.org_name} </li>
                    <li className={styles.Content}><span className={styles.Title}> Address: </span>{job.address}</li>
                    <li className={styles.Content}><span className={styles.Title}> Mobile: </span>{job.admin_phone_number} </li>
                    <li className={styles.Content}><span className={styles.Title}> Email: </span>{job.admin_email_address} </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-6 col-sm-12 pl-2">
                <div className={jobStyles.SectionHeading}>To</div>
                <div className={jobStyles.card}>
                  <ul className="mb-0">
                    <li className={styles.Content}><span className={styles.Title}>Attention: </span>{job.client_person_name} </li>
                    <li className={styles.Content}><span className={styles.Title}> Company: </span>{job.client_name} </li>
                    <li className={styles.Content}><span className={styles.Title}> Address: </span>{job.client_address} </li>
                    <li className={styles.Content}><span className={styles.Title}> Mobile: </span>{job.client_person_phone}</li>
                    <li className={styles.Content}><span className={styles.Title}> Email: </span>{job.client_person_email}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={`${jobStyles.SectionHeading} mt-2`}>Job Details</div>
            <div className={jobStyles.card}>
              {job && job.sites && job.sites.length > 0 &&
                job.sites.map((site) => {
                  return <div className="row" key={site.id}>
                    <div className="col-3 d-flex" style={{ columnGap: "5px" }}>
                      <div className={styles.Title}>Site Name: </div>
                      <div className={styles.Content}>{site.site_name}</div>
                    </div>
                    <div className="col-3 d-flex" style={{ columnGap: "5px" }}>
                      <div className={styles.Title}>Site Address: </div>
                      <div className={styles.Content}>{site.street_address} {site.city},{site.state},{site.country}  </div>
                    </div>
                    <div className="col-3">
                      <div>
                        <span className={styles.Title}>Quote Number: </span><span className={styles.Content}>{job.quote_number}</span>
                      </div>
                    </div>
                    <div className="col-3">
                      <div>
                        <span className={styles.Title}>Purchase Order: </span><span className={styles.Content}>{job.PO_number}{" "}</span>
                      </div>
                    </div>
                  </div>
                })}
            </div>
          </div>

          <Modal
            visible={this.state.viewDoc}
            className="cs-modal-v2"
            zIndex="99999"
            style={{ left: 40, top: 20 }}
            footer={null}
            width="60%"
            closeIcon={<div className={jobStyles.ModalCloseIcon}><CloseIcon /></div>}
            onCancel={this.handleDocCancel}
          >
            <ViewReportDocModel taskFiles={this.state.taskFiles} />
          </Modal>
        </div>

        {this.state.uploadLoader ? this.profileLoaderView : null}
        <div>
          <div>
            <div className="row">
              <div className="col-lg-12">
                <div className="mt-2 px-2">
                  <div>
                    <div className={jobStyles.SectionHeading}>Job Details</div>
                    <div className={jobStyles.card}>
                      <div className="container-fluid pl-0">
                        {this.props.jobReports && this.props.jobReports.length > 0 ? (
                          <div className="row">
                            <div className="col-12 pr-0">
                              <div className={jobStyles.ReportCard}>
                                <Checkbox
                                  onChange={this.checkAllRecords}
                                  checked={this.state.selectedRowCheck.length === this.props.jobReports.length && this.props.jobReports.length > 0}
                                  className="cs-select-v2">Select All Records</Checkbox>
                              </div>
                            </div>
                          </div>
                        ) : ""}
                        <div className="row">
                          {this.props.jobReports.map((report, index) => (
                            <div className="col-6 pr-0" key={report.id}>
                              {this.state.inlineUsers && this.state.inlineUsers.findIndex((id) => id === report.id) !== -1 ? (
                                <UpdateSingleReport
                                  form={"UpdateSingleReport" + report.id}
                                  initialValues={report}
                                  removeInlineUser={this.removeInlineUser}
                                  allTasks={this.state.allTasks} />
                              ) : (
                                <div>
                                  <div className={jobStyles.ReportCard}>
                                    <div className="mb-1">
                                      <Checkbox
                                        onClick={e => { e.stopPropagation(); }}
                                        checked={this.state.selectedRowCheck.find((item) => item.id.toString() === report.id.toString())}
                                        onChange={() => this.rowCheckClick(this.props.jobReports[index])}
                                        className="cs-select-v2">
                                        Task -  {this.state.allTasks && this.state.allTasks.find((tsk) => tsk.id === report.quote_task_id) &&
                                          this.state.allTasks.find((tsk) => tsk.id === report.quote_task_id).task_name}{` ${report.area ? `,  Area-${report.area}` : " "}`}
                                      </Checkbox>
                                    </div>
                                    <div className="d-flex align-items-stretch" style={{ columnGap: "10px" }}>
                                      <div className="d-flex align-items-center" style={{ columnGap: "5px", flex: "1 1 auto" }}>
                                        <div className={jobStyles.ReportImageContainer}>
                                          {report.location_photo &&
                                            JSON.parse(report.location_photo).map((url) => (
                                              <img className={jobStyles.ReportImage} key={url} src={this.props.filePath + url} alt="" onClick={(e) => this.handleTaskFileView((this.props.filePath + url), e)} />
                                            ))}
                                        </div>
                                        <div className={jobStyles.ReportImageContainer}>
                                          {report.before_photo &&
                                            JSON.parse(report.before_photo).map((url) => (
                                              <img className={jobStyles.ReportImage} key={url} src={this.props.filePath + url} alt="" onClick={(e) => this.handleTaskFileView((this.props.filePath + url), e)} />
                                            ))}
                                        </div>
                                        <div className={jobStyles.ReportImageContainer}>
                                          {report.after_photo &&
                                            JSON.parse(report.after_photo).map(
                                              (url) => (
                                                <img className={jobStyles.ReportImage} key={url} src={this.props.filePath + url} alt="" onClick={(e) => this.handleTaskFileView((this.props.filePath + url), e)} />
                                              ))}
                                        </div>
                                      </div>
                                      <div className="d-flex align-items-center" style={{ columnGap: "10px", flex: "0 0 300px" }}>
                                        <div style={{ flex: "1 1 auto" }}>
                                          <div className={jobStyles.CommonLabel}>Notes</div>
                                          <textarea disabled readOnly value={report.note} rows={3} className={jobStyles.ReportTextArea} />
                                        </div>
                                        <div style={{ flex: "0 0 30px" }}>
                                          <div className="mb-2">
                                            <IconButton onClick={() => this.handleUserEditClick(report, "jobReport")} style={{ background: "#9abe3a", color: "#fff", height: "auto" }}>
                                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: "14px", width: "14px", }}>
                                                <path fill="currentColor" d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                              </svg>
                                            </IconButton>
                                          </div>
                                          <div>
                                            <IconButton style={{ background: "#362148", color: "#fff", height: "auto" }} onClick={() => this.deleteReport(report)}>
                                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{ height: "14px", width: "14px", }}>
                                                <path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                                              </svg>
                                            </IconButton>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                          {this.state.recordForms && this.state.recordForms.map(form => (
                            <div className="col-6 pr-0" key={form.id}>
                              <AddNewReportRecord
                                form={`newRecordForm${form.id}`}
                                allTasks={this.state.allTasks}
                                jobId={this.props.jobId}
                                onClose={() => this.closeAddRecordForm(form.id)}
                                key={form.id} />
                            </div>
                          ))
                          }
                        </div>
                        <div className="row">
                          <div className="col-12 pr-0">
                            {this.state.recordForms && this.state.recordForms.length === 0 ? (
                              <button
                                className={`${jobStyles.PrimaryButton} ${jobStyles.SmallPrimaryButton} mt-2`}
                                type="button"
                                onClick={() => this.handleAddNewRecord()}
                              >Add New Record</button>
                            ) : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-2">
                    <div className={jobStyles.SectionHeading}>Additional Documents </div>
                    <div className={jobStyles.card}>
                      <div className="accordion" id="accordionExample">
                        <div className="table-responsive">
                          <table className="table table-sm cs-table cs-table-v2 w-100">
                            <thead>
                              <tr>
                                <th style={{ width: "30px" }}>
                                  <Checkbox
                                    onChange={(e) => this.headerDocClick(e, !this.state.allDocCheck)}
                                    className="cs-select-v2"
                                    checked={this.state.allDocCheck ? true : false}
                                  />
                                </th>
                                <th>Document Name</th>
                                <th>Document</th>
                                <th>Comments</th>
                                <th width="120px">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.props.jobDocument &&
                                this.props.jobDocument.map((report, index) => {
                                  var inline = this.state.inlineUsers.findIndex(
                                    (id) => id === report.id
                                  );
                                  if (inline !== -1) {
                                    return (
                                      <UpdateSingleDocument
                                        form={"UpdateSingleDocument" + report.id}
                                        key={report.id}
                                        initialValues={report}
                                        removeInlineUser={this.removeInlineUser}
                                      />
                                    );
                                  }
                                  return (
                                    <tr key={report.id}>
                                      <td style={{ width: "30px" }}>
                                        <Checkbox
                                          value={report.id}
                                          className="cs-select-v2"
                                          onChange={(report) => this.rowDocCheckClick(this.props.jobDocument[index])}
                                          checked={this.state.selectedDocRowCheck.find((item) => item.id.toString() === report.id.toString())}
                                        />
                                      </td>
                                      <td>
                                        {report.document_name}
                                      </td>
                                      <td>
                                        <div className="location-afbf-pic">
                                          {report.shift_report_document && (
                                            <a target="_blank" rel="noopener noreferrer"
                                              className={jobStyles.PrimaryText}
                                              href={report.shift_report_document}
                                              title={report.document_name}
                                            >
                                              <i className="fa fa-paperclip mr-2" sx={{ color: "#c0b4ff" }} aria-hidden="true"></i>
                                              {report.document_name}
                                            </a>
                                          )}
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: report.comments,
                                            }}
                                          />
                                        </div>
                                      </td>
                                      <td style={{ width: "120px" }}>
                                        <div className="d-flex align-items-center" style={{ columnGap: "10px" }}>
                                          <IconButton onClick={() => this.handleUserEditClick(report, "jobDoc")} className={jobStyles.MuiIconButton} style={{ color: "#9abe3a" }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: "14px", width: "14px", }}>
                                              <path fill="currentColor" d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                            </svg>
                                          </IconButton>
                                          <IconButton style={{ color: "red" }} onClick={() => this.deleteDocument(report)} className={jobStyles.MuiIconButton}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{ height: "14px", width: "14px", }}>
                                              <path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                                            </svg>
                                          </IconButton>
                                          <IconButton style={{ color: "#9abe3a" }} onClick={(e) => this.handleDocView(report.shift_report_document, e)} className={jobStyles.MuiIconButton}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style={{ height: "14px", width: "14px" }}>
                                              <path fill="currentColor" d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                                            </svg>
                                          </IconButton>

                                          <IconButton style={{ color: "#362148" }} onClick={() => this.downLoadDoc(report.shift_report_document)} className={jobStyles.MuiIconButton}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: "14px", width: "14px" }}>
                                              <path fill="currentColor" d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                            </svg>
                                          </IconButton>
                                        </div>
                                      </td>
                                    </tr>);
                                })}
                            </tbody>
                          </table>
                        </div>
                        {this.state.documentForms && this.state.documentForms.map(form =>
                          <AddNewReportDocument
                            key={form.id}
                            form={`newDocumentForm${form.id}`}
                            jobId={this.props.jobId}
                            allTasks={this.state.allTasks}
                            onClose={() => this.closeAddDocumentForm(form.id)}
                          />
                        )}
                        {this.state.documentForms && this.state.documentForms.length === 0 ? (
                          <button
                            className={`${jobStyles.PrimaryButton} ${jobStyles.SmallPrimaryButton} mt-2`}
                            type="button"
                            onClick={() => this.handleAddNewDocument()}
                          >Add New Document</button>
                        ) : ""}
                      </div>
                    </div>
                  </div>
                </div>

                {/* save and preview button */}
                <div className="all-btn d-flex justify-content-end mt-4 sc-doc-bnt mr-4">
                  <div className="btn-hs-icon">
                    <button
                      type="button"
                      className={jobStyles.SecondaryButton}
                      onClick={() => this.showPdf(true, true)}>
                      {Strings.download_report}
                    </button>
                  </div>
                  <div className="btn-hs-icon">
                    <button
                      type="button"
                      className={jobStyles.PrimaryButton}
                      onClick={this.showModal}>
                      {Strings.preview_btn}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* for view full image */}
        <Modal
          visible={this.state.viewTaskFiles}
          className="cs-modal-v2"
          centered
          footer={null}
          width={768}
          closeIcon={<div className={jobStyles.ModalCloseIcon}><CloseIcon /></div>}
          onCancel={this.handleViewImageCancel}
        >
          <div className="container py-3">
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-center" style={{ height: '768px', backgroundColor: '#000000' }}>
                  {this.state.fileType && imageTypes.includes(this.state.fileType) ?
                    <img src={this.state.taskFiles} alt="" className="img-fluid" style={{ maxHeight: '760px' }} />
                    : this.state.fileType && this.state.fileType === "pdf" ?
                      <div style={{ width: '750px', height: '768px' }} >
                        <embed src={this.state.taskFiles} type="application/pdf" style={{ width: '100%', height: '100%' }} />
                      </div>
                      : <div className="text-center" style={{ width: '400px', color: '#ffffff' }}>
                        <span className="h6 font-weight-normal">Preview Not Available.</span>
                        <div>
                          <Button target="_blank" href={this.state.taskFiles} className="mt-4">Download</Button>
                        </div>
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          className="cs-modal-v2"
          visible={this.state.visible}
          style={{ padding: "2px" }}
          onOk={this.handleOk}
          okText={Strings.email_job_docs_bnt}
          width="100%"
          centered
          cancelButtonProps={{ className: jobStyles.FilterResetButton }}
          okButtonProps={{ className: jobStyles.PrimaryButton }}
          closeIcon={<div className={jobStyles.ModalCloseIcon}><CloseIcon /></div>}
          onCancel={this.handleCancel}
          bodyStyle={{ maxHeight: "80vh", overflow: "hidden scroll" }}
        >
          <PreviewJobReport
            allTasks={this.state.allTasks}
            rowCheck={this.state.selectedRowCheck}
            docCheck={this.state.selectedDocRowCheck}
            jobSignOffs={this.props.jobSignOffs}
            pdfPrint={this.showPdf}
            signOffToggle={this.signOffToggle}
            isSignOff={this.state.isSignOff}
          />
        </Modal>
        {/* )} */}

        {/* history popup  */}
        <Modal
          title={null}
          visible={this.state.hisVisible}
          okButtonProps={{ style: { display: "none" } }}
          width="100%"
          closeIcon={<div className={jobStyles.ModalCloseIcon}><CloseIcon /></div>}
          className="cs-modal-v2"
          onCancel={this.hisHandleCancel}
        >
          <HistoryJobReport versionNo={this.state.versionNo} />
        </Modal>
        <Modal
          className="cs-modal-v2"
          visible={this.state.emailJobReportModal}
          width="768px"
          centered
          bodyStyle={{ paddingRight: "0px", paddingLeft: "0px", maxHeight: "80vh", overflow: "hidden auto" }}
          footer={null}
          destroyOnClose={true}
          onCancel={() => this.setState({ emailJobReportModal: false })}
          closeIcon={<div className={v2Styles.ModalCloseIcon}><CloseIcon /></div>}
        >
          <EmailJobReport
            jobReports={this.state.selectedRowCheck}
            documents={this.state.selectedDocRowCheck}
            isSignOff={this.state.isSignOff}
            job={job}
            onClose={() => this.setState({ emailJobReportModal: false })}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formValues:
      state.form && state.form.jobReport && state.form.jobReport.values
        ? state.form.jobReport.values
        : {},
    jobReports: state.sAJobMgmt.jobReports,
    job: state.sAJobMgmt.jobDetails,

    filePath: state.sAJobMgmt.filePath,
    resizedFilePath: state.sAJobMgmt.resizedFilePath,
    versionCount: state.sAJobMgmt.versionCount,
    versionArray: state.sAJobMgmt.versions,
    jobDocument: state.sAJobMgmt.jobDocument,
    jobSignOffs: state.sAJobMgmt.jobSignOffs
  };
};

const mapDispatchToprops = (dispatch) => {
  return {
    action: bindActionCreators(actions, dispatch),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToprops),
  reduxForm({
    form: "jobReport",
    validate,
    onSubmitFail: (errors, dispatch, sub, props) => {
      handleFocus(errors, "#");
    },
  })
)(CleaningReport);
