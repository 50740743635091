import React, { useState, useEffect } from "react";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { customInput } from '../../../common/custom-input';
import { Icon } from "antd";
import { MultiEmailInput } from "../../../common/chipInputRedux";
import EmailEditor from '../../../common/EmailEditor';
import { reduxForm, Field } from "redux-form";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import styles from "./SignOffStyles.module.css"
import colourStyles from "./FilterSelectEmailStyles.js";

const EmailModal = (props) => {

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [emailOptions, setEmailOptions] = useState({
    allowMutiToEmail: true
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let blocksFromHtml = htmlToDraft(props.initialValues.body);
    if (blocksFromHtml) {
      let contentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks);
      let editorStateVal = EditorState.createWithContent(contentState);
      setEditorState(editorStateVal);
    }
  }, []);

  useEffect(() => {
    if (props.loading) {
      setLoading(props.loading);
    }
  }, [props.loading])

  useEffect(() => {
    if (props.options) {
      setEmailOptions(props.options)
    }
  }, [props.options])

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const onSubmit = (values) => {
    let body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    let emailData = { ...values, body: body };
    props.onSubmitForm(emailData);
  }

  return <div className="h-100">
    {loading && <div className="d-flex align-items-center justify-content-center h-100">
      <Icon type="loading" style={{ fontSize: "40px" }} />
    </div>}
    <div className="email-form" style={{ marginTop: "0px", display: loading ? 'none' : 'block' }}>
      <form onSubmit={props.handleSubmit(onSubmit)}>
        <div className="my-1">
          <div className={styles.QuoteFormLabel}>From</div>
          <Field
            name="from"
            type="text"
            id="name"
            disabled={true}
            autoComplete="off"
            className={styles.QuoteFormField}
            component={customInput} />
        </div>
        <div className="my-1">
          <div className={styles.QuoteFormLabel}>To</div>
          <Field
            name="to"
            type="text"
            id="name"
            isMulti={emailOptions.allowMutiToEmail}
            autoComplete="off"
            component={MultiEmailInput}
            otherProps={{
              styles: colourStyles,
            }} />
        </div>
        <div className="my-1">
          <div className={styles.QuoteFormLabel}>CC</div>
          <Field
            name="cc"
            type="text"
            id="name"
            autoComplete="off"
            component={MultiEmailInput}
            otherProps={{
              styles: colourStyles,
            }} />
        </div>
        <div className="my-1">
          <div className={styles.QuoteFormLabel}>BCC</div>
          <Field
            name="bcc"
            type="text"
            id="name"
            autoComplete="off"
            component={MultiEmailInput}
            otherProps={{
              styles: colourStyles,
            }} />
        </div>
        <div className="my-1">
          <div className={styles.QuoteFormLabel}>Subject</div>
          <Field
            name="subject"
            type="text"
            id="name"
            autoComplete="off"
            className={styles.QuoteFormField}
            component={customInput}
            otherProps={{
              styles: colourStyles,
            }} />
        </div>
        <div className="my-1 form-group sf-form rich-textbox">
          <div className="sf-rich-txtbox">
            <fieldset>
              <Field
                name="body"
                type="text"
                id="name"
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                component={EmailEditor} />
            </fieldset>
          </div>
        </div>
        <div className="text-right">
          <button type="submit" className={styles.FilterButton}>Send Email</button>
        </div>
      </form>
    </div>
  </div>
}

export default reduxForm()(EmailModal);