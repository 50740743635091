import moment from 'moment'
import React from 'react'
import styles from './Sections.module.css'

const JobBoardMiddlePanel = ({ jobDetails }) => {
  return (
    <div className="sf-card">
      <div className="sf-card-body">
        <div className={styles.MiddlePanelWrapper}>
          <div className={styles.gridContainer}>
            <div className={styles.gridItem}>
              <label>Quote ID:</label>
              <span>{jobDetails.quote_number}</span>
            </div>
            <div className={styles.gridItem}>
              <label>Start Date:</label>
              <span>{moment(jobDetails.job_start_date).format('YYYY-MM-DD')}</span>
            </div>
            <div className={styles.gridItem}>
              <label>Due Date:</label>
              <span>{moment(jobDetails.job_end_date).format('YYYY-MM-DD')}</span>
            </div>
            <div className={styles.gridItem}>
              <label>Created By:</label>
              <span>{jobDetails.account_manager_name}</span>
            </div>
            <div className={styles.gridItem}>
              <label>Manager:</label>
              <span>{jobDetails.account_manager_name}</span>
            </div>
            <div className={styles.gridItem}>
              <label>Allocated Budget:</label>
              <span>${jobDetails.total_outsourced_budget_to_acc_manager}</span>
            </div>
            <div className={styles.gridItem}>
              <label>Job Status:</label>
              <span>{'Allocated'}</span>
            </div>
            <div className={styles.gridItem}>
              <label></label>
              <span></span>
            </div>
            <div className={styles.gridItem}>
              <label>Budget Consumed:</label>
              <span>${jobDetails.total_outsourced_budget_to_sa}</span>
            </div>
            <div className={styles.gridItem}>
              <label>Incidents Reported:</label>
              <span>{0}</span>
            </div>
            <div className={styles.gridItem}>
              <label>Hazards Reported:</label>
              <span>{0}</span>
            </div>
            <div className={styles.gridItem}>
              <label>Invoiced:</label>
              <span>{'No'}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobBoardMiddlePanel
