import React from 'react';
import { Dropdown, notification } from 'antd';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import * as actions from '../../../../actions/scopeDocActions';
import * as actionEmail from '../../../../actions/emailTemplateAction'
import { JobDocsEmailvalidate } from '../../../../utils/Validations/emailQuoteValidation';
import { customInput } from '../../../common/custom-input';
import { Strings } from '../../../../dataProvider/localize';
import { getEmailDate, goBack, handleFocus } from '../../../../utils/common';
import { ADMIN_DETAILS, SLUG_JOB_DOC_TO_CLIENT } from '../../../../dataProvider/constant';
import { getStorage, goBackBrowser, getFormattedPhone, getFirstName } from '../../../../utils/common';
import EmailEditor from './EmailEditor';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { withRouter } from 'react-router-dom';
import { DeepTrim } from '../../../../utils/common';
import * as jobDocumentsAction from '../../../../actions/jobDocumentsAction';
import { ERROR_NOTIFICATION_KEY } from '../../../../config';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import styles from "./EmailStyles.module.css"
import moment from 'moment';
class ViewJobEmail extends React.Component {
    job_doc_no;
    quote_number;
    client_id;
    org_id;
    constructor(props) {
        super(props);
        this.state = {
            toggleCc: true,
            toggleBcc: true,
            editorState: '',
            togleSearch: true,
            toEmails: [],
            bccEmails: [],
            ccEmails: [],
            signature: "",
            noReply: ``
        }

        // this.props.change('client_id', this.props.selectedScopDoc && this.props.selectedScopDoc.length > 0 && this.props.selectedScopDoc[0] && this.props.selectedScopDoc[0].client_id ? this.props.selectedScopDoc[0].client_id : '');

        // this.props.change('quote_number', this.props.selectedScopDoc && this.props.selectedScopDoc.length > 0 && this.props.selectedScopDoc[0] && this.props.selectedScopDoc[0].quote_number ? this.props.selectedScopDoc[0].quote_number : '')

        this.loginUserName = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)).name + " " + JSON.parse(getStorage(ADMIN_DETAILS)).last_name : null;

        this.loginUserRoleName = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)).role.role_name : null;
    }

    componentDidMount() {
        document.title = "Email Job Document to Client - Service Farm";
        this.job_doc_no = this.props.location && this.props.location.state && this.props.location.state.job_doc_number;
        this.quote_number = this.props.location && this.props.location.state && this.props.location.state.quote_number;
        this.client_id = this.props.location && this.props.location.state && this.props.location.state.client_id;
        this.org_id = this.props.location && this.props.location.state && this.props.location.state.org_id;

        this.props.emailAction.getEmailTemplate(SLUG_JOB_DOC_TO_CLIENT)
            .then(flag => {
                this.editorState();

                this.props.change("from_profile", this.props.jobDocEmailDetails.from_profile);
                this.props.change("from_field", this.props.jobDocEmailDetails.from_field);
                // this.props.change('to_field', this.props.jobDocEmailDetails.to_field);
                // this.props.change('cc_field', this.props.jobDocEmailDetails.cc_field);
                // this.props.change('bcc_field', this.props.jobDocEmailDetails.bcc_field);
                this.props.change('subject', this.props.jobDocEmailDetails.subject);
                this.props.change('client_id', this.props.jobDetails.client_id);
                this.props.change('quote_number', this.props.jobDetails.quote_number)


                if (this.props.initialValues) {
                    this.setState({
                        toEmails: [this.props.initialValues.to_field],
                        bccEmails: [this.props.initialValues.bcc_field],
                        ccEmails: [this.props.initialValues.cc_field],
                    })
                }
            }).catch(message => {
                notification.error({
                    key: ERROR_NOTIFICATION_KEY,
                    message: Strings.success_title,
                    description: message ? message : Strings.generic_error,
                    onClick: () => { },
                    className: 'ant-error'
                })
            });
    }

    editorState = () => {
        var body = this.props.selectedMailContent && this.props.selectedMailContent.body ? this.props.selectedMailContent.body : '';
        let adminDetails = JSON.parse(getStorage(ADMIN_DETAILS))
        const jobId = getStorage('JOB_ID');
        let jobLink = `${window.location.origin}/client/v2/job-doc-preview/${this.org_id}/${jobId}`;
        if (process.env.REACT_APP_CLIENT_APP_URL) {
            jobLink = `${process.env.REACT_APP_CLIENT_APP_URL}/job-doc-preview/${this.org_id}/${jobId}`;
        }
        if (this.props.location && this.props.location.state && this.props.location.state.job_doc_number) {
            body = body.replace(/\{\{link\}\}/g, `<a href='${jobLink}' style='' target='_blank' rel="noopener noreferrer">View Job Documents</a>`)
        }

        body = body.replace(/<p><\/p>/g, "<br />");

        let userName = adminDetails ? adminDetails.name + " " + adminDetails.last_name : "";
        body = body.replace(/\{\{user_name\}\}/g, userName);

        // let org_name = adminDetails.organisation ? adminDetails.organisation.name : ""

        let userPhoneNumber = adminDetails && adminDetails.phone_number ? getFormattedPhone(adminDetails.phone_number) : ''
        body = body.replace(/\{\{user_phone_number\}\}/g, userPhoneNumber);

        let start_date = this.props.jobDetails.job_start_date ? getEmailDate(this.props.jobDetails.job_start_date) : ''
        body = body.replace(/\{\{job_start_date\}\}/g, start_date);

        body = body.replace(/\{\{user_role\}\}/g, adminDetails && adminDetails.role && adminDetails.role.role_name ? adminDetails.role.role_name : '')

        body = body.replace(/\{\{user_email\}\}/g, adminDetails && adminDetails.email_address ?
            `<a href="mailto:${adminDetails.email_address}">${adminDetails.email_address}</a>` : "")

        body = body.replace(/\{\{org_phone\}\}/g, adminDetails && adminDetails.organisation && adminDetails.organisation.phone_number ? adminDetails.organisation.phone_number : "")

        body = body.replace(/\{\{org_website\}\}/g, adminDetails && adminDetails.organisation && adminDetails.organisation.website ? adminDetails.organisation.website : '')

        body = body.replace(/\{\{org_address\}\}/g, adminDetails && adminDetails.organisation && adminDetails.organisation.address ? adminDetails.organisation.address : '')

        body = body.replace(/\{\{signature_image\}\}/g, `<img naturalheight="0" naturalwidth="0" src="https://dev-sf-store.s3.ap-southeast-2.amazonaws.com/email_banner.jpg" crossorigin="use-credentials" border="0" style="max-height: 200px; width: auto; cursor: pointer;">`)

        let site_name = this.props.jobDetails.sites && this.props.jobDetails.sites.length > 0 && this.props.jobDetails.sites[0].site_name ? this.props.jobDetails.sites[0].site_name : ''
        body = body.replace(/\{\{site_name\}\}/g, site_name)

        let client_person_first_name = this.props.jobDetails && this.props.jobDetails.client_person_name ? getFirstName(this.props.jobDetails.client_person_name) : '';
        body = body.replace(/\{\{client_person_first_name\}\}/g, client_person_first_name)

        // signature modification --- must be done in the end.
        if (body.includes("<p>____sign</p>") && body.includes("<p>sign____</p>")) {
            // has signature
            let start = body.indexOf("<p>____sign</p>");
            let end = body.lastIndexOf("<p>sign____</p>")

            if (start !== -1 && end !== -1) {
                let signEnd = end + 15;
                let dataStart = start + 15;
                let signData = body.substr(dataStart, (end - dataStart));
                this.setState({ signature: signData });
                body = body.substring(0, start) + '' + body.substring(signEnd);
            }
        }

        if (body.includes("<p>____no_reply</p>") && body.includes("<p>no_reply____</p>")) {
            // has no reply
            let start = body.indexOf("<p>____no_reply</p>");
            let end = body.lastIndexOf("<p>no_reply____</p>")

            if (start !== -1 && end !== -1) {
                let signEnd = end + 15;
                let dataStart = start + 15;
                let replyData = body.substr(dataStart, (end - dataStart));
                this.setState({ noReply: replyData });
                body = body.substring(0, start) + '' + body.substring(signEnd);
            }
        }

        const html = body
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({
                editorState: editorState
            });
            return editorState
        }
    }

    handleSearchToggle = () => {
        this.setState({ togleSearch: !this.state.togleSearch })
    }

    handleCcToggle = (event) => {
        event.preventDefault();
        this.setState({ toggleCc: !this.state.toggleCc })
    }

    handleBccToggle = (event) => {
        event.preventDefault();
        this.setState({ toggleBcc: !this.state.toggleBcc })
    }

    cancel = () => {
        goBackBrowser(this.props);
        this.props.setJobView(false);
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    onSubmit = async formData => {

        const jobId = getStorage('JOB_ID');
        let to = this.state.toEmails ? this.state.toEmails.map((val) => val) : []
        let bcc = this.state.bccEmails ? this.state.bccEmails.map((val) => val) : []
        let cc = this.state.ccEmails ? this.state.ccEmails.map((val) => val) : []
        let fromEmail = formData.from_field ? formData.from_field : '';

        formData.to_field = to.toString()
        formData.bcc_field = bcc.toString()
        formData.cc_field = cc.toString()

        formData = await DeepTrim(formData);
        var formValues = { ...formData }

        let jobLink = `${window.location.origin}/client/job-doc-preview/${this.org_id}/${jobId}`;
        if (process.env.REACT_APP_CLIENT_APP_URL) {
            jobLink = `${process.env.REACT_APP_CLIENT_APP_URL}/job-doc-preview/${this.org_id}/${jobId}`;
        }

        var value = this.state.editorState === '' ? '' : draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))

        value = value + `<!--[if mso]><table role="presentation" cellspacing="0" cellpadding="0" border="0" width="600"><tr><td><![endif]--><div style='width: auto;border:none;border-top:solid #000000 1.0pt; border-bottom: solid #000000 1.0pt; padding:4.0pt 0cm 4.0pt 0cm; margin: 20pt 0cm 10pt 0cm;'>`
            + this.state.noReply + `</div><!--[if mso]></td></tr></table><![endif]-->`;
        value = `<!--[if mso]><table role="presentation" cellspacing="0" cellpadding="0" border="0"><tr><td><![endif]--><div style="font-family: 'calibri', sans-serif !important; font-size: 11pt !important; width: fit-content;">` + value + `</div><!--[if mso]></td></tr></table><![endif]-->`;

        value = value.replace(/font-family:.?inherit;/g, "font-family: 'calibri', sans-serif !important;");

        value = value.replace(/\{\{service_name\}\}/g, Strings.SERVICE_NAME);
        value = value.replace(/\{\{service_email\}\}/g, `<a href="mailto:${Strings.SERVICE_EMAIL}">${Strings.SERVICE_EMAIL}</a>`);
        value = value.replace(/<p><\/p>/g, '')
        value = value.replace(/(?:\r\n|\r|\n)/g, '');

        formValues.message = this.state.editorState === '' ? this.editorState() : value

        delete formValues.job_doc_number;
        formValues.job_doc_link = jobLink
        formValues.job_doc_number = this.job_doc_no;
        // formValues.quote_number = this.quote_number
        formValues.client_id = this.client_id;
        formValues.org_id = this.org_id
        this.props.action.sendJobDocEmail(formValues)
            .then((res) => {
                goBackBrowser(this.props);
                this.props.setJobView(false)
                notification.success({
                    message: Strings.success_title,
                    description: "Email Sent",
                    onClick: () => { },
                    className: 'ant-success'
                    //onOK: goBack(this.props)
                });
            }).catch((message) => {
                notification.error({
                    message: Strings.error_title,
                    description: message ? message : Strings.generic_error,
                    onClick: () => { },
                    className: 'ant-error'
                });
            });
    }

    render() {
        const { handleSubmit, jobDocEmailDetails, setJobView, jobDetails } = this.props;
        return (
            <div className={this.props.togleSearch ? "col-md-9" : "col-md-9 col-md-srch"}>
                <form onSubmit={handleSubmit(this.onSubmit)}>
                    <div className="sf-card-wrap">
                        {/* <div className="sf-card scope-v-value">
                            <div className="sf-card-head d-flex justify-content-between align-items-start">
                                <div className="doc-vlue">Document #:
                                    <span>{this.props.location && this.props.location.state && this.props.location.state.job_doc_number}</span>
                                </div>
                                <strong className="doc-v-usr"><span>{this.loginUserRoleName}:</span>{this.loginUserName}</strong>
                            </div>
                        </div> */}
                        <div className="sf-card mt-4">
                            <div className="sf-card-head abb-1 d-flex justify-content-between align-items-center">
                                <h2 className="sf-pg-heading">{Strings.send_job_document}</h2>
                                <div className="info-btn disable-dot-menu">
                                    <Dropdown className="more-info" disabled>
                                        <i className="material-icons">more_vert</i>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="sf-card-body">
                                <div className="user-p-notxt send-email d-flex justify-content-start">
                                    <img alt="" src="/images/owl-img.png" />
                                    <span>This job document will be sent to {jobDetails && jobDetails.client_name ? jobDetails.client_name : ''} on email id {jobDocEmailDetails && jobDocEmailDetails.to_field ? jobDocEmailDetails.to_field : jobDetails && jobDetails.client_person_email}. If you wish to speak to the client and give them a heads up call them on their number {jobDetails && jobDetails.client_person_phone ? getFormattedPhone(jobDetails.client_person_phone) : ''}.</span>
                                </div>
                                <div className="email-form">
                                    <fieldset className="form-group sf-form">
                                        <Field
                                            label={Strings.from_profile}
                                            name="from_profile"
                                            type="text"
                                            id="name"
                                            disabled={true}
                                            component={customInput} />
                                    </fieldset>

                                    <fieldset className="form-group sf-form bcc-userid">
                                        <div className="cc-bcc-email">
                                            <button onClick={(event) => this.handleCcToggle(event)} className="normal-bnt">Cc</button>
                                            <button onClick={(event) => this.handleBccToggle(event)} className="normal-bnt">Bcc</button>
                                        </div>
                                        <Field
                                            label={Strings.from_txt}
                                            name="from_field"
                                            type="text"
                                            id="name"
                                            disabled={true}
                                            component={customInput} />
                                    </fieldset>

                                    <fieldset className="form-group sf-form">
                                        {/* <Field
                        label={Strings.to_txt}
                        name="to_field"
                        id="name"
                        component={Basic}
                      /> */}
                                        <label>To</label>
                                        <ReactMultiEmail
                                            placeholder="placeholder"
                                            emails={this.state.toEmails}
                                            onChange={(_emails) => {
                                                this.setState({ toEmails: _emails });
                                            }}
                                            validateEmail={email => {
                                                if (!isEmail(email)) {
                                                    this.setState({ toEmailsError: true })
                                                }
                                                this.setState({ toEmailsError: false })
                                                return isEmail(email); // return boolean

                                            }}
                                            getLabel={(email, index, removeEmail) => {
                                                return (
                                                    <div data-tag key={index}>
                                                        {email}
                                                        <span data-tag-handle onClick={() => removeEmail(index)}>
                                                            ×
                                                        </span>
                                                    </div>
                                                );
                                            }}
                                        />
                                        <span>{this.state.toEmailsError && "Invalid email"}</span>

                                    </fieldset>
                                    {/* cc and bcc fields here */}
                                    <div className="bcc-fields">
                                        <fieldset
                                            className={
                                                this.state.toggleCc
                                                    ? "form-group sf-form"
                                                    : "form-group sf-form d-none"
                                            }
                                        >
                                            {/* <Field
                          label="cc"
                          name="cc_field"
                          type="text"
                          id="name"
                          component={Basic}
                        /> */}
                                            <label>Cc</label>

                                            <ReactMultiEmail
                                                placeholder="placeholder"
                                                emails={this.state.ccEmails}
                                                onChange={(_emails) => {
                                                    this.setState({ ccEmails: _emails });
                                                }}
                                                validateEmail={email => {
                                                    if (!isEmail(email)) {
                                                        this.setState({ ccEmailsError: true })
                                                    }
                                                    this.setState({ ccEmailsError: false })
                                                    return isEmail(email); // return boolean

                                                }}
                                                getLabel={(email, index, removeEmail) => {
                                                    return (<>
                                                        <div data-tag key={index}>
                                                            {email}
                                                            <span data-tag-handle onClick={() => removeEmail(index)}>
                                                                ×
                                                            </span>
                                                        </div>
                                                    </>
                                                    );
                                                }}
                                            />
                                            <span>{this.state.ccEmailsError && "Invalid email"}</span>

                                        </fieldset>
                                        <fieldset
                                            className={
                                                this.state.toggleBcc
                                                    ? "form-group sf-form"
                                                    : "form-group sf-form d-none"
                                            }
                                        >
                                            {/* <Field
                          label="Bcc"
                          name="bcc_field"
                          type="text"
                          id="name"
                          component={Basic}
                        /> */}
                                            <label>Bcc</label>

                                            <ReactMultiEmail
                                                placeholder="placeholder"
                                                emails={this.state.bccEmails}
                                                onChange={(_emails) => {
                                                    this.setState({ bccEmails: _emails });
                                                }}
                                                validateEmail={email => {
                                                    if (!isEmail(email)) {
                                                        this.setState({ bccEmailsError: true })
                                                    }
                                                    this.setState({ bccEmailsError: false })
                                                    return isEmail(email); // return boolean

                                                }}
                                                getLabel={(email, index, removeEmail) => {
                                                    return (
                                                        <div data-tag key={index}>
                                                            {email}
                                                            <span data-tag-handle onClick={() => removeEmail(index)}>
                                                                ×
                                                            </span>
                                                        </div>
                                                    );
                                                }}
                                            />
                                            <span>{this.state.bccEmailsError && "Invalid email"}</span>

                                        </fieldset>
                                    </div>
                                    <fieldset className="form-group sf-form">
                                        <Field
                                            label={Strings.subject_txt}
                                            name="subject"
                                            type="text"
                                            id="name"
                                            component={customInput} />
                                    </fieldset>
                                    <div className="form-group sf-form rich-textbox">
                                        <label>{Strings.message_txt}</label>
                                        <div className="sf-rich-txtbox">
                                            <fieldset>
                                                <Field
                                                    name="message"
                                                    type="text"
                                                    id="name"
                                                    editorState={this.state.editorState === '' ? this.editorState() : this.state.editorState}
                                                    onEditorStateChange={this.onEditorStateChange}
                                                    component={EmailEditor} />
                                            </fieldset>
                                        </div>
                                    </div>
                                    <fieldset className="form-group sf-form">
                                        <Field
                                            label={Strings.validity_swms}
                                            name="validity"
                                            type="text"
                                            id="name"
                                            autoComplete="off"
                                            component={customInput} />
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="all-btn d-flex justify-content-end mt-4 sc-doc-bnt">
                        <div className="btn-hs-icon">
                            <button type="button" className={styles.FilterResetButton} onClick={this.cancel}>
                                {Strings.cancel_btn}</button>
                        </div>
                        <div className="btn-hs-icon">
                            <button type="submit" className={styles.PrimaryButton}>
                                {Strings.send_btn}</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let initialOrgData = {}

    let adminDetails = JSON.parse(getStorage(ADMIN_DETAILS));

    let jobDetails = state.jobDetailsReducer && state.jobDetailsReducer.jobDetails ? state.jobDetailsReducer.jobDetails : {};

    let user_name = adminDetails ? adminDetails.name + " " + (adminDetails.last_name ? adminDetails.last_name : '') : ''
    let org_name = adminDetails && adminDetails.organisation && adminDetails.organisation.name ? adminDetails.organisation.name : '';
    let emailTemplateContent = state.emailTemplate && state.emailTemplate.emailTemplateList && state.emailTemplate.emailTemplateList.length && state.emailTemplate.emailTemplateList[0] ? state.emailTemplate.emailTemplateList[0] : '';

    let cc = adminDetails && adminDetails.email_address ? adminDetails.email_address : "";

    let to = jobDetails && jobDetails.client_person_email ? jobDetails.client_person_email : '';

    let bcc = emailTemplateContent && emailTemplateContent.to_bcc !== undefined ? emailTemplateContent.to_bcc : '';

    let subject = emailTemplateContent && emailTemplateContent.subject ? emailTemplateContent.subject : '';

    subject = subject.replace(/\{\{org_name\}\}/g, org_name);
    subject = subject.replace(/\{\{job_number\}\}/g, jobDetails && jobDetails.job_number ? jobDetails.job_number : '');

    let site_name = jobDetails.sites && jobDetails.sites.length > 0 && jobDetails.sites[0].site_name ? jobDetails.sites[0].site_name : ''
    subject = subject.replace(/\{\{site_name\}\}/g, site_name)

    let noReplyFrom = `${user_name} (${org_name}) via ${Strings.SERVICE_NAME} <${emailTemplateContent.from_email}>`

    initialOrgData = emailTemplateContent ? {
        from_profile: org_name,
        from_field: noReplyFrom,
        to_field: to,
        cc_field: cc,
        bcc_field: bcc,
        subject: subject,
    } : {};

    return {
        jobDetails: jobDetails,
        jobDocEmailDetails: (initialOrgData ? initialOrgData : {}),
        initialValues: (initialOrgData ? initialOrgData : {}),
        selectedScopDoc: state.scopeDocs.scopeDocsDetails,
        selectedMailContent: emailTemplateContent ? emailTemplateContent : {},
    }
}

const mapDispatchToprops = dispatch => {
    return {
        action: bindActionCreators(actions, dispatch),
        emailAction: bindActionCreators(actionEmail, dispatch),
        setJobView: (value) => dispatch(jobDocumentsAction.setJobView(value)),
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToprops),
    reduxForm({
        form: 'ViewJobEmail', validate: JobDocsEmailvalidate,
        onSubmitFail: (errors, dispatch, sub, props) => {
            handleFocus(errors, "#");
        }
    })
)(ViewJobEmail)
