import {
  PROFILE_MANAGEMENT,
  ORG_USERS_URL,
  ORG_USER_DETAILS_URL,
  ORG_USER_PAYROLL_URL,
  ORG_USER_LICENCE_URL,
  ORG_USER_MEDICAL_DECLARATION_URL,
  ORG_USER_ADD_LEAVE_URL,
  PROFILE_LEAVE_TYPE,
  ORG_USER_LEAVE_TYPE_URL,
  PROFILE_LICENCES_TYPE,
  ORG_USER_ROSTERING_URL,
  ORG_USER_UP_PRO_IMG__URL,
  ORG_USER_VERIFY_ATTR,
  RESEND_VERIFY_ATTR,
  ORG_USER_RESIDENTIAL_ADDRESS_URL,
  ORG_USER_EXPERIENCE_URL,
  ORG_USER_EERGENCY_CONTACT_URL,
  ORG_USER_SUPER_FUND_URL,
  ORG_USER_BANK_DETAIL_URL,
  STATES_URL,
  STATES_LIST,
  PROFILE_PAY_CODE_URL,
  PROFILE_EMPLOYMENT_TYPE_URL,
  PROFILE_SERVICE_TYPE_URL,
  PROFILE_ROSTERING_DETAILS,
  PROFILE_SIGNATURE_URL,
  UPDATE_PROFILE_PICTURE,
  ALL_LICENSE_TYPES_URL,
  PROFILE_ALL_PAY_CLASSIFICATIONS_URL,
  ALL_TIMEZONES_URL,
  UPDATE_USER_TIMEZONE_URL,
  LOGGED_IN_USER_DETAIL_URL,
  ORGANIZATION_USERS_URL_V2,
  ORGANIZATION_USERS_PROFILE,
  ORGANIZATION_USERS_PROFILE_MANAGERS
} from "../dataProvider/constant";
import { startSipnner, stopSipnner } from "../utils/spinner";
import axiosInstance, { baseApiInstance, scopeApiInstance } from "../dataProvider/axiosHelper";
import { Strings } from "../dataProvider/localize";

export const getLoggedInUser = (org_user_id, org_user_name) => (dispatch) => {
  return baseApiInstance.get(LOGGED_IN_USER_DETAIL_URL, {
    params: {
      org_user_id,
      org_user_name,
      page_no: 1,
    },
    headers: {
      disableAccessRenew: true
    }
  }).then(res => {
    if (res.data && res.data.data && res.data.data.orgUsers && res.data.data.orgUsers.length > 0) {
      dispatch({
        type: PROFILE_MANAGEMENT,
        payload: res.data.data.orgUsers,
        // profilePic: res.data.data.orgUsers[0].profile_pic,
        // profileProgress: res.data.data.orgUsers[0].profile_progress,
      });
      dispatch({
        type: 'AUTH_USER_PROFILE',
        payload: { ...res.data.data.orgUsers[0] }
      })

      return Promise.resolve(true);
    } else {
      return Promise.reject(null);
    }
  }).catch(error => {
    console.error(error);
    return Promise.reject(error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error);
  })
}

export const getOrgUserDetails = (org_user_id, org_user_name, page_no, isFromManagement = true) => (
  dispatch
) => {
  startSipnner(dispatch);
  return axiosInstance
    .get(
      `${ORG_USERS_URL}?org_user_id=${org_user_id}&org_user_name=${org_user_name}&page_no=${page_no ? page_no : 1
      }`
    )
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: PROFILE_MANAGEMENT,
          payload: res.data.data.orgUsers,
          profilePic:
            res &&
            res.data &&
            res.data.data &&
            res.data.data.orgUsers[0] &&
            res.data.data.orgUsers[0].profile_pic,
          profileProgress:
            res &&
            res.data &&
            res.data.data &&
            res.data.data.orgUsers[0] &&
            res.data.data.orgUsers[0].profile_progress,
        });

        if (!isFromManagement) {
          dispatch({
            type: 'AUTH_USER_PROFILE',
            payload: { ...res.data.data.orgUsers[0] }
          })
        }

        return Promise.resolve(true);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    });
};

export const getOrgUserProfileDetails = (org_user_id, org_user_name, page_no, isFromManagement = true) => (
  dispatch
) => {
  startSipnner(dispatch);
  return baseApiInstance
    .get(
      `${ORGANIZATION_USERS_PROFILE}?org_user_id=${org_user_id}&org_user_name=${org_user_name}&page_no=${page_no ? page_no : 1
      }`
    )
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: PROFILE_MANAGEMENT,
          payload: res.data.data.orgUsers,
          profilePic:
            res &&
            res.data &&
            res.data.data &&
            res.data.data.orgUsers[0] &&
            res.data.data.orgUsers[0].profile_pic,
          profileProgress:
            res &&
            res.data &&
            res.data.data &&
            res.data.data.orgUsers[0] &&
            res.data.data.orgUsers[0].profile_progress,
        });

        if (!isFromManagement) {
          dispatch({
            type: 'AUTH_USER_PROFILE',
            payload: { ...res.data.data.orgUsers[0] }
          })
        }

        return Promise.resolve(true);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    });
};

export const updateOrgUserDetails = (formData) => (dispatch) => {
  startSipnner(dispatch);
  return axiosInstance
    .put(ORG_USER_DETAILS_URL, formData)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response : Strings.network_error
      );
    });
};

export const updateOrgUserPersonalDetails = (formData, staffUserName) => (dispatch) => {
  startSipnner(dispatch);
  const headers = staffUserName ? { headers: { 'staff_user_name': staffUserName } } : { headers: {} }
  return axiosInstance
    .put(ORG_USER_DETAILS_URL, formData, headers)
    .then((res) => {
      stopSipnner(dispatch);
      if (staffUserName)
        dispatch({
          type: 'SET_EDIT_STAFF_PROFILE'
        })
      else
        dispatch({
          type: 'UNSET_EDIT_STAFF_PROFILE'
        })
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response : Strings.network_error
      );
    });
};

export const updateOrgUserResidentialAddress = (formData, staffUserName) => (dispatch) => {
  startSipnner(dispatch);
  const headers = staffUserName ? { headers: { 'staff_user_name': staffUserName } } : { headers: {} }
  return axiosInstance
    .put(ORG_USER_RESIDENTIAL_ADDRESS_URL, formData, headers)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response : Strings.network_error
      );
    });
};

export const updateOrgUserExperience = (formData) => (dispatch) => {
  startSipnner(dispatch);
  return axiosInstance
    .put(ORG_USER_EXPERIENCE_URL, formData)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response : Strings.network_error
      );
    });
};

export const updateOrgUserEmergencyContact = (formData, staffUserName) => (dispatch) => {
  startSipnner(dispatch);
  const headers = staffUserName ? { headers: { 'staff_user_name': staffUserName } } : { headers: {} }
  return axiosInstance
    .put(ORG_USER_EERGENCY_CONTACT_URL, formData, headers)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response : Strings.network_error
      );
    });
};

export const updateUserPayroll = (formData, staffUserName) => (dispatch) => {
  startSipnner(dispatch);
  const headers = staffUserName ? { headers: { 'staff_user_name': staffUserName } } : { headers: {} }
  return axiosInstance
    .post(ORG_USER_PAYROLL_URL, formData, headers)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response : Strings.network_error
      );
    });
};

export const updateUserSuperFund = (formData, staffUserName) => (dispatch) => {
  startSipnner(dispatch);
  const headers = staffUserName ? { headers: { 'staff_user_name': staffUserName } } : { headers: {} }
  return axiosInstance
    .post(ORG_USER_SUPER_FUND_URL, formData, headers)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response : Strings.network_error
      );
    });
};

export const updateUserBankDetails = (formData, staffUserName) => (dispatch) => {
  startSipnner(dispatch);
  const headers = staffUserName ? { headers: { 'staff_user_name': staffUserName } } : { headers: {} }
  return axiosInstance
    .post(ORG_USER_BANK_DETAIL_URL, formData, headers)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response : Strings.network_error
      );
    });
};

export const adNewLicencesApi = (formData, staffUserName) => (dispatch) => {
  startSipnner(dispatch);
  const headers = staffUserName ? { headers: { 'staff_user_name': staffUserName } } : { headers: {} }
  return axiosInstance
    .post(ORG_USER_LICENCE_URL, formData, headers)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response : Strings.network_error
      );
    });
};

export const addMedicalDeclaration = (formData, staffUserName) => (dispatch) => {
  startSipnner(dispatch);
  const headers = staffUserName ? { headers: { 'staff_user_name': staffUserName } } : { headers: {} }
  return axiosInstance
    .post(ORG_USER_MEDICAL_DECLARATION_URL, formData, headers)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response : Strings.network_error
      );
    });
};

export const getLeaveType = () => (dispatch) => {
  startSipnner(dispatch);
  return axiosInstance
    .get(ORG_USER_LEAVE_TYPE_URL)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: PROFILE_LEAVE_TYPE,
          payload: res.data.data.leave_type,
        });
        return Promise.resolve(true);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(error ? error : Strings.network_error);
    });
};

export const addTimeOffRequest = (formData) => (dispatch) => {
  startSipnner(dispatch);
  return axiosInstance
    .post(ORG_USER_ADD_LEAVE_URL, formData)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response : Strings.network_error
      );
    });
};

export const getLicencesType = () => (dispatch) => {
  startSipnner(dispatch);
  return scopeApiInstance
    .get(ALL_LICENSE_TYPES_URL)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data && res.data.status) {
        dispatch({
          type: PROFILE_LICENCES_TYPE,
          payload: res.data.data.license_type_list,
        });
        return Promise.resolve(true);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(error ? error : Strings.network_error);
    });
};

export const deleteLicence = (formData, staffUserName) => (dispatch) => {
  startSipnner(dispatch);
  const headers = staffUserName ? { headers: { 'staff_user_name': staffUserName } } : { headers: {} }
  return axiosInstance
    .delete(ORG_USER_LICENCE_URL, {
      data: { id: formData.id, profile_progress: formData.profile_progress },
      ...headers
    })
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.message ? error.response.data.message : Strings.network_error
      );
    });
};

export const updateRostering = (formData, staffUserName) => (dispatch) => {
  startSipnner(dispatch);
  const headers = staffUserName ? { headers: { 'staff_user_name': staffUserName } } : { headers: {} }
  return axiosInstance
    .post(ORG_USER_ROSTERING_URL, formData, headers)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response : Strings.network_error
      );
    });
};

export const uploadProfileImage = (formData) => (dispatch) => {
  startSipnner(dispatch);
  return axiosInstance
    .put(ORG_USER_UP_PRO_IMG__URL, formData)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response : Strings.network_error
      );
    });
};

export const verifyAttr = (formData) => (dispatch) => {
  startSipnner(dispatch);
  return axiosInstance
    .post(ORG_USER_VERIFY_ATTR, formData)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response : Strings.network_error
      );
    });
};

export const resendVerifyAttr = (data) => (dispatch) => {
  startSipnner(dispatch);
  return axiosInstance
    .get(RESEND_VERIFY_ATTR + "?attr=" + data)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        return Promise.resolve(res.data.message && res.data.message);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(
        error.response ? error.response.data.message : Strings.network_error
      );
    });
};

export const getStates = () => (dispatch) => {
  startSipnner(dispatch);
  return axiosInstance
    .get(STATES_URL)
    .then((res) => {
      stopSipnner(dispatch);
      if (res.data.status) {
        dispatch({
          type: STATES_LIST,
          payload: res.data.data,
        });
        return Promise.resolve(true);
      }
    })
    .catch((error) => {
      stopSipnner(dispatch);
      return Promise.reject(error ? error : Strings.network_error);
    });
};

export const getPayCode = () => dispatch => {
  let url = PROFILE_PAY_CODE_URL;
  return scopeApiInstance.get(url).then((res) => {
    let data = res.data.data;
    if (data) {
      return Promise.resolve(data);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getEmploymentType = () => dispatch => {
  let url = PROFILE_EMPLOYMENT_TYPE_URL;
  return scopeApiInstance.get(url).then((res) => {
    let data = res.data.data;
    if (data) {
      return Promise.resolve(data);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getPayClassification = (search = "") => dispatch => {
  let url = PROFILE_ALL_PAY_CLASSIFICATIONS_URL;
  if (search.trim() !== "") {
    url = `${url}?search=${search.trim()}`;
  }
  return scopeApiInstance.get(url).then((res) => {
    let data = res.data.data;
    if (data) {
      return Promise.resolve(data);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getServiceType = (search = "") => dispatch => {
  let url = PROFILE_SERVICE_TYPE_URL;
  if (search.trim() !== "") {
    url = `${url}?search=${search.trim()}`;
  }
  return scopeApiInstance.get(url).then((res) => {
    let data = res.data.data;
    if (data) {
      return Promise.resolve(data);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const updateRosteringDetails = (formData, userName) => dispatch => {
  startSipnner(dispatch);
  return scopeApiInstance.put(PROFILE_ROSTERING_DETAILS, formData, { headers: { staff_user_name: userName } })
    .then(res => {
      let data = res.data;
      if (data) {
        return Promise.resolve(data.message)
      }
      else {
        return Promise.reject()
      }
    })
    .catch(error => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    }).finally(() => stopSipnner(dispatch));
}

export const addUpdateProfileSignature = (formData, staffUserName, isAdd) => dispatch => {
  let url = PROFILE_SIGNATURE_URL;
  const method = isAdd ? baseApiInstance.post : baseApiInstance.put;
  return method(url, formData, {
    headers: {
      staff_user_name: staffUserName
    }
  }).then((res) => {
    if (res && res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject();
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const updateProfilePicture = (formData, staff_user_name) => dispatch => {
  startSipnner(dispatch);
  return baseApiInstance.put(UPDATE_PROFILE_PICTURE, formData, {
    headers: {
      staff_user_name: staff_user_name
    }
  })
    .then(res => {
      let data = res.data;
      if (data) {
        return Promise.resolve(data.message)
      }
      else {
        return Promise.reject()
      }
    })
    .catch(error => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    }).finally(() => stopSipnner(dispatch));
}


export const getTimeZones = (abortController) => () => {
  return baseApiInstance.get(ALL_TIMEZONES_URL, { ...abortController }).then((res) => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })

}

export const updateUserTimeZone = (formData, staffUserName = null) => () => {
  return baseApiInstance.put(UPDATE_USER_TIMEZONE_URL, formData, { ...(staffUserName ? { headers: { staff_user_name: staffUserName } } : {}) }).then(res => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject()
    }
  })
    .catch((error) => {
      if (error) {
        return Promise.reject(
          error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
        );
      } else {
        return Promise.reject(null);
      }
    })
}

export const updateProfileManagers = (formData) => async (dispatch) => {
  return baseApiInstance.put(ORGANIZATION_USERS_PROFILE_MANAGERS, formData)
    .then(res => {
      if (res.data.status) {
        return Promise.resolve(res.data.message);
      }
    })
    .catch(error => {
      if (error) {
        return Promise.reject(
          error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
        );
      } else {
        return Promise.reject(null);
      }
    })
}