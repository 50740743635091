import React, { Component } from "react";
import { connect } from "react-redux";
import TabsContainer from "./Tabs/TabsContainer";
import { Icon } from "antd";
import { Switch, Route } from "react-router-dom";
// import SWMSActivityContainer from "./SWMSActivity/SWMSActivityContainer";
// import { getInitialValues } from './serializer'
import CreateToolboxTalk from '../ReportManagement/Tabs/CreateToolboxTalk';
import EditToolboxTalk from '../ReportManagement/Tabs/EditToolboxTalk';
export class OHSMastersContainer extends Component {
  state = {};

  componentDidMount() { }

  render() {
    console.log(";;;;;;;;;;;;;;;;;==",this.props.match.path);
    return (
      <div className="sf-page-layout">
        {/* inner header  */}
        <div className="dash-header">
          <h2 className="page-mn-hd">
            <Icon type="arrow-left" onClick={() => this.props.history.goBack()} />
            <p>OHS Management</p>
          </h2>
        </div>
        <div className="main-container swms-masters-container">
          <div className="sf-card-wrap">
            <Switch>
              <Route
                path={this.props.match.path + "/"}
                exact
                component={TabsContainer}
              />
              <Route
                path={this.props.match.path + "/report-management/create-toolbox-report"}
                exact
                render={(props) => <CreateToolboxTalk {...props} />}
                
              />
               <Route
                path={this.props.match.path + "/report-management/edit-toolbox-report"}
                exact
                render={(props) => <EditToolboxTalk {...props} />}
                
              />
            </Switch>
            {/* <TabsContainer /> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OHSMastersContainer);
