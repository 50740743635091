import { splitDateTime } from "./utils";
import moment from "moment";

export const serializeTimesheetData = (data) => {
  return data.map((dataItem) => ({
    ...dataItem,
    start_date: dataItem.start_time
      ? splitDateTime(dataItem.start_time, "DATE")
      : null,
    start_time: dataItem.start_time
      ? splitDateTime(dataItem.start_time, "TIME")
      : null,
    finish_date: dataItem.finish_time
      ? splitDateTime(dataItem.finish_time, "DATE")
      : null,
    finish_time: dataItem.finish_time
      ? splitDateTime(dataItem.finish_time, "TIME")
      : null,
  }));
};

export const serializeParams = ({
  start_date,
  finish_date,
  staff_user,
  user_role,
  state,
  selectedStaffUsers,
  currentPage,
  client,
  type,
}) => {
  const twoWeeksAgo = new Date();
  twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

  const serializedParams = {
    ...(start_date
      ? {
          pay_period_from_date: moment(start_date).format("YYYY-MM-DD"),
        }
      : { pay_period_from_date: moment(twoWeeksAgo).format("YYYY-MM-DD") }),
    ...(finish_date
      ? {
          pay_period_to_date: moment(finish_date).format("YYYY-MM-DD"),
        }
      : { pay_period_to_date: moment(new Date()).format("YYYY-MM-DD") }),
    ...(staff_user
      ? {
          staff_user_name: staff_user.map((item) => item.toString()),
        }
      : {}),
    ...(user_role
      ? {
          role_id: user_role.map((item) => item.toString()),
        }
      : {}),
    ...(state
      ? {
          state_id: state,
        }
      : {}),
    ...(selectedStaffUsers && selectedStaffUsers.length > 0
      ? {
          selected_job_shift_timesheet_ids: selectedStaffUsers.map((item) =>
            item.toString()
          ),
        }
      : {}),
    ...(currentPage ? { page: currentPage } : {}),
    ...(client ? { client_id: client.map((item) => item.toString()) } : {}),
    ...(type ? { csv_type: type } : {}),
  };

  return serializedParams;
};

export const serializeUpdateTimesheetShiftRequest = (formValues) => {
  return [
    {
      approved: formValues.approved ? 1 : 0,
      approved_by: formValues.approved_by,
      approved_false: formValues.approved ? false : true,
      approved_true: formValues.approved ? true : false,
      approver_name: formValues.approver_name,
      break_time: formValues.break_time,
      cold_places_allowance: parseInt(formValues.cold_places_allowance),
      ewp_allowance: parseInt(formValues.ewp_allowance),
      finish_date: formValues.finish_date,
      finish_time: `${formValues.finish_date} ${moment(
        formValues.finish_time,
        "HH:mm"
      ).format("HH:mm:ss")}`,
      first_aid_allowance: parseInt(formValues.first_aid_allowance),
      hot_places_allowance_exceeding_54degrees: parseInt(
        formValues.hot_places_allowance_exceeding_54degrees
      ),
      hotPlaces_allowance_between_46and54_degrees: parseInt(
        formValues.hotPlaces_allowance_between_46and54_degrees
      ),
      id: formValues.job_shift_timesheet_id,
      job_schedule_shift_id: formValues.job_schedule_shift_id,
      leading_hand_allowance_11to20_employees: parseInt(
        formValues.leading_hand_allowance_11to20_employees
      ),
      leading_hand_allowance_1to10_employees: parseInt(
        formValues.leading_hand_allowance_1to10_employees
      ),
      leading_hand_allowance_over_20_employees: parseInt(
        formValues.leading_hand_allowance_over_20_employees
      ),
      meal_allowance: parseInt(formValues.meal_allowance),
      motor_cycle_allowance: parseInt(formValues.motor_cycle_allowance),
      ra_greater_than_22: parseInt(formValues.ra_greater_than_22),
      ra_less_than_22: parseInt(formValues.ra_less_than_22),
      refuse_collection_allowance: parseInt(
        formValues.refuse_collection_allowance
      ),
      role_id: formValues.role_id,
      role_name: formValues.role_name,
      staff_name: formValues.staff_name,
      staff_user_name: formValues.staff_user_name,
      start_date: formValues.start_date,
      start_time: `${formValues.start_date} ${moment(
        formValues.start_time,
        "HH:mm"
      ).format("HH:mm:ss")}`,
      toilet_cleaning_allowance: parseInt(formValues.toilet_cleaning_allowance),
      total_hrs: formValues.total_hrs,
      user_name: formValues.user_name,
      vehicle_allowance: parseInt(formValues.vehicle_allowance),
      wah_allowance: parseInt(formValues.wah_allowance),
      allowance_updated: 1,
    },
  ];
};

export const serializeShiftIds = (data) => {
  const shiftIds = data.map((d) => d.job_schedule_shift_id);
  const uniqueIds = [...new Set(shiftIds)];

  return uniqueIds.map((item) => ({
    label: `${item}`,
    value: `${item}`,
  }));
};

export const getShiftStatusOptions = () => [
  { label: "Notified", value: 1 },
  { label: "Accepted", value: 2 },
  { label: "Declined", value: 3 },
  { label: "Started", value: 4 },
  { label: "Finished", value: 5 },
];
