import {
    REPORT_MANAGEMENT_CREATE_TOOLBOX_TALK,
    REPORT_MANAGEMENT_GET_TOOLBOX_TALK,
    GET_CLIENT_LIST,
    GET_TYPE_LIST,
    GET_LICENCE_LIST,
    GET_TOOLBOX_TALK_BY_ID
} from "../dataProvider/constant";

const INITIAL_STATE = {
    currentPageNumber: 1,
    reportManagementToolboxList: [],
    clientListData: {},
  }

  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      
        case REPORT_MANAGEMENT_GET_TOOLBOX_TALK:
          return {
            ...state,
            reportManagementToolboxList: action.reportManagementToolboxList
          }
          case GET_CLIENT_LIST:
          return {
            ...state,
            clientListData: action.clientListData
          }
          case GET_TYPE_LIST:
            return {
              ...state,
              typeListData: action.typeListData
            }
            case GET_LICENCE_LIST:
            return {
              ...state,
              licenceListData: action.licenceListData
            }
            case GET_TOOLBOX_TALK_BY_ID:
              return {
                ...state,
                toolboxTalkDetail: action.toolboxTalkDetail
              }
        default:
          return {
            ...state
          }
      }
  }
