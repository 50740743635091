export default {
  control: (styles, { isFocused, menuIsOpen }) => {
    return {
      ...styles,
      backgroundColor: '#fff',
      boxShadow: isFocused || menuIsOpen ? '0 0 0 2px rgb(3 167 145 / 20%)' : 'none',
      border: '1px solid #eff0f1',
      fontSize: '14px',
      "&:hover": {
        border: '1px solid #eff0f1',
      },
    }
  },
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      color: '#000000a6',
      padding: '5px 12px',
      fontSize: '14px',
      lineHeight: '22px',
      backgroundColor: isFocused || isSelected ? '#cfe6df' : '#fff',
      "&:hover": {
        backgroundColor: '#cfe6df80',
      },
    };
  },
};