import React, { useEffect, useRef, useState } from "react";
import { Icon } from "antd";

import styles from "./PreviewTaskImages.module.css"

export default function ImageLoader(props) {
  const [isimageLoading, setImageLoading] = useState(true);
  const imgRef = useRef(null);

  const onImageLoaded = () => {
    setImageLoading(false);
  };

  useEffect(() => {
    const el = imgRef.current;
    if (el.complete) {
      onImageLoaded();
    }
    el.addEventListener("load", onImageLoaded, { useCapture: true });
    return () => el.removeEventListener("load", onImageLoaded);
  }, [imgRef]);

  useEffect(() => {
    const el = imgRef.current;
    el.addEventListener("error", onImageLoaded, { useCapture: true });
    return () => el.removeEventListener("error", onImageLoaded);
  }, [imgRef])

  return (
    <div className="imgHolder">
      {isimageLoading && <div className={styles.loaderDiv}>
        <Icon type="loading" style={{ fontSize: "50px" }} />
      </div>}
      <img style={{ opacity: isimageLoading ? '0.5' : '1.0' }} ref={imgRef} alt={props.alt} {...props}/>
    </div>
  );
}
