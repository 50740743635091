import React from "react";
import { Modal, Button } from "antd";
import { connect } from "react-redux";
import { compose } from "redux";
import { handleFocus } from "../../../utils/common";
import { reduxForm } from "redux-form";
import ViewReportDocModel from "./viewReportDocModel";
import jsPdf from "jspdf";
import "./PreviewJobReport.css"
import jobStyles from "../Job/JobStyle.module.css";
import { IconButton } from "@mui/material";

const imageTypes = ['png', 'jpg', 'jpeg', 'webp', 'bmp'];

class PreviewJobReport extends React.Component {

    state = { isSignOff: false, viewTaskFiles: false, viewDoc: false, taskFiles: "", isDocument: false, fileType: '' }

    footer = () => {
        return <div className="footerBtn">
            <div className="btn-hs-icon">
                <button
                    type="button"
                    className="bnt bnt-normal"
                    onClick={() => this.props.pdfPrint(this.props.isSignOff, this.state.isDocument)}
                >
                    <i className="material-icons">get_app</i>
                    {"Download Photo Report"}
                </button>
            </div>

        </div>
    }

    jsPdfGenerator = () => {
        var doc = new jsPdf("p", "mm", [1000, 2000]);
        doc.getFillColor('#003300');
        doc.setFillColor('#003300');
        doc.html(document.querySelector('#content'), {
            callback: function (pdf) {
                // var pageCount = doc.internal.getNumberOfPages();
                // pdf.deletePage(pageCount);
                pdf.save("GenratedPdf.pdf");
            }
        })
        doc.setFont("Georgia");
        doc.setFillColor("#9DE080");
    };

    onSubmit = (formData, signObj, sign, actionSign) => {
        formData.client_sign_file = sign;
    };


    handleTaskFileView = (files, e) => {
        e.stopPropagation();
        if (files && files.length > 0) {
            let fileType = files.substring(files.lastIndexOf('.') + 1, files.length) || files;
            this.setState({
                fileType: fileType.toLowerCase(),
                viewTaskFiles: true,
                taskFiles: files,
            });
        }
    };

    handleDocView = (files, e) => {
        e.stopPropagation();
        if (files && files.length > 0) {
            let fileType = files.substring(files.lastIndexOf('.') + 1, files.length) || files;
            this.setState({
                fileType: fileType.toLowerCase(),
                viewTaskFiles: true,
                taskFiles: files,
            });
        }
    };


    handleDocCancel = () => {
        this.setState({
            viewDoc: false,
            taskFiles: [],
        });
    };

    handleCancel = () => {
        this.setState({
            viewTaskFiles: false,
            taskFiles: [],
        });
    };
    /////////download doc
    downLoadDoc = (url) => {
        fetch(url)
            .then((res) => {
                res.blob()
                    .then((blob) => {
                        var url = URL.createObjectURL(blob);
                        //////////////for downloading///////////////////////
                        const a = document.createElement("a");
                        a.style.display = "none";
                        a.href = url;
                        // the filename you want
                        a.download = `jobDoc_s.pdf`;


                        a.click();

                    })
                    .then((res) => {

                    })
            })



    }
    render() {
        const { job, jobReports, filePath, handleSubmit, rowCheck, docCheck, jobSignOffs } = this.props;



        return (
            <div className="preview-section">
                <div className="row logo-row">
                    <div className="col-6"><img className="area-img" src={job.org_logo} ></img></div>
                    <div className="col-6"><span className="heading">  Job Report</span></div>
                </div>
                <div className="innner-card first-row">
                    <div className="row">
                        <div className="col-12 col-lg-8 col-md-6 col-sm-12">
                            <h5>By</h5>
                            <ul className="card-list">
                                <li><span>Documents Prepared By: </span>{job.admin_name}</li>
                                <li><span> Company: </span>{job.org_name} </li>
                                <li><span> Address: </span>{job.address}</li>
                                <li><span> Mobile: </span>{job.admin_phone_number} </li>
                                <li><span> Email: </span>{job.admin_email_address} </li>
                            </ul>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 col-sm-12">
                            <h5>To</h5>
                            <ul className="card-list">
                                <li><span>Attention: </span>{job.client_person_name} </li>
                                <li><span> Company: </span>{job.client_name} </li>
                                <li><span> Address: </span>{job.client_address} </li>
                                <li><span> Mobile: </span>{job.client_person_phone}</li>
                                <li><span> Email: </span>{job.client_person_email}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="innner-card row-job-details">
                    <h4 className="bg-gray">Job Details</h4>

                    {job &&
                        job.sites.map((site) => {
                            return <div className="row">
                                <div className="col-12 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <h3>Site Name</h3>
                                    <p>  {site.site_name}</p>
                                </div>
                                <div className="col-12 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <h3>Site Address</h3>
                                    <p> {site.street_address} {site.city},{site.state},{site.country}  </p>
                                </div>
                                <div className="col-12 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <p>
                                        <span style={{ color: "#000", fontWeight: 800 }}>Quote Number:</span> {job.quote_number}
                                    </p>
                                    <p>
                                        <span style={{ color: "#000", fontWeight: 800 }} >Purchase Order:</span> {job.PO_number}{" "}
                                    </p>
                                </div>
                            </div>
                        })}
                </div>
                <div className="innner-card job-details-section">
                    {/* <DownloadOutlined style={{ fontSize: '20px', color: "#03a791", marginRight: "10px", }} /> Download Photo Report */}
                    <h4 className="bg-gray">Job Details <span className="download-icon" onClick={() => this.props.pdfPrint(this.props.isSignOff, this.state.isDocument)}></span></h4>

                    <div class="accordion" id="accordExample">

                        {rowCheck.map((report, index) => {
                            return <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button
                                            class="btn btn-link d-flex align-items-center justify-content-between"
                                            type="button"
                                            data-toggle="collapse"
                                            data-target={`#collapseOne${index}`}
                                            aria-expanded="true"
                                            aria-controls="collapseOne">
                                            <p className="m-0 pr-4" style={{ width: "95% !important", overflowX: "hidden", textOverflow: "ellipsis" }}>
                                                Task - {this.props.allTasks && this.props.allTasks.find(tsk => tsk.id === report.quote_task_id)
                                                    && this.props.allTasks.find(tsk => tsk.id === report.quote_task_id).task_name} {` ${report.area ? `,  Area-${report.area}` : " "}`}
                                            </p>
                                            <i class="fa fa-angle-right" aria-hidden="true" style={{ top: "0px" }}></i>
                                        </button>
                                    </h5>
                                </div>

                                <div id={`collapseOne${index}`} class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div class="card-body p-0" >
                                        <div className="table-responsive">
                                            <table class="table table-border">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" width="200px">Area Photo</th>
                                                        <th scope="col" width="200px">Before Photo</th>
                                                        <th scope="col" width="200px">After Photo</th>
                                                        <th scope="col" width="400px">Notes</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td scope="row"> {report.location_photo &&
                                                            report.location_photo.length > 0 ? (

                                                            <div className="location-afbf-pic  job-report-img" onClick={(e) => this.handleTaskFileView((filePath +
                                                                JSON.parse(report.location_photo)[0]), e)}>
                                                                <img

                                                                    src={
                                                                        filePath +
                                                                        JSON.parse(report.location_photo)[0]
                                                                    }

                                                                />
                                                            </div>

                                                        ) : null}
                                                        </td>
                                                        <td>{report.before_photo &&
                                                            JSON.parse(report.before_photo).length >
                                                            0 ? (
                                                            <div className="location-afbf-pic  job-report-img" onClick={(e) => this.handleTaskFileView((filePath +
                                                                JSON.parse(report.before_photo)[0]), e)}>
                                                                <img

                                                                    src={
                                                                        filePath +
                                                                        JSON.parse(report.before_photo)[0]
                                                                    }

                                                                />
                                                            </div>
                                                        ) : null}</td>
                                                        <td> {report.after_photo &&
                                                            JSON.parse(report.after_photo).length >
                                                            0 ? (
                                                            <div className="location-afbf-pic  job-report-img" onClick={(e) => this.handleTaskFileView((filePath +
                                                                JSON.parse(report.after_photo)[0]), e)}>
                                                                <img

                                                                    src={
                                                                        filePath +
                                                                        JSON.parse(report.after_photo)[0]
                                                                    }

                                                                />
                                                            </div>
                                                        ) : null}</td>
                                                        <td>{report && report.note}</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}




                    </div>
                </div>
                {
                    docCheck && docCheck.length > 0 && <div className="innner-card additional-documents-box">
                        <h4 className="bg-gray">Additional Documents</h4>
                        <div className="table-responsive">
                            <table class="table table-border">
                                <thead>
                                    <tr>
                                        <th scope="col" width="200px">Document Name</th>
                                        <th scope="col" width="200px">Document Comments</th>
                                        <th scope="col" width="200px">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {docCheck &&
                                        docCheck.map((doc) => {
                                            return <tr>
                                                <td scope="row">{doc.document_name}</td>
                                                <td> <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: doc.comments,
                                                    }}
                                                /></td>
                                                <td>
                                                    <IconButton onClick={(e) => this.handleDocView(doc.shift_report_document, e)} className={`${jobStyles.MuiIconButton} ${jobStyles.GreenTextV2} mx-2`}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style={{ height: "16px", width: "16px" }}>
                                                            <path fill="currentColor" d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                                                        </svg>
                                                    </IconButton>
                                                    <IconButton href={doc.shift_report_document} target="_blank" className={`${jobStyles.MuiIconButton} ${jobStyles.PrimaryText} mx-2`}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: "16px", width: "16px" }}>
                                                            <path fill="currentcolor" d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                        </svg>
                                                    </IconButton>
                                                    {/* <a onClick={(e) => this.handleDocView(doc.shift_report_document, e)}>
                                                        <EyeOutlined style={{ fontSize: '20px', color: "#03a791", marginLeft: "8px" }} />
                                                    </a>
                                                    <a href={doc.shift_report_document} target="_blank">
                                                        <DownloadOutlined style={{ fontSize: '20px', color: "#03a791", marginLeft: "10px" }} />
                                                    </a> */}
                                                </td>
                                            </tr>
                                        })}

                                </tbody>
                            </table>
                        </div>

                    </div>
                }

                {/* <div className="float-right mb-3">

                    <Checkbox

                        onChange={() => this.props.signOffToggle()}
                        checked={this.props.isSignOff ? true : false}
                    > Show Sign Off</Checkbox>
                </div>


                {
                    this.props.isSignOff && <div className="innner-card signOff-box mt-5">
                        <h4 className="bg-gray">Sign Off</h4>
                        <div className="responsive-table">
                            <table class="table table-border">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">SignOff Name</th>
                                        <th scope="col" width="400px" >Comment</th>
                                        <th scope="col">Signature</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {jobSignOffs &&
                                        <tr>
                                            <td scope="row">{jobSignOffs.length > 0 && jobSignOffs[0]["job_report_sign_off_date"]}</td>
                                            <td>{jobSignOffs.length > 0 && jobSignOffs[0]["client_name"]}</td>
                                            <td>{jobSignOffs.length > 0 && jobSignOffs[0]["feedback"]}</td>
                                            <td>
                                                <img src={jobSignOffs.length > 0 && jobSignOffs[0]["client_sign"]} width="215" />
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                } */}
                <div className="">
                    <div className="text-center">
                        <button onClick={() => this.props.pdfPrint(this.props.isSignOff, true)} className={`${jobStyles.SecondaryButton} mb-2`}>Download Report</button>
                        {/* <p className="download-icon"><a ><DownloadOutlined style={{ fontSize: '20px', color: "#03a791", marginRight: "10px", }} /> Download Report</a></p> */}
                    </div>
                </div>
                {/* task file view  */}

                <Modal
                    visible={this.state.viewTaskFiles}
                    className="job-img-gallery"
                    centered
                    footer={null}
                    width={768}
                    onCancel={this.handleCancel}
                >
                    <div className="container py-3">
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex align-items-center justify-content-center" style={{ height: '768px', backgroundColor: '#000000' }}>
                                    {this.state.fileType && imageTypes.includes(this.state.fileType) ?
                                        <img src={this.state.taskFiles} alt="Image" className="img-fluid" style={{ maxHeight: '760px' }} />
                                        : this.state.fileType && this.state.fileType === "pdf" ?
                                            <div style={{ width: '750px', height: '768px' }} >
                                                <embed src={this.state.taskFiles} type="application/pdf" style={{ width: '100%', height: '100%' }} />
                                            </div>
                                            : <div className="text-center" style={{ width: '400px', color: '#ffffff' }}>
                                                <span className="h6 font-weight-normal">Preview Not Available.</span>
                                                <div>
                                                    <Button class target="_blank" href={this.state.taskFiles} className="mt-4">Download</Button>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    visible={this.state.viewDoc}
                    className="job-img-gallery"
                    zIndex="99999"
                    style={{ left: 40, top: 20 }}
                    footer={null}
                    width="60%"

                    onCancel={this.handleDocCancel}
                >
                    <ViewReportDocModel taskFiles={this.state.taskFiles} />
                </Modal>
            </div >


        )
    }
}


const mapStateToProps = (state) => {
    //console.log(state);
    var name = state.sAJobMgmt.jobDetails.client_name
    return {
        innitialValues: { client_name: name },
        formValues:
            state.form && state.form.jobReport && state.form.jobReport.values
                ? state.form.jobReport.values
                : {},
        job: state.sAJobMgmt.jobDetails,
        jobReports: state.sAJobMgmt.jobReports,
        filePath: state.sAJobMgmt.filePath,
        jobDocument: state.sAJobMgmt.jobDocument
    };
};

const mapDispatchToprops = (dispatch) => {
    return {};
};

//connect with redux form
export default compose(
    connect(mapStateToProps, mapDispatchToprops),
    reduxForm({
        form: "PreviewJobReport",
        enableReinitialize: true,
        onSubmitFail: (errors, dispatch, sub, props) => {
            handleFocus(errors, "#");
        },
    })
)(PreviewJobReport);


