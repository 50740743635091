import React from 'react';
import { Menu, notification } from 'antd';
import { reduxForm, Field, isDirty } from 'redux-form';
import { withRouter } from "react-router-dom";
import { superFundValidation } from '../../../../utils/Validations/payrollDetailsVallidation';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { customInput } from '../../../common/custom-input';
import * as actions from '../../../../actions/profileManagementActions';
import { Strings } from '../../../../dataProvider/localize';
import { CustomCheckbox } from '../../../../components/common/customCheckbox';
import { ADMIN_DETAILS, VALIDATE_STATUS, ACCESS_CONTROL } from '../../../../dataProvider/constant';
import { getStorage, handleFocus, DeepTrim } from '../../../../utils/common';
import "../ProfileStyle.css";
import styles from "../ProfileStyles.module.css";
class SuperFundForm extends React.Component {
    paymentMothod = ['eWAY']
    confirm_bank_ac_no_flag = true
    constructor(props) {
        super(props);
        this.state = {
            hasEditPermission: false,
            // checked: false,
            superfundprovider: 'block'
        }

        this.org_user_id = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id : null;
        this.org_user_name = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)).user_name : null;
    }

    // more info
    editMenu = (
        <Menu>
            <Menu.Item onClick={this.handleEditClick}>{Strings.menu_edit_org}</Menu.Item>
        </Menu>
    )

    onSubmit = async (formData) => {
        formData = await DeepTrim(formData);

        if (formData.is_super_ac) {
            formData = { ...formData, fund_name: null, fund_abn: null, usi: null, phone_number: null, payment_method: null, bsb: null, ac_no: null, ac_name: null }
            this.props.change('fund_name', null)
            this.props.change('fund_abn', null)
            this.props.change('usi', null)
            this.props.change('phone_number', null)
            this.props.change('payment_method', null)
            this.props.change('bsb', null)
            this.props.change('ac_no', null)
            this.props.change('ac_name', null)
        }

        formData = { ...formData, 'phone_no': formData.phone_number, 'org_id': this.org_user_id, 'user_name': this.org_user_name, 'profile_progress': this.props.profileComplete }
        delete formData.phone_number

        let staffUserName = false
        if (this.props.userDetails) {
            staffUserName = this.props.userDetails.user_name
        }

        this.props.action.updateUserSuperFund(formData, staffUserName)
            .then(flag => {
                notification.success({
                    message: Strings.success_title,
                    description: flag,
                    onClick: () => { },
                    className: 'ant-success'
                });
                this.props.action.getOrgUserProfileDetails(this.props.userId ? this.props.userId : this.org_user_id, staffUserName ? staffUserName : this.org_user_name, null, this.shouldUpdateAuthUser)
            })
            .catch((error) => {
                if (error.status === VALIDATE_STATUS) {
                    notification.warning({
                        message: Strings.validate_title,
                        description: error && error.data && typeof error.data.message == 'string'
                            ? error.data.message : Strings.generic_validate,
                        onClick: () => { },
                        className: 'ant-success'
                    });
                } else {
                    notification.error({
                        message: Strings.error_title,
                        description: error && error.data && error.data.message && typeof error.data.message == 'string'
                            ? error.data.message : Strings.generic_error,
                        onClick: () => { },
                        className: 'ant-error'
                    });
                }
            });
    }

    componentDidMount() {
        if (this.hasPermission("sf_payroll_details_controller_update_payroll_details")) {
            this.setState({ hasEditPermission: true });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.initialValues.bank_ac_number !== parseInt(this.props.formValues.bank_ac_number)) {
            this.confirm_bank_ac_no_flag = false
        }
        if (this.props.initialValues.bank_ac_number === parseInt(this.props.formValues.bank_ac_number)) {
            this.confirm_bank_ac_no_flag = true
        }
        if (this.props.formValues && this.props.formValues.is_super_ac) {
            if (this.state.superfundprovider === "block") {
                this.setState({
                    superfundprovider: 'none'
                })
            }
        } else {
            if (this.state.superfundprovider === "none") {
                this.setState({
                    superfundprovider: 'block'
                })
            }
        }
    }

    handleChecked = (e) => {
        if (!e.target.checked) {
            this.setState({
                superfundprovider: 'block'
            })
        } else {
            this.setState({
                superfundprovider: 'none'
            })
        }
    }

    hasPermission = (permission) => {
        let allowed = false;
        let userPermissions = JSON.parse(getStorage(ACCESS_CONTROL))["userManagement"]
        let accessControl = userPermissions && userPermissions.permissions ? userPermissions.permissions : [];
        let permission_id = accessControl.findIndex(acess => acess.control_name.toLowerCase() === permission.toLowerCase());
        if (permission_id !== -1 || this.props.location.pathname === "/dashboard/profile") {
            allowed = true;
        }

        return allowed;
    }

    render() {
        const { handleSubmit } = this.props;

        return (
            <form onSubmit={handleSubmit(this.onSubmit)} >

                {/* Super Fund Provider */}

                <hr className={styles.DetailsDivider}></hr>
                <div className="container-fluid pt-3 pb-2">
                    <div className="row align-items-center">
                        <h2 className={`${styles.ProfileLabel} font-weight-bold`}>Super Fund Provider</h2>
                    </div>
                    {this.state.superfundprovider === 'block' ?
                        <div className="pb-2" style={{ display: this.state.superfundprovider }}>
                            <div className="row">
                                <div className="col-3 border-right">
                                    <div className={styles.ProfileLabelV2}>Super Name</div>
                                    <div>
                                        <Field
                                            name="fund_name"
                                            type="text"
                                            id="fund_name"
                                            component={customInput}
                                            className={styles.ProfileFormField}
                                            disabled={!this.state.hasEditPermission}
                                        />
                                    </div>
                                </div>
                                <div className="col-3 border-right">
                                    <div className={styles.ProfileLabelV2}>Super USI</div>
                                    <div>
                                        <Field
                                            name="usi"
                                            type="text"
                                            id="usi"
                                            component={customInput}
                                            className={styles.ProfileFormField}
                                            disabled={!this.state.hasEditPermission}
                                        />
                                    </div>
                                </div>
                                <div className="col-3 border-right">
                                    <div className={styles.ProfileLabelV2}>Account Number</div>
                                    <div>
                                        <Field
                                            name="ac_no"
                                            type="text"
                                            id="ac_no"
                                            component={customInput}
                                            className={styles.ProfileFormField}
                                            disabled={!this.state.hasEditPermission}
                                        />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className={styles.ProfileLabelV2}>Account Name</div>
                                    <div>
                                        <Field
                                            name="ac_name"
                                            type="text"
                                            id="ac_name"
                                            component={customInput}
                                            className={styles.ProfileFormField}
                                            disabled={!this.state.hasEditPermission}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ''
                    }
                    <div className="col-12 px-0">
                        <div className={styles.ProfileLabelV2}>
                            <Field
                                name="is_super_ac"
                                label={Strings.super_acc_check}
                                onChange={this.handleChecked}
                                component={CustomCheckbox}
                                className="cs-select-v2"
                                disabled={!this.state.hasEditPermission} />
                        </div>
                        <div className="mt-2">
                            <button type="submit" className={styles.BtnSaveTab} disabled={!this.props.isDirty || !this.hasPermission('sf_payroll_details_controller_update_payroll_details')}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </form>

        );
    }
}

const mapStateToProps = (state) => {
    let value = {}
    let temp = state.profileManagement && state.profileManagement.profile && state.profileManagement.profile[0];
    if (temp && temp.payroll_details) {
        value = {
            ...value, id: temp.payroll_details.id, fund_name: temp.payroll_details.fund_name, fund_abn: temp.payroll_details.fund_abn,
            usi: temp.payroll_details.usi, phone_number: temp.payroll_details.phone_no, bsb: temp.payroll_details.bsb,
            ac_no: temp.payroll_details.ac_no, ac_name: temp.payroll_details.ac_name, is_super_ac: temp.payroll_details.is_super_ac
        }
    }

    for (let key in value) {
        if (value[key] === "null") {
            value[key] = null;
        }
    }

    return {
        formValues: state.form && state.form.SuperFundForm &&
            state.form.SuperFundForm.values ? state.form.SuperFundForm.values : {},
        initialValues: value,
        isDirty: isDirty('SuperFundForm')(state),
        profileComplete: state.profileManagement && state.profileManagement.profileComplete
    }
}

const mapDispatchToprops = dispatch => {
    return {
        action: bindActionCreators(actions, dispatch),
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToprops),
    reduxForm({
        form: 'SuperFundForm', validate: superFundValidation, enableReinitialize: true,
        destroyOnUnmount: true,
        onSubmitFail: (errors, dispatch, sub, props) => {
            handleFocus(errors, "#");
        }
    })
)(SuperFundForm)