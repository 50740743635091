import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./JobBoardContainer.module.css";
import InformationPanelContainer from "./InformationPanel/InformationPanelContainer";
import CalendarContainer from "./CalendarPanel/CalendarContainer";
import { legends } from "./legendsConfig";

export class JobBoardContainer extends Component {
  componentDidMount() {
    // console.log('selectedJobDetails', this.props.selectedJobDetails)
    // console.log('selectedJobDetails', this.props.jobDetails)
  }

  render() {
    const { allTasks } = this.props;

    return (
      <div className="sf-card">
        <div className={styles.JobBoardContainer}>
          <div className="col-md-6">
            <InformationPanelContainer />
          </div>
          <div className="col-md-6">
            <div className={styles.FiltersLegendWrapper}>
              {legends.map((legend) => (
                <div
                  style={{
                    background: `${legend.color}`,
                    color: 'white',
                    borderRadius: '5px',
                    margin: '0 20px 5px',
                    padding: '4px',
                    fontSize: '0.8rem',
                    fontWeight: 'bold',
                    minWidth: '5rem',
                    textAlign: 'center'
                  }}
                >
                  {legend.label}
                </div>
              ))}
            </div>
            <CalendarContainer events={allTasks} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedJobDetails: state.JobCalendar.selectedJobDetails,
  jobDetails: state.jobManagement.taskJobDetails,
  allTasks: state.JobCalendar.allTasks,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(JobBoardContainer);
