import {
  SET_LOADING_CONTRACTORS,
  SET_CONTRACTORS,
  SET_CONTRACTOR_DETAILS,
  SET_CONTRACTOR_DOCUMENTS,
  SET_CONTRACTOR_NOTES,
  SET_CONTRACTOR_CERTIFICATES,
  SET_CONTRACTOR_FINANCIAL_DETAILS,
  SET_CONTRACTOR_SERVICES,
  SET_CONTRACTOR_FILTERS,
  SET_CONTRACTOR_USERS,
  SET_CONTRACTOR_USER_DETAILS,
  CLEAR_CONTRACTORS,
  CLEAR_CONTRACTOR_DETAILS
} from "../../dataProvider/constant";
const INITIAL_STATE = {
  contractors: [],
  loadingContractors: true,
  contractorsCount: 0,
  contractorDetails: null,
  contractorFinancialDetails: null,
  contractorDocuments: [],
  contractorNotes: [],
  contractorCertificates: [],
  contractorServices: [],
  contractorServiceSectors: [],
  contractorServiceStates: [],
  contractorServiceRegions: [],
  contractorUsers: [],
  contractorUserDetails: null,
  contractorUserLicenses: [],
  contractorUsersCount: 0
}

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_CONTRACTORS: {
      return {
        ...state,
        contractors: payload.contractors,
        contractorsCount: payload.contractors_count
      }
    }
    case SET_LOADING_CONTRACTORS: {
      return {
        ...state,
        loadingContractors: payload
      }
    }
    case SET_CONTRACTOR_DETAILS: {
      return {
        ...state,
        contractorDetails: payload
      }
    }
    case SET_CONTRACTOR_DOCUMENTS: {
      return {
        ...state,
        contractorDocuments: payload
      }
    }
    case SET_CONTRACTOR_NOTES: {
      return {
        ...state,
        contractorNotes: payload
      }
    }
    case SET_CONTRACTOR_CERTIFICATES: {
      return {
        ...state,
        contractorCertificates: payload
      }
    }
    case SET_CONTRACTOR_FINANCIAL_DETAILS: {
      return {
        ...state,
        contractorFinancialDetails: payload
      }
    }
    case SET_CONTRACTOR_SERVICES: {
      return {
        ...state,
        contractorServices: payload
      }
    }
    case SET_CONTRACTOR_FILTERS: {
      return {
        ...state,
        contractorServiceSectors: payload.service_sectors ? payload.service_sectors : [],
        contractorServiceStates: payload.service_states ? payload.service_states : [],
        contractorServiceRegions: payload.service_regions ? payload.service_regions : []
      }
    }
    case SET_CONTRACTOR_USERS: {
      return {
        ...state,
        contractorUsers: payload.orgUsers,
        contractorUsersCount: payload.org_users_count
      }
    }
    case SET_CONTRACTOR_USER_DETAILS: {
      return {
        ...state,
        contractorUserDetails: payload,
        contractorUserLicenses: payload.licences ? payload.licences : []
      }
    }
    case CLEAR_CONTRACTOR_DETAILS: {
      return {
        ...state,
        contractorDetails: null,
        contractorFinancialDetails: null,
        contractorDocuments: [],
        contractorNotes: [],
        contractorCertificates: [],
        contractorServices: [],
        contractorUsers: [],
        contractorUserDetails: null,
        contractorUserLicenses: [],
        contractorUsersCount: 0
      }
    }
    case CLEAR_CONTRACTORS: {
      return {
        ...state,
        contractors: [],
        contractorsCount: 0,
      }
    }
    default: {
      return state;
    }
  }
}