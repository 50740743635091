import React from "react";
import { Input } from "antd";

export default function InputField({ input, label, meta: { touched, error, warning }, ...props }) {
  return (
    <div>
      {label !== undefined &&
        <label>{label}</label>
      }
      <div>
        <Input {...input} {...props} />
        {touched && ((error && <span className="error-input">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </div>
  )
}