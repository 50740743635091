import { Tabs } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import CleaningReport from "./CleaningReport";

const { TabPane } = Tabs;

export class ReportContainer extends Component {
  handleTabChange = () => { };

  render() {
    // const { currentAction, job } = this.props;
    return (
      <div className=" m-0">
          <Tabs tabPosition="top" renderTabBar={() => <div></div>} onChange={this.handleTabChange}>
            <TabPane tab="Job Report" key={"1"}>

              <CleaningReport jobId={this.props.jobId} />

            </TabPane>
            {/* <TabPane tab="Incident" key={"2"}>

          </TabPane>
          <TabPane tab="Hazards" key={"3"}>

          </TabPane> */}
          </Tabs>
      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  // job: state.sAJobMgmt.jobDetails
});

const mapDispatchToProps = (dispatch) => {
  return {}

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportContainer);
