import React, { useRef, useEffect, useState } from "react";
import styles from "../../JobStyle.module.css";
import { IconButton, Typography } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { useDispatch } from "react-redux";
import { notification, Pagination } from "antd";
import { LinearProgress } from "@mui/material";
import { Strings } from "../../../../../dataProvider/localize";
import { getWorkFlowHistory } from "../../../../../actions/v2/JobAllocatonActions";
import { downloadDocument } from "../../../../../utils/common";
import PreviewDocument from "../../JobSwms/JobSwmsForm/PreviewDocument";

export default function WorkFlowHistory({ jobId }) {

  const dispatch = useDispatch();
  const controller = useRef();
  const moment = require("moment");

  const [histories, setHistories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fieldCount, setFieldCount] = useState(0);
  const [page, setPage] = useState(1);
  const [downloading, setDownloading] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);


  const fetchHistory = (pageNo = 1) => {
    setLoading(true);
    if (page !== pageNo) {
      setPage(pageNo)
    }
    controller.current = new AbortController();
    dispatch(getWorkFlowHistory(pageNo, { job_id: jobId, limit: 5 }, { signal: controller.current.signal })).then(res => {
      let data = res.rows;
      setHistories(data.map(x => ({
        ...x,
        action_description: x.action_description === "Approved" ? "Invoice Approved" : x.action_description === "Unapproved" ? "Invoice Unapproved" : x.action_description
      })));
      setFieldCount(res.count);
      setLoading(false);
    }).catch((err) => {
      if (err) {
        setLoading(false);
        notification.error({
          message: Strings.error_title,
          description: "There was an error while fetching Notes.",
          className: "ant-error"
        })
      }
    })
  }

  const toggleFilePreview = (file = null) => {
    setPreviewFile(file);
  }

  const downloadReport = async (history) => {
    setDownloading(true);
    try {
      downloadDocument(history.doc_file);
    } catch (e) {
      notification.error({
        message: Strings.error_title,
        description: e ? e : Strings.generic_error,
        className: "ant-error"
      })
    }
  }

  useEffect(() => {
    fetchHistory();
    return () => {
      if (controller.current) {
        controller.current.abort()
      }
    }
  }, []);

  return (
    <div className={styles.blankContainer}>
      <div className="container-fluid mt-2 pr-0" style={{ color: "#000" }}>
        <div className="row">
          <div className="col">
            <div className={`${styles.SectionHeading} px-2`}>Workflow History</div>
            <div className={styles.card}>
              <div className="container-fluid px-0 p-2">
                <div className="row">
                  <div className="col-12">
                    <div style={{ height: "4px" }}>
                      {loading ? (
                        <LinearProgress />
                      ) : ""}
                    </div>
                    <table className="table-sm cs-table cs-table-v2" style={{ tableLayout: "fixed", width: "100%" }}>
                      <thead>
                        <tr>
                          <th width="15%">Task Id / Name</th>
                          <th width="20%">Notes</th>
                          <th width="15%">Invoice</th>
                          <th width="20%">Manager</th>
                          <th width="15%">Date / Time</th>
                          <th width="15%" className="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {histories.map(history => (
                          <tr key={history.id}>
                            <td title={history.task_and_area_name}>
                              <Typography className={styles.TableText} noWrap>
                                {history.task_and_area_name}
                              </Typography>
                            </td>
                            <td title={history.action_description}>
                              <Typography className={styles.TableText} noWrap>
                                {history.action_description}
                              </Typography>
                            </td>
                            <td title={history.po_order_or_invoice_number}>
                              <Typography className={styles.TableText} noWrap>
                                {history.po_order_or_invoice_number}
                              </Typography>
                            </td>
                            <td title={history.manager_name}>
                              <Typography className={styles.TableText} noWrap>
                                {history.manager_name}
                              </Typography>
                            </td>
                            <td title={history.created_at ? moment.utc(history.created_at).format("DD/MM/YY HH:mm") : ""}>
                              <Typography className={styles.TableText} noWrap>
                                {history.created_at ? moment.utc(history.created_at).format("DD/MM/YY HH:mm") : ""}
                              </Typography>
                            </td>
                            <td className="text-center">
                              <Tooltip title="Preview">
                                <span>
                                  <IconButton onClick={() => toggleFilePreview(history.doc_file)} className="px-1" sx={{ height: "auto", color: "#90bf00" }} size="small">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
                                      <path fill="currentColor" d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                                    </svg>
                                  </IconButton>
                                </span>
                              </Tooltip>
                              <Tooltip title="Download">
                                <span>
                                  <IconButton onClick={() => downloadReport(history)} className="px-1" sx={{ height: "auto", color: "#362148" }} size="small">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                      <path fill="currentColor" d="M256 0a256 256 0 1 0 0 512A256 256 0 1 0 256 0zM127 281c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l71 71L232 136c0-13.3 10.7-24 24-24s24 10.7 24 24l0 182.1 71-71c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L273 393c-9.4 9.4-24.6 9.4-33.9 0L127 281z" />
                                    </svg>
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-flex justify-content-end pt-1">
                    <Pagination
                      className="cs-pagination-v2"
                      total={fieldCount}
                      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Histories`}
                      onChange={(pageNo) => { fetchHistory(pageNo) }}
                      pageSize={5}
                      current={page}
                      defaultCurrent={1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {previewFile &&
        <PreviewDocument file={previewFile} onCancel={toggleFilePreview} />
      }
    </div>
  )
}