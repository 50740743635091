import React, { useEffect, useMemo, useState } from "react";
import { Tag } from "antd";
import moment from "moment";

import { downloadDocument } from "../../../utils/common";
import styles from "./ProfileStyles.module.css";

export default function LicenseView({ license, editLicence, deleteLicence, isDisabled, profile, licenseType }) {

  const [active, setActive] = useState(null);

  const userLicenseType = useMemo(() => {
    return licenseType.find((type) => type.id === license.type);
  }, [license, licenseType])

  const downloadImage = () => {
    downloadDocument(profile[0] && profile[0].licences_file_path + active, true);
  };

  useEffect(() => {
    if (license.image) {
      setActive(license.image)
    }
  }, [license]);

  return (
    <div className="col-12 col-md-6 col-lg-6 col-xl-4">
      <div className="licence-list">
        <div className="action-bnt" style={{ flexDirection: "column", rowGap: "5px", justifyContent: "center", height: "150px", alignItems: "center", paddingRight: "5px" }}>
          <button className="circular-icon-button" onClick={downloadImage}>
            <i class="fa fa-download"></i>
          </button>
          <button
            className="circular-icon-button"
            type="button"
            onClick={() => editLicence(license)}
            disabled={isDisabled}
          >
            <i class="fa fa-pencil"></i>
          </button>
          <button
            className="circular-icon-button"
            type="button"
            onClick={() => deleteLicence(license.id)}
            disabled={isDisabled}
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
        <div
          id={"licencesSlider_" + license.id}
          className="carousel slide taskfimgModal"
          data-interval="false"
          data-ride="carousel"
        >
          <div className="carousel-inner text-center">
            <div
              className={"carousel-item active"}
              style={{ height: "unset" }}
            >
              {license && license.image &&
                license.image.toLowerCase().includes(".pdf") ? (
                <div className="license-img show-pdf-file-view" style={{ width: "200px", height: "150px" }}>
                  <i class="fa fa-file-pdf-o" style={{ fontSize: "80px", height: "100px", paddingTop: "30px" }}></i>
                  <div className="pt-2">Front</div>
                </div>
              ) : (
                <div
                  className={`licence-img ${styles.LicenseImageWrapper}`}
                  style={{ width: "100%" }}
                >
                  <img
                    src={
                      profile[0] &&
                      profile[0].licences_file_path +
                      license.image
                    }
                    className={styles.LicenseImage}
                    alt="Front"
                  />
                </div>
              )}
            </div>
            {license && license.license_back_photo ? (
              <div
                className={"carousel-item"}
                style={{ height: "unset" }}
              >
                {license.license_back_photo && license.license_back_photo.toLowerCase().includes(".pdf") ? (
                  <div className="license-img show-pdf-file-view" style={{ width: "200px", height: "150px" }}>
                    <i class="fa fa-file-pdf-o" style={{ fontSize: "80px", height: "100px", paddingTop: "30px" }}></i>
                    <div className="pt-2">Back</div>
                  </div>
                ) : (
                  <div
                    className={`licence-img ${styles.LicenseImageWrapper}`}
                    style={{ width: "100%" }}
                  >
                    <img
                      src={
                        profile[0] &&
                        profile[0].licences_file_path +
                        license.license_back_photo
                      }
                      className={styles.LicenseImage}
                      alt="Back"
                    />
                  </div>
                )}
              </div>
            ) : null}
          </div>
          {license.license_back_photo && license.image ? (
            <div className="slider-nav">
              <a
                className="carousel-control-prev"
                href={"#licencesSlider_" + license.id}
                role="button"
                style={{ left: "5px" }}
                data-slide="prev"
                onClick={() => setActive(val => license.license_back_photo && val === license.image ? license.license_back_photo : license.image)}
              >
                <i
                  className="material-icons"
                  aria-hidden="true"
                >
                  keyboard_arrow_left
                </i>
              </a>
              <a
                className="carousel-control-next"
                href={"#licencesSlider_" + license.id}
                role="button"
                style={{ right: "5px" }}
                data-slide="next" onClick={() => setActive(val => license.license_back_photo && val === license.image ? license.license_back_photo : license.image)}
              >
                <i
                  className="material-icons"
                  aria-hidden="true"
                >
                  keyboard_arrow_right
                </i>
              </a>
            </div>) : null}
        </div>
        <div className="li-details pt-2" style={{ flex: "1 1 auto", width: "auto" }}>
          <h3 className={`${styles.ProfileLabel} font-weight-bold`}>{userLicenseType ? userLicenseType.name : ""}</h3>
          {license.expired ? (
            <Tag color="red">Expired</Tag>
          ) : null}
          <ul className="li-lists">
            <li>
              <span className={`${styles.ProfileLabel} font-weight-bold`}>Number:</span>{" "}
              <span className={`${styles.ProfileLabel}`}>{license && license.number}</span>
            </li>
            <li>
              <span className={`${styles.ProfileLabel} font-weight-bold`}>Issued By:</span>{" "}
              <span className={`${styles.ProfileLabel}`}>{license && license.issued_by}</span>
            </li>
            <li>
              <span className={`${styles.ProfileLabel} font-weight-bold`}>Issue Date:</span>{" "}
              <span className={`${styles.ProfileLabel}`}>{license &&
                license.issued_date &&
                moment(
                  license && license.issued_date
                ).format("D/MM/YYYY")}</span>
            </li>
            <li>
              <span className={`${styles.ProfileLabel} font-weight-bold`}>Expiry Date:</span>{" "}
              <span className={`${styles.ProfileLabel}`}>{license &&
                license.expiry_date &&
                moment(
                  license && license.expiry_date
                ).format("D/MM/YYYY")}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}