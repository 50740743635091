import React from 'react';
import { Icon, Dropdown, Menu, notification } from 'antd';
import { withRouter } from "react-router-dom";
import { reduxForm, Field, isDirty } from 'redux-form';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { customInput } from '../../../common/custom-input';
import { customCountrySelect } from '../../../common/customCountrySelect';
import { CustomSelect } from '../../../common/customSelect';
import { Strings } from '../../../../dataProvider/localize';
import { ADMIN_DETAILS, VALIDATE_STATUS, ACCESS_CONTROL } from '../../../../dataProvider/constant';
import { handleFocus, DeepTrim } from '../../../../utils/common';
import * as actions from '../../../../actions/profileManagementActions';
import { getStorage, setStorage, countries } from '../../../../utils/common';
import { emergencyContactValidation } from '../../../../utils/Validations/profileValidation';
import colourStyles from "../FilterSelectProfileStyles";
import { components } from "react-select";
import { CustomSingleSelect } from "../../../common/new-core-ui/CustomSingleSelect";
import "../ProfileStyle.css";
import styles from "../ProfileStyles.module.css";
class EmergencyContactForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            date: '',
            phnOtpErr: false,
            emailOtpErr: false,
            hasEditPermission: false
        }

        this.org_user_id = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id : null;
        this.org_user_name = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)).user_name : null;
    }

    static getDerivedStateFromProps(props, state) {
        var OrgSACountry = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)).country : null;
        if (!props.formValues && props.formValues.ec_country || props.formValues && props.formValues.ec_country === '') {
            props.change(`ec_country`, OrgSACountry);
        }
    }

    componentDidMount() {
        if (this.hasPermission("sf_personal_details_controller_update_personal_details")) {
            this.setState({ hasEditPermission: true });
        }
    }

    onSubmit = async (formData) => {
        let accessControl = JSON.parse(getStorage(ACCESS_CONTROL))["userManagement"].permissions;
        if (accessControl.findIndex(acess => acess.control_name === 'sf_personal_details_controller_update_personal_details') === -1 && this.props.location.pathname !== "/dashboard/profile") {
            notification.error({
                message: Strings.error_title,
                description: "You don't have permissions to edit Emergency Contact Details.",
                className: 'ant-error'
            })
        } else {
            formData = await DeepTrim(formData);
            formData = {
                ...formData,
                'profile_progress': this.props.profileComplete,
                ec_state: formData.ec_state && formData.ec_state.value ? formData.ec_state.value : null,
                ec_country: formData.ec_country && formData.ec_country.value ? formData.ec_country.value : null
            }

            let staffUserName = false
            if (this.props.userDetails) {
                staffUserName = this.props.userDetails.user_name
            }

            this.props.action.updateOrgUserEmergencyContact(formData, staffUserName)
                .then((flag) => {
                    notification.success({
                        message: Strings.success_title,
                        description: flag,
                        onClick: () => { },
                        className: 'ant-success'
                    });
                    this.props.action.getOrgUserProfileDetails(staffUserName ? this.props.userDetails.user_id : this.org_user_id, staffUserName ? this.props.userDetails.user_name : this.org_user_name)
                        .then(() => {
                            if (staffUserName) {
                                return
                            } else {
                                setStorage(ADMIN_DETAILS, JSON.stringify({
                                    ...JSON.parse(getStorage(ADMIN_DETAILS)),
                                    name: this.props && this.props.profile && this.props.profile[0] && this.props.profile[0].name ?
                                        this.props.profile[0].name : JSON.parse(getStorage(ADMIN_DETAILS)).name,
                                    last_name: this.props && this.props.profile && this.props.profile[0] && this.props.profile[0].last_name ?
                                        this.props.profile[0].last_name : JSON.parse(getStorage(ADMIN_DETAILS)).last_name
                                }))
                            }
                        });
                })
                .catch((error) => {
                    if (error.status === VALIDATE_STATUS) {
                        notification.warning({
                            message: Strings.validate_title,
                            description: error && error.data && typeof error.data.message == 'string'
                                ? error.data.message : Strings.generic_validate,
                            onClick: () => { },
                            className: 'ant-warning'
                        });
                    } else {
                        notification.error({
                            message: Strings.error_title,
                            description: error && error.data && error.data.message && typeof error.data.message == 'string'
                                ? error.data.message : Strings.generic_error,
                            onClick: () => { },
                            className: 'ant-error'
                        });
                    }
                });
        }
    }

    hasPermission = (permission) => {
        let allowed = false;
        let userPermissions = JSON.parse(getStorage(ACCESS_CONTROL))["userManagement"]
        let accessControl = userPermissions && userPermissions.permissions ? userPermissions.permissions : [];
        let permission_id = accessControl.findIndex(acess => acess.control_name.toLowerCase() === permission.toLowerCase());
        if (permission_id !== -1 || this.props.location.pathname === "/dashboard/profile") {
            allowed = true;
        }

        return allowed;
    }

    render() {
        const { handleSubmit, initialValues, statesList } = this.props;
        const editMenu = (
            <Menu>
                <Menu.Item></Menu.Item>
            </Menu>
        )

        return (
            <form onSubmit={handleSubmit(this.onSubmit)}>
                <hr className={styles.DetailsDivider}></hr>
                <div className="container-fluid pt-3">
                    <div className="row align-items-center">
                        <h2 className={`${styles.ProfileLabel} font-weight-bold`}>Emergency Contact Details</h2>
                    </div>
                    <div className="row">
                        <div className="col-3 border-right">
                            <div className={styles.ProfileLabelV2}>Full Name</div>
                            <div>
                                <Field
                                    name="ec_full_name"
                                    placeholder={Strings.ec_full_name_acc_pd}
                                    type="text"
                                    id="ec_full_name"
                                    component={customInput}
                                    className={styles.ProfileFormField}
                                    disabled={!this.state.hasEditPermission} />
                            </div>
                        </div>
                        <div className="col-3 border-right">
                            <div className={styles.ProfileLabelV2}>Relationship</div>
                            <div>
                                <Field
                                    name="relationship"
                                    placeholder={Strings.relationship_acc_pd}
                                    type="text"
                                    id="relationship"
                                    component={customInput}
                                    className={styles.ProfileFormField}
                                    disabled={!this.state.hasEditPermission} />
                            </div>
                        </div>
                        <div className="col-3 border-right">
                            <div className={styles.ProfileLabelV2}>Mobile Number</div>
                            <div>
                                <Field
                                    name="ec_mobile_number"
                                    type="text"
                                    id="ec_mobile_number"
                                    component={customInput}
                                    className={styles.ProfileFormField}
                                    disabled={!this.state.hasEditPermission} />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className={styles.ProfileLabelV2}>Email</div>
                            <div>
                                <Field
                                    name="ec_email"
                                    placeholder={Strings.ec_email_acc_pd}
                                    type="text"
                                    id="ec_email"
                                    component={customInput}
                                    className={styles.ProfileFormField}
                                    disabled={!this.state.hasEditPermission} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3 border-right">
                            <div className={styles.ProfileLabelV2}>Street Address</div>
                            <div>
                                <Field
                                    name="ec_street_address"
                                    placeholder={Strings.street_address_acc_pd}
                                    type="text"
                                    id="ec_street_address"
                                    component={customInput}
                                    className={styles.ProfileFormField}
                                    disabled={!this.state.hasEditPermission} />
                            </div>
                        </div>
                        <div className="col-3 border-right">
                            <div className={styles.ProfileLabelV2}>City</div>
                            <div>
                                <Field
                                    name="ec_city"
                                    placeholder={Strings.city_acc_pd}
                                    type="text"
                                    id="ec_city"
                                    component={customInput}
                                    className={styles.ProfileFormField}
                                    disabled={!this.state.hasEditPermission} />
                            </div>
                        </div>
                        <div className="col-3 border-right">
                            <div className={styles.ProfileLabelV2}>State</div>
                            <div>
                                <Field
                                    name="ec_state"
                                    placeholder={Strings.state_acc_pd}
                                    options={statesList.map(state => ({ label: state.name, value: state.id.toString(), isDisabled: !this.state.hasEditPermission }))}
                                    component={CustomSingleSelect}
                                    styles={colourStyles}
                                    otherProps={{
                                        components: { dropdownIndicator: <components.DownChevron /> },
                                        menuPortalTarget: document.body
                                    }}
                                    disabled={!this.state.hasEditPermission} />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className={styles.ProfileLabelV2}>Zip Code</div>
                            <div>
                                <Field
                                    name="ec_zip_code"
                                    type="text"
                                    id="ec_zip_code"
                                    component={customInput}
                                    className={styles.ProfileFormField}
                                    disabled={!this.state.hasEditPermission} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3 border-right">
                            <div className={styles.ProfileLabelV2}>Country</div>
                            <div>
                                <Field
                                    name="ec_country"
                                    type="text"
                                    id="ec_country"
                                    options={countries.map(country => ({ label: country, value: country.toString(), isDisabled: !this.state.hasEditPermission }))}
                                    component={CustomSingleSelect}
                                    styles={colourStyles}
                                    otherProps={{
                                        components: { dropdownIndicator: <components.DownChevron /> },
                                        menuPortalTarget: document.body
                                    }}
                                    disabled={!this.state.hasEditPermission} />
                            </div>
                        </div>
                    </div>
                    <div className="mt-2">
                        <button type="submit" className={styles.BtnSaveTab} disabled={!this.props.isDirty || !this.hasPermission('sf_personal_details_controller_update_personal_details')}>
                            Save
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    let value = {}
    if (state.profileManagement && state.profileManagement.profile) {
        for (let item of state.profileManagement.profile) {
            let selectedState = item.ec_state && item.ec_state !== "null" ? state.profileManagement.statesList.find(x => x.id.toString() === item.ec_state.toString()) : null
            value = {
                ...value, id: item.id, ec_full_name: item.ec_full_name, relationship: item.relationship, ec_mobile_number: item.ec_mobile_number, ec_email: item.ec_email,
                ec_street_address: item.ec_street_address, ec_city: item.ec_city, ec_zip_code: item.ec_zip_code,
                ec_country: item.ec_country && item.ec_country !== "null" ? { label: item.ec_country, value: item.ec_country } : null,
                ec_state: selectedState ? { label: selectedState.name, value: selectedState.id } : null
            }
        }
    }

    for (let key in value) {
        if (value[key] === "null") {
            value[key] = null;
        }
    }

    return {
        formValues: state.form && state.form.EmergencyContactForm &&
            state.form.EmergencyContactForm.values ? state.form.EmergencyContactForm.values : {},
        isDirty: isDirty('EmergencyContactForm')(state),
        profileComplete: state.profileManagement && state.profileManagement.profileComplete,
        profile: state.profileManagement && state.profileManagement.profile,
        initialValues: value ? value : {},
        statesList: state.profileManagement.statesList,
    }
}

const mapDispatchToprops = dispatch => {
    return {
        action: bindActionCreators(actions, dispatch),
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToprops),
    reduxForm({
        form: 'EmergencyContactForm', validate: emergencyContactValidation, enableReinitialize: true,
        onSubmitFail: (errors, dispatch, sub, props) => {
            handleFocus(errors, "#");
        }
    })
)(EmergencyContactForm)