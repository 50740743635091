import React from "react";
import { reduxForm, Field } from "redux-form";
import { CustomDatepicker } from "../../../common/customDatepicker";
import { customInput } from "../../../common/custom-input";
import { customTextarea } from "../../../common/customTextarea";
import styles from "./SignOffStyles.module.css";
import jobStyles from "../JobStyle.module.css";
import { validateSignOff } from "./validate";

const ClientSignDetailsForm = (props) => {
  return <div className="row justify-content-center border">
    <div className="col-lg-12 col-xl-6 px-0">
      <div className="container-fluid">
        <div className="row pt-3 pb-2">
          <div className="col">
            <div className={`${styles.BlueText}`}>Name</div>
            <Field
              name="person_name"
              autoComplete="off"
              className={jobStyles.SingleLine}
              component={customInput}
            />
          </div>
        </div>
        <div className="row pt-2 pb-3">
          <div className="col-6">
            <div className={`${styles.BlueText}`}>Role</div>
            <Field
              name="person_role"
              autoComplete="off"
              className={jobStyles.SingleLine}
              component={customInput}
            />
          </div>
          <div className="col-6">
            <div className={`${styles.BlueText}`}>Date</div>
            <Field name="job_sign_off_date"
              className="custom-single-datepicker"
              component={CustomDatepicker}
              placeholder=""
            />
          </div>
        </div>
        <div className="row pt-1 pb-2">
          <div className="col-12">
            <div className={`${styles.BlueText} pb-1`}>Feedback</div>
            <Field component={customTextarea} className={styles.textArea} rows="4" name="feedback" />
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-12 col-xl-6 px-0 pb-2 border-left">
      {props.children}
    </div>
  </div>
}


export default reduxForm({
  form: 'clientSignDetailsForm',
  validate: validateSignOff
})(ClientSignDetailsForm);