import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CoreCalendar from "./CoreCalendar/CoreCalendar";
import * as AllocationActions from "../../../../../actions/v2/AllocationActions";
import moment from "moment";
import { notification } from "antd";
import { Strings } from "../../../../../dataProvider/localize";
import styles from "./AllocationsCalendarContainer.module.css";
import { legends } from "../../JobBoard/legendsConfig";

export class AllocationsCalendarContainer extends Component {
  state = {
    view: "week",
  };

  componentDidMount() {
    this.props.actions
      .getShiftAllocations(
        this.props.selectedJobDetails.id,
        this.props.selectedJobDetails
      )
      .then(() => {})
      .catch(() => {});
  }

  handleEventSelection = (event) => {};

  handleSlotSelect = (event) => {
    const { actions, selectedJobDetails } = this.props;
    const today = moment(new Date());
    const isPastTime =
      moment(new Date()).format("YYYY-MM-DD") ===
      moment(event.start).format("YYYY-MM-DD");
    const isBefore = moment(event.start).isBefore(today);
    if (isBefore) {
      return notification.warning({
        message: Strings.warning_title,
        description: `Cannot create shift for a past ${
          isPastTime ? "time" : "date"
        }`,
        onClick: () => {},
        className: "ant-warning",
      });
    }
    this.state.view !== "month" &&
      actions.startAction("CREATE", selectedJobDetails, event, event);
  };

  render() {
    return (
      <div className="sf-page-layout">
        <div className="sf-job-calendar">
          <div className="row">
            <div className="col-md-9">
              <div className="sf-card sf-shadow">
                <div className={styles.FiltersLegendWrapper}>
                  {legends.filter(legend => legend.key !== 'paused').map((legend) => (
                    <div
                      style={{
                        background: `${legend.color}`,
                        color: "white",
                        borderRadius: "5px",
                        margin: "0 20px 5px",
                        padding: "4px",
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                        minWidth: "5rem",
                        textAlign: "center",
                      }}
                    >
                      {legend.label}
                    </div>
                  ))}
                </div>
                <div className="job-calendar-view job-calendar-view-expand allocations-shifts-calendar">
                  <CoreCalendar
                    view={this.state.view}
                    onCalendarView={(view) => this.setState({ view })}
                    onChangeView={(view) => this.setState({ view })}
                    events={this.props.allocationShifts}
                    onEventSelection={(event) =>
                      this.handleEventSelection(event)
                    }
                    tooltipAccessor='monthTitle'
                    handleSlotSelect={this.handleSlotSelect}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allocationShifts: state.Allocations.shifts,
  selectedJobDetails: state.JobCalendar.selectedJobDetails,
  selectedShiftDetails: state.Allocations.selectedShiftDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(AllocationActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllocationsCalendarContainer);
