import moment from "moment";
import axiosInstance, { scopeV2AxiosInstance, scopeV3AxiosInstance, scopeAxiosInstance, axiosV2Instance, baseApiInstance, scopeApiInstance } from "../../dataProvider/axiosHelper";
import {
  SEARCH_SITE_URL,
  QUOTES_GET_URL,
  SET_QUOTES,
  SEARCH_CLIENTS_URL,
  QUOTE_FILTER_ITEMS_URL,
  SET_QUOTE_STATIC_FILTERS,
  SEARCH_QUOTE_CREATORS_URL,
  SEARCH_QUOTE_CONTACT_NAMES_URL,
  QUOTE_PRIORITIES_URL,
  SET_QUOTE_PRIORITY_OPTIONS,
  GET_QUOTES_SITE_HISTORY_URL,
  SET_SITE_HISTORY,
  GET_AREAS_BY_SEARCH_URL,
  GET_TASKS_BY_SEARCH_URL,
  GET_QUOTE_SWMS_URL,
  SET_QUOTES_SWMS,
  GET_QUOTE_TOOLBOX_URL,
  SET_QUOTES_TOOLBOX_TALK_SWMS,
  GET_QUOTE_DETAILS_URL,
  SET_QUOTE_DETAILS,
  GET_CLIENT_ADDITIONAL_DETAILS_URL,
  SET_QUOTE_CLIENT_ADDITIONAL_DETAILS,
  SET_QUOTE_SITE_ADDITIONAL_DETAILS,
  GET_SITE_ADDITIONAL_DETAILS_URL,
  QUOTES_ADD_UPDATE_URL,
  RESET_QUOTE_DETAILS,
  QUOTES_GET_FREQUENCY_COUNT_URL,
  QUOTES_TASK_URL,
  QUOTES_HISTORY_URL,
  SET_QUOTE_HISTORY,
  QUOTES_PREVIEW_URL,
  QUOTE_MANAGEMENT_URL,
  SET_QUOTE_MANAGEMENT,
  QUOTE_ACTIONS_URL,
  SET_QUOTE_ACTIONS,
  QUOTE_ADMINS_URL,
  SET_QUOTE_ADMINS,
  QUOTE_SEND_ADMIN_APPROVAL_URL,
  QUOTE_ADMIN_RESPOND_URL,
  QUOTE_TASK_FREQUENCIES_URL,
  QUOTE_SEND_CLIENT_APPROVAL_URL,
  GET_EMAIL_CONTENT_URL,
  SLUG_QUOTE_TO_CLIENT,
  TASK_SWMS_URL,
  QUOTE_CLIENT_SEND_EMAIL_URL,
  UPDATE_TASKS_DATA,
  QUOTE_TASK_FILE_URL,
  QUOTE_TO_CLIENT_NO_EMAIL,
  QUOTE_TASK_TYPE_URL,
  QUOTE_ALL_TASK_TYPES_URL,
  SET_QUOTE_TASK_TYPES,
  SUB_TASK_URL,
  SITE_AREA_CHILDREN_AREAS_URL,
  GET_QUOTE_TASKS_LIST,
  GET_QUOTE_PDF_DOWNLOAD,
  QUOTE_TASK_SEND_CLIENT_APPROVAL_URL
} from "../../dataProvider/constant";
import { Strings } from "../../dataProvider/localize";

const serializeFilters = (filters) => {
  let args = "";
  let params = {
    ...(filters.quote_number && filters.quote_number.trim().length > 0 ?
      { quote_number: filters.quote_number } : {}),

    ...(filters.client_id && filters.client_id.length > 0 ?
      { client_id: filters.client_id.map(client => client.value) } : {}),

    ...(filters.site_id && filters.site_id.length > 0 ?
      { site_id: filters.site_id.map(site => site.value) } : {}),

    ...(filters.contact_person_id && filters.contact_person_id.length > 0 ?
      { contact_person_id: filters.contact_person_id.map(contact => contact.value) } : {}),

    ...(filters.priority ?
      { priority: filters.priority.value } : {}),

    ...(filters.quote_status && filters.quote_status.length > 0 ?
      { quote_status: filters.quote_status.map(status => status.value) } : {}),

    ...(filters.created_by && filters.created_by.length > 0 ?
      { created_by: filters.created_by.map(creator => creator.value) } : {}),

    ...(filters.request_date ? { request_date: JSON.stringify([moment(filters.request_date[0]).format("YYYY-MM-DD"), moment(filters.request_date[1]).format("YYYY-MM-DD")]) } : {}),
    ...(filters.quote_date ? { quote_date: JSON.stringify([moment(filters.quote_date[0]).format("YYYY-MM-DD"), moment(filters.quote_date[1]).format("YYYY-MM-DD")]) } : {})
  }
  if (Object.keys(params).length > 0) {
    Object.keys(params).forEach(key => {
      args = `${args}&${key}=${encodeURIComponent(params[key])}`;
    })
  }
  return args;
}

export const getQuotes = (page = 1, filters = {}) => dispatch => {

  let params = serializeFilters(filters);
  let url = QUOTES_GET_URL;
  url = `${url}?page=${page}`;
  if (params && params.length > 0) {
    url += params;
  }

  return scopeV3AxiosInstance.get(url).then((res) => {
    let data = res.data.data;
    if (data) {
      dispatch({
        type: SET_QUOTES,
        payload: data
      })
      return Promise.resolve(data);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getSitesBySearch = () => dispatch => {
  let url = SEARCH_SITE_URL;
  return scopeV2AxiosInstance.get(url).then((res) => {
    let data = res.data.data;
    if (data) {
      return Promise.resolve(data);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getClientsBySearch = () => dispatch => {
  let url = SEARCH_CLIENTS_URL;
  return scopeV3AxiosInstance.get(url).then((res) => {
    let data = res.data.data;
    if (data) {
      return Promise.resolve(data);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getFilterItems = () => dispatch => {
  let url = QUOTE_FILTER_ITEMS_URL;
  return scopeV3AxiosInstance.get(url).then((res) => {
    let data = res.data.data;
    if (data) {
      dispatch({
        type: SET_QUOTE_STATIC_FILTERS,
        payload: data
      })
      return Promise.resolve(data);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getCreatorsBySearch = () => dispatch => {
  let url = SEARCH_QUOTE_CREATORS_URL;
  return scopeV3AxiosInstance.get(url).then((res) => {
    let data = res.data.data;
    if (data) {
      return Promise.resolve(data);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getContactNamesBySearch = (clients = []) => dispatch => {
  let url = SEARCH_QUOTE_CONTACT_NAMES_URL;
  url = `${url}?${clients && clients.length > 0 ? "client_id=" + clients.map(client => client.value).join(",") : ""}`;
  return scopeV3AxiosInstance.get(url).then((res) => {
    let data = res.data.data;
    if (data) {
      return Promise.resolve(data);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getQuotePrioirties = () => dispatch => {
  let url = QUOTE_PRIORITIES_URL;
  return scopeV3AxiosInstance.get(url).then((res) => {
    let data = res.data.data;
    if (data) {
      dispatch({
        type: SET_QUOTE_PRIORITY_OPTIONS,
        payload: data
      })
      return Promise.resolve(data);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

const serializeSiteHistoryFilters = (filters) => {
  let args = "";
  let params = {
    ...(filters.task_ids && filters.task_ids.length > 0 ?
      { task_ids: filters.task_ids.map(task => task.value) } : {}),
    ...(filters.area_ids && filters.area_ids.length > 0 ?
      { area_ids: filters.area_ids.map(area => area.value) } : {}),
    ...(filters.task_amount_to) ?
      { task_amount_to: filters.task_amount_to } : {},
    task_amount_from: filters.task_amount_from ? filters.task_amount_from : 0
  }
  if (Object.keys(params).length > 0) {
    Object.keys(params).forEach(key => {
      if (key !== "task_amount_to" && key !== "task_amount_from") {
        args = `${args}&${key}=[${encodeURIComponent(params[key])}]`;
      } else {
        args = `${args}&${key}=${encodeURIComponent(params[key])}`;
      }
    })
  }
  return args;
}

export const getSiteHistory = (siteId, page = 1, filters = {}, excludeIds = []) => dispatch => {
  let url = GET_QUOTES_SITE_HISTORY_URL;
  url = `${url}?site_id=${siteId}&page=${page}`;
  if (excludeIds && excludeIds.length > 0) {
    url += `&excluded_task_ids=${JSON.stringify(excludeIds)}`
  }
  let params = serializeSiteHistoryFilters(filters);
  if (params && params.length > 0) {
    url += params;
  }
  return scopeV3AxiosInstance.get(url).then((res) => {
    let data = res.data.data;
    if (data) {
      dispatch({
        type: SET_SITE_HISTORY,
        payload: data
      })
      return Promise.resolve(data);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getAreasBySearch = (search = '') => dispatch => {
  let url = GET_AREAS_BY_SEARCH_URL;
  url = `${url}?search_key=${search}`
  return scopeV3AxiosInstance.get(url).then((res) => {
    let data = res.data.data;
    if (data && data.areas) {
      return Promise.resolve(data.areas);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getTasksBySearch = (search = '', siteId) => dispatch => {
  let url = GET_TASKS_BY_SEARCH_URL;
  url = `${url}?search_key=${search}`
  if (siteId) {
    url += `&site_id=${siteId}`
  }
  return scopeV3AxiosInstance.get(url).then((res) => {
    let data = res.data.data;
    if (data && data.tasks) {
      return Promise.resolve(data.tasks);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getQuotesSwms = () => dispatch => {
  let url = GET_QUOTE_SWMS_URL;
  return scopeAxiosInstance.get(url).then((res) => {
    let data = res.data.data;
    if (data && data.area_swms) {
      dispatch({
        type: SET_QUOTES_SWMS,
        payload: data.area_swms
      })
      return Promise.resolve(data.area_swms);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getTaskSwms = (taskIds = "[]", quote_id = "") => dispatch => {
  let url = TASK_SWMS_URL;
  url += `?quote_id=${quote_id}&task_id=${taskIds}`;
  return scopeV3AxiosInstance.get(url).then((res) => {
    let data = res.data.data;
    if (data) {
      return Promise.resolve(data && data.length > 0 ? data[0] : {});
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const updateTaskSwms = (formData) => dispatch => {
  let url = TASK_SWMS_URL;
  return scopeV3AxiosInstance.put(url, formData).then(res => {
    let data = res.data;
    if (data) {
      return Promise.resolve(data.message)
    }
    else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getQuotesToolBox = () => dispatch => {
  let url = GET_QUOTE_TOOLBOX_URL;
  return scopeAxiosInstance.get(url).then((res) => {
    let data = res.data.data;
    if (data && data.toolbox_talk) {
      dispatch({
        type: SET_QUOTES_TOOLBOX_TALK_SWMS,
        payload: data.toolbox_talk
      })
      return Promise.resolve(data.toolbox_talk);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getQuoteDetails = (quoteId, store = true) => dispatch => {
  let url = `${GET_QUOTE_DETAILS_URL}?id=${quoteId}`;
  return scopeV3AxiosInstance.get(url).then(res => {
    let data = res.data.data;
    if (data) {
      if (store) {
        dispatch({
          type: SET_QUOTE_DETAILS,
          payload: data
        })
      }
      return Promise.resolve(data);
    } else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getClientAdditionalInfo = (clientId = null) => dispatch => {
  let url = GET_CLIENT_ADDITIONAL_DETAILS_URL;
  if (clientId) {
    url = `${url}?client_id=${clientId}`
  }
  return scopeV3AxiosInstance.get(url).then(res => {
    let data = res.data.data;
    if (data) {
      dispatch({
        type: SET_QUOTE_CLIENT_ADDITIONAL_DETAILS,
        payload: data
      })
      return Promise.resolve(data);
    } else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getSiteAdditionalInfo = (siteId = null) => dispatch => {
  let url = GET_SITE_ADDITIONAL_DETAILS_URL;
  if (siteId) {
    url = `${url}?site_id=${siteId}`
  }
  return scopeV3AxiosInstance.get(url).then(res => {
    let data = res.data.data;
    if (data) {
      dispatch({
        type: SET_QUOTE_SITE_ADDITIONAL_DETAILS,
        payload: data
      })
      return Promise.resolve(data);
    } else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const addUpdateQuote = (formData) => dispatch => {
  let url = QUOTES_ADD_UPDATE_URL;
  return scopeV3AxiosInstance.put(url, formData).then(res => {
    if (res.data && res.data.status) {
      return Promise.resolve(res.data && res.data);
    } else {
      return Promise.reject(Strings.generic_error)
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data ? error.response.data : Strings.network_error
      );
    })
}

export const resetQuoteDetails = () => dispatch => {
  dispatch({
    type: RESET_QUOTE_DETAILS,
    payload: null
  })
}

export const getTaskFrequencyCount = (formData, abortController = {}) => dispatch => {
  let url = QUOTES_GET_FREQUENCY_COUNT_URL;
  return scopeApiInstance.put(url, formData, { ...abortController }).then(res => {
    let data = res && res.data && res.data.data ? res.data.data : null;
    if (data) {
      return Promise.resolve(data);
    } else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getQuotesPreview = (id, tasks = null) => dispatch => {
  let url = QUOTES_PREVIEW_URL;
  url = `${url}?id=${id}`;
  if (tasks) {
    url = `${url}&selected_tasks=${tasks}`;
  }
  return scopeV3AxiosInstance.get(url).then((res) => {
    let data = res.data.data;
    if (data) {
      return Promise.resolve(data);
    } else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const addUpdateQuoteTask = (formData) => dispatch => {
  let url = QUOTES_TASK_URL;
  return scopeV3AxiosInstance.post(url, formData).then(res => {
    let data = res.data;
    if (data) {
      return Promise.resolve(data.message)
    }
    else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const updateTasksForQuoteSelection = (formData, sendEmail = true) => dispatch => {
  let url = QUOTES_TASK_URL;
  let method = scopeV3AxiosInstance.put
  if (!sendEmail) {
    url = QUOTE_TO_CLIENT_NO_EMAIL
    method = scopeV3AxiosInstance.post
  }
  return method(url, formData).then(res => {
    let data = res.data;
    if (data) {
      return Promise.resolve(data.message)
    }
    else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getQuoteHistory = (quote_id, page = 1) => dispatch => {
  let url = QUOTES_HISTORY_URL;
  url += `?quote_id=${quote_id}&page=${page}`;
  return scopeV3AxiosInstance.get(url).then(res => {
    let data = res.data.data;
    if (data && data.quote_histories) {
      dispatch({
        type: SET_QUOTE_HISTORY,
        payload: data.quote_histories
      })
      return Promise.resolve(data)
    }
    else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getQuoteMangements = (quote_id, page = 1) => dispatch => {
  let url = QUOTE_MANAGEMENT_URL;
  url += `?quote_id=${quote_id}&page=${page}`;
  return scopeV3AxiosInstance.get(url).then(res => {
    let data = res.data.data;
    if (data) {
      dispatch({
        type: SET_QUOTE_MANAGEMENT,
        payload: data
      })
      return Promise.resolve(data)
    }
    else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getQuoteActions = () => dispatch => {
  let url = QUOTE_ACTIONS_URL;
  return scopeApiInstance.get(url).then(res => {
    let data = res.data.data;
    if (data) {
      dispatch({
        type: SET_QUOTE_ACTIONS,
        payload: data
      })
      return Promise.resolve(data)
    }
    else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const addQuoteAction = (formData) => dispatch => {
  let url = QUOTE_MANAGEMENT_URL;
  return scopeV3AxiosInstance.post(url, formData).then(res => {
    let data = res.data;
    if (data) {
      return Promise.resolve(data.message)
    }
    else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const updateQuoteAction = (formData) => dispatch => {
  let url = QUOTE_MANAGEMENT_URL;
  return scopeV3AxiosInstance.put(url, formData).then(res => {
    let data = res.data;
    if (data) {
      return Promise.resolve(data.message)
    }
    else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getQuoteAdmins = (org_id = "") => dispatch => {
  let url = QUOTE_ADMINS_URL;
  url += `?organisation_id=${org_id}`;
  return axiosV2Instance.get(url).then(res => {
    let data = res.data.data;
    if (data && data.admins) {
      dispatch({
        type: SET_QUOTE_ADMINS,
        payload: data.admins
      })
      return Promise.resolve(data.admins)
    }
    else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const sendQuoteForAdminApproval = (formData) => dispatch => {
  let url = QUOTE_SEND_ADMIN_APPROVAL_URL;
  return scopeV3AxiosInstance.post(url, formData).then(res => {
    let data = res.data;
    if (data) {
      return Promise.resolve(data.message)
    }
    else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getQuoteTasks = (quote_id = "") => dispatch => {
  let url = QUOTES_TASK_URL;
  url += `?quote_id=${quote_id}`;
  return scopeV3AxiosInstance.get(url).then(res => {
    let data = res.data;
    if (data && data.data) {
      return Promise.resolve(data.data)
    }
    else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const adminAcceptRejectQuote = (formData) => dispatch => {
  let url = QUOTE_ADMIN_RESPOND_URL;
  return scopeV3AxiosInstance.post(url, formData).then(res => {
    let data = res.data;
    if (data) {
      return Promise.resolve(data.message)
    }
    else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const clientAcceptRejectQuote = (formData) => dispatch => {
  let url = QUOTE_SEND_CLIENT_APPROVAL_URL;
  return scopeV3AxiosInstance.post(url, formData).then(res => {
    let data = res.data;
    if (data) {
      return Promise.resolve(data.message)
    }
    else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getQuoteTaskFrequencies = (quoteId = "") => dispatch => {
  let url = QUOTE_TASK_FREQUENCIES_URL;
  url += `?quote_id=${quoteId}`;
  return scopeV3AxiosInstance.get(url).then(res => {
    let data = res.data;
    if (data && data.data) {
      return Promise.resolve(data.data)
    }
    else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getQuoteEmailContent = () => dispatch => {
  let url = GET_EMAIL_CONTENT_URL;
  url += `?slug=${SLUG_QUOTE_TO_CLIENT}`;
  return axiosInstance.get(url).then(res => {
    let data = res.data;
    if (data && data.data) {
      return Promise.resolve(data.data)
    }
    else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const sendQuoteEmail = (formData) => disptach => {
  let url = QUOTE_CLIENT_SEND_EMAIL_URL;
  return scopeV3AxiosInstance.post(url, formData).then(res => {
    let data = res.data;
    if (data) {
      return Promise.resolve(data.message)
    }
    else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getTaskDetails = (task_id = "") => dispatch => {
  let url = QUOTES_TASK_URL;
  url += `?task_id=${task_id}`;
  return scopeV3AxiosInstance.get(url).then(res => {
    let data = res.data;
    if (data) {
      dispatch({
        type: UPDATE_TASKS_DATA,
        payload: data.data
      })
      return Promise.resolve(data)
    }
    else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const deleteTaskFile = (fileId) => dispatch => {
  let url = QUOTE_TASK_FILE_URL;
  return scopeV3AxiosInstance.delete(url, { data: { id: fileId } }).then((res) => {
    if (res.data.status) {
      return Promise.resolve(res.data.message && res.data.message);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const deleteTask = (taskId, quoteId) => dispatch => {
  let url = QUOTES_TASK_URL;
  return scopeV3AxiosInstance.delete(url, { data: { id: taskId, quote_id: quoteId, quote_type: "QUOTE" } }).then((res) => {
    if (res.data.status) {
      return Promise.resolve(res.data.message && res.data.message);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const addUpdateTaskFrequencies = (formData) => dispatch => {
  let url = QUOTE_TASK_FREQUENCIES_URL;
  return scopeV3AxiosInstance.put(url, formData).then(res => {
    let data = res.data;
    if (data) {
      return Promise.resolve(data.message)
    }
    else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getQuoteTaskTypes = (search = "") => dispatch => {
  let url = QUOTE_TASK_TYPE_URL;
  if (search.trim() !== "") {
    url = `${url}?search=${search.trim()}`;
  }
  return baseApiInstance.get(url).then((res) => {
    let data = res.data.data;
    if (data) {
      return Promise.resolve(data);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getAllQuoteTaskTypes = () => dispatch => {
  return scopeApiInstance.get(QUOTE_ALL_TASK_TYPES_URL).then(res => {
    let data = res.data.data;
    if (data) {
      dispatch({
        type: SET_QUOTE_TASK_TYPES,
        payload: data
      });
      return Promise.resolve(data);
    } else {
      return Promise.reject();
    }
  }).catch((error) => {
    return Promise.reject(
      error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })

}


export const createEditSubtask = (formData = {}, mode = "Add") => () => {
  const method = mode === "Edit" ? scopeApiInstance.put : mode === "Delete" ? scopeApiInstance.delete : scopeApiInstance.post;
  return method(SUB_TASK_URL, { ...formData }).then(res => {
    if (res.data.status) {
      return Promise.resolve(res.data.message);
    }
  })
    .catch(error => {
      if (error) {
        return Promise.reject(
          error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
        );
      } else {
        return Promise.reject(null);
      }
    })
}

export const deleteSubTasks = (params) => () => {
  return scopeApiInstance.delete(SUB_TASK_URL, { params: params }).then(res => {
    if (res.data.status) {
      return Promise.resolve(res.data.message);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error);
    } else {
      return Promise.reject(null);
    }
  })
}

export const getSubTasks = (params = {}, abortController) => () => {
  return scopeApiInstance.get(
    SUB_TASK_URL,
    {
      params: { ...params },
      ...abortController
    }
  )
    .then((res) => {
      if (res && res.data && res.data.data) {
        return Promise.resolve(res.data.data);
      } else {
        return Promise.reject(Strings.generic_error);
      }
    }).catch(error => {
      if (error) {
        return Promise.reject(
          error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
        );
      } else {
        return Promise.reject(null);
      }
    })
}

export const getSiteAreaChildren = (params = {}, abortController) => () => {
  return scopeApiInstance.get(SITE_AREA_CHILDREN_AREAS_URL, {
    params: { ...params },
    ...abortController
  }).then((res) => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const getQuoteTasksList = (params = {}, abortController) => () => {
  return scopeApiInstance.get(GET_QUOTE_TASKS_LIST, {
    params: { ...params },
    ...abortController
  }).then((res) => {
    if (res && res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const getQuotePdfDownload = (params = {}, abortController) => () => {
  return scopeApiInstance.get(GET_QUOTE_PDF_DOWNLOAD, {
    params: { ...params },
    ...abortController
  }).then((res) => {
    if (res && res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const clientApprovalQuote = (formData) => dispatch => {
  let url = QUOTE_TASK_SEND_CLIENT_APPROVAL_URL;
  return scopeApiInstance.post(url, formData).then(res => {
    let data = res.data;
    if (data) {
      return Promise.resolve(data.message)
    }
    else {
      return Promise.reject()
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}