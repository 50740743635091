import {
    REPORT_MANAGEMENT_CREATE_TOOLBOX_TALK,
    REPORT_MANAGEMENT_CREATE_TOOLBOX_TALK_URL,
    REPORT_MANAGEMENT_CREATE_HAZARD_URL,
    REPORT_MANAGEMENT_GET_TOOLBOX_TALK,
    REPORT_MANAGEMENT_GET_TOOLBOX_TALK_URL,
    GET_CLIENT_LIST,
    GET_TYPE_LIST,
    GET_LICENCE_LIST,
    GET_TOOLBOX_TALK_BY_ID

} from '../dataProvider/constant';
import { startSipnner, stopSipnner, stopMiniSpinner, startMiniSpinner, stopScrollSpinner, startScrollSpinner } from '../utils/spinner';
import { scopeAxiosInstance,axiosInstance } from '../dataProvider/axiosHelper'
import { Strings } from '../dataProvider/localize'
import { getStorage } from '../utils/common';

export const getReportManagementToolboxTalk = (params) => dispatch => {
  console.log("llllllllllllllllll=======>>>",params);
    startSipnner(dispatch);
    let get
    return scopeAxiosInstance.get(REPORT_MANAGEMENT_GET_TOOLBOX_TALK_URL+"?filter="+JSON.stringify(params))
      .then(res => {
        stopSipnner(dispatch);
        if (res.data.status) {
          dispatch({
            type: REPORT_MANAGEMENT_GET_TOOLBOX_TALK,
            reportManagementToolboxList: res.data.data,
          })
          return Promise.resolve(res.data.message && res.data.message)
        }
      })
      .catch(error => {
        stopSipnner(dispatch)
        return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
      })
  }

  export const getClientList = () => dispatch => {
    startSipnner(dispatch);
    return scopeAxiosInstance.get('/client-filter-items')
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {
          dispatch({
            type: GET_CLIENT_LIST,
            clientListData: res.data.data,
          })
          return Promise.resolve(res.data.message && res.data.message)
        }
      })
      .catch(error => {
        stopSipnner(dispatch)
        return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
      })
  }

  export const getTypeList = () => dispatch => {
    startSipnner(dispatch);
    return scopeAxiosInstance.get('/org-general-master?master_type=2')
    .then(res => {
      stopSipnner(dispatch);
      if (res.data.status) {

          dispatch({
            type: GET_TYPE_LIST,
            typeListData: res.data,
          })
          return Promise.resolve(res.data.message && res.data.message)
        }
      })
      .catch(error => {
        stopSipnner(dispatch)
        return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
      })
  }
  

  export const deleteToolBoxTalk = (id) => dispatch => {
    startSipnner(dispatch);
    return scopeAxiosInstance.delete('/report-toolbox-talk?id='+id)
      .then(res => {
        stopSipnner(dispatch);
        if (res.data.status) {
          return Promise.resolve(res.data.message)
        }
      })
      .catch((error) => {
        stopSipnner(dispatch);
        return Promise.reject(error.response ?
          error.response.data.message
          : Strings.network_error)
      });
  }

  export const getLicenceList = () => dispatch => {
    startSipnner(dispatch);
    return axiosInstance.get('/org-license-types')
    .then(res => {
      stopSipnner(dispatch);
      console.log(";;;;;;;^^^^^^^;;;;;;",res.data);
      if (res.data.status) {
          dispatch({
            type: GET_LICENCE_LIST,
            licenceListData: res.data.data,
          })
          return Promise.resolve(res.data.message && res.data.message)
        }
      })
      .catch(error => {
        stopSipnner(dispatch)
        return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
      })
  }
  export const getToolboxTalkbyId = (id) => dispatch => {
    if (!id) {
      return false;
    }
    if (isNaN(id)) {
      console.log(";;;;;;;^^^not a no^^^^;;;;;;",id);
      return false;
    }
    startSipnner(dispatch);
  
    return scopeAxiosInstance.get('/report-toolbox-talk-by-id?id='+id)
    .then(res => {
    console.log(";;;;;;;^^###^^^^^;;;;;;",res.data.data);

      stopSipnner(dispatch);
      if (res.data.status) {
          dispatch({
            type: GET_TOOLBOX_TALK_BY_ID,
            toolboxTalkDetail: res.data.data,
          })
          return Promise.resolve(res.data.message && res.data.message)
        }
      })
      .catch(error => {
        stopSipnner(dispatch)
        return Promise.reject(error.response ? error.response.data.message : Strings.network_error)
      })
  }
  export const createReportManagementToolboxTalk = (formData) => dispatch => {
    console.log(";;;form data is:",formData);
    startSipnner(dispatch);
    delete formData.controls_temp
    return scopeAxiosInstance.post(`${REPORT_MANAGEMENT_CREATE_TOOLBOX_TALK_URL}`, formData)
      .then(res => {
        stopSipnner(dispatch);
        if (res.data.status) {
          return Promise.resolve(res.data.message)
        }
      })
      .catch((error) => {
        stopSipnner(dispatch);
        return Promise.reject(error.response ?
          error.response.data.message
          : Strings.network_error)
      });
  }

  
  export const createHazardManagement = (formData) => dispatch => {
    console.log(";;;form data is:",formData);
    startSipnner(dispatch);
    delete formData.controls_temp
    return scopeAxiosInstance.post(`${REPORT_MANAGEMENT_CREATE_HAZARD_URL}`, formData)
      .then(res => {
        stopSipnner(dispatch);
        if (res.data.status) {
          return Promise.resolve(res.data.message)
        }
      })
      .catch((error) => {
        stopSipnner(dispatch);
        return Promise.reject(error.response ?
          error.response.data.message
          : Strings.network_error)
      });
  }
  
  export const updateReportManagementToolboxTalk = (formData) => dispatch => {
    console.log(";;;form data is:",formData);
    startSipnner(dispatch);
    delete formData.controls_temp
    return scopeAxiosInstance.put(`${REPORT_MANAGEMENT_CREATE_TOOLBOX_TALK_URL}`, formData)
      .then(res => {
        stopSipnner(dispatch);
        if (res.data.status) {
          return Promise.resolve(res.data.message)
        }
      })
      .catch((error) => {
        stopSipnner(dispatch);
        return Promise.reject(error.response ?
          error.response.data.message
          : Strings.network_error)
      });
  }
