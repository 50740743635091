import React, { useEffect, useState, useMemo } from "react";
import LinearProgress from '@mui/material/LinearProgress';
import DescriptionIcon from '@mui/icons-material/Description';
import IconButton from "@mui/material/IconButton";
import { downloadDocument, getStorage } from "../../../../../utils/common";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { JWT_ID_TOKEN, ADMIN_DETAILS } from "../../../../../dataProvider/constant";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity"
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { Typography } from "@mui/material";

import styles from "../../JobStyle.module.css";
import { Dropdown, Menu, notification } from "antd";

export default function DocumentImageField({ disabled, input, s3UploadPath, onDelete, previewFile }) {

  const [isUploading, setIsUploading] = useState(false);
  const imageTypes = ["png", "jpg", "jpeg", "webp"];

  const isPreviewable = useMemo(() => {
    if (input.value && input.value.file_name) {
      let nameSplit = input.value.file_name.split(".");
      let type = nameSplit[nameSplit.length - 1].toString().toLowerCase();
      if (type === "pdf" || imageTypes.indexOf(type) !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [input.value]);

  const controller = new AbortController();

  useEffect(() => {
    if (input.value && input.value.originFileObj) {
      let file = input.value;
      if (!file.isUploaded) {
        uploadFile();
      }
    }
  }, [input.value])

  const uploadPreviewPath = useMemo(() => {
    let pathValue = ""
    if (input.value && input.value.originFileObj) {
      let nameSplit = input.value.originFileObj.name.split(".");
      let type = nameSplit[nameSplit.length - 1];
      if (imageTypes.includes(type)) {
        let file = input.value;
        pathValue = URL.createObjectURL(file.originFileObj);
      }
    } else {
      if (input.value && input.value.file_name && input.value.file_base_path) {
        let nameSplit = input.value.file_name.split(".");
        let type = nameSplit[nameSplit.length - 1];
        if (imageTypes.includes(type)) {
          pathValue = `${input.value.file_base_path}${input.value.file_name}`
        }
      }
    }
    return pathValue;
  }, [input.value])

  const uploadFile = () => {
    if (!s3UploadPath) {
      input.onChange(null);
      notification.error({
        message: "Unable to Process file Upload",
        className: "ant-error"
      });
      console.error("Failed to upload file - Upload Path not provided");
    } else {
      setIsUploading(true);
      let region = process.env.REACT_APP_REGION;
      let logins = {};
      logins[process.env.REACT_APP_COGNITO_USER_POOL_ID] = getStorage(JWT_ID_TOKEN);
      let client = new S3Client({
        region,
        credentials: fromCognitoIdentityPool({
          identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
          client: new CognitoIdentityClient({ region }),
          logins,
          clientConfig: { region },
        })
      });

      try {
        let slug = JSON.parse(getStorage(ADMIN_DETAILS)).organisation.slug;
        let name = `${new Date().getTime()}-${input.value.originFileObj.name}`
        let path = `${s3UploadPath}/${name}`;
        Promise.resolve(client.send(
          new PutObjectCommand({ Bucket: process.env.REACT_APP_TEMP_BUCKET, Key: slug + "/" + path, Body: input.value.originFileObj, ContentType: input.value.originFileObj.type }),
          { abortSignal: controller.signal }
        ))
          .then(() => { input.onChange({ ...input.value, file_name: path, name: name, visible_name: input.value.originFileObj.name, isUploaded: true, isFailed: false }) })
          .catch(err => {
            input.onChange({ ...input.value, visible_name: input.value.originFileObj.name, isUploaded: true, isFailed: true })
            console.log(err);
          })
          .finally(() => setIsUploading(false))
      }
      catch (e) {
        notification.error({
          message: "Failed to upload File.",
          className: "ant-error"
        });
        console.log(e);
        onDelete();
      }
    }
  }

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, [])

  const downloadAttachment = () => {
    try {
      let organisation = JSON.parse(getStorage(ADMIN_DETAILS)).organisation;
      let domain = new URL(organisation.logo_path).origin;
      let slug = organisation.slug;
      let fullPath = `${domain}/${slug}/${input.value.file_name}`;
      downloadDocument(fullPath, true, input.value.visible_name ? input.value.visible_name : null)
    } catch (e) {
      notification.error({
        message: "Failed to Preview File.",
        className: "ant-error"
      });
      console.log(e);
    }
  }

  const preview = () => {
    try {
      let organisation = JSON.parse(getStorage(ADMIN_DETAILS)).organisation;
      let domain = new URL(organisation.logo_path).origin;
      let slug = organisation.slug;
      let fullPath = `${domain}/${slug}/${input.value.file_name}`;
      previewFile(fullPath)
    } catch (e) {
      notification.error({
        message: "Failed to Preview File.",
        className: "ant-error"
      });
      console.log(e);
    }
  }


  const handleOption = ({ key }) => {
    if (key === "3") {
      if (typeof onDelete !== "undefined") {
        onDelete()
      } else {
        input.onChange(null);
      }
    } if (key === "2") {
      downloadAttachment();
    }
    if (key === "1") {
      if (typeof previewFile !== "undefined" && isPreviewable) {
        preview()
      }
    }
  }

  const optionMenu = () => (
    <Menu onClick={handleOption}>
      <Menu.Item key="1" disabled={isUploading || !isPreviewable}>
        Preview
      </Menu.Item>
      <Menu.Item key="2" disabled={isUploading || input && input.value && input.value.isFailed}>
        Download
      </Menu.Item>
      <Menu.Item key="3">
        Delete
      </Menu.Item>
    </Menu>
  )

  return (
    <div className="border" style={{ minWidth: "0px", flex: "1" }} onClick={e => e.stopPropagation()}>
      <div className="" style={{ height: " 4px" }}>
        {isUploading ? (
          <LinearProgress sx={{ height: "2px" }} />
        ) : ""}
      </div>
      <div className={`${styles.UploadBox} pt-0`}>
        <div className={styles.UploadImageWrapper}>
          {uploadPreviewPath ? (
            <div className="d-flex align-items-center h-100">
              <img
                src={uploadPreviewPath}
                className={`${styles.UploadPhoto}`}
                alt="preview" />
            </div>
          ) : (
            <DescriptionIcon sx={{ fontSize: "40px" }} />
          )}
        </div>
        <div className="px-2" style={{ minWidth: "0px" }}>
          {/* <div className={`${styles.UploadName}`}> */}
          <Typography fontSize={13} noWrap> {input.value.originFileObj && input.value.originFileObj.name ? input.value.originFileObj.name : input.value && input.value.visible_name ? input.value.visible_name : ""}
          </Typography>
          {/* </div> */}
        </div>
        <div className={`${styles.AttachemntIcons}`}>
          <div>
            <Dropdown overlayClassName="cs-v3-dropdown" overlay={optionMenu} disabled={disabled}>
              <IconButton style={{ height: "auto" }} disabled={disabled}>
                <svg xmlns="http://www.w3.org/2000/svg" height="16px" width="16px" viewBox="0 0 512 512">
                  <path fill="currentColor" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                </svg>
              </IconButton>
            </Dropdown>
          </div>
        </div>
      </div>
      {input && input.value && input.value.isFailed ? (
        <div className={styles.UploadErrorBox}>
          Failed to Upload.
        </div>) : ""}
    </div>
  )
}
