import React, { useEffect, useState, createRef, forwardRef, memo } from "react";
import { useDispatch } from "react-redux";
import { addUpdateProfileSignature, getOrgUserDetails, getOrgUserProfileDetails } from '../../../actions/profileManagementActions';
import SignCanvas from "../../common/SignCanvas";
import { notification } from "antd";
import { Strings } from "../../../dataProvider/localize";
import { getStorage } from "../../../utils/common";
import { ACCESS_CONTROL } from "../../../dataProvider/constant";
import styles from "./ProfileStyles.module.css";
import { useLocation } from "react-router";

const SignatureComponent = forwardRef((props, ref) => <SignCanvas forwardedRef={ref}  {...props} />)
const MemoizedSignature = memo(SignatureComponent)

export default function Signature(props) {

  const dispatch = useDispatch();
  const canvasRef = createRef(null);

  const [signedData, setSignedData] = useState(null);
  const [editing, setEditing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (props.id && props.sign) {
      setSignedData({
        sign_data: props.sign,
        id: props.id
      })
    } else {
      setSignedData(null);
    }
  }, [props.userId, props.sign])

  const fetchSignature = () => {
    dispatch(getOrgUserProfileDetails(props.userId, props.userName, null, location.source === "isFromUserMgmt" ? true : false)).then((res) => {
      if (editing) {
        setEditing(false);
      }
    }).catch(err => {
      notification.error({
        message: Strings.error_title,
        description: err ? err : Strings.generic_error,
        className: "ant-error"
      })
    })
  }

  const saveSign = () => {
    let canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext('2d');
      const pixelBuffer = new Uint32Array(
        context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
      );
      if (!pixelBuffer.some(color => color !== 0)) {
        notification.warning({
          message: "Signature is Required.",
          className: 'ant-warning'
        })
      } else {
        setSubmitting(true);
        let sign = canvas.toDataURL("image/png");
        let formData = {
          profile_sign_data: sign,
          id: props.id
        }
        dispatch(addUpdateProfileSignature(formData, props.userName, false)).then(res => {
          notification.success({
            message: Strings.success_title,
            description: res.message,
            className: "ant-success"
          });
          fetchSignature();
        }).catch(err => {
          notification.error({
            message: Strings.error_title,
            description: err ? err : Strings.generic_error,
            className: "ant-error"
          })
        }).finally(() => {
          setSubmitting(false);
        })
      }
    }
  }

  const hasPermission = (permission) => {
    let allowed = false;
    let userPermissions = JSON.parse(getStorage(ACCESS_CONTROL))["userManagement"]
    let accessControl = userPermissions && userPermissions.permissions ? userPermissions.permissions : [];
    let permission_id = accessControl.findIndex(acess => acess.control_name.toLowerCase() === permission.toLowerCase());
    if (permission_id !== -1 || location.pathname === "/dashboard/profile") {
      allowed = true;
    }

    return allowed;
  }

  return (
    <div>
      <hr className={styles.DetailsDivider}></hr>
      <div className="container-fluid pt-3 pb-2">
        <div className="row align-items-center">
          <h2 className={`${styles.MedicalLabel} font-weight-bold`}>Declaration</h2>
        </div>
        <div className="row py-3">
          <div className="col-12 px-0">
            <p className={`${styles.MedicalLabel} font-weight-bold`}>
              I understand that all offers of employment may be conditional
              upon satisfactory reference and background checks being
              obtained, (including National criminal record and pre-employment
              medical checks, as relevant and required for the position) and
              the production of all documents necessary for High Clean Pty Ltd
              to verify my identity, qualifications and ability to work in
              Australia. I consent to the authorised representatives of High
              Clean Pty Ltd contacting any person(s) or institutions relevant
              to this application to undertake these verifications and checks.
            </p>
            <p className={`${styles.MedicalLabel} font-weight-bold pt-3`}>
              I certify that the information provided in this application is
              true and complete to the best of my knowledge, information and
              belief. I understand withholding relevant information or
              submitting false or misleading information on this application,
              my resume, during interviews or at any other time during the
              hiring process, may result in my disqualification from further
              consideration for employment or if I am employed, the
              termination of my employment.
            </p>
          </div>
        </div>
      </div>
      <hr className={styles.DetailsDivider}></hr>
      <div className="container-fluid pt-3 pb-2">
        <div className="row align-items-center">
          <h2 className={`${styles.MedicalLabel} font-weight-bold`}>Signature</h2>
        </div>
        <div className="row">
          <div className="col-12 px-0">
            {signedData && !editing ? (
              <div>
                <div>
                  <img alt="Signature" className={styles.signatureImage} src={props.sign ? props.sign : ""} />
                </div>
                <div>
                  <button className={styles.BtnSaveTab} onClick={() => setEditing(true)}>Update</button>
                </div>
              </div>
            ) : ""}
            <div style={{ display: editing ? "block" : "none", width: "100%" }}>
              <MemoizedSignature
                ref={canvasRef}
                cancelEditing={() => setEditing(false)}
                onSave={saveSign}
                submitting={submitting}
                isEditable={hasPermission('sf_update_medical_declaration_update_medical_declaratio')}
                isNew={!props.sign} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}