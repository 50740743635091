import React, { useRef } from "react";
import OnSite from "./OnSite/OnSite";
import TaskFileList from "./TaskFiles/TaskFileList";
import TaskList from "./Tasks/TaskList";
import WorkFlowHistory from "./WorkFlowHistory/WorkFlowHistory";
import NotesHistory from "./Notes/NotesHistory";

export default function JobBoardContainer(props) {

  const historyKey = useRef(1);

  return (
    <div className="container-fluid pb-3">
      <div className="row">
        <div className="col-xl-7 col-lg-12 pr-0">
          <TaskList jobId={props.jobId} setSelectedTask={props.setSelectedTask} onReload={() => historyKey.current = historyKey.current + 1} />
        </div>
        <div className="col-xl-5 col-lg-12 pl-0">
          <OnSite jobId={props.jobId} />
          <TaskFileList jobId={props.jobId} />
          <NotesHistory jobId={props.jobId} />
          {/* <WorkFlowHistory jobId={props.jobId} key={historyKey.current}/> */}
        </div>
      </div>
    </div>
  );
}
