import React, { useEffect, useState, useRef } from "react";
import { Icon, Avatar, Radio, Input, notification } from "antd";
import { components } from "react-select";
import { Field, formValueSelector, reduxForm, FormSection } from "redux-form";
import { CircularProgress } from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { frequencyOptions, repeatOptions, dayOptions, monthOptions, monthWeekOptions, getFrequencyPattern, weekDayOptions } from "../../Quotes/QuoteDetails/QuoteTask/QuoteTaskutils";
import { customInput } from "../../../common/custom-input";
import { CustomDatepicker } from "../../../common/customDatepicker";
import { CustomSingleSelect } from "../../../common/new-core-ui/CustomSingleSelect";
import { getFormattedOptions } from "../../Quotes/QuoteUtils";
import { getTaskFrequencyCount } from "../../../../actions/v2/QuoteActions";
import { addNewFrequency, getJobHeader, getJobTasks } from "../../../../actions/v2/JobActions";
import { Strings } from "../../../../dataProvider/localize";
import CustomRadioGroupV2 from "../../../common/new-core-ui/CustomRadioGroupV2";
import colourStyles from "../../Quotes/FilterSelectQuotesStyles";

import v2Styles from "../../v2Styles.module.css";
import "../../v2Styles.css";

export default function AddFrequencyModal({ jobId, task, onClose, setSelectedTask }) {

  const job = useSelector((state) => state.Job.jobDetails);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const onSubmit = (values) => {
    setLoading(true);
    const index = task && task.task_number ? task.task_number.lastIndexOf("J") : -1;

    const formData = {
      task_id: task.task_id,
      task_number: index !== -1 ? task.task_number.substr(0, index) : task.task_number,
      task_cost: task.task_cost,
      job_id: job.job_id,
      job_number: job.job_number,
      job_cost: job.job_total_amount,
      freq_details: getFrequencyPattern(values.frequency_type, values.frequency, values.task_duration),
    }
    dispatch(addNewFrequency(formData)).then((message) => {
      Promise.all([dispatch(getJobTasks(jobId)), dispatch(getJobHeader(jobId))]).then(res => {
        if (res[0] && res[0].job_board_details && res[0].job_board_details.task_level_data) {
          const selected = res[0].job_board_details.task_level_data.find(x => x.task_id === task.task_id);
          if (selected) {
            setSelectedTask({ ...selected, quote_task_id: selected.task_id, task_number: selected.task_label });
          }
        }
      })
        .catch(err => {
          notification.error({
            message: Strings.error_title,
            description: err ? err : Strings.generic_error,
            className: 'ant-error'
          })
        })
      setLoading(false);
      notification.success({
        message: Strings.success_title,
        description: message,
        className: "ant-success"
      })
      onClose();
    }).catch(err => {
      if (err) {
        setLoading(false)
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: "ant-error"
        })
      }
    })
  }

  useEffect(() => {
    let allValues = {};
    let frequency = {
      selected_days: [],
      week_days: []
    }
    if (task && task.frquency_details) {
      allValues.task_duration = task.frquency_details.task_duration ?
        ({
          label: task.frquency_details.task_duration.replace("_", " "),
          value: task.frquency_details.task_duration
        }) : ({ label: "1 Day", value: "1_Day" });

      if (task.frquency_details.pattern) {
        frequency.repeat_value = task.frquency_details.pattern.repeat_every;

        if (task.frquency_details.pattern.type === "D_EVERY_DAY") {
          allValues.frequency_type = frequencyOptions[1];
          frequency.repeat_type = repeatOptions[0];
        }
        else if (task.frquency_details.pattern.type === "D_EVERY_WEEK_DAY") {
          allValues.frequency_type = frequencyOptions[2];
          frequency.repeat_type = repeatOptions[1];
          frequency.selected_days = task.frquency_details.pattern.day ? [task.frquency_details.pattern.day.split("|")[0]] : [dayOptions[1]];
          frequency.week_days = [dayOptions[1]];
        }
        else if (task.frquency_details.pattern.type === "D_EVERY_WEEK") {
          allValues.frequency_type = frequencyOptions[2];
          frequency.repeat_type = repeatOptions[1];
          // frequency.selected_days = task.frquency_details.pattern.day ? [task.frquency_details.pattern.day.split("|")[0]] : [dayOptions[1]];
          let selected_days = task.frquency_details.pattern.day ? [task.frquency_details.pattern.day.split("|")[0]] : [dayOptions[1]];
          frequency.selected_days = selected_days;
          frequency.week_days = task.frquency_details.week_days ? task.frquency_details.week_days.split(",").map(x => weekDayOptions[x - 1]) : selected_days;
        }
        else if (task.frquency_details.pattern.type === "M_DATE_OF_EVERY_MONTH") {
          allValues.frequency_type = frequencyOptions[3];
          frequency.repeat_type = repeatOptions[2];
          frequency.month_value = 1;
          frequency.month_day_number = task.frquency_details.pattern.day_of_month ? task.frquency_details.pattern.day_of_month.toString() : "1";
        }
        else if (task.frquency_details.pattern.type === "M_DAY_OF_EVERY_MONTH") {
          allValues.frequency_type = frequencyOptions[3];
          frequency.repeat_type = repeatOptions[2];
          frequency.month_value = 2;
          frequency.month_week = task.frquency_details.pattern.day_of_month ?
            monthWeekOptions.find(s => s.sequence.toString() === task.frquency_details.pattern.day_of_month.toString()) :
            monthWeekOptions[0];

          if (task.frquency_details.pattern.day) {
            let monthDay = dayOptions.find(x => x.toLowerCase() === task.frquency_details.pattern.day.toLowerCase());
            if (monthDay) {
              frequency.month_day = { label: monthDay, value: monthDay }
            } else {
              frequency.month_day = { label: dayOptions[0], value: dayOptions[0] }
            }
          } else {
            frequency.month_day = { label: dayOptions[0], value: dayOptions[0] }
          }
        }
        else if (task.frquency_details.pattern.type === "Y_DATE_OF_MONTH") {
          allValues.frequency_type = frequencyOptions[4]
          frequency.repeat_type = repeatOptions[3];
          frequency.year_value = 1;
          frequency.year_month_day_number = task.frquency_details.pattern.day_of_month ? task.frquency_details.pattern.day_of_month.toString() : "1";
          frequency.year_month = task.frquency_details.pattern.month ?
            monthOptions.find(x => x.sequence.toString() === task.frquency_details.pattern.month.toString()) : monthOptions[0];

        } else if (task.frquency_details.pattern.type === "Y_DAY_OF_MONTH") {
          allValues.frequency_type = frequencyOptions[4];
          frequency.repeat_type = repeatOptions[3];
          frequency.year_value = 2;
          frequency.year_month_week = task.frquency_details.pattern.day_of_month ?
            monthWeekOptions.find(x => x.sequence.toString() === task.frquency_details.pattern.day_of_month.toString()) :
            monthWeekOptions([0]);

          if (task.frquency_details.pattern.day) {
            let yearMonthDay = dayOptions.find(x => x.toLowerCase() === task.frquency_details.pattern.day.toLowerCase());
            if (yearMonthDay) {
              frequency.year_month_day = { label: yearMonthDay, value: yearMonthDay }
            } else {
              frequency.year_month_day = { label: dayOptions[0], value: dayOptions[0] };
            }
          } else {
            frequency.year_month_day = { label: dayOptions[0], value: dayOptions[0] }
          }
          frequency.year_month_name = task.frquency_details.pattern.month ?
            monthOptions.find(x => x.sequence.toString() === task.frquency_details.pattern.month.toString()) :
            monthOptions[0];
        } else {
          allValues.frequency_type = frequencyOptions[0];
          frequency.repeat_type = null;
          frequency.repeat_value = "1";
        }
      }
      frequency.freq_start_date = task.task_end_date ? moment(task.task_end_date).endOf("day").add(1, "day") : null;
      frequency.freq_end_date = null;
    }
    allValues.frequency = frequency;
    setInitialValues(allValues);
  }, [task]);

  return (
    <div className="v2-modal-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={v2Styles.ModalHeading}>Add Frequency</div>
            <div>
              <hr />
            </div>
          </div>
          <div className="col-12">
            <NewFrequencyForm
              task={task}
              initialValues={initialValues}
              onClose={onClose}
              loading={loading}
              onSubmit={onSubmit} />
          </div>
        </div>
      </div>
    </div>
  )
}

let NewFrequencyForm = ({ handleSubmit, task, frequency, frequencyType, taskDuration, initialValues, onClose, loading }) => {

  const controller = useRef();
  const dispatch = useDispatch()

  const [durationOptions, setDurationOptions] = useState([]);
  const [durationInput, setDurationInput] = useState("");
  const [durationValue, setDurationValue] = useState(null);
  const [disabledDays, setDisabledDays] = useState([]);
  const [frequencyTimes, setFrequncyTimes] = useState(0);
  const [frequencyLoading, setFrequencyLoading] = useState(false);

  const getFrequencies = () => {
    if (frequencyType && frequency && frequency.repeat_value && frequency.freq_start_date && frequency.freq_end_date) {
      let data = getFrequencyPattern(frequencyType, frequency, taskDuration)
      setFrequencyLoading(true);
      controller.current = new AbortController();
      dispatch(getTaskFrequencyCount(data, { signal: controller.current.signal })).then(res => {
        if (res.frequency_count || (res.frequency_count !== undefined && res.frequency_count.toString() === "0")) {
          setFrequncyTimes(res.frequency_count);
        }
        setFrequencyLoading(false)
      }).catch((e) => { if (e) { setFrequencyLoading(false); } })
    }
  }

  const getDurationOptions = (val = "") => {
    let num = val;
    if (!val || (val && val.trim() === "")) {
      if (durationValue && durationValue.value) {
        num = durationValue.value
      } else if (initialValues && initialValues.task_duration && initialValues.task_duration.value) {
        num = initialValues.task_duration.value;
      }
    }
    setDurationOptions(getFormattedOptions(num))
  }

  useEffect(() => {
    getFrequencies();
  }, [frequency, taskDuration])

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-4">
          <div className={v2Styles.Label}>Start Date</div>
          <Field name="frequency.freq_start_date"
            component={CustomDatepicker}
            className="cs-datepicker-v3"
            disabled={loading}
            disabledDate={(v) => moment(v).isSameOrBefore(moment(task.task_end_date).endOf("day"), "day")}
            dropdownClassName="cs-datepicker-dropdown" />
        </div>
        <div className="col-4">
          <div className={v2Styles.Label}>End Date</div>
          <Field name="frequency.freq_end_date"
            component={CustomDatepicker}
            className="cs-datepicker-v3"
            disabled={loading}
            disabledDate={(v) => moment(v).isSameOrBefore(moment(task.task_end_date).endOf("day"), "day")}
            dropdownClassName="cs-datepicker-dropdown"
            otherProps={{
              defaultPickerValue: moment(task.task_end_date).endOf("day").add(1, "day")
            }}
          />
        </div>
        <div className="col-4">
          <div className={v2Styles.Label}>Frequency</div>
          <Field
            component={CustomSingleSelect}
            name="frequency_type"
            styles={colourStyles}
            dropdownIndicator={<Icon type="down" style={{ fontSize: "13px" }} />}
            options={frequencyOptions}
            otherProps={{
              components: { dropdownIndicator: <components.DownChevron /> },
              isDisabled: true
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6 pt-3">
          <div className={v2Styles.Label}>Task Duration</div>
          <Field name="task_duration"
            placeholder=""
            component={CustomSingleSelect}
            styles={colourStyles}
            options={durationOptions}
            otherProps={{
              isDisabled: true,
              inputValue: durationInput,
              onInputChange: () => { },
              onChange: () => { },
              onMenuClose: () => setDurationInput(''),
              onMenuOpen: () => getDurationOptions(),
              components: { dropdownIndicator: <components.DownChevron /> }
            }} />
        </div>
        <div className="col-4 pt-3">
          <div className={v2Styles.Label}>Count</div>
          <div style={{ display: "flex", alignItems: "center", columnGap: "5px" }}>
            <Input
              className={v2Styles.TextField}
              value={frequencyTimes}
              disabled
            />
            <div style={{ width: "25px" }}>
              {frequencyLoading ? (<Icon style={{ fontSize: "20px" }} type="loading" />) : ""}
            </div>
          </div>
          {!frequencyLoading && frequencyTimes === 0 ? (<div className="error-input">Invalid</div>) : ""}
        </div>
      </div>
      {frequency && frequency.repeat_type !== null ? (
        <FormSection name="frequency">
          <div className="row">
            <div className="col-6 pt-3 pb-1">
              <div className={v2Styles.Label}>Repeat Every</div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <Field name="repeat_value" type="number"
                min={1}
                disabled
                max={99}
                component={customInput}
                className={v2Styles.TextField}
              />
            </div>
            <div className="col-3">
              <Field name="repeat_type" component={CustomSingleSelect} styles={colourStyles}
                onChange={() => { }}
                options={repeatOptions}
                otherProps={{
                  isDisabled: true,
                  components: { dropdownIndicator: <components.DownChevron /> }
                }} />
            </div>
          </div>
        </FormSection>
      ) : ""}
      {frequency && frequency.repeat_type && frequency.repeat_type.value === "Week" && frequencyType && frequencyType.value === "weekly" ? (
        <FormSection name="frequency">
          <div className="row align-items-center">
            <div className="col-12 pt-3">
              <div className={v2Styles.Label}>Day of the Week</div>
              <div className="d-flex align-items-center">
                {dayOptions.map(day => <div className="pr-1" key={day}>
                  <Avatar
                    style={{ margin: "0px auto" }}
                    className={`${v2Styles.SelectableAvatar} ${frequency.week_days && frequency.week_days.includes(day) ? v2Styles.SelectedAvatar : disabledDays.includes(day) ? v2Styles.DisabledAvatar : ""}`}
                    size={32}
                    onClick={() => { }}
                  >
                    {day.charAt(0).toUpperCase()}
                  </Avatar>
                </div>)}
              </div>
            </div>
          </div>
        </FormSection>
      ) : (frequency && frequency.repeat_type && frequency.repeat_type.value === "Month") ? (
        <FormSection name="frequency">
          <div className="row">
            <div className="col-12 pt-3">
              <Field component={CustomRadioGroupV2} name="month_value" defaultValue={1}>
                <div className="pb-2">
                  <Radio disabled className="cs-radio-v2" value={1}>On day </Radio>
                  <div style={{ width: "120px", display: "inline-block" }}>
                    <Field
                      name="month_day_number"
                      component={customInput}
                      type="number"
                      defaultValue={1}
                      max={31}
                      min={1}
                      className={v2Styles.TextField}
                      disabled
                    />
                  </div>
                </div>
                <div>
                  <Radio disabled className="cs-radio-v2" value={2}>On the </Radio>
                  <div style={{ width: "120px", display: "inline-block" }}>
                    <Field
                      name="month_week"
                      component={CustomSingleSelect}
                      styles={colourStyles}
                      otherProps={{
                        isDisabled: true,
                        components: { dropdownIndicator: <components.DownChevron /> }
                      }}
                      options={monthWeekOptions} />
                  </div>
                  <div style={{ width: "120px", marginLeft: "10px", display: "inline-block" }}>
                    <Field name="month_day" component={CustomSingleSelect} styles={colourStyles}
                      otherProps={{
                        isDisabled: true,
                        components: { dropdownIndicator: <components.DownChevron /> }
                      }}
                      options={dayOptions.map(x => ({ label: x, value: x }))} />
                  </div>
                </div>
              </Field>
            </div>
          </div>
        </FormSection>
      ) : (frequency && frequency.repeat_type && frequency.repeat_type.value === "Year") ? (
        <FormSection name="frequency">
          <div className="row">
            <div className="col-12 pt-3">
              <Field component={CustomRadioGroupV2} disabled name="year_value" defaultValue={1}>
                <div className="pb-2 d-flex align-items-end">
                  <Radio disabled className="cs-radio-v2" value={1}>On </Radio>
                  <div style={{ width: "120px", display: "inline-block" }}>
                    <Field name="year_month" component={CustomSingleSelect} styles={colourStyles}
                      otherProps={{
                        isDisabled: true,
                        components: { dropdownIndicator: <components.DownChevron /> }
                      }}
                      options={monthOptions} />
                  </div>
                  <div style={{ width: "120px", display: "inline-block", marginLeft: "10px", }}>
                    <Field disabled name="year_month_day_number" component={customInput} type="number" defaultValue={1} max={31} min={1} className={v2Styles.TextField} />
                  </div>
                </div>
                <div className="pb-2 d-flex align-items-end">
                  <Radio disabled className="cs-radio-v2" value={2}>On the</Radio>
                  <div style={{ width: "120px", display: "inline-block" }}>
                    <Field name="year_month_week" component={CustomSingleSelect} styles={colourStyles}
                      otherProps={{
                        isDisabled: true,
                        components: { dropdownIndicator: <components.DownChevron /> }
                      }}
                      options={monthWeekOptions} />
                  </div>
                  <div style={{ width: "120px", marginLeft: "10px", display: "inline-block" }}>
                    <Field name="year_month_day" component={CustomSingleSelect} styles={colourStyles}
                      dropdownIndicator={<Icon type="down" style={{ fontSize: "13px" }} />}
                      options={dayOptions.map(x => ({ label: x, value: x }))}
                      otherProps={{
                        isDisabled: true,
                        components: { dropdownIndicator: <components.DownChevron /> }
                      }} />
                  </div>
                  <div className="px-3">of</div>
                  <div style={{ width: "120px", display: "inline-block" }}>
                    <Field name="year_month_name" component={CustomSingleSelect} styles={colourStyles}
                      options={monthOptions}
                      otherProps={{
                        isDisabled: true,
                        components: { dropdownIndicator: <components.DownChevron /> }
                      }} />
                  </div>
                </div>
              </Field>
            </div>
          </div>
        </FormSection>
      ) : ""}
      <div className="row">
        <div className="col-12 text-right pt-3">
          <div className="d-flex align-items-center justify-content-end" style={{ gap: "5px" }}>
            {loading ? (<CircularProgress size={20} />) : null}
            <button type="button" onClick={() => onClose()} className={v2Styles.OutlinedButton}>Cancel</button>
            <button type="submit" className={v2Styles.PrimaryButton}
              disabled={
                frequencyTimes === 0 ||
                frequencyLoading || loading
              }
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}


const validate = (values, props) => {
  let errors = {};
  let freqErrors = {};
  if (!values.frequency || (values.frequency && !values.frequency.freq_start_date)) {
    freqErrors.freq_start_date = "Required";
  }
  if (!values.frequency || (values.frequency && !values.frequency.freq_end_date)) {
    freqErrors.freq_end_date = "Required";
  }
  if (values.frequency && values.frequency.freq_start_date && values.frequency.freq_end_date) {
    if (moment(values.frequency.freq_start_date).isSameOrBefore(moment(props.task.task_end_date).endOf("day"), "day")) {
      freqErrors.freq_start_date = "Invalid Start Date";
    }
    if (moment(values.frequency.freq_end_date).isSameOrBefore(moment(props.task.task_end_date).endOf("day"), "day")) {
      freqErrors.freq_end_date = "Invalid End Date";
    }
    if (moment(values.frequency.freq_start_date).startOf("day").isAfter(moment(values.frequency.freq_end_date).endOf("day"))) {
      freqErrors.freq_end_date = "Invalid end date";
    }
  }
  if (Object.keys(freqErrors).length > 0) {
    errors.frequency = freqErrors;
  }
  return errors;
}

NewFrequencyForm = reduxForm({
  enableReinitialize: true,
  destroyOnUnmount: true,
  form: "add_new_frequency_form",
  validate: validate
})(NewFrequencyForm);

NewFrequencyForm = connect((state) => ({
  frequency: (formValueSelector("add_new_frequency_form"))(state, "frequency"),
  frequencyType: (formValueSelector("add_new_frequency_form"))(state, "frequency_type"),
  taskDuration: (formValueSelector("add_new_frequency_form"))(state, "task_duration"),
}))(NewFrequencyForm)