import { CustomCheckbox } from "../../../../../components/common/customCheckbox";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Field } from "redux-form";
import styles from "./PreAllocateStyles.module.css";
import { downLoadDoc } from "../../../Job/JobBoard/utils";
import jobStyles from "../../../Job/JobStyle.module.css";
import { IconButton } from "@mui/material";

const ChemicalsTable = ({ onShowModal }) => {
  const chemicals = useSelector((state) => state.JobDocument.chemicals);

  return (
    <div className="table-responsive">
      <table className="table table-sm cs-table cs-table-v2 v2-table-no-last-border w-100">
        <thead>
          <tr>
            <th>Product Name</th>
            <th width="200">Manufacturer</th>
            <th width="150">Hazardous</th>
            <th width="200">Issue Date</th>
            <th width="200">Expiry Date</th>
            <th width="150" className="text-center">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {chemicals.map((row, index) => (
            <tr key={index}>
              <td>
                <Field
                  type="text"
                  name={`select_chemical_${row.chemical_id}`}
                  component={CustomCheckbox}
                  className="cs-select-v2"
                  label={row.chemical_name}
                />
              </td>
              <td>{row.chemical_manufacturer}</td>
              <td>{row.is_hazardous}</td>
              <td>
                {row.chemical_issue_date ? moment(row.chemical_issue_date).format("DD/MM/YYYY") : ''}
              </td>
              <td className={styles.LabelRed}>
                {row.chemical_expiry_date ? moment(row.chemical_expiry_date).format("DD/MM/YYYY") : ''}
              </td>
              <td className="text-center">
                {row.chemical_doc ? (
                  <>
                    <IconButton onClick={() => onShowModal(row.chemical_doc, row.chemical_doc.endsWith(".pdf"))} className={`${jobStyles.MuiIconButton} ${jobStyles.GreenTextV2} mx-2`}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style={{ height: "16px", width: "16px" }}>
                        <path fill="currentColor" d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                      </svg>
                    </IconButton>
                    <IconButton onClick={() => downLoadDoc(row.chemical_doc)} className={`${jobStyles.MuiIconButton} ${jobStyles.PrimaryText} mx-2`}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: "16px", width: "16px" }}>
                        <path fill="currentcolor" d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                      </svg>
                    </IconButton>
                  </>
                ) : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ChemicalsTable;
