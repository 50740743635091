import React from "react";
import { components } from "react-select";

export const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{ width: 'auto', margin: '0px 5px' }}
        />
        <label style={{ margin: '0px 5px' }}>{props.label}</label>
      </components.Option>
    </div>
  );
};

export const OptionV2 = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{ width: 'auto', margin: '0px 5px', accentColor: '#e6e6e6' }}
        />
        <label style={{ margin: '0px 5px' }}>{props.label}</label>
      </components.Option>
    </div>
  );
};