import React from "react";
import styles from "../../JobStyle.module.css";
import { Field, reduxForm } from 'redux-form'
import { CustomSingleSelect } from "../../../../common/new-core-ui/CustomSingleSelect";
import { components } from "react-select";
import { customInput } from "../../../../../components/common/custom-input";
import { addNotes, getNotesDetails } from "../../../../../actions/v2/JobAllocatonActions";
import { useSelector, useDispatch } from "react-redux";
import { Strings } from '../../../../../dataProvider/localize';
import { notification } from "antd";
import SelectV2SingleStyle from "../../SelectV2SingleStyle";
import validator from "validator";

const NotesForm = ({ jobId, setLoading, handleSubmit, reset, reloadNotes }) => {

  const dispatch = useDispatch();
  const tasks = useSelector(state => state.Job.parentTasks);

  const onSubmit = (values) => {
    setLoading(true);
    let formData = {
      task_name: values.task_label.value,
      notes_desc: values.notes_desc,
      job_id: jobId
    }
    dispatch(addNotes(formData)).then((message) => {
      notification.success({
        message: Strings.success_title,
        description: message ? message : Strings.generic_error, onClick: () => { },
        className: 'ant-success'
      });
      reloadNotes();
      reset();
      setLoading(false);
    }).catch(err => {
      if (err) {
        setLoading(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: 'ant-error'
        })
      }
    })
  }


  return (
    <form onSubmit={handleSubmit((values => onSubmit(values, tasks)))}>
      <div className="container-fluid px-0 p-2">
        <div className="row align-items-center">
          <div className="col-4">
            <div className={styles.Label}>Select Task</div>
            <div style={{ width: 'webkit-fill-auto' }}>
              <Field
                name="task_label"
                component={CustomSingleSelect}
                clearable={false}
                styles={SelectV2SingleStyle}
                placeholder=""
                options={tasks.map((task) => task && task.task_label && ({ value: task.task_label, label: `${task.task_label} - ${task.task_name}` }))}
                otherProps={{
                  components: { dropdownIndicator: <components.DownChevron /> }
                }}
              />
            </div>
          </div>
          <div className="col-6">
            <div className={styles.Label}>Notes</div>
            <div>
              <Field className={styles.SingleLine}
                name="notes_desc"
                component={customInput}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="col-2 pt-3 text-right">
            <button className={`${styles.PrimaryButton}`} type="submit" >Save</button>
          </div>
        </div>
      </div>
    </form>
  )
}

const validate = (values) => {
  let errors = {};
  if (!values.task_label) {
    errors.task_label = "Required"
  }
  if (!values.notes_desc || (values.notes_desc && validator.isEmpty(values.notes_desc, { ignore_whitespace: true }))) {
    errors.notes_desc = "Required"
  }
  return errors;
}

export default reduxForm({
  enableReinitialize: true,
  form: "notes_form",
  validate: validate
})(NotesForm);