import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './utils/createStore'
import App from './App';
import './assets/css/bootstrap.min.css';
import './App.css';
import './assets/css/dashboard.css';
import "./AppV2.css";
import "./AppV3.css";

export const Store = store;

if ("serviceWorker" in navigator) {
	navigator.serviceWorker
		.register("../firebase-messaging-sw.js")
		.catch((err) => {
			console.log("Service worker registration failed", err);
		});
}

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root'),
);
