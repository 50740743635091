import React from "react";
import { Checkbox } from "antd";
import styles from "./SignOffStyles.module.css";
import moment from "moment";

export default function SignOffTask(props) {
  return <tr class="">
    <td class="text-center">
      <Checkbox checked={props.selected} className="cs-select-v2" onChange={props.onChange} />
    </td>
    <td>
      <div href="#" className={styles.TableSecondaryText}>{props.task.task_label}</div>
      <div><span className={styles.TableSecondaryText}>Task: </span>{props.task.task_name}</div>
      <div><span className={styles.TableSecondaryText}>Area: </span>{props.task.area_name}</div>
      <div><span className={styles.TableSecondaryText}>Note: </span>{props.task.task_note}</div>
    </td>
    <td>{props.task.task_state}</td>
    <td>
      {props.task.person_name && <div className={`${styles.TableSecondaryText} font-weight-bold`}>{props.task.person_name}</div>}
      {props.task.person_role && <div className={styles.TableSecondaryText}>{props.task.person_role}</div>}
      {props.task.job_sign_off_date && <div className={styles.TableSecondaryText}>{moment(props.task.job_sign_off_date).format("DD-MM-YYYY")}</div>}
    </td>
    <td className="text-center">
      {props.task.client_sign && <img alt="Signature" className={styles.signatureImage} src={props.task.client_sign} />}
    </td>
  </tr>
}