import React from 'react';

export const customInput = ({ input, maxLength, placeholder, disabled, readonly, autoFocus, label, type, min, max, onChange, value, autoComplete = "none", className = "", step="", customRef = null, meta: { touched, error, warning } }) => {
  const onKeyDown = e => {
    if (e.keyCode == 32
      && input.name
      && (
        input.name.includes('email')
        || input.name.includes('mobile')
        || input.name.includes('phone')
      )
    ) {
      e.preventDefault();
      return false;
    }

  }
  var id = `${input.name}-focus`


  return (

    <div>
      {label !== undefined &&
        <label>{label}</label>
      }
      <div>
        {readonly ?
          <input {...input} placeholder={placeholder}
            autoComplete={autoComplete} className={className}
            type={type} readOnly onKeyDown={onKeyDown} /> :
          <input {...input} id={id} onChange={onChange ? onChange : input.onChange} value={value ? value : input.value} placeholder={placeholder} disabled={disabled} type={type} autoFocus={autoFocus} maxLength={maxLength} min={min ? parseInt(min) : null} max={max ? parseInt(max) : null} onKeyDown={onKeyDown}
            autoComplete={autoComplete}
            className={className}
            ref={customRef}
            step={step}
          />
        }
        {touched && ((error && <span className="error-input">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </div>
  )
}
