import React, { useState, useEffect, useMemo } from 'react';
import { IconButton } from "@mui/material";
import { Modal } from "antd";
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';

import ManagerDetailsModal from './ManagerDetailsModal';
import { ADMIN_DETAILS, ACCESS_CONTROL } from "../../../../dataProvider/constant";
import { getStorage } from "../../../../utils/common";

import "../ProfileStyle.css";
import styles from "../ProfileStyles.module.css";
import v2Styles from "../../../v2/v2Styles.module.css";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const ManagerDetails = ({ userDetails, onClose }) => {

  const location = useLocation();

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  let orgId = JSON.parse(getStorage(ADMIN_DETAILS)) ? JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id : null;

  const serializeInitialValues = (userDetails) => {
    let values = {};
    if (userDetails.direct_manager) {
      values.direct_manager = { label: userDetails.direct_manager === userDetails.user_name ? 'Self Appointed' : userDetails.direct_manager_name, value: userDetails.direct_manager };
    }
    if (userDetails.compliance_manager) {
      values.compliance_manager = { label: userDetails.compliance_manager === userDetails.user_name ? 'Self Appointed' : userDetails.compliance_manager_name, value: userDetails.compliance_manager }
    }
    return values;
  }
  const checkPermission = (permission) => {
    let allowed = false;
    let userPermissions = JSON.parse(getStorage(ACCESS_CONTROL)) ? JSON.parse(getStorage(ACCESS_CONTROL)).profile : null;
    let accessControl = userPermissions && userPermissions.permissions ? userPermissions.permissions : [];
    let permission_id = accessControl.findIndex(acess => acess.control_name.toLowerCase() === permission.toLowerCase());
    if (permission_id !== -1) {
      allowed = true;
    }
    return allowed;
  }

  const hasEditPermission = useMemo(() => {
    return checkPermission("sf_update_users_managers_update_users_managers")
  }, [])

  useEffect(() => {
    let initialValues = serializeInitialValues(userDetails);
    setInitialValues(initialValues);
  }, [userDetails]);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className={`d-flex align-items-center row ${v2Styles.ManagerDetailsContainer}`}>
            <div>
              <b>Direct Manager:</b>
              <span className='ml-1'>
                {userDetails.direct_manager && userDetails.direct_manager === userDetails.user_name ? 'Self Appointed' : userDetails.direct_manager && userDetails.direct_manager_name ? userDetails.direct_manager_name : "Not Appointed"}
              </span>
            </div>
            <div>
              <b>Compliance Manager:</b>
              <span className='ml-1'>
                {userDetails.compliance_manager && userDetails.compliance_manager === userDetails.user_name ? 'Self Appointed' : userDetails.compliance_manager && userDetails.compliance_manager_name ? userDetails.compliance_manager_name : "Not Appointed"}
              </span>
            </div>
            <div>
              {hasEditPermission ? (
                <div className={`d-flex align-items-center ${v2Styles.ManagerEditButton}`}>
                  <Tooltip title={"Edit Manager"}>
                    <span>
                      <IconButton onClick={() => setEditModalVisible(true)} className={`${v2Styles.IconBtn} ${v2Styles.SuccessColor}`} >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: "14px", width: "14px" }} >
                          <path fill="currentColor" d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
                        </svg>
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              ) : ""}
            </div>
          </div>
        </div>
      </div>
      <Modal
        className={`cs-modal-v2 ${v2Styles.ManagerModal}`}
        centered
        footer={null}
        visible={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        afterClose={onClose}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        destroyOnClose={true}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
      >
        <ManagerDetailsModal
          onClose={() => { setEditModalVisible(false) }}
          location={location}
          initialValues={initialValues}
          orgId={orgId}
          userDetails={userDetails}
        />
      </Modal>
    </div>
  )
}

export default ManagerDetails;