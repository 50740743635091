export const MultiSelectSerializer = {
  toServer: (list, type) => {
    return list.map((value) =>
      type === "number"
        ? parseInt(value)
        : type === "string"
        ? value.toString
        : value
    );
  },
  fromServer: (list, type) => {},
};

export const ObjToValueSerializer = {
  //to extract specific value from an object
  toServer: (obj, key) => {
    return obj[key];
  },
};

export const ReactSelectSerializer = {
  toServer: (list) => list.map((item) => ({ id: item.value.toString() })),
};
