import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SignOffTask from "./SignOffTask"
import { Checkbox, notification, Icon, Tooltip } from "antd";
import LinearProgress from '@mui/material/LinearProgress';

import EmailSignOff from "./EmailSignOff";

import jobStyles from "../JobStyle.module.css"
// import styles from "./SignOffStyles.module.css"
import { getSignOffTasks } from "../../../../actions/v2/TaskSignOffActions";
import { IconButton } from "@mui/material";

export default function TasksSignOffList(props) {

  const [tasks, setTasks] = useState([]);
  const dispatch = useDispatch();
  const jobHeader = useSelector(state => state.Job.jobHeader);
  const [showEmailSignOff, setShowEmailSignOff] = useState(false);
  const [loading, setLoading] = useState(true);

  const [selectedTasks, setSelectedTasks] = useState([])

  const handleTaskSelect = (e, task) => {
    if (e === true) {
      setSelectedTasks([...selectedTasks, task])
    } else {
      let jtasks = selectedTasks.filter(sTask => sTask.task_label !== task.task_label);
      setSelectedTasks([...jtasks]);
    }
  }

  const handleAllSelect = (e) => {
    if (e === true) {
      setSelectedTasks([...tasks]);
    } else {
      setSelectedTasks([]);
    }
  }

  useEffect(() => {
    if (props.refresh !== 0) {
      setLoading(true);
      setSelectedTasks([]);
      dispatch(getSignOffTasks(props.jobId)).then(data => {
        setTasks(data.job_sign_off_tasks);
      }).finally(() => setLoading(false));
    }
  }, [props.refresh])

  useEffect(() => {
    dispatch(getSignOffTasks(props.jobId)).then(data => {
      setTasks(data.job_sign_off_tasks);
    }).finally(() => setLoading(false));
  }, [])

  const handleEmailClick = () => {
    if (selectedTasks.length > 0) {
      setShowEmailSignOff(true)
    } else {
      notification.warning({
        message: "Please select atleast one task for Sign Off.",
        className: 'ant-warning'
      })
    }
  }

  return <div>
    <div className="text-right">
      <Tooltip title="Reload Sign Off">
        <IconButton onClick={() => props.onRefresh()} className={`${jobStyles.iconButton} ${jobStyles.greenButton} mr-2`} style={{ height: "auto" }}>
          <Icon type="sync" style={{ fontSize: "12px", color: "#fff" }} />
        </IconButton>
      </Tooltip>
      <button className={`${jobStyles.PrimaryButton}`} onClick={() => handleEmailClick()}>Email Sign Off Link To Client</button>
    </div>
    <div className="pt-2">
      <div class={jobStyles.card}>
        <table class="table table-sm cs-table-job-board cs-table-job-board-v2">
          <thead>
            <tr>
              <th width="50" class="text-center align-middle">
                <Checkbox
                  className="cs-select-v2"
                  checked={tasks.length === selectedTasks.length && tasks.length !== 0}
                  onChange={(e) => handleAllSelect(e.target.checked)}
                  disabled={tasks.length === 0}
                />
              </th>
              <th>Task ID / Description</th>
              <th width="140">Status</th>
              <th width="180">Sign Off Records</th>
              <th width="140" className="text-center">Signature</th>
            </tr>
          </thead>
          <tbody class="table-body">
            {tasks.map((task, i) =>
              <SignOffTask
                selected={selectedTasks.some(stask => stask.task_label === task.task_label)}
                onChange={(e) => { handleTaskSelect(e.target.checked, task) }} task={task} key={"task_" + i} />)}
          </tbody>
        </table>
        <div style={{ height: '4px' }}>
          {loading &&
            <LinearProgress />
          }
        </div>
      </div>
    </div>
    {showEmailSignOff &&
      <EmailSignOff
        jobDetails={jobHeader}
        tasks={selectedTasks}
        onCancel={() => { setShowEmailSignOff(false); setSelectedTasks([]); }}
      />
    }
  </div>
}