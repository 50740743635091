import React, { useState } from "react";
import { Field, FormSection, reduxForm } from "redux-form";
import { CircularProgress } from "@mui/material";
import { Collapse, Icon, notification } from "antd";
import { useDispatch } from "react-redux";

import JobSwmsDetailsSection from "./JobSwmsDetailsSection";
import ReviewsSection from "./ReviewsSection";
import TaskDetailsSection from "./TaskDetailsSection";
import HighRisksSection from "./HighRisksSection";
import PermitsSection from "./PermitsSection";
import PlantEquipmentSection from "./PlantEquipmentsSection";
import MaterialsSection from "./MaterialsSection";
import ToolsSection from "./ToolsSection";
import PPESection from "./PPESection";
import TrainingLicensesCompetenciesSection from "./TrainingLicensesCompetenciesSection";
import LegislationStandardCOPSection from "./LegislationStandardCOPSection";
import RiskMatrixSection from "./RiskMatrixSection";
import KeyContactsSection from "./KeyContactsSection";
import SwmsConsultationsSection from "./SwmsConsultationsSection";

import { saveSwmsForm } from "../../../../../actions/v2/JobSwmsActions";
import { Strings } from "../../../../../dataProvider/localize";
import styles from "../../JobStyle.module.css";
import { CustomSwitch } from "../../../../common/customSwitch";
import JSASection from "./JSASection";
import SdsSection from "./SdsSection";

const { Panel } = Collapse;

const formatSection = (values, sectionName, arrayName = "data") => {
  let selected = {};
  try {
    if (values[sectionName]) {
      if (values[sectionName].selected) {
        let arr = [...values[sectionName].selected];
        let allEnabled = [];
        if (values[sectionName].enabled) {
          for (let key in values[sectionName].enabled) {
            if (values[sectionName].enabled[key] === true) {
              allEnabled.push(key.slice(key.indexOf("_") + 1).toString());
            }
          }
        }
        // console.log(sectionName, allEnabled, selected[arrayName]);
        selected[arrayName] = arr.map(({ label, value, ...x }) => ({ ...x, selected: allEnabled.indexOf(x.id.toString()) !== -1 ? 1 : 0 }))
        selected.toggle = values[sectionName].toggle && values[sectionName].toggle === true ? 1 : 0
      }
    }
  } catch (err) {
    console.log(err);
  }
  return selected;
}

const JobSwmsForm = ({ handleSubmit, onClose, id, swms_data, swms_revision, highRiskData, change, likelihoods, consequences, risks, swms_number = "" }) => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onSubmit = (values) => {
    setLoading(true);

    let uploading = 0;
    let selected_permit_data = {};
    let enabledHRS = [];
    let hrsData = {};
    if (values.high_risk_section.enabled) {
      for (let key in values.high_risk_section.enabled) {
        if (values.high_risk_section.enabled[key] === true) {
          enabledHRS.push(key.slice(key.indexOf("_") + 1));
        }
      }
    }
    if (values.high_risk_section.data) {
      for (let key in values.high_risk_section.data) {
        hrsData[key] = values.high_risk_section.data[key].map(x => ({
          ...x,
          selected: enabledHRS.findIndex(y => y.toString() === x.id.toString()) !== -1 ? 1 : 0
        }))
      }
    }

    let allPerms = [];
    if (values.permits_section && values.permits_section.permits) {
      values.permits_section.permits.forEach(permit => {
        let permObj = {};
        if (permit.permit_name) {
          permObj.permit_name = permit.permit_name
        }
        if (permit.permit_document) {
          if (permit.permit_document.isUploaded) {

            permObj.permit_document = {
              file_name: permit.permit_document.file_name,
              visible_name: permit.permit_document.visible_name
            }
          } else {
            uploading++;
          }
        }
        if (Object.keys(permObj).length > 0) {
          allPerms.push(permObj);
        }
      })
      selected_permit_data.permits = allPerms;
      if (values.permits_section.toggle === true) {
        selected_permit_data.toggle = 1;
      } else {
        selected_permit_data.toggle = 0;
      }
    } else {
      selected_permit_data.toggle = 0;
    }

    let selected_ppe_section = formatSection(values, "ppe_section", "ppe_items");
    let selected_tools_section = formatSection(values, "tools_section", "tool_items");
    let selected_pe_section = formatSection(values, "plant_equipment_section", "plant_and_equipment_items");
    let selected_tlc_section = formatSection(values, "tlc_section", "training_and_license_items");
    let selected_lsc_section = formatSection(values, "lsc_section", "legislation_safety_acts_and_standard_items");
    let selected_materials_sec = formatSection(values, "materials_section", "material_items");
    let selected_sds = formatSection(values, "sds_section", "sds_items");

    let consulations = {};
    if (values.swms_consultation_section) {
      if (values.swms_consultation_section.swms_consultation_items) {
        consulations.swms_consultation_items = values.swms_consultation_section.swms_consultation_items;
      }
      if (values.swms_consultation_section.hasOwnProperty("toggle")) {
        consulations.toggle = values.swms_consultation_section.toggle === true ? 1 : 0
      }
    }
    let jsa_data = values.jsa_section && values.jsa_section.activities ? values.jsa_section.activities : [];

    let taskDetails = {};
    if (values.task_details_section) {
      taskDetails = { ...values.task_details_section };
      let allDocs = [];
      if (values.task_details_section.task_documents_images && values.task_details_section.task_documents_images.length > 0) {
        values.task_details_section.task_documents_images.forEach(doc => {
          if (doc.file_name && doc.file_name.isUploaded) {
            allDocs.push({
              file_name: doc.file_name.file_name,
              visible_name: doc.file_name.visible_name
            })
          } else {
            uploading++;
          }
        })
      }
      taskDetails = { ...taskDetails, task_documents_images: [...allDocs] }
    }

    let formData = {
      swms_form_id: id, swms_data: {
        ...swms_data,
        key_contacts: { ...values.key_contacts_section, toggle: values.key_contacts_section.toggle === true ? 1 : 0 },
        ppe_details: selected_ppe_section,
        tool_details: selected_tools_section,
        plant_and_equipment_details: selected_pe_section,
        permits_section: selected_permit_data,
        training_and_licenses_details: selected_tlc_section,
        legislation_safety_acts_and_standards_details: selected_lsc_section,
        material_details: selected_materials_sec,
        sds_details: selected_sds,
        swms_consultations: { ...consulations },
        task_details: { ...values.task_details_section },
        activities: { swms_activities: [...jsa_data], toggle: values.jsa_section.toggle && values.jsa_section.toggle === true ? 1 : 0 },
        swms_revisions_and_approvals: { ...values.swms_review_section, toggle: values.swms_review_section && values.swms_review_section.toggle === true ? 1 : 0 },
        risk_matrix_section: { toggle: values.risk_matrix_section && values.risk_matrix_section.toggle === true ? 1 : 0 },
        high_risk_activities: { high_risk_activities_item: { ...hrsData }, toggle: values.high_risk_section && values.high_risk_section.toggle === true ? 1 : 0 },
        task_details: { ...taskDetails },
        risk_ratings: { ...values.risk_ratings, ...(risks && risks.length > 0 ? { risk_rating_items: [...risks] } : {}) },
        ...(consequences && consequences.length > 0 ? { consequences: [...consequences] } : {}),
        ...(likelihoods && likelihoods.length > 0 ? { likelihoods: [...likelihoods] } : {})
      }
    };
    if (uploading > 0) {
      notification.error({
        message: Strings.error_title,
        description: "One or more files are still uploading or they failed to upload.",
        className: "ant-error"
      });
      setLoading(false);
    } else {
      dispatch(saveSwmsForm(formData)).then(message => {
        setLoading(false);
        notification.success({
          message: Strings.success_title,
          message: message,
          className: "ant-success"
        });
        onClose();
      }).catch(err => {
        if (err) {
          setLoading(false);
          notification.error({
            message: Strings.error_title,
            description: err ? err : Strings.generic_error,
            className: "ant-error"
          });
        }
      })
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container-fluid" style={{ height: "calc(100vh - 380px)", overflow: "hidden auto" }}>
          <div className="row">
            <div className="col-12 pb-3">
              <div className="mb-2">
                <div className={styles.TaskListContainer}>
                  <FormSection
                    component={JobSwmsDetailsSection}
                    name="details_section"
                    loading={loading}
                    swmsVersion={swms_revision}
                    swmsNumber={swms_number}
                    clientDetails={swms_data && swms_data.client_details ? swms_data.client_details : {}}
                    siteDetails={swms_data && swms_data.site_details ? swms_data.site_details : {}}
                  />
                </div>
              </div>
              <Collapse
                accordion
                bordered={false}
                expandIconPosition="right"
                style={{ marginTop: "10px" }}
                className="custom-section-v2-collapse custom-job-swms-collapse"
                expandIcon={({ isActive }) => (
                  <Icon type="caret-right" rotate={isActive ? 90 : 0} style={{ color: "#2f1f41" }} />)
                }>
                <Panel
                  key={1}
                  className="custom-job-swms-collapse-panel"
                  style={{ border: '0px !important' }}
                  header={
                    <div className={`${styles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>SWMS Revisions & Approvals
                      <div onClick={e => { e.preventDefault(); e.stopPropagation(); }} className="float-right position-relative" style={{ right: "30px" }}>
                        <Field name="swms_review_section.toggle" size="small" className="cs-switch-v3" component={CustomSwitch} disabled={loading} />
                      </div>
                    </div>
                  }>
                  <FormSection
                    component={ReviewsSection}
                    revisionsData={swms_data && swms_data.swms_revisions_and_approvals && swms_data.swms_revisions_and_approvals.revision_and_approval_items ? swms_data.swms_revisions_and_approvals.revision_and_approval_items : []}
                    name="swms_review_section"
                    loading={loading}
                  />
                </Panel>
                <Panel
                  key={2}
                  className="custom-job-swms-collapse-panel"
                  style={{ border: '0px !important' }}
                  header={
                    <div className={`${styles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>Task Details</div>
                  }>
                  <FormSection
                    component={TaskDetailsSection}
                    taskDetails={swms_data && swms_data.task_details ? swms_data.task_details : {}}
                    name="task_details_section"
                    loading={loading} />
                </Panel>
                <Panel
                  key={3}
                  className="custom-job-swms-collapse-panel"
                  style={{ border: '0px !important' }}
                  header={
                    <div className={`${styles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>High Risk Activity</div>
                  }>
                  <FormSection component={HighRisksSection} highRiskData={highRiskData} name="high_risk_section" loading={loading} />
                </Panel>
                <Panel
                  key={4}
                  className="custom-job-swms-collapse-panel"
                  style={{ border: '0px !important' }}
                  header={
                    <div className={`${styles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>Permit Required
                      <div onClick={e => { e.preventDefault(); e.stopPropagation(); }} className="float-right position-relative" style={{ right: "30px" }}>
                        <Field name="permits_section.toggle" size="small" className="cs-switch-v3" component={CustomSwitch} disabled={loading} />
                      </div>
                    </div>
                  }>
                  <FormSection component={PermitsSection} name="permits_section" loading={loading} />

                </Panel>
                <Panel
                  key={5}
                  className="custom-job-swms-collapse-panel"
                  style={{ border: '0px !important' }}
                  header={
                    <div className={`${styles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>Plant Equipment
                      <div onClick={e => { e.preventDefault(); e.stopPropagation(); }} className="float-right position-relative" style={{ right: "30px" }}>
                        <Field name="plant_equipment_section.toggle" size="small" className="cs-switch-v3" component={CustomSwitch} disabled={loading} />
                      </div>
                    </div>
                  }>
                  <FormSection component={PlantEquipmentSection} name="plant_equipment_section" change={change} loading={loading} />
                </Panel>
                <Panel
                  key={6}
                  className="custom-job-swms-collapse-panel"
                  style={{ border: '0px !important' }}
                  header={
                    <div className={`${styles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>Materials Required
                      <div onClick={e => { e.preventDefault(); e.stopPropagation(); }} className="float-right position-relative" style={{ right: "30px" }}>
                        <Field name="materials_section.toggle" size="small" className="cs-switch-v3" component={CustomSwitch} disabled={loading} />
                      </div>
                    </div>
                  }>
                  <FormSection component={MaterialsSection} name="materials_section" change={change} loading={loading} />
                </Panel>
                <Panel
                  key={19}
                  className="custom-job-swms-collapse-panel"
                  style={{ border: '0px !important' }}
                  header={
                    <div className={`${styles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>Safety Data Sheets
                      <div onClick={e => { e.preventDefault(); e.stopPropagation(); }} className="float-right position-relative" style={{ right: "30px" }}>
                        <Field name="sds_section.toggle" size="small" className="cs-switch-v3" component={CustomSwitch} disabled={loading} />
                      </div>
                    </div>
                  }>
                  <FormSection component={SdsSection} name="sds_section" change={change} loading={loading} />
                </Panel>
                <Panel
                  key={7}
                  className="custom-job-swms-collapse-panel"
                  style={{ border: '0px !important' }}
                  header={
                    <div className={`${styles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>Tools
                      <div onClick={e => { e.preventDefault(); e.stopPropagation(); }} className="float-right position-relative" style={{ right: "30px" }}>
                        <Field name="tools_section.toggle" size="small" className="cs-switch-v3" component={CustomSwitch} disabled={loading} />
                      </div>
                    </div>
                  }>
                  <FormSection component={ToolsSection} name="tools_section" change={change} loading={loading} />
                </Panel>
                <Panel
                  key={8}
                  className="custom-job-swms-collapse-panel"
                  style={{ border: '0px !important' }}
                  header={
                    <div className={`${styles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>PPE Requirements
                      <div onClick={e => { e.preventDefault(); e.stopPropagation(); }} className="float-right position-relative" style={{ right: "30px" }}>
                        <Field name="ppe_section.toggle" size="small" className="cs-switch-v3" component={CustomSwitch} disabled={loading} />
                      </div>
                    </div>
                  }>
                  <FormSection component={PPESection} name="ppe_section" change={change} loading={loading} />
                </Panel>
                <Panel
                  key={9}
                  className="custom-job-swms-collapse-panel"
                  style={{ border: '0px !important' }}
                  header={
                    <div className={`${styles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>Training, Licenses & Competencies
                      <div onClick={e => { e.preventDefault(); e.stopPropagation(); }} className="float-right position-relative" style={{ right: "30px" }}>
                        <Field name="tlc_section.toggle" size="small" className="cs-switch-v3" component={CustomSwitch} disabled={loading} />
                      </div>
                    </div>
                  }>
                  <FormSection component={TrainingLicensesCompetenciesSection} name="tlc_section" change={change} loading={loading} />
                </Panel>
                <Panel
                  key={10}
                  className="custom-job-swms-collapse-panel"
                  style={{ border: '0px !important' }}
                  header={
                    <div className={`${styles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>Legislation / Regulations / Standards & COP
                      <div onClick={e => { e.preventDefault(); e.stopPropagation(); }} className="float-right position-relative" style={{ right: "30px" }}>
                        <Field name="lsc_section.toggle" size="small" className="cs-switch-v3" component={CustomSwitch} disabled={loading} />
                      </div>
                    </div>
                  }>
                  <FormSection component={LegislationStandardCOPSection} name="lsc_section" change={change} loading={loading} />
                </Panel>
                <Panel
                  key={11}
                  className="custom-job-swms-collapse-panel"
                  style={{ border: '0px !important' }}
                  header={
                    <div className={`${styles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>Risk Assessment Matrix
                      <div onClick={e => { e.preventDefault(); e.stopPropagation(); }} className="float-right position-relative" style={{ right: "30px" }}>
                        <Field name="risk_matrix_section.toggle" size="small" className="cs-switch-v3" component={CustomSwitch} disabled={loading} />
                      </div>
                    </div>
                  }>
                  <div className="p-1 py-2">
                    <FormSection likelihoods={likelihoods} risks={risks} consequences={consequences} component={RiskMatrixSection} name="risk_matrix_section" />
                  </div>
                </Panel>
                <Panel
                  key={12}
                  className="custom-job-swms-collapse-panel"
                  style={{ border: '0px !important' }}
                  header={
                    <div className={`${styles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>Job Safety Analysis (JSA) and Safe Work Method Statement SWMS</div>
                  }>
                  <FormSection component={JSASection} name="jsa_section" change={change} loading={loading} />
                </Panel>
                <Panel
                  key={13}
                  className="custom-job-swms-collapse-panel"
                  style={{ border: '0px !important' }}
                  header={
                    <div className={`${styles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>Key Contacts
                      <div onClick={e => { e.preventDefault(); e.stopPropagation(); }} className="float-right position-relative" style={{ right: "30px" }}>
                        <Field name="key_contacts_section.toggle" size="small" className="cs-switch-v3" component={CustomSwitch} disabled={loading} />
                      </div>
                    </div>
                  }>
                  <FormSection component={KeyContactsSection} name="key_contacts_section" loading={loading} />
                </Panel>
                <Panel
                  key={14}
                  className="custom-job-swms-collapse-panel"
                  style={{ border: '0px !important' }}
                  header={
                    <div className={`${styles.BackgroundSectionHeading} custom-section-v2-panel-heading`}>SWMS Consultation & Communication
                      <div onClick={e => { e.preventDefault(); e.stopPropagation(); }} className="float-right position-relative" style={{ right: "30px" }}>
                        <Field name="swms_consultation_section.toggle" size="small" className="cs-switch-v3" component={CustomSwitch} disabled={loading} />
                      </div>
                    </div>
                  }>
                  <FormSection component={SwmsConsultationsSection} name="swms_consultation_section" change={change} loading={loading} />
                </Panel>
              </Collapse>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-end" style={{ gap: "10px" }}>
              {loading ? (
                <CircularProgress size={20} />) : ""}
              <button className={styles.OutlinedButton} type="button" onClick={() => onClose()}>Cancel</button>
              <button disabled={loading} className={styles.PrimaryButton} type="submit">Save SWMS</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default reduxForm({
  form: "job_swms_form",
  enableReinitialize: true,
  destroyOnUnmount: true
})(JobSwmsForm)