import React from 'react';
import { connect } from 'react-redux';
import { Icon, notification, Pagination } from 'antd';
import { goBack, handleFocus } from '../../../utils/common';
import { Strings } from '../../../dataProvider/localize';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { customInput } from '../../common/custom-input';
import { Field, reduxForm, autofill, reset } from 'redux-form';
import { validate, isRequired } from '../../../utils/Validations/payRatesValidations';
import $ from "jquery";
import * as payRateAction from '../../../actions/payRateAction';
import NoDataImg from '../../../images/no-data.png';
import * as jobAction from "../../../actions/jobAction";

const mapRouteToTitle = {
  '/dashboard/payRates': Strings.pay_rates_title
}

const columns = [
  {
    title: 'Contract Type',
    key: '1',
    asPer: '',
  },
  {
    title: 'Hourly Pay Rate (Ordinary hours)',
    key: '2',
    asPer: 'Per Hr'
  },
  {
    title: 'Early Morning, afternoon & non permanent night shift Mon - Fri',
    key: '3',
    asPer: 'Per Hr'

  },
  {
    title: 'Night shift Permanent Mon - Fri',
    key: '4',
    asPer: 'Per Hr'
  },
  {
    title: 'Saturday',
    key: '5',
    asPer: 'Per Hr'
  },
  {
    title: 'Sunday',
    key: '6',
    asPer: 'Per Hr'
  },
  {
    title: 'Public Holiday',
    key: '7',
    asPer: 'Per Hr'
  },
  {
    title: 'Overtime Mon to Sat (first 2 hours)',
    key: '8',
    asPer: 'Per Hr'
  },
  {
    title: 'Overtime Mon to Sat (after 2 hours)',
    key: '9',
    asPer: 'Per Hr'
  },
  {
    title: 'Overtime Sunday',
    key: '10',
    asPer: 'Per Hr'
  },
  {
    title: 'Overtime Public Holiday',
    key: '11',
    asPer: 'Per Hr'
  },
  {
    title: 'EWP Allowance',
    key: '12',
    asPer: 'Per Hr'
  },
  {
    title: 'WAH Allowance',
    key: '13',
    asPer: 'Per Hr'
  },
  {
    title: 'Height allowance cleaning up to 22 floor',
    key: '14',
    asPer: 'Per Hr'
  },
  {
    title: 'Height allowance cleaning above to 22 floor',
    key: '15',
    asPer: 'Per Hr'
  },
  {
    title: 'Cold places allowance',
    key: '16',
    asPer: 'Per Hr'
  },
  {
    title: 'Hot places allowance between 46 & 54 degrees',
    key: '17',
    asPer: 'Per Hr'
  },
  {
    title: 'Hot places allowance exceeding 54 degrees',
    key: '18',
    asPer: 'Per Hr'
  },
  {
    title: 'Broken shift allowance (shift)',
    key: '19',
    asPer: 'Shift'
  },
  {
    title: 'First aid allowance',
    key: '20',
    asPer: 'Shift'
  },
  {
    title: 'Leading hand allowance 1 to 10 employees',
    key: '21',
    asPer: 'Shift'
  },
  {
    title: 'Leading hand allowance 11 to 20 employees',
    key: '22',
    asPer: 'Shift'
  },
  {
    title: 'Leading hand allowance over 20 employees',
    key: '23',
    asPer: 'Shift'
  },
  {
    title: 'Refuse collection allowance',
    key: '24',
    asPer: 'Shift'
  },
  {
    title: 'Toilet cleaning allowance',
    key: '25',
    asPer: 'Shift'
  },
  {
    title: 'Meal allowance',
    key: '26',
    asPer: 'Units'
  },
  {
    title: 'Vehicle allowance motor vehicle',
    key: '27',
    asPer: 'Per Km'
  },
  {
    title: 'Vehicle allowance motor cycle',
    key: '28',
    asPer: 'Per Km'
  },
];

class PayRate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowIndex: -1,
      isNewAdd: false,
      page_no: 1
    }
  }


  componentDidUpdate() {

  }

  componentDidMount() {
    this.props.payRateAction.getBroadcastMsg().subscribe(data => {
      if (data && data.type == 'leftmenu') {
        setTimeout(() => {
          this.adjustSize()
        }, 1000);
      }
    });
    setTimeout(() => {
      this.adjustSize()
    }, 1000);
    let obj = { page_no: 1 }
    this.props.payRateAction.getPayrateList(obj);
  }

  adjustSize = () => {
    let minusWidth = 60;
    let { isNewAdd } = this.state;
    minusWidth = isNewAdd ? 60 : minusWidth
    $(document).ready(function () {
      let adjust_size = function () {
        let $windowWidth = $(window).width();
        let $leftSliderWidths = $('.ant-layout-sider').width();
        let $winWleflSlW = $windowWidth - ($leftSliderWidths && $leftSliderWidths > 0 ? $leftSliderWidths : 0);

        $('.swms-big-tb-scroll').css('width', ($winWleflSlW - minusWidth));
      };
      adjust_size();
      $(window).resize(adjust_size);
    });
  }


  editRow = (index, data) => {
    let { fillForm } = this.props;
    console.log(data, 'datadata')
    this.setState({ selectedRowIndex: index, isNewAdd: false });
    fillForm(data)
  };

  cancelRow = (index, data) => {
    this.setState({ selectedRowIndex: -1, isNewAdd: false });
  };

  addNew = () => {
    let { resetForm } = this.props;
    this.setState({ isNewAdd: true, selectedRowIndex: -1 });
    resetForm();
  }

  onSubmit = async (formData) => {
    console.log(formData, 'formData========F')
    let { selectedRowIndex, page_no } = this.state;
    let { resetForm, payRateAction } = this.props;
    formData['page_no'] = page_no;
    payRateAction.addNewPayRate(formData).then(response => {
      console.log(response, '0000000000000000')
      if (response.status == -1) {
        notification.error({
          message: Strings.error_title,
          description: "Allready Exist",
          onClick: () => { },
          className: 'ant-success'
        })
        return;
      }

      if (response.status == 1) {
        notification.success({
          message: Strings.success_title,
          description: selectedRowIndex === -1 ? "PayRate Added Successfully" : "PayRate Uodated Successfully",
          onClick: () => { },
          className: 'ant-success'
        })
      }
      resetForm();
      this.setState({ isNewAdd: false, selectedRowIndex: -1 });
    }).catch(err => {
      notification.error({
        message: Strings.error_title,
        description: err ? err : Strings.generic_error,
        onClick: () => { },
        className: 'ant-error'
      })
    })

  }



  renderScheduleMembers = () => {
    let { isNewAdd } = this.state;
    return (<>
      <div className="main-filelds-frms row" key={0}>
        <h3>{isNewAdd ? 'Add New ' : 'Update '}Pay Rate</h3>
        <fieldset className="sf-form">
          <Field name={`contract_type`} type="text" label={Strings.contract_typeLabel} validate={isRequired} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`hourly_pay_rate_ordinary`} type="text" label={Strings.hourly_pay_rate_ordinaryLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`early_morning_afternoon_non_permanent_night_shift`} type="text" label={Strings.early_morning_afternoon_non_permanent_night_shiftLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`night_shift_permanent`} type="text" label={Strings.night_shift_permanentLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`saturday`} type="text" label={Strings.saturdayLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`sunday`} type="text" label={Strings.sundayLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`public_holiday`} type="text" label={Strings.public_holidayLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`overtime_monday_to_saturday_first_2hours`} type="text" label={Strings.overtime_monday_to_saturday_first_2hoursLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`overtime_monday_to_saturday_after_2hours`} type="text" label={Strings.overtime_monday_to_saturday_after_2hoursLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`overtime_sunday`} type="text" label={Strings.overtime_sundayLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`overtime_public_holiday`} type="text" label={Strings.overtime_public_holidayLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`ewp_allowance`} type="text" label={Strings.ewp_allowanceLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`wah_allowance`} type="text" label={Strings.wah_allowanceLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`height_allowance_cleaning_upto_22floor`} type="text" label={Strings.height_allowance_cleaning_upto_22floorLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`height_allowance_cleaning_above_22floor`} type="text" label={Strings.height_allowance_cleaning_above_22floorLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`cold_places_allowance`} type="text" label={Strings.cold_places_allowanceLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`hotPlaces_allowance_between_46and54_degrees`} type="text" label={Strings.hotPlaces_allowance_between_46and54_degreesLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`hot_places_allowance_exceeding_54degrees`} type="text" label={Strings.hot_places_allowance_exceeding_54degreesLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`broken_shift_allowance`} type="text" label={Strings.broken_shift_allowanceLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`first_aid_allowance`} type="text" label={Strings.first_aid_allowanceLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`leading_hand_allowance_1to10_employees`} type="text" label={Strings.leading_hand_allowance_1to10_employeesLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`leading_hand_allowance_11to20_employees`} type="text" label={Strings.leading_hand_allowance_11to20_employeesLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`leading_hand_allowance_over_20_employees`} type="text" label={Strings.leading_hand_allowance_over_20_employeesLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`refuse_collection_allowance`} type="text" label={Strings.refuse_collection_allowanceLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`toilet_cleaning_allowance`} type="text" label={Strings.toilet_cleaning_allowanceLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`meal_allowance`} type="text" label={Strings.meal_allowanceLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`vehicle_allowance_motor_vehicle`} type="text" label={Strings.vehicle_allowance_motor_vehicleLabel} component={customInput}
          />
        </fieldset>

        <fieldset className="sf-form">
          <Field name={`vehicle_allowance_motor_cycle`} type="text" label={Strings.vehicle_allowance_motor_cycleLabel} component={customInput}
          />
        </fieldset>

        <div className="btns-main-flds d-flex mt-3 mb-3">
          <button className='bnt bnt-normal' type='button' onClick={() => this.cancelRow()}>Cancel</button> &nbsp;&nbsp;
          <button className='bnt bnt-active' type="submit">{isNewAdd ? 'Add' : 'Update'}</button>
        </div>
      </div>
    </>)
  }

  handlePageChange = page => {
    this.setState({
      page_no: page
    })
    if (page) {
      this.props.payRateAction.getPayrateList({ page_no: page });
    }
  };

  render() {
    let { selectedRowIndex, isNewAdd, page_no } = this.state;
    const { handleSubmit, payRateList, totalPayRateCount, isGetPayRatelist } = this.props;
    return (
      <>
        <div className="sf-page-layout">
          <div className="dash-header">
            <h2 className="page-mn-hd">
              <Icon type="arrow-left" onClick={() => goBack(this.props)} />
              {mapRouteToTitle[this.props.location.pathname] ? mapRouteToTitle[this.props.location.pathname] : Strings.pay_rates_title}
            </h2>
            <div class="oh-cont">

            </div>
          </div>
          <div className="main-container">
            <div className="sf-card-wrap p-0 swms-big-tb-scroll">
              <div className="p-0">

                <div className="sf-card-body">
                  {/* {!isGetPayRatelist && payRateList && payRateList.length === 0 &&
                    <>
                      <table className="table pay-rate-table">

                        <thead>
                          <tr>  {columns.map((a, index) => <th key={index} style={{}} > {a.title}</th>)}</tr>
                        </thead>
                      </table>
                      <div className='nodata-img'>
                        <img src={NoDataImg} />
                      </div>
                    </>
                  } */}

                  
                    <form className={selectedRowIndex > -1 || isNewAdd ? '' : 'payrate-leftfixed'} onSubmit={handleSubmit(this.onSubmit)}>
                      {selectedRowIndex > -1 && <>{this.renderScheduleMembers()}</>}
                      {isNewAdd &&
                        <>{this.renderScheduleMembers()}</>
                      }

                      {selectedRowIndex === -1 && !isNewAdd &&
                        <table className="table pay-rate-table" >

                          <tr>
                            {columns.map((a, index) => index === 0 ? <th key={index} style={{}} className={'headcol hed-newddsds'} > {a.title}</th> :
                              <td key={index} style={{}}  > {a.title}</td>)}
                          </tr>
                          <tr>
                            {columns.map((a, index) => index === 0 ? <th key={index} className={'headcol'}>{a.asPer}</th> :
                              <td>{a.asPer}</td>)}
                          </tr>

                          {payRateList && payRateList.map((data, index) => <>

                            <>
                              {selectedRowIndex === -1 && !isNewAdd &&
                                <tr style={{}} key={index}>
                                  <th className="headcol">{data.contract_type} &nbsp; <a href="#" onClick={(e) => this.editRow(index, data)}>
                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                  </a></th>
                                  {data.hourly_pay_rate_ordinary ? <td>${data.hourly_pay_rate_ordinary}</td> : <td></td>}
                                  {data.early_morning_afternoon_non_permanent_night_shift ? <td>${data.early_morning_afternoon_non_permanent_night_shift}</td> : <td></td>}
                                  {data.night_shift_permanent ? <td>${data.night_shift_permanent}</td> : <td></td>}
                                  {data.saturday ? <td>${data.saturday}</td> : <td></td>}
                                  {data.sunday ? <td>${data.sunday}</td> : <td></td>}
                                  {data.public_holiday ? <td>${data.public_holiday}</td> : <td></td>}
                                  {data.overtime_monday_to_saturday_first_2hours ? <td>${data.overtime_monday_to_saturday_first_2hours}</td> : <td></td>}
                                  {data.overtime_monday_to_saturday_after_2hours ? <td>${data.overtime_monday_to_saturday_after_2hours}</td> : <td></td>}
                                  {data.overtime_sunday ? <td>${data.overtime_sunday}</td> : <td></td>}
                                  {data.overtime_public_holiday ? <td>${data.overtime_public_holiday}</td> : <td></td>}
                                  {data.ewp_allowance ? <td>${data.ewp_allowance}</td> : <td></td>}
                                  {data.wah_allowance ? <td>${data.wah_allowance}</td> : <td></td>}
                                  {data.height_allowance_cleaning_upto_22floor ? <td>${data.height_allowance_cleaning_upto_22floor}</td> : <td></td>}
                                  {data.height_allowance_cleaning_above_22floor ? <td>${data.height_allowance_cleaning_above_22floor}</td> : <td></td>}
                                  {data.cold_places_allowance ? <td>${data.cold_places_allowance}</td> : <td></td>}
                                  {data.hotPlaces_allowance_between_46and54_degrees ? <td>${data.hotPlaces_allowance_between_46and54_degrees}</td> : <td></td>}
                                  {data.hot_places_allowance_exceeding_54degrees ? <td>${data.hot_places_allowance_exceeding_54degrees}</td> : <td></td>}
                                  {data.broken_shift_allowance ? <td>${data.broken_shift_allowance}</td> : <td></td>}
                                  {data.first_aid_allowance ? <td>${data.first_aid_allowance}</td> : <td></td>}
                                  {data.leading_hand_allowance_1to10_employees ? <td>${data.leading_hand_allowance_1to10_employees}</td> : <td></td>}
                                  {data.leading_hand_allowance_11to20_employees ? <td>${data.leading_hand_allowance_11to20_employees}</td> : <td></td>}
                                  {data.leading_hand_allowance_over_20_employees ? <td>${data.leading_hand_allowance_over_20_employees}</td> : <td></td>}
                                  {data.refuse_collection_allowance ? <td>${data.refuse_collection_allowance}</td> : <td></td>}
                                  {data.toilet_cleaning_allowance ? <td>${data.toilet_cleaning_allowance}</td> : <td></td>}
                                  {data.meal_allowance ? <td>${data.meal_allowance}</td> : <td></td>}
                                  {data.vehicle_allowance_motor_vehicle ? <td>${data.vehicle_allowance_motor_vehicle}</td> : <td></td>}
                                  {data.vehicle_allowance_motor_cycle ? <td>${data.vehicle_allowance_motor_cycle}</td> : <td></td>}
                                </tr>
                              }
                            </>

                          </>)}
                          <tr>
                            {selectedRowIndex == -1 && !isNewAdd &&
                              <th className="btn-headcol">
                                <button className='bnt bnt-normal payrate-addbtn' type='button' onClick={() => this.addNew()}>
                                  <i class="fa fa-plus" aria-hidden="true"></i> &nbsp;  Add Record</button>

                              </th>

                            }
                          </tr>


                        </table>

                      }
                    </form>

                  
                </div>
              </div>
            </div>

            {!isGetPayRatelist && totalPayRateCount && totalPayRateCount > 10 ?
              <Pagination className="sf-pagination"
                defaultCurrent={page_no}
                defaultPageSize={10}
                total={totalPayRateCount}
                onChange={this.handlePageChange}
              />
              : ''}
          </div>

        </div>


      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    payRateList: state.payRateReducer.payRateList,
    totalPayRateCount: state.payRateReducer.totalPayRateCount,
    isGetPayRatelist: state.payRateReducer.isGetPayRatelist,
  }
}

const mapDispatchToprops = dispatch => {
  return {
    fillForm: (data) => {
      dispatch(autofill("PayRate", `id`, data.id ? data.id : 0));
      dispatch(autofill("PayRate", `contract_type`, data.contract_type));
      dispatch(autofill("PayRate", `hourly_pay_rate_ordinary`, data.hourly_pay_rate_ordinary));
      dispatch(autofill("PayRate", `early_morning_afternoon_non_permanent_night_shift`, data.early_morning_afternoon_non_permanent_night_shift));
      dispatch(autofill("PayRate", `night_shift_permanent`, data.night_shift_permanent));
      dispatch(autofill("PayRate", `saturday`, data.saturday));
      dispatch(autofill("PayRate", `sunday`, data.sunday));
      dispatch(autofill("PayRate", `public_holiday`, data.public_holiday));
      dispatch(autofill("PayRate", `overtime_monday_to_saturday_first_2hours`, data.overtime_monday_to_saturday_first_2hours));
      dispatch(autofill("PayRate", `overtime_monday_to_saturday_after_2hours`, data.overtime_monday_to_saturday_after_2hours));
      dispatch(autofill("PayRate", `overtime_sunday`, data.overtime_sunday));
      dispatch(autofill("PayRate", `overtime_public_holiday`, data.overtime_public_holiday));
      dispatch(autofill("PayRate", `ewp_allowance`, data.ewp_allowance));
      dispatch(autofill("PayRate", `wah_allowance`, data.wah_allowance));
      dispatch(autofill("PayRate", `height_allowance_cleaning_upto_22floor`, data.height_allowance_cleaning_upto_22floor));
      dispatch(autofill("PayRate", `height_allowance_cleaning_above_22floor`, data.height_allowance_cleaning_above_22floor));
      dispatch(autofill("PayRate", `cold_places_allowance`, data.cold_places_allowance));
      dispatch(autofill("PayRate", `hotPlaces_allowance_between_46and54_degrees`, data.hotPlaces_allowance_between_46and54_degrees));
      dispatch(autofill("PayRate", `hot_places_allowance_exceeding_54degrees`, data.hot_places_allowance_exceeding_54degrees));
      dispatch(autofill("PayRate", `broken_shift_allowance`, data.broken_shift_allowance));
      dispatch(autofill("PayRate", `first_aid_allowance`, data.first_aid_allowance));
      dispatch(autofill("PayRate", `leading_hand_allowance_1to10_employees`, data.leading_hand_allowance_1to10_employees));
      dispatch(autofill("PayRate", `leading_hand_allowance_11to20_employees`, data.leading_hand_allowance_11to20_employees));
      dispatch(autofill("PayRate", `leading_hand_allowance_over_20_employees`, data.leading_hand_allowance_over_20_employees));
      dispatch(autofill("PayRate", `refuse_collection_allowance`, data.refuse_collection_allowance));
      dispatch(autofill("PayRate", `toilet_cleaning_allowance`, data.toilet_cleaning_allowance));
      dispatch(autofill("PayRate", `meal_allowance`, data.meal_allowance));
      dispatch(autofill("PayRate", `vehicle_allowance_motor_vehicle`, data.vehicle_allowance_motor_vehicle));
      dispatch(autofill("PayRate", `vehicle_allowance_motor_cycle`, data.vehicle_allowance_motor_cycle));
    },
    resetForm: () => dispatch(reset('PayRate')),
    payRateAction: bindActionCreators(payRateAction, dispatch),
    jobAction: bindActionCreators(jobAction, dispatch),

  }

}

export default compose(
  connect(mapStateToProps, mapDispatchToprops),
  withRouter,
  reduxForm({
    form: 'PayRate', validate,
    onSubmitFail: (errors, dispatch, sub, props) => {
      handleFocus(errors, "#");
    },
    enableReinitialize: true,
  })
)(PayRate)