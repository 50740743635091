import { splitDateTime } from "./utils";
import moment from "moment";

export const serializeTimesheetData = (data) => {
  return data.map((dataItem) => ({
    ...dataItem,
    start_date: dataItem.start_time
      ? splitDateTime(dataItem.start_time, "DATE")
      : null,
    start_time: dataItem.start_time
      ? splitDateTime(dataItem.start_time, "TIME")
      : null,
    finish_date: dataItem.finish_time
      ? splitDateTime(dataItem.finish_time, "DATE")
      : null,
    finish_time: dataItem.finish_time
      ? splitDateTime(dataItem.finish_time, "TIME")
      : null,
  }));
};

export const serializeParams = ({
  start_date,
  finish_date,
  staff_user,
  user_role,
  state,
  jobId,
  siteIds,
  selectedStaffUsers,
  managers,
  currentPage,
  client,
  type,
  payClassifications
}) => {
  const twoWeeksAgo = new Date();
  twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

  const serializedParams = {
    ...(start_date
      ? {
        pay_period_from_date: moment(start_date).format("YYYY-MM-DD"),
      }
      : { pay_period_from_date: moment(twoWeeksAgo).format("YYYY-MM-DD") }),
    ...(finish_date
      ? {
        pay_period_to_date: moment(finish_date).format("YYYY-MM-DD"),
      }
      : { pay_period_to_date: moment(new Date()).format("YYYY-MM-DD") }),
    ...(staff_user && staff_user.length
      ? {
        staff_user_name: staff_user.map((item) => item.value.toString()),
      }
      : {}),
    ...(user_role && user_role.length
      ? {
        role_id: user_role.map((item) => item.value.toString()),
      }
      : {}),
    ...(state
      ? {
        state_id: state.value,
      }
      : {}),
    ...(managers && managers.length > 0 ? {
      allocated_manager: managers.map(item => item.value.toString())
    } : {}),
    ...(selectedStaffUsers && selectedStaffUsers.length > 0
      ? {
        selected_job_shift_timesheet_ids: selectedStaffUsers.map((item) =>
          item.toString()
        ),
      }
      : {}),
    ...(currentPage ? { page: currentPage } : {}),
    ...(client && client.length ? { client_id: client.map((item) => item.value.toString()) } : {}),
    ...(type ? { csv_type: type } : {}),
    ...(jobId ? { job_label: jobId } : {}),
    ...(siteIds && siteIds.length ? { site_ids: siteIds.map(s => s.value) } : {}),
    ...(payClassifications && payClassifications.length ? { pay_classification_id: payClassifications.map(p => p.value) } : {})
    // ...(payrate
    //   ? {
    //       client_id: payrate,
    //     }
    //   : {}),
  };

  return serializedParams;
};
