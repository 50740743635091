import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { CustomReactTimePicker } from "../../../common/customReactTimePicker";
import { CustomCheckbox } from "../../../../components/common/customCheckbox";
import validate from "./AvailabilityValidate";
import moment from "moment";
import styles from "../ProfileStyles.module.css";

let AddAvailabilityForm = (props) => {

  const [selectedDays, setSelectedDays] = useState([]);

  const onDaySelect = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter(item => item !== day));
      props.change("start_time_" + day, null)
      props.change("end_time_" + day, null)
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  }

  useEffect(() => {
    if (props.selectedDays) {
      let oDays = [];
      Object.keys(props.selectedDays).forEach(dayName => {
        if (props.selectedDays[dayName] && props.selectedDays[dayName] === true) {
          let day = dayName.split("_");
          if (day && day.length === 2) {
            oDays.push(day[1]);
          }
        }
      })
      setSelectedDays([...oDays]);
    }
  }, [props.selectedDays])

  const getHours = (day) => {
    let res = "-";
    if (props.staffHours) {
      let startTime = props.staffHours["start_time_" + day] ? moment(props.staffHours["start_time_" + day]).seconds(0).millisecond(0) : null;
      let endTime = props.staffHours["end_time_" + day] ? moment(props.staffHours["end_time_" + day]).seconds(0).millisecond(0) : null;
      if (startTime && endTime && endTime.isAfter(startTime)) {
        let duration = parseFloat(moment.duration(endTime.diff(startTime)).asHours()).toFixed(2);
        res = duration + " Hrs";
      }
    }
    return res;
  }


  const handleSave = (values) => {
    let avail = {};
    if (selectedDays.length > 0) {
      let hours = 0;
      let start = moment();
      let nextDate = null;
      let dayNum = start.day();
      if (dayNum >= 1) {
        nextDate = start.add(7 - (dayNum - 1), "day");
      } else {
        nextDate = start.add(1 - dayNum);
      }
      const week_start_date = nextDate.format("YYYY-MM-DD");
      values.week_start_date = week_start_date;
      avail.week_start_date = week_start_date;
      const last_week_end_date = nextDate.subtract(1, "day").format("YYYY-MM-DD");
      values.last_week_end_date = last_week_end_date;
      avail.last_week_end_date = last_week_end_date;
      selectedDays.forEach(day => {
        let startTime = values["start_time_" + day] ? moment(values["start_time_" + day]).seconds(0).millisecond(0) : null;
        let endTime = values["end_time_" + day] ? moment(values["end_time_" + day]).seconds(0).millisecond(0) : null;
        if (startTime & endTime) {
          let duration = parseFloat(moment.duration(endTime.diff(startTime)).asHours());
          hours += duration;
          avail[day] = {
            start_time: startTime.format("H:mm"),
            end_time: endTime.format("H:mm")
          }
        }
      });
      avail.total_hours = hours.toFixed(2);
      props.onSaveClose({ values: avail, formData: values });
    } else {
      props.onSaveClose({ values: null, formData: {} });
    }
  }

  return (
    <div>
      <form onSubmit={props.handleSubmit(handleSave)}>
        <div className="container-fluid">
          <div className="row pt-3">
            <div className="col-12">
              <h2 className={`${styles.ProfileLabel} font-weight-bold`}>Add Availability</h2>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-12">
              <table className="table table-sm cs-table-cont cs-table-cont-v2">
                <thead>
                  <tr>
                    <th scope="col" width="6%" className="text-center"></th>
                    <th scope="col" width="15%">Day</th>
                    <th scope="col" width="32%">Start Time</th>
                    <th scope="col" width="32%">End Time</th>
                    <th className="text-center" scope="col" width="15%">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Field
                        name="selected_mon"
                        onChange={() => onDaySelect("mon")}
                        component={CustomCheckbox}
                        className="cs-select-v2"
                      />
                    </td>
                    <td>Monday</td>
                    <td>
                      <div>
                        <Field
                          name="start_time_mon"
                          component={CustomReactTimePicker}
                          disabled={!selectedDays.includes("mon")}
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        <Field
                          name="end_time_mon"
                          disabled={!selectedDays.includes("mon")}
                          component={CustomReactTimePicker}
                        />
                      </div>
                    </td>
                    <td className="text-center">
                      {selectedDays.includes("mon") ? getHours("mon") : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Field
                        name="selected_tue"
                        onChange={() => onDaySelect("tue")}
                        component={CustomCheckbox}
                        className="cs-select-v2"
                      />
                    </td>
                    <td>Tuesday</td>
                    <td>
                      <div>
                        <Field
                          name="start_time_tue"
                          component={CustomReactTimePicker}
                          disabled={!selectedDays.includes("tue")}
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        <Field
                          name="end_time_tue"
                          disabled={!selectedDays.includes("tue")}
                          component={CustomReactTimePicker}
                        />
                      </div>
                    </td>
                    <td className="text-center">
                      {selectedDays.includes("tue") ? getHours("tue") : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Field
                        name="selected_wed"
                        onChange={() => onDaySelect("wed")}
                        component={CustomCheckbox}
                        className="cs-select-v2"
                      />
                    </td>
                    <td>Wednesday</td>
                    <td>
                      <div>
                        <Field
                          name="start_time_wed"
                          component={CustomReactTimePicker}
                          disabled={!selectedDays.includes("wed")}
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        <Field
                          name="end_time_wed"
                          component={CustomReactTimePicker}
                          disabled={!selectedDays.includes("wed")}
                        />
                      </div>
                    </td>
                    <td className="text-center">
                      {selectedDays.includes("wed") ? getHours("wed") : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Field
                        name="selected_thur"
                        onChange={() => onDaySelect("thur")}
                        component={CustomCheckbox}
                        className="cs-select-v2"
                      />
                    </td>
                    <td>Thursday</td>
                    <td>
                      <div>
                        <Field
                          name="start_time_thur"
                          disabled={!selectedDays.includes("thur")}
                          component={CustomReactTimePicker}
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        <Field
                          name="end_time_thur"
                          disabled={!selectedDays.includes("thur")}
                          component={CustomReactTimePicker}
                        />
                      </div>
                    </td>
                    <td className="text-center">
                      {selectedDays.includes("thur") ? getHours("thur") : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Field
                        name="selected_fri"
                        onChange={() => onDaySelect("fri")}
                        component={CustomCheckbox}
                        className="cs-select-v2"
                      />
                    </td>
                    <td>Friday</td>
                    <td>
                      <div>
                        <Field
                          name="start_time_fri"
                          disabled={!selectedDays.includes("fri")}
                          component={CustomReactTimePicker}
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        <Field
                          name="end_time_fri"
                          disabled={!selectedDays.includes("fri")}
                          component={CustomReactTimePicker}
                        />
                      </div>
                    </td>
                    <td className="text-center">
                      {selectedDays.includes("fri") ? getHours("fri") : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Field
                        name="selected_sat"
                        onChange={() => onDaySelect("sat")}
                        component={CustomCheckbox}
                        className="cs-select-v2"
                      />
                    </td>
                    <td>Saturday</td>
                    <td>
                      <div>
                        <Field
                          name="start_time_sat"
                          disabled={!selectedDays.includes("sat")}
                          component={CustomReactTimePicker}
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        <Field
                          name="end_time_sat"
                          disabled={!selectedDays.includes("sat")}
                          component={CustomReactTimePicker}
                        />
                      </div>
                    </td>
                    <td className="text-center">
                      {selectedDays.includes("sat") ? getHours("sat") : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Field
                        name="selected_sun"
                        onChange={() => onDaySelect("sun")}
                        component={CustomCheckbox}
                        className="cs-select-v2"
                      />
                    </td>
                    <td>Sunday</td>
                    <td>
                      <div>
                        <Field
                          name="start_time_sun"
                          disabled={!selectedDays.includes("sun")}
                          component={CustomReactTimePicker}
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        <Field
                          name="end_time_sun"
                          disabled={!selectedDays.includes("sun")}
                          component={CustomReactTimePicker}
                        />
                      </div>
                    </td>
                    <td className="text-center">
                      {selectedDays.includes("sun") ? getHours("sun") : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-right py-3">
              <button
                type="submit"
                disabled={props.pristine}
                className={`${styles.BtnSaveTab} ${props.pristine ? styles.DisabledBtn : ""}`}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

AddAvailabilityForm = reduxForm({
  enableReinitialize: true,
  form: "staff_availability_form",
  validate: validate
})(AddAvailabilityForm);

AddAvailabilityForm = connect((state, initialProps) => ({
  staffHours: (formValueSelector("staff_availability_form"))(state,
    "start_time_mon",
    "end_time_mon",
    "start_time_tue",
    "end_time_tue",
    "start_time_wed",
    "end_time_wed",
    "start_time_thur",
    "end_time_thur",
    "start_time_fri",
    "end_time_fri",
    "start_time_sat",
    "end_time_sat",
    "start_time_sun",
    "end_time_sun",
  ),
  selectedDays: (formValueSelector("staff_availability_form"))(state,
    "selected_mon",
    "selected_tue",
    "selected_wed",
    "selected_thur",
    "selected_fri",
    "selected_sat",
    "selected_sun"
  )
}))(AddAvailabilityForm);

export default AddAvailabilityForm;