import React, { useEffect, useMemo, useState } from "react";
import { Checkbox, notification, Input } from "antd";

import { Strings } from "../../../../../dataProvider/localize";
import { createNewSwms } from "../../../../../actions/v2/JobSwmsTaskActions";
import { CircularProgress } from "@material-ui/core";

import styles from "../../JobStyle.module.css";
import { useDispatch } from "react-redux";

export default function SwmsMethodsSelection({ categories, onGoBack, onClose, task }) {

  const dispatch = useDispatch();

  const [selectedMethods, setSelectedMethods] = useState([]);
  const [categoryMethods, setCategoryMethods] = useState({});
  const [search, setSearch] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [searchMethods, setSearchMethods] = useState([]);

  const methods = useMemo(() => {
    let allMethods = [];
    categories.forEach(category => {
      category.methods.forEach(method => {
        if (allMethods.findIndex(x => x.id === method.id) === -1) {
          allMethods.push({ ...method, category_id: category.id })
        }
      })
    });
    return allMethods
  }, [categories]);

  useEffect(() => {
    setSearchMethods(methods);
  }, [methods])

  useEffect(() => {
    if (search && search.trim().length > 2) {
      setSearchMethods(methods.filter(x => x.method_name.toLowerCase().includes(search.trim().toLowerCase())));
    } else {
      setSearchMethods(methods);
    }
  }, [search])

  const onMethodCheck = (id, category, checked) => {
    if (checked) {
      setSelectedMethods(x => [...x, id]);
      setCategoryMethods(x => ({ ...x, [category]: x[category] ? [...x[category], id] : [id] }));
    } else {
      setSelectedMethods(x => x.filter(y => y !== id));
      setCategoryMethods(x => ({ ...x, [category]: x[category].filter(y => y !== id) }));
    }
  }

  const onBack = () => {
    setSelectedMethods([]);
    setCategoryMethods({});
    setSearch("");
    setSearchMethods(methods);
    onGoBack();
  }

  const onSubmit = () => {
    setSubmitting(true);
    let allData = [];
    for (let key in categoryMethods) {
      allData.push({
        category_id: key,
        methods: [...categoryMethods[key]]
      })
    }

    let formData = {
      job_id: task.job_id,
      task_id: task.task_id,
      task_number: task.task_number,
      job_number: task.job_number,
      swms_data: {
        swms_categories: [...allData]
      }
    }

    dispatch(createNewSwms(formData)).then(message => {
      setSubmitting(false);
      notification.success({
        message: Strings.success_title,
        description: message,
        className: "ant-success"
      })
      onClose();
    }).catch(err => {
      if (err) {
        setSubmitting(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: "ant-error"
        })
      }
    })

  }

  return (
    <div>
      {methods && methods.length > 0 ? (
        <div className="container-fluid px-0 py-2">
          <div className="row">
            <div className="col-4 pt-1 pb-2">
              <Input onChange={e => setSearch(e.target.value)} value={search} className={styles.FilterTextField} placeholder="Search Methods" />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="container-fluid px-0" style={{ maxHeight: "200px", overflow: "hidden auto" }}>
                <div className="row">
                  {searchMethods.map(method => (
                    <div className="col-4" key={method.id}>
                      <Checkbox disabled={submitting} checked={selectedMethods.includes(method.id)} onChange={(e) => onMethodCheck(method.id, method.category_id, e.target.checked)} className="cs-select-v2">{method.method_name}</Checkbox>
                    </div>
                  ))}
                </div>
                {search && searchMethods.length === 0 ? (
                  <div className="row">
                    <div className="col-12 text-center" style={{ color: "red" }}>
                      Unable to find any methods.
                    </div>
                  </div>
                ) : ""}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="d-flex align-items-center pt-3">
                <button type="button" onClick={() => onBack()} disabled={submitting} className={styles.OutlinedButton}>Back</button>
              </div>
            </div>
            <div className="col-8 d-flex justify-content-end align-items-center pt-3" style={{ gap: "10px" }}>
              {submitting ? (
                <CircularProgress size={20} />
              ) : ""}
              <button type="button" onClick={() => onSubmit()} disabled={selectedMethods.length === 0 || submitting} className={styles.PrimaryButton}>Create SWMS</button>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid px-0 py-2">
          <div className="row">
            <div className="col-12">
              <div className="py-2 text-center" style={{ color: "red" }}>
                No Methods are available.
              </div>
              <div className="text-center">
                <button type="button" onClick={() => onBack()} className={styles.OutlinedButton}>Back</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}