import React, { useEffect, useState } from "react";
import styles from "../RosteringStyles.module.css";
import { Radio } from "antd";
import { Tooltip, IconButton } from "@mui/material";
import { Modal } from 'antd';
import CloseIcon from '@mui/icons-material/Close';

const SwmsVersionModal = ({ swmsVersions, selectedSwmsVersion, onClose, jobType, selectedTask = {} }) => {
  const [previewSwmsModal, setPreviewSwmsModal] = useState(false);
  const [swmsUrl, setswmsUrl] = useState(null);
  const [versions, setVersions] = useState({});

  const showSwmsPdf = (pdfLink) => {
    setswmsUrl(pdfLink);
    setPreviewSwmsModal(true)
  }

  useEffect(() => {
    setVersions(selectedSwmsVersion);
  }, [selectedSwmsVersion])

  const onSubmit = () => {
    onClose(versions);
  }

  return (
    <div className="roster-container">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-12">
            <div className={styles.ModalHeading}>
              {jobType && jobType == "Planned" ?
                "Please Select the SWMS Version to attach to Shifts" :
                "Please Select the SWMS Versions to send to the Staff"
              }
            </div>
          </div>
        </div>

        <div className="mt-2">
          {swmsVersions.map(task => (
            <div key={task.task_id} className="">
              <div className="font-weight-bold">{task.task_number} - {task.task_name}</div>
              <div className="border-top my-2 px-3 py-2">
                <Radio.Group size="small" onChange={(e) => setVersions(vers => ({ ...vers, [task.task_id]: e.target.value }))} defaultValue={selectedSwmsVersion[task.task_id]} value={versions[task.task_id]} name={task.task_id} className="w-100">
                  {task.swms_form_ids.map(version => (
                    <div key={version.swms_form_id} className="mb-2 border-bottom pb-2 d-flex align-items-center justify-content-between">
                      <div style={{ flex: "1 1 auto" }}>
                        <Radio value={version.swms_form_id} className="cs-radio-v2">SWMS Version {version.swms_revision}</Radio>
                      </div>
                      <div style={{ flex: "0 0 40px" }} className="text-center">
                        <Tooltip title="Preview SWMS">
                          <span>
                            <IconButton onClick={() => showSwmsPdf(version.pdf_link)} className={`${styles.IconBtn} ${styles.SuccessIcon}`}>
                              <svg xmlns="http://www.w3.org/2000/svg" style={{ height: "16px", width: "16px" }} viewBox="0 0 576 512">
                                <path fill="currentColor" d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                              </svg>
                            </IconButton>
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                </Radio.Group>
              </div>
            </div>
          ))}

        </div>
        <div className="py-2">
          <div className="pt-3">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "end", columnGap: "10px" }}>
              <button
                type="button"
                className={`${styles.BtnNextTab}`}
                onClick={() => onSubmit()}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={previewSwmsModal}
        onCancel={() => setPreviewSwmsModal(false)}
        afterClose={() => { }}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        destroyOnClose={true}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
        style={{ minWidth: "80vw", minHeight: "80vh" }}
      >
        <div style={{ height: "80vh" }}>
          {swmsUrl ? (
            <embed type="application/pdf" src={swmsUrl} style={{ height: '100%', width: '100%' }} />
          ) : ""}
        </div>
      </Modal>
    </div>
  )
}
export default SwmsVersionModal;