import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import { CircularProgress } from "@mui/material";

import { getEmailDate, getStorage } from "../../../utils/common";
import { Strings } from "../../../dataProvider/localize";
import { getFirstName, getFormattedPhone } from "../../../utils/common";
import { ADMIN_DETAILS } from "../../../dataProvider/constant";
import { SLUG_JOB_DOC_TO_CLIENT } from "../../../dataProvider/constant";
import { getEmailTemplateV2 } from "../../../actions/v2/EmailTemplateActions";
import { emailJobDocument } from "../../../actions/v2/JobDocumentActions";
import EmailJobDocForm from "./EmailJobDocForm";

export default function EmailJobDoc(props) {

  const dispatch = useDispatch();
  const [emailData, setEmailData] = useState({ body: "" });
  const [noReplyData, setNoReplyData] = useState({});
  const [loading, setLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(true);

  useEffect(() => {
    setDetailsLoading(true);
    dispatch(getEmailTemplateV2(SLUG_JOB_DOC_TO_CLIENT)).then(data => {

      let adminDetails = JSON.parse(getStorage(ADMIN_DETAILS));
      let body = data.body;
      let subject = data.subject;

      const jobId = props.jobDetails.job_id;

      let jobLink = `${window.location.origin}/client/v2/job-doc-preview/${props.jobDetails.org_id}/${jobId}`;
      if (process.env.REACT_APP_CLIENT_APP_URL) {
        jobLink = `${process.env.REACT_APP_CLIENT_APP_URL}/job-doc-preview/${props.jobDetails.org_id}/${jobId}`;
      }


      let bcc = data.to_bcc ? Array.isArray(data.to_bcc) ? data.to_bcc.map(address => ({ label: address, value: address })) : [{ label: data.to_bcc, value: data.to_bcc }] : [];
      let cc = adminDetails && adminDetails.email_address ? [{ label: adminDetails.email_address, value: adminDetails.email_address }] : []
      let to = props.jobDetails && props.jobDetails.client_person_email ? [{ label: props.jobDetails.client_person_email, value: props.jobDetails.client_person_email }] : ''
      let orgName = adminDetails && adminDetails.organisation && adminDetails.organisation.name ? adminDetails.organisation.name : '';
      let userName = adminDetails ? adminDetails.name + " " + (adminDetails.last_name ? adminDetails.last_name : '') : []
      let noReplyFrom = `${userName} (${orgName}) via ${Strings.SERVICE_NAME} <${data.from_email}>`;

      let clientPersonFirstName = props.jobDetails && props.jobDetails.client_person_name ? getFirstName(props.jobDetails.client_person_name) : '';
      let userPhoneNumber = adminDetails && adminDetails.phone_number ? getFormattedPhone(adminDetails.phone_number) : ''
      let userEmailAddress = adminDetails && adminDetails.email_address ? `<a href="mailto:${adminDetails.email_address}">${adminDetails.email_address}</a>` : "";
      let siteName = props.jobDetails && props.jobDetails.sites && props.jobDetails.sites.length > 0 && props.jobDetails.sites[0].site_name ? props.jobDetails.sites[0].site_name : ''
      let start_date = props.jobDetails && props.jobDetails.job_start_date ? getEmailDate(props.jobDetails.job_start_date) : ''

      subject = subject.replace(/\{\{org_name\}\}/g, orgName);
      subject = subject.replace(/\{\{job_number\}\}/g, props.jobDetails && props.jobDetails.job_number ? props.jobDetails.job_number : '');

      subject = subject.replace(/\{\{site_name\}\}/g, siteName)

      body = body.replace(/(?:\r\n|\r|\n)/g, '');


      body = body.replace(/\{\{org_name\}\}/g, orgName);
      body = body.replace(/\{\{client_person_first_name\}\}/g, clientPersonFirstName);
      body = body.replace(/\{\{job_start_date\}\}/g, start_date);
      body = body.replace(/\{\{link\}\}/g, `<a href='${jobLink}' style='' target='_blank' rel="noopener noreferrer">View Job Documents</a>`)
      body = body.replace(/\{\{user_phone_number\}\}/g, userPhoneNumber);
      body = body.replace(/\{\{user_email\}\}/g, userEmailAddress);
      body = body.replace(/\{\{user_name\}\}/g, userName);
      body = body.replace(/\{\{site_name\}\}/g, siteName);
      body = body.replace(/\{\{service_name\}\}/g, Strings.SERVICE_NAME);
      body = body.replace(/\{\{service_email\}\}/g, `<a href="mailto:${Strings.SERVICE_EMAIL}">${Strings.SERVICE_EMAIL}</a>`);

      if (body.includes("<p>____no_reply</p>") && body.includes("<p>no_reply____</p>")) {
        let start = body.indexOf("<p>____no_reply</p>");
        let end = body.lastIndexOf("<p>no_reply____</p>")

        if (start !== -1 && end !== -1) {
          let signEnd = end + 15;
          let dataStart = start + 15;
          let noReply = body.substr(dataStart, (end - dataStart));
          noReply = `<!--[if mso]><table role="presentation" cellspacing="0" cellpadding="0" border="0" width="600"><tr><td><![endif]--><div style='width: auto;border:none;border-top:solid #000000 1.0pt; border-bottom: solid #000000 1.0pt; padding:4.0pt 0cm 4.0pt 0cm; margin: 20pt 0cm 10pt 0cm;'>`
            + noReply + `</div><!--[if mso]></td></tr></table><![endif]-->`;
          setNoReplyData(noReply);
          body = body.substring(0, start) + '' + body.substring(signEnd);
        }
      }

      setEmailData({
        from_profile: orgName,
        subject: subject,
        body: body,
        from_field: noReplyFrom,
        cc_field: cc,
        to_field: to,
        bcc_field: bcc,
        job_doc_link: jobLink,
        job_doc_number: props.jobDocumentDetails && props.jobDocumentDetails.job_doc_number ? props.jobDocumentDetails.job_doc_number : null,
        client_id: props.jobDetails && props.jobDetails.client_id ? props.jobDetails.client_id : null
      });
      setDetailsLoading(false);
    }).catch(err => {
      if (err) {
        setLoading(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: 'ant-error',
          style: { zIndex: 1301 }
        })
      }
      props.onClose();
    });
  }, [])

  const sendEmail = (values) => {
    setLoading(true);
    let content = values.body;

    content = content + noReplyData;
    content = `<!--[if mso]><table role="presentation" cellspacing="0" cellpadding="0" border="0"><tr><td><![endif]--><div style="font-family: 'calibri', sans-serif !important; font-size: 11pt !important; width: fit-content;">` + content + `</div><!--[if mso]></td></tr></table><![endif]-->`;
    content = content.replace(/font-family:.?inherit;/g, "font-family: 'calibri', sans-serif;");

    content = content.replace(/<p><\/p>/g, '')

    //strip /n from body before sending email
    content = content.replace(/(?:\r\n|\r|\n)/g, '');
    content = content.replace(/<ins>/g, "<u>");
    content = content.replace(/<\/ins>/g, "</u>");

    let formData = {
      to_field: values.to_field.length > 0 ? values.to_field.map(x => x.value).join(',') : '',
      from_field: values.from_field,
      subject: values.subject,
      bcc_field: values.bcc_field.length > 0 ? values.bcc_field.map(x => x.value).join(',') : '',
      cc_field: values.cc_field.length > 0 ? values.cc_field.map(x => x.value).join(',') : '',
      validity: values.validity,
      job_doc_link: values.job_doc_link,
      job_doc_number: values.job_doc_number,
      client_id: values.client_id,
      message: content,
      from_profile: values.from_profile
    };

    dispatch(emailJobDocument(formData)).then(message => {
      notification.success({
        message: Strings.success_title,
        description: message ? message : "Email Sent Successfully.",
        className: 'ant-success'
      })
      setLoading(false);
      props.onClose();
    }).catch(err => {
      if (err) {
        setLoading(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: 'ant-error',
          style: { zIndex: 1301 }
        })
      }
    })
  }

  return (
    <div className="v2-modal-container">
      {detailsLoading ? (
        <div className="d-flex align-items-center justify-content-center py-3" style={{ color: "#362148" }}>
          <CircularProgress size={20} color="inherit" />
        </div>
      ) : emailData && Object.keys(emailData).length > 1 ? (
        <EmailJobDocForm
          form="email_job_doc"
          enableReinitialize={true}
          initialValues={emailData}
          onSubmit={sendEmail}
          onClose={props.onClose}
          loading={loading}
        />
      ) : ""}
    </div>
  )
}