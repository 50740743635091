import React, { Component } from "react";
import { connect } from "react-redux";
import { tabs } from "./tabDefinitions";
import { Tabs } from "antd";
import styles from "./TabsContainer.module.css";
import * as SWMSMastersActions from "../../../../actions/SWMSMastersActions";
import * as roleManagementActions from "../../../../actions/roleManagementActions";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { getStorage } from "../../../../utils/common";
import { ADMIN_DETAILS } from "../../../../dataProvider/constant";

const { TabPane } = Tabs;

export class TabsContainer extends Component {
  state = {
    currentSelectedTab: 1,
  };

  componentDidMount() {
    for (let SWMSType = 1; SWMSType <= 7; SWMSType++) {
      this.props.actions
        .getSWMS(SWMSType)
        .then(() => { })
        .catch((err) => { });
    }

    this.props.actions
      .getAllOrgSWMS()
      .then(() => { })
      .catch((err) => { });

    const orgId = JSON.parse(getStorage(ADMIN_DETAILS))
      ? JSON.parse(getStorage(ADMIN_DETAILS)).organisation.id
      : null;

    orgId && this.props.roleManagementActions.getRoles(orgId);

    this.props.actions.resetSWMSActivityInitialValues()
  }

  onTabChange = (type) => {
    this.props.actions.setActivityPage(1)
    if (type != 1) {
      this.props.actions.deactivateBreadCrumbs()
    }
    this.setState({ currentSelectedTab: type });
    if (type <= 5) {
      this.props.actions
        .getSWMS(type)
        .then(() => { })
        .catch((err) => { });
    } else if (type == 1) {
      this.props.actions.getSWMSActivity()
        .then(() => { })
        .catch((err) => { });
    } else {
      this.props.actions
        .getAllOrgSWMS()
        .then(() => { })
        .catch((err) => { });
    }
  };

  render() {
    const { breadCrumbs } = this.props

    return (
      <Tabs tabPosition="left" activeKey={breadCrumbs.active ? '1' : `${this.state.currentSelectedTab}`} onChange={(key) => this.onTabChange(key)} destroyInactiveTabPane={true}>
        {tabs.map((tab) => (
          <TabPane tab={tab.title} key={tab.id}>
            <div>
              <h1 className={styles.tabHeader}>{tab.title}</h1>
              {tab.Component ? (
                <tab.Component currentTab={this.state.currentSelectedTab} pageChangeCb={this.props.pageChangeCb} />
              ) : (
                <div>{tab.title}</div>
              )}
            </div>
          </TabPane>
        ))}
      </Tabs>
    );
  }
}

const mapStateToProps = (state) => ({
  breadCrumbs: state.SWMSMasters.breadCrumbs
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(SWMSMastersActions, dispatch),
    roleManagementActions: bindActionCreators(roleManagementActions, dispatch),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TabsContainer)
);
