import { Radio } from "antd";
import React from "react";

const CustomRadioGroup = ({
  input,
  value,
  disabled,
  label,
  options,
  placeholder,
  defaultValue,
  mode,
  showSearch,
  itemClassName = "",
  meta: { touched, error, warning },
}) => (
  <>
    <Radio.Group
      onChange={input.onChange}
      value={input.value}
      disabled={disabled}
    >
      {options.map((option) => (
        <div className={itemClassName} >
          <Radio value={option.value}>{option.label ? option.label : ""}</Radio>
        </div>
      ))}
    </Radio.Group>
    {touched &&
      ((error && <span className="error-input">{error}</span>) ||
        (warning && <span>{warning}</span>))}
  </>
);

export default CustomRadioGroup;
