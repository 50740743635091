export const serializePreviewData = (data = {}) => {
  let acts = data.activities && data.activities.swms_activities ? data.activities.swms_activities : [];
  let risks = data.risk_ratings && data.risk_ratings.risk_rating_items ? data.risk_ratings.risk_rating_items : [];
  acts = acts.map(x => {
    let actRowCount = 0;
    let hazards = x.fm_swms_activities_hazards ? x.fm_swms_activities_hazards.map(haz => {
      let hazRowCount = haz.fm_swms_hazards_consequences ? haz.fm_swms_hazards_consequences.length : 1;
      actRowCount += (hazRowCount === 0 ? 1 : hazRowCount) + 1;
      let hazCons = [];
      haz.fm_swms_hazards_consequences.map(cons => {
        let irRisk = cons.initial_risk ? risks.find(x => x.risk_rating_high_value >= cons.initial_risk && x.risk_rating_low_value <= cons.initial_risk) : null;
        let rrRisk = cons.residual_risk ? risks.find(x => x.risk_rating_high_value >= cons.residual_risk && x.risk_rating_low_value <= cons.residual_risk) : null;
        hazCons.push({ ...cons, irColor: irRisk ? irRisk.risk_colours : "", rrColor: rrRisk ? rrRisk.risk_colours : "" })
      })
      return { ...haz, rowCount: hazRowCount, fm_swms_hazards_consequences: hazCons }
    }) : []
    return {
      ...x,
      fm_swms_activities_hazards: [...hazards],
      rowCount: actRowCount
    }
  })
  return { ...data, activities: { ...data.activities, swms_activities: [...acts] } }
}