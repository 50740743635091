import { SET_SITES } from "../../dataProvider/constant";
const INITIAL_STATE = {
  sites: [],
  sitesCount: 0,
}

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_SITES: {
      return {
        ...state,
        sites: payload.sitesList,
        sitesCount: payload.sites_count
      }
    }
    default: {
      return state;
    }
  }
}