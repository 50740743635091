import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Icon, notification, Popconfirm, Select } from "antd";
import { reduxForm, Field } from "redux-form";
import { CustomAsyncSelect } from "../../../../common/new-core-ui/CustomAsyncSelect";
import { getContractorsBySearch, outsourceTask } from "../../../../../actions/v2/JobAllocatonActions";
import { updateTaskContractorStatus, getJobTasks, getJobHeader, updateContractorInvoiceState } from "../../../../../actions/v2/JobActions";
import { getNewJobDetails } from "../../../../../actions/v2/JobCalendarActions";
import styles from "../../JobStyle.module.css";
import { customInput } from "../../../../common/custom-input";
import { Strings } from "../../../../../dataProvider/localize";
import moment from "moment";
import { validateJobBoardContractor } from "../validate"
import { getStorage, removeCurrencyFormat } from "../../../../../utils/common";
import { ADMIN_DETAILS } from "../../../../../dataProvider/constant";
import SelectV2SingleStyle, { JobBoardStatusSelectStyle } from "../../SelectV2SingleStyle";
import { components } from "react-select";
import { CustomSingleSelect } from "../../../../common/new-core-ui/CustomSingleSelect";

const { Option } = Select;

const TaskContractorForm = (props) => {

  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState([])
  const [visible, setVisible] = useState(false);
  const [taskState, setTaskState] = useState("");
  const [invoiceStatus, setInvoiceStatus] = useState(0);
  const [confirmInvoicing, setConfirmInvoicing] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const dispatch = useDispatch();

  const taskStates = ["Outsourced", "Started", "Completed", "Signed Off", "Cancelled"];

  const isDisabledState = (state) => {
    let availableStates = [];
    let currentState = props.contractor && props.contractor.contractor_task_status ? props.contractor.contractor_task_status : "";

    switch (currentState) {
      case "Outsourced":
        // if (task_type === "JOB_TASK") {
        availableStates.push("Started", "Completed", "Cancelled");
        // } else {
        // availableStates.push("Cancelled");
        // }
        break;
      case "Started":
        availableStates.push("Completed", "Cancelled")
        break;
      case "Completed":
        availableStates.push("Started", "Cancelled");
        break;
      case "Signed Off":
        availableStates = [];
        break;
      case "Cancelled":
        availableStates.push("Started");
        // if (props.contractor.previous_task_status && props.contractor.previous_task_status !== null) {
        //   availableStates.push(props.contractor.previous_task_status);
        // }
        break;
      case "":
        availableStates = [];
        break;
      default: availableStates.push("Started", "Cancelled");
    }
    return state !== currentState && !availableStates.includes(state)
  }

  const handleInputChange = (search) => {
    return search.trim().length > 0 ?
      Promise.resolve(dispatch(getContractorsBySearch(search, 1)).then(res =>
        res.contractors.map(contractor => ({
          label: `${contractor.contractor_name}${contractor.contractor_type && contractor.contractor_type === 1 ? ' (Org)' : ''}`,
          value: contractor.contractor_id
        }))
      )) : Promise.resolve([]);
  }

  useEffect(() => {
    if (props.contractor && props.contractor.contractor_task_status) {
      setTaskState(props.contractor.contractor_task_status);
      let status = 0;
      if (props.task.invoice_number && props.task.invoice_number !== null) {
        status = 3;
      } else if (props.contractor.job_board_contractor_task_state && props.contractor.job_board_contractor_task_state.toLowerCase() === "contractor value approved by") {
        status = 2;
      } else if (props.contractor.job_board_contractor_task_state && props.contractor.job_board_contractor_task_state.toLowerCase() === "contractor job value approved") {
        status = 1;
      }
      setInvoiceStatus(status);
      if (props.contractor.contractor_task_status.toString().toLowerCase() === "Completed" ||
        props.contractor.contractor_task_status.toString().toLowerCase() === "Cancelled" ||
        (props.task && props.task.task_state && props.task.task_state.toString().toLowerCase() === "cancelled") || status === 3) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
  }, [props.contractor]);

  const getDefaultContractorOption = () => {
    let defaultOrg = [];
    let storageUser = JSON.parse(getStorage(ADMIN_DETAILS));
    if (storageUser && storageUser.organisation && storageUser.organisation.id && storageUser.organisation.name) {
      defaultOrg.push({ label: `${storageUser.organisation.name} (Org)`, value: storageUser.organisation.id })
    }

    return props.contractor && props.contractor.org_id ? [
      {
        label: `${props.contractor.contractor_name}${props.contractor.organisation_type && props.contractor.organisation_type === 1 ? ' (Org)' : ''}`,
        value: props.contractor.org_id
      },
      ...(storageUser && storageUser.organisation && storageUser.organisation.id && storageUser.organisation.id !== props.contractor.org_id ? defaultOrg : [])
    ] : defaultOrg
  }

  const onSubmit = (values) => {
    setLoading(false);
    let formData = {
      job_id: props.jobId,
      task_label: props.task.task_label,
      task_state: props.task.task_state,
      outsourced_task_cost: parseFloat(removeCurrencyFormat(values.outsourced_budget)),
      outsoured_notes: values.notes,
      task_id: props.task.quote_task_id ? props.task.quote_task_id : 0,
      ...(props.task.task_start_date ? { sug_start_date: moment(props.task.task_start_date).format("YYYY-MM-DD") } : {}),
      ...(props.task.task_end_date ? { sug_finish_date: moment(props.task.task_end_date).format("YYYY-MM-DD") } : {}),
      ...(props.task.task_invoice_id ? { task_invoice_id: props.task.task_invoice_id } : {})
    }
    // let contractorTasks = [{
    //   service_agent_id: values.contractor.value,
    //   outsourced_budget_to_sa: parseFloat(removeCurrencyFormat(values.outsourced_budget)),
    //   quote_notes: values.notes,
    //   ...(values.id ? { id: values.id } : {}),
    //   ...(props.contractor && props.contractor.contractor_task_status ? { contractor_task_status: props.contractor.contractor_task_status } : {}),
    //   ...(props.contractor && props.contractor.contractor_task_invoice_id ? { contractor_task_invoice_id: props.contractor.contractor_task_invoice_id } : {})
    // }];
    // formData.contractor_tasks = JSON.stringify(contractorTasks);
    dispatch(outsourceTask(formData)).then((message) => {
      Promise.all([dispatch(getJobTasks(props.jobId)), dispatch(getJobHeader(props.jobId))])
        .catch(err => {
          notification.error({
            message: Strings.error_title,
            description: err ? err : Strings.generic_error,
            className: 'ant-error'
          })
        })
      props.reset();
      if (props.isAdd) {
        props.closeAddNew();
      }
      notification.success({
        message: Strings.success_title,
        description: message ? message : Strings.generic_error,
        className: 'ant-success'
      });
    }).catch(err => {
      cancelContractorStateChange();
      notification.error({
        message: Strings.error_title,
        description: err ? err : Strings.generic_error,
        className: 'ant-error'
      })
    }).finally(() => setLoading(false));
  }

  const cancelContractorStateChange = () => {
    setVisible(false)
    if (props.contractor && props.contractor.contractor_task_status) {
      setTaskState(props.contractor.contractor_task_status);
    }
  }

  const onTaskStateChange = (e) => {
    setTaskState(e.value);
    setVisible(true);
  }

  const sendContractorForInvoicing = () => {
    if (props.contractor.contractor_task_invoice_id) {
      let formData = {
        contractor_task_invoice_id: props.contractor.contractor_task_invoice_id,
        job_board_contractor_task_state: "Contractor Job Value Approved",
        // job_id: props.job.job_id
      }
      dispatch(updateContractorInvoiceState(formData)).then((message) => {
        setConfirmInvoicing(false);
        let adminDetails = JSON.parse(getStorage(ADMIN_DETAILS));
        Promise.all([dispatch(getJobTasks(props.jobId)), dispatch(getJobHeader(props.jobId)), dispatch(getNewJobDetails(adminDetails.role.slug, props.jobId, false))])
          .catch(err => {
            notification.error({
              message: Strings.error_title,
              description: err ? err : Strings.generic_error,
              className: 'ant-error'
            })
          })
        notification.success({
          message: Strings.success_title,
          description: message ? message : Strings.generic_error,
          className: 'ant-success'
        });
      }).catch(err => {
        cancelContractorStateChange();
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: 'ant-error'
        })
      })
    } else {
      setConfirmInvoicing(false)
      notification.error({
        message: Strings.error_title,
        description: Strings.generic_error,
        className: 'ant-error'
      })
    }
  }

  const onContractorStatusChange = () => {
    let formData = {
      contractor_quote_task_id: props.contractor.id,
      contractor_task_state: taskState,
      task_label: props.task.task_label,
      quote_task_id: props.task.quote_task_id ? props.task.quote_task_id : 0,
      job_id: props.jobId,
      task_type: props.task.task_type,
      contractor_id: props.contractor.org_id,
      previous_contractor_task_status: props.contractor.contractor_task_status,
      ...(props.contractor.contractor_task_invoice_id ? { contractor_task_invoice_id: props.contractor.contractor_task_invoice_id } : {}),
      ...(props.task.task_invoice_id ? { task_invoice_id: props.task.task_invoice_id } : {})
    }
    dispatch(updateTaskContractorStatus(formData)).then((message) => {
      setVisible(false);
      let adminDetails = JSON.parse(getStorage(ADMIN_DETAILS));
      Promise.all([dispatch(getJobTasks(props.jobId)), dispatch(getJobHeader(props.jobId)), dispatch(getNewJobDetails(adminDetails.role.slug, props.jobId, false))])
        .catch(err => {
          notification.error({
            message: Strings.error_title,
            description: err ? err : Strings.generic_error,
            className: 'ant-error'
          })
        })
      notification.success({
        message: Strings.success_title,
        description: message ? message : Strings.generic_error,
        className: 'ant-success'
      });
      props.reset();
    }).catch(err => {
      cancelContractorStateChange();
      notification.error({
        message: Strings.error_title,
        description: err ? err : Strings.generic_error,
        className: 'ant-error'
      })
    })
  }

  const getSelectedOption = () => {
    let defaultOrg = [];
    let storageUser = JSON.parse(getStorage(ADMIN_DETAILS));
    if (storageUser && storageUser.organisation && storageUser.organisation.id && storageUser.organisation.name) {
      defaultOrg.push({ label: `${storageUser.organisation.name} (Org)`, value: storageUser.organisation.id })
    }
    return [
      ...selectedOption,
      ...(storageUser && storageUser.organisation && storageUser.organisation.id && selectedOption[0].value !== storageUser.organisation.id ? defaultOrg : [])]
  }

  return (
    <form onSubmit={props.handleSubmit(onSubmit)}>
      <div>
        <div className="container-fluid px-0">
          <div className="row align-items-center">
            <div className="col-5">
              <div className="container-fluid px-0">
                <div className="row align-items-center">
                  <div className="col-9">
                    <div className="container-fluid px-0">
                      <div className="row">
                        <div className="col-7">
                          <Field name="contractor"
                            component={CustomAsyncSelect}
                            clearable={true}
                            placeholder="Contractor"
                            styles={SelectV2SingleStyle}
                            otherProps={{
                              isDisabled: true,
                              cacheOptions: true,
                              hideSelectedOptions: false, defaultOptions: selectedOption.length ? getSelectedOption() : getDefaultContractorOption(),
                              onChange: (value => { setSelectedOption([value]); props.change("contractor", value) }),
                              components: { dropdownIndicator: <components.DownChevron /> }
                            }}
                            loadOptions={handleInputChange}
                            dropdownIndicator={<Icon type="down" style={{ fontSize: "13px" }} />}
                          />
                        </div>
                        <div className="col-5">
                          <Field disabled placeholder="Value" name="outsourced_budget" className={styles.SingleLine} component={customInput} autoComplete="off" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mt-2">
                          <Field disabled={loading || isDisabled} placeholder="Description" name="notes" className={styles.SingleLine} component={customInput} autoComplete="off" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <button disabled={loading || props.pristine || isDisabled} type="submit" className={`${styles.PrimaryButton} ${styles.SmallPrimaryButton}`}>{props.isAdd ? 'Outsource' : 'Update'}</button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    {props.error && <span className="error-input">{props.error}</span>}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-7">
              <div className="container-fluid">
                <div className="row">
                  {/* {!props.isAdd ? (
                    <>
                      <div className="col-4 px-4 text-center align-self-center" onClick={(e) => e.stopPropagation()}>
                        <Popconfirm
                          title={<div>
                            <div className="font-weight-bold">Are you sure you want to change Status for this Contractor?</div>
                            <div>Status Changes are not reversible.</div>
                          </div>}
                          visible={visible}
                          onCancel={() => cancelContractorStateChange()}
                          onConfirm={() => onContractorStatusChange()}
                        >
                          <CustomSingleSelect
                            value={{ label: taskState, value: taskState }}
                            className={`w-100 text-${taskState.toString().replace(/\s/gm, '').toLowerCase()}`}
                            onChange={onTaskStateChange}
                            isDisabled={invoiceStatus === 3 || (props.task && props.task.task_state && props.task.task_state.toString().toLowerCase() === "cancelled")}
                            options={taskStates.map(state => ({
                              label: state, value: state,
                              isDisabled: isDisabledState(state)
                            }))}
                            styles={JobBoardStatusSelectStyle}
                            meta={{ touched: false, error: false, warning: false }}
                            otherProps={{
                              components: { dropdownIndicator: <components.DownChevron /> }
                            }}
                          />
                        </Popconfirm>
                      </div> */}
                  {/* {invoiceStatus === 2 || invoiceStatus === 3 ?
                        <div className={`col-6 text-center ${styles.PrimaryText}`}>
                          <div className={`text-center ${props.contractor && props.contractor.contractor_task_state === "Started" && invoiceStatus === 2 ? styles.DisabledText : ''}`}>
                            <div>Contractor Values Approved by</div>
                            <div>{props.contractor.invoice_approver_name && props.contractor.invoice_approver_name}
                              {props.contractor.invoice_approved_at ? ` - ${moment(props.contractor.invoice_approved_at).format("DD/MM/YYYY")}` : ''}</div>
                          </div>
                        </div>
                        // // Commented for New Approve Invoice Button
                        // : invoiceStatus === 1 && !props.isDisabledJob ?
                        //   <div className={`col-6 text-center ${styles.PrimaryText}`}>
                        //     <Popconfirm
                        //       title={<div onClick={e => e.stopPropagation()}>
                        //         <div className="font-weight-bold">Are you sure you want to confirm Job Values for this Contractor?</div>
                        //         <div>This change is not reversible.</div>
                        //       </div>}
                        //       visible={confirmInvoicing}
                        //       onCancel={(e) => { e.stopPropagation(); setConfirmInvoicing(false) }}
                        //       onConfirm={(e) => { e.stopPropagation(); sendContractorForInvoicing() }}>
                        //       <button onClick={e => { e.stopPropagation(); setConfirmInvoicing(true) }} className={`${styles.textButton} ${styles.orangeButton}`}>Contractor Job Value Approved</button>
                        //     </Popconfirm>
                        //   </div>
                          :
                          <div className={`col-6 text-center ${styles.PrimaryText}`}>
                            <div className="text-center">N/A</div>
                          </div>} */}
                  {/* </>
                  ) : ''} */}
                  {!props.isDisabledJob ? (
                    <div className="col-4 ml-auto px-0">
                      <button className={styles.PrimaryButton} type="button" onClick={() => props.setSelectedTask(props.task)}>View Frequency</button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </form>
  )
}

export default reduxForm({
  enableReinitialize: true,
  validate: validateJobBoardContractor
})(TaskContractorForm)