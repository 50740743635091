import React, { useEffect, useState } from "react";
import { Collapse, Icon, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { getJobTasks, getJobSiteTasks, getJobOnSiteInfo, getJobHeader } from "../../../../../actions/v2/JobActions";
import { currencyFormat } from "../../../../../utils/common";
import AddNewTask from "./AddNewTask";
import TaskContractors from "./TaskContractors";
// import IssuePO from "./IssuePO/IssuePO";
// import BatchIssuePO from "./IssuePO/BatchIssuePO";
// import Task from "./Task";
import TaskHeader from "./TaskHeader";


import styles from "../../JobStyle.module.css";

const { Panel } = Collapse;

export default function TaskList(props) {
  const dispatch = useDispatch();
  const jobTasks = useSelector((state) => state.Job.parentTasks)
  const jobDetails = useSelector((state) => state.Job.jobDetails)
  const isAlreadyInvoiced = useSelector(state =>
    (state.Job.jobHeader && state.Job.jobHeader.ar_job_state && (state.Job.jobHeader.ar_job_state.toLowerCase() === "not found")) ? true : false
  );
  const isFullyInvoiced = useSelector(state =>
    (state.Job.jobHeader && state.Job.jobHeader.ar_job_state && (
      state.Job.jobHeader.ar_job_state.toString().toLowerCase() === "job csv uploaded" || state.Job.jobHeader.ar_job_state.toString().toLowerCase() === "job csv created")) ? true : false);

  const isDisabledJob = useSelector(state =>
    isAlreadyInvoiced || (state.Job.jobHeader && !state.Job.jobHeader.job_allocated_to) ? true : false
  );
  const siteId = useSelector(state => state.Job.jobHeader && state.Job.jobHeader.hasOwnProperty('site_id') && state.Job.jobHeader.site_id)
  const siteName = useSelector(state => state.Job.jobHeader && state.Job.jobHeader.hasOwnProperty('site_name') && state.Job.jobHeader.site_name)

  const [addNewTask, setAddNewTask] = useState(false);
  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    if (props.jobId) {
      dispatch(getJobTasks(props.jobId))
    }
  }, [props.jobId])

  const toggleAddNewTask = () => {
    setAddNewTask(!addNewTask);
  }

  const refreshJobBoard = () => {
    if (props.jobId) {
      props.onReload();
      Promise.all([
        dispatch(getJobHeader(props.jobId)),
        dispatch(getJobTasks(props.jobId)),
        dispatch(getJobSiteTasks(props.jobId)),
        dispatch(getJobOnSiteInfo(props.jobId)),
      ])
    }
  }

  const togglePanelKey = (key) => {
    if (activeKey !== key) {
      setActiveKey(key)
    } else {
      setActiveKey(null)
    }
  }

  return (
    <div>
      <div className={`${styles.JobTasksListContainer}`}>
        <div className={styles.JobTaskHeadRow}>
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-4">
                <div className={styles.JobListHeading}>{jobDetails && jobDetails.job_number && jobDetails.job_number}
                  <Tooltip title="Reload Job Board"><Icon type="sync" onClick={() => refreshJobBoard()} className={`ml-1 ${styles.JobListHeadingIcon}`} /></Tooltip></div>
                <div>{jobDetails && jobDetails.job_number && jobDetails.job_name}</div>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row align-items-center">
                    <div className="col-2 px-0">{jobDetails && jobDetails.job_total_amount ? currencyFormat(jobDetails.job_total_amount) : ''}</div>
                    <div className="col-3 pl-0 pr-4">
                      <div className={`${styles.jobStatusBox} job-board-status-${jobDetails && jobDetails.job_state ? jobDetails.job_state.toLowerCase().replaceAll(" ", "") : ""}`}>
                        <div>
                          {jobDetails && jobDetails.job_state && jobDetails.job_state}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Collapse
          bordered={false}
          className={styles.Collapse}
          accordion
          activeKey={activeKey}
          expandIcon={({ isActive, showIcon = true }) => (
            <Icon
              type={isActive ? "minus" : "plus"}
              className={`${styles.blueText} ${!showIcon ? styles.transparentIcon : ''}`}
            />
          )}
        >
          {jobTasks && jobTasks.map(task => (<React.Fragment key={task.task_number}>
            <Panel
              className={`${styles.Panel} job-board-default-panel-cursor ${task.task_type === "JOB_TASK" ? "job-board-v2-panel-part" : ""}`}
              header={<TaskHeader refreshJobBoard={refreshJobBoard} onActive={() => togglePanelKey(task.task_number)} isDisabledJob={isDisabledJob} taskDetails={task} job={jobDetails} />}
              // disabled={true}
              isActive={activeKey === task.task_number}
              showIcon={false}
              openAnimation={Collapse.defaultProps.openAnimation}
              expandIcon={({ isActive, showIcon = true }) => showIcon ? (
                <Icon onClick={() => togglePanelKey(task.task_number)}
                  type={isActive ? "minus" : "plus"}
                  className={`${styles.PrimaryText} ${!showIcon ? styles.transparentIcon : ''} ant-collapse-arrow`}
                />) : null}
            >
              {/* {task.quote_task_id && task.task_type !== "JOB_TASK" && <Task isDisabledJob={isDisabledJob} taskDetails={task} jobId={props.jobId} />} */}
            </Panel>
            <Panel className={`${styles.PartPanel} job-board-panel-part job-board-v2-panel-part`} disabled
              header={<TaskContractors
                task={task}
                jobId={props.jobId}
                tasks={jobTasks}
                siteId={siteId}
                isDisabledJob={isDisabledJob}
                siteName={siteName}
                setSelectedTask={props.setSelectedTask}
                job={jobDetails}
                refreshJobBoard={refreshJobBoard}
              />}
              showIcon={false} />

          </React.Fragment>)
          )}
        </Collapse>
        <div>
          {addNewTask &&
            <AddNewTask onClose={toggleAddNewTask} job={jobDetails} />
          }
        </div>
        <div className="d-flex align-items-center">
          {/* {!isFullyInvoiced && <button className={`${!addNewTask ? styles.SecondaryButton : styles.FilterResetButton} my-3 mx-2`}
          onClick={() => toggleAddNewTask()} disabled={isDisabledJob}>
          {!addNewTask ? 'Add New Task' : 'Cancel'}
        </button>} */}
          {isAlreadyInvoiced ? <span className="text-danger" >This job is already Invoiced.</span> : isDisabledJob ? <span className="text-danger">This job is not Resourced yet.</span> : ''}
        </div>
        {jobDetails.conditions && jobDetails.conditions !== "" ?
          <div className="p-2" style={{ borderTop: '1px solid #e5e5e5' }}>
            <div className="font-weight-bold">Special Notes</div>
            <div className="custom-htmlBox px-3" dangerouslySetInnerHTML={{ __html: jobDetails.conditions }}></div>
          </div>
          : ''}
      </div>
      <div className="mt-3">
        {/* <BatchIssuePO
          tasks={jobTasks}
          jobId={props.jobId}
          siteId={siteId}
          jobLabel={jobDetails.job_label}
          siteName={siteName}
          refreshJobBoard={refreshJobBoard}
        /> */}
      </div>
    </div>
  );
}
