import React from 'react'
import AsyncCreatableSelect from 'react-select/async-creatable';
import colourStyles from "./reactSelectStyles"

export const CustomAsyncCreatableSelect = ({ input, label = "", loadOptions, onChange, clearable, placeholder, styles = colourStyles, dropdownIndicator = null, indicatorSeparator = null,  isMulti = false, otherProps = {}, meta: { touched, error, warning } }) => {
    return (
      <div>
        { label && label.length > 0 && 
            <label>{label}</label> 
        }
        <AsyncCreatableSelect
            onChange={onChange || input.onChange}
            clearable={clearable || true}
            styles={styles}
            placeholder={placeholder}
            components={{
              DropdownIndicator: () => dropdownIndicator,
              IndicatorSeparator: () => indicatorSeparator
            }}
            value={input.value}
            loadOptions={loadOptions}
            isMulti={isMulti}
            menuPortalTarget={document.body}
            {...otherProps}
        />
        {touched && ((error && <span className="error-input">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    )   
}    