import React, { useState } from 'react';
import { compose } from 'redux';
import { CircularProgress } from "@mui/material";
import { notification } from "antd";
import { Field, reduxForm } from "redux-form";
import { components } from "react-select";
import { useDispatch } from "react-redux";

import { CustomAsyncSelect } from "../../../common/new-core-ui/CustomAsyncSelect";
import { getOrgUsersBySearch } from "../../../../actions/v2/OrganizationActions";
import { updateProfileManagers } from "../../../../actions/profileManagementActions";
import { Strings } from '../../../../dataProvider/localize';


import "../../../v2/v2Styles.css";
import colourStyles from "../../../v2/v2FormSelectStyle";
import v2Styles from "../../../v2/v2Styles.module.css";

const validate = (values) => {

  let errors = {};

  if (!values.compliance_manager) {
    errors.compliance_manager = "Required";
  }
  if (!values.direct_manager) {
    errors.direct_manager = "Required";
  }

  return errors;
}

const ManagerDetailsForm = ({ handleSubmit, onClose, dirty, orgId, userDetails }) => {

  const dispatch = useDispatch();

  const defaultList = [{ label: "Self Appointed", value: userDetails.user_name }];
  const [directManager, setDirectManager] = useState([...defaultList]);
  const [complianceManager, setComplianceManager] = useState([...defaultList]);
  const [loading, setLoading] = useState(false);

  const getManagers = (inputValue, isDirect = true) => {
    return inputValue.trim().length > 2 ?
      Promise.resolve(dispatch(getOrgUsersBySearch({ org_user_id: orgId, staff_name: `"${inputValue.trim()}"`, page: 1 })).then(res => {
        let managerList = res && res.length > 0 ? res.map(manager => ({ label: manager.name && manager.last_name ? `${manager.name} ${manager.last_name}` : manager.name, value: manager.user_name })) : [];
        if (isDirect === true) {
          setDirectManager([...managerList, ...defaultList]);
        } else {
          setComplianceManager([...managerList, ...defaultList]);
        }
        return [...managerList, ...defaultList];
      })).catch(err => {
        if (err) {
          console.log(err);
        }
      }) : Promise.resolve([]);
  }

  const updateManagers = (formValues) => {
    setLoading(true);
    const formData = {
      is_self_direct_manager: formValues.direct_manager && formValues.direct_manager.value === userDetails.user_name ? 1 : 0,
      direct_manager: formValues.direct_manager && formValues.direct_manager.value === userDetails.user_name ? null : formValues.direct_manager.value,
      is_self_compliance_manager: formValues.compliance_manager && formValues.compliance_manager.value === userDetails.user_name ? 1 : 0,
      compliance_manager: formValues.compliance_manager && formValues.compliance_manager.value === userDetails.user_name ? null : formValues.compliance_manager.value,
      org_user_id: orgId,
      org_user_name: userDetails.user_name,
    };
    dispatch(updateProfileManagers(formData)).then((message) => {
      setLoading(false);
      notification.success({
        message: Strings.success_title,
        description: message,
        className: "ant-success"
      })
      onClose();
    })
      .catch((err) => {
        setLoading(false);
        if (err) {
          notification.error({
            message: Strings.error_title,
            description: err ? err : Strings.generic_error,
            className: "ant-error"
          })
        }
      })
  }

  return (
    <div className="v2-modal-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={v2Styles.ModalHeading}>{"Edit Managers"}</div>
            <div className="">
              <hr className="v3-modal-hr" />
            </div>
          </div>
          <div className="col-6">
            <div className={v2Styles.Label}>Direct Manager</div>
            <div>
              <Field
                name={`direct_manager`}
                component={CustomAsyncSelect}
                loadOptions={(v) => getManagers(v, true)}
                styles={colourStyles}
                otherProps={{
                  components: { dropdownIndicator: <components.DownChevron /> },
                  defaultOptions: directManager,
                  noOptionsMessage: ({ inputValue }) => inputValue.trim().length > 2 ? "No Options" : "Type to Search",
                  cacheOptions: true,
                  placeholder: "Search or Appoint Self",
                  isValidNewOption: (inputValue) => inputValue && inputValue.trim().length > 2
                }}
              />
            </div>
          </div>
          <div className="col-6">
            <div className={v2Styles.Label}>Compliance Manager</div>
            <div>
              <Field
                name={`compliance_manager`}
                component={CustomAsyncSelect}
                loadOptions={(v) => getManagers(v, false)}
                styles={colourStyles}
                otherProps={{
                  components: { dropdownIndicator: <components.DownChevron /> },
                  defaultOptions: complianceManager,
                  noOptionsMessage: ({ inputValue }) => inputValue.trim().length > 2 ? "No Options" : "Type to Search",
                  cacheOptions: true,
                  placeholder: "Search or Appoint Self",
                  isValidNewOption: (inputValue) => inputValue && inputValue.trim().length > 2
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-right pt-3">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "end", columnGap: "10px" }}>
              {loading ? (
                <CircularProgress size={20} />) : ""}
              <button type="button" className={`${v2Styles.OutlinedButton}`} onClick={onClose} style={{ padding: "0px 5px" }}>
                Cancel
              </button>
              <button
                disabled={!dirty || loading}
                className={v2Styles.PrimaryButton}
                onClick={handleSubmit(updateManagers)}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default compose(
  reduxForm({ form: 'ManagerDetailsForm', validate: validate, enableReinitialize: true }))(ManagerDetailsForm);