import React, { useState } from "react";
import styles from "../../JobStyle.module.css";
import { Field, FieldArray } from "redux-form";
import { customTextarea } from "../../../../common/customTextarea";
import DocumentImageContainer from "./DocumentImageContainer";
import PreviewDocument from "./PreviewDocument";

export default function TaskDetailsSection({ taskDetails, loading }) {

  const [previewFile, setPreviewFile] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const openFilePreview = (file) => {
    setPreviewFile(file);
    setShowModal(true);
  }

  const closePreview = () => {
    setShowModal(false);
    setPreviewFile(null)
  }

  return (
    <div className="m-1 mb-0">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-4">
            <div><b>Task Id:</b> {taskDetails.task_number ? taskDetails.task_number : ""}</div>
          </div>
          <div className="col-4">
            <div><b>Task Name:</b> {taskDetails.task_name ? taskDetails.task_name : ""}</div>
          </div>
          <div className="col-4">
            <div><b>Task Area:</b> {taskDetails.task_areas ? taskDetails.task_areas : ""}</div>
          </div>
          <div className="col-4">
            <b>Task Notes:</b> {taskDetails.note ? taskDetails.note : ""}
          </div>
          <div className="col-4">
            <div className={styles.Label}>Task Details</div>
            <Field
              name="details"
              disabled={loading}
              component={customTextarea}
              rows={3}
              className={styles.TextArea}
            />
          </div>
          <div className="col-4">
            <FieldArray
              name="task_documents_images"
              loading={loading}
              previewFile={openFilePreview}
              formName="job_swms_form"
              component={DocumentImageContainer}
              s3UploadPath={"job-swms/task-details/task-attachments"}
              fieldName={"task_details_section.task_documents_images"}
            />
          </div>
        </div>
      </div>
      {showModal && previewFile &&
        <PreviewDocument file={previewFile} onCancel={closePreview} />
      }
    </div>
  )
}