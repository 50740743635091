import React from "react";
import styles from "../../JobStyle.module.css";

export default function PreviewSwmsCheckboxSection({ sectionHeader, data = [] }) {
  return (
    <div className="row my-3">
      <div className="col-12 px-0">
        <div className="swms-v4-table-heading text-uppercase">
          {sectionHeader}
        </div>
        <div className="swms-v4-border px-3 py-2">
          <div className="container-fluid">
            <div className="row" style={{ rowGap: "5px" }}>
              {data.map(item => (
                <div className="col-6 px-0" key={item.id}>
                  <div className="w-full border-bottom">
                    {item.method_item}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>


    // <div className="row mb-0 mt-3">
    //   <div className="col-sm-12">
    //     <div className="content-box">
    //       <div className="form-group">
    //         <label className="half-width">
    //           {sectionHeader}
    //         </label>
    //         <div className="content-checkbox">
    //           <div className="row">
    //             <div className="col-sm-12 box-check box-50">
    //               <ul>
    //                 {data.map(item => (
    //                   <li key={`${item.id}`}>
    //                     <div className="form-check">
    //                       <input
    //                         type="checkbox"
    //                         className="form-check-input"
    //                         checked={item.selected === 1}
    //                         readOnly
    //                       />
    //                       <label className="form-check-label">
    //                         {item.method_item}
    //                       </label>
    //                     </div>
    //                   </li>
    //                 ))}
    //               </ul>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

  )
}

export const PreviewSwmsSdsSection = ({ sectionHeader, data = [], filesBasePath }) => {
  return (
    <div className="row my-3">
      <div className="col-12 px-0">
        <div className="swms-v4-table-heading text-uppercase">
          {sectionHeader}
        </div>
        <div className="swms-v4-border">
          <table className="swms-v4-table">
            <thead>
              <tr>
                <th className="swms-v4-normal-th px-3 font-weight-bold">Safety Data Sheets</th>
                <th className="swms-v4-normal-th px-3 font-weight-bold" width="20%">Document</th>
              </tr>
            </thead>
            <tbody>
              {data.map(item => (
                <tr key={item.id}>
                  <td className="px-3">
                    {item.method_item}
                  </td>
                  <td className="px-3">
                    {item.sds_documents && item.sds_documents.file_name ? <a target="_blank" className={styles.SwmsPreviewLink} rel="noreferrer noopener">Attached Document</a> : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>

    // <div className="row mb-0 mt-3">
    //   <div className="col-sm-12">
    //     <div className="content-box">
    //       <div className="form-group">
    //         <label className="half-width">
    //           {sectionHeader}
    //         </label>
    //         <div className="content-checkbox">
    //           <div className="row">
    //             <div className="col-sm-12 box-check box-50">
    //               <ul>
    //                 {data.map(item => (
    //                   <li key={`${item.id}`}>
    //                     <div className="form-check">
    //                       <input
    //                         type="checkbox"
    //                         className="form-check-input"
    //                         checked={item.selected === 1}
    //                         readOnly
    //                       />
    //                       <label className="form-check-label d-flex align-items-center" style={{ gap: "3px" }}>
    //                         {item.method_item} {item.sds_documents && item.sds_documents.file_name ? <div> - <a target="_blank" className={styles.SwmsPreviewLink} href={`${filesBasePath}${item.sds_documents.file_name}`} rel="noreferrer noopener">Attached Document</a></div> : ""}
    //                       </label>
    //                     </div>
    //                   </li>
    //                 ))}
    //               </ul>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}