import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { notification, Radio, Modal } from "antd";
import CloseIcon from '@mui/icons-material/Close';

import { copySwms, getApprovedSiteSwms } from "../../../../../actions/v2/JobSwmsTaskActions";
import { Strings } from "../../../../../dataProvider/localize";
import PreviewSwms from "../JobSwmsPreview/PreviewSwms";

import styles from "../../JobStyle.module.css";
import { IconButton, Tooltip, CircularProgress } from "@mui/material";

export default function CopySiteSwmsModal({ task, onClose, onCreateSwms }) {

  const [loading, setLoading] = useState(false);
  const [previewSwmsModal, setPreviewSwmsModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [version, setVersion] = useState(null);
  const [selectedTaskSWMS, setSelectedTaskSWMS] = useState(null);

  const [versions, setVersions] = useState([]);
  const controller = useRef();
  const dispatch = useDispatch();

  const onPreview = (swms) => {
    setSelectedTaskSWMS({ swms });
    setPreviewSwmsModal(true);
  }

  const fetchApprovedSiteSwms = () => {
    setLoading(true);
    controller.current = new AbortController();
    dispatch(getApprovedSiteSwms({ signal: controller.current.signal }, { task_type: task.task_type, site_id: task.site_id })).then(res => {
      setVersions(res);
      setLoading(false);
    }).catch(err => {
      if (err) {
        setLoading(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: "ant-error"
        })
        onClose();
      }
    })
  }

  useEffect(() => {
    fetchApprovedSiteSwms();
    return () => {
      if (controller.current) {
        controller.current.abort();
      }
    }
  }, []);

  const onCopy = () => {
    setSubmitting(true);
    let formData = {
      swms_form_id: version,
      task_to_be_copied: [task.task_id],
      job_id: task.job_id,
      job_number: task.job_number
    }
    dispatch(copySwms(formData)).then(message => {
      setSubmitting(false);
      notification.success({
        message: Strings.success_title,
        description: message,
        className: "ant-success"
      });
      onClose();
    }).catch(err => {
      if (err) {
        setSubmitting(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: "ant-error"
        })
      }
    })
  }

  return (
    <div className="job-swms-modal-container">
      {loading ? (
        <div className="d-flex align-items-center justify-content-center" style={{ height: "120px" }}>
          <CircularProgress size={20} />
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className={styles.ModalHeading}>
                Select SWMS Version to copy
              </div>
              <div>
                {task.site_name ? task.site_name : ""} - {task.task_name ? task.task_name : ""}{task.task_type_name ? " - " + task.task_type_name : ""}
              </div>
              <div className="pt-2">
                <hr />
              </div>
            </div>
          </div>
          {versions && versions.length > 0 ? (
            <div className="row">
              <div className="col-12">
                <div className="container-fluid px-0" style={{ maxHeight: "40vh", overflow: "hidden auto" }}>
                  {versions.map((item, index) => (
                    <div className="row py-2 border-bottom" key={index}>
                      <div className="col-8">
                        <Radio disabled={submitting} checked={version === item.swms_form_id} onChange={() => setVersion(item.swms_form_id)} name="select_version" className="cs-radio-v2">
                          SWMS Version {item.swms_revision}</Radio>
                      </div>
                      <div className="col-4 text-right pr-3">
                        <Tooltip title="Preview SWMS">
                          <span>
                            <IconButton onClick={() => onPreview(item)} className={`${styles.IconBtn} ${styles.SuccessIcon}`} disabled={submitting}>
                              <svg xmlns="http://www.w3.org/2000/svg" style={{ height: "16px", width: "16px" }} viewBox="0 0 576 512">
                                <path fill="currentColor" d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                              </svg>
                            </IconButton>
                          </span>
                        </Tooltip>
                      </div>
                    </div>))}
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12 pt-2">
                <div className="text-center" style={{ color: "red" }}>No SWMS Available to Copy from the Site.</div>
                <div className="text-center mt-2">
                  <button onClick={() => { onCreateSwms() }} className={styles.PrimaryButton}>Create New SWMS</button>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12 pt-3">
              <div className="d-flex align-items-center justify-content-end" style={{ gap: "5px" }}>
                {submitting ? (
                  <CircularProgress size={20} />
                ) : ""}
                <button className={styles.OutlinedButton} onClick={() => onClose()}>Cancel</button>
                {versions && versions.length > 0 ? (
                  <button disabled={submitting || !version} onClick={onCopy} className={styles.PrimaryButton}>Copy SWMS</button>
                ) : ""}
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={previewSwmsModal}
        onCancel={() => setPreviewSwmsModal(false)}
        afterClose={() => { setSelectedTaskSWMS(null); }}
        maskClosable={false}
        destroyOnClose={true}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
        style={{ minWidth: "80vw", minHeight: "80vh" }}
      >
        {selectedTaskSWMS ? (
          <PreviewSwms
            {...selectedTaskSWMS}
            onClose={() => setPreviewSwmsModal(false)}
          />
        ) : ""}
      </Modal>
    </div>
  )
}