import {
  INIT_DRAG_LIST,
  GET_MASTERS_DATA,
  GET_ORIGINAL_SWMS_DATA,
  RECOMPUTE_DRAG_DROP_LISTS,
  GET_CLIENT_SITES_LIST,
  GET_SINGLE_SWMS_ITEM,
  GET_ALL_LIKELIHOOD_BEFORE_CONTROL,
  GET_ALL_BEFORE_CONSEQUENCES,
  GET_CONSEQUENCES_BY_HAZARDS,
  GET_SINGLE_SWMS_ACTIVITY,
  SET_SWMS_ACTIVITY_INITIALVALUES,
  RESET_SWMS_ACTIVITY_INITIALVALUES,
  GET_NEW_SWMS_ACTIVITY_LIST,
  ACTIVATE_BREADCRUMBS,
  DEACTIVATE_BREADCRUMBS,
  SET_ACTIVITY_PAGE,
  GET_ALL_SWMS,
  GET_SWMS_ORG_DETAILS,
  GET_SWMS_TASK_DETAILS,
  GET_LICENSEES_DATA,
  GET_SIGN_HISTORY_DATA
} from "../dataProvider/constant";
import { getSWMSTypeByMap } from "../utils/common";

const DEFAULT_STATE = {
  generalCategory: [],
  taskType: [],
  consequences: [],
  control: [],
  standards: [],
  licensesAndTraining: [],
  orgDetails: {},
  sites: [],
  job_swms_id: '',
  swms_number: '',
  swms_revision: '',
  clientDetails: {},
  jobDetails: {},
  hazards: [],
  highRiskWork: [],
  ppe: [],
  category: [],
  tools: [],
  chemicals: [],
  site_specific_swms: [],
  licensees: [],
  activity: [],
  chemicalsCheck: [],
  ppesCheck: [],
  activityCheck: [],
  highRiskWorkCheck: [],
  newActivityList: [],
  viewActivity: {},
  activityDragItems: {
    tools: [],
    ppe: [],
    highRiskWork: [],
    licensesAndTraining: [],
    standards: [],
  },
  activityDropItems: {
    tools: [],
    ppe: [],
    highRiskWork: [],
    licensesAndTraining: [],
    standards: [],
  },
  clientsList: [],
  sitesList: [],
  singleSWMSItems: {},
  likelihood_before: [],
  consequences_before: [],
  consequencesByHazards: [],
  signHistory: [],
  historyStaff: [],
  activityCurrentPage: 1,
  initialValues: {
    general_category: [],
    category: [],
    responsible: [],
    task_type: [],
    is_site_specific: 0,
    client: [],
    site: [],
    status: 1,
    hazards: [],
    ppesSelected: [],
    consequences: [],
    likelihood_before_controls: [],
    consequence_before_controls: [],
    likelihood_after_controls: [],
    consequence_after_controls: [],
  },
  breadCrumbs: {
    active: false,
    tabKey: 99,
    page: 1
  }
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case GET_MASTERS_DATA: {
      return {
        ...state,
        [getSWMSTypeByMap[action.payload.type]]: action.payload.data,
      };
    }

    case GET_ORIGINAL_SWMS_DATA: {
      const {
        high_risk_works,
        ppes,
        sds,
        swms_cat,
        tools,
        swms_activities,
      } = action.payload;

      return {
        ...state,
        highRiskWork: high_risk_works,
        ppe: ppes,
        category: swms_cat,
        chemicals: sds,
        tools: tools,
        activity: swms_activities,
      };
    }

    //////////////get swms data///////
    case GET_ALL_SWMS: {
      const {
        high_risk_works,
        ppes,
        sds,
        swms_cat,
        tools,
        swms,
      } = action.payload;

      return {
        ...state,
        highRiskWork: high_risk_works,
        ppe: ppes,
        category: swms_cat,
        chemicals: sds,
        tools: tools,
        activity: swms,
      };
    }

    //// GET_history_DATA///
    case GET_SIGN_HISTORY_DATA: {
      return {
        ...state,

        signHistory: action.payload.history,
        historyStaff: action.payload.staff
      };
    }

    //// GET_LINCESEES_DATA///
    case GET_LICENSEES_DATA: {
      return {
        ...state,

        licensees: action.payload,
      };
    }

    //////////get swms org details
    case GET_SWMS_ORG_DETAILS: {
      const {
        org_details,
        client_details,
        job_details,
        job_swms_id,
        swms_number,
        swms_revision,
        sites,
        swms,
        ppes,
        high_risk_works,
        chemicals,
        site_specific_swms
      } = action.payload;

      return {
        ...state,
        orgDetails: org_details,
        clientDetails: client_details,
        jobDetails: job_details,
        sites: sites && Array.isArray(sites) && sites.length === 1 ? sites[0] : sites ? sites : null,
        job_swms_id: job_swms_id,
        swms_number: swms_number,
        swms_revision: swms_revision,
        chemicalsCheck: chemicals,
        ppesCheck: ppes,
        activityCheck: swms,
        highRiskWorkCheck: high_risk_works,
        site_specific_swms: site_specific_swms
      };
    }

    ///////get swms org task details
    case GET_SWMS_TASK_DETAILS: {
      const {
        org_details,
        client_details,
        job_details,
        sites
      } = action.payload;

      return {
        ...state,
        orgDetails: org_details,
        clientDetails: client_details,
        jobDetails: job_details,
        sites: sites
      };
    }

    case INIT_DRAG_LIST: {
      return {
        ...state,
        activityDragItems: action.payload,
        activityDropItems: DEFAULT_STATE.activityDropItems
      };
    }

    case RECOMPUTE_DRAG_DROP_LISTS: {
      const { dragList, dropList } = action.payload;

      return {
        ...state,
        activityDragItems: dragList,
        activityDropItems: dropList,
      };
    }

    case GET_CLIENT_SITES_LIST: {
      const { clients, sites } = action.payload

      return {
        ...state,
        clientsList: clients,
        sitesList: sites
      }
    }

    case GET_SINGLE_SWMS_ITEM: {
      return {
        ...state,
        singleSWMSItems: {
          [getSWMSTypeByMap[action.payload.type]]: action.payload.data
        }
      }
    }

    case GET_ALL_LIKELIHOOD_BEFORE_CONTROL: {
      return {
        ...state,
        likelihood_before: action.payload
      }
    }

    case GET_ALL_BEFORE_CONSEQUENCES: {
      return {
        ...state,
        consequences_before: action.payload
      }
    }

    case GET_CONSEQUENCES_BY_HAZARDS:
      return {
        ...state,
        consequencesByHazards: action.payload
      }

    case GET_SINGLE_SWMS_ACTIVITY:
      return {
        ...state,
        viewActivity: action.payload
      }

    case SET_SWMS_ACTIVITY_INITIALVALUES:
      return {
        ...state,
        initialValues: action.payload
      }

    case RESET_SWMS_ACTIVITY_INITIALVALUES:
      return {
        ...state,
        initialValues: { ...DEFAULT_STATE.initialValues }
      }

    case GET_NEW_SWMS_ACTIVITY_LIST:
      return {
        ...state,
        newActivityList: action.payload
      }

    case ACTIVATE_BREADCRUMBS:
      return {
        ...state,
        breadCrumbs: {
          active: true,
          tabKey: 99,
          page: action.payload
        }
      }

    case DEACTIVATE_BREADCRUMBS:
      return {
        ...state,
        breadCrumbs: { ...DEFAULT_STATE.breadCrumbs }
      }

    case SET_ACTIVITY_PAGE:
      return {
        ...state,
        activityCurrentPage: action.payload
      }

    default: {
      return state;
    }
  }
};
