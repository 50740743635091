import React from "react";

export const getIsAllDay = (start, end) => {
  return true;
};

export const getOutsourceStatus = (sites) => {
  return sites.every((site) =>
    site.tasks.every((task) => task.outsource_status === 1)
  ) === true
    ? 1
    : 0;
};

export const getJobState = (sites) => {
  return sites && Array.isArray(sites) && sites.length && sites[0].state
    ? sites[0].state
    : "";
};

export const getJobTasks = (sites, params) => {
  if (!(sites && Array.isArray(sites) && sites.length)) {
    return [];
  }

  let taskCount = 0;
  sites.forEach((site) =>
    site.tasks.forEach((task) => task.task_id && taskCount++)
  );

  if (!taskCount) {
    return [];
  }

  const tasks = [];
  sites.forEach((siteItem) =>
    siteItem.tasks.forEach((taskItem) => {
      const { id: site_id, client_id, state } = siteItem;
      const {
        id,
        job_id,
        task_id,
        quote_id,
        scope_doc_id,
        service_agent_id,
        start_date,
        end_date,
        task_title,
        task_status,
        job_task_label,
        description,
        task_amount,
        invoice_generated,
        invoice_number,
        xero_invoice_state,
        contractor_details,
        estimate,
        estimated_cost,
        additional_cost_details,
        task_additional_total_cost,
        task_name,
        task_state,
        area_name
      } = taskItem;

      let title = task_title;

      if(xero_invoice_state && xero_invoice_state !== null) {
        title = <span><i className="fa fa-star mr-1" aria-hidden="true"></i>{task_title}</span>
      }

      tasks.push({
        id,
        taskId: task_id,
        jobId: job_id,
        quoteId: quote_id,
        scopeId: scope_doc_id,
        siteId: site_id,
        state: state,
        clientId: client_id,
        serviceAgentId: service_agent_id ? service_agent_id : params.org_id,
        start: new Date(start_date),
        end: new Date(end_date),
        title: title,
        task_title: `${task_title}${area_name ? ' - ' + area_name : ''}`,
        allDay: getIsAllDay(start_date, end_date),
        status: task_status,
        invoiced: invoice_generated,
        invoice_number: invoice_number,
        signedOff: params.job_sheet_sign_off_status,
        accountManagerUsername: params.acc_manager_user_name,
        quoteNumber: params.quote_number,
        xeroInvoiceState: xero_invoice_state,
        contractors: contractor_details,
        taskLabel: job_task_label,
        taskDescription: description,
        taskAmount: task_amount,
        estimate: estimate,
        estimateValue: estimated_cost,
        additionalCostDetails: additional_cost_details,
        totalAdditionalCost: task_additional_total_cost,
        taskName: task_name,
        task_state: task_state,
        areaName: area_name

      });
    })
  );

  return tasks;
};

export const getJobColorByMap = {
  0: "#D0021B", //Not Approved
  1: "#548235", //Booked
  2: "#0080b8", //unknown
  3: "#4A90E2", //Allocated
  4: "linear-gradient(135deg, #00b0f0 14.29%, #ffffff 14.29%, #ffffff 50%, #00b0f0 50%, #00b0f0 64.29%, #ffffff 64.29%, #ffffff 50%)", //unknown
  5: "#00B0F0", //Outsourced
  6: "",
  7: "#7ED321", //Started
  8: "#F5A623", //Paused
  9: "#4A4A4A", //Completed
  10: "#7B1FA2", //Signed Off
  12: "#cccccc" //Cancelled
};

export const filtersByMap = {};

export const getEventModifyableStatus = (tasks, roleType) => {
  return roleType === "admin"
    ? !tasks.every((task) => task.status >= 7)
    : !tasks.some((task) => task.status >= 7);
};
