import {
  GET_PAYRATE_LIST,
  GET_PAYRATE_FOR_USER_MANAGEMENT,
  GET_TIMESHEET_BY_USERNAME,
  IS_GET_PAY_RATE_LIST,
  IS_GET_TIMESHEET_BY_USERNAME
} from "../dataProvider/constant";

const INITIAL_STATE = {
  payRateList: [],
  totalPayRateCount: 0,
  payRateUserManagement: [],
  timesheetByUsername: null,
  isTimesheetByUsername: false,
  isGetPayRatelist: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case GET_PAYRATE_LIST:
      return {
        ...state,
        payRateList: action.payload,
        totalPayRateCount: action.total
      }
    case GET_PAYRATE_FOR_USER_MANAGEMENT:
      return {
        ...state,
        payRateForUserManagement: action.payload
      }
    case GET_TIMESHEET_BY_USERNAME:
      return {
        ...state,
        timesheetByUsername: action.payload
      }
    case IS_GET_TIMESHEET_BY_USERNAME:
      return {
        ...state,
        isTimesheetByUsername: action.payload
      }
      case IS_GET_PAY_RATE_LIST:
      return {
        ...state,
        isGetPayRatelist: action.payload
      }
    default:
      return {
        ...state
      }
  }
}