import React from "react";
import FileViewer from 'react-file-viewer';
class ViewReportDocModel extends React.Component {



    render() {
        const { taskFiles } = this.props;

        const file = taskFiles && taskFiles.length > 0 && taskFiles
        var index = file && file.lastIndexOf(".");
        var type = file && file.substr(index + 1);
        console.log(type);
        return (
            <div>
                <div className="text-center">
                    {taskFiles && taskFiles.length > 0
                        ?

                        <FileViewer
                            fileType={type}
                            filePath={file}
                        /> : ""}
                </div>
            </div>
        );
    }
}

export default ViewReportDocModel;
